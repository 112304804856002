import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Icon, IconButton, SearchBar } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export type CustomersTableToolbarProps = {
  onCreateCustomer?: VoidFunction;
  onImportCustomers: VoidFunction;
  search?: string;
  onSearch: (value: string) => void;
  shouldDisplayFilters?: boolean;
};

export const CustomersTableToolbar = forwardRef<CustomersTableToolbarProps>(
  ({ onImportCustomers, onCreateCustomer, search, onSearch, shouldDisplayFilters = true, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const {
      settings: { arDashboardSearchLabelDisabled },
    } = useConfig();
    const hideSearchLabel = arDashboardSearchLabelDisabled;

    if (!shouldDisplayFilters) return null;

    return (
      <Group
        data-component={CustomersTableToolbar.displayName}
        width="full"
        justifyContent="space-between"
        data-testid="customers-table-toolbar"
        {...props}
        ref={ref}
      >
        <SearchBar
          value={search}
          onSearch={(value) => onSearch(String(value ?? ''))}
          label={
            hideSearchLabel ? undefined : formatMessage('ar.dashboard.activities.customersTable.searchInput.label')
          }
          placeholder={
            hideSearchLabel
              ? formatMessage('ar.dashboard.activities.customersTable.searchInput.placeholder')
              : undefined
          }
          onClear={() => onSearch('')}
          aria-label={formatMessage('ar.dashboard.activities.customersTable.searchInput.aria-label')}
          instructionsText={formatMessage('ar.dashboard.activities.customersTable.searchInput.instructions')}
        />

        <Group spacing="s" variant="horizontal">
          <IconButton
            aria-label={formatMessage('ar.dashboard.activities.customersTable.importCustomers.button.ariaLabel')}
            icon="upload"
            data-testid="import-customers-icon-button"
            onClick={onImportCustomers}
          />
          <Button
            variant="tertiary"
            onClick={onCreateCustomer}
            label={formatMessage('ar.dashboard.activities.customersTable.newCustomer.button.label')}
            leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
            data-testid="new-customer-button"
          />
        </Group>
      </Group>
    );
  }
);
CustomersTableToolbar.displayName = 'CustomersTableToolbar';
