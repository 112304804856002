import { ActionsDropdownMenu, IconButton } from '@melio/penny';
import { ActionItemWithoutIndexProps } from '@melio/penny/dist/containers/menus/ActionsDropdownMenu/ActionItem';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

type Props = {
  subscriptionPlanDropdownActions: ActionItemWithoutIndexProps[];
};

export const SubscriptionActionsMenu = ({ subscriptionPlanDropdownActions }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <ActionsDropdownMenu
      data-testid="actions-dropdown-menu-list"
      items={[...subscriptionPlanDropdownActions]}
      label="Actions"
      isOpen={isMenuOpen}
      onOpenChange={setIsMenuOpen}
      size="small"
      variant="default"
      trigger={
        <IconButton
          variant="primary"
          icon="more-vertical"
          aria-label={formatMessage(
            'activities.accountants.firmDashboard.v2.clientDrawer.subscription.actionMenu.button.ariaLabel'
          )}
        />
      }
    />
  );
};
