import { Layout } from '@melio/platform-ds';
import { forwardRef } from '@melio/platform-utils';

type PaymentIntentsTableLoadingScreenProps = {
  onBack: VoidFunction;
};

export const PaymentIntentsTableLoadingScreen = forwardRef<PaymentIntentsTableLoadingScreenProps, 'div'>(
  ({ onBack, ...props }, ref) => (
    <Layout
      data-component="BatchPaymentsActivity.PaymentIntentsTableLoadingScreen"
      data-testid="batch-payments-activity-payment-intents-table-screen"
      isLoading
      {...props}
      ref={ref}
    />
  )
);

PaymentIntentsTableLoadingScreen.displayName = 'BatchPaymentsActivity.PaymentIntentsTableLoadingScreen';
