import { Box } from '@chakra-ui/react';
import { isPayorPaymentFee } from '@melio/ap-domain';
import { BillDetailsWidgetAccessibleNew, Carousel } from '@melio/ap-widgets';
import { Arrows } from '@melio/ap-widgets/src/components/BillsDetailsCarousel/components/Arrows';
import { useArrowsStateMachine } from '@melio/ap-widgets/src/components/BillsDetailsCarousel/components/UseArrowsStateMachine';
import { Group, Loader, StackedContainer, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Bill,
  BillsApiGetBillsRequest,
  PaymentFullyExpanded,
  PaymentStatusEnum,
  useAccountingPlatforms,
  useBills,
  usePaymentApprovalDecisions,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePartnerFeature } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { noop } from 'lodash';
import React, { RefObject, useEffect } from 'react';

import { MicroDepositModalRefHandlers } from '../../funding-sources';
import { DrawerPreviewInvoice } from '../../PayDashboard/components/Drawer/DrawerPreviewInvoice';
import { usePaymentDrawerData, useShowBillDetailsInPaymentDrawer } from '../paymentDrawer.utils';
import { PaymentDetails } from './payment-details/PaymentDetails';
import { PaymentFees } from './payment-fees/PaymentFees';

export const Body = ({
  paymentId,
  billDetailsRef,
  microDepositModalRef,
}: {
  paymentId: string;
  billDetailsRef: RefObject<HTMLDivElement>;
  microDepositModalRef: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const [isNPEPaymentFeesEnabled] = useDevFeature(FeatureFlags.NPEPaymentFees, false);
  const { formatMessage } = useMelioIntl();
  const [isPreviewInvoiceEnabled] = usePartnerFeature('PreviewInvoice', false);
  const { data: payment, refetch: refetchPayment, isFetching: isPaymentLoading } = usePaymentDrawerData(paymentId);
  const { fundingSource, markedAsPaid, bills: billsFromPayment } = payment ?? {};
  const { showBillDetailsInPaymentDrawer } = useShowBillDetailsInPaymentDrawer(payment?.createMethod);
  const { track } = useAnalytics();

  useEffect(() => {
    if (fundingSource && payment && payment?.fundingSource?.isVerified !== fundingSource?.isVerified) {
      void refetchPayment();
    }
  }, [refetchPayment, payment, fundingSource]);

  const handleNextFileClick = () => {
    track('Payment', 'Click', {
      Cta: 'next-file',
    });
  };

  const handlePreviousFileClick = () => {
    track('Payment', 'Click', {
      Cta: 'previous-file',
    });
  };

  const billsFromPaymentIds = billsFromPayment?.map((bill) => bill.id) || [];
  const billsSearchParams: BillsApiGetBillsRequest = markedAsPaid
    ? {
        search: {
          'bill.id': billsFromPaymentIds,
          'bill.status': {
            $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'cancelled'],
          },
        },
        expand: ['vendor', 'payments', 'file'],
      }
    : {
        search: {
          'payment.id': payment?.id,
          'bill.status': {
            $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'cancelled'],
          },
        },
        expand: ['vendor', 'payments', 'file'],
      };

  const {
    isMutating: isBillUpdating,
    data: bills,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
  } = useBills({
    enabled: markedAsPaid ? !!billsFromPaymentIds : !!payment?.id,
    params: billsSearchParams,
  });
  const { currentElementIndex, handlePrevClick, handleNextClick } = useArrowsStateMachine(bills?.length || 0);

  const { activeAccountingPlatform, isFetching: isAccountingPlatformLoading } = useAccountingPlatforms();
  const { data: approvalDecisions, isLoading: isApprovalDecisionsLoading } = usePaymentApprovalDecisions({
    paymentId,
    refetchOnMount: 'always',
  });

  const isLoading = isPaymentLoading || isBillUpdating || isApprovalDecisionsLoading;

  const isLoadingBillsSection = isLoadingBills || isFetchingBills || isAccountingPlatformLoading;

  const { routeReady } = useMonitoring();

  if (isLoading) {
    return <Loader aria-live="polite" />;
  }
  if (!payment || (!!payment?.fundingSource && !fundingSource)) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        data-testid="payment-details-empty-state"
      >
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('activities.payDashboard.drawer.body.payment.empty.label')}
        </Text>
      </Box>
    );
  }

  const mapBills = (bill: Bill, _index: number, bills: Bill[]) => (
    <BillDetailsWidgetAccessibleNew
      ref={billDetailsRef}
      key={bill.id}
      bill={bill}
      hasBorder={bills.length > 1}
      showOpenBalance
      activeAccountingPlatform={activeAccountingPlatform}
      onSubmit={noop}
      onSubmissionStateChange={noop}
      onInvoiceChange={noop}
      paymentVendor={payment?.vendor?.history?.deletedAt ? payment?.vendor : undefined}
      hideRemainingAmountSection={
        payment?.status === PaymentStatusEnum.Canceled || payment?.status === PaymentStatusEnum.Failed
      }
      disableSystemMessage
    />
  );

  const elements = bills?.map(mapBills);

  const renderBillsSection = () => {
    if (isLoadingBillsSection) {
      return <Loader />;
    }
    if (!elements || elements.length === 0) {
      return null;
    }
    if (elements.length > 1) {
      const prevButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.prev.tooltip'
      );
      const nextButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.next.tooltip'
      );
      return (
        <Group variant="vertical" spacing="m">
          {isPreviewInvoiceEnabled ? (
            <>
              <Group variant="horizontal" spacing="m" justifyContent="space-between" alignItems="center">
                <Text textStyle="body2Semi">
                  {formatMessage('activities.payDashboard.drawer.body.payment.billDetails.label', {
                    amount: elements.length,
                  })}
                </Text>
                <Arrows
                  currentElementIndex={currentElementIndex}
                  handlePrevClick={handlePrevClick}
                  handleNextClick={handleNextClick}
                  totalElements={elements.length}
                  isCyclical={false}
                  tooltipLeftButtonLabel={prevButtonTooltipLabel}
                  tooltipRightButtonLabel={nextButtonTooltipLabel}
                  testId="bills"
                />
              </Group>
              <StackedContainer width="full" stacksToDisplay={elements.length <= 1 ? 0 : elements.length === 2 ? 1 : 2}>
                {elements[currentElementIndex]}
              </StackedContainer>
            </>
          ) : (
            <Carousel
              elements={elements}
              initialElementIndex={0}
              ref={billDetailsRef}
              tooltipLeftButtonLabel={prevButtonTooltipLabel}
              tooltipRightButtonLabel={nextButtonTooltipLabel}
              arrowSpacing="m"
            />
          )}
        </Group>
      );
    }
    return elements[0];
  };

  const renderPaymentDetails = () => (
    <Group hasDivider ref={routeReady} data-testid="pay-dashboard-payment" variant="vertical" spacing="l">
      <Group variant="vertical" spacing="l">
        <SystemMessageDisplay data-testid="payment-drawer-notification" />
        <PaymentDetails
          payment={payment as PaymentFullyExpanded}
          approvalDecisions={approvalDecisions}
          billDetailsRef={billDetailsRef}
          microDepositModalRef={microDepositModalRef}
        />
      </Group>

      {isNPEPaymentFeesEnabled && !payment.isFinanced && payorFees.length > 0 ? (
        <PaymentFees fees={payorFees} paymentAmount={payment.amount} />
      ) : null}

      {showBillDetailsInPaymentDrawer ? renderBillsSection() : null}
    </Group>
  );

  const payorFees = payment.fees?.filter(isPayorPaymentFee) ?? [];
  const isPaymentIncludeBillWithInvoice = payment?.bills?.some((bill) => bill.invoice.fileId);

  return isPreviewInvoiceEnabled ? (
    <DrawerPreviewInvoice
      filesIds={payment.bills?.[currentElementIndex]?.invoice.filesIds}
      isIncludeMultiInvoices={isPaymentIncludeBillWithInvoice}
      onNextFileClick={handleNextFileClick}
      onPreviousFileClick={handlePreviousFileClick}
    >
      {renderPaymentDetails()}
    </DrawerPreviewInvoice>
  ) : (
    renderPaymentDetails()
  );
};
