import { GridItem, SimpleGrid, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ComponentProps, ReactNode } from 'react';

import { FormattedMessage } from '../i18n';
import { useIsMobile } from '../useIsMobile';

const useResponsiveForm = () => {
  const isMobile = useIsMobile();
  return {
    getCols: <T,>(value: T): T | number => value && (isMobile ? 1 : value),
    getSpacing: (value?: ComponentProps<typeof SimpleGrid>['spacing']) => value ?? (isMobile ? 's' : 'm'),
  };
};

export type FormGridProps = ComponentProps<typeof SimpleGrid> & { isHidden?: boolean };
export const FormGrid = forwardRef<FormGridProps>(({ columns, spacing, isHidden, ...props }, ref) => {
  const { getSpacing, getCols } = useResponsiveForm();
  return (
    <SimpleGrid
      data-component={FormGrid.displayName}
      spacing={getSpacing(spacing)}
      columns={getCols(columns)}
      display={isHidden ? 'none' : props.display}
      {...props}
      ref={ref}
    />
  );
});
FormGrid.displayName = 'Form.Grid';

export type FormGridItemProps = Override<ComponentProps<typeof GridItem>, { isHidden?: boolean; hidden?: never }>;
export const FormGridItem = forwardRef<FormGridItemProps>(({ colSpan, isHidden, ...props }, ref) => (
  <GridItem
    data-component={FormGridItem.displayName}
    display={isHidden ? 'none' : props.display}
    colSpan={useResponsiveForm().getCols(colSpan)}
    {...props}
    ref={ref}
  />
));
FormGridItem.displayName = 'Form.GridItem';

export type FormSectionProps = Override<FormGridProps, { title?: ReactNode }> &
  Pick<FormGridItemProps, 'colSpan' | 'isHidden'>;
export const FormSection = forwardRef<FormSectionProps>(
  ({ children, colSpan, columns, isHidden, title, ...props }, ref) => (
    <FormGridItem data-component={FormSection.displayName} colSpan={colSpan} isHidden={isHidden} ref={ref}>
      <FormGrid columns={columns} {...props}>
        {title && <FormSectionHeader colSpan={columns} title={title} />}
        {children}
      </FormGrid>
    </FormGridItem>
  )
);
FormSection.displayName = 'Form.Section';

export type FormSectionHeaderProps = Override<
  FormGridItemProps,
  { title: ReactNode; shouldDisplayRequiredFields?: boolean }
>;

export const FormSectionHeader = forwardRef<FormSectionHeaderProps, 'div', false>(
  ({ title, shouldDisplayRequiredFields = false, ...props }, ref) => (
    <FormGridItem data-component={FormSectionHeader.displayName} {...props} ref={ref}>
      <Text as="h3" textStyle="heading3Semi">
        {shouldDisplayRequiredFields && (
          <Text as="p" color="global.neutral.800" textStyle="body3">
            <FormattedMessage id="ar.domain.common.form.required.fields.info.text" />
          </Text>
        )}
        {title}
      </Text>
    </FormGridItem>
  )
);
FormSectionHeader.displayName = 'Form.SectionHeader';
