// eslint-disable-next-line import/no-deprecated
import { _createFormFieldInput, Search, SearchOption, SearchProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { useCustomers } from '../api-hooks';
import { useMelioIntl } from '../i18n';

export type CustomerSelectInputProps = Omit<SearchProps, 'options' | 'creatableOption' | 'emptyState'> & {
  onCreate?: (customerName: string) => void;
  onMissingRequiredData?: (customerId: string) => void;
  onClearCustomer?: () => void;
};

export const CustomerSelectInput = _createFormFieldInput(
  forwardRef<CustomerSelectInputProps, 'input'>(
    ({ onChange, onCreate, onMissingRequiredData, onClearCustomer, placeholder, ...props }, ref) => {
      const [selectOptions, setSelectOptions] = useState<SearchOption[]>([]);
      const [creatingPlaceHolder, setCreatingPlaceHolder] = useState<string | null>(null);
      const { formatMessage } = useMelioIntl();
      const { data: customers, isFetching, isMutating, create: createCustomer } = useCustomers();

      const { track } = useAnalytics();

      useEffect(() => {
        if (!isFetching && customers && customers.length > 0) {
          setSelectOptions(
            customers.map((customer) => ({
              label: customer.companyName,
              value: customer.id,
              avatarProps: { name: customer.fullName },
            }))
          );
        }
      }, [customers, isFetching]);

      const handleCreateOption = (inputValue: string) => {
        track('Customer', 'Click', {
          Cta: 'create-customer',
          CustomerName: inputValue,
        });
        // This is so we could display the name of the soon-to-be-created vendor as the placeholder.
        setCreatingPlaceHolder(inputValue);
        createCustomer({ companyName: inputValue, contactName: inputValue })
          .then((res) => {
            setSelectOptions((value) => [...value] as SearchOption[]);
            onChange?.({ target: { value: res.id } } as never);
            track('Customer', 'Status', {
              StatusType: 'success',
              Cta: 'create-customer',
              CustomerName: inputValue,
            });
          })
          .catch((error) => {
            track('Customer', 'Status', {
              StatusType: 'failure',
              Cta: 'create-customer',
              CustomerName: inputValue,
            });
            setCreatingPlaceHolder(null);
            throw error;
          });
      };

      return (
        <Search
          data-component="CustomerSelect"
          ref={ref}
          {...props}
          options={[
            {
              options: selectOptions,
              metadata: {
                label: formatMessage(
                  'ar.invoiceLifecycle.activities.createInvoice.inputs.customerName.dropdown.header.text'
                ),
              },
            },
          ]}
          onClear={onClearCustomer}
          onChange={(e) => {
            const customer = customers?.find((customer) => customer.id === e.target.value);
            if (customer && (!customer.email || !customer.phone || !customer.firstName || !customer.lastName)) {
              onMissingRequiredData?.(e.target.value);
            } else {
              onChange?.(e);
            }
          }}
          placeholder={isMutating && creatingPlaceHolder ? creatingPlaceHolder : placeholder}
          creatableOption={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.inputs.customerName.dropdown.buttons.addCustomer.label'
            ),
            onClick: onCreate ?? handleCreateOption,
            shouldDisplay: (value) => value !== '',
          }}
          emptyState={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.inputs.customerName.dropdown.emptyState.label'
            ),
          }}
          isLoading={isFetching || isMutating}
          shouldShowPresetOptions
        />
      );
    }
  )
);

CustomerSelectInput.displayName = 'CustomerSelectInput';
