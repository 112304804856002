import { SubscriptionBillingOptionFlow, useUpdateClientSubscriptionBillingOptions } from '../../../hooks';
import { SubscriptionBillingPayor } from '../../../types';
import { BillingPayorFlowBannerType } from '../types';

export const usePaymentMethodBannerType = ({
  clientOrgId,
  subscriptionBillingPayor,
}: {
  clientOrgId?: string;
  subscriptionBillingPayor: SubscriptionBillingPayor;
}) => {
  const {
    firmFundingSources,
    clientFundingSources,
    subscriptionBillingOptionFlow,
    firmSubscriptionBillingFundingSourceId,
  } = useUpdateClientSubscriptionBillingOptions(clientOrgId).data;

  const isFirmToClientWithFirmBillingMethod =
    firmSubscriptionBillingFundingSourceId ||
    subscriptionBillingPayor === SubscriptionBillingPayor.AccountingFirm ||
    (subscriptionBillingPayor === SubscriptionBillingPayor.Client && firmFundingSources.length);

  const bannerTypesMapping: Record<SubscriptionBillingOptionFlow, BillingPayorFlowBannerType> = {
    [SubscriptionBillingOptionFlow.Create]: isFirmToClientWithFirmBillingMethod
      ? BillingPayorFlowBannerType.ClientToFirmWithFirmHasBillingFundingSource
      : BillingPayorFlowBannerType.ClientToFirmWithFirmMissingBillingFundingSource,
    [SubscriptionBillingOptionFlow.FirmToClient]: clientFundingSources?.length
      ? BillingPayorFlowBannerType.FirmToClientWithClientFundingSources
      : BillingPayorFlowBannerType.FirmToClientWithClientMissingFundingSources,
    [SubscriptionBillingOptionFlow.ClientToFirm]: isFirmToClientWithFirmBillingMethod
      ? BillingPayorFlowBannerType.ClientToFirmWithFirmHasBillingFundingSource
      : BillingPayorFlowBannerType.ClientToFirmWithFirmMissingBillingFundingSource,
    [SubscriptionBillingOptionFlow.AnonymousToClient]: !subscriptionBillingPayor
      ? BillingPayorFlowBannerType.LimitedBillingOptions
      : clientFundingSources?.length
      ? BillingPayorFlowBannerType.FirmToClientWithClientFundingSources
      : BillingPayorFlowBannerType.FirmToClientWithClientMissingFundingSources,
  };
  return bannerTypesMapping[subscriptionBillingOptionFlow];
};
