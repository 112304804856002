import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

export const BatchPaymentsFundsDebitDateBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();

  return (
    <SectionBanner
      data-testid="funds-debit-date-section"
      title={formatMessage('widgets.batchPaymentsFundsDebitDateBanner.title')}
      description={formatMessage(`widgets.batchPaymentsFundsDebitDateBanner.description`, {
        link: (
          <Link
            data-testid="funds-debit-date-section-link"
            href={links?.whenPaymentArrive}
            label={formatMessage('widgets.batchPaymentsFundsDebitDateBanner.linkText')}
            newTab
          />
        ),
      })}
      variant="informative"
    />
  );
};
