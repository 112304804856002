import { DeliveryPreference, DeliveryPreferenceType, PaymentIntent } from '@melio/platform-api';
import { isAfter } from 'date-fns';

export const isDeliveryPreferenceTypeFast = (type: DeliveryPreferenceType): boolean =>
  type === DeliveryPreferenceType.ExpressCheck ||
  type === DeliveryPreferenceType.OvernightCheck ||
  type === DeliveryPreferenceType.ExpressDomesticWire ||
  type === DeliveryPreferenceType.ExpeditedAch ||
  type === DeliveryPreferenceType.Rtp;

export const isDeliveryPreferenceTypeCheck = (type: DeliveryPreferenceType): boolean =>
  type === DeliveryPreferenceType.Check ||
  type === DeliveryPreferenceType.ExpressCheck ||
  type === DeliveryPreferenceType.OvernightCheck;

export const getFastDeliveryPreference = (deliveryPreferences: DeliveryPreference[]) =>
  deliveryPreferences.find((option) => isDeliveryPreferenceTypeFast(option.type));

export const isFastCheck = (type: DeliveryPreferenceType): boolean =>
  isDeliveryPreferenceTypeCheck(type) && isDeliveryPreferenceTypeFast(type);

export const isDeliveryPreferenceTypeAch = (type: DeliveryPreferenceType): boolean =>
  type === DeliveryPreferenceType.ExpeditedAch || type === DeliveryPreferenceType.Ach;

export const getRegularDeliveryPreference = (deliveryPreferences: DeliveryPreference[]) =>
  deliveryPreferences.find((option) => !isDeliveryPreferenceTypeFast(option.type));

export const hasFastDeliveryPreferenceOption = (
  deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions']
): boolean => !!getFastDeliveryPreferenceOptionType(deliveryPreferencesOptions);

export const getFastDeliveryPreferenceOptionType = (
  deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions']
) => deliveryPreferencesOptions?.find((option) => isDeliveryPreferenceTypeFast(option.type))?.type;

export const isLateDeliveryDate = (deliveryPreference: DeliveryPreference, dueDate: Date) =>
  isAfter(deliveryPreference.minDeliveryDate, dueDate);
