import { SectionBanner, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { BannerIcon } from './BannerIcon';
import { PushFxForInternationalOrgsBannerProps } from './types';

export const PushFxForInternationalOrgsBanner = ({ iconSize, onClose }: PushFxForInternationalOrgsBannerProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const title = formatMessage('widgets.pushFxForInternationalOrgsBanner.title');
  const description = formatMessage('widgets.pushFxForInternationalOrgsBanner.description');

  const iconProps = iconSize === 'small' ? { width: '56px', height: '56px' } : {};

  return (
    <SectionBanner
      data-testid="push-fx-for-international-orgs-banner"
      variant="brand"
      title={title}
      description={description}
      onClose={onClose}
      hideIcon={isExtraSmallScreen}
      leftElement={isExtraSmallScreen ? undefined : <BannerIcon {...iconProps} />}
      showCloseIcon
    />
  );
};

PushFxForInternationalOrgsBanner.displayName = 'PushFxForInternationalOrgsBanner';
