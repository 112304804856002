import { useConfig } from '@melio/platform-provider';

export const useBatchPaymentsConfig = () => {
  const {
    settings: { batchPayments },
  } = useConfig();

  return {
    isBatchPaymentsEnabled: batchPayments.enabled,
  };
};
