import { useGuestPayorFundingSource, useGuestPayorPayment, useMonitoring } from '@melio/ar-domain';
import { useAnalyticsView } from '@melio/platform-analytics';
import { convertDateToStringRepresentation, forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useGuestPaymentIntent } from '../../../hooks';
import { GuestPaymentIntentParams } from '../../../types';
import {
  CreatingPaymentScreen,
  PayByBankReviewAndConfirmScreen,
  PayByBankReviewAndConfirmScreenProps,
} from './screens';

export type PayByBankReviewAndConfirmActivityProps = Pick<PayByBankReviewAndConfirmScreenProps, 'onViewInvoice'> & {
  fundingSourceId: string;
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onDone: (paymentId: string) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
  guestPaymentIntentId: string;
};

export const PayByBankReviewAndConfirmActivity = forwardRef<PayByBankReviewAndConfirmActivityProps>(
  (
    {
      guestPaymentIntentParams,
      onDone,
      onError,
      onClose,
      fundingSourceId,
      onViewInvoice,
      guestPaymentIntentId,
      ...props
    },
    ref
  ) => {
    const [paymentId, setPaymentId] = useState<string>();

    const {
      isLoading: isGuestPaymentIntentLoading,
      data: guestPaymentIntentData,
      refetch: refetchGuestPaymentIntentData,
    } = useGuestPaymentIntent({ guestPaymentIntentParams, onError });
    const fundingSourceModel = useGuestPayorFundingSource({ id: fundingSourceId });
    const paymentModel = useGuestPayorPayment({ id: paymentId, enabled: false, onError });

    useAnalyticsView('PaymentRequest', true, true, { Intent: 'pay-invoice' });

    const { startAction } = useMonitoring();

    const [isSaving, setIsSaving] = useState(false);

    const handleOnSubmit = async ({ scheduledDate }: { scheduledDate: Date }) => {
      try {
        setIsSaving(true);
        startAction('pay');
        const { paymentId } = await paymentModel.createPayment({
          fundingSourceId,
          scheduledDate: convertDateToStringRepresentation(scheduledDate),
          guestPaymentIntentId,
        });
        setPaymentId(paymentId);
        await Promise.all([refetchGuestPaymentIntentData(), paymentModel.refetch()]);
        onDone(paymentId);
      } catch (error) {
        onClose?.();
        onError?.(error as ARPlatformError);
      } finally {
        setIsSaving(false);
      }
    };

    if (isSaving) {
      return (
        <CreatingPaymentScreen
          backgroundColor={guestPaymentIntentData?.vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
          {...props}
          ref={ref}
        />
      );
    }

    return (
      <PayByBankReviewAndConfirmScreen
        isLoading={isGuestPaymentIntentLoading || fundingSourceModel.isLoading}
        guestPaymentIntentData={guestPaymentIntentData}
        onSubmit={(...args) => void handleOnSubmit(...args)}
        fundingSourceName={fundingSourceModel.data?.displayName}
        logo={fundingSourceModel.data?.logo}
        onViewInvoice={onViewInvoice}
        {...props}
        ref={ref}
      />
    );
  }
);
PayByBankReviewAndConfirmActivity.displayName = 'PayByBankReviewAndConfirmActivity';
