import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { useGuestPaymentIntent, useUpdatedInvoiceSystemMessage, useUserState } from '../../../hooks';
import { GuestPaymentIntentParams, GuestPayorUserNameAndEmailDetails } from '../../../types';
import { AddBankAccountDetailsScreen, AddBankAccountDetailsScreenProps } from './screens';

export type AddBankAccountDetailsActivityProps = Pick<
  AddBankAccountDetailsScreenProps,
  'onViewInvoice' | 'onSelectFundingSource' | 'isDisabled'
> & {
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (userDetails: GuestPayorUserNameAndEmailDetails) => void;
  onError?: ARErrorFunction;
  guestPaymentIntentParams: GuestPaymentIntentParams;
};

export const AddBankAccountDetailsActivity = withAnalyticsContext<AddBankAccountDetailsActivityProps>(
  forwardRef(
    (
      {
        onError,
        guestPaymentIntentParams,
        isNavigateApUsersToDashboardEnabled,
        isDisabled,
        onDone,
        setAnalyticsProperties,
        ...props
      },
      ref
    ) => {
      const [currentUserEmail, setCurrentUserEmail] = useState<string>();
      const { isLoading, data: guestPaymentIntentData } = useGuestPaymentIntent({
        guestPaymentIntentParams,
        onError,
      });
      const userState = useUserState({ guestPaymentIntentParams, email: currentUserEmail });
      const isPayable = !!guestPaymentIntentData?.isPayable;

      useUpdatedInvoiceSystemMessage(guestPaymentIntentParams);

      const { track } = useAnalytics();

      useEffect(() => {
        if (userState.userType) {
          track('Connection', 'Status', { UserType: userState.userType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [userState.userType]);

      setAnalyticsProperties({
        UserType: userState.userType,
        Email: currentUserEmail || undefined,
        PaymentMethodType: 'ach',
      });

      return (
        <AddBankAccountDetailsScreen
          guestPaymentIntentData={guestPaymentIntentData}
          onSubmit={onDone}
          isSaving={isDisabled}
          onEmailChanged={setCurrentUserEmail}
          isLoading={isLoading}
          isCheckingEmail={userState.isCheckingEmail}
          signInUrl={
            (isNavigateApUsersToDashboardEnabled && isPayable && userState.isApUser && userState.signInUrl) || undefined
          }
          isPayable={isPayable}
          {...props}
          ref={ref}
        />
      );
    }
  )
);
AddBankAccountDetailsActivity.displayName = 'AddBankAccountDetailsActivity';
