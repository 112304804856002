import { useReceivablePayments, useSearchParams } from '@melio/ar-domain';
import { isArray, isNil } from 'lodash';
import { useMemo } from 'react';

import { FilterParams, QueryParams } from '../types';

export const usePaymentsFiltering = () => {
  const [searchParams, setSearchParams] = useSearchParams<QueryParams>();

  const {
    searchTerm,
    creationDateRange,
    creationDate,
    receivablePaymentStatus: _receivablePaymentStatus,
    sortBy = 'creationDate',
    sortOrder = 'Desc',
    pageNumber,
  } = searchParams;

  const receivablePaymentStatus = useMemo(() => {
    const currentValue = _receivablePaymentStatus;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return isArray(currentValue) || !currentValue ? currentValue : [currentValue];
  }, [_receivablePaymentStatus]);

  const filters: FilterParams = {
    ...(receivablePaymentStatus && { receivablePaymentStatus }),
    ...(creationDateRange && { creationDateRange }),
    ...(creationDate && { creationDate }),
  };

  const params = useMemo(
    () => ({
      searchTerm,
      receivablePaymentStatus,
      creationDateRange,
      sort: { field: sortBy, order: sortOrder },
    }),
    [searchTerm, receivablePaymentStatus, creationDateRange, sortBy, sortOrder]
  );

  const {
    data: payments,
    isLoading,
    isFetching,
    error,
    pagination,
  } = useReceivablePayments({
    params,
    pageNumber,
    onPageChange: (newPage) => {
      setSearchParams((prev) => ({ ...prev, pageNumber: newPage }));
    },
  });

  const setParams = (params: QueryParams) => {
    const { searchTerm, ...rest } = params;
    if (Object.keys(rest).length > 0) {
      setSearchParams((prev) => ({
        ...prev,
        receivablePaymentStatus: rest.receivablePaymentStatus || prev.receivablePaymentStatus,
        creationDateRange: rest.creationDateRange || prev.creationDateRange,
        creationDate: rest.creationDate || prev.creationDate,
        sortBy: rest.sortBy || prev.sortBy,
        sortOrder: rest.sortOrder || prev.sortOrder,
      }));
    }

    if (!isNil(searchTerm) && searchTerm != searchParams.searchTerm) {
      setSearchParams((prev) => ({
        ...prev,
        searchTerm,
      }));
    }
  };

  const clearFilterParams = () => {
    setSearchParams(({ receivablePaymentStatus, creationDateRange, creationDate, ...rest }) => rest);
  };

  const clearAllParams = () => {
    setSearchParams({});
  };

  const setSearch = (searchTerm?: string) => {
    setParams({ searchTerm });
  };

  return {
    payments: payments ?? [],
    pagination,
    isLoading,
    isFetching,
    error,
    filters,
    searchTerm: searchParams.searchTerm || '',
    setSearch,
    setParams,
    clearFilterParams,
    clearAllParams,
    sortParams: { sortBy, sortOrder },
  };
};
