import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebouncedObject } from '@melio/platform-utils';
import { useCallback, useEffect, useMemo } from 'react';

import { isBank, isContainsEmail, isPartialName, isWhitelist } from './utils';

type Props = {
  isFormSubmitted: boolean;
  isFreeTextInput: boolean;
  vendorName?: string;
  isManaged?: boolean;
  vendorNameFieldErrorType?: string;
};

enum ConstraintType {
  Bank = 'bank',
  Email = 'not-contains-email',
  SingleWord = 'single-word',
}

export const useVendorNameConstraints = ({
  isFormSubmitted,
  isFreeTextInput,
  vendorName,
  isManaged,
  vendorNameFieldErrorType,
}: Props) => {
  const [vendorNameConstraintsEnabled] = useDevFeature(FeatureFlags.PlatformVendorNameConstraints, false);
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const shouldSkip = !vendorName || isManaged || vendorNameFieldErrorType || isWhitelist(vendorName);

  const constraintTypeBannerDescriptions: Record<string, string> = {
    [ConstraintType.Bank]: formatMessage('form.vendorSelect.constraints.bank'),
    [ConstraintType.SingleWord]: formatMessage('form.vendorSelect.constraints.singleWord'),
  };

  const vendorNameConstraintType = useMemo(() => {
    if (shouldSkip) {
      return undefined;
    }

    if (isBank(vendorName)) {
      return ConstraintType.Bank;
    }

    if (isContainsEmail(vendorName)) {
      return ConstraintType.Email;
    }

    if (isPartialName(vendorName)) {
      return ConstraintType.SingleWord;
    }

    return undefined;
  }, [shouldSkip, vendorName]);

  const trackConstraintEvent = useCallback(
    ({ constraintType, searchValue }: { constraintType?: string; searchValue?: string }) => {
      track('Vendor', 'View', {
        VendorType: 'local',
        Feature: {
          name: FeatureFlags.PlatformVendorNameConstraints,
          type: constraintType,
          displayed: vendorNameConstraintsEnabled,
        },
        SearchValue: searchValue,
      });
    },
    [vendorNameConstraintsEnabled, track]
  );

  const currentConstraintType = vendorNameFieldErrorType || vendorNameConstraintType;
  // Using short debounce to avoid multiple track-events (when dropdown mode) or longer debounce in order to avoid incorrect banners as long as the user is typing (when free text input mode)
  const debouncedValues = useDebouncedObject(
    {
      constraintType:
        currentConstraintType === ConstraintType.Email && !isFormSubmitted ? undefined : currentConstraintType,
      searchValue: vendorName?.trim(),
    },
    isFreeTextInput ? 500 : 20
  );

  useEffect(() => {
    if (debouncedValues.constraintType) {
      trackConstraintEvent(debouncedValues);
    }
  }, [debouncedValues, trackConstraintEvent]);

  const { constraintType } = debouncedValues;

  const vendorNameBannerDescription =
    vendorNameConstraintsEnabled && constraintType ? constraintTypeBannerDescriptions[constraintType] : undefined;

  return {
    vendorNameBannerDescription,
    vendorNameConstraintType: constraintType || '',
  };
};
