import { StatusIconSolid } from '@melio/penny';
import { TodoPaymentsToApprove, TodoPaymentsToApproveTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { isEmptySection } from '../utils';
import { TodoSection } from './TodoSection';

export type TodoSectionPaymentsToApproveProps = {
  todo?: TodoPaymentsToApprove;
  clientOrganizationId: string;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodoSectionPaymentsToApprove = ({
  clientOrganizationId,
  todo,
  onSwitchOrganization,
}: TodoSectionPaymentsToApproveProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToPayDashboardApprovalsTab } = useAccountantsRoutes();

  if (isEmptySection(todo)) {
    return null;
  }

  const totalItems = todo?.items.pagination.totalCount || 0;

  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.paymentsToApprove.singular.title', {
          vendorName: todo?.items.data[0]?.vendor?.name ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.paymentsToApprove.plural.title', {
          count: totalItems,
        });

  const action = {
    label: 'view',
    onClick: async () => {
      await onSwitchOrganization({
        organizationId: clientOrganizationId,
        keepLoadingState: true,
        switchAccessTokenOnly: true,
      });
      goToPayDashboardApprovalsTab({ refresh: true, id: totalItems === 1 ? todo?.items.data[0]?.id : undefined });
    },
  };

  return (
    <TodoSection
      key={`section-${TodoPaymentsToApproveTypeEnum.PaymentsToApprove}`}
      icon={<StatusIconSolid variant="warning" size="medium" />}
      title={title}
      action={action}
      type={TodoPaymentsToApproveTypeEnum.PaymentsToApprove}
    />
  );
};
