import { Box } from '@chakra-ui/react';
import { FloatingMenu, Text } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

type ActionProp = {
  onClick: VoidFunction;
  isDisabled?: boolean;
  dataTestId: string;
};

export const DrawerActions = ({
  client,
  editClient,
  toggleHidden,
}: {
  client?: AccountingFirmClientExpanded;
  editClient?: ActionProp;
  toggleHidden?: ActionProp;
}) => {
  const { formatMessage } = useMelioIntl();

  return (
    <FloatingMenu.DropdownList data-testid="client-drawer-action-items">
      <FloatingMenu.Item disabled={{ isDisabled: !!editClient?.isDisabled }} onClick={() => void editClient?.onClick()}>
        <Box minWidth="168px">
          <Text textStyle="body3Semi">
            {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.editClient')}
          </Text>
        </Box>
      </FloatingMenu.Item>
      <FloatingMenu.Item onClick={() => void toggleHidden?.onClick()}>
        <Box minWidth="168px">
          <Text textStyle="body3Semi">
            {client?.organizationInfo.isHidden
              ? formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.unHideClient')
              : formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.hideClient')}
          </Text>
        </Box>
      </FloatingMenu.Item>
    </FloatingMenu.DropdownList>
  );
};
