import { useIsMobile } from '@melio/ar-domain';
import { Group, LoadingContainer } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { noop } from 'lodash';

import { PayoutsTable, PayoutsToolBar } from '../components';
import { Payout } from '../mocks';

export type PaymentsTableScreenProps = {
  payouts: Payout[];
  isLoading?: boolean;
  isFetching?: boolean;
  onError?: ARErrorFunction;
  onViewInvoice?: (invoiceId: string) => void;
};

export const PayoutsTabScreen = forwardRef<PaymentsTableScreenProps>(
  ({ isLoading, isFetching, payouts, onViewInvoice }, ref) => {
    const isMobile = useIsMobile();
    //TODO: need to get the current totalFundsHeld per organization.
    const totalFundsHeld = payouts[0]?.totalFundsHeld || 0;
    return (
      <LoadingContainer isLoading={isLoading}>
        <Group
          width="full"
          variant="vertical"
          spacing={isMobile ? 's' : 'm'}
          data-component={PayoutsTabScreen.displayName}
          ref={ref}
        >
          <PayoutsToolBar totalFundsHeld={totalFundsHeld} onExportPayouts={noop} />
          <PayoutsTable isLoading={isFetching} payouts={payouts} onViewInvoice={onViewInvoice} ref={ref} />
        </Group>
      </LoadingContainer>
    );
  }
);

PayoutsTabScreen.displayName = 'PayoutsTabScreen';
