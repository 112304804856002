/* eslint-disable no-restricted-imports */

import { Group, Text } from '@melio/penny';
import { AccountingFirmClientExpanded, AccountingPlatform } from '@melio/platform-api-axios-client';
import { Collapse } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';

export const CompanyDescription = ({
  dataTestId,
  client,
  accountingPlatform,
  expanded,
}: {
  dataTestId?: string;
  client: AccountingFirmClientExpanded;
  accountingPlatform?: AccountingPlatform;
  expanded?: boolean;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const formatLastSyncDate = (date: Date) =>
    `${formatDate(date, { dateStyle: 'long' })}, ${formatDate(date, { timeStyle: 'short' })}`;

  return (
    <Group data-testid={dataTestId} variant="vertical" justifyContent="center" spacing="s">
      <Group.Item>
        <Group variant="vertical" spacing="none">
          <Text textStyle="body2Semi">{client.organizationInfo.companyName}</Text>
          {accountingPlatform?.lastCompletionTime && (
            <Text textStyle="body3" color="semantic.text.secondary">
              {formatMessage('widgets.syncNow.initial.tooltip.label', {
                lastSyncDate: accountingPlatform.lastCompletionTime
                  ? formatLastSyncDate(accountingPlatform.lastCompletionTime)
                  : '',
              })}
            </Text>
          )}
        </Group>
      </Group.Item>
      <Collapse animateOpacity endingHeight="auto" startingHeight="0" in={expanded}>
        <Group.Item>
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body3" color="semantic.text.secondary" data-testid="drawer-client-name">
              {client.contactInfo.name}
            </Text>
            <Text textStyle="body3" color="semantic.text.secondary" data-testid="drawer-client-email">
              {client.contactInfo.email}
            </Text>
            <Text textStyle="body3" color="semantic.text.secondary" data-testid="drawer-client-phone">
              {client.contactInfo.phone}
            </Text>
          </Group>
        </Group.Item>
      </Collapse>
    </Group>
  );
};
