import { Link, Tourtip, TourtipStep, TourtipSteps, useBreakpoint } from '@melio/penny';
import { useUserPreference } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { compact } from 'lodash';
import { useCallback, useEffect } from 'react';

import { createTestIdSelector } from '../../../../../utils';
import { usePayDashboardTabsToShow } from '../../../hooks/usePayDashboardTabsToShow';
import { PayDashboardTabs } from '../../../types';
import { useMultiAccountTourtip } from './useMultiAccountTourtip';

type TooltipInfo = TourtipStep & {
  onDismiss: () => void;
};

type Props = {
  activeTab?: string;
};

const convertToStep = ({ onDismiss, ...rest }: TooltipInfo): TourtipStep => rest;

export const NewFeaturesTour = (props: Props) => {
  const { activeTab } = props;

  const toolTipsItems = useGetNewFeaturesTourSteps(activeTab);

  const handleClose = useCallback(() => {
    toolTipsItems.forEach((item) => item.onDismiss());
  }, [toolTipsItems]);

  if (!toolTipsItems || toolTipsItems.length == 0) {
    return null;
  }

  const steps = toolTipsItems.map((item) => convertToStep(item));

  return (
    <Tourtip
      data-testid="tabs-tooltips"
      steps={steps as TourtipSteps}
      onTourDismiss={handleClose}
      onTourComplete={handleClose}
      defaultLabels={{
        nextButtonLabel: 'Got it',
        prevButtonLabel: '',
      }}
    />
  );
};

const financingTooltipPreferenceKey = 'isDismissedFinancingTooltip';

const useFinancingTooltip = (activeTab?: string) => {
  const { create } = useUserPreference({ enabled: false });
  const { shouldShow: tabsToShow } = usePayDashboardTabsToShow();

  const [isFinancingToolTipEnabledFF] = useDevFeature(FeatureFlags.FinancingTabToolTipEnabled, false);

  const { data: _isDismissedFinancingTooltip } = useUserPreference({
    id: financingTooltipPreferenceKey,
  });

  const isDismissedFinancingTooltip = _isDismissedFinancingTooltip
    ? _isDismissedFinancingTooltip.value === 'true'
    : true;

  const shouldShowFinancingTooltip =
    isFinancingToolTipEnabledFF && !isDismissedFinancingTooltip && tabsToShow[PayDashboardTabs.Financing];

  const handleTooltipDismiss = useCallback(() => {
    create({ userPreference: { key: financingTooltipPreferenceKey, value: 'true' } });
  }, [create]);

  useEffect(() => {
    if (activeTab === PayDashboardTabs.Financing && shouldShowFinancingTooltip) {
      handleTooltipDismiss();
    }
  }, [activeTab, handleTooltipDismiss, shouldShowFinancingTooltip]);

  return { shouldShow: shouldShowFinancingTooltip, handleDismiss: handleTooltipDismiss };
};

const useGetNewFeaturesTourSteps = (activeTab?: string): TooltipInfo[] => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const financialTooltip = useFinancingTooltip();
  const multiAccountTooltip = useMultiAccountTourtip();
  const { links } = useConfig();

  return compact([
    multiAccountTooltip.shouldShow && {
      target: createTestIdSelector('select-company-btn'),
      title: formatMessage('activities.payDashboard.multiOrgTour.title'),
      nextButtonLabel: formatMessage('activities.payDashboard.multiOrgTour.nextButtonLabel'),
      prevButtonLabel: '',
      description: formatMessage('activities.payDashboard.multiOrgTour.description', {
        link: (
          <Link
            label={formatMessage('activities.payDashboard.multiOrgTour.learnMore')}
            href={links['activities.multiAccountBanner.cta.href']}
            color="inherit"
            newTab
          />
        ),
      }),
      placement: 'bottom-end',
      onDismiss: multiAccountTooltip.handleDismiss,
    },
    financialTooltip.shouldShow &&
      activeTab !== PayDashboardTabs.Financing && {
        target: createTestIdSelector('pay-dashboard-activity-pay-dashboard-tabs-list-tab-financing'),
        title: formatMessage('activities.payDashboard.tabs.financing.tooltip.title'),
        nextButtonLabel: 'Got it',
        prevButtonLabel: '',
        description: formatMessage('activities.payDashboard.tabs.financing.tooltip.description'),
        placement: isExtraSmallScreen ? 'bottom-start' : 'bottom-end',
        onDismiss: financialTooltip.handleDismiss,
      },
  ]);
};
