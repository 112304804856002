import { useMelioQueryClient } from '@melio/api-client';
import { Container, Group, Illustration, Text } from '@melio/penny';
import { Button } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';

export const ClientDrawerError = () => {
  const { formatMessage } = useMelioIntl();
  const queryClient = useMelioQueryClient();

  return (
    <Container maxWidth="462" textAlign="center" data-testid="client-drawer-error-state" paddingY="s">
      <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
        <Illustration type="network-error" size="small" />
        <Text textStyle="heading3Semi">
          {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.errorState.header')}
        </Text>
        <Text textStyle="body3">
          {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.errorState.description')}
        </Text>
        <Button
          data-testid="try-again-button"
          size="medium"
          variant="tertiary"
          label={formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.errorState.tryAgainButton.label')}
          onClick={() => {
            void queryClient.invalidateQueries('AccountingFirmClientApi');
          }}
        />
      </Group>
    </Container>
  );
};
