import type { PillProps, RadioOption, TooltipProps } from '@melio/penny';
import { DeliveryPreference, DeliveryPreferenceType, UnsupportedDeliveryPreferenceReason } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { isEqual } from 'lodash';

import { isDeliveryPreferenceTypeCheck, isDeliveryPreferenceTypeFast } from '../../functions';
import { useRelativeDateText } from '../date/useRelativeDateText';
import { useGetCapOrMinFeeDescription } from '../fees/fees';
import { usePaymentSchedulingPreference } from '../payments';

type BadgeProps = Pick<PillProps, 'status' | 'label' | 'data-testid'>;

const useDeliveryPreferenceMessages = () => {
  const { formatMessage, formatCurrency, formatDateTimeRange, formatDate } = useMelioIntl();
  const { formatRelativeDate } = useRelativeDateText();
  const { isByDeliveryDate, isByScheduleDate } = usePaymentSchedulingPreference();
  const [eoyCheckEnabled] = usePartnerFeature('eoyCheck', false);
  const { getCapOrMinFeeDescription } = useGetCapOrMinFeeDescription();

  const getLabelTextByScheduleDate = (
    type: DeliveryPreferenceType,
    totalBusinessDays: number,
    totalMaxBusinessDays: number
  ) => {
    const showDateRange = !isEqual(totalBusinessDays, totalMaxBusinessDays);
    const isSameDayDelivery = totalMaxBusinessDays === 0;

    if (showDateRange) {
      return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.range.label', {
        totalBusinessDays,
        totalMaxBusinessDays,
      });
    }

    if (isSameDayDelivery) {
      switch (type) {
        case DeliveryPreferenceType.Rtp:
          return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.sameDay.label.rtp');
        case DeliveryPreferenceType.ExpressDomesticWire:
          return formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.sameDay.label.express-domestic-wire'
          );
        case DeliveryPreferenceType.ExpeditedAch:
          return formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.sameDay.label.expedited-ach'
          );
        default:
          return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.sameDay.label');
      }
    }

    return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.byScheduleDate.single.label', {
      totalBusinessDays,
    });
  };

  const getLabelTextByDeliveryDate = (
    type: DeliveryPreferenceType,
    deliveryDate: string,
    totalBusinessDays: number
  ) => {
    switch (type) {
      case DeliveryPreferenceType.Rtp:
        return formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.label.rtp`);
      case DeliveryPreferenceType.ExpressDomesticWire:
        return formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.label.express-domestic-wire`, {
          totalBusinessDays,
        });
      default:
        return formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.label`, {
          date: deliveryDate,
        });
    }
  };

  const getMainLabelTooltip = (deliveryPreference: DeliveryPreference): TooltipProps | undefined => {
    const disabled = getDisabled(deliveryPreference);

    if (disabled?.isDisabled && disabled.message) {
      return { content: disabled.message };
    }

    const { type, fee } = deliveryPreference;

    if (eoyCheckEnabled && type === 'check') {
      return {
        content: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.check.tooltipEOYText`),
      };
    }

    if (fee) {
      const capOrMinHelperDescription = getCapOrMinFeeDescription(fee.type, fee.amount);
      if (capOrMinHelperDescription) {
        return {
          content: capOrMinHelperDescription,
        };
      }
    }

    return;
  };

  const getDescription = ({
    type,
    minDeliveryDate,
    maxDeliveryDate,
    effectiveScheduleDate,
    fee,
  }: DeliveryPreference): RadioOption['descriptionProps'] => {
    const deliveryDates = formatDateTimeRange(minDeliveryDate, maxDeliveryDate, { dateStyle: 'medium' });
    const debitDate = formatDate(effectiveScheduleDate, { dateStyle: 'medium' });

    if (isByScheduleDate) {
      if (fee?.amount) {
        return {
          label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.descriptionWithFee`, {
            fee: formatCurrency(fee.amount),
            date: deliveryDates,
          }),
        };
      } else {
        return {
          label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.description`, {
            date: deliveryDates,
          }),
        };
      }
    } else {
      if (fee?.amount) {
        return {
          label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.descriptionWithFee`, {
            fee: formatCurrency(fee.amount),
            date: debitDate,
          }),
        };
      } else {
        return {
          label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.description`, {
            date: debitDate,
          }),
        };
      }
    }
  };

  const getMainLabel = (
    deliveryPreference: DeliveryPreference,
    shouldHaveBadge: boolean
  ): NonNullable<RadioOption['mainLabelProps']> => {
    const { type, minDeliveryDate, totalBusinessDays, totalMaxBusinessDays } = deliveryPreference;
    const badgeLabel = formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${type}.badge`);
    const badgeProps: BadgeProps | undefined =
      badgeLabel && shouldHaveBadge
        ? { status: 'brand', label: badgeLabel, 'data-testid': `fast-badge-${type}` }
        : undefined;

    const deliveryDate = formatRelativeDate({
      date: minDeliveryDate,
      options: {
        weekday: 'short',
        month: 'short',
        year: 'numeric',
        day: 'numeric',
      },
    });

    const tooltipProps = getMainLabelTooltip(deliveryPreference);
    const isCheck = isDeliveryPreferenceTypeCheck(type);
    const isFast = isDeliveryPreferenceTypeFast(type);
    const shouldShowSecondaryLabel = isByDeliveryDate && isCheck;

    const label = isByScheduleDate
      ? getLabelTextByScheduleDate(type, totalBusinessDays, totalMaxBusinessDays)
      : getLabelTextByDeliveryDate(type, deliveryDate, totalBusinessDays);

    const secondaryLabel = formatMessage(
      isFast
        ? `widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.secondary.expressCheck`
        : `widgets.deliveryDate.deliveryPreferenceForm.byDeliveryDate.secondary.check`,
      { date: deliveryDate }
    );
    return {
      label,
      pillProps: badgeProps,
      tooltipProps,
      ...(shouldShowSecondaryLabel ? { secondaryLabel } : {}),
    };
  };

  const getDisabled = ({ supported, unsupportedReason }: DeliveryPreference): RadioOption['disabled'] | null => {
    if (supported) {
      return null;
    }

    switch (unsupportedReason) {
      case UnsupportedDeliveryPreferenceReason.NoFutureDateScheduling: {
        return {
          isDisabled: true,
          message: formatMessage('widgets.deliveryDate.deliveryPreferenceForm.disabledMessage.noFutureDateScheduling'),
        };
      }
      case UnsupportedDeliveryPreferenceReason.OutsideManualReviewHours: {
        return {
          isDisabled: true,
          message: formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.disabledMessage.outsideManualReviewHours'
          ),
        };
      }
      case UnsupportedDeliveryPreferenceReason.RoutingNumberNotCovered: {
        return {
          isDisabled: true,
          message: formatMessage(
            'widgets.deliveryDate.deliveryPreferenceForm.disabledMessage.rtp.routingNumberNotCovered'
          ),
        };
      }
      case UnsupportedDeliveryPreferenceReason.PaymentRequiresApproval: {
        return {
          isDisabled: true,
          message: formatMessage('widgets.deliveryDate.deliveryPreferenceForm.disabledMessage.paymentRequiresApproval'),
        };
      }
    }

    return null;
  };

  return {
    getDescription,
    getMainLabel,
    getDisabled,
  };
};

export const useDeliveryPreferenceOption = () => {
  const { getDescription, getMainLabel, getDisabled } = useDeliveryPreferenceMessages();

  const getOption = (deliveryPreference: DeliveryPreference, fastestOption?: DeliveryPreference) => {
    const isDisabled = getDisabled(deliveryPreference)?.isDisabled;
    const shouldHaveBadge = !!(fastestOption && deliveryPreference.type === fastestOption.type);

    const option: RadioOption = {
      value: deliveryPreference.type,
      mainLabelProps: getMainLabel(deliveryPreference, shouldHaveBadge),
      descriptionProps: getDescription(deliveryPreference),
      ...(isDisabled ? { disabled: { isDisabled: true } } : undefined),
    };

    return option;
  };

  return { getOption };
};
