import { forwardRef } from '@melio/platform-utils';

import { useGuestPaymentIntent } from '../../hooks';
import { GuestPaymentIntentParams } from '../../types';
import { AddPaymentDetailsScreen } from './screens';

type AddPaymentDetailsActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
};

export const AddPaymentDetailsActivity = forwardRef<AddPaymentDetailsActivityProps>(
  ({ guestPaymentIntentParams }, ref) => {
    const { isLoading, data } = useGuestPaymentIntent({ guestPaymentIntentParams });

    return (
      <AddPaymentDetailsScreen
        isLoading={isLoading}
        vendorPaymentDetails={data?.vendorPaymentDetails}
        onSubmit={() => null}
        ref={ref}
      />
    );
  }
);

AddPaymentDetailsActivity.displayName = 'AddPaymentDetailsActivity';
