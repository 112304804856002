import { useDisclosure } from '@chakra-ui/react';
import { BankAccountFormModel, MidConfidenceMatchingModal } from '@melio/ap-widgets';
import { ApiError } from '@melio/api-client';
import { Traits, useAnalytics } from '@melio/platform-analytics';
import { BankAccountDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import {
  useSubmitVendorRecommendationDecision,
  useVendorRecommendationsById,
} from '@melio/platform-api/src/entities-v2/vendor-recommendations';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { DeliveryMethodsFailureCodeErrors } from '../types';
import { AddVendorBankDetailsScreen } from './screens';
import { AddVendorBankDetailsActivityProps, SubmissionTargetType } from './types';

export const AddVendorBankDetailsActivity = ({
  vendorId,
  onBack: onFirstStepBack,
  onClose,
  onError,
  onDone,
  origin = 'payment',
  bottomContent,
  defaultValues,
}: AddVendorBankDetailsActivityProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const collection = useDeliveryMethods({ vendorId, enabled: false });
  const [submitData, setSubmitData] = useState<{ data?: BankAccountDeliveryMethod; target?: SubmissionTargetType }>({});
  const [isMidConfidenceEnabled] = useDevFeature(FeatureFlags.MidConfidence, false, { shouldTrack: true });

  const vendorModel = useVendor({ id: vendorId });
  const vendorName = vendorModel.data?.name || '';
  const {
    isOpen: isShowVendorMidConfidenceModal,
    onOpen: onShowVendorMidConfidenceModal,
    onClose: onCloseVendorMidConfidenceModal,
  } = useDisclosure();
  const { showMessage } = useSystemMessage();

  const {
    data: recommendedVendor,
    mutateAsync: getVendorRecommendation,
    isMutating: isFetchingRecommendedVendor,
  } = useVendorRecommendationsById({});

  const { mutateAsync: submitVendorRecommendationDecision, isMutating: isSubmittingVendorRecommendationDecision } =
    useSubmitVendorRecommendationDecision({});

  const handleOnDone = (data?: BankAccountDeliveryMethod, target?: SubmissionTargetType) => {
    if (data) {
      onDone(data, target);
    }
  };
  const onSubmitVendorRecommendationDecision = async (
    decision: 'accepted' | 'rejected' | 'inconclusive',
    cta: string
  ) => {
    if (recommendedVendor?.recommendationId) {
      try {
        await submitVendorRecommendationDecision({
          recommendationId: recommendedVendor?.recommendationId,
          decision,
        });
        track('MidConfidenceModal', 'Status', {
          Flow: 'add-vendor-bank-details',
          Intent: 'submit-vendor-recommendation-decision',
          PageName: 'add-vendor-bank-details',
          Status: 'success',
          Cta: `${cta}-${decision}`,
        });

        if (decision === 'accepted') {
          showMessage({
            type: 'success',
            title: formatMessage('widgets.midConfidenceMatchingModal.successToast', {
              vendorName: recommendedVendor?.companyName || '',
            }),
            dataTestId: 'vendor-recommendation-success',
          });
        }
      } catch (error) {
        track('MidConfidenceModal', 'Status', {
          Flow: 'add-vendor-bank-details',
          Intent: 'submit-vendor-recommendation-decision',
          PageName: 'add-vendor-bank-details',
          Status: 'failure',
          Cta: `${cta}-${decision}`,
        });
      } finally {
        onCloseVendorMidConfidenceModal();
        handleOnDone(submitData.data, submitData.target);
      }
    } else {
      onCloseVendorMidConfidenceModal();
      handleOnDone(submitData.data, submitData.target);
    }
  };
  const handleOnCloseVendorMidConfidenceModal = async () => {
    await onSubmitVendorRecommendationDecision('inconclusive', 'close');
  };

  const handleOnConfirmRecommendedVendor = async () => {
    await onSubmitVendorRecommendationDecision('accepted', 'confirm');
  };

  const handleOnDeclineRecommendedVendor = async () => {
    await onSubmitVendorRecommendationDecision('rejected', 'decline');
  };
  const handleFail = (error: PlatformError) => {
    const errorCode = (error as ApiError).errorCode;
    const message =
      errorCode === DeliveryMethodsFailureCodeErrors.InvalidRouteNumber
        ? formatMessage('activities.addVendorBankDetails.errorRoutingFormat')
        : error.message;
    showMessage({ type: 'error', title: message });
    onError?.(error);
  };

  const { createTrackHandler, setTraits } = useAnalytics();
  const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>('AddVendorBankDetails', 'Saved');
  const setDeliveryMethodTraits = () => {
    const traits: Traits = { added_delivery_method: true };

    setTraits(traits);
  };

  const createDeliveryMethod = (data: BankAccountFormModel) =>
    collection.create({ type: 'bank-account', details: { ...data, accountType: 'checking' } });

  const createDeliveryMethodAndSubmitTrack = (data: BankAccountFormModel, target?: SubmissionTargetType) => {
    if (isMidConfidenceEnabled) {
      Promise.allSettled([
        getVendorRecommendation({ vendorId, bankAccount: { ...data } }),
        createDeliveryMethod(data),
      ]).then(([recommendationResult, deliveryMethodResult]) => {
        // Check if delivery method creation succeeded
        if (deliveryMethodResult.status === 'rejected') {
          submitHandler({ Status: 'failed' }, () => handleFail(deliveryMethodResult.reason));
          return;
        }

        // Delivery method creation was successful
        const deliveryMethod = deliveryMethodResult.value as BankAccountDeliveryMethod;
        submitHandler({ Status: 'succeeded' });
        setDeliveryMethodTraits();

        // Check if recommendation was successful and has recommendation ID
        if (recommendationResult.status === 'fulfilled' && recommendationResult.value?.recommendationId) {
          setSubmitData({ data: deliveryMethod, target });
          onShowVendorMidConfidenceModal();
        } else {
          // Either recommendation failed or no recommendation found, proceed normally
          handleOnDone(deliveryMethod, target);
        }
      });
    } else {
      createDeliveryMethod(data)
        .then((data) => {
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();
          handleOnDone(data as BankAccountDeliveryMethod, target);
        })
        .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
    }
  };

  return (
    <>
      <AddVendorBankDetailsScreen
        onBack={onFirstStepBack}
        onClose={onClose}
        onDone={(data, _event, target) => createDeliveryMethodAndSubmitTrack(data, target)}
        isSaving={collection.isMutating || isFetchingRecommendedVendor || isSubmittingVendorRecommendationDecision}
        origin={origin}
        vendorName={vendorName}
        bottomContent={bottomContent}
        defaultValues={defaultValues}
      />
      {isMidConfidenceEnabled &&
      vendorModel.data &&
      recommendedVendor?.recommendationId &&
      !isFetchingRecommendedVendor ? (
        <MidConfidenceMatchingModal
          isOpen={isShowVendorMidConfidenceModal}
          onClose={handleOnCloseVendorMidConfidenceModal}
          onConfirm={handleOnConfirmRecommendedVendor}
          onDecline={handleOnDeclineRecommendedVendor}
          localVendor={vendorModel.data}
          externalVendor={recommendedVendor}
          isSubmittingVendorRecommendationDecision={isSubmittingVendorRecommendationDecision}
          analyticsFields={{
            Flow: origin === 'vendor' ? 'add-vendor' : 'bill-flow',
            PageName: 'vendor-match-found',
            EntryPoint: 'choose-delivery-method',
            localVendorId: vendorId,
          }}
        />
      ) : null}
    </>
  );
};

AddVendorBankDetailsActivity.displayName = 'AddVendorBankDetailsActivity';
