// eslint-disable-next-line import/no-deprecated
import {
  addWildcardToRoutes,
  RouteElement,
  useCurrentRoute,
  useFlowRouting,
  useSystemMessage,
  withMemoryRouter,
  withOutlet,
} from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AddBankAccountDetailsActivity,
  AddBankAccountDetailsActivityProps,
  AddPlaidAccountModalActivity,
  AuthenticationModalActivity,
  OnboardingActivity,
  PayByBankReviewAndConfirmActivity,
} from '../activities';
import { useGetPaymentErrorNotification } from '../hooks';
import { GuestPaymentIntentParams, GuestPayorUserNameAndEmailDetails } from '../types';

type BankPaymentFulfillmentFlowProps = Pick<
  AddBankAccountDetailsActivityProps,
  'onSelectFundingSource' | 'onViewInvoice'
> & {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (paymentId: string) => unknown;
  onError?: ARErrorFunction;
  partnerName: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
};

export const BankPaymentFulfillmentFlow = withMemoryRouter(
  forwardRef<BankPaymentFulfillmentFlowProps>(
    (
      {
        onError,
        guestPaymentIntentParams,
        isNavigateApUsersToDashboardEnabled,
        onLoggedIn,
        onDone,
        partnerName,
        onViewInvoice,
        onSelectFundingSource,
        ...props
      },
      ref
    ) => {
      const navigation = useFlowNavigation();
      const [isPlaidLoading, plaidLoading] = useBoolean();
      const [guestPaymentIntentId, setGuestPaymentIntentId] = useState<string>();
      const [userDetails, setUserDetails] = useState<GuestPayorUserNameAndEmailDetails>();

      const { track } = useAnalytics();
      const getPaymentErrorNotification = useGetPaymentErrorNotification();
      const { triggerMessage } = useSystemMessage();
      const errorHandler = (error: ARPlatformError) => {
        track('PaymentRequest', 'Status', { ErrorType: 'payment-failed' });
        triggerMessage({ type: 'critical', title: getPaymentErrorNotification('bank').description });
        onError?.(error);
      };

      const authModalCloseHandler = useCallback(
        () => navigation.goToForm({ replace: true, keepSystemMessage: true }),
        [] // eslint-disable-line react-hooks/exhaustive-deps
      );

      const onOnboardingDone = (_vendorId: string, _isMissingMcc: boolean, guestPaymentIntentId: string) => {
        setGuestPaymentIntentId(guestPaymentIntentId);
        navigation.goToPlaid({ replace: true });
      };

      return (
        <Routes>
          <Route
            path={navigation.Paths.Form}
            element={withOutlet(
              <AddBankAccountDetailsActivity
                onViewInvoice={onViewInvoice}
                onSelectFundingSource={onSelectFundingSource}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                guestPaymentIntentParams={guestPaymentIntentParams}
                onDone={(userDetails: GuestPayorUserNameAndEmailDetails) => {
                  setUserDetails(userDetails);
                  navigation.goToAuth({ replace: false });
                }}
                onError={onError}
                isDisabled={navigation.currentRoute != 'Form'}
                {...props}
                ref={ref}
              />
            )}
          >
            <Route
              path={navigation.Paths.Auth}
              element={withOutlet(
                <AuthenticationModalActivity
                  isOpen={navigation.currentRoute != 'Plaid' || isPlaidLoading}
                  email={userDetails?.email as string}
                  onLoggedIn={onLoggedIn}
                  onDone={() => navigation.goToOnboarding({ replace: true })}
                  partnerName={partnerName}
                  isLoading={navigation.currentRoute != 'Auth'}
                  onClose={authModalCloseHandler}
                  onError={errorHandler}
                  guestPaymentIntentParams={guestPaymentIntentParams}
                />
              )}
            >
              <Route
                path={navigation.Paths.Onboarding}
                element={
                  <OnboardingActivity
                    guestPaymentIntentParams={guestPaymentIntentParams}
                    onDone={onOnboardingDone}
                    onError={errorHandler}
                    onClose={authModalCloseHandler}
                    userDetails={{
                      firstName: userDetails?.firstName as string,
                      lastName: userDetails?.lastName as string,
                    }}
                  />
                }
              />
              <Route
                path={navigation.Paths.Plaid}
                element={
                  <AddPlaidAccountModalActivity
                    isOpen
                    onDone={(fundingSourceId) => navigation.goToReviewAndConfirm(fundingSourceId, { replace: true })}
                    onLoadToken={(isLoading) => (isLoading ? plaidLoading.on() : plaidLoading.off())}
                    onClose={authModalCloseHandler}
                    onError={errorHandler}
                  />
                }
              />
            </Route>
          </Route>
          <Route
            path={navigation.Paths.ReviewAndConfirm}
            element={
              <RouteElement
                component={PayByBankReviewAndConfirmActivity}
                pathToProps={{ fundingSourceId: 'fundingSourceId' }}
                guestPaymentIntentParams={guestPaymentIntentParams}
                onViewInvoice={onViewInvoice}
                onDone={onDone}
                onError={errorHandler}
                onClose={() => navigation.goToForm({ replace: true })}
                guestPaymentIntentId={guestPaymentIntentId as string}
              />
            }
          />
        </Routes>
      );
    }
  )
);

BankPaymentFulfillmentFlow.displayName = 'BankPaymentFulfillmentFlow';

const useFlowNavigation = () => {
  enum Paths {
    Form = '',
    Auth = 'setup',
    Onboarding = 'onboarding',
    Plaid = 'account',
    ReviewAndConfirm = 'review-and-confirm/:fundingSourceId',
  }

  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>();

  const currentRoute = useCurrentRoute(Paths);

  return {
    currentRoute,
    Paths: addWildcardToRoutes(Paths),
    goToForm: createCallback(Paths.Form),
    goToAuth: createCallback(Paths.Auth),
    goToOnboarding: createCallback(Paths.Onboarding, { pathPrefix: Paths.Auth }),
    goToPlaid: createCallback(Paths.Plaid, { pathPrefix: Paths.Auth }),
    goToReviewAndConfirm: createCallbackWithParam(Paths.ReviewAndConfirm, 'fundingSourceId'),
  };
};
