/* eslint-disable no-restricted-imports */
import { useMelioQueryClient } from '@melio/api-client';
import { Button, FloatingMenu, Group, Icon, LoadingContainer, useBreakpoint } from '@melio/penny';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useCanSwitch } from '../../../../hooks/useCanSwitch';
import { useClientActions } from '../../../FirmDashboard/v2/hooks';
import { DrawerActions } from './DrawerActions';

type Props = {
  client?: AccountingFirmClientExpanded;
  isFooterDisabled: boolean;
  goToSettingsSubscriptionPlansAndRefresh: VoidFunction;
  goToSettingsAndRefresh: VoidFunction;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const DrawerFooter = ({
  client,
  isFooterDisabled,
  goToSettingsSubscriptionPlansAndRefresh,
  goToSettingsAndRefresh,
  onSwitchOrganization,
}: Props) => {
  const queryClient = useMelioQueryClient();
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { goToClientCompanyPage, onGoToClientAccount, onToggleHiddenState } = useClientActions({
    goToSettingsSubscriptionPlansAndRefresh,
    goToSettingsAndRefresh,
    onSwitchOrganization,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { canSwitch } = useCanSwitch();
  const hasPermissionToClient = canSwitch({ permission: client?.organizationInfo.permissionLevel });

  const goToClientAccount = client && {
    onClick: () => void onGoToClientAccount(client.organizationInfo.id, client.subscriptionInfo),
    isDisabled: !hasPermissionToClient,
    dataTestId: 'menu-item-go-to-client-account',
  };

  const editClient = client && {
    onClick: () => void goToClientCompanyPage(client.organizationInfo.id),
    isDisabled: !hasPermissionToClient,
    dataTestId: 'menu-item-edit-client',
  };

  const toggleHidden = client && {
    onClick: async () => {
      setIsLoading(true);
      await onToggleHiddenState({ id: client.organizationInfo.id, isHidden: !!client.organizationInfo.isHidden });
      await queryClient.invalidateQueries('AccountingFirmClientApi');
      setIsLoading(false);
      await queryClient.invalidateQueries('AccountingFirmClientsApi', 'collection');
    },
    dataTestId: 'menu-item-toggle-hidden-state',
  };

  return (
    <Group variant="horizontal" spacing="m" justifyContent="space-between" width="full">
      <FloatingMenu
        isOpen={isMenuOpen}
        onOpenChange={setIsMenuOpen}
        isDisabled={isFooterDisabled}
        trigger={
          <FloatingMenu.ActionTrigger
            label={formatMessage(
              isExtraSmallScreen
                ? 'activities.accountants.firmDashboard.v2.clientDrawer.footer.moreActions.mobile'
                : 'activities.accountants.firmDashboard.v2.clientDrawer.footer.moreActions'
            )}
            size="medium"
            variant="default"
          />
        }
        maxWidth="max-content"
        content={
          <LoadingContainer isLoading={isLoading}>
            <DrawerActions client={client} editClient={editClient} toggleHidden={toggleHidden} />
          </LoadingContainer>
        }
      />
      <Button
        label={formatMessage(
          isExtraSmallScreen
            ? 'activities.accountants.firmDashboard.v2.clientDrawer.footer.goToClient'
            : 'activities.accountants.firmDashboard.v2.clientDrawer.footer.goToClientAccount'
        )}
        isDisabled={isFooterDisabled || goToClientAccount?.isDisabled}
        rightElement={<Icon type="link-open" size="small" color="inverse" />}
        onClick={goToClientAccount?.onClick}
        data-testid={goToClientAccount?.dataTestId}
        size="medium"
        variant="primary"
      />
    </Group>
  );
};
