import { SortingState } from '@melio/penny';
import { useDateUtils, useDebounceCallback, useOneTimeEffect } from '@melio/platform-utils';
import { noop } from 'lodash';
import { createContext, useContext, useState } from 'react';

import { useAccountingFirmClientsV2 } from '../../../../../api/entities/client/useAccountingFirmClientsV2';
import { usePatchAccountingFirmClient } from '../../../../../api/entities/client/usePatchAccountingFirmClient';
import { useCanSwitch } from '../../../../../hooks/useCanSwitch';
import { useClientsTableQueryParam } from '../../hooks/useClientsTableQueryParam';
import { ClientData, FirmClientsTableFilters, SortableClientsTableColumns } from './types';
import { fakerClientsForLoading, sortingFunctions } from './utils';

const DEFAULT_PAGE_SIZE = 20;
const DEBOUNCE_TIMEOUT = 500;
const NEXT_THIRTY_DAYS = 30;

const FirmClientsTableContext = createContext<FirmClientsTableFilters | undefined>({
  clients: [],
  shouldShowEmptyState: false,
  isLoading: false,
  error: null,
  filters: {
    searchText: '',
    showHiddenClients: false,
  },
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
    totalCount: 0,
  },
  sorting: undefined,
  handlers: {
    handleInputChange: noop,
    handlePageChange: noop,
    handleSortChange: noop,
    handleToggleHiddenClients: noop,
  },
  clientActions: {
    updateClient: undefined,
  },
});

export const useFirmClientsTableContext = () => {
  const value = useContext(FirmClientsTableContext);
  if (!value) {
    throw new Error(`${useFirmClientsTableContext.name} must be used within a ${FirmClientsTableProvider.name}`);
  }

  return value;
};

export const FirmClientsTableProvider = ({ children }: { children: React.ReactNode }) => {
  const { canSwitch } = useCanSwitch();
  const { createDate } = useDateUtils();
  const now = createDate();
  const futureDate = createDate();
  futureDate.setDate(now.getDate() + NEXT_THIRTY_DAYS);

  const {
    data: clientsData = [],
    isLoading: isClientsLoading,
    error: isClientsError,
  } = useAccountingFirmClientsV2({
    params: {
      activityStartDate: now,
      activityEndDate: futureDate,
    },
    enabled: true,
  });
  const patchClientMutation = usePatchAccountingFirmClient();

  const {
    filters: { searchParamText, searchParamShowHiddenClients },
    applyFilter,
  } = useClientsTableQueryParam();

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const handlePageChange = (newPage: number) => setCurrentPage(newPage);

  // hidden clients
  const [showHiddenClients, setShowHiddenClients] = useState<boolean>(
    searchParamShowHiddenClients === 'true' ? true : false
  );
  const handleToggleHiddenClients = (toggled?: boolean) => {
    applyFilter({ searchText, showHiddenClients: !!toggled });
    setShowHiddenClients(!!toggled);
  };

  // search text
  const [searchText, setSearchText] = useState<string>(searchParamText);
  const handleInputChange = useDebounceCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value || '');
    handlePageChange(1);
    applyFilter({ searchText: e.target.value, showHiddenClients });
  }, DEBOUNCE_TIMEOUT);

  // sorting
  const [sorting, setSorting] = useState<SortingState | undefined>();
  const handleSortChange = (sortingState: SortingState | undefined) => {
    setSorting(sortingState);
    handlePageChange(1);
  };

  useOneTimeEffect(
    () => {
      if (!isClientsLoading && !sorting) {
        const hasBillsCount = clientsData.find((client) => client.billsCount > 0);
        handleSortChange({
          id: hasBillsCount ? SortableClientsTableColumns.BILLS_DUE : SortableClientsTableColumns.CLIENT,
          sortDirection: hasBillsCount ? 'desc' : 'asc',
        });
      }
    },
    () => !sorting && !isClientsLoading && !!clientsData.length,
    [clientsData, isClientsLoading, sorting]
  );

  const filteredClients: Array<ClientData> = clientsData
    .filter((client) => {
      const isVisible = showHiddenClients ? true : !client.client.isHidden;
      const matchesSearch = client.client.name?.toLowerCase().includes(searchText?.toLowerCase() || '');

      return isVisible && matchesSearch;
    })
    .map((client) => ({
      ...client,
      hasPermissions: canSwitch({ permission: client.client.permissionLevel }),
    }));

  const applyPagination = (data: Array<ClientData>) => {
    const startIndex = (currentPage - 1) * DEFAULT_PAGE_SIZE;
    return data.slice(startIndex, startIndex + DEFAULT_PAGE_SIZE);
  };

  const applySorting = (data: Array<ClientData>) => {
    const sortingId: SortableClientsTableColumns | undefined = sorting?.id
      ? (sorting.id as SortableClientsTableColumns)
      : undefined;
    if (sortingId && sortingFunctions[sortingId]) {
      return sortingFunctions[sortingId](data, sorting?.sortDirection);
    }
    return data;
  };

  const isLoading = isClientsLoading;
  const error = isClientsError;

  return (
    <FirmClientsTableContext.Provider
      value={{
        clients: isClientsLoading ? fakerClientsForLoading : applyPagination(applySorting(filteredClients)),
        shouldShowEmptyState: !clientsData.length,
        isLoading,
        error,
        pagination: {
          currentPage,
          pageSize: DEFAULT_PAGE_SIZE,
          totalCount: filteredClients.length,
        },
        filters: {
          searchText,
          showHiddenClients,
        },
        sorting,
        handlers: {
          handleInputChange,
          handlePageChange,
          handleSortChange,
          handleToggleHiddenClients,
        },
        clientActions: {
          updateClient: ({ id, data }) => patchClientMutation.mutateAsync({ id, data }),
        },
      }}
    >
      {children}
    </FirmClientsTableContext.Provider>
  );
};
