import { CustomersDashboardSortQueryParam, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, SearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MobileContainer } from '../../../components';
import { useCustomersTableSortableColumns } from '../hooks';
import { OnSort } from '../types';
import { SortMenu } from './SortMenu.mobile';

export type CustomersTableToolbarProps = {
  search?: string;
  onSearch: (value: string) => void;
  sortParams?: CustomersDashboardSortQueryParam;
  onSort: OnSort;
  shouldDisplayFilters?: boolean;
};

export const CustomersTableMobileToolbar = forwardRef<CustomersTableToolbarProps>(
  ({ search, onSearch, onSort, sortParams, shouldDisplayFilters, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const sortableColumns = useCustomersTableSortableColumns();
    const sortMenuItems = Object.values(sortableColumns);

    if (!shouldDisplayFilters) return null;

    return (
      <MobileContainer>
        <Group
          data-component={CustomersTableMobileToolbar.displayName}
          width="full"
          justifyContent="space-between"
          data-testid="customers-table-mobile-toolbar"
          {...props}
          ref={ref}
        >
          <SearchBar
            value={search}
            onSearch={(value) => onSearch(String(value ?? ''))}
            placeholder={formatMessage('ar.dashboard.activities.customersTable.searchInput.label')}
            onClear={() => onSearch('')}
            aria-label={formatMessage('ar.dashboard.activities.customersTable.mobile.searchInput.aria-label')}
            instructionsText={formatMessage('ar.dashboard.activities.customersTable.mobile.searchInput.instructions')}
          />
          <Container width="fit-content">
            <SortMenu items={sortMenuItems} onSortChange={onSort} sortParams={sortParams} />
          </Container>
        </Group>
      </MobileContainer>
    );
  }
);
CustomersTableMobileToolbar.displayName = 'CustomersTableMobileToolbar';
