import { Box } from '@chakra-ui/react';
import { useSetRecoilState } from 'recoil';
import { Group, IconButton, Image } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { DemoLoginAccountUrl } from '@/cl/components/DemoLoginAccountUrl/DemoLoginAccountUrl.component';
import { MiniTopNavLayoutTheme } from '@/cl/layouts/app-layout/mini-topnav.layout.theme';
import { TopNavProps } from '@/cl/layouts/app-layout/topnav.layout.types';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { mobileSideNavState } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { AccountSelectWidget } from '@/widgets/AccountSelect/AccountSelect.widget';
import { BackToPartner } from '@/widgets/BackToPartner/BackToPartner.widget';
import { OrganizationSelectWidget } from '@/widgets/OrganizationSelect/OrganizationSelect.widget';

export const MiniTopNavLayout = ({
  TopNavIcon,
  BackToPartnerIcon,
  showOrgSwitcher = true,
  sticky,
}: TopNavProps & { sticky?: boolean }) => {
  const [multiOrgUi] = useDevFeature<boolean>(FeatureFlags.MultiOrgUI, false);
  const [useOrganizationsEndpointForCompaniesList] = useDevFeature<boolean>(
    FeatureFlags.UseOrganizationsEndpointForCompaniesList,
    false,
  );
  const setShowSideNav = useSetRecoilState(mobileSideNavState);
  const toggleSideNav = () => setShowSideNav((value) => !value);

  const styles = useAppTheme(MiniTopNavLayoutTheme, { sticky });
  const config = useConfig();
  const { formatMessage } = usePlatformIntl();
  const shouldShowSwitcher = multiOrgUi && showOrgSwitcher;

  const header = config.settings.embeddedExperience?.enabled ? null : (
    <Box as="header" aria-label={formatMessage('app.topnav.ariaLabel')} role="banner" sx={styles['header']}>
      <Group variant="vertical" hasDivider spacing="none">
        <Box as={'nav'} data-component={'top-nav'} sx={styles['banner']}>
          {BackToPartnerIcon && <BackToPartner BackIcon={BackToPartnerIcon!} />}
          <Image src={TopNavIcon} width="auto" height="auto" alt="logo" sx={styles['centerIcon']} />
        </Box>
      </Group>
    </Box>
  );

  return (
    <>
      {header}
      <Box as={'nav'} data-component={'top-nav'} sx={styles['menu']}>
        <IconButton icon="menu" aria-label="Menu" variant="naked" size="medium" onClick={toggleSideNav} />
        {shouldShowSwitcher && (
          <Box sx={styles['mobileAccountSwitcher']}>
            {useOrganizationsEndpointForCompaniesList ? <OrganizationSelectWidget /> : <AccountSelectWidget />}
          </Box>
        )}
        {!config.production && <DemoLoginAccountUrl />}
      </Box>
    </>
  );
};
