import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { difference } from 'lodash';
import { useCallback } from 'react';

import { Plan, Tier } from '../api';
import { usePlansTiers } from './usePlansTiers';
import { useSubscriptionPlan } from './useSubscriptionPlan';

enum KeyEnum {
  MULTIPLE_USERS_COLLABORATION = 'multipleUsersCollaboration',
  APPROVAL_WORKFLOWS = 'approvalWorkflows',
  CUSTOM_APPROVAL_WORKFLOWS = 'customApprovalWorkflows',
  AR_FUNCTIONALITY = 'arFunctionality',
  USER_SEATS = 'userSeats',
  ADVANCED_ATTRIBUTES = 'advancedAttributes',
  TWO_DAY_DELIVERY = 'twoDayDelivery',
  QBDT_SYNC = 'qbdtSync',
  PRIORITY_PHONE_SUPPORT = 'priorityPhoneSupport',
  AUTOMATIC_2WAY_SYNC = 'automatic2waySync',
  VENDOR_REPORTING = 'vendorReporting',
  MULTIPLE_BATCH_PAYMENTS = 'multipleBatchPayments',
  COMBINE_MULTIPLE_PAYMENTS = 'combineMultiplePayments',
  ACCOUNTING_SOFTWARE_SYNC = 'accountingSoftwareSync',
  UNLIMITED_ACH = 'unlimitedAchPayments',
}

const CORE_FEATURES = [
  KeyEnum.MULTIPLE_USERS_COLLABORATION,
  KeyEnum.APPROVAL_WORKFLOWS,
  KeyEnum.AUTOMATIC_2WAY_SYNC,
  KeyEnum.VENDOR_REPORTING,
  KeyEnum.MULTIPLE_BATCH_PAYMENTS,
  KeyEnum.COMBINE_MULTIPLE_PAYMENTS,
];

const BOOST_FEATURES = [
  KeyEnum.MULTIPLE_USERS_COLLABORATION,
  KeyEnum.APPROVAL_WORKFLOWS,
  KeyEnum.TWO_DAY_DELIVERY,
  KeyEnum.ADVANCED_ATTRIBUTES,
  KeyEnum.AUTOMATIC_2WAY_SYNC,
  KeyEnum.QBDT_SYNC,
  KeyEnum.VENDOR_REPORTING,
  KeyEnum.MULTIPLE_BATCH_PAYMENTS,
  KeyEnum.COMBINE_MULTIPLE_PAYMENTS,
  KeyEnum.CUSTOM_APPROVAL_WORKFLOWS,
  KeyEnum.PRIORITY_PHONE_SUPPORT,
];

const UNLIMITED_ACH_FEATURES = [KeyEnum.UNLIMITED_ACH];

const features: Record<Plan['tier'], Array<`${KeyEnum}`>> = {
  [Tier.MelioBasic]: [],
  [Tier.MelioPlus]: CORE_FEATURES,
  [Tier.MelioPro]: BOOST_FEATURES,
  [Tier.MelioUnlimitedAch]: UNLIMITED_ACH_FEATURES,
  [Tier.MelioVip]: [],
  [Tier.FiservApBasic]: [],
  [Tier.FiservApPro]: [KeyEnum.ACCOUNTING_SOFTWARE_SYNC, KeyEnum.USER_SEATS],
  [Tier.FiservApArBasic]: [KeyEnum.AR_FUNCTIONALITY],
  [Tier.FiservApArPro]: [KeyEnum.ACCOUNTING_SOFTWARE_SYNC, KeyEnum.AR_FUNCTIONALITY, KeyEnum.USER_SEATS],
  [Tier.MelioAccountingClientCore]: CORE_FEATURES,
  [Tier.MelioAccountingClientBoost]: BOOST_FEATURES,
  [Tier.MelioAccountingClientUnlimitedAch]: UNLIMITED_ACH_FEATURES,
};

export const usePlansFeaturesDifference = () => {
  const { currentPlan } = useSubscriptionPlan();
  const { getPlan } = usePlansTiers();

  const { formatMessage } = useMelioIntl();

  const getFeaturesListDifference = useCallback(
    (plan1: Plan, plan2: Plan) => {
      const featuresListKeysDifferences = difference(features[plan1.tier], features[plan2.tier]);

      return featuresListKeysDifferences.map((key) =>
        formatMessage(`activities.subscription.featuresListDifference.${key}`)
      );
    },

    [formatMessage]
  );

  const getDowngradedPlanFeaturesLost = useCallback(
    (downgradedPlanId: Plan['id']) => {
      const downgradedPlan = getPlan(downgradedPlanId);

      if (!currentPlan) {
        const error = `current plan not found`;
        Logger.log(error, 'error');
        return [];
      }

      return getFeaturesListDifference(currentPlan, downgradedPlan);
    },
    [currentPlan, getPlan, getFeaturesListDifference]
  );

  const getUpgradedPlanFeaturesUnlocked = useCallback(
    (upgradedPlanId: Plan['id']) => {
      const upgradedPlan = getPlan(upgradedPlanId);

      if (!currentPlan) {
        const error = `current plan not found`;
        Logger.log(error, 'error');
        return [];
      }

      return getFeaturesListDifference(upgradedPlan, currentPlan);
    },
    [currentPlan, getPlan, getFeaturesListDifference]
  );

  return { getDowngradedPlanFeaturesLost, getUpgradedPlanFeaturesUnlocked };
};
