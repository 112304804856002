import {
  BillingFeeSetting,
  FeeType,
  hasActiveBillingFeeMethod,
  PaymentFee,
  useFeeCatalog,
  useFreeChecks,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { sumBy, useLocation } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

export type PaymentFeeDetails = {
  type: FeeType;
  amount: number;
  description: React.ReactNode;
  helperText: string;
};

// While we transition from cap = 30 to cap = 75, we want to show users that are still used to the old cap that the cap changed.
const OLD_CAP_VALUE_IN_DOLLARS = 30;

export const useFeeValueFormatting = () => {
  const { formatCurrency, formatPercents } = useMelioIntl();

  const { data: feeCatalogEntries, isLoading } = useFeeCatalog();

  return {
    formatFeeValue: (feeType: FeeType) => {
      const feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === feeType);

      if (feeCatalogEntry) {
        return feeCatalogEntry.valueType === 'fixed'
          ? formatCurrency(feeCatalogEntry.value)
          : formatPercents(feeCatalogEntry.value, { divide: true });
      } else {
        return '';
      }
    },
    formatCapValue: (feeType: FeeType) => {
      const feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === feeType);
      if (feeCatalogEntry?.cap) {
        return { formattedCap: formatCurrency(feeCatalogEntry.cap), cap: feeCatalogEntry.cap };
      }
      return null;
    },
    formatMinFeeValue: (feeType: FeeType) => {
      const feeCatalogEntry = feeCatalogEntries?.find((entry) => entry.feeType === feeType);
      if (feeCatalogEntry?.min) {
        return { formattedMin: formatCurrency(feeCatalogEntry.min), min: feeCatalogEntry.min };
      }
      return null;
    },
    isLoading,
  };
};

export const useGetCapOrMinFeeDescription = () => {
  const { formatFeeValue, formatCapValue, formatMinFeeValue } = useFeeValueFormatting();
  const { formatMessage } = useMelioIntl();
  const isNotifyOnNewMaximumFee = useDevFeature<boolean>(FeatureFlags.IncreasePayorFastPaymentCap, false, {
    shouldTrack: true,
  });
  const isNotifyOnNewMinimumFee = useDevFeature<boolean>(FeatureFlags.ApplyMinimumFee, false, {
    shouldTrack: true,
  });

  const getCapOrMinFeeDescription = (feeType: FeeType, feeAmount: number) => {
    const capData = formatCapValue(feeType);
    if (
      isNotifyOnNewMaximumFee &&
      capData?.formattedCap &&
      (feeAmount === capData.cap || feeAmount > OLD_CAP_VALUE_IN_DOLLARS)
    ) {
      return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.maximumCap.title', {
        feeValue: formatFeeValue(feeType),
        cap: capData.formattedCap,
      });
    }

    const minFee = formatMinFeeValue(feeType);
    if (isNotifyOnNewMinimumFee && minFee && feeAmount === minFee.min) {
      return formatMessage('widgets.deliveryDate.deliveryPreferenceForm.minimumFee.title', {
        feeValue: formatFeeValue(feeType),
        min: minFee.formattedMin,
      });
    }
    return undefined;
  };
  return { getCapOrMinFeeDescription };
};

export const getFeeDescription = ({
  formatFeeValue,
  fee,
}: {
  formatFeeValue: (feeType: FeeType) => string;
  fee: PaymentFee;
}): React.ReactNode => {
  if (
    fee.type === 'card' ||
    fee.type === 'virtual' ||
    fee.type === 'push-to-debit' ||
    fee.type === 'overnight-ach' ||
    fee.type === 'rtp' ||
    fee.type === 'international-fx'
  ) {
    return null;
  }

  return (
    <FormattedMessage
      id={`widgets.reviewAndConfirm.feesSection.feeTypes.${fee.type}`}
      values={{
        feeValue: formatFeeValue(fee.type),
      }}
    />
  );
};

export const usePaymentFee = (paymentFees: PaymentFee[]): PaymentFeeDetails[] | null => {
  const { formatMessage } = useMelioIntl();
  const { pathname } = useLocation();
  const { getCapOrMinFeeDescription } = useGetCapOrMinFeeDescription();
  const { formatFeeValue, isLoading: isLoadingFormatting } = useFeeValueFormatting();
  const { data: feeCatalogEntries } = useFeeCatalog();

  const isChecksPromotionSupported = feeCatalogEntries?.some(
    (entry) => entry.feeType === 'ach-to-check' && entry.promotedFee
  );

  const isCheckPaymentFee = paymentFees.some((fee) => fee.type === 'ach-to-check');

  const shouldFetchFreeChecksPromotionData = isChecksPromotionSupported && isCheckPaymentFee;

  const { data: freeChecksData } = useFreeChecks({
    enabled: !!shouldFetchFreeChecksPromotionData,
  });

  if (!feeCatalogEntries || (shouldFetchFreeChecksPromotionData && !freeChecksData)) {
    return null;
  }

  // This is a real patch until we'll fix promotions on edit flows
  const isCreatePaymentFlow = !/.pymnt_\d+/.test(pathname);
  const getHelperText = (fee: PaymentFee) => {
    if (isCreatePaymentFlow && fee.type === 'ach-to-check' && freeChecksData && freeChecksData.available > 0) {
      return formatMessage('widgets.reviewAndConfirm.feesSection.monthlyFreeChecksAvailableHelperText', {
        available: freeChecksData.available,
        total: freeChecksData.total,
      });
    }

    const creditFeeDisclaimer = formatMessage('widgets.reviewAndConfirm.feesSection.creditFeeDisclaimer');
    if (fee.type === 'credit' && !isEmpty(creditFeeDisclaimer)) {
      return creditFeeDisclaimer;
    }
    const capOrMinHelperDescription = getCapOrMinFeeDescription(fee.type, fee.amount);
    if (capOrMinHelperDescription) {
      return capOrMinHelperDescription;
    }
    return '';
  };

  const results = paymentFees.map((fee) => {
    const helperText = getHelperText(fee);
    return {
      type: fee.type,
      amount: fee.amount,
      description: isLoadingFormatting
        ? ''
        : getFeeDescription({
            formatFeeValue,
            fee,
          }),
      helperText,
    };
  });

  return results.filter(filterUnusedFeeTypes);
};

export const filterUnusedFeeTypes = ({ type }: PaymentFee) => type !== 'virtual';

export const useTotalFees = ({
  transactionFees,
  orgBillingFeeSettings,
  recurringLastAmountFees,
}: {
  transactionFees: { type: string; amount: number }[];
  orgBillingFeeSettings: BillingFeeSetting[];
  recurringLastAmountFees?: PaymentFee[];
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();

  const totalFeesAmount = sumBy(transactionFees, (fee) => fee.amount);
  const recurringLastAmountTotalFees = recurringLastAmountFees
    ? sumBy(recurringLastAmountFees, (fee) => fee.amount)
    : undefined;

  if (recurringLastAmountTotalFees && recurringLastAmountTotalFees !== totalFeesAmount) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.recurringLastAmount', {
        feeValue: formatCurrency(recurringLastAmountTotalFees),
      }),
    };
  }

  if (!totalFeesAmount) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.noFeesDescription'),
    };
  }

  const isRtpFeeType = transactionFees.some((fee) => fee.type === FeeType.RtpOrganization);

  if (isRtpFeeType) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.rtp-organization'),
    };
  }

  const hasActiveBillingFee = hasActiveBillingFeeMethod(orgBillingFeeSettings, transactionFees);

  if (hasActiveBillingFee) {
    return {
      amount: formatCurrency(totalFeesAmount),
      description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription.billingMethodAvailable'),
    };
  }

  return {
    amount: formatCurrency(totalFeesAmount),
    description: formatMessage('widgets.reviewAndConfirm.feesSection.totalFeesDescription'),
  };
};
