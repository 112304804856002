import { VisuallyHidden } from '@chakra-ui/react';
import { Group, Icon, SectionBanner, SimpleGrid, StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import {
  PaymentStatusEnum,
  useAccount,
  useCollaborators,
  useOrganizationPreferences,
  usePaginatedPayments,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { usePartnerFeature } from '@melio/platform-provider';
import { isCollaboratorBlocked } from '@melio/platform-utils';
import { useState } from 'react';

import { useSubscriptionMe } from '../../../../../api';
import {
  usePartnerGroups,
  usePlanInfo,
  usePlansDowngradedImportantFeaturesLost,
  usePlansFeaturesDifference,
  usePlansTiers,
  useRedirects,
  useSubscription,
} from '../../../../../hooks';
import { getQuantityBasedIsFeatureInPlan } from '../../../../../utils';
import { SubscriptionDowngradeWarning } from '../../../../components/SubscriptionDowngradeWarning/SubscriptionDowngradeWarning';
import { DeleteFutureClientPaymentsSectionBanner } from './components/DeleteFutureClientPaymentsSectionBanner';

type SubscriptionCancelApprovalModalScreenProps = {
  isOpen: boolean;
  newPlanId: string;
  onClose: () => void;
  onSuccess?: () => void;
};

export const SubscriptionCancelApprovalModalScreen = withAnalyticsContext<SubscriptionCancelApprovalModalScreenProps>(
  ({ isOpen, newPlanId, onClose, onSuccess, setAnalyticsProperties }) => {
    const [accountManagerAccessCollaboratorsEnabled] = useDevFeature<boolean>(
      FeatureFlags.AccountManagerAccessCollaborators,
      false
    );
    const [isDisplayCancelPaymentsBannerEnabled] = usePartnerFeature(
      'DisplayCancelPaymentsBannerInCancelFlowForClients',
      false
    );
    const [downgradeError, setDowngradeError] = useState<boolean>(false);
    const { formatMessage, formatDate } = useMelioIntl();
    const { data: account } = useAccount({ id: 'me' });
    const isAccountingFirmClient: boolean = account?.isAccountingClient ?? false;

    const subscription = useSubscription();
    const { getPlan } = usePlansTiers();
    const { getDowngradedPlanFeaturesLost } = usePlansFeaturesDifference();
    const { isFiservPartner } = usePartnerGroups();
    const { goToSettingsCollaboratorsPage } = useRedirects();
    const { planName: currentPlanName } = usePlanInfo(subscription?.planId);
    const { planName: newPlanName } = usePlanInfo(newPlanId);
    const {
      data: collaborators,
      isFetching: isCollaboratorsFetching,
      isLoading: isCollaboratorsLoading,
    } = useCollaborators({
      enabled: accountManagerAccessCollaboratorsEnabled,
    });

    const { data: paginatedPayments, isFetching: isPaymentsFetching } = usePaginatedPayments({
      cacheTime: 0,
      params: {
        search: {
          'payment.status': [PaymentStatusEnum.Scheduled, PaymentStatusEnum.Blocked],
        },
      },
      enabled: isDisplayCancelPaymentsBannerEnabled && isAccountingFirmClient,
    });

    const isFetching = isCollaboratorsFetching || isCollaboratorsLoading || isPaymentsFetching;
    const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
    const featuresLostList = [
      ...(isDisplayCancelPaymentsBannerEnabled && isAccountingFirmClient
        ? [
            formatMessage('activities.subscription.featuresListDifference.accountantAndUsersRemoval'),
            formatMessage('activities.subscription.featuresListDifference.cancelFutureRecurringPayments'),
          ]
        : []),
      ...getDowngradedPlanFeaturesLost(newPlanId),
    ];

    const hasPartnerColors = !!isFiservPartner;

    setAnalyticsProperties({
      PageName: 'downgrade-plan',
      Flow: 'settings',
      Intent: 'cancel-my-plan',
      CurrentPlan: currentPlanName.toLowerCase(),
    });

    const { track } = useAnalytics();

    useAnalyticsView('Organization', isOpen, true);

    if (!subscription) {
      Logger.log('SubscriptionCancelApprovalModalScreen - subscription is not exists');

      return null;
    }

    const formattedNextBillingDate = formatDate(subscription?.nextBillingDate, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    const activeCollaborators = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];

    const newPlan = getPlan(newPlanId);
    const {
      quota: { freeUnitsLimit: newPlanCollaboratorsLimit },
      isEligible: doesNewPlanAllowCurrentCollaboratorsCount,
    } = getQuantityBasedIsFeatureInPlan(newPlan, 'collaborators', activeCollaborators.length);

    const { create: createOrgPreference } = useOrganizationPreferences();

    const switchSubscription = () => {
      setDowngradeError(false);

      track('Organization', 'Click', {
        Cta: 'cancel-my-plan',
      });

      void updateSubscription({ planId: newPlanId })
        .then(() => {
          track('Organization', 'Status', {
            StatusType: 'success',
          });
          shouldDisplayCancelPaymentsBanner &&
            void createOrgPreference({ key: 'cancelPaymentsSubscriptionBannerDisplayed', value: 'true' });
          onSuccess?.();
        })
        .catch(() => {
          setDowngradeError(true);
          track('Organization', 'Status', {
            StatusType: 'failure',
            ErrorType: 'submit-error',
          });
        });
    };

    const onCloseHandler = (closeType: string) => {
      track('Organization', 'Click', {
        Intent: closeType,
        Cta: closeType,
      });
      onClose();
    };

    const shouldRenderTooManySeatsUx = isFiservPartner && !doesNewPlanAllowCurrentCollaboratorsCount;

    const content = shouldRenderTooManySeatsUx
      ? ({
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: onClose,
          },
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.secondaryButton',
            onClick: () => {
              goToSettingsCollaboratorsPage();
              track('Organization', 'Click', {
                Intent: 'users-and-role-settings',
                Cta: 'users-and-role-settings',
              });
            },
            variant: 'tertiary',
          },
          descriptionKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.description',
        } as const)
      : ({
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: switchSubscription,
          },
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.secondaryButton',
            onClick: () => {
              onCloseHandler('keep-my-plan');
            },
            variant: hasPartnerColors ? 'secondary' : 'tertiary',
          },
          descriptionKey: subscription?.isFreeTrial
            ? 'activities.subscription.cancelModal.descriptionWithFreeTrial'
            : 'activities.subscription.cancelModal.description',
        } as const);

    const { primaryButton, secondaryButton, descriptionKey } = content;

    const modalTitle = formatMessage(`activities.subscription.cancelModal.cancel.title`, {
      currentPlanName,
    });

    const shouldDisplayCancelPaymentsBanner = isDisplayCancelPaymentsBannerEnabled && isAccountingFirmClient;

    const { getDowngradedImportantFeaturesLost } = usePlansDowngradedImportantFeaturesLost();

    const importantFeatureLost = getDowngradedImportantFeaturesLost(newPlanId);
    const hasWarningBanner = !isFiservPartner && importantFeatureLost.length > 0 && !shouldDisplayCancelPaymentsBanner;

    return (
      <>
        <VisuallyHidden role="status" aria-live="polite">
          {(isFetching || isUpdatingSubscription) &&
            formatMessage('activities.subscription.cancelModal.loading', { title: modalTitle })}
        </VisuallyHidden>

        <StatusModal
          isOpen={isOpen}
          onClose={() => onCloseHandler('exit')}
          header={modalTitle}
          variant={isFiservPartner ? 'warning' : 'alert'}
          primaryButton={{
            isLoading: isUpdatingSubscription,
            label: formatMessage(primaryButton.textKey),
            onClick: primaryButton.onClick,
            variant: primaryButton.variant,
          }}
          secondaryButton={{
            label: formatMessage(secondaryButton.textKey),
            onClick: secondaryButton.onClick,
            variant: secondaryButton.variant,
            isDisabled: isUpdatingSubscription,
          }}
          data-testid="subscription-cancel-approval-modal-screen"
          isLoading={isFetching}
        >
          <Group variant="vertical" spacing="m">
            {downgradeError && (
              <SectionBanner
                description={formatMessage(`activities.subscription.cancelModal.cancel.toast.error`)}
                variant="critical"
                data-testid="subscription-downgrade-failed"
                data-role="alert"
              />
            )}
            {hasWarningBanner && <SubscriptionDowngradeWarning messages={importantFeatureLost} />}

            <Text data-testid="subscription-cancel-approval-modal-description">
              {formatMessage(descriptionKey, {
                nextBillingDate: formattedNextBillingDate,
                newPlanName,
                currentPlanName,
                collaboratorsLimit: newPlanCollaboratorsLimit,
              })}
            </Text>
            {shouldDisplayCancelPaymentsBanner && (
              <DeleteFutureClientPaymentsSectionBanner
                nextBillingDate={formattedNextBillingDate}
                numberOfPaymentsToCancel={paginatedPayments?.pagination.totalCount ?? 0}
              />
            )}
            {!shouldRenderTooManySeatsUx && (
              <Group variant="vertical" spacing="xxxs">
                {featuresLostList.map((feature) => (
                  <SimpleGrid key={feature} alignItems="flex-start" display="flex" gap="xs" paddingBottom="xxs">
                    <Text color={hasPartnerColors ? 'global.neutral.700' : 'global.critical.700'}>
                      <Icon type="cancel-circle" color="inherit" size="small" aria-hidden="true" />
                    </Text>
                    <Text color={hasPartnerColors ? 'global.neutral.700' : undefined} textStyle="body3">
                      {feature}
                    </Text>
                  </SimpleGrid>
                ))}
              </Group>
            )}
            {isFiservPartner && (
              <SectionBanner
                description={formatMessage(`activities.subscription.cancelModal.cancel.info`)}
                variant="neutral"
                leftElement={<Icon type="info-fill" aria-hidden />}
                data-testid="subscription-cancel-approval-modal-info"
                data-role="alert"
              />
            )}
          </Group>
        </StatusModal>
      </>
    );
  }
);
