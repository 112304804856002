import { PayorVendorPaymentDetails, useMelioIntl } from '@melio/ar-domain';
import { Avatar, Button, Group, Text, useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { BaseLayout } from '../../../layout';
import { AddPaymentDetailsForm } from '../components';
import { AddPaymentDetailsFields } from '../types';

export type AddPaymentDetailsScreenProps = {
  isLoading: boolean;
  vendorPaymentDetails?: PayorVendorPaymentDetails;
  onSubmit: VoidFunction;
};

export const AddPaymentDetailsScreen = forwardRef<AddPaymentDetailsScreenProps>(
  ({ vendorPaymentDetails, onSubmit, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<AddPaymentDetailsFields>();

    return (
      <BaseLayout
        data-component={AddPaymentDetailsScreen.displayName}
        data-testid="guest-payment-details-screen"
        backgroundColor={vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="s">
            <Avatar
              data-testid="company-logo"
              name={vendorPaymentDetails?.payeeDetails.companyName || ''}
              size="large"
              src={vendorPaymentDetails?.payeeDetails.logoUrl ?? undefined}
              variant="square"
            />
            <Text textStyle="heading2Semi" data-testid="company-name">
              {vendorPaymentDetails?.payeeDetails.companyName}
            </Text>
          </Group>
          <AddPaymentDetailsForm onSubmit={onSubmit} onSubmissionStateChange={onSubmissionStateChange} />
          <Button
            data-testid="guest-payment-details-submit-button"
            label={formatMessage('ar.guestPayment.activities.paymentDetails.form.button.submit.label')}
            size="large"
            {...submitButtonProps}
          />
        </Group>
      </BaseLayout>
    );
  }
);

AddPaymentDetailsScreen.displayName = 'AddPaymentDetailsScreen';
