import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { sanitizeId } from '@melio/platform-analytics';
import { useIsAccountingFirm, useMelioQueryClient } from '@melio/platform-api';

import { useSwitchOrganization } from '@/hooks/useSwitchOrganization.hooks';
import { getAccessTokenData } from '@/utils/getAccessTokenData.utils';

const ORGANIZATION_QUERY_PARAM = 'organization';

export const useDeepLinks = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useMelioQueryClient();
  const { switchOrganization } = useSwitchOrganization();
  const isAccountingFirm = useIsAccountingFirm();

  useEffect(() => {
    const externalOrganizationIdFromQuery = searchParams.get(ORGANIZATION_QUERY_PARAM);
    if (!externalOrganizationIdFromQuery) {
      return;
    }

    const accessTokenData = getAccessTokenData();
    if (!accessTokenData) {
      return;
    }

    if (sanitizeId(externalOrganizationIdFromQuery) === accessTokenData.organization?.id) {
      searchParams.delete(ORGANIZATION_QUERY_PARAM);
      setSearchParams(searchParams);
      return;
    }

    switchOrganization({
      organizationId: externalOrganizationIdFromQuery,
      isAccountingFirm,
      switchAccessTokenOnly: true,
    }).then(() => {
      searchParams.delete(ORGANIZATION_QUERY_PARAM);
      setSearchParams(searchParams);
      queryClient.clear();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
