import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { LocalStorageKeys } from '@melio/local-storage';
import { useSendMessage } from '@melio/partner-bridge';
import { useAnalytics } from '@melio/platform-analytics';

import { removePartnerTokens } from '@/api/utilities';
import { useOAuthQueryParams } from '@/hooks/auth.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useResetAccessToken } from '@/hooks/session.hooks';
import { useAppRedirectData } from '@/hooks/useAppRedirect.hooks';
import { OAuthCallbackScreen } from '@/screens/onboarding/OAuthCallback.screen';
import { OAuthRegisterScreen } from '@/screens/onboarding/OAuthRegister.screen';
import { decodeAccessToken } from '@/utils/getAccessTokenData.utils';
import { OnboardingErrorReason } from '@/utils/onboarding.utils';
import { OutgoingPostMessageTypes } from '@/utils/partnerBridge.utils.types';
import { OnboardingError } from '@/widgets/onboarding/components/OnboardingError';

export const AuthRoute = () => {
  const [step, setStep] = useState<'callback' | 'register' | null>(null);
  const [error, setError] = useState<AxiosError<unknown, unknown> | string | null>(null);
  const [encryptedProviderData, setEncryptedProviderData] = useState<string>('');

  const { state } = useLocation<{ redirectUrl: string }>();
  const { token, redirectUrl, sessionData, target } = useOAuthQueryParams();
  const { track } = useAnalytics();
  const resetAccessToken = useResetAccessToken();
  const { getAppRedirectData, setAppRedirectData } = useAppRedirectData();
  const { goToAppRedirect: _goToAppRedirect, goToStart: _goToStart, generateBackToPartnerUrl } = useRouter();
  const localStorage = usePartnerLocalStorage();
  const { partnerConfig } = usePartnerConfig();
  const backToPartnerUrl = generateBackToPartnerUrl();
  const sendMessage = useSendMessage();

  // Memoizing useRouter functions to prevent potential endless loop in useEffect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToAppRedirect = useCallback(_goToAppRedirect, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToStart = useCallback(_goToStart, []);

  useEffect(() => {
    localStorage.removeItem(LocalStorageKeys.freeAchPromotionModalSeen);

    if (sessionData) {
      sessionStorage.setItem('sessionData', decodeURIComponent(sessionData));
    }

    if (token) {
      const oAuthToken = localStorage.getItem(LocalStorageKeys.oAuthToken);
      if (token !== oAuthToken) {
        localStorage.setItem(LocalStorageKeys.oAuthToken, token);
        removePartnerTokens();
      }
      setStep('callback');
    } else if (localStorage.getItem(LocalStorageKeys.accessToken)) {
      setAppRedirectData({ redirectUrl, target });
      goToAppRedirect();
    } else if (partnerConfig.oAuth.required && partnerConfig.config.redirectWhenMissingToken) {
      goToStart({ redirectUrl: state?.redirectUrl });
    } else {
      setError(OnboardingErrorReason.MISSING_TOKEN);
    }
  }, [
    goToAppRedirect,
    goToStart,
    localStorage,
    partnerConfig.config.redirectWhenMissingToken,
    partnerConfig.oAuth.required,
    redirectUrl,
    sessionData,
    setAppRedirectData,
    state?.redirectUrl,
    target,
    token,
  ]);

  const onConsentClose = () => {
    sendMessage(OutgoingPostMessageTypes.AUTHENTICATION_ERROR, { reason: OnboardingErrorReason.DID_NOT_CONSENT });
    if (backToPartnerUrl && partnerConfig.config.redirectOnConsentScreenReject) {
      window.location.href = backToPartnerUrl;
    }
  };

  const onAuthCallbackSuccess: Parameters<typeof OAuthCallbackScreen>[0]['onSuccess'] = useCallback(
    (data) => {
      const { targetAction, targetId, target } = data.metadata;
      setAppRedirectData({ targetAction, targetId, redirectUrl, target });

      if ('authData' in data) {
        const { accessToken, refreshToken } = data.authData;
        resetAccessToken(accessToken, refreshToken);
        goToAppRedirect();
      } else {
        setEncryptedProviderData(data.encryptedProviderData);
        setStep('register');
      }
    },
    [redirectUrl, setAppRedirectData, resetAccessToken, goToAppRedirect, setEncryptedProviderData, setStep],
  );

  const onOAuthRegisterSuccess: Parameters<typeof OAuthRegisterScreen>[0]['onSuccess'] = useCallback(
    (data) => {
      const { accessToken, refreshToken } = data;
      const { organization, user } = decodeAccessToken(accessToken);
      track('AccountCreated', 'Succeeded', {
        HaveMelioAccount: false, // data segregation - we always create a new account for new login
        OrgId: organization?.id,
        UserId: user?.id,
      });

      setAppRedirectData({ ...getAppRedirectData(), isFirstTimeLogin: true });
      resetAccessToken(accessToken, refreshToken);
      goToAppRedirect();
    },
    [track, resetAccessToken, getAppRedirectData, setAppRedirectData, goToAppRedirect],
  );

  if (error) {
    localStorage.removeItem(LocalStorageKeys.oAuthToken);
    return (
      <OnboardingError
        backToPartnerUrl={backToPartnerUrl}
        isBackToPartner={partnerConfig.showOnboardingBackToPartner}
        error={error}
        productName={partnerConfig.partnerProductName}
      />
    );
  }

  if (step === 'callback') {
    return <OAuthCallbackScreen onSuccess={onAuthCallbackSuccess} onError={setError} />;
  }

  if (step === 'register') {
    return (
      <OAuthRegisterScreen
        encryptedProviderData={encryptedProviderData}
        onSuccess={onOAuthRegisterSuccess}
        onError={setError}
        onClose={onConsentClose}
      />
    );
  }

  return <></>;
};
