/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  BaseOwnershipDetails,
  createDateFormatter,
  OwnerDetails,
  useOwnershipDetails,
  useSystemMessage,
} from '@melio/ar-domain';
import { forwardRef, useDateUtils } from '@melio/platform-utils';
import { useMemo } from 'react';

import { CompanyOwnersFormFields } from './components';
import { OwnershipDetailsScreen } from './screens';
import { OwnerDetailsFormValues, UsResident } from './types';

export type OwnershipDetailsActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onBack: VoidFunction;
  onError?: ARErrorFunction;
  isSaving?: boolean;
};

export const OwnershipDetailsActivity = forwardRef<OwnershipDetailsActivityProps>(
  ({ onDone, onError, isSaving: isSavingExternal, ...props }, ref) => {
    const { update: updateOwnershipDetails, isMutating: isSaving } = useOwnershipDetails({ enabled: false });

    const { defaultValues, controlPersonDefaultValues, companyOwnersDefaultValues, isLoading } = useDefaultValues();
    const { triggerMessage } = useSystemMessage();

    const { createDate } = useDateUtils();

    return (
      <OwnershipDetailsScreen
        isLoading={isLoading}
        defaultValues={defaultValues}
        controlPersonDefaultValues={controlPersonDefaultValues}
        companyOwnersDefaultValues={companyOwnersDefaultValues}
        isSaving={isSaving || isSavingExternal}
        onSubmit={(values) => {
          updateOwnershipDetails({
            ownersDetails: values.map<BaseOwnershipDetails>(({ usResident, ...owner }) => ({
              jobTitle: owner.jobTitle || (undefined as never),
              address: usResident === 'yes' ? { countryCode: 'US', ...owner.address } : owner.address,
              dateOfBirth: createDate(formatDate(owner.dateOfBirth, { target: 'yyyy-MM-dd' })),
              firstName: owner.firstName,
              lastName: owner.lastName,
              ownershipPercentage: owner.ownershipPercentage || null,
              usResident: usResident === 'yes',
              taxIdType: usResident === 'yes' ? owner.taxIdType : undefined,
              taxId: usResident === 'yes' ? owner.taxId : undefined,
              internationalIdType: usResident === 'no' ? owner.internationalIdType : undefined,
              internationalId: usResident === 'no' && owner.internationalId ? owner.internationalId : undefined,
              types: [owner.isUbo ? 'ubo' : null, owner.isDirector ? 'director' : null].filter(
                Boolean
              ) as OwnerDetails['types'],
            })),
          })
            .then(() => {
              triggerMessage({ type: 'success', title: 'Ownership details saved' });
              onDone();
            })
            .catch((error) => {
              triggerMessage({ type: 'critical', title: 'Something went wrong, please try again' });
              onError?.(error as ARPlatformError);
            });
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

OwnershipDetailsActivity.displayName = 'OwnershipDetailsActivity';

const formatDate = createDateFormatter('MM/dd/yyyy', 'yyyy-MM-dd');

const useDefaultValues = () => {
  const { data, isLoading } = useOwnershipDetails();

  const defaultValues = useMemo(
    () => data?.filter((owner) => owner.types?.includes('ubo')).map(mapOwnerToFormValues) ?? [],
    [data]
  );

  const controlPersonDefaultValues = useMemo(() => {
    const controlPerson = data?.find(({ types = [] }) => types.includes('director') && !types.includes('ubo'));
    return controlPerson && mapOwnerToFormValues(controlPerson);
  }, [data]);

  const companyOwnersDefaultValues = useMemo<Partial<CompanyOwnersFormFields> | undefined>(() => {
    if (!defaultValues.length && controlPersonDefaultValues) {
      return {
        own25Percent: 'no',
        noOwners: true,
      };
    } else if (defaultValues.length) {
      return {
        own25Percent: 'yes',
        soleOwner: defaultValues.length === 1,
      };
    }
    return undefined;
  }, [controlPersonDefaultValues, defaultValues.length]);

  return useMemo(
    () => ({
      isLoading,
      defaultValues,
      controlPersonDefaultValues,
      companyOwnersDefaultValues,
    }),
    [companyOwnersDefaultValues, controlPersonDefaultValues, defaultValues, isLoading]
  );
};

const mapOwnerToFormValues = (owner: OwnerDetails): OwnerDetailsFormValues => {
  const isUsResident = owner.usResident;
  return {
    id: owner.id,
    jobTitle: owner.jobTitle ?? undefined,
    address: {
      city: owner.address.city,
      countryCode: owner.address.countryCode ?? 'US',
      line1: owner.address.line1,
      line2: owner.address.line2,
      postalCode: owner.address.postalCode,
      state: owner.address.state,
    },
    dateOfBirth: formatDate(owner.dateOfBirth, { target: 'MM/dd/yyyy' }),
    firstName: owner.firstName,
    lastName: owner.lastName,
    ownershipPercentage: owner.ownershipPercentage ?? undefined,
    usResident: isUsResident ? UsResident.Yes : UsResident.No,
    taxIdType: isUsResident ? owner.taxIdType ?? undefined : undefined,
    taxId: isUsResident ? owner.taxId ?? undefined : undefined,
    internationalIdType: !isUsResident ? owner.internationalIdType ?? undefined : undefined,
    internationalId: !isUsResident ? owner.internationalId ?? undefined : undefined,
    isDirector: owner.types?.includes('director'),
    isUbo: owner.types?.includes('ubo'),
  };
};
