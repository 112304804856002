import { Button, Group, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { SubmitTarget } from '../types';

type Props = {
  isLoading: boolean;
  isDisabled: boolean;
  onSubmit: (target: SubmitTarget) => void;
  onClose: VoidFunction;
};

export const FooterContentForMsnBusiness = ({ isLoading, isDisabled, onClose, onSubmit }: Props) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  const secondaryNextButtonLabel = isDisabled
    ? isMobile
      ? formatMessage('activities.addVendor.searchAndAddVendorDetails.footer.msn.close')
      : formatMessage('activities.addVendor.searchAndAddVendorDetails.footer.msn.continue')
    : formatMessage('activities.addVendor.searchAndAddVendorDetails.footer.msn.close');

  return (
    <NewSinglePaymentStepLayout.Footer>
      {!isMobile && (
        <Button
          data-testid="layout-close-button"
          size="medium"
          variant="tertiary"
          label={formatMessage('activities.addVendor.searchAndAddVendorDetails.footer.msn.cancel')}
          onClick={onClose}
        />
      )}
      <Group width={isMobile ? 'full' : undefined}>
        <Group.Item {...(isMobile ? { grow: 1, shrink: 1, basis: 1 } : {})}>
          <NewSinglePaymentStepLayout.SecondaryNextButton
            data-testid={`${SubmitTarget.CLOSE}-button`}
            size="medium"
            isFullWidth
            isLoading={isLoading}
            isDisabled={isDisabled}
            label={secondaryNextButtonLabel}
            loadingText={formatMessage('app.loading')}
            onClick={() => onSubmit(SubmitTarget.CLOSE)}
          />
        </Group.Item>

        {!isDisabled && (
          <Group.Item {...(isMobile ? { grow: 1, shrink: 1, basis: 1 } : {})}>
            <NewSinglePaymentStepLayout.NextButton
              data-testid={`${SubmitTarget.CONTINUE}-button`}
              size="medium"
              isFullWidth
              isLoading={isLoading}
              label={formatMessage('activities.addVendor.searchAndAddVendorDetails.footer.msn.pay')}
              loadingText={formatMessage('app.loading')}
              onClick={() => onSubmit(SubmitTarget.CONTINUE)}
            />
          </Group.Item>
        )}
      </Group>
    </NewSinglePaymentStepLayout.Footer>
  );
};
