import { Bill } from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { useEffect } from 'react';

import { isBillPayable } from '../../functions';

type PartialBill = Partial<Pick<Bill, 'balance' | 'eBillAmountDue' | 'minimumAmount' | 'billerAccountBalance'>>;

export const useNonPositiveAmountsEffect = (effect: VoidFunction, bills: Array<PartialBill | undefined> = []) => {
  useEffect(() => {
    if (!bills || bills.length === 0) {
      return;
    }

    const nonPayableBills = bills.filter((bill) => bill && !isBillPayable(bill));

    if (nonPayableBills.length > 0) {
      Logger.log(`Found ${nonPayableBills.length} bills with non-positive amounts`);
      effect();
    }
  }, [bills, effect]);
};
