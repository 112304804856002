import { useDisclosure } from '@chakra-ui/react';
import { CheckAccountFormModel, MidConfidenceMatchingModal } from '@melio/ap-widgets';
import { Traits, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PaperCheckDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import {
  useSubmitVendorRecommendationDecision,
  useVendorRecommendationsById,
} from '@melio/platform-api/src/entities-v2/vendor-recommendations';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { AddVendorCheckDetailsScreen } from './screens';
import { AddVendorCheckDetailsActivityProps, SubmissionTargetType } from './types';

export const AddVendorCheckDetailsActivity = withAnalyticsContext<AddVendorCheckDetailsActivityProps>(
  ({ vendorId, onBack: onFirstStepBack, onClose, onError, onDone, origin = 'payment', defaultValues }) => {
    const { formatMessage } = useMelioIntl();
    const collection = useDeliveryMethods({ vendorId, enabled: false });
    const vendorModel = useVendor({ id: vendorId });
    const { showMessage } = useSystemMessage();
    const [submitData, setSubmitData] = useState<{ data?: PaperCheckDeliveryMethod; target?: SubmissionTargetType }>(
      {}
    );
    const [isMidConfidenceEnabled] = useDevFeature(FeatureFlags.MidConfidence, false, { shouldTrack: true });
    const { track } = useAnalytics();

    const {
      isOpen: isShowVendorMidConfidenceModal,
      onOpen: onShowVendorMidConfidenceModal,
      onClose: onCloseVendorMidConfidenceModal,
    } = useDisclosure();

    const {
      data: recommendedVendor,
      mutateAsync: getVendorRecommendation,
      isMutating: isFetchingRecommendedVendor,
    } = useVendorRecommendationsById({});

    const { mutateAsync: submitVendorRecommendationDecision, isMutating: isSubmittingVendorRecommendationDecision } =
      useSubmitVendorRecommendationDecision({});

    const handleOnDone = (data?: PaperCheckDeliveryMethod, target?: SubmissionTargetType) => {
      if (data) {
        onDone(data, target);
      }
    };

    const onSubmitVendorRecommendationDecision = async (
      decision: 'accepted' | 'rejected' | 'inconclusive',
      cta: string
    ) => {
      if (recommendedVendor?.recommendationId) {
        try {
          await submitVendorRecommendationDecision({
            recommendationId: recommendedVendor?.recommendationId,
            decision,
          });
          track('MidConfidenceModal', 'Status', {
            Flow: 'add-vendor-check-details',
            Intent: 'submit-vendor-recommendation-decision',
            PageName: 'add-vendor-check-details',
            Status: 'success',
            Cta: `${cta}-${decision}`,
          });

          if (decision === 'accepted') {
            showMessage({
              type: 'success',
              title: formatMessage('widgets.midConfidenceMatchingModal.successToast', {
                vendorName: recommendedVendor?.companyName || '',
              }),
              dataTestId: 'vendor-recommendation-success',
            });
          }
        } catch (error) {
          track('MidConfidenceModal', 'Status', {
            Flow: 'add-vendor-check-details',
            Intent: 'submit-vendor-recommendation-decision',
            PageName: 'add-vendor-check-details',
            Status: 'failure',
            Cta: `${cta}-${decision}`,
          });
        } finally {
          onCloseVendorMidConfidenceModal();
          handleOnDone(submitData.data, submitData.target);
        }
      } else {
        onCloseVendorMidConfidenceModal();
        handleOnDone(submitData.data, submitData.target);
      }
    };

    const handleOnCloseVendorMidConfidenceModal = async () => {
      await onSubmitVendorRecommendationDecision('inconclusive', 'close');
    };

    const handleOnConfirmRecommendedVendor = async () => {
      await onSubmitVendorRecommendationDecision('accepted', 'confirm');
    };

    const handleOnDeclineRecommendedVendor = async () => {
      await onSubmitVendorRecommendationDecision('rejected', 'decline');
    };

    const handleFail = (error: PlatformError) => {
      showMessage({ type: 'error', title: error.message });
      onError?.(error);
    };

    const { createTrackHandler, setTraits } = useAnalytics();
    const submitHandler = createTrackHandler<{ Status: 'failed' | 'succeeded' }>('AddVendorCheckDetails', 'Saved');
    const setDeliveryMethodTraits = () => {
      const traits: Traits = { added_delivery_method: true };

      setTraits(traits);
    };

    const createDeliveryMethod = (data: CheckAccountFormModel) => {
      const { printName, ...address } = data;
      return collection.create({ type: 'paper-check', details: { printName, address } });
    };

    const createDeliveryMethodAndSubmitTrack = (data: CheckAccountFormModel, target?: SubmissionTargetType) => {
      if (isMidConfidenceEnabled) {
        Promise.allSettled([
          getVendorRecommendation({ vendorId, address: { ...data } }),
          createDeliveryMethod(data),
        ]).then(([recommendationResult, deliveryMethodResult]) => {
          // Check if delivery method creation succeeded
          if (deliveryMethodResult.status === 'rejected') {
            submitHandler({ Status: 'failed' }, () => handleFail(deliveryMethodResult.reason));
            return;
          }

          // Delivery method creation was successful
          const deliveryMethod = deliveryMethodResult.value as PaperCheckDeliveryMethod;
          submitHandler({ Status: 'succeeded' });
          setDeliveryMethodTraits();

          // Check if recommendation was successful and has recommendation ID
          if (recommendationResult.status === 'fulfilled' && recommendationResult.value?.recommendationId) {
            setSubmitData({ data: deliveryMethod, target });
            onShowVendorMidConfidenceModal();
          } else {
            // Either recommendation failed or no recommendation found, proceed normally
            handleOnDone(deliveryMethod, target);
          }
        });
      } else {
        createDeliveryMethod(data)
          .then((data) => {
            submitHandler({ Status: 'succeeded' });
            setDeliveryMethodTraits();
            handleOnDone(data as PaperCheckDeliveryMethod, target);
          })
          .catch((error) => submitHandler({ Status: 'failed' }, () => handleFail(error)));
      }
    };

    return (
      <>
        <AddVendorCheckDetailsScreen
          isLoading={vendorModel.isLoading}
          vendor={vendorModel.data}
          onBack={onFirstStepBack}
          onClose={onClose}
          onDone={(data, _event, target) => createDeliveryMethodAndSubmitTrack(data, target)}
          isSaving={collection.isMutating || isFetchingRecommendedVendor || isSubmittingVendorRecommendationDecision}
          origin={origin}
          defaultValues={{ printName: vendorModel.data?.name || defaultValues?.printName, ...defaultValues }}
        />
        {isMidConfidenceEnabled &&
        vendorModel.data &&
        recommendedVendor?.recommendationId &&
        !isFetchingRecommendedVendor ? (
          <MidConfidenceMatchingModal
            isOpen={isShowVendorMidConfidenceModal}
            onClose={handleOnCloseVendorMidConfidenceModal}
            onConfirm={handleOnConfirmRecommendedVendor}
            onDecline={handleOnDeclineRecommendedVendor}
            localVendor={vendorModel.data}
            externalVendor={recommendedVendor}
            isSubmittingVendorRecommendationDecision={isSubmittingVendorRecommendationDecision}
            analyticsFields={{
              Flow: origin === 'vendor' ? 'add-vendor' : 'bill-flow',
              PageName: 'vendor-match-found',
              EntryPoint: 'choose-delivery-method',
              localVendorId: vendorId,
            }}
          />
        ) : null}
      </>
    );
  }
);

AddVendorCheckDetailsActivity.displayName = 'AddVendorCheckDetailsActivity';
