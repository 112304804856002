import { FormSelectNewOption, SelectNewOption } from '@melio/penny';
import { useState } from 'react';

export const useStateSelect = <T extends SelectNewOption<string> = FormSelectNewOption>(stateOptions: T[]) => {
  const [filteredOptions, setFilteredOptions] = useState<T[]>(stateOptions);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredOptions(stateOptions.filter((city) => city.label.toLowerCase().includes(e.target.value.toLowerCase())));
  };

  return { filteredStateOptions: filteredOptions, handleChange };
};
