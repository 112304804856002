import { useDeliveryMethodDecryptedAccountNumber } from '@melio/ap-domain';
import { BankAccountDeliveryMethod, DeliveryMethodType, Vendor } from '@melio/platform-api';

import { DeliveryMethodStateMap } from './types';
import { useDeliveryMethodCardDescription } from './useDeliveryMethodCardDescription';

export const useDeliveryMethodsWidgetInitialState = (vendor?: Vendor): DeliveryMethodStateMap => {
  const { getDescription } = useDeliveryMethodCardDescription();
  const bankAccount: BankAccountDeliveryMethod | undefined = vendor?.deliveryMethods.find(
    (dm) => dm.type === DeliveryMethodType.BankAccount
  ) as BankAccountDeliveryMethod;

  const { bankAccountNumber } = useDeliveryMethodDecryptedAccountNumber({
    suspense: true,
    bankAccount,
  });

  const vendorDeliveryMethodsToWidgetState = (vendor?: Vendor): DeliveryMethodStateMap => {
    const deliveryMethods = vendor?.deliveryMethods || [];
    const deliveryMethodsMap: DeliveryMethodStateMap = new Map();
    deliveryMethods.forEach((dm) => {
      switch (dm.type) {
        case DeliveryMethodType.BankAccount:
          deliveryMethodsMap.set(DeliveryMethodType.BankAccount, {
            values: dm.details,
            description: getDescription({
              values: {
                ...dm.details,
                accountNumber: dm.details.accountNumberLast4Digits ?? '',
              },
              type: DeliveryMethodType.BankAccount,
            }),
          });
          break;
        case DeliveryMethodType.PaperCheck:
          deliveryMethodsMap.set(DeliveryMethodType.PaperCheck, {
            values: {
              printName: dm.details.printName,
              line1: dm.details.address.line1,
              line2: dm.details.address.line2,
              city: dm.details.address.city,
              state: dm.details.address.state,
              postalCode: dm.details.address.postalCode,
            },
            description: getDescription({
              values: {
                printName: dm.details.printName,
                line1: dm.details.address.line1,
                line2: dm.details.address.line2,
                city: dm.details.address.city,
                state: dm.details.address.state,
                postalCode: dm.details.address.postalCode,
              },
              type: DeliveryMethodType.PaperCheck,
            }),
          });
          break;

        case DeliveryMethodType.PaypalBalance:
          deliveryMethodsMap.set(DeliveryMethodType.PaypalBalance, {
            values: {
              email: dm.details.paypalAccountEmail,
            },
            description: getDescription({
              type: DeliveryMethodType.PaypalBalance,
              values: {
                email: dm.details.paypalAccountEmail,
              },
            }),
          });
          break;

        default:
          break;
      }
    });
    return deliveryMethodsMap;
  };

  if (!vendor) {
    return new Map();
  }

  const state = vendorDeliveryMethodsToWidgetState(vendor);

  const bankAccountState = state.get(DeliveryMethodType.BankAccount);
  if (bankAccountNumber && bankAccountState) {
    state.set(DeliveryMethodType.BankAccount, {
      ...bankAccountState,
      values: {
        ...bankAccountState.values,
        accountNumber: bankAccountNumber,
      },
    });
  }

  return state;
};
