import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { ManageClientFormStep, ManageClientFormStepPayload } from '../../../components';
import { useStepManager } from '../../../hooks';
import { SubscriptionBillingPayor } from '../../../types';

export const useUpdateClientBillingOptionStepManager = ({
  defaultActiveId,
  clientName,
  planName,
  whoPays,
  firmName,
}: {
  defaultActiveId?: ManageClientFormStep;
  whoPays?: SubscriptionBillingPayor;
  clientName: string;
  firmName: string;
  planName: string;
}) => {
  const { formatMessage } = useMelioIntl();

  const stepsData = useMemo(
    () => [
      {
        stepId: ManageClientFormStep.BillingDetails,
        description: formatMessage(
          'activities.accountants.manageClient.form.billingDetails.updateBillingMethod.description.text',
          {
            clientName,
            planName,
          }
        ),
      },
      {
        stepId: ManageClientFormStep.ReviewAndConfirm,
        description: formatMessage(
          whoPays === SubscriptionBillingPayor.AccountingFirm
            ? `activities.accountants.manageClient.form.reviewAndConfirm.description.text.firmBilled.updateBillingOption`
            : `activities.accountants.manageClient.form.reviewAndConfirm.description.text.clientBilled.updateBillingOption`,
          {
            clientName,
            firmName,
          }
        ),
      },
    ],
    [formatMessage, clientName, planName, whoPays, firmName]
  );

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } = useStepManager<
    ManageClientFormStep,
    ManageClientFormStepPayload
  >({
    data: stepsData,
    defaultActiveId,
  });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
