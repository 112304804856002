import { FormInputs, FormWidgetProps, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Form, GridItem, Group, Icon, Link, SimpleGrid, Text, useMelioForm } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { MouseEventHandler } from 'react';
import * as yup from 'yup';

import { AddPaymentDetailsFields } from '../types';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return yup.object().shape({
    amount: yup
      .number()
      .nullable()
      .typeError(formatMessage('ar.guestPayment.activities.paymentDetails.form.amount.required.label'))
      .required(formatMessage('ar.guestPayment.activities.paymentDetails.form.amount.required.label'))
      .moreThan(0, formatMessage('ar.guestPayment.activities.paymentDetails.form.amount.min.label')),
    invoiceNumber: yup.string(),
    attachment: yup.mixed<File>().nullable(),
    note: yup.string(),
  }) as unknown as yup.SchemaOf<AddPaymentDetailsFields>;
};

type AddPaymentDetailsFormProps = FormWidgetProps<AddPaymentDetailsFields>;

export const AddPaymentDetailsForm = forwardRef<AddPaymentDetailsFormProps, 'form'>(
  ({ onSubmit, onSubmissionStateChange, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { formatMessage } = useMelioIntl();
    const [isNoteFieldVisible, noteFieldVisibility] = useBoolean();

    const { registerField, formProps } = useMelioForm<AddPaymentDetailsFields>({
      onSubmit,
      schema: useSchema(),
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
      defaultValues: { amount: undefined as never },
    });

    const handleClickAddNote: MouseEventHandler<HTMLLinkElement> = (e) => {
      e.preventDefault();
      noteFieldVisibility.on();
    };

    return (
      <Form data-component={AddPaymentDetailsForm.displayName} {...formProps} {...props} ref={ref}>
        <Group variant="vertical" spacing="xs">
          <Text color="semantic.text.secondary" textStyle="body4">
            {formatMessage('ar.domain.common.form.required.fields.info.text')}
          </Text>
          <Group variant="vertical" spacing="s">
            <Text textStyle="body2Semi">{formatMessage('ar.guestPayment.activities.paymentDetails.title.label')}</Text>
            <SimpleGrid columns={3} spacing={isMobile ? 's' : 'm'}>
              <GridItem colSpan={isMobile ? 3 : 2}>
                <FormInputs.AmountField
                  aria-label={formatMessage('ar.guestPayment.activities.paymentDetails.form.amount.label')}
                  labelProps={{
                    label: formatMessage('ar.guestPayment.activities.paymentDetails.form.amount.label'),
                  }}
                  {...registerField('amount')}
                />
              </GridItem>
              <GridItem colSpan={isMobile ? 3 : 1}>
                <Form.TextField
                  aria-label={formatMessage('ar.guestPayment.activities.paymentDetails.form.invoiceNumber.label')}
                  labelProps={{
                    label: formatMessage('ar.guestPayment.activities.paymentDetails.form.invoiceNumber.label'),
                  }}
                  {...registerField('invoiceNumber')}
                />
              </GridItem>
            </SimpleGrid>
            <Form.FileUpload
              acceptTypes={['pdf']}
              aria-label={formatMessage('ar.guestPayment.activities.paymentDetails.form.attachment.label')}
              labelProps={{
                label: formatMessage('ar.guestPayment.activities.paymentDetails.form.attachment.label'),
              }}
              placeholder={formatMessage('ar.guestPayment.activities.paymentDetails.form.attachment.placeholder')}
              {...registerField('attachment')}
            />
            {isNoteFieldVisible ? (
              <Form.TextArea
                data-private
                aria-label={formatMessage('ar.guestPayment.activities.paymentDetails.form.note.label')}
                labelProps={{ label: formatMessage('ar.guestPayment.activities.paymentDetails.form.note.label') }}
                placeholder={formatMessage('ar.guestPayment.activities.paymentDetails.form.note.placeholder')}
                maxChars={100}
                {...registerField('note')}
              />
            ) : (
              <Group alignItems="center" spacing="xxs">
                <Icon type="note-add" size="small" />
                <Text textStyle="body3">
                  <Link
                    href="#"
                    onClick={handleClickAddNote}
                    label={formatMessage('ar.guestPayment.activities.paymentDetails.form.note.button.label')}
                  />
                </Text>
              </Group>
            )}
          </Group>
        </Group>
      </Form>
    );
  }
);

AddPaymentDetailsForm.displayName = 'AddPaymentDetailsForm';
