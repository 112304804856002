import { PaymentWidgetFundingSourceType, usePaymentWidgetUrl } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { useGuestPaymentIntent } from '../../hooks';
import { GuestPaymentIntentParams, GuestPayorFundingSourceTypes } from '../../types';
import { FiservPaymentScreen } from './screens';

type FiservPaymentActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  fundingSourceType: GuestPayorFundingSourceTypes;
  onViewInvoice: VoidFunction;
  onSelectFundingSourceType: (type: GuestPayorFundingSourceTypes) => void;
  onError?: ARErrorFunction;
};

const guestPayorToWidgetTypes: Record<GuestPayorFundingSourceTypes, PaymentWidgetFundingSourceType> = {
  bank: 'bank',
  card: 'card',
};

export const FiservPaymentActivity = forwardRef<FiservPaymentActivityProps>(
  ({ guestPaymentIntentParams, onError, onViewInvoice, onSelectFundingSourceType, fundingSourceType }, ref) => {
    const { paymentRequestLink } = guestPaymentIntentParams;

    const { isLoading: isGuestPaymentIntentLoading, data: guestPaymentIntentData } = useGuestPaymentIntent({
      guestPaymentIntentParams,
      onError,
    });

    const shouldAllowFundingSourceSelection = !!(
      guestPaymentIntentData?.vendorPaymentDetails?.paymentOptions.isAchAllowed &&
      guestPaymentIntentData.vendorPaymentDetails.paymentOptions.isCardAllowed
    );

    const paymentWidgetFundingSourceType = guestPayorToWidgetTypes[fundingSourceType];

    const { data: paymentWidgetUrlData } = usePaymentWidgetUrl({
      fundingSourceType: paymentWidgetFundingSourceType,
      paymentRequestLink,
    });

    return (
      <FiservPaymentScreen
        onViewInvoice={onViewInvoice}
        isLoading={isGuestPaymentIntentLoading}
        guestPaymentIntentData={guestPaymentIntentData}
        paymentWidgetUrl={paymentWidgetUrlData?.paymentWidgetUrl}
        onSelectFundingSourceType={onSelectFundingSourceType}
        selectedFundingSource={fundingSourceType}
        shouldAllowFundingSourceSelection={shouldAllowFundingSourceSelection}
        ref={ref}
      />
    );
  }
);

FiservPaymentActivity.displayName = 'FiservPaymentActivity';
