// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioQueryClient } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';

import { useSubscriptionMe } from '../api';
import { SubscriptionMonitoringAction } from '../monitoring';
import { useCreateSubscriptionMarketingTrack } from './analytics';
import { usePlanInfo } from './usePlanInfo';
import { usePlansTiers } from './usePlansTiers';
import { useRedirects } from './useRedirects';

export const useSubscribeToFreePlan = () => {
  const { formatMessage } = useMelioIntl();
  const { getFreePlan } = usePlansTiers();
  // eslint-disable-next-line import/no-deprecated
  const { toast } = useToast();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { create: createSubscription, isMutating: isSubscribingToFreePlan } = useSubscriptionMe({
    enabled: false,
  });
  const queryClient = useMelioQueryClient();

  const freePlan = getFreePlan();

  const { planName } = usePlanInfo(freePlan?.id);

  const { track } = useAnalytics();

  const { endAction } = useMonitoring<SubscriptionMonitoringAction>();

  const { trackCreateSubscriptionMarketingEvent } = useCreateSubscriptionMarketingTrack({
    isFreePlan: true,
    planName: planName.toLowerCase(),
  });

  const trackCreateSubscription = (statusType: 'success' | 'failure') => {
    track('Organization', 'Status', {
      PageName: 'plan-selection',
      Intent: 'free-tier',
      Flow: 'subscription',
      Cta: 'subscribe',
      ChoosenPlan: 'free',
      StatusType: statusType,
      ...(statusType === 'failure' && {
        ErrorType: formatMessage('activities.subscription.checkout.summary.toast.error'),
      }),
    });
  };

  const showErrorToast = () =>
    toast({
      type: 'error',
      title: formatMessage('activities.subscription.checkout.summary.toast.error'),
      id: 'subscribe-free-plan-fail',
    });

  const subscribeToFreePlan = () => {
    const freePlanId = freePlan?.id;

    if (!freePlanId) {
      return showErrorToast();
    }

    return createSubscription({ planId: freePlanId, planCyclePeriod: 'monthly' })
      .then(() => {
        trackCreateSubscription('success');
        trackCreateSubscriptionMarketingEvent();

        void queryClient.invalidateQueries('collaborator');
        void queryClient.invalidateQueries('invitation');
        endAction('subscription_onboarding');
        return goToReturnUrlOrDashboard();
      })
      .catch(() => {
        trackCreateSubscription('failure');

        return showErrorToast();
      });
  };

  return { isSubscribingToFreePlan, subscribeToFreePlan };
};
