import { CompleteLegalInfoFormWidget, CompleteLegalInfoFormWidgetFields } from '@melio/ap-widgets';
import { Divider, Group, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { LegalInfoField } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { TermsAndConditionsLabel } from '../../../../terms-and-conditions-label';

export type CompleteLegalInfoScreenProps = {
  companyName: string;
  missingLegalInfoFields: LegalInfoField[];
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<CompleteLegalInfoFormWidgetFields>;
  onBack: VoidFunction;
  onClose?: VoidFunction;
  onDone: (data: CompleteLegalInfoFormWidgetFields) => void;
};

export const CompleteLegalInfoScreen = ({
  missingLegalInfoFields,
  isSaving,
  defaultValues,
  onBack,
  onClose,
  onDone,
  isLoading,
  companyName,
  ...props
}: CompleteLegalInfoScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, cancelButtonProps, submitButtonProps } =
    useFormSubmissionController<CompleteLegalInfoFormWidgetFields>();

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('review_and_confirm');
    endAction('batch_payment_confirm');
  }, [endAction]);

  useAnalyticsView('VerifyTaxDetails', !isLoading);

  const actionLabel = formatMessage('activities.completeLegalInfo.screens.completeLegalInfo.continue');

  return (
    <NewSinglePaymentStepLayout
      data-component="CompleteLegalInfoActivity.CompleteLegalInfoScreen"
      data-testid="complete-legal-info-activity-complete-legal-info-screen"
      isLoading={isLoading}
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          {onClose && <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />}
          <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.completeLegalInfo.screens.completeLegalInfo.title')}
      </NewSinglePaymentStepLayout.Title>
      <NewSinglePaymentStepLayout.Description>
        {formatMessage('activities.completeLegalInfo.screens.completeLegalInfo.description', { companyName })}
      </NewSinglePaymentStepLayout.Description>
      <SystemMessageDisplay data-testid="complete-legal-info-system-message" />
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="s">
          <CompleteLegalInfoFormWidget
            companyName={companyName}
            missingLegalInfoFields={missingLegalInfoFields}
            onSubmit={(data) => {
              startAction('complete_legal_info');
              onDone(data);
            }}
            isSaving={isSaving}
            defaultValues={defaultValues}
            onSubmissionStateChange={async (args) => {
              const { errors } = await args.control._executeSchema(Object.keys(args.getValues()));
              const requiredFields = Object.entries(errors).filter(([, value]) => value?.type === 'required');
              onSubmissionStateChange({
                ...args,
                submitButtonProps: { ...args.submitButtonProps, isDisabled: requiredFields.length > 0 },
              });
            }}
          />
          <Divider />
          <NewSinglePaymentStepLayout.Actions>
            <Group variant="vertical" alignItems="center" spacing="s">
              <TermsAndConditionsLabel actionLabel={actionLabel} />
              <NewSinglePaymentStepLayout.NextButton {...submitButtonProps} label={actionLabel} />
            </Group>
          </NewSinglePaymentStepLayout.Actions>
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

CompleteLegalInfoScreen.displayName = 'CompleteLegalInfoActivity.CompleteLegalInfoScreen';
