import { useConfig } from '@melio/platform-provider';
import { useEffect } from 'react';

export const useSetDocumentTitle = (title: string) => {
  const {
    tabTitle,
    settings: { embeddedExperience },
  } = useConfig();

  useEffect(() => {
    if (embeddedExperience?.enabled) {
      document.title = title;
    }
  }, [title, embeddedExperience]);

  useEffect(
    () => () => {
      if (embeddedExperience?.enabled) {
        document.title = tabTitle;
      }
    },
    [tabTitle, embeddedExperience]
  );
};
