import { LegalDocumentsApiClient, LegalDocumentTypeEnum } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useIsFetching, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useLegalDocumentsContent = ({ isEnabled }: { isEnabled: boolean }) => {
  const legalDocumentType = LegalDocumentTypeEnum.TermsAndConditions;
  const queryKey = ['LegalDocumentsApi', 'content', 'get', legalDocumentType];
  const query = useQuery({
    enabled: isEnabled,
    queryKey,
    queryFn: async () => {
      const response = await LegalDocumentsApiClient.getLegalDocumentsContent(legalDocumentType);
      return response.data.data.legalDocumentContent;
    },
  });

  const isFetching = useIsFetching(queryKey) > 0;
  return {
    ...query,
    isLoading: query.isInitialLoading,
    isFetching,
  };
};

export const useLegalDocumentsContentApprove = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
  const legalDocumentType = LegalDocumentTypeEnum.TermsAndConditions;
  const key = ['LegalDocumentsApi', 'content', 'post', legalDocumentType];
  const queryClient = useQueryClient();

  const mutation = useMutation(
    key,
    async () => {
      await LegalDocumentsApiClient.postLegalDocumentsContent(legalDocumentType);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['LegalDocumentsApi', 'details']);
        onSuccess?.();
      },
    }
  );

  const isFetching = useIsFetching(key) > 0;
  return {
    ...mutation,
    isFetching: mutation.isLoading || isFetching,
  };
};
