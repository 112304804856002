import {
  addWildcardToRoutes,
  Navigate,
  useCurrentRoute,
  useFlowRouting,
  withLocalization,
  withOutlet,
} from '@melio/ar-domain';
import { Route, Routes } from 'react-router-dom';

import {
  ActivateOrganizationActivity,
  ActivationSuccessActivity,
  BusinessDetailsActivity,
  ConfirmReceivingAccountActivity,
  GetStartedActivity,
  OwnershipDetailsActivity,
} from '../activities';

type ActivationFlowProps = {
  onCreateInvoice?: VoidFunction;
  onGoToDashboard?: VoidFunction;
};

export const ActivationFlow = withLocalization(({ onGoToDashboard, onCreateInvoice }: ActivationFlowProps) => {
  const { Paths, ...Router } = useActivationFlow();

  return (
    <Routes>
      <Route index path="*" element={<Navigate to={Router.DefaultPath} replace />} />

      <Route
        path={Paths.GetStarted}
        element={<GetStartedActivity onClose={onGoToDashboard} onDone={Router.goToConfirmReceivingAccount} />}
      />
      <Route
        path={Paths.ConfirmReceivingAccount}
        element={
          <ConfirmReceivingAccountActivity
            onDone={Router.goToBusinessDetails}
            onBack={Router.goToGetStarted}
            onClose={Router.goToGetStarted}
          />
        }
      />
      <Route
        path={Paths.BusinessDetails}
        element={
          <BusinessDetailsActivity
            onDone={Router.goToOwnershipsDetails}
            onBack={Router.goToConfirmReceivingAccount}
            onClose={Router.goToGetStarted}
          />
        }
      />

      <Route
        path={Paths.OwnershipDetails}
        element={withOutlet(
          <OwnershipDetailsActivity
            onDone={Router.goToActivateOrganization}
            onBack={Router.goToBusinessDetails}
            onClose={Router.goToGetStarted}
            isSaving={Router.currentRoute === 'ActivateOrganization'}
          />
        )}
      >
        <Route
          path={Paths.ActivateOrganization}
          element={
            <ActivateOrganizationActivity
              onDone={Router.goToActivationSuccess}
              onError={() => Router.goToOwnershipsDetails({ keepSystemMessage: true })}
            />
          }
        />
      </Route>

      <Route
        path={Paths.ActivationSuccess}
        element={<ActivationSuccessActivity onCreateInvoice={onCreateInvoice} onGoToDashboard={onGoToDashboard} />}
      />
    </Routes>
  );
});

ActivationFlow.displayName = 'ActivationFlow';

const useActivationFlow = () => {
  enum Paths {
    GetStarted = 'get-started',
    ConfirmReceivingAccount = 'receiving-account',
    BusinessDetails = 'business-details',
    OwnershipDetails = 'ownership-details',
    ActivateOrganization = 'activate-organization',
    ActivationSuccess = 'activation-success',
  }

  const { createCallback } = useFlowRouting<Paths>({ withSearchparams: true });
  const currentRoute = useCurrentRoute(Paths);

  return {
    Paths: addWildcardToRoutes(Paths),
    DefaultPath: Paths.GetStarted,
    currentRoute,
    goToGetStarted: createCallback(Paths.GetStarted),
    goToBusinessDetails: createCallback(Paths.BusinessDetails),
    goToOwnershipsDetails: createCallback(Paths.OwnershipDetails),
    goToConfirmReceivingAccount: createCallback(Paths.ConfirmReceivingAccount),
    goToActivateOrganization: createCallback(Paths.ActivateOrganization, { pathPrefix: [Paths.OwnershipDetails] }), // ...new callback...
    goToActivationSuccess: createCallback(Paths.ActivationSuccess),
  };
};
