import { forwardRef } from '@melio/platform-utils';

import { mockPayout } from './mocks';
import { PayoutsTabScreen } from './screens';

export type PayoutsTabActivityProps = {
  onViewInvoice?: (invoiceId: string) => void;
  onError?: ARErrorFunction;
};

export const PayoutsTabActivity = forwardRef<PayoutsTabActivityProps>(({ onViewInvoice }, ref) => {
  const payouts = mockPayout;
  return (
    <>
      <PayoutsTabScreen payouts={payouts} onViewInvoice={onViewInvoice} ref={ref} />
    </>
  );
});
