import { getBusinessDaysDiff } from '@melio/melio-business-days';
import { DeliveryMethodType } from '@melio/platform-api';

import { FundsDebitDateSectionProps } from '../types';

export const fundsDebitDateResolver = ({ deliveryType, scheduledDate, deliveryDate }: FundsDebitDateSectionProps) => {
  const isDmCheck = deliveryType === DeliveryMethodType.PaperCheck;
  const daysToDebit = scheduledDate && deliveryDate ? getBusinessDaysDiff(scheduledDate, deliveryDate) : 0;

  return {
    isDmCheck,
    daysToDebit,
  };
};
