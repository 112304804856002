import { Grid, Link, LoadingContainer, SectionBanner } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { Plan, usePlansTiers } from '@melio/subscriptions';

import { useIsSmallScreen } from '../../hooks';
import { FirmClientPlan } from './components/FirmClientPlan';

export type FirmClientSubscriptionPlanSelectionActivityProps = {
  selectedSubscriptionPlanId?: string;
  onSelect: (plan: Plan) => void;
  isSaving: boolean;
  discountPercentage?: number;
};

export const FirmClientSubscriptionPlanSelectionActivity = ({
  selectedSubscriptionPlanId,
  onSelect,
  isSaving,
  discountPercentage,
}: FirmClientSubscriptionPlanSelectionActivityProps) => {
  const [enableUnlimitedAchPlan] = useDevFeature(FeatureFlags.UnlimitedAchPlan, false);
  const isSmallScreen = useIsSmallScreen();
  const clientPlans = usePlansTiers().getClientPlans();

  const { formatMessage } = useMelioIntl();
  const { supportEmail } = useConfig().settings;

  return (
    <LoadingContainer isLoading={isSaving}>
      {!clientPlans.length && (
        <SectionBanner
          variant="critical"
          description={formatMessage('activities.accountants.planSelection.missing-plans.error', {
            link: <Link href={`mailto:${supportEmail}`} label={supportEmail} newTab />,
          })}
        />
      )}
      <Grid
        justifyContent="center"
        gap="m"
        gridTemplateColumns={isSmallScreen || enableUnlimitedAchPlan ? '1fr' : '1fr 1fr'}
        data-testid="firm-client-subscription-plan-selection-screen"
      >
        {clientPlans.map((plan) => (
          <FirmClientPlan
            key={plan.id}
            plan={plan}
            isSelected={selectedSubscriptionPlanId === plan.id}
            onSelect={() => !isSaving && onSelect(plan)}
            discountPercentage={discountPercentage}
          />
        ))}
      </Grid>
    </LoadingContainer>
  );
};
