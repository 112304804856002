import { Container, Group, Loader, NakedButton, SectionBanner, Text } from '@melio/penny';
import { DeliveryMethodType, DeliveryMethodTypeOption, useDeliveryMethodTypeOptions } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { BankAccountCard } from './components/forms-by-type/BankAccountCard';
import { PaperCheckAccountCard } from './components/forms-by-type/PaperCheckAccountCard';
import { PaypalBalanceAccountCard } from './components/forms-by-type/PaypalBalanceAccountCard';
import {
  AddVendorDeliveryMethodSectionProps,
  AddVendorPaypalAccountForm,
  BankAccountFormModel,
  DeliveryMethodCardOnSubmit,
  DeliveryMethodStateMap,
  PaperCheckForm,
} from './types';
import { useDeliveryMethodCardDescription } from './useDeliveryMethodCardDescription';
import { useDeliveryMethodsWidgetInitialState } from './useDeliveryMethodsWidgetInitialState';

const getSupportedDeliveryMethods = (dmTypeOptions?: DeliveryMethodTypeOption[]) => {
  if (!dmTypeOptions?.length) {
    return [];
  }

  return dmTypeOptions.filter(({ supported }) => !!supported).map((option) => option.type);
};

const getDeliveryMethodCardByType = ({ type }: { type: DeliveryMethodType }) => {
  switch (type) {
    case DeliveryMethodType.BankAccount:
      return BankAccountCard;

    case DeliveryMethodType.PaperCheck:
      return PaperCheckAccountCard;

    case DeliveryMethodType.PaypalBalance:
      return PaypalBalanceAccountCard;
    default:
      return null;
  }
};

export const VendorDeliveryMethodsSection = ({
  onDeliveryMethodChanged,
  isSaving,
  vendor,
  shouldShowEmptyDeliveryMethodsError,
}: AddVendorDeliveryMethodSectionProps) => {
  const { formatMessage } = useMelioIntl();
  const { getDescription } = useDeliveryMethodCardDescription();
  const [expandedType, setIsExpanded] = useState<DeliveryMethodType | null>(null);
  const initialState = useDeliveryMethodsWidgetInitialState(vendor);
  const [currentDeliveryMethods, setCurrentDeliveryMethods] = useState<DeliveryMethodStateMap>(() => initialState);

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const { data: dmTypeOptions, isLoading } = useDeliveryMethodTypeOptions({ vendorId: vendor?.id });
  const supportedDeliveryMethodTypes = getSupportedDeliveryMethods(dmTypeOptions);

  const onSubmitDeliveryMethod = (args: {
    values: BankAccountFormModel | PaperCheckForm | AddVendorPaypalAccountForm;
    type: DeliveryMethodType;
  }) => {
    const { values, type } = args;
    const newDeliveryMethods = new Map(
      currentDeliveryMethods.set(type, {
        values,
        description: getDescription({ type, values }),
      })
    );
    setCurrentDeliveryMethods(newDeliveryMethods);
    setIsExpanded(null);
    setShowMoreOptions(false);
    const array = Array.from(newDeliveryMethods);
    const ret = array.map(([type, values]) => ({ type, values: values.values }));
    onDeliveryMethodChanged(ret);
  };

  const onAddClick = (type: DeliveryMethodType) => {
    setIsExpanded(type);
  };

  const currentDeliveryMethodsArray = Array.from(currentDeliveryMethods);

  if (isLoading || isSaving) {
    return (
      <Container paddingY="xl" alignItems="center" justifyContent="center">
        <Loader />
      </Container>
    );
  }

  if (currentDeliveryMethodsArray.length) {
    const availableDeliveryMethodTypesToAdd = supportedDeliveryMethodTypes.filter(
      (type) => !currentDeliveryMethodsArray.find(([key]) => key === type)
    );
    return (
      <Group variant="vertical" data-testid="add-vendor-dm-widget-wrapper">
        <Group variant="vertical">
          {currentDeliveryMethodsArray.map(([type, values]) => {
            const Component = getDeliveryMethodCardByType({ type });

            if (!Component) {
              return null;
            }

            return (
              <Component
                description={values.description}
                key={`delivery-method-card-${type}-existing`}
                isExpanded={expandedType === type}
                isDisabled={false}
                onCancel={() => setIsExpanded(null)}
                onAdd={() => onAddClick(type)}
                onSubmit={(values: DeliveryMethodCardOnSubmit) =>
                  onSubmitDeliveryMethod({
                    type,
                    values,
                  })
                }
                // @ts-expect-error - Marik Sh: Need to find a solution to types.
                values={values.values}
              />
            );
          })}
        </Group>
        {!showMoreOptions && !!availableDeliveryMethodTypesToAdd.length && (
          <NakedButton
            data-testid="add-more-delivery-methods"
            label={formatMessage('activities.deliveryMethodsWidget.forms.addAnother')}
            onClick={() => {
              setShowMoreOptions(true);
            }}
            variant="secondary"
          />
        )}
        {showMoreOptions && (
          <>
            <Text textStyle="body3semi" color="semantic.text.secondary">
              {formatMessage('activities.deliveryMethodsWidget.forms.moreDeliveryOptions')}
            </Text>
            <Group spacing="s" variant="vertical">
              {availableDeliveryMethodTypesToAdd.map((type) => {
                const FormComponent = getDeliveryMethodCardByType({ type });

                if (!FormComponent) {
                  return null;
                }

                return (
                  <FormComponent
                    key={`add-delivery-method-card-${type}-new`}
                    isExpanded={expandedType === type}
                    isDisabled={!!expandedType && expandedType !== type}
                    onCancel={() => setIsExpanded(null)}
                    onAdd={() => onAddClick(type)}
                    onSubmit={(values: DeliveryMethodCardOnSubmit) =>
                      onSubmitDeliveryMethod({
                        type,
                        values,
                      })
                    }
                  />
                );
              })}
            </Group>
          </>
        )}
      </Group>
    );
  }

  return (
    <Group spacing="s" variant="vertical" data-testid="add-vendor-dm-widget-wrapper">
      <Container aria-live="polite" aria-relevant="additions removals">
        {shouldShowEmptyDeliveryMethodsError ? (
          <SectionBanner
            variant="critical"
            data-testid="vendorDeliveryMethodsSection-deliveryMethodsEmptyBanner"
            description={formatMessage('activities.deliveryMethodsWidget.forms.deliveryMethodsEmptyError')}
          />
        ) : null}
      </Container>
      {supportedDeliveryMethodTypes.map((type) => {
        const FormComponent = getDeliveryMethodCardByType({ type });

        if (!FormComponent) {
          return null;
        }

        return (
          <FormComponent
            key={`delivery-method-card-${type}`}
            isExpanded={expandedType === type}
            isDisabled={!!expandedType && expandedType !== type}
            onCancel={() => setIsExpanded(null)}
            onAdd={() => onAddClick(type)}
            onSubmit={(values: DeliveryMethodCardOnSubmit) => onSubmitDeliveryMethod({ type, values })}
          />
        );
      })}
    </Group>
  );
};
