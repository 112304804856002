import { Button, Container, Drawer, Group, Link, SectionBanner, Text } from '@melio/penny';
import { FundingSource } from '@melio/platform-api/src/entities-v2/funding-source/types';
import { useMelioIntl } from '@melio/platform-i18n';
import {
  PaymentMethodView,
  SubscriptionPaymentMethod,
} from '@melio/subscriptions/src/ui/activities/SubscriptionCheckout/components/SubscriptionCheckoutSelection/components/SubscriptionPaymentMethod';
import { useEffect, useState } from 'react';

import { useGetSearchParamsValues } from '../../hooks/useGetSearchParamsValues';
import { AnalyticsPaymentDrawerActionType, SubscriptionBillingPayor } from '../../types';
import { FundingSourceSelect } from './components/FundingSourceSelect';

type AccountantsFundingSourceDrawerProps = {
  isOpen: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (fundingSourceId: string | null) => void;
  onClose: (action?: AnalyticsPaymentDrawerActionType) => void;
  subscriptionBillingPayor: SubscriptionBillingPayor;
  clientFundingSources?: Array<FundingSource>;
  onNavigateToClientPaymentMethodsSettings?: VoidFunction;
};

export const AddPaymentMethodDrawer = ({
  isOpen,
  fundingSourceId,
  setFundingSourceId,
  onClose,
  subscriptionBillingPayor,
  clientFundingSources,
  onNavigateToClientPaymentMethodsSettings,
}: AccountantsFundingSourceDrawerProps) => {
  const { formatMessage } = useMelioIntl();
  const { getParamsValues } = useGetSearchParamsValues(['fundingSourceId']);
  const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();
  const defaultFundingSourceId = fundingSourceIdFromUrl || fundingSourceId;
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string | null>(defaultFundingSourceId);
  const [paymentMethodViewState, setPaymentMethodViewState] = useState<PaymentMethodView | null>(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedFundingSourceId(defaultFundingSourceId);
    }
  }, [defaultFundingSourceId, fundingSourceId, isOpen]);

  const onCancel = () => {
    onClose('cancel');
  };

  const onConfirm = () => {
    setFundingSourceId(selectedFundingSourceId);
    onClose('continue');
  };

  const onExit = () => {
    onClose('exit');
  };

  const paymentMethodListRendererMapping = {
    [SubscriptionBillingPayor.AccountingFirm]: () => (
      <SubscriptionPaymentMethod
        fundingSourceId={selectedFundingSourceId}
        setFundingSourceId={setSelectedFundingSourceId}
        onPaymentMethodViewChanged={setPaymentMethodViewState}
      />
    ),
    [SubscriptionBillingPayor.Client]: () => (
      <Group variant="vertical" width="full">
        <FundingSourceSelect
          fundingSources={clientFundingSources || []}
          onCardClick={(fundingSource) => setSelectedFundingSourceId(fundingSource.id)}
          selectedId={selectedFundingSourceId}
          addLabel={formatMessage('activities.subscription.checkout.fundingSourceSelection.bank.addAnother')}
        />
        {onNavigateToClientPaymentMethodsSettings && (
          <SectionBanner
            isCompact
            variant="informative"
            description={formatMessage(
              'activities.accountants.paymentMethodCard.drawer.addPaymentMethod.label.client',
              {
                action: (
                  <Link
                    href=""
                    label={formatMessage(
                      'activities.accountants.paymentMethodCard.addPaymentMethod.action.label.client'
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      onNavigateToClientPaymentMethodsSettings();
                    }}
                  />
                ),
              }
            )}
          />
        )}
      </Group>
    ),
  };

  return (
    <Drawer
      data-testid="payment-method-drawer"
      isOpen={isOpen}
      header={
        <Text textStyle="heading2Semi">
          {formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.header.label')}
        </Text>
      }
      body={<Container paddingX="s">{paymentMethodListRendererMapping[subscriptionBillingPayor]()}</Container>}
      footer={
        paymentMethodViewState !== PaymentMethodView.ADD_NEW && (
          <Container justifyContent="space-between">
            <Button
              data-testid="payment-method-drawer-cancel"
              label={formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.footer.cancel')}
              variant="secondary"
              onClick={onCancel}
            />
            <Button
              isDisabled={!selectedFundingSourceId}
              data-testid="payment-method-drawer-continue"
              label={formatMessage('activities.accountants.paymentMethodCard.paymentMethodDrawer.footer.continue')}
              variant="primary"
              onClick={onConfirm}
            />
          </Container>
        )
      }
      onClose={onExit}
    />
  );
};
