import { Container, Group, Illustration, Text, useBreakpoint } from '@melio/penny';
import {
  Todo,
  TodoActivateCollaborators,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPayment,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItems,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApprove,
  TodoPaymentsToApproveTypeEnum,
  todosOrder,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { EnrichedTodo, isEmptySection } from '../utils';
import { TodoSectionActivateCollaborators } from './section-types/TodoSectionActivateCollaborators';
import { TodoSectionFailedPayments } from './section-types/TodoSectionFailedPayments';
import { TodoSectionOverdueInboxItems } from './section-types/TodoSectionOverdueInboxItems';
import { TodoSectionOverdueInFutureInboxItems } from './section-types/TodoSectionOverdueInFutureInboxItems';
import { TodoSectionPaymentsToApprove } from './section-types/TodoSectionPaymentsToApprove';

type TodosDrawerBodyProps = {
  todos: EnrichedTodo<Todo>[];
  totalCount: number;
  markAsSeen: () => void;
  onClose: VoidFunction;
};

export const TodosDrawerBody = ({ todos, totalCount, markAsSeen, onClose }: TodosDrawerBodyProps) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  useEffect(() => {
    markAsSeen();
  }, [markAsSeen]);

  const renderFailedPaymentSection = (todos: EnrichedTodo<Todo>[]) => {
    const failedPaymentTodo = todos.find(
      (t): t is EnrichedTodo<TodoFailedPayment> => t.type === TodoFailedPaymentTypeEnum.FailedPayments
    );
    if (isEmptySection(failedPaymentTodo)) {
      return null;
    }
    return (
      <TodoSectionFailedPayments
        todo={failedPaymentTodo}
        key={TodoFailedPaymentTypeEnum.FailedPayments}
        onNavigate={onClose}
      />
    );
  };

  const renderPaymentsToApproveSection = (todos: EnrichedTodo<Todo>[]) => {
    const paymentsToApprove = todos.find(
      (t): t is EnrichedTodo<TodoPaymentsToApprove> => t.type === TodoPaymentsToApproveTypeEnum.PaymentsToApprove
    );
    if (isEmptySection(paymentsToApprove)) {
      return null;
    }
    return (
      <TodoSectionPaymentsToApprove
        todo={paymentsToApprove}
        key={TodoPaymentsToApproveTypeEnum.PaymentsToApprove}
        onNavigate={onClose}
      />
    );
  };

  const renderOverdueInboxItemsSection = (todos: EnrichedTodo<Todo>[]) => {
    const overdueInboxItems = todos.find(
      (t): t is EnrichedTodo<TodoOverdueInboxItems> => t.type === TodoOverdueInboxItemsTypeEnum.OverdueInboxItems
    );
    if (isEmptySection(overdueInboxItems)) {
      return null;
    }
    return (
      <TodoSectionOverdueInboxItems
        todo={overdueInboxItems}
        key={TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}
        onNavigate={onClose}
      />
    );
  };

  const renderOverdueInFutureInboxItemsSection = (todos: EnrichedTodo<Todo>[]) => {
    const overdueIn7Days = todos.find(
      (t): t is EnrichedTodo<TodoOverdueIn7DaysInboxItems> =>
        t.type === TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems
    );
    const overdueInMoreThan7Days = todos.find(
      (t): t is EnrichedTodo<TodoOverdueInMoreThan7DaysInboxItems> =>
        t.type === TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems
    );
    if (isEmptySection(overdueIn7Days) && isEmptySection(overdueInMoreThan7Days)) {
      return null;
    }
    return (
      <TodoSectionOverdueInFutureInboxItems
        overdueIn7Days={overdueIn7Days}
        overdueInMoreThan7Days={overdueInMoreThan7Days}
        key={TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}
        onNavigate={onClose}
      />
    );
  };

  const renderActivateCollaboratorsSection = (todos: EnrichedTodo<Todo>[]) => {
    const activateCollaborator = todos.find(
      (t): t is EnrichedTodo<TodoActivateCollaborators> =>
        t.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators
    );
    if (isEmptySection(activateCollaborator)) {
      return null;
    }
    return (
      <TodoSectionActivateCollaborators
        todo={activateCollaborator}
        key={TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}
        onNavigate={onClose}
      />
    );
  };

  const renderTodosSection = () => {
    if (!todos) {
      return null;
    }
    let isOverdueInFutureInboxItemsHandled = false;
    return todosOrder
      .map((type) => {
        switch (type) {
          case TodoFailedPaymentTypeEnum.FailedPayments:
            return renderFailedPaymentSection(todos);
          case TodoPaymentsToApproveTypeEnum.PaymentsToApprove:
            return renderPaymentsToApproveSection(todos);
          case TodoOverdueInboxItemsTypeEnum.OverdueInboxItems:
            return renderOverdueInboxItemsSection(todos);
          case TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems:
          case TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems: {
            if (isOverdueInFutureInboxItemsHandled) {
              return null;
            }
            isOverdueInFutureInboxItemsHandled = true;
            return renderOverdueInFutureInboxItemsSection(todos);
          }
          case TodoActivateCollaboratorsTypeEnum.ActivateCollaborators:
            return renderActivateCollaboratorsSection(todos);
          default:
            return null;
        }
      })
      .filter((section) => !!section);
  };

  const renderEmptyState = () => (
    <Container paddingY={isExtraSmallScreen ? 's' : 'none'} data-testid="todos-drawer-empty-state">
      <Group
        height="full"
        width="full"
        alignItems="center"
        justifyContent="center"
        variant="vertical"
        textAlign="center"
      >
        <Illustration size="medium" type="no-items" />
        <Text textStyle="heading2Semi">{formatMessage('widgets.todosDrawer.section.emptyState.title')}</Text>
        <Text textStyle="body4" color="global.neutral.900">
          {formatMessage('widgets.todosDrawer.section.emptyState.description')}
        </Text>
      </Group>
    </Container>
  );

  return totalCount === 0 ? (
    renderEmptyState()
  ) : (
    <Container paddingY={isExtraSmallScreen ? 's' : 'none'}>
      <Group variant="vertical" hasDivider spacing="s">
        {renderTodosSection()}
      </Group>
    </Container>
  );
};
