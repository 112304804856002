import {
  ComboboxOption,
  FlagIcon,
  FlagKey,
  FormField,
  FormSelectNewProps,
  Group,
  SelectNew,
  SelectNewOption,
  SelectNewSection,
  Text,
  VisuallyHidden,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useMemo, useState } from 'react';

import { countryOptions, usCountryOption } from './const';

export type CountrySelectProps = Omit<FormSelectNewProps, 'options'>;
type CountrySelectSection = SelectNewSection<string, SelectNewOption<string>>;

export const CountrySelect = (props: CountrySelectProps) => {
  const { formatMessage } = useMelioIntl();
  const [query, setQuery] = useState<string>('');

  const onSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  }, []);

  const sections = useMemo<[CountrySelectSection, CountrySelectSection]>(
    () => [
      { label: null, options: [usCountryOption] },
      {
        label: formatMessage('widgets.vendors.countryCode.otherCountriesSection'),
        options: countryOptions.filter(({ value }) => value !== usCountryOption.value),
      },
    ],
    [formatMessage]
  );

  const filteredSections = useMemo(() => {
    const filterOptions = (options: SelectNewOption<string>[]) =>
      options.filter(({ label }) => label.toLowerCase().startsWith(query.toLowerCase()));

    const usSectionFilteredOptions = filterOptions(sections[0].options);

    const otherCountriesSectionFilteredOptions = filterOptions(sections[1].options);

    return [
      ...(usSectionFilteredOptions.length ? [{ ...sections[0], options: usSectionFilteredOptions }] : []),
      ...(otherCountriesSectionFilteredOptions.length
        ? [{ ...sections[1], options: otherCountriesSectionFilteredOptions }]
        : []),
    ];
  }, [query, sections]);

  const renderSelectOption = (option: ComboboxOption<string>) => (
    <Group alignItems="center" spacing="s">
      <FlagIcon countryCode={option.value as FlagKey} size="medium" />
      <Group alignItems="center" spacing="xs">
        <Text textStyle="body3" shouldSupportEllipsis color="inherit">
          {option.label}
        </Text>
      </Group>
    </Group>
  );

  const {
    colSpan,
    labelProps,
    showOptionalIndicator,
    control,
    name,
    'data-testid': dataTestId,
    isReadOnly,
    ...selectNewProps
  } = props;

  return (
    <FormField
      control={control}
      name={name}
      data-testid={`form-field-${name}`}
      labelProps={
        labelProps || {
          tooltipProps: isReadOnly
            ? {
                content: (
                  <>
                    <VisuallyHidden>{formatMessage('widgets.vendors.countryCode.tooltip')} tooltip</VisuallyHidden>
                    {formatMessage('widgets.vendors.countryCode.tooltip')}
                  </>
                ),
              }
            : undefined,
          label: formatMessage('widgets.vendors.countryCode.label'),
        }
      }
      showOptionalIndicator={showOptionalIndicator}
      colSpan={colSpan}
      isReadOnly={isReadOnly}
      render={(fieldProps) => (
        <SelectNew
          {...fieldProps}
          {...selectNewProps}
          data-testid={`form-input-${name}`}
          placeholder={formatMessage('widgets.vendors.countryCode.placeholder')}
          searchBarProps={{
            value: query,
            onChange: onSearchChange,
            options: filteredSections,
          }}
          options={sections}
          optionRenderer={renderSelectOption}
        />
      )}
    />
  );
};
