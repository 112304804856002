import { ZeroState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

export type ZeroStateProps = {
  onCreateInvoice?: () => void;
};

export const PaymentsTableZeroState = forwardRef<ZeroStateProps>(({ onCreateInvoice, ...props }, ref) => (
  <ZeroState
    ref={ref}
    illustrationType="payout-add"
    secondaryLabel="ar.dashboard.activities.paymentsTable.emptyState.noData.label"
    actions={
      onCreateInvoice
        ? [
            {
              label: 'ar.dashboard.activities.invoiceTable.emptyState.create.button.label',
              onClick: onCreateInvoice,
              variant: 'primary',
              testId: 'empty-state-create-invoice-button',
            },
          ]
        : []
    }
    dataTestId="payments-dashboard-no-payments"
    {...props}
  />
));

PaymentsTableZeroState.displayName = 'PaymentsTableZeroState';
