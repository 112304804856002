import { useNavigate, useSearchParams } from 'react-router-dom';

import { FirmClientsTableFilters } from '../components/FirmClients/types';

enum QueryParamKeys {
  SHOW_HIDDEN_CLIENTS = 'showHiddenClients',
  SEARCH_TEXT = 'searchText',
}

export const useClientsTableQueryParam = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const searchParamText = searchParams.get(QueryParamKeys.SEARCH_TEXT) ?? '';
  const searchParamShowHiddenClients = searchParams.get(QueryParamKeys.SHOW_HIDDEN_CLIENTS) ?? '';

  const applyFilter = (filter: Partial<FirmClientsTableFilters['filters']>) => {
    filter.searchText
      ? searchParams.set(QueryParamKeys.SEARCH_TEXT, filter.searchText)
      : searchParams.delete(QueryParamKeys.SEARCH_TEXT);
    searchParams.set(QueryParamKeys.SHOW_HIDDEN_CLIENTS, String(!!filter.showHiddenClients));
    navigate({ search: searchParams.toString() }, { replace: true, state: { keepToast: true } });
  };

  return {
    filters: {
      searchParamText,
      searchParamShowHiddenClients,
    },
    applyFilter,
  };
};
