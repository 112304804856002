import { FormattedMessage, InvoicePreferences, useMelioIntl } from '@melio/ar-domain';
import { Group, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { AddCustomPaymentDetailsButton, EditCustomPaymentDetails, PaymentOptionSwitcher } from '../components';
import { FeesPaidBy } from '../types';

export type AcceptedPaymentMethodsScreenProps = {
  invoicePreferences: InvoicePreferences;
  onEditCustomPaymentDetails: VoidFunction;
  onRemoveCustomPaymentDetails: VoidFunction;
  onToggleACHPaymentsAllowed: (enabled: boolean) => void;
  onToggleCardPaymentsAllowed: (enabled: boolean) => void;
  isUpdating: boolean;
  feesPaidBy?: FeesPaidBy;
  onEditFeeAbsorption: VoidFunction;
  hasVerifiedDeliveryMethod: boolean;
};

export const AcceptedPaymentMethodsScreen = forwardRef<AcceptedPaymentMethodsScreenProps>(
  ({
    invoicePreferences,
    onEditCustomPaymentDetails,
    onRemoveCustomPaymentDetails,
    onToggleACHPaymentsAllowed,
    onToggleCardPaymentsAllowed,
    isUpdating,
    feesPaidBy = 'payor',
    onEditFeeAbsorption,
    hasVerifiedDeliveryMethod,
  }) => {
    const { formatMessage } = useMelioIntl();

    const {
      customPayInstructions: customPaymentDetails,
      allowCardPayment: isCardPaymentAllowed,
      allowACHPayment: isACHPaymentAllowed,
    } = invoicePreferences;

    const hasCustomPaymentDetails = !!customPaymentDetails;

    return (
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="xs">
          <Text textStyle="body1Semi" as="h3">
            <FormattedMessage id="ar.settings.activities.acceptedPaymentMethods.title.text" />
          </Text>
          <Text textStyle="body2">
            <FormattedMessage id="ar.settings.activities.acceptedPaymentMethods.subtitle.text" />
          </Text>
        </Group>
        <Group variant="vertical">
          <PaymentOptionSwitcher
            paymentOptionType="ACH"
            iconType="bank"
            title={formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.ACH.title.text'
            )}
            subtitle={formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.ACH.subtitle.text'
            )}
            value={isACHPaymentAllowed}
            onChange={onToggleACHPaymentsAllowed}
            isUpdating={isUpdating}
          />
          <PaymentOptionSwitcher
            paymentOptionType="card"
            iconType="credit-card"
            title={formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.Card.title.text'
            )}
            subtitle={formatMessage(
              `ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.Card.subtitle.absorbedFeesBy.${feesPaidBy}.text`
            )}
            value={isCardPaymentAllowed}
            onChange={onToggleCardPaymentsAllowed}
            isUpdating={isUpdating}
            editFeeAbsorptionLabel={formatMessage(
              `ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.Card.subtitle.absorbedFeesBy.editButton.${
                feesPaidBy === 'payor' ? 'vendor' : 'payor'
              }.text`
            )}
            onEditFeeAbsorption={onEditFeeAbsorption}
            isFeeEditingDisabled={!hasVerifiedDeliveryMethod}
          />
          {hasCustomPaymentDetails ? (
            <EditCustomPaymentDetails
              onRemoveCustomPaymentDetails={onRemoveCustomPaymentDetails}
              onEditCustomPaymentDetails={onEditCustomPaymentDetails}
              customPaymentDetails={customPaymentDetails}
            />
          ) : (
            <AddCustomPaymentDetailsButton onEditCustomPaymentDetails={onEditCustomPaymentDetails} />
          )}
        </Group>
      </Group>
    );
  }
);

AcceptedPaymentMethodsScreen.displayName = 'AcceptedPaymentMethodsScreen';
