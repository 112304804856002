import { Button, Container, Form, Group, Text, useToast, useWatch } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useUpdateEffect } from '@melio/platform-utils';
import React, { FocusEventHandler, useEffect, useState } from 'react';

import { ExistingClientAccountModal } from '../../../activities/ExistingClientAccountModal/ExistingClientAccountModal.activity';
import { useIsClientExists, useRequestAccessToOrganization } from '../../../api/entities';
import { useKeyDownHandlerButtonClickSimulation, useTriggerFormValidationFields } from '../../../hooks';
import { useFocusStepOnError } from '../../../hooks/useFocusStepOnError';
import { useAccountantsRoutes } from '../../../utils/useAccountantsRoutes';
import { ErrorBanner } from '../../ErrorBanner';
import { ManageClientFormFields, StepFormProps, useManageClientFormContext } from '..';

const stepFields: Array<keyof ManageClientFormFields> = [
  'businessName',
  'businessDBA',
  'shouldInviteClient',
  'clientFirstName',
  'clientLastName',
  'clientEmailAddress',
];

export const ManageClientBasicDetailsStep: React.FC<StepFormProps> = ({ submitLabel, focusErrorStep, onContinue }) => {
  const { form: formContext } = useManageClientFormContext();
  const { formState, control, registerField, submitButtonProps, getValues, setValue } = formContext;
  const triggerFormValidationFields = useTriggerFormValidationFields(formContext);
  const { buttonRef, handleKeyDown } = useKeyDownHandlerButtonClickSimulation('Enter');
  const { track } = useAnalytics();

  useFocusStepOnError<ManageClientFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const { formatMessage } = useMelioIntl();
  const [isClientExists, setIsClientExists] = useState<boolean>(false);
  const [shouldCopyBusinessNameToBusinessDBA, setShouldCopyBusinessNameToBusinessDBA] = useState<boolean>(true);
  const { toast } = useToast();
  const { goToInvitationSent } = useAccountantsRoutes();

  const [watchShouldInviteClient, watchClientEmail, watchBusinessName, watchBusinessDBA] = useWatch({
    control,
    name: ['shouldInviteClient', 'clientEmailAddress', 'businessName', 'businessDBA'],
  });

  useUpdateEffect(() => {
    const action = watchShouldInviteClient ? 'invite-client' : 'invite-client-uncheck';

    track('Organization', 'Click', {
      PageName: 'business-details',
      Cta: action,
      IsInviteClientChecked: watchShouldInviteClient,
    });
  }, [watchShouldInviteClient]);

  useEffect(() => {
    if (shouldCopyBusinessNameToBusinessDBA && watchBusinessName !== watchBusinessDBA) {
      setValue('businessDBA', watchBusinessName);
    }
  }, [watchBusinessName, watchBusinessDBA, shouldCopyBusinessNameToBusinessDBA, setValue]);

  const {
    refetch: refetchIsClientExists,
    isFetching: isFetchingClientExists,
    error: clientExistsCheckError,
  } = useIsClientExists({
    params: { email: watchClientEmail },
    enabled: false,
    onError: (error) => {
      toast({
        type: 'informative',
        title: formatMessage('activities.accountants.activities.existingClientAccount.checkEmail.error'),
      });
      Logger.log(`Error while trying to check for email conflict ${error.message}`);
    },
  });

  const {
    mutate: requestAccessToOrganization,
    isLoading: isSavingRequestAccessToOrganization,
    error: requestToJoinError,
  } = useRequestAccessToOrganization({
    onSuccess: goToInvitationSent,
    onError(error) {
      Logger.log(`Error while trying to send a join organization request. ${error.message}`);
    },
  });

  const requestToJoinClientOrganization = () => {
    const { businessName, clientEmailAddress, clientFirstName, clientLastName } = getValues();
    requestAccessToOrganization({
      company: {
        name: businessName,
      },
      user: {
        email: clientEmailAddress,
        firstName: clientFirstName,
        lastName: clientLastName,
      },
    });
  };

  const verifyBasicDetails = async () => {
    const { shouldInviteClient, clientEmailAddress } = getValues();
    track('Organization', 'Click', {
      Cta: 'continue',
      PageName: 'business-details',
      Intent: 'add-basic-details',
    });
    const fieldsToVerify: Array<keyof ManageClientFormFields> = watchShouldInviteClient
      ? stepFields
      : ['businessName', 'businessDBA'];
    const isStepFormFieldsValid = await triggerFormValidationFields(fieldsToVerify);

    if (isStepFormFieldsValid) {
      if (shouldInviteClient && clientEmailAddress) {
        const { data } = await refetchIsClientExists();
        if (data?.isExisting) {
          setIsClientExists(true);
          return;
        }
      }

      onContinue();
    }
  };

  const continueCreateNewAccount = () => {
    setIsClientExists(false);
    onContinue();
  };

  const commonFieldsProps = {
    onKeyDown: handleKeyDown,
  };

  const handleBusinessNameBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.value) {
      return;
    }
    setShouldCopyBusinessNameToBusinessDBA(false);
  };

  const handleBusinessDBAFocus: FocusEventHandler<HTMLInputElement> = () => {
    setShouldCopyBusinessNameToBusinessDBA(false);
  };

  return (
    <>
      <Group spacing="m" variant="vertical" width="full">
        <Text textStyle="body2Semi">
          {formatMessage('activities.accountants.manageClient.form.basicDetails.sectionHeader.businessName.text')}
        </Text>
        <Form.TextField
          isRequired
          onBlur={handleBusinessNameBlur}
          labelProps={{
            label: formatMessage('activities.accountants.manageClient.form.basicDetails.fields.businessName.label'),
          }}
          {...registerField('businessName')}
          {...commonFieldsProps}
        />
        <Form.TextField
          isRequired
          labelProps={{
            label: formatMessage('activities.accountants.manageClient.form.basicDetails.fields.businessDBA.label'),
          }}
          onFocus={handleBusinessDBAFocus}
          {...registerField('businessDBA')}
          {...commonFieldsProps}
        />
        <Form.Checkbox
          label={formatMessage('activities.accountants.manageClient.form.basicDetails.fields.inviteClient.label')}
          {...registerField('shouldInviteClient')}
        />
        {watchShouldInviteClient && (
          <>
            <Text textStyle="body2Semi">
              {formatMessage('activities.accountants.manageClient.form.basicDetails.sectionHeader.clientDetails.text')}
            </Text>
            <ErrorBanner
              dataTestId="client-email-check-warning"
              variant="warning"
              message={
                clientExistsCheckError
                  ? formatMessage('activities.accountants.activities.existingClientAccount.checkEmail.error')
                  : undefined
              }
            />
            <Form.TextField
              labelProps={{
                label: formatMessage(
                  'activities.accountants.manageClient.form.clientDetails.fields.emailAddress.label'
                ),
              }}
              {...registerField('clientEmailAddress')}
              {...commonFieldsProps}
              isRequired={watchShouldInviteClient}
            />
            <Form columns={2} as="div">
              <Form.TextField
                colSpan={1}
                labelProps={{
                  label: formatMessage('activities.accountants.manageClient.form.clientDetails.fields.firstName.label'),
                }}
                {...registerField('clientFirstName')}
                {...commonFieldsProps}
                isRequired={watchShouldInviteClient}
              />
              <Form.TextField
                colSpan={1}
                labelProps={{
                  label: formatMessage('activities.accountants.manageClient.form.clientDetails.fields.lastName.label'),
                }}
                {...registerField('clientLastName')}
                {...commonFieldsProps}
                isRequired={watchShouldInviteClient}
              />
            </Form>
          </>
        )}
        <Container justifyContent="flex-end">
          <Button
            ref={buttonRef}
            variant="primary"
            size="medium"
            label={submitLabel}
            data-testid="basic-details-continue-button"
            {...submitButtonProps}
            isLoading={submitButtonProps.isLoading || isFetchingClientExists}
            onClick={() => void verifyBasicDetails()}
          />
        </Container>
      </Group>
      <ExistingClientAccountModal
        data={{ email: watchClientEmail }}
        isOpen={isClientExists}
        onDone={requestToJoinClientOrganization}
        isSaving={isSavingRequestAccessToOrganization}
        onCancel={continueCreateNewAccount}
        onClose={() => setIsClientExists(false)}
        errorMessage={
          requestToJoinError
            ? formatMessage('activities.accountants.activities.existingClientAccount.requestAccess.error')
            : undefined
        }
      />
    </>
  );
};
