import { Box } from '@chakra-ui/react';
import { Container, DateField, Group, RadioGroup, Text, useBreakpoint } from '@melio/penny';
import { DebitDateFilterValues } from '@melio/platform-api/src/entities-v2/payment/types';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { endOfToday, format, isAfter, isBefore, startOfToday } from 'date-fns';
import { Ref, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { BillsTabCreationDateFilterValues } from '../components/BillsTab/types';
import { FilterOption, TabFilter } from '../components/Filters/types';
import { formatDateRangeStrToDates } from './utils';

type Props<T extends string> = {
  options: FilterOption[];
  activeFilter: TabFilter<T>;
  selected: string[];
  onChange: (value?: string, calcCustomUrlParams?: string | undefined) => void;
  firstOptionRef?: Ref<HTMLDivElement>;
};

export const FilterMenuOptionsSingle = <T extends string>({ options, activeFilter, selected, onChange }: Props<T>) => {
  const isNothingSelected = selected.length === 0;
  const [startDate, setStartDate] = useState<Date | undefined>(startOfToday());
  const [endDate, setEndDate] = useState<Date | undefined>(endOfToday());
  const { createDate } = useDateUtils();
  const { isExtraSmallScreen } = useBreakpoint();
  const isCustomDateRangeOptionSelected =
    selected[0] === DebitDateFilterValues.Custom || selected[0] === BillsTabCreationDateFilterValues.Custom;
  const customDateRangeContainerRef = useRef<HTMLInputElement>(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (isCustomDateRangeOptionSelected && customDateRangeContainerRef.current) {
      customDateRangeContainerRef.current.scrollIntoView();
      const customDateSearchParam = searchParams.get(`${activeFilter.key}`);
      const dateRange = customDateSearchParam?.split(',')[1];
      if (dateRange) {
        const { startFormatted, endFormatted } = formatDateRangeStrToDates(dateRange);
        setStartDate(createDate(startFormatted));
        setEndDate(createDate(endFormatted));
      }
    }
  }, [isCustomDateRangeOptionSelected, activeFilter, searchParams, createDate]);

  const radioOptions = options.map((option) => ({
    value: option.value,
    label: (
      <Box id={option.value}>
        <Text textStyle="body3">{option.label}</Text>
      </Box>
    ),
    ariaLabelledby: `${activeFilter.key} ${option.value}`,
    ariaLabel: option.label,
  }));

  const handleChange = (value: string) => {
    const selectedOption = options.find((option) => option.value === value);
    if (selectedOption) {
      if (selectedOption.selectWhenNothingSelected) {
        return onChange(undefined);
      }
      return onChange(
        selectedOption.value,
        selectedOption.calcCustomUrlParams?.({
          customStartDate: startDate ? format(startDate, 'MM/dd/yyyy').toString() : undefined,
          customEndDate: endDate ? format(endDate, 'MM/dd/yyyy').toString() : undefined,
        })
      );
    }
  };

  return (
    <Group variant="vertical" spacing="none" aria-orientation="vertical">
      <RadioGroup
        value={isNothingSelected ? options.find((option) => option.selectWhenNothingSelected)?.value : selected[0]}
        options={radioOptions}
        variant="vertical"
        paddingX="s"
        paddingY="xs-s"
        gap="m"
        onChange={(e) => handleChange(e.target.value)}
      />
      {isCustomDateRangeOptionSelected && (
        <Group variant={isExtraSmallScreen ? 'vertical' : 'horizontal'} spacing="xs">
          <DatesRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            customOption={options[options.length - 1]}
            onChange={onChange}
            containerRef={customDateRangeContainerRef}
          />
        </Group>
      )}
    </Group>
  );
};

const DatesRange = ({
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  onChange,
  customOption,
  containerRef,
}: {
  startDate?: Date;
  setStartDate: (startDate: Date | undefined) => void;
  setEndDate: (endDate: Date | undefined) => void;
  endDate?: Date;
  onChange: (value?: string, calcUrlParams?: string | undefined) => void;
  customOption: FilterOption | undefined;
  containerRef: Ref<HTMLInputElement>;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  useEffect(() => {
    onChange(
      'custom',
      customOption?.calcCustomUrlParams?.({
        customStartDate: startDate ? format(startDate, 'MM/dd/yyyy').toString() : undefined,
        customEndDate: endDate ? format(endDate, 'MM/dd/yyyy').toString() : undefined,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);

  return (
    <>
      <Container
        paddingLeft="s"
        paddingRight={isExtraSmallScreen ? 's' : 'xxs'}
        paddingBottom={!isExtraSmallScreen ? 's' : 'xxs'}
        ref={containerRef}
      >
        <Text textStyle="body4Semi">{formatMessage('activities.payDashboard.filters.custom.from')}</Text>
        <DateField
          data-testid="custom-start-date"
          value={startDate}
          size={isExtraSmallScreen ? 'large' : 'small'}
          hideClear
          weekDays={[0, 1, 2, 3, 4, 5, 6]}
          excludeHolidays={false}
          onChange={(date) => {
            setStartDate(date || undefined);
            if (date && endDate && isAfter(date, endDate)) {
              setEndDate(date || undefined);
            }
          }}
          format="MM/dd/yyyy"
        />
      </Container>
      <Container
        paddingRight="s"
        paddingLeft={isExtraSmallScreen ? 's' : 'xxs'}
        paddingBottom={!isExtraSmallScreen ? 's' : 'xs-s'}
      >
        <Text textStyle="body4Semi">{formatMessage('activities.payDashboard.filters.custom.to')}</Text>
        <DateField
          data-testid="custom-end-date"
          minDate={startDate}
          value={endDate}
          size={isExtraSmallScreen ? 'large' : 'small'}
          hideClear
          weekDays={[0, 1, 2, 3, 4, 5, 6]}
          excludeHolidays={false}
          onChange={(date) => {
            setEndDate(date || undefined);
            if (date && startDate && isBefore(date, startDate)) {
              setStartDate(date || undefined);
            }
          }}
          format="MM/dd/yyyy"
        />
      </Container>
    </>
  );
};
