/* eslint-disable no-restricted-imports */
import { Box } from '@chakra-ui/react';
import { Group, Icon, Text, Tooltip } from '@melio/penny';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { LimitedBillingOptionType } from '../../../FirmDashboard/hooks/useFirmClientSubscriptionActionsProps';
import { useSubscriptionFundingSourceInfo } from '../../hooks/useSubscriptionFundingSourceInfo';

export const BillingInfo = ({
  client,
  limitedBillingOptionsType,
}: {
  client: AccountingFirmClientExpanded;
  limitedBillingOptionsType?: LimitedBillingOptionType;
}) => {
  const { formatMessage } = useMelioIntl();
  const { usedFundingSourceForPayingSubscription, isClientPayingForSubscription } = useSubscriptionFundingSourceInfo({
    clientOrganizationId: client.organizationInfo.id,
    payingOrganizationId: client.subscriptionInfo?.subscription?.payingOrganization?.organizationId,
    usedFundingSourceId: client.subscriptionInfo?.subscription?.fundingSourceId || undefined,
  });

  return (
    <>
      <Group variant="horizontal" spacing="xxs">
        <Text textStyle="body4Semi" color="semantic.text.secondary">
          {formatMessage(
            'activities.accountants.firmDashboard.v2.clientDrawer.subscription.planBilling.semiHeader.title'
          )}
        </Text>
        {limitedBillingOptionsType && (
          <Box
            data-testid={`organization-manage-client-limitations-indication-${limitedBillingOptionsType}-${client.organizationInfo.id}`}
            cursor="default"
            display="flex"
            flexDirection="column"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Tooltip
              content={formatMessage(
                `activities.accountants.OrganizationCard.pill.tooltip.manageClientLimitations.content.${limitedBillingOptionsType}`
              )}
              data-testid={`organization-manage-client-limitations-pill-tooltip-indication-${client.organizationInfo.id}`}
            >
              <Icon
                size="small"
                type="info-fill"
                color="informative"
                data-testid={`organization-manage-client-limitations-pill-icon-indication-${client.organizationInfo.id}`}
                aria-label={formatMessage(
                  `activities.accountants.OrganizationCard.pill.tooltip.manageClientLimitations.content.${limitedBillingOptionsType}`
                )}
              />
            </Tooltip>
          </Box>
        )}
      </Group>
      {usedFundingSourceForPayingSubscription ? (
        <>
          <Text textStyle="body2" color="semantic.text.primary" data-testid="subscription-billing-info-payor">
            {formatMessage(
              isClientPayingForSubscription
                ? 'activities.accountants.firmDashboard.v2.clientDrawer.subscription.planBilling.clientPays.label'
                : 'activities.accountants.firmDashboard.v2.clientDrawer.subscription.planBilling.firmPays.label'
            )}
          </Text>
          <Text textStyle="body3" color="semantic.text.secondary">
            {usedFundingSourceForPayingSubscription?.displayName}
          </Text>
        </>
      ) : (
        <Text textStyle="body3" color="semantic.text.secondary">
          -
        </Text>
      )}
    </>
  );
};
