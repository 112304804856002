import { Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { Props as DashboardProps } from '../../../types';
import { FirmDashboardV2TableEmptyStateGuard } from '../FirmDashboardV2TableEmptyStateGuard';
import { ClientsFilters } from './ClientsFilters';
import { ClientsTable } from './ClientsTable';
import { FirmClientsTableProvider } from './FirmClientsTableContext';

type Props = Pick<
  DashboardProps,
  'goToSettingsAndRefresh' | 'goToSettingsSubscriptionPlansAndRefresh' | 'onSwitchOrganization'
>;

export const FirmClients = ({
  goToSettingsSubscriptionPlansAndRefresh,
  goToSettingsAndRefresh,
  onSwitchOrganization,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group variant="vertical" spacing="m">
      <Container paddingX={['s', null, '0'] as never}>
        <Text textStyle="heading3Semi">
          {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.semiTitle.label')}
        </Text>
      </Container>
      <FirmClientsTableProvider>
        <FirmDashboardV2TableEmptyStateGuard>
          <ClientsFilters />
          <ClientsTable
            goToSettingsAndRefresh={goToSettingsAndRefresh}
            goToSettingsSubscriptionPlansAndRefresh={goToSettingsSubscriptionPlansAndRefresh}
            onSwitchOrganization={onSwitchOrganization}
          />
        </FirmDashboardV2TableEmptyStateGuard>
      </FirmClientsTableProvider>
    </Group>
  );
};
