import { Button, Container, Grid, Group, Icon, NakedButton, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useCollaborators } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { isCollaboratorBlocked, PageTitle } from '@melio/platform-utils';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled, usePlanInfo, usePlansTiers } from '../../../hooks';
import { DEFAULT_DASHBOARD_ROUTE } from '../../../router/routes.consts';
import { CrownLarge } from '../../assets/CrownLarge.icon';
import { SectionSettingCard } from '../../components/SectionSettingCard';
import { useSubscriptionRouter } from '../../utils';
import { SubscriptionGraceSwitchPlanConfirmation } from '../SubscriptionPostGraceFreePlanConfirmation/SubscriptionGraceSwitchPlanConfirmation';

export const GraceEndPeriodSelectYourPlan = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { getFirstPaidPlan, getFreePlan } = usePlansTiers();
  const firstPaidPlan = getFirstPaidPlan();
  const freePlan = getFreePlan();
  const { planName: freePlanName } = usePlanInfo(freePlan?.id);
  const { planName, capabilities } = usePlanInfo(firstPaidPlan?.id);
  const { goToSubscriptionPlans, goToSubscriptionCheckout } = useSubscriptionRouter();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: collaborators, isLoading: isCollaboratorsLoading } = useCollaborators({ enabled: true });
  const [showgraceSwitchPlanConfirmationModal, setShowgraceSwitchPlanConfirmationModal] = useState<boolean>(false);
  const activeCollaborators = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];

  setAnalyticsProperties({
    PageName: 'keep-enjoying-premium-features',
    Flow: 'existing-user-transition',
    Intent: 'existing-user-subscribe',
  });

  useAnalyticsView('Organization', isSubscriptionsEnabled);
  const { track } = useAnalytics();
  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  return (
    <Layout maxWidth="600px" backgroundColor="white">
      <SubscriptionGraceSwitchPlanConfirmation
        isOpen={showgraceSwitchPlanConfirmationModal}
        planName={freePlanName}
        onDismiss={() => {
          setShowgraceSwitchPlanConfirmationModal(false);
        }}
      />
      <Container
        paddingX="xxs"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="subscription-grace-end-period-select-your-plan"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="m">
          <CrownLarge />
          <Grid gap="s">
            <PageTitle textStyle="heading2Semi">
              {formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.title')}
            </PageTitle>
            <Text color="global.neutral.900" data-testid="subscription-grace-end-period-select-your-plan-description">
              {formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.description')}
            </Text>
          </Grid>

          <SectionSettingCard variant="default">
            <Grid gap="m" textAlign="start">
              <Text textStyle="body4Semi" color="global.neutral.900">
                {formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.capabilities.title', {
                  planName,
                })}
              </Text>

              <Group as="ul" variant="vertical" spacing="xs">
                {capabilities.list.map((item) => (
                  <Group alignItems="center" spacing="xs" key={item.message} as="li">
                    <Icon type="checked" size="small" color="brand" />
                    <Text textStyle="body4" color="global.neutral.900">
                      {item.message}
                    </Text>
                  </Group>
                ))}
              </Group>
            </Grid>
          </SectionSettingCard>

          <Button
            isFullWidth
            label={formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.cta')}
            size="large"
            onClick={
              firstPaidPlan
                ? () => {
                    track('Organization', 'Click', {
                      Intent: 'subscribe',
                      Cta: 'start-my-free-trial',
                    });
                    goToSubscriptionCheckout({
                      planId: firstPaidPlan.id,
                      analyticsEntryPoint: 'subscription-existing-users-upgrade-modal',
                      analyticsFlow: 'existing-user-transition',
                    });
                  }
                : undefined
            }
            data-testid="subscription-grace-end-period-select-your-plan-cta-button"
          />

          <Button
            isFullWidth
            label={formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.seeAllPlans')}
            data-testid="subscription-grace-end-period-select-your-plan-plans-button"
            variant="tertiary"
            size="large"
            onClick={() => {
              track('Organization', 'Click', {
                Intent: 'see-plans-screen',
                Cta: 'see-all-plans',
              });
              goToSubscriptionPlans({
                returnUrl: DEFAULT_DASHBOARD_ROUTE,
                analyticsEntryPoint: 'subscription-existing-users-upgrade-modal',
                analyticsFlow: 'existing-user-transition',
              });
            }}
          />

          {(isCollaboratorsLoading || activeCollaborators.length === 1) && (
            <NakedButton
              label={formatMessage('activities.subscription.graceEndPeriod.selectYourPlan.continueWithFreePlan')}
              data-testid="subscription-grace-end-period-select-your-plan-continue-with-free-plan-button"
              size="medium"
              onClick={() => {
                track('Organization', 'Click', {
                  PageName: 'keep-enjoying-premium-features',
                  Flow: 'existing-user-transition',
                  Intent: 'chose-free-tier',
                  Cta: 'continue-with-free-trial',
                });
                setShowgraceSwitchPlanConfirmationModal(true);
              }}
            />
          )}
        </Grid>
      </Container>
    </Layout>
  );
});
