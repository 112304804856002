import { isValidState } from '@melio/ap-domain';
import { InternationalAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { string } from 'yup';

import { InternationalAddressFieldNamesEnum } from './const';

export const useInternationalVendorAddressSchema = () => {
  const { formatMessage } = useMelioIntl();

  const countryCodeSchema = string().notRequired();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const addressFields = {
    [InternationalAddressFieldNamesEnum.Line1]: string().notRequired(),
    [InternationalAddressFieldNamesEnum.Line2]: string().notRequired(),
    [InternationalAddressFieldNamesEnum.City]: string().notRequired().trim(),
    [InternationalAddressFieldNamesEnum.State]: string()
      .optional()
      .nullable()
      .test(
        'isValidState',
        formatMessage('widgets.vendors.internationalAddress.state.validation.invalid'),
        (state, context) => {
          const countryCode = (context.parent as InternationalAddress)?.countryCode;
          if (countryCode && state) {
            return isValidState(state, countryCode);
          }
          return true;
        }
      ),
    [InternationalAddressFieldNamesEnum.PostalCode]: string().notRequired().trim(),
  };

  return { addressFields, countryCodeSchema };
};
