export const downloadFile = (url: string, fileName: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.target = '_blank';
  anchor.click();
};

export const getFileName = (fileName: string): string =>
  fileName.includes('.') ? fileName.split('.')[0] ?? fileName : fileName;
