import { Box } from '@chakra-ui/react';
import { _createFormFieldInput, FormSelectNewProps, SelectNew, SelectNewOption, SelectNewProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ForwardRefExoticComponent, useMemo } from 'react';

export type SelectInputProps = SelectNewProps<string, SelectNewOption<string>>;

export const SelectInput = _createFormFieldInput(
  forwardRef<SelectInputProps, 'input'>(({ options: _options, value: _value, id = '', ...props }, ref) => {
    const options = useMemo<SelectInputProps['options']>(() => normalizeOptions(_options), [JSON.stringify(_options)]); // eslint-disable-line react-hooks/exhaustive-deps
    const value = useMemo(() => (hasOption(options, _value) ? _value : undefined), [options, _value]);
    return (
      <Box data-component="Select" data-value={value} data-testid={`form-field-select-${id}`}>
        <SelectNew {...props} id={id} value={value} options={options} ref={ref} />
      </Box>
    );
  })
) as ForwardRefExoticComponent<FormSelectNewProps<string, SelectNewOption<string>>>;
SelectInput.displayName = 'SelectInput';

const normalizeOptions = (options: SelectInputProps['options']) =>
  options.map((option) =>
    isSelectNewOption(option) ? normalizeOption(option) : option.options.map(normalizeOption)
  ) as SelectInputProps['options'];

const normalizeOption = (option: SelectNewOption<string>): SelectNewOption<string> => ({
  ...option,
  testId: option.value,
});

const isSelectNewOption = (option: SelectInputProps['options'][number]): option is SelectNewOption<string> =>
  typeof option === 'object' && 'value' in option && 'label' in option;

const hasOption = (options: SelectInputProps['options'], value?: string) =>
  options.some((option) =>
    isSelectNewOption(option) ? option.value === value : option.options.some((subOption) => subOption.value === value)
  );
