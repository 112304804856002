import { Button, Icon, IconButton, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';

export const AddNewClientButton = ({ isDisabled, withIcon = true }: { isDisabled?: boolean; withIcon?: boolean }) => {
  const { track } = useAnalytics();
  const { goToClientCreationOptions } = useAccountantsRoutes();
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();

  const onAddButtonClick = () => {
    track('User', 'Click', {
      Intent: 'add-client',
      Cta: 'add-client',
    });
    goToClientCreationOptions();
  };

  return isExtraSmallScreen || isSmallScreen ? (
    <IconButton
      isDisabled={isDisabled}
      icon="add"
      data-testid="add-company-button"
      size="large"
      variant="brand"
      onClick={onAddButtonClick}
    />
  ) : (
    <Button
      leftElement={withIcon && <Icon size="small" type="add" color="inherit" aria-hidden />}
      data-testid="add-company-button"
      size="medium"
      variant="primary"
      isDisabled={isDisabled}
      label={formatMessage('activities.accountants.firmDashboard.addClientButton.label')}
      onClick={onAddButtonClick}
    />
  );
};
