import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Typography, useMelioForm } from '@melio/penny';
import { useAnalytics, withAnalyticsContext, WithAnalyticsContextProps } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { FeeAbsorptionSettingsModalFormFields } from '../../types';

export type FeeAbsorptionSettingsModalScreenProps = FormDialogWidgetProps<FeeAbsorptionSettingsModalFormFields>;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    cardFeesPaidBy: string().required(
      formatMessage(
        'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.required.label'
      )
    ),
  }) as SchemaOf<FeeAbsorptionSettingsModalFormFields>;
};

export const FeeAbsorptionSettingsModalScreen = withAnalyticsContext(
  forwardRef<FeeAbsorptionSettingsModalScreenProps & WithAnalyticsContextProps>(
    ({ isOpen, onClose, onSubmit, defaultValues, setAnalyticsProperties, ...props }, ref) => {
      const { formatMessage } = useMelioIntl();
      const { track } = useAnalytics();

      setAnalyticsProperties({ PageName: 'set-who-pays-card-fees-for-this-payment' });

      const handleSubmit = ({ cardFeesPaidBy }: FeeAbsorptionSettingsModalFormFields) => {
        track('Invoice', 'Click', {
          Intent: cardFeesPaidBy === 'payor' ? 'charge-card-fees-to-customers' : 'charge-card-fees-to-business',
          Cta: 'save',
        });
        onSubmit({ cardFeesPaidBy });
      };

      const { registerField, setValue, resetField, ...useFormResults } = useMelioForm({
        schema: useSchema(),
        onSubmit: handleSubmit,
        defaultValues: { cardFeesPaidBy: defaultValues?.cardFeesPaidBy },
        subscribeToDefaultValuesChanges: true,
      });

      const handleClose = () => {
        track('Invoice', 'Click', { Intent: 'exit flow', Cta: 'close' });
        setTimeout(() => resetField('cardFeesPaidBy'));
        onClose();
      };

      useEffect(() => {
        if (isOpen) track('Invoice', 'View', { Intent: 'change-card-fee-assignment' });
      }, [isOpen, track]);

      return (
        <FormDialog
          data-testid="fee-absorption-settings-modal-screen"
          data-component={FeeAbsorptionSettingsModalScreen.displayName}
          isOpen={isOpen}
          size="large"
          modalSize="medium"
          onClose={handleClose}
          header={formatMessage('ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.header')}
          primaryButton={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.buttons.save.label'
            ),
          }}
          secondaryButton={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.buttons.close.label'
            ),
            variant: 'tertiary',
          }}
          useFormResults={useFormResults}
          {...props}
          ref={ref}
        >
          <Group spacing="m" variant="vertical">
            <Form.RadioGroup
              variant="vertical"
              options={[
                {
                  value: 'payor',
                  mainLabelProps: {
                    size: 'small',
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.customers.main.label'
                    ),
                  },
                  descriptionProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.customers.description.label'
                    ),
                  },
                },
                {
                  value: 'vendor',
                  mainLabelProps: {
                    size: 'small',
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.business.main.label'
                    ),
                  },
                  descriptionProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.feesPaidBy.business.description.label'
                    ),
                  },
                },
              ]}
              {...registerField('cardFeesPaidBy')}
            />
            <Typography.Description
              label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.feeAbsorptionSettings.modal.note')}
            />
          </Group>
        </FormDialog>
      );
    }
  )
);

FeeAbsorptionSettingsModalScreen.displayName = 'FeeAbsorptionSettingsModalScreen';
