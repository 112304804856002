import { useMelioIntl } from '@melio/platform-i18n';
import { usePlanName } from '@melio/subscriptions/src/hooks';

import { ManageClientFormFields, useManageClientForm } from '../../../components';
import { ManageClientForm } from '../../../components/ManageClientForm/ManageClientForm';
import { useUpdateClientSubscriptionBillingOptions } from '../../../hooks';
import { SubscriptionBillingPayor } from '../../../types';
import { useUpdateClientBillingInfoFormSchema, useUpdateClientBillingOptionStepManager } from '../hooks';

type UpdateClientBillingInfoFormProps = {
  onSubmit: (data: ManageClientFormFields) => Promise<void>;
  isSaving: boolean;
  clientOrgId: string;
  onNavigateToClientPaymentMethodsSettings?: VoidFunction;
};

export const UpdateClientBillingInfoForm = ({
  onSubmit,
  isSaving,
  clientOrgId,
  onNavigateToClientPaymentMethodsSettings,
}: UpdateClientBillingInfoFormProps) => {
  const { formatMessage } = useMelioIntl();

  const {
    isAnonymousPayor,
    suggestedClientFundingSource,
    suggestedFirmFundingSource,
    firmSubscriptionBillingFundingSourceId,
    clientSubscriptionBillingFundingSourceId,
    currentPayor,
    clientName,
    firmName,
    clientPlan,
  } = useUpdateClientSubscriptionBillingOptions(clientOrgId).data;

  const payorDefaultValues: Record<
    SubscriptionBillingPayor,
    Pick<ManageClientFormFields, 'whoPays' | 'fundingSourceId'>
  > = {
    [SubscriptionBillingPayor.Client]: {
      whoPays: SubscriptionBillingPayor.Client,
      fundingSourceId: clientSubscriptionBillingFundingSourceId ?? suggestedClientFundingSource?.id ?? '',
    },
    [SubscriptionBillingPayor.AccountingFirm]: {
      whoPays: SubscriptionBillingPayor.AccountingFirm,
      fundingSourceId: firmSubscriptionBillingFundingSourceId ?? suggestedFirmFundingSource?.id ?? '',
    },
  };

  const form = useManageClientForm({
    isSaving,
    onSubmit,
    schema: useUpdateClientBillingInfoFormSchema(),
    defaultValues: {
      ...(!isAnonymousPayor && currentPayor && payorDefaultValues[currentPayor]),
    },
  });

  const planName = usePlanName(clientPlan);

  const stepManager = useUpdateClientBillingOptionStepManager({
    whoPays: form.watch('whoPays'),
    clientName,
    firmName,
    planName,
  });

  return (
    <ManageClientForm
      form={form}
      isSaving={isSaving}
      stepManager={stepManager}
      clientOrgId={clientOrgId}
      shouldListClientFundingSources
      onNavigateToClientPaymentMethodsSettings={onNavigateToClientPaymentMethodsSettings}
      labels={{ submit: formatMessage('activities.accountants.updateBillingMethod.form.submitButton.label') }}
      allowJumpBetweenSections={form.watch('whoPays') && currentPayor !== form.watch('whoPays')}
    />
  );
};
