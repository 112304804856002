import { Container, Group, Text } from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';

import { HelpMenu } from '../HelpMenu/HelpMenu';

type Props = {
  isFirstTime: boolean;
  userDisplayName?: string;
};

export const DashboardHeader = ({ isFirstTime, userDisplayName }: Props) => {
  const { formatMessage } = useMelioIntl();
  const greetingUserMessage: MessageKey = isFirstTime
    ? 'activities.accountants.firmDashboard.v2.firstTime.title'
    : 'activities.accountants.firmDashboard.v2.title';

  return (
    <Container paddingTop={['s', null, '0'] as never} paddingX={['s', null, '0'] as never} overflow="initial">
      <Group justifyContent="space-between" spacing="m">
        <Group variant="vertical" spacing="xs">
          <PageTitle data-testid="dashboard-v2-header" textStyle={['heading2Semi', null, 'heading1Semi'] as never}>
            {formatMessage('activities.accountants.firmDashboard.v2.title.label')}
          </PageTitle>
          <Text as="h2" textStyle="body3">
            {formatMessage(greetingUserMessage, {
              userName: userDisplayName,
            })}
          </Text>
        </Group>
        <Group variant="vertical" alignItems="flex-end">
          <Group variant="horizontal" spacing={['xs', null, 's'] as never} alignItems="center">
            <HelpMenu />
          </Group>
        </Group>
      </Group>
    </Container>
  );
};
