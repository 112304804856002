import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import type { SystemMessageRequest } from './types';
import { isLocationStateWithMessageRequest } from './types';

/**
 * Hook that reads and consumes a system message request from the URL state.
 * The request is automatically removed from the URL state when read.
 *
 * @returns The system message request or null if none exists
 */
export function useSystemMessageRequest<T>(): SystemMessageRequest<T> | null {
  const location = useLocation();
  const navigate = useNavigate();

  if (!isLocationStateWithMessageRequest(location.state)) {
    return null;
  }

  const { systemMessageRequest, ...restState } = location.state;

  if (systemMessageRequest) {
    // Clean up URL state immediately, preserving query params and hash
    navigate(
      {
        pathname: location.pathname,
        search: location.search,
        hash: location.hash,
      },
      {
        replace: true,
        state: isEmpty(restState) === false ? restState : undefined,
      }
    );

    return systemMessageRequest as SystemMessageRequest<T>;
  }

  return null;
}
