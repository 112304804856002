import { ApiError, useMelioQueryClient } from '@melio/api-client';
import { PayorApiClient, PostPayorPaymentRequest, PostPayorPaymentResponseData } from '@melio/ar-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { PaymentsApiClient } from '@melio/platform-api-axios-client';
import { useMutation } from '@tanstack/react-query';

import { useModel, UseModelProps } from '../../api-client';

export type UseGuestPayorPaymentProps = UseModelProps<typeof PaymentsApiClient.getPaymentsPaymentId>;

export const useGuestPayorPayment = (props: UseGuestPayorPaymentProps = {}) => {
  const query = useModel({
    ...props,
    queryKey: ['PaymentApi', props.id],
    queryFn: PaymentsApiClient.getPaymentsPaymentId,
  });

  const queryClient = useMelioQueryClient();

  const { mutateAsync: createPayment, isLoading: isCreatingPayment } = useMutation<
    PostPayorPaymentResponseData,
    ApiError,
    PostPayorPaymentRequest
  >(
    async ({ fundingSourceId, guestPaymentIntentId, scheduledDate }) => {
      const response = await PayorApiClient.postPayorPayment({ fundingSourceId, guestPaymentIntentId, scheduledDate });
      return response.data.data;
    },
    {
      mutationKey: ['PayorApiClient:postPayorPayment'],
      onSuccess: () => {
        void queryClient.invalidateQueries('PublicPayorApi');
      },
    }
  );

  return {
    ...query,
    createPayment,
    isCreatingPayment,
  };
};
