import { ExternalLayout } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { ModelError, useAutoPayment, useVendor, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import {
  SystemMessageProvider,
  useLocation,
  useNavigate,
  useSystemMessage,
  useWizardSimple,
  withSystemMessageProvider,
} from '@melio/platform-utils';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AutoPaymentActivationFormActivity } from '../AutoPaymentActivationForm';
import { AutoPaymentActivationFormFields } from '../AutoPaymentActivationForm/screens/AutoPaymentActivation/AutoPaymentActivationForm/types';
import { AutoPaymentAction, AutoPaymentActivationSuccessScreen } from '../screens';
import { AutoPaymentAmountDetails } from '../screens/AutoPaymentActivationSuccess/useAutoPaymentActivationSuccessMessages.hook';
import { getSubmitErrorMessageKey } from '../utils';

type WizardSteps = 'form' | 'success';
const locationsMap = {
  form: 'form',
  success: 'success',
};

// Create a wrapper component that provides the boundary
export const AutoPaymentActivationFlowActivity = withSystemMessageProvider(
  ({ vendorId }: { vendorId: Vendor['id'] }) => {
    const { showMessage: showGlobalMessage } = useSystemMessage();

    return (
      <SystemMessageProvider>
        <_AutoPaymentActivationFlowActivity vendorId={vendorId} showGlobalMessage={showGlobalMessage} />
      </SystemMessageProvider>
    );
  }
);

// Internal component that receives the global message handler
const _AutoPaymentActivationFlowActivity = ({
  vendorId,
  showGlobalMessage,
}: {
  vendorId: Vendor['id'];
  showGlobalMessage: ReturnType<typeof useSystemMessage>['showMessage'];
}) => {
  const [autoPaymentAmountDetails, setAutoPaymentAmountDetails] = useState<AutoPaymentAmountDetails>();
  const { data: vendor } = useVendor({ id: vendorId });
  const { create: createAutoPayment, isMutating } = useAutoPayment({
    vendorId,
    options: {
      onCreate: ({ paymentAmountType, fixedAmount }) => goToSuccessPage({ fixedAmount, paymentAmountType }),
      onCreateError: (error) => onCreateFailed(error as ModelError),
    },
  });
  const navigate = useNavigate();
  const { state } = useLocation<{ returnUrl?: string }>();
  const { returnUrl } = state || {};
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage(); // Local system message provider
  const { track } = useAnalytics();

  const { goNext, cancelFlow } = useWizardSimple<WizardSteps>({
    flowName: 'auto-payment-activation',
    firstStep: 'form',
    locationsMap,
    navigationSequence: ['form', 'success'],
    cancelUrlFallback: '/',
  });

  const goToSuccessPage = ({ paymentAmountType, fixedAmount }: AutoPaymentAmountDetails) => {
    setAutoPaymentAmountDetails({
      fixedAmount,
      paymentAmountType,
    });

    goNext();
  };

  const onDoneSuccessPage = () => {
    track('Dashboard', 'Status', {
      flow: 'dashboard',
      intent: 'set-autopay',
      Status: 'success',
    });

    // Use the global message provider instead of the local one
    showGlobalMessage({
      title: formatMessage('activities.vendor.autoPaymentActivationFlow.screens.success.toast'),
      type: 'success',
    });

    onClose();
  };

  const onDone = ({
    deliveryDateType,
    fixedAmount,
    fundingSourceId,
    maxAmount,
    paymentAmountType,
    scheduleDaysBefore,
    shouldNotifyOnScheduled,
    shouldNotifyOnSent,
  }: AutoPaymentActivationFormFields) => {
    const request = {
      deliveryDateType,
      fundingSourceId,
      paymentAmountType,
      shouldNotifyOnScheduled,
      shouldNotifyOnSent,
      ...(scheduleDaysBefore ? { scheduleDaysBefore: Number(scheduleDaysBefore) } : {}),
      ...(fixedAmount ? { fixedAmount: Number(fixedAmount) } : {}),
      ...(maxAmount ? { maxAmount: Number(maxAmount) } : {}),
    };

    createAutoPayment(request);
  };

  const onCreateFailed = (error: ModelError) => {
    track('Dashboard', 'Status', {
      flow: 'dashboard',
      intent: 'set-autopay',
      Status: 'failure',
    });

    // Use local system message for errors that don't navigate
    showMessage({
      type: 'error',
      title: formatMessage(getSubmitErrorMessageKey(error)),
    });
  };

  const onClose = () => {
    if (returnUrl) {
      navigate(returnUrl);
    } else {
      cancelFlow();
    }
  };

  if (!vendor) {
    return <ExternalLayout isLoading />;
  }

  return (
    <Routes>
      <Route
        path={locationsMap.form}
        element={
          <AutoPaymentActivationFormActivity
            vendorId={vendorId}
            onDone={onDone}
            onClose={onClose}
            isMutating={isMutating}
            action={AutoPaymentAction.Create}
          />
        }
      />
      <Route
        path={locationsMap.success}
        element={
          <AutoPaymentActivationSuccessScreen
            vendorName={vendor.name}
            onDone={onDoneSuccessPage}
            autoPaymentAmountDetails={autoPaymentAmountDetails}
            action={AutoPaymentAction.Create}
          />
        }
      />
    </Routes>
  );
};
