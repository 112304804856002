import {
  getDeliveryMethodIconKey,
  getManagedAccountDeliveryMethodType,
  isBusinessTypeAllowedForFX,
  usePaymentProcessingInfo,
} from '@melio/ap-domain';
import { BrandSymbol, BrandSymbolKey, FlagIcon, FlagKey, getBrandSymbolsMap, Icon, IconKey } from '@melio/penny';
import {
  BillerAccountDeliveryMethod,
  Card,
  DeliveryMethod,
  DeliveryMethodType,
  DeliveryPreferenceType,
  FundingSource,
  OrganizationBusinessType,
  PaymentTimelineItem,
  PaymentTimelineItemFlowEnum,
  PaymentTimelineItemStatusEnum,
  useFeeCatalog,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

type Options = {
  suspense?: boolean;
};
export const useDeliveryMethodFee = (fundingSourceType?: FundingSource['type'], options?: Options) => {
  const { data: feeCatalog = [], isFetched } = useFeeCatalog({
    suspense: options?.suspense,
  });
  const { formatCurrency, formatPercents } = useMelioIntl();

  const getCatalogLogEntry = (deliveryMethodType: DeliveryMethod['type']) => {
    switch (deliveryMethodType) {
      case 'bank-account': {
        const achToAchFeeCatalogEntry = feeCatalog.find((fee) => fee.feeType == 'ach-to-ach');
        if (fundingSourceType === 'bank-account' && achToAchFeeCatalogEntry) {
          return achToAchFeeCatalogEntry;
        }

        const cardToAchFeeCatalogEntry = feeCatalog.find((fee) => fee.feeType == 'card-to-ach');
        if (fundingSourceType === 'card' && cardToAchFeeCatalogEntry) {
          return cardToAchFeeCatalogEntry;
        }

        const paypalBalanceToAchFeeCatalogEntry = feeCatalog.find((fee) => fee.feeType == 'paypal-balance-to-ach');
        if (fundingSourceType === 'paypal-balance' && paypalBalanceToAchFeeCatalogEntry) {
          return paypalBalanceToAchFeeCatalogEntry;
        }

        const achFeeCatalogEntry = feeCatalog.find((fee) => fee.feeType == 'ach');

        return achFeeCatalogEntry;
      }
      case 'paper-check':
        return fundingSourceType === 'bank-account'
          ? feeCatalog.find((fee) => fee.feeType == 'ach-to-check')
          : feeCatalog.find((fee) => fee.feeType == 'check');
      case 'domestic-wire-account':
        return feeCatalog.find((fee) => fee.feeType == 'domestic-wire');
      case 'international-account':
        return feeCatalog.find((fee) => fee.feeType == 'international');
    }
    return void 0;
  };

  return {
    isFetched,
    getFee: (deliveryMethodType: DeliveryMethod['type']) => {
      const feeCatalogEntry = getCatalogLogEntry(deliveryMethodType);
      if (feeCatalogEntry && feeCatalogEntry?.value > 0) {
        return feeCatalogEntry.valueType === 'fixed'
          ? formatCurrency(feeCatalogEntry.value)
          : formatPercents(feeCatalogEntry.value, { divide: true });
      }
      return null;
    },
  };
};

export const isRppsDeliveryMethod = (deliveryMethod: DeliveryMethod): deliveryMethod is BillerAccountDeliveryMethod =>
  deliveryMethod.type === DeliveryMethodType.BillerAccount;

export const useGetDeliveryMethodHelperText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
  countryDisplayName?: string,
  scheduledDate?: Date
): string => {
  const { formatMessage, formatDate } = useMelioIntl();
  const { calculateExpiryDate } = usePaymentProcessingInfo();

  switch (deliveryMethod.type) {
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${
          (deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'
        }.helperText`
      );
    case 'bank-account': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.bankAccount.trace', {
          tracingNumber,
        });
      }
      return '';
    }
    case 'virtual-card': {
      const formattedExpiryDate = scheduledDate
        ? formatDate(calculateExpiryDate(scheduledDate), { day: 'numeric', month: 'short' })
        : undefined;
      return formatMessage('utils.deliveryMethod.virtualCard.helperText', { expiryDate: formattedExpiryDate });
    }
    case 'paypal-balance':
      return formatMessage('utils.deliveryMethod.paypalBalance.helperText', {
        email: deliveryMethod.details.paypalAccountEmail,
      });
    case 'international-account': {
      return formatMessage('utils.deliveryMethod.internationalAccount.helperText', {
        country: countryDisplayName || deliveryMethod.details.identifierDetails.bankCountryCode,
        flag: (
          <FlagIcon countryCode={deliveryMethod.details.identifierDetails.bankCountryCode as FlagKey} size="small" />
        ),
      });
    }
    default:
      return '';
  }
};

const useGetDeliveryPreferenceLabelText = (
  deliveryPreferenceType: DeliveryPreferenceType | null | undefined
): string | null => {
  const { formatMessage } = useMelioIntl();

  switch (deliveryPreferenceType) {
    case 'rtp':
      return formatMessage('utils.deliveryMethodPreference.rtpOrganization.label');
    default:
      return null;
  }
};

export type SupportedInternationalType = 'international' | 'international-fx' | 'international-usd';

export const getSupportedInternationalType = ({
  isInternationalFxEnabled,
  vendorCurrency,
  businessType,
}: {
  isInternationalFxEnabled: boolean;
  vendorCurrency?: Vendor['currency'];
  businessType?: OrganizationBusinessType | null;
}): SupportedInternationalType => {
  if (vendorCurrency === 'USD' || !isInternationalFxEnabled || !isBusinessTypeAllowedForFX(businessType)) {
    return 'international-usd';
  }
  // Currency is not USD.
  return vendorCurrency ? 'international-fx' : 'international';
};

export const useGetDeliveryMethodLabelText = (
  deliveryMethod: DeliveryMethod,
  timeline: Array<PaymentTimelineItem>,
  deliveryMethodPreference?: DeliveryPreferenceType | null
): string => {
  const { formatMessage } = useMelioIntl();
  const deliveryPreferenceLabelText = useGetDeliveryPreferenceLabelText(deliveryMethodPreference);

  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryPreferenceLabelText) {
        return deliveryPreferenceLabelText;
      }
      return formatMessage('utils.deliveryMethod.bankAccount.label');
    }
    case 'paper-check': {
      const tracingNumber = extractTracingNumber(timeline);
      if (tracingNumber) {
        return formatMessage('utils.deliveryMethod.paperCheck.labelScheduled', { tracingNumber });
      }
      return formatMessage('utils.deliveryMethod.paperCheck.label');
    }
    case 'domestic-wire-account':
      return formatMessage('utils.deliveryMethod.domesticWireAccount.label');
    case 'virtual-account':
      return formatMessage('utils.deliveryMethod.unilateral.label');
    case 'card':
      return formatMessage(
        `utils.deliveryMethod.${(deliveryMethod.details as Card).type === 'debit' ? 'debitCard' : 'creditCard'}.label`
      );
    case 'virtual-card':
      return formatMessage('utils.deliveryMethod.virtualCard.label');
    case 'biller-account':
      return formatMessage('utils.deliveryMethod.billerAccount.label');
    case 'paypal-balance':
      return formatMessage('utils.deliveryMethod.paypalBalance.label');
    case 'managed-account': {
      const managedDeliveryMethodType = getManagedAccountDeliveryMethodType(deliveryMethod.details);
      switch (managedDeliveryMethodType) {
        case 'bank-account':
          return formatMessage('utils.deliveryMethod.bankAccount.managed.label');
        case 'paper-check':
          return formatMessage('utils.deliveryMethod.paperCheck.managed.label');
        default:
          return formatMessage('utils.deliveryMethod.billerAccount.label');
      }
    }
    case 'international-account':
      return formatMessage('utils.deliveryMethod.internationalAccount.label');
    default:
      return 'unsupported';
  }
};

export const useDeliveryMethodIconOrBrandSymbol = (deliveryMethod: DeliveryMethod) => {
  const { settings } = useConfig();

  const iconKey = getDeliveryMethodIconKey(deliveryMethod, settings.icons.deliveryMethod);

  // TODO: replace with utility from @melio/platform-api - https://meliorisk.atlassian.net/browse/ME-24864
  const isBrandSymbol = Object.keys(getBrandSymbolsMap('')).includes(iconKey as BrandSymbolKey);

  return isBrandSymbol ? (
    <BrandSymbol type={iconKey as BrandSymbolKey} />
  ) : (
    <Icon type={iconKey as IconKey} color="inherit" />
  );
};

const extractTracingNumber = (timeline: Array<PaymentTimelineItem>) =>
  timeline.find(
    (item) =>
      item.flow === PaymentTimelineItemFlowEnum.Deliver &&
      item.tracingNumber &&
      item.status !== PaymentTimelineItemStatusEnum.Failed
  )?.tracingNumber;

export const isSelectedDmRequiresConfirmation = ({
  enablePromoteAch,
  selectedDeliveryMethod,
}: {
  enablePromoteAch: boolean;
  selectedDeliveryMethod?: DeliveryMethod;
}): boolean => enablePromoteAch && !!selectedDeliveryMethod?.requireConfirmationForPayment;
