import { addWildcardToRoutes, useEnrollmentStatus, useFlowRouting, withHidden, withOutlet } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useShouldCollectLegalInfoOnPaymentFlow } from '@melio/platform-kyc';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { EditInvoiceActivity as _EditInvoiceActivity, EditInvoiceActivityProps } from '../activities';
import { useInvoiceSubRoutes } from './useInvoiceSubRoutes';

const EditInvoiceActivity = withOutlet(withHidden(_EditInvoiceActivity));

export type EditInvoiceFlowProps = {
  invoiceId: string;
  onClose: VoidFunction;
  onDone: (id?: string) => void;
  onIssueInvoice: (id: string) => void;
  onError?: ARErrorFunction;
  isMtlKycUpliftEnabled?: boolean;
};

export const EditInvoiceFlow = withAnalyticsContext<EditInvoiceFlowProps>(
  forwardRef(
    (
      { setAnalyticsProperties, invoiceId, onClose, onDone, onError, onIssueInvoice, isMtlKycUpliftEnabled, ...props },
      ref
    ) => {
      const { Paths, goToEditInvoice } = useEditInvoiceFlowNavigation();
      const enrollmentStatus = useEnrollmentStatus();

      const {
        renderSubRoutes,
        openTaxRateModal,
        openDiscountModal,
        openServiceChargeModal,
        removeServiceCharge,
        discount,
        serviceCharges,
        removeDiscount,
        taxRate,
        onCreateCustomer,
        onUpdateCustomer,
        customerId,
        goToCompleteKycDetails,
        currentRoute,
        isKycSkipped,
      } = useInvoiceSubRoutes({ onClose: goToEditInvoice, invoiceId, onError });

      const { track } = useAnalytics();

      const { shouldCollectLegalInfoOnPaymentFlow, isLoadingShouldCollectLegalInfoOnPaymentFlow } =
        useShouldCollectLegalInfoOnPaymentFlow();

      setAnalyticsProperties({
        InvoiceId: invoiceId,
        PageName: 'update-invoice',
        Flow: 'update-invoice',
        Intent: 'update-invoice',
        IsTestInvoice: enrollmentStatus === 'notSubmitted',
      });

      const onEditDone = (target: Parameters<EditInvoiceActivityProps['onDone']>[0]) => {
        track('Invoice', 'Status', {
          StatusType: 'success',
          Cta: target,
          Intent: 'save-changes',
        });
        if (target === 'save-and-close') {
          onDone(invoiceId);
        } else {
          onIssueInvoice(invoiceId);
        }
      };

      const onErrorWithTracking = (error: ARPlatformError) => {
        track('Invoice', 'Status', {
          StatusType: 'failure',
          Cta: 'update-invoice',
          Intent: 'save-changes',
        });
        onError?.(error);
      };

      return (
        <Routes>
          <Route
            path={Paths.Home}
            element={
              <EditInvoiceActivity
                invoiceId={invoiceId}
                discount={discount}
                onSetDiscount={openDiscountModal}
                onRemoveDiscount={removeDiscount}
                onClose={onClose}
                onDone={onEditDone}
                onError={onErrorWithTracking}
                taxRate={taxRate}
                onSetTaxRate={openTaxRateModal}
                serviceCharges={serviceCharges}
                onAddServiceCharge={openServiceChargeModal}
                onRemoveServiceCharge={removeServiceCharge}
                onCreateCustomer={onCreateCustomer}
                onUpdateCustomer={onUpdateCustomer}
                customerId={customerId}
                isMissingKycDetails={isMtlKycUpliftEnabled && shouldCollectLegalInfoOnPaymentFlow && !isKycSkipped}
                handleMissingKycDetails={goToCompleteKycDetails}
                isHidden={currentRoute === 'CompleteKycDetails'}
                isDisabled={isMtlKycUpliftEnabled && isLoadingShouldCollectLegalInfoOnPaymentFlow}
                {...props}
                ref={ref}
              />
            }
          >
            {renderSubRoutes()}
          </Route>
        </Routes>
      );
    }
  )
);

EditInvoiceFlow.displayName = 'EditInvoiceFlow';

const useEditInvoiceFlowNavigation = () => {
  enum Paths {
    Home = '',
  }

  const { createCallback } = useFlowRouting<Paths>({ withSearchparams: true });
  const goToEditInvoice = createCallback(Paths.Home);

  return {
    Paths: addWildcardToRoutes(Paths),
    goToEditInvoice,
  };
};
