import { Group, ListItem } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { ConversionUtils } from '../../../../utils/ConversionUtils';

export const PaymentOverviewContainer = ({
  title,
  amount,
  description,
  dataTestId,
  onClick,
}: {
  title: string;
  amount: number;
  description: string;
  dataTestId?: string;
  onClick: VoidFunction;
}) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const isEmptyState = amount === 0;
  const formattedAmount = formatCurrency(ConversionUtils.fromCentsToDollars(amount), 'USD');
  return (
    <Group data-testid={dataTestId} variant="vertical" spacing="xxxs">
      {isEmptyState ? (
        <ListItem
          isReadOnly
          descriptionProps={{
            label: description,
            action: undefined,
          }}
          labelProps={{
            label: title,
          }}
          mainLabelProps={{
            label: formattedAmount,
            variant: 'default',
          }}
          textAlign="start"
        />
      ) : (
        <ListItem
          descriptionProps={{
            label: description,
            action: {
              label: formatMessage(
                'activities.accountants.firmDashboard.v2.clientDrawer.paymentsOverview.section.link.label'
              ),
              onClick,
            },
          }}
          labelProps={{
            label: title,
          }}
          mainLabelProps={{
            label: formattedAmount,
            variant: 'default',
          }}
          textAlign="start"
        />
      )}
    </Group>
  );
};
