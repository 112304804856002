import { useNonEntityMutation } from '@melio/api-client';
import { PayorApiClient } from '@melio/ar-api-axios-client';

import { PromiseFunctionReturnType } from '../../api-client';
import { PostPayorSetupRequest } from './types';

export const useGuestPayorSetup = () => {
  type MutationFn = typeof PayorApiClient.postPayorSetup;

  const setup = useNonEntityMutation<MutationFn, PostPayorSetupRequest, PromiseFunctionReturnType<MutationFn>>(
    PayorApiClient.postPayorSetup,
    ['PayorApi', 'model', 'postPayorSetup', 'default']
  );

  return {
    setup: setup.mutateAsync,
  };
};
