import { Vendor } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';

export const useVendorNotPermitted = (vendor?: Vendor) => {
  const [exclusiveUserViewEnabled] = usePartnerFeature('exclusiveUserViewEnabled', false);
  const { can } = usePermissions();

  if (!vendor || !exclusiveUserViewEnabled) {
    return false;
  }

  const vendorSubjectData = { createdById: vendor.createdById, managedBy: vendor.managedBy };
  const canUpdateVendor = can({ subject: 'vendor', action: 'update', subjectData: vendorSubjectData });
  const canDeleteVendor = can({ subject: 'vendor', action: 'delete', subjectData: vendorSubjectData });

  return !canUpdateVendor && !canDeleteVendor;
};
