import { Container, Divider, Group } from '@melio/penny';
import React from 'react';

export const SectionWrapper: React.FC<{ dataTestId?: string; withSpacing?: boolean; withDivider?: boolean }> = ({
  dataTestId,
  children,
  withSpacing = true,
  withDivider = true,
}) => (
  <Group data-testid={dataTestId} variant="vertical" justifyContent="center" spacing="none">
    <Container paddingTop={withSpacing ? 'l' : undefined} paddingBottom="l">
      {children}
    </Container>
    {withDivider && <Divider />}
  </Group>
);
