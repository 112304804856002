import {
  Container,
  Group,
  Pagination,
  SortDirection,
  Table,
  TableColumnDef,
  Text,
  useBreakpoint,
  useTable,
} from '@melio/penny';
import { useAccount } from '@melio/platform-api';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClient } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createSearchParams, Route, Routes, useNavigate, useResolvedPath } from 'react-router-dom';

import { getPaginationIndex } from '../../../../../utils/tables';
import { ClientDrawerActivity } from '../../../../FirmClientDrawer/ClientDrawer.activity';
import { Props as DashboardProps } from '../../../types';
import { ClientLogo } from '../ClientLogo/ClientLogo';
import { CellLoadingWrapper } from './cells/cellLoadingWrapper';
import { ClientActions } from './cells/clientActions';
import { StatusCell } from './cells/status';
import { ClientListItem } from './ClientListItem';
import { ClientsTableError } from './ClientsTableError';
import { useFirmClientsTableContext } from './FirmClientsTableContext';
import { ClientData, SortableClientsTableColumns } from './types';

type Props = Pick<
  DashboardProps,
  'goToSettingsAndRefresh' | 'goToSettingsSubscriptionPlansAndRefresh' | 'onSwitchOrganization'
>;

export const ClientsTable = ({
  goToSettingsSubscriptionPlansAndRefresh,
  goToSettingsAndRefresh,
  onSwitchOrganization,
}: Props) => {
  const [loadingRowIds, setLoadingRowIds] = useState<string[]>([]);
  const { data: firmAccount, isLoading: isFirmAccountLoading } = useAccount({ id: 'me' });
  const navigate = useNavigate();
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const clientsTableRef = useRef<HTMLDivElement>();
  const {
    clients: clientsData,
    isLoading: isClientsLoading,
    error,
    pagination: { currentPage, pageSize, totalCount },
    sorting,
    handlers: { handlePageChange, handleSortChange },
  } = useFirmClientsTableContext();
  const resolvedPathUrl = useResolvedPath('');

  const addToLoadingRowIds = (rowId: AccountingFirmClient['client']['id']) =>
    setLoadingRowIds([...loadingRowIds, rowId]);
  const removeFromLoadingRowIds = (idToRemove: AccountingFirmClient['client']['id']) =>
    setLoadingRowIds(loadingRowIds?.filter((id) => idToRemove !== id));

  const onTablePageChange = (pageNumber: number) => {
    requestAnimationFrame(() => {
      clientsTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    });
    handlePageChange(pageNumber);
  };

  const columns: TableColumnDef<ClientData>[] = useMemo(
    () => [
      {
        id: SortableClientsTableColumns.CLIENT,
        header: (
          <Table.HeaderSortableCell
            sortDirection={sorting?.id === SortableClientsTableColumns.CLIENT ? sorting.sortDirection : undefined}
            onClick={(sortDirection: SortDirection | undefined) =>
              !isClientsLoading &&
              handleSortChange(sortDirection ? { id: SortableClientsTableColumns.CLIENT, sortDirection } : undefined)
            }
          >
            {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.columns.client.title')}
          </Table.HeaderSortableCell>
        ),
        isPinnedToLeft: true,
        isRowHeader: true,
        cell: ({ row }) => (
          <Table.Cell>
            <CellLoadingWrapper isLoading={isClientsLoading} withCircleLoader>
              <Group spacing="s" alignItems="center" justifyContent="center">
                <ClientLogo
                  client={{ name: row.client.name, isHidden: row.client.isHidden, logoUrl: row.client.logoUrl }}
                />
                <Group variant="vertical" spacing="xxxs">
                  <Text textStyle="body3Semi">{row.client.name}</Text>
                  {row.client.isHidden && (
                    <Text textStyle="body4" color="semantic.text.secondary">
                      {formatMessage(
                        'activities.accountants.firmDashboard.v2.firmClientsTable.columns.client.hiddenIndication.label'
                      )}
                    </Text>
                  )}
                </Group>
              </Group>
            </CellLoadingWrapper>
          </Table.Cell>
        ),
      },
      {
        id: SortableClientsTableColumns.ACCOUNT_STATUS,
        header: (
          <Table.HeaderSortableCell
            sortDirection={
              sorting?.id === SortableClientsTableColumns.ACCOUNT_STATUS ? sorting.sortDirection : undefined
            }
            onClick={(sortDirection: SortDirection | undefined) =>
              !isClientsLoading &&
              handleSortChange(
                sortDirection ? { id: SortableClientsTableColumns.ACCOUNT_STATUS, sortDirection } : undefined
              )
            }
          >
            {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.columns.accountStatus.title')}
          </Table.HeaderSortableCell>
        ),
        cell: ({ row }) => (
          <Table.Cell>
            <CellLoadingWrapper isLoading={isClientsLoading}>
              <StatusCell status={row.clientStatus} />
            </CellLoadingWrapper>
          </Table.Cell>
        ),
      },
      {
        id: SortableClientsTableColumns.BILLS_DUE,
        header: (
          <Table.HeaderSortableCell
            sortDirection={sorting?.id === SortableClientsTableColumns.BILLS_DUE ? sorting.sortDirection : undefined}
            onClick={(sortDirection: SortDirection | undefined) =>
              !isClientsLoading &&
              handleSortChange(sortDirection ? { id: SortableClientsTableColumns.BILLS_DUE, sortDirection } : undefined)
            }
          >
            {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.columns.billsDue.title')}
          </Table.HeaderSortableCell>
        ),
        cell: ({ row }) => (
          <Table.Cell>
            <CellLoadingWrapper isLoading={isClientsLoading}>
              <Text textStyle="body3">
                {row.billsCount > 0
                  ? formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.billsDue.label', {
                      billsCount: row.billsCount,
                    })
                  : formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.billsDue.empty.label')}
              </Text>
            </CellLoadingWrapper>
          </Table.Cell>
        ),
      },
      {
        id: 'actions',
        cell: ({ row }) =>
          !isClientsLoading &&
          !isFirmAccountLoading &&
          !!firmAccount && (
            <ClientActions
              client={row.client}
              hasPermissions={row.hasPermissions}
              clientType={row.type}
              subscriptionInfo={row.subscriptionInfo}
              goToSettingsSubscriptionPlansAndRefresh={goToSettingsSubscriptionPlansAndRefresh}
              goToSettingsAndRefresh={goToSettingsAndRefresh}
              onSwitchOrganization={onSwitchOrganization}
              addToLoadingRowIds={addToLoadingRowIds}
              removeFromLoadingRowIds={removeFromLoadingRowIds}
            />
          ),
        size: 'xs',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, handleSortChange]
  );

  const handleClientDrawerQuery = ({ newPath }: { newPath: string }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const existingParams = Object.fromEntries(searchParams);
    const updatedSearchParams = createSearchParams({ ...existingParams });
    navigate(`${newPath}?${updatedSearchParams.toString()}`);
  };

  const mobileRowRenderer = useCallback(
    (row: ClientData) => <ClientListItem data={row} isLoading={isClientsLoading} />,
    [isClientsLoading]
  );
  const isRowClickDisabled = (rowData: ClientData) => rowData.type === 'joinOrganizationRequest';
  const tableProps = useTable({
    data: clientsData,
    columns,
    ...sorting,
    loadingRowIds,
    disableAllRowsSelection: true,
    headerVariant: 'dark',
    allRowsSelectionAriaLabel: '',
    captionId: formatMessage(`activities.accountants.firmDashboard.v2.firmClientsTable.caption`),
    getRowId: (row) => row.client.id,
    onRowClick: (param) => {
      if (!isRowClickDisabled(param.rowData)) {
        handleClientDrawerQuery({ newPath: param.rowId });
      }
    },
    mobileRowRenderer,
  });

  const paginationIndex = getPaginationIndex(currentPage, pageSize, totalCount ?? 0);

  return (
    <Group ref={clientsTableRef as never} variant="vertical" spacing="s">
      <Table {...tableProps} />
      {error && <ClientsTableError />}
      {!isClientsLoading && !!totalCount && (
        <Container
          data-testid="clients-table-pagination"
          justifyContent={isExtraSmallScreen ? 'center' : 'flex-end'}
          alignItems="center"
        >
          <Pagination
            ariaLabels={{
              chevronLeftLabel: formatMessage(
                'activities.accountants.firmDashboard.v2.firmClientsTable.pagination.button.ariaLabel',
                {
                  startIndex: paginationIndex.startIndex,
                  endIndex: paginationIndex.endIndex,
                  totalResults: totalCount ?? 0,
                  chevronDirection: formatMessage(
                    'activities.accountants.firmDashboard.v2.firmClientsTable.pagination.button.previous.ariaLabel'
                  ),
                }
              ),
              chevronRightLabel: formatMessage(
                'activities.accountants.firmDashboard.v2.firmClientsTable.pagination.button.ariaLabel',
                {
                  startIndex: paginationIndex.startIndex,
                  endIndex: paginationIndex.endIndex,
                  totalResults: totalCount ?? 0,
                  chevronDirection: formatMessage(
                    'activities.accountants.firmDashboard.v2.firmClientsTable.pagination.button.next.ariaLabel'
                  ),
                }
              ),
            }}
            currentPage={currentPage}
            onPageChange={onTablePageChange}
            pageSize={pageSize}
            totalItems={totalCount}
          />
        </Container>
      )}
      <Routes>
        <Route
          path=":clientId"
          element={
            <ClientDrawerActivity
              onSwitchOrganization={onSwitchOrganization}
              goToSettingsAndRefresh={goToSettingsAndRefresh}
              goToSettingsSubscriptionPlansAndRefresh={goToSettingsSubscriptionPlansAndRefresh}
              onClose={() => handleClientDrawerQuery({ newPath: resolvedPathUrl.pathname })}
            />
          }
        />
      </Routes>
    </Group>
  );
};
