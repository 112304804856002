import { Icon, SelectOption } from '@melio/penny';
import {
  BusinessItemDirectoryName,
  formatAddress,
  InitialManagedByTypeEnum,
  MsnBusiness,
  Vendor,
} from '@melio/platform-api';
import { identity } from 'lodash';

import { UnifiedBusinessItem } from './useVendorSelectBusinesses';

type VendorToSelectOptionOptions = {
  isLogoEnabled: boolean;
  isVerifiedIconEnabled: boolean;
};

export const mapVendorToSelectOption = (vendor: Vendor, options?: VendorToSelectOptionOptions): SelectOption => {
  const isLogoEnabled = options?.isLogoEnabled ?? false;
  const isVerifiedIconEnabled = options?.isVerifiedIconEnabled ?? false;

  const description = [vendor.nickname, vendor.accountNumber].filter(identity).join(' | ');
  const externalLogoUrl = isLogoEnabled ? vendor.externalLogoUrl : undefined;

  const badgeProps =
    vendor.isManaged && isVerifiedIconEnabled
      ? {
          badge: <Icon type="verified" size="extra-small" color="brand" />,
        }
      : undefined;

  return {
    label: vendor.name,
    value: vendor.id,
    description,
    avatarProps: { name: vendor.name, src: externalLogoUrl, ...badgeProps },
  };
};

export const mapUnifiedBusinessItemToSelectOption = (businessItem: UnifiedBusinessItem): SelectOption => {
  const { directoryName, business } = businessItem;

  switch (directoryName) {
    case BusinessItemDirectoryName.Fiserv:
      return {
        label: business.name,
        value: business.id,
        avatarProps: { name: business.name, src: business.logoUri },
      };
    case BusinessItemDirectoryName.Msn:
      return {
        label: business.name ?? '',
        value: business.id,
        description: business.contact?.address ? formatAddress(business.contact.address) : undefined,
        avatarProps: { name: business.name },
      };
  }
};

export const matchVendorWithQuery = ({ name, nickname, accountNumber }: Vendor, query = '') =>
  name.toLowerCase().includes(query.toLocaleLowerCase()) ||
  nickname?.toLowerCase().includes(query.toLocaleLowerCase()) ||
  accountNumber?.toLowerCase().includes(query.toLocaleLowerCase());

export const matchMsnBusinessWithVendor = (business: MsnBusiness, vendor: Vendor) => {
  if (!business.contact || vendor.initialManagedByType !== InitialManagedByTypeEnum.Managed) {
    return false;
  }

  const vendorAddress = vendor.contact.address && formatAddress(vendor.contact.address);
  const businessAddress = business.contact.address && formatAddress(business.contact.address);

  return (
    vendor.name === business.name &&
    vendor.contact.email === business.contact.email &&
    vendorAddress === businessAddress
  );
};
