import { Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const AddMsnVendorDrawerHeader = () => {
  const { formatMessage } = useMelioIntl();

  return (
    <Text as="h2" textStyle="heading2Semi">
      {formatMessage('widgets.vendorDrawer.add.msn.title')}
    </Text>
  );
};
