import { FormInputs, FormLayout, OrganizationBusinessType, useMelioIntl } from '@melio/ar-domain';
import { Form } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { ComponentProps, useEffect } from 'react';

import { DateStringInput } from '../../../../components';
import { useBusinessDetailsForm } from '../BusinessDetailsFormUtils';

type TaxInfoFormSectionProps = ComponentProps<typeof FormLayout.Section>;

export const TaxInfoFormSection = forwardRef<TaxInfoFormSectionProps>(({ ...props }, ref) => {
  const { registerField } = useBusinessDetailsForm();
  const { businessType, hasBusinessType, hasSSn, isEin, last4Digits } = useTaxInfoFormSection();
  const { formatMessage } = useMelioIntl();

  return (
    <FormLayout.Section {...props} ref={ref} data-component={TaxInfoFormSection.displayName}>
      <FormInputs.Select
        {...registerField('businessType')}
        labelProps={{ label: formatMessage('ar.onboarding.activities.businessDetails.form.fields.businessType.label') }}
        placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.fields.businessType.placeholder')}
        options={useBusinessTypeSelectOptions()}
        shouldHideClearButton={false}
      />

      <FormLayout.Section columns={2} isHidden={!hasBusinessType}>
        <Form.Checkbox
          {...registerField('isEin')}
          label={formatMessage('ar.onboarding.activities.businessDetails.form.fields.isEin.label')}
          colSpan={2}
          isHidden={!hasSSn}
        />

        <FormInputs.TaxIdNumber
          {...registerField('taxIdNumber')}
          taxIdType={isEin ? 'EIN' : 'SSN'}
          last4Digits={last4Digits}
          labelProps={{
            label: isEin
              ? formatMessage('ar.onboarding.activities.businessDetails.form.fields.taxIdNumber.label.ein')
              : formatMessage('ar.onboarding.activities.businessDetails.form.fields.taxIdNumber.label.ssn'),
          }}
        />

        <DateStringInput
          {...registerField('legalDateOfBirth')}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalDateOfBirth.label'),
          }}
          dateFormat="MM/dd/yyyy"
        />
      </FormLayout.Section>

      <Form.TextField
        {...registerField('legalName')}
        labelProps={{
          label:
            businessType == 'sole-proprietorship'
              ? formatMessage(
                  'ar.onboarding.activities.businessDetails.form.fields.legalName.sole-proprietorship.label'
                )
              : formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.label'),
        }}
        helperTextProps={{
          label:
            businessType == 'sole-proprietorship'
              ? formatMessage(
                  'ar.onboarding.activities.businessDetails.form.fields.legalName.sole-proprietorship.helperText'
                )
              : formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.helperText'),
        }}
      />
    </FormLayout.Section>
  );
});

const useTaxInfoFormSection = () => {
  const { watch, setValue, formState, clearErrors, trigger } = useBusinessDetailsForm();
  const businessType = watch('businessType');
  const isEin = watch('isEin');
  const hasBusinessType = !!businessType as boolean;
  const hasSSn = businessType === 'sole-proprietorship';

  useEffect(() => {
    if (hasBusinessType) {
      clearErrors(['isEin', 'taxIdNumber', 'legalDateOfBirth']);
      setValue('isEin', businessType !== 'sole-proprietorship');
    }
  }, [clearErrors, businessType, hasBusinessType, setValue]);

  useEffect(() => {
    if (formState.isSubmitted) void trigger('taxIdNumber');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEin, trigger]);

  // reset taxIdNumber on load as the value is masked
  useEffect(() => {
    if (!formState.isDirty) setTimeout(() => setValue('taxIdNumber', ''), 0);
  }, [formState.isDirty, setValue]);

  return {
    businessType,
    hasBusinessType,
    hasSSn,
    isEin,
    last4Digits: formState.defaultValues?.taxIdNumber?.slice(-4) ?? 'XXXX',
  };
};

const useBusinessTypeSelectOptions = (): { label: string; value: OrganizationBusinessType }[] => {
  const { formatMessage } = useMelioIntl();

  return [
    OrganizationBusinessType.SoleProprietorship,
    OrganizationBusinessType.Partnership,
    OrganizationBusinessType.LimitedLiabilityCompany,
    OrganizationBusinessType.Corporation,
    OrganizationBusinessType.MunicipalCorporation,
    OrganizationBusinessType.NonProfit,
  ].map((value) => ({
    value,
    label: formatMessage(`ar.onboarding.activities.businessDetails.form.fields.businessType.options.${value}.label`),
  }));
};
