import { ConversionUtils, useMelioIntl } from '@melio/ar-domain';
import { Group, LoadingContainer, SectionBanner } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { FundingSourceSelection } from '../components';
import { GuestPaymentIntentData } from '../types';
import { getGuestPayorAvailableFundingSourceTypes } from '../utils';
import { BaseLayout } from './Base.layout';
import { InvoiceDetailsHeader, LegalDisclaimer, PaymentMethodTitle } from './components';
import { SharedPaymentLayoutProps } from './types';

export type PaymentLayoutProps = SharedPaymentLayoutProps & {
  isLoading?: boolean;
  isHidden?: boolean;
  guestPaymentIntentData?: GuestPaymentIntentData;
};

export const PaymentLayout = forwardRef<PaymentLayoutProps>(
  (
    {
      onViewInvoice,
      selectedFundingSource,
      onSelectFundingSource,
      isDisabled,
      isLoading: isLoadingContent,
      shouldHidePaymentMethodSelection,
      guestPaymentIntentData,
      children,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const { vendorPaymentDetails } = guestPaymentIntentData || {};
    const isLoading = !guestPaymentIntentData;
    const fees = vendorPaymentDetails?.fees;
    const invoiceTotalAmount = guestPaymentIntentData?.invoice.totalAmount;
    const supportedPaymentMethods = getGuestPayorAvailableFundingSourceTypes(vendorPaymentDetails);

    // TODO extract "shouldShowGoodsReceivedBanner" to pay by card activity after refactoring
    const goodsReceivedAmountThreshold = ConversionUtils.fromDollarsToCents(
      useConfig().settings.guestPaymentFlow.goodsReceivedAmountThreshold
    );
    const shouldShowGoodsReceivedBanner =
      selectedFundingSource === 'card' && invoiceTotalAmount && invoiceTotalAmount > goodsReceivedAmountThreshold;

    return (
      <BaseLayout
        data-layout={PaymentLayout.displayName}
        isLoading={isLoading}
        data-disabled={!!isDisabled}
        isPayable={guestPaymentIntentData?.isPayable}
        backgroundColor={vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
        {...props}
        ref={ref}
      >
        <Group variant="vertical">
          <Group variant="vertical" spacing="l" hasDivider>
            <InvoiceDetailsHeader
              companyName={vendorPaymentDetails?.payeeDetails.companyName}
              companyLogoUrl={vendorPaymentDetails?.payeeDetails.logoUrl ?? undefined}
              invoiceDueDate={guestPaymentIntentData?.invoice.dueDate}
              invoiceTotalAmount={invoiceTotalAmount}
              invoiceFileUrl={guestPaymentIntentData?.invoice.fileInfo?.previewUrls[0]}
              onViewInvoice={isDisabled ? undefined : onViewInvoice}
            />
            <Group variant="vertical" spacing="l">
              {supportedPaymentMethods.length > 0 && !shouldHidePaymentMethodSelection && (
                <Group variant="vertical">
                  {shouldShowGoodsReceivedBanner ? (
                    <SectionBanner
                      data-testid="guest-payment-goods-received-banner"
                      description={formatMessage(
                        supportedPaymentMethods.length == 1
                          ? 'ar.guestPayment.paymentMethods.goodsReceivedBanner.card.description.text'
                          : 'ar.guestPayment.paymentMethods.goodsReceivedBanner.cardAndBank.description.text'
                      )}
                    />
                  ) : null}
                  {supportedPaymentMethods.length > 1 ? (
                    <FundingSourceSelection
                      fees={fees}
                      selectedFundingSource={selectedFundingSource}
                      onCardSelected={() => onSelectFundingSource?.('card')}
                      onBankSelected={() => onSelectFundingSource?.('bank')}
                      isDisabled={isDisabled || isLoading}
                    />
                  ) : supportedPaymentMethods.includes('card') ? (
                    <PaymentMethodTitle
                      title={formatMessage('ar.guestPayment.paymentMethods.payByCard.title.text')}
                      feeDetails={fees?.card}
                    />
                  ) : (
                    <PaymentMethodTitle
                      title={formatMessage('ar.guestPayment.paymentMethods.payByBank.title.text')}
                      feeDetails={fees?.ach}
                    />
                  )}
                </Group>
              )}
              <LoadingContainer isLoading={isLoadingContent}>{children} </LoadingContainer>
            </Group>
          </Group>
          {supportedPaymentMethods.length > 0 ? <LegalDisclaimer /> : null}
        </Group>
      </BaseLayout>
    );
  }
);

PaymentLayout.displayName = 'PaymentLayout';
