export type TransactionType =
  | 'Standard'
  | 'Returns'
  | 'Chargeback'
  | 'Representment'
  | 'ChargebackRefund'
  | 'MerchantDebit';

export type FundingMethod = 'BankAccount' | 'CardAccount';

export type Transaction = {
  id: string;
  transactionType: TransactionType;
  fundingMethod: FundingMethod;
  customerName: string;
  customerAccountNumber: string;
  transactionUri: string;
  invoiceId: string;
  invoiceNumber: string;
  paymentDate: string;
  returnDate?: string;
  paymentAmount: number;
  returnAmount: number;
  settlementAmount: number;
  paymentFee: number;
  returnFee: number;
};

export type Payout = {
  id: string;
  settlementDate: string;
  totalPaymentAmount: number;
  totalPaymentFee: number;
  totalReturnAmount: number;
  totalReturnFee: number;
  fundsHeldToday: number;
  fundsReleasedToday: number;
  totalFundsHeld: number;
  netSettlementAmount: number;
  transactions: Transaction[];
};

const mockTransaction1: Transaction = {
  id: 'transaction_1',
  paymentDate: '2025-03-18',
  returnDate: '2025-03-19',
  paymentAmount: 1000.0,
  returnAmount: 100.0,
  transactionType: 'Standard',
  fundingMethod: 'BankAccount',
  customerName: 'John Doe',
  customerAccountNumber: '1234567890',
  transactionUri: 'https://example.com/transaction/abc123',
  invoiceId: 'arinv_4519712',
  invoiceNumber: 'INV-123343',
  settlementAmount: 900.0,
  paymentFee: 10.0,
  returnFee: 1.0,
};

const mockTransaction2: Transaction = {
  id: 'transaction_2',
  paymentDate: '2025-03-18',
  returnDate: '2025-03-19',
  paymentAmount: 700.0,
  returnAmount: 100.0,
  transactionType: 'Standard',
  fundingMethod: 'BankAccount',
  customerName: 'John Doe',
  customerAccountNumber: '1234567890',
  transactionUri: 'https://example.com/transaction/abc123',
  invoiceId: 'arinv_4519712',
  invoiceNumber: 'INV-123456',
  settlementAmount: 600.0,
  paymentFee: 10.0,
  returnFee: 1.0,
};

export const mockPayout: Payout[] = [
  {
    id: 'payout_1',
    settlementDate: '2025-03-18',
    totalPaymentAmount: 2000.0,
    totalPaymentFee: 50.0,
    totalReturnAmount: 500.0,
    totalReturnFee: 5.0,
    fundsHeldToday: 1000.0,
    fundsReleasedToday: 800.0,
    totalFundsHeld: 2000.0,
    netSettlementAmount: 4600.0,
    transactions: [mockTransaction1, mockTransaction2],
  },
];
