import { usePartnerFeature } from '@melio/platform-provider';

import { Props } from './types';
import { FirmDashboardActivityV1 } from './v1/FirmDashboardV1.activity';
import { FirmDashboardActivityV2 } from './v2/FirmDashboardV2.activity';

export const FirmDashboardActivity = (props: Props) => {
  const [accountantsDashboardV2Enabled] = usePartnerFeature('AccountantsDashboardV2Enabled', false);

  return accountantsDashboardV2Enabled ? (
    <FirmDashboardActivityV2 {...props} />
  ) : (
    <FirmDashboardActivityV1 {...props} />
  );
};
