import { useInvoice, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { Modal, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

export type DeleteInvoiceModalActivityProps = {
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  onDone?: VoidFunction;
  invoiceId?: string;
};

export const DeleteInvoiceModalActivity = forwardRef<DeleteInvoiceModalActivityProps>(
  ({ invoiceId, onClose, onError, onDone = onClose, ...props }, ref) => {
    const {
      data: invoice,
      isLoading,
      delete: deleteInvoice,
      isMutating: isDeletingInvoice,
    } = useInvoice({ id: invoiceId, onError });
    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();
    const onDeleteInvoice = () =>
      void deleteInvoice()
        .then(() => {
          triggerMessage({
            type: 'informative',
            title: formatMessage('ar.dashboard.activities.invoiceTable.toasts.delete.success.label', {
              invoiceNumber: invoice?.invoiceNumber,
            }),
          });
          onDone();
        })
        .catch(onError);

    useAnalyticsView('Invoice', !isLoading, true, {
      PageName: 'delete-invoice',
      Flow: 'delete-invoice',
      Intent: 'delete-invoice',
      InvoiceId: invoice?.id,
      InvoiceStatus: invoice?.displayStatus,
      DueDate: invoice?.dueDate,
    });

    return (
      <Modal
        data-testid={`delete-invoice-modal-activity-${invoiceId ?? ''}`}
        data-component={DeleteInvoiceModalActivity.displayName}
        header={formatMessage('ar.invoiceLifecycle.activities.deleteInvoice.modal.header.text', {
          invoiceNumber: invoice?.invoiceNumber,
        })}
        isLoading={isLoading}
        isOpen={!!invoiceId}
        onClose={() => !isDeletingInvoice && onClose()}
        // TODO: https://meliorisk.atlassian.net/browse/ME-57709
        // disable close icon when `isDeletingInvoice`
        secondaryButton={{
          isDisabled: isDeletingInvoice,
          label: formatMessage('ar.invoiceLifecycle.activities.deleteInvoice.modal.buttons.cancel.label'),
          onClick: onClose,
          variant: 'tertiary',
        }}
        primaryButton={{
          isLoading: isDeletingInvoice,
          label: formatMessage('ar.invoiceLifecycle.activities.deleteInvoice.modal.buttons.confirm.label'),
          variant: 'critical',
          onClick: onDeleteInvoice,
        }}
        {...props}
        ref={ref}
      >
        <Text>{formatMessage('ar.invoiceLifecycle.activities.deleteInvoice.modal.description.text')}</Text>
      </Modal>
    );
  }
);

DeleteInvoiceModalActivity.displayName = 'DeleteInvoiceModalActivity';
