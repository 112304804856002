import { useDateUtils } from '@melio/platform-utils';
import {
  addDays,
  addMonths,
  addQuarters,
  endOfMonth,
  endOfQuarter,
  endOfYear,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subQuarters,
  subYears,
} from 'date-fns';

import { useMelioIntl } from '../../i18n';
import { DatePreset, DatePresetsType, DateRange, DateRangeDates, DateRangeFilterType } from './types';

const getDateRange = (preset: DatePreset, today: Date, customDateRange?: DateRangeDates): DateRange => {
  const formatDate = (date: Date): string => format(date, 'yyyy-MM-dd');

  switch (preset) {
    case 'ThisMonth':
      return { start: formatDate(startOfMonth(today)), end: formatDate(endOfMonth(today)) };
    case 'Next30Days':
      return { start: formatDate(today), end: formatDate(addDays(today, 30)) };
    case 'NextMonth':
      return { start: formatDate(startOfMonth(addMonths(today, 1))), end: formatDate(endOfMonth(addMonths(today, 1))) };
    case 'ThisQuarter':
      return { start: formatDate(startOfQuarter(today)), end: formatDate(endOfQuarter(today)) };
    case 'NextQuarter':
      return {
        start: formatDate(startOfQuarter(addQuarters(today, 1))),
        end: formatDate(endOfQuarter(addQuarters(today, 1))),
      };
    case 'Last30Days':
      return { start: formatDate(subDays(today, 30)), end: formatDate(today) };
    case 'ThisWeek':
      return { start: formatDate(startOfWeek(today)), end: formatDate(today) };
    case 'PreviousMonth':
      return { start: formatDate(startOfMonth(subMonths(today, 1))), end: formatDate(endOfMonth(subMonths(today, 1))) };
    case 'PreviousQuarter':
      return {
        start: formatDate(startOfQuarter(subQuarters(today, 1))),
        end: formatDate(endOfQuarter(subQuarters(today, 1))),
      };
    case 'ThisYear':
      return { start: formatDate(startOfYear(today)), end: formatDate(endOfYear(today)) };
    case 'PreviousYear':
      return { start: formatDate(startOfYear(subYears(today, 1))), end: formatDate(endOfYear(subYears(today, 1))) };
    case 'Custom':
      if (!customDateRange) {
        throw new Error('Custom date range is required');
      }
      return {
        start: customDateRange.start ? formatDate(customDateRange.start) : undefined,
        end: customDateRange.end ? formatDate(customDateRange.end) : undefined,
      };
    default:
      throw new Error('Invalid date preset');
  }
};

export const useDateRangeFilter = <T extends DateRangeFilterType>(config: T) => {
  const { createDate } = useDateUtils();
  const { formatMessage } = useMelioIntl();

  const { presets, filterLabel } = config;
  const today = createDate();

  const getPresetLabel = (preset: DatePreset): string => {
    const DatePresets: DatePresetsType = {
      ThisWeek: formatMessage('ar.domain.components.dateRangeFilter.presets.thisWeek'),
      ThisMonth: formatMessage('ar.domain.components.dateRangeFilter.presets.thisMonth'),
      Last30Days: formatMessage('ar.domain.components.dateRangeFilter.presets.last30Days'),
      Next30Days: formatMessage('ar.domain.components.dateRangeFilter.presets.next30Days'),
      NextMonth: formatMessage('ar.domain.components.dateRangeFilter.presets.nextMonth'),
      ThisQuarter: formatMessage('ar.domain.components.dateRangeFilter.presets.thisQuarter'),
      NextQuarter: formatMessage('ar.domain.components.dateRangeFilter.presets.nextQuarter'),
      PreviousMonth: formatMessage('ar.domain.components.dateRangeFilter.presets.previousMonth'),
      PreviousQuarter: formatMessage('ar.domain.components.dateRangeFilter.presets.previousQuarter'),
      ThisYear: formatMessage('ar.domain.components.dateRangeFilter.presets.thisYear'),
      PreviousYear: formatMessage('ar.domain.components.dateRangeFilter.presets.previousYear'),
      Custom: formatMessage('ar.domain.components.dateRangeFilter.presets.custom'),
    };

    return DatePresets[preset];
  };

  const getPresetRange = (preset: DatePreset, customDateRange?: DateRangeDates): DateRange =>
    getDateRange(preset, today, customDateRange);

  return {
    presets,
    filterLabel,
    getPresetLabel,
    getPresetRange,
  };
};
