import { useMelioIntl } from '@melio/ar-domain';
import { useFormSubmissionController } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ActivationLayout } from '../../../layouts';
import { BusinessDetailsForm, BusinessDetailsFormProps } from '../components';
import { BusinessDetailsFormFields } from '../types';

export type BusinessDetailsScreenProps = Pick<
  BusinessDetailsFormProps,
  'onSubmit' | 'defaultValues' | 'isLoading' | 'isSaving'
> & {
  onBack: VoidFunction;
  onClose?: VoidFunction;
};

export const BusinessDetailsScreen = forwardRef<BusinessDetailsScreenProps>(
  ({ isLoading, isSaving, onSubmit, onClose, onBack, defaultValues, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { onSubmissionStateChange, submitButtonProps, cancelButtonProps } =
      useFormSubmissionController<BusinessDetailsFormFields>();

    return (
      <ActivationLayout
        isLoading={isLoading}
        step="business-details"
        primaryButtonProps={{ ...submitButtonProps }}
        secondaryButtonProps={{ ...cancelButtonProps, onClick: onBack }}
        tertiaryButtonProps={{ ...cancelButtonProps, onClick: onClose }}
        data-component={BusinessDetailsScreen.displayName}
        header={{
          title: formatMessage('ar.onboarding.activities.businessDetails.header.text'),
          description: formatMessage('ar.onboarding.activities.businessDetails.description.text'),
        }}
        {...props}
        ref={ref}
      >
        <BusinessDetailsForm
          defaultValues={defaultValues}
          onSubmissionStateChange={onSubmissionStateChange}
          onSubmit={onSubmit}
          isSaving={isSaving}
          isLoading={isLoading}
        />
      </ActivationLayout>
    );
  }
);
BusinessDetailsScreen.displayName = 'BusinessDetailsScreen';
