import { useDeliveryMethodFee } from '@melio/ap-widgets';
import { IconKey, PillProps } from '@melio/penny';
import { DeliveryMethod, formatAddress, FundingSource, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

export const useDeliveryMethodCardProps = ({
  vendor,
  deliveryMethod,
  fundingSource,
}: {
  vendor: Vendor | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  fundingSource?: FundingSource;
}): {
  title: string;
  description: string;
  icon: IconKey;
  pillProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage } = useMelioIntl();

  const { getFee } = useDeliveryMethodFee(fundingSource?.type);

  if (!vendor || !deliveryMethod) {
    return {
      title: '',
      icon: 'error',
      description: '',
    };
  }

  const fee = getFee(deliveryMethod.type);
  const pillProps = fee
    ? [
        {
          label: formatMessage('utilities.fees', { feeValue: fee }),
          status: 'brand' as const,
          type: 'secondary' as const,
          'data-testid': 'fee-pill',
        },
      ]
    : undefined;

  switch (deliveryMethod.type) {
    case 'managed-account':
      if (deliveryMethod.details.deliveryType === 'check') {
        return {
          title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.title'),
          description: formatMessage(
            'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.check.description',
            { vendorName: vendor.name }
          ),
          icon: 'paper-check',
        };
      }
      return {
        title: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.title'
        ),
        description: formatMessage(
          'activities.paymentFlow.form.content.deliveryMethodCard.managed-account.bank-account.description',
          { vendorName: vendor.name }
        ),
        icon: 'bank-out',
        pillProps,
      };
    case 'bank-account':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.bank-account.description', {
          account: deliveryMethod.details.accountNumberLast4Digits,
        }),
        icon: 'bank-out',
        pillProps,
      };
    case 'paper-check':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.title'),
        description: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paper-check.description', {
          vendorName: vendor.name,
          address: formatAddress(deliveryMethod.details.address),
        }),
        icon: 'paper-check',
        pillProps,
      };
    case 'paypal-balance':
      return {
        title: formatMessage('activities.paymentFlow.form.content.deliveryMethodCard.paypal-balance.title'),
        description: deliveryMethod.details.paypalAccountEmail,
        icon: 'paypal',
        pillProps,
      };

    default:
      return {
        title: 'not implemented',
        description: 'not implemented',
        icon: 'error',
      };
  }
};
