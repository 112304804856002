import { Box } from '@chakra-ui/react';
import { useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Icon, IconKey, NakedButton, Switch, Text, Tooltip } from '@melio/penny';

export type PaymentOptionSwitcherProps = {
  isFeeEditingDisabled?: boolean;
  iconType: IconKey;
  title: string;
  subtitle: string;
  value: boolean;
  onChange: (enabled: boolean) => void;
  isUpdating: boolean;
  paymentOptionType: 'card' | 'ACH';
  editFeeAbsorptionLabel?: string;
  onEditFeeAbsorption?: VoidFunction;
};

export const PaymentOptionSwitcher = ({
  isFeeEditingDisabled,
  iconType,
  title,
  subtitle,
  value,
  onChange,
  isUpdating,
  paymentOptionType,
  editFeeAbsorptionLabel,
  onEditFeeAbsorption,
}: PaymentOptionSwitcherProps) => {
  const { formatMessage } = useMelioIntl();

  const feePreferenceLink = editFeeAbsorptionLabel ? (
    <Box display="inline-block">
      <Group alignItems="center" spacing="xxs">
        <NakedButton
          data-testid="edit-global-fee-absorption-settings-button"
          isDisabled={isFeeEditingDisabled}
          variant="secondary"
          onClick={onEditFeeAbsorption}
          label={editFeeAbsorptionLabel}
        />
        {isFeeEditingDisabled && (
          <Tooltip content={formatMessage('ar.domain.common.tooltip.feesAbsorptionSettingsDisabled')}>
            <Icon data-testid="card-pay-option-fees-settings-not-available-tooltip" size="small" type="info" />
          </Tooltip>
        )}
      </Group>
    </Box>
  ) : null;

  return (
    <Container border="regular" paddingX="s" paddingY="s">
      <Group justifyContent="space-between">
        <Group alignItems="center">
          <Icon type={iconType} />
          <Group spacing="none" variant="vertical" width="fit-content">
            <Text textStyle="body2Semi">{title}</Text>
            <Text color="global.neutral.700" textStyle="body3">
              {subtitle} {feePreferenceLink}
            </Text>
          </Group>
        </Group>
        <Switch
          data-testid={`invoice-payment-option-${paymentOptionType}`}
          aria-hidden
          value={value}
          onChange={onChange}
          label={formatMessage(
            value
              ? 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.on.label'
              : 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.off.label'
          )}
          aria-label={formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.container.switch.ariaLabel',
            { switchTitle: title }
          )}
          isDisabled={isUpdating}
        />
      </Group>
    </Container>
  );
};
