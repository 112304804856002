import { Box } from '@chakra-ui/react';
import { useMelioIntl, useReceivingMethods } from '@melio/ar-domain';
import { Group, Icon, NakedButton, Tooltip } from '@melio/penny';
import { BankAccountVerifiedStatusEnum } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { useInvoiceFormContext } from '../../../../utils';
import { PayOptionFieldName } from '../types';

type UsePayOptionDescriptionLabelProps = {
  isDisabled: boolean;
  payOptionType: 'bankTransfer' | 'card';
  onOpenCardFeesSettings?: VoidFunction;
};

export const usePayOptionDescriptionLabel = ({
  isDisabled: _isDisabled,
  payOptionType,
  onOpenCardFeesSettings,
}: UsePayOptionDescriptionLabelProps) => {
  const { formatMessage } = useMelioIntl();
  const { watch, formProps } = useInvoiceFormContext();
  const { data: receivingMethods } = useReceivingMethods();
  const [isFeeAbsorptionSettingsEnabled] = usePartnerFeature('IsArFeeAbsorptionSettingsEnabled', false);

  const hasVerifiedDeliveryMethod = receivingMethods?.some(
    (rm) =>
      rm.details.verifiedStatus !== BankAccountVerifiedStatusEnum.PlaidNotVerified &&
      rm.details.verifiedStatus !== BankAccountVerifiedStatusEnum.NotVerified
  );

  const isDisabled = Boolean(
    _isDisabled || !hasVerifiedDeliveryMethod || formProps.isReadOnly || !watch(PayOptionFieldName[payOptionType])
  );

  const cardFeeSettingsLink = (
    <Box display="inline-block">
      <Group alignItems="center" spacing="xxs">
        <NakedButton
          data-testid="card-pay-option-fees-settings-button"
          isDisabled={isDisabled}
          label={formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.card.description.link.edit.text'
          )}
          onClick={onOpenCardFeesSettings}
          variant="secondary"
        />
        {!hasVerifiedDeliveryMethod && (
          <Tooltip content={formatMessage('ar.domain.common.tooltip.feesAbsorptionSettingsDisabled')}>
            <Icon data-testid="card-pay-option-fees-settings-not-available-tooltip" size="small" type="info" />
          </Tooltip>
        )}
      </Group>
    </Box>
  );

  if (isFeeAbsorptionSettingsEnabled && payOptionType === 'card') {
    return formatMessage(
      `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.card.description.fees.${
        watch('isCardFeesAbsorbedByPayor') ? 'payor' : 'vendor'
      }.text`,
      { link: cardFeeSettingsLink }
    );
  }

  return formatMessage(
    `ar.invoiceLifecycle.activities.createInvoice.inputs.payOptions.options.${payOptionType}.description.text`
  );
};
