import { Box } from '@chakra-ui/react';
import { EntityMatchingTableWidget } from '@melio/ap-widgets';
import { EntityMatchingTableWidgetProps } from '@melio/ap-widgets/src/components/EntityMatchingTable/EntityMatchingTable.widget';
import { Container, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { FooterContent } from './components/FooterContent';

type Props = {
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  isConfirmLoading: boolean;
  onCardOpen?: (index: number) => void;
} & EntityMatchingTableWidgetProps;

export const VendorLinkingScreen = ({
  entities,
  isLoading,
  onSelect,
  onSelectAll,
  onCancel,
  onConfirm,
  isConfirmLoading,
  onCardOpen,
}: Props) => {
  const [isCheckAllVendorsWhenMatchingFeatureEnabled] = usePartnerFeature('CheckAllVendorsWhenMatching', false);

  const { formatMessage } = useMelioIntl();

  const description = isCheckAllVendorsWhenMatchingFeatureEnabled
    ? formatMessage('activities.accountingPlatformConnectCallback.screens.vendorLinking.description.preselected')
    : formatMessage('activities.accountingPlatformConnectCallback.screens.vendorLinking.description.unselected');

  return (
    <NewSinglePaymentStepLayout
      maxWidth="800px"
      backgroundColor="white"
      data-component="AccountingPlatformConnectActivity.VendorLinkingScreen"
      data-testid="accounting-platform-vendor-linking-screen"
      headerContent={<Box paddingBottom="xs" />}
      isStickyFooter
      isLoading={isLoading}
      footerContent={<FooterContent onClose={onCancel} onSubmit={onConfirm} isLoading={isConfirmLoading} />}
    >
      <Container overflow="visible">
        <Group variant="vertical" spacing="m">
          <NewSinglePaymentStepLayout.Title textAlign="start" tabIndex={-1} onClose={onCancel}>
            {formatMessage('activities.accountingPlatformConnectCallback.screens.vendorLinking.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description textAlign="start" as="p">
            {description}
          </NewSinglePaymentStepLayout.Description>
        </Group>

        <Container paddingY="l" overflow="visible">
          <Group variant="vertical" alignItems="center">
            <EntityMatchingTableWidget
              entities={entities}
              onSelect={onSelect}
              onSelectAll={onSelectAll}
              onCardOpen={onCardOpen}
            />
          </Group>
        </Container>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
