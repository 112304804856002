import { useIsAccountingFirm } from '@melio/platform-api';
import { Plan, Tier } from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

export const usePlanDescription = (plan?: Plan) => {
  const { formatMessage } = useMelioIntl();
  const isAccountingFirm = useIsAccountingFirm();
  const [isSubscriptionsRemoveAchTPlus2FromSmbVip] = useDevFeature(
    FeatureFlags.SubscriptionsRemoveAchTPlus2FromSmbVip,
    false
  );

  if (isAccountingFirm) {
    return formatMessage('activities.subscription.plans.accountingFirm.description');
  }

  // note: when the FF isSubscriptionsRemoveAchTPlus2FromSmbVip is merged we will need to copy the content from activities.subscription.plans.MELIO_VIP.description.withoutTPlus2 to activities.subscription.plans.MELIO_VIP.description
  if (isSubscriptionsRemoveAchTPlus2FromSmbVip && plan?.tier === Tier.MelioVip) {
    return formatMessage('activities.subscription.plans.MELIO_VIP.description.withoutTPlus2');
  }

  return plan ? formatMessage(`activities.subscription.plans.${plan.tier}.description`) : '';
};
