import { createDateFormatter, useAccount, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { BusinessDetailsScreen, BusinessDetailsScreenProps } from './screens';

type BusinessDetailsActivityProps = {
  onDone: VoidFunction;
  onError?: ARErrorFunction;
  onBack: VoidFunction;
  onClose?: VoidFunction;
};

export const BusinessDetailsActivity = forwardRef(
  ({ onDone, onBack, onClose, onError, ...props }: BusinessDetailsActivityProps, ref) => {
    const accountsModel = useAccount({ id: 'me', onError });

    const { triggerMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();

    const defaultVales = useMemo<BusinessDetailsScreenProps['defaultValues']>(() => {
      const { legalAddress, legalName, businessType, industry, legalDateOfBirth, taxInfo } =
        accountsModel.data?.company ?? {};
      return {
        taxIdNumber: taxInfo?.identifier ?? '',
        legalName: legalName ?? '',
        businessType: businessType ?? undefined,
        industry: industry ?? undefined,
        legalDateOfBirth: legalDateOfBirth ? formatLegalDateOfBirth(legalDateOfBirth, { target: 'MM/dd/yyyy' }) : '',
        legalAddress: {
          line1: legalAddress?.line1 ?? '',
          city: legalAddress?.city ?? '',
          state: legalAddress?.state ?? '',
          postalCode: legalAddress?.postalCode ?? '',
        },
        isEin: taxInfo?.type !== 'SSN',
      };
    }, [accountsModel.data?.company]);

    const updateAccount: BusinessDetailsScreenProps['onSubmit'] = ({
      businessType,
      legalAddress,
      legalDateOfBirth,
      legalName,
      industry,
      taxIdNumber,
      isEin,
    }) => {
      accountsModel
        .update({
          company: {
            legalDateOfBirth: formatLegalDateOfBirth(legalDateOfBirth, { target: 'yyyy-MM-dd' }),
            legalName,
            legalAddress: {
              city: legalAddress.city,
              line1: legalAddress.line1,
              postalCode: legalAddress.postalCode,
              state: legalAddress.state,
              aptNumber: '1',
              line2: '',
            },
            industry: {
              name: industry.name,
              naicsCode: industry.naicsCode,
            },
            taxInfo: { identifier: taxIdNumber.replace(/-/g, ''), type: isEin ? 'EIN' : 'SSN' },
            businessType,
          },
        })
        .then(onDone)
        .catch((e) => {
          triggerMessage({
            type: 'critical',
            title: formatMessage('ar.onboarding.activities.businessDetails.global.error.message'),
          });
          onError?.(e as never);
        });
    };

    return (
      <BusinessDetailsScreen
        isLoading={accountsModel.isLoading}
        onBack={onBack}
        onClose={onClose}
        isSaving={accountsModel.isMutating}
        onSubmit={updateAccount}
        defaultValues={defaultVales}
        {...props}
        ref={ref}
      />
    );
  }
);

const formatLegalDateOfBirth = createDateFormatter('MM/dd/yyyy', 'yyyy-MM-dd');
