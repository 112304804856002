import { SelectionCard, Tooltip } from '@melio/penny';
import { Card, FundingSource } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import noop from 'lodash/noop';
import { Fragment } from 'react/jsx-runtime';

import { getFundingSourceIconByType, UnsupportedFundingSourceTypeReasons } from '../../../utils';
import { useAddFundingSourceCardHelperTexts } from './AddFundingSourceCard.utils';
import { CardPaymentLearnMoreModal, useCardPaymentLearnMoreModal } from './CardPaymentLearnMoreModal';
import { CardPaymentPromotionModal, useCardPaymentPromotionPopup } from './CardPaymentPromotionModal';

export type AddFundingSourceCardProps = {
  fundingType: FundingSource['type'];
  cardType?: Card['type'];
  onClick?: (ctaLabel: string) => void;
  isDisabled?: boolean;
  disabledReasons?: UnsupportedFundingSourceTypeReasons[];
};

const FragmentWithoutProps: React.FC = ({ children }) => <Fragment>{children}</Fragment>; // this is to avoid the console warning that react shows when providing props for a fragment

export const AddFundingSourceCard = forwardRef<AddFundingSourceCardProps, 'div'>(
  ({ fundingType, cardType, onClick = noop, isDisabled, disabledReasons, ...props }, ref) => {
    const { settings } = useConfig();
    const { formatMessage } = useMelioIntl();
    const fsIcon = getFundingSourceIconByType({ fundingType, bankIcon: settings.icons.fundingSource.bank, cardType });
    const { getAddFundingSourceHelperTextByType, getAddFundingSourceContentTextByType } =
      useAddFundingSourceCardHelperTexts();

    const canAddCardFundingSource: boolean = !cardType && !isDisabled;
    const {
      mainLabelProps,
      descriptionProps,
      isOpen: isLearnMoreOpen,
      onDismiss: onDismissLearnMore,
    } = useCardPaymentLearnMoreModal(canAddCardFundingSource);
    const { isOpen: isPromotionOpen, onDismiss: onDismissPromotion } =
      useCardPaymentPromotionPopup(canAddCardFundingSource);

    const tooltip =
      isDisabled && disabledReasons?.length === 1 && disabledReasons[0] === 'internationalPaymentRestrictions'
        ? formatMessage(
            'widgets.fundingSources.selectFundingSourceCard.card.disabledReason.cardFundingSourceNotSupported'
          )
        : false;

    const cardLabel = getAddFundingSourceContentTextByType(fundingType, cardType);

    const TooltipComponent = tooltip ? Tooltip : FragmentWithoutProps;

    return (
      <>
        <TooltipComponent
          data-testid={`add-${fundingType as string}-funding-source-card-tooltip`}
          content={tooltip}
          shouldAddTriggerFocus={isDisabled}
        >
          <SelectionCard
            data-component="AddFundingSourceCard"
            data-testid={`add-${fundingType as string}-funding-source-card`}
            {...props}
            ref={ref}
            icon={fsIcon}
            mainLabelProps={{ label: cardLabel, ...mainLabelProps }}
            descriptionProps={{
              label: getAddFundingSourceHelperTextByType(fundingType, cardType),
              ...descriptionProps,
            }}
            onClick={() => onClick(cardLabel)}
            isDisabled={isDisabled}
          />
        </TooltipComponent>
        {canAddCardFundingSource && (
          <>
            <CardPaymentPromotionModal isOpen={isPromotionOpen} onDismiss={onDismissPromotion} />
            <CardPaymentLearnMoreModal
              isOpen={isLearnMoreOpen}
              onDismiss={onDismissLearnMore}
              onCardAction={() => onClick(cardLabel)}
            />
          </>
        )}
      </>
    );
  }
);

AddFundingSourceCard.displayName = 'AddFundingSourceCard';
