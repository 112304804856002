import { useOnce, useOrganizationEnrollmentStatus, useSystemMessage } from '@melio/ar-domain';

export type ActivateOrganizationProps = {
  onDone?: VoidFunction;
  onError?: ARErrorFunction;
};

export const ActivateOrganizationActivity = ({ onDone, onError }: ActivateOrganizationProps) => {
  const { activateOrganization } = useOrganizationEnrollmentStatus({ enabled: false });
  const { triggerMessage } = useSystemMessage();

  useOnce(() => {
    activateOrganization()
      .then(onDone)
      .catch((error: ARPlatformError) => {
        triggerMessage({ type: 'critical', title: 'Something went wrong with activation, please try again' });
        onError?.(error);
      });
  });

  return null;
};

ActivateOrganizationActivity.displayName = 'ActivateOrganizationActivity';
