import { screen } from '@testing-library/react';
export class SubscriptionPaymentMethodCardDriver {
  getComponent() {
    return screen.queryByTestId('subscription-payment-method-card');
  }

  getTitle() {
    return screen.queryByTestId('subscription-payment-method-card-title');
  }

  getDescription() {
    return screen.queryByTestId('subscription-payment-method-card-description');
  }
}

export class SubscriptionPaymentMethodCardCypressDriver {
  clickChangeButton() {
    cy.getByTestId('subscription-payment-method-card-change-button').click();
  }
}
