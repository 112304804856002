/* eslint-disable no-restricted-imports */
import { Group, Text } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFirmClientSubscriptionActionsProps } from '../../../FirmDashboard/hooks/useFirmClientSubscriptionActionsProps';
import { useClientActions } from '../../../FirmDashboard/v2/hooks';
import { EmptyState } from '../EmptyState/EmptyState';
import { SectionWrapper } from '../SectionWrapper';
import { BillingInfo } from './BillingInfo';
import { PlanInfo } from './PlanInfo';
import { SubscriptionActionsMenu } from './SubscriptionActionsMenu';

type Props = {
  client: AccountingFirmClientExpanded;
  goToSettingsSubscriptionPlansAndRefresh: VoidFunction;
  goToSettingsAndRefresh: VoidFunction;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const SubscriptionSection = ({
  client,
  goToSettingsSubscriptionPlansAndRefresh,
  onSwitchOrganization,
  goToSettingsAndRefresh,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { data: accountingFirm, isLoading } = useAccount({ id: 'me' });

  const {
    onManageClientBillingOption,
    onManageClientPlan,
    onGoToAssignPlan,
    onProceedCheckout,
    onViewBillingHistoryOption,
  } = useClientActions({
    goToSettingsSubscriptionPlansAndRefresh,
    goToSettingsAndRefresh,
    onSwitchOrganization,
  });

  const { subscriptionPlanDropdownActionsProps, limitedBillingOptionsType } = useFirmClientSubscriptionActionsProps({
    clientOrgId: client.organizationInfo.id,
    subscriptionInfo: client?.subscriptionInfo,
    firmOrganizationId: accountingFirm?.organizationId || '',
    isDataFetchedSuccessfully: !isLoading,
    onProceedToCheckout: () => onProceedCheckout(client.organizationInfo.id),
    onManageClientBillingOption: () => onManageClientBillingOption(client.organizationInfo.id),
    onManageClientPlan: () => onManageClientPlan(client.organizationInfo.id),
    onGoToAssignPlan: () => onGoToAssignPlan(client.organizationInfo.id),
    onViewBillingHistoryOption: ({ isClientPayingForSubscription, isFirmPayingForSubscription }) =>
      void onViewBillingHistoryOption(client.organizationInfo.id, {
        isClientPayingForSubscription,
        isFirmPayingForSubscription,
      }),
  });

  return (
    <SectionWrapper dataTestId="drawer-subscription-section" withDivider={false}>
      <Group justifyContent="center">
        <Group.Item grow={1}>
          <Text textStyle="heading3Semi">
            {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.subscription.semiHeader.title')}
          </Text>
        </Group.Item>
        <Group.Item>
          <SubscriptionActionsMenu subscriptionPlanDropdownActions={subscriptionPlanDropdownActionsProps} />
        </Group.Item>
      </Group>
      {client.subscriptionInfo?.subscription ? (
        <Group variant="vertical" spacing="m">
          <Group variant="vertical" spacing="none">
            <PlanInfo client={client} />
          </Group>
          <Group variant="vertical" spacing="none">
            <BillingInfo client={client} limitedBillingOptionsType={limitedBillingOptionsType} />
          </Group>
        </Group>
      ) : (
        <EmptyState
          dataTestId="subscription-empty-state"
          text={formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.subscription.emptyState.label')}
        />
      )}
    </SectionWrapper>
  );
};
