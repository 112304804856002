import { Container, Divider, Group, LoadingContainer, Skeleton } from '@melio/penny';
import { useAccount } from '@melio/platform-api';
import { useIsSubscriptionsEnabled, usePlan } from '@melio/subscriptions';
import { SubscriptionBillingCycleEnum, useGetOffer, useSubscriptionsPreview } from '@melio/subscriptions/src/api';
import { SubscriptionCheckoutErrorBanner } from '@melio/subscriptions/src/ui';
import {
  SubscriptionCheckoutBillingTotal,
  SubscriptionCheckoutPromoCode,
  SubscriptionCheckoutSummaryPlan,
  SubscriptionDetailedSubtotal,
} from '@melio/subscriptions/src/ui/components/SubscriptionCheckoutSummary';
import { noop } from 'lodash';
import { useEffect } from 'react';

import { FIRM_DISCOUNT_PERCENTAGE_DEFAULT } from '../../consts';
import { useFirmClient } from '../../hooks';
import { FirmClientSubscriptionReviewAndConfirmDisclaimer } from './components/FirmClientSubscriptionReviewAndConfirmDisclaimer.component';

export type PreviewState = 'loading' | 'error' | 'fetched';

type FirmClientSubscriptionReviewAndConfirmActivityProps = {
  planId: string;
  fundingSourceId: string | null;
  organizationId: string | undefined;
  seatsQuantity?: number;
  planCycle: SubscriptionBillingCycleEnum;
  onPreviewStateChange?: (state: PreviewState) => void;
  promoCode?: string;
  onPromoCodeChange: (value?: string) => void;
  shouldPrefetchPromoCode: boolean;
  suppressPromoCode?: boolean;
  isFirmBilled?: boolean;
};

export const FirmClientSubscriptionReviewAndConfirmActivity = ({
  planId,
  fundingSourceId,
  organizationId,
  planCycle,
  onPreviewStateChange,
  seatsQuantity,
  promoCode,
  onPromoCodeChange,
  shouldPrefetchPromoCode,
  suppressPromoCode,
  isFirmBilled,
}: FirmClientSubscriptionReviewAndConfirmActivityProps) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: client } = useFirmClient(organizationId);
  const { data: plan, isFetching: isFetchingPlan, error: planError } = usePlan({ id: planId });
  const {
    data: offer,
    error: offerError,
    isFetching: isFetchingOffer,
  } = useGetOffer({ id: plan?.offerId ?? '', enabled: !!plan });
  const { data: accountData } = useAccount({ id: 'me' });
  const {
    data: preview,
    isFetching: isPreviewFetching,
    error: previewError,
  } = useSubscriptionsPreview({
    enabled: isSubscriptionsEnabled,
    params: {
      planId,
      fundingSourceId: fundingSourceId || undefined,
      unitQuantity: !organizationId ? seatsQuantity : undefined,
      planCycle,
      organizationId,
      promoCode,
    },
  });

  useEffect(() => {
    onPreviewStateChange?.(isPreviewFetching ? 'loading' : previewError ? 'error' : 'fetched');
  }, [onPreviewStateChange, isPreviewFetching, previewError]);

  const error = previewError || offerError || planError;
  const isLoading = isFetchingPlan || isFetchingOffer;
  const billingAddress = isFirmBilled ? accountData?.company.legalAddress : client?.organization.legalAddress;

  return (
    <LoadingContainer isLoading={isLoading}>
      {error ? (
        <SubscriptionCheckoutErrorBanner error={error} />
      ) : (
        <Container
          backgroundColor="default"
          border="regular"
          height="auto"
          overflow="hidden"
          paddingX="m"
          paddingY="m"
          width="full"
          data-testid="review-and-confirm-container"
        >
          <Group variant="vertical" spacing="s">
            <Group variant="vertical" spacing="m">
              {!!plan && (
                <SubscriptionCheckoutSummaryPlan
                  selectedPlan={plan}
                  currentCycle={planCycle}
                  preview={preview}
                  isLoading={isPreviewFetching}
                  predefinedDiscountPercentage={offer?.integratedDiscount || FIRM_DISCOUNT_PERCENTAGE_DEFAULT}
                />
              )}

              <Divider />
              {!suppressPromoCode && isSubscriptionsEnabled && (
                <SubscriptionCheckoutPromoCode
                  preview={preview}
                  promoCode={promoCode}
                  isLoading={isPreviewFetching}
                  shouldPrefetchPromoCode={shouldPrefetchPromoCode}
                  onChangePromoCode={onPromoCodeChange}
                  onDoneFetchingPromoCodeData={noop}
                  currentCycle={planCycle}
                  isNestedForm
                />
              )}

              {preview && !isPreviewFetching ? (
                <SubscriptionDetailedSubtotal legalAddress={billingAddress ?? null} preview={preview} />
              ) : (
                <Container>
                  <Skeleton height="12px" />
                </Container>
              )}

              <Divider />
              <SubscriptionCheckoutBillingTotal preview={preview} isFreeTrial={false} isLoading={isPreviewFetching} />
              {isFirmBilled && (
                <FirmClientSubscriptionReviewAndConfirmDisclaimer
                  isLoading={isPreviewFetching}
                  preview={preview}
                  planCycle={planCycle}
                />
              )}
            </Group>
          </Group>
        </Container>
      )}
    </LoadingContainer>
  );
};
