import jwtDecode from 'jwt-decode';
import { useAccountantsRoutes } from '@melio/accountants';
import {
  AccountingPlatformConnectActivity,
  AccountingPlatformSyncConnectionErrorEnum,
  SyncFlowEnum,
} from '@melio/ap-activities';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { getAccessTokenOrganizationId, getAccessTokenUserId } from '@/utils/getAccessTokenData.utils';
import { getPartnerName } from '@/utils/partner.utils';

export const AccountingPlatformAuthCallbackScreen = () => {
  const { track, trackMarketing } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const { goToClientAccountingSoftwareRedirect } = useAccountantsRoutes();
  const {
    goToAccountingPlatform,
    goToAccountingPlatformSync,
    goToAccountingPlatformError,
    goToPayDashboard,
    goToSuccessfulQboConnectLink,
  } = useRouter();
  const { formatMessage } = useMelioIntl();
  const partnerName = getPartnerName();
  const {
    partnerConfig: { displayName: partnerDisplayName },
  } = usePartnerConfig();

  const onQuickBooksOnlineDone = ({
    flow,
    orgId,
    authToken,
    accountingPlatformId,
    revalidateAccountingPlatforms,
  }: {
    flow: SyncFlowEnum;
    orgId?: string | null;
    authToken?: string | null;
    accountingPlatformId?: string;
    accountingPlatformName?: string;
    revalidateAccountingPlatforms?: boolean;
  }) => {
    const isSettingFlow = flow === SyncFlowEnum.Setting;
    const accessTokenUserId = getAccessTokenUserId();
    const accessTokenOrganizationId = getAccessTokenOrganizationId();

    if (revalidateAccountingPlatforms) {
      emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_CONNECTED, { accountingPlatformId });
    }

    if (flow === SyncFlowEnum.AccountingFirmAddClient) {
      goToClientAccountingSoftwareRedirect(`org_${orgId}`);
      return;
    }

    if (!authToken) {
      if (isSettingFlow) {
        goToAccountingPlatform({
          syncError: AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          accountingPlatformId,
        });
      } else {
        goToAccountingPlatformError({
          syncError: AccountingPlatformSyncConnectionErrorEnum.GeneralError,
          flowToReturn: flow,
        });
      }
      return;
    }
    const { userId } = jwtDecode<{ userId: number }>(authToken!);

    if (Number(accessTokenUserId) !== Number(userId) || Number(orgId) !== Number(accessTokenOrganizationId)) {
      if (isSettingFlow) {
        goToAccountingPlatform({
          syncError: AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount,
          accountingPlatformId,
        });
      } else {
        goToAccountingPlatformError({
          syncError: AccountingPlatformSyncConnectionErrorEnum.AccountPlatformConnectToDifferentMelioAccount,
          flowToReturn: flow,
        });
      }
    } else if (!isSettingFlow && accountingPlatformId) {
      trackMarketing('connected-quickbooks');
      if (flow === SyncFlowEnum.ContextualOnboarding) {
        track('SyncAccountingSoftware', 'Status', {
          StatusType: 'success',
          Cta: 'connect-your-quickbooks-online',
          PageName: 'onboarding',
          Flow: 'accounting-software-connection',
          Intent: 'connect-accounting-software',
          AccountingSoftwareType: 'quickbooks-online',
        });
        goToSuccessfulQboConnectLink();
      } else {
        goToAccountingPlatformSync({ accountingPlatformId, flowToReturn: flow });
      }
    } else {
      showMessage({
        type: 'success',
        dataTestId: 'accounting-platform-sync-success',
        title: formatMessage('activities.accountingPlatformConnectCallback.success.quickbooksOnlineToast'),
      });
      trackMarketing('connected-quickbooks');
      goToAccountingPlatform({});
    }
  };

  const onDone = ({
    flow,
    accountingPlatformName,
    accountingPlatformNameForAnalytics,
  }: {
    flow: SyncFlowEnum;
    accountingPlatformName: string;
    accountingPlatformNameForAnalytics: string;
  }) => {
    showMessage({
      type: 'success',
      dataTestId: 'accounting-platform-sync-success',
      title: formatMessage('activities.accountingPlatformConnectCallback.success.toast', { accountingPlatformName }),
    });

    if (flow === SyncFlowEnum.Setting) {
      track('SyncAccountingSoftware', 'Status', {
        PageName: 'accounting-software-sync',
        Status: 'success',
        AccountingSoftwareType: accountingPlatformNameForAnalytics,
      });
      goToAccountingPlatform({});
    } else {
      track('Dashboard', 'Status', { PageName: 'accounting-software-sync', Status: 'success' });
      goToPayDashboard({ keepToast: true });
    }
  };

  const onError = (
    error: AccountingPlatformSyncConnectionErrorEnum,
    accountingPlatformNameForAnalytics: string,
    accountingPlatformId?: string,
  ) => {
    track('SyncAccountingSoftware', 'Status', {
      PageName: 'accounting-software-sync',
      Status: 'failure',
      AccountingSoftwareType: accountingPlatformNameForAnalytics,
    });

    if (!accountingPlatformId) {
      showMessage({
        type: 'error',
        dataTestId: 'accounting-platform-sync-error',
        title: formatMessage('activities.accountingPlatformConnectCallback.authError.toast'),
      });
    }

    goToAccountingPlatform({ syncError: error, accountingPlatformId }, { keepToast: true });
  };

  const onClose = (flow: SyncFlowEnum) => {
    if (flow === SyncFlowEnum.Setting) {
      goToAccountingPlatform({});
    } else {
      goToPayDashboard();
    }
  };

  return (
    <AccountingPlatformConnectActivity
      partnerName={partnerName}
      onDone={onDone}
      onQuickBooksOnlineDone={onQuickBooksOnlineDone}
      onError={onError}
      onClose={onClose}
      partnerDisplayName={partnerDisplayName}
    />
  );
};

AccountingPlatformAuthCallbackScreen.displayName = 'AccountingPlatformAuthCallbackScreen';
