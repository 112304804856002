const modalTestId = 'subscription-upgrade-modal-activity';
const submitButtonTestId = 'modal-btn-primary';

export class SubscriptionUpgradeModalActivityCypressDriver {
  getModal() {
    return cy.getByTestId(modalTestId);
  }

  submit() {
    return cy.getByTestId(modalTestId).within(() => {
      cy.getByTestId(submitButtonTestId).click();
    });
  }
}
