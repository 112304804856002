import { Box } from '@chakra-ui/react';
import { useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, IconButton, SearchBar } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

export type CatalogItemsTableToolbarProps = {
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
  isLoading?: boolean;
  onAddItem: VoidFunction;
  onImportCatalogItems: VoidFunction;
};

export const CatalogItemsTableToolbar = forwardRef<CatalogItemsTableToolbarProps>(
  ({ setSearchTerm, searchTerm, onImportCatalogItems, onAddItem, isLoading, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { formatMessage } = useMelioIntl();
    const {
      settings: { arDashboardSearchLabelDisabled },
    } = useConfig();
    const hideSearchLabel = isMobile || arDashboardSearchLabelDisabled;

    return (
      // we need a container with padding for the inputs to not break on focus state that grows the inputs
      <Container paddingX="xxs" paddingY="xxs" data-component={CatalogItemsTableToolbar.displayName}>
        <Group variant="horizontal" justifyContent="space-between" {...props} ref={ref}>
          <Box flex={1}>
            <SearchBar
              label={
                hideSearchLabel
                  ? undefined
                  : formatMessage(
                      'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.searchBar.label'
                    )
              }
              placeholder={
                hideSearchLabel
                  ? formatMessage(
                      'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.searchBar.label'
                    )
                  : undefined
              }
              onSearch={(value) => setSearchTerm(String(value ?? ''))}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              data-testid="search-bar"
            />
          </Box>
          <Group>
            {!isMobile ? (
              <IconButton
                aria-label={formatMessage(
                  'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.import.button.ariaLabel'
                )}
                data-testid="import-catalog-item-icon-button"
                icon="upload"
                onClick={onImportCatalogItems}
              />
            ) : null}
            <Button
              variant="tertiary"
              label={formatMessage(
                'ar.settings.activities.catalogItemsTable.components.catalogItemsTableToolbar.addButton.label'
              )}
              onClick={() => onAddItem()}
              data-testid="add-catalog-item-button"
            />
          </Group>
        </Group>
      </Container>
    );
  }
);
CatalogItemsTableToolbar.displayName = 'CatalogItemsTableToolbar';
