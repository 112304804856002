import { useMelioForm } from '@melio/penny';
import { UseMelioFormProps } from '@melio/penny/dist/components/form/hooks/types';
import { createContext, useContext } from 'react';

import { ManageClientFormFields, ManageClientFormResults } from './manage-client-form.types';

type ManageClientFormContextValue = {
  form: ManageClientFormResults;
  clientOrgId?: string;
  shouldListClientFundingSources?: boolean;
};
const ManageClientFormContext = createContext<ManageClientFormContextValue | null>(null);

export const useManageClientFormContext = () => {
  const value = useContext(ManageClientFormContext);
  if (!value) {
    throw new Error('FormContext is not provided');
  }
  return value;
};

export const ManageClientFormContextProvider = ManageClientFormContext.Provider;

export const useManageClientForm = (props: UseMelioFormProps<ManageClientFormFields>) =>
  useMelioForm<ManageClientFormFields>(props);
