/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImportEntitiesFromFileModal } from '@melio/ap-widgets';
import { Container, Group, Icon, IconButton, NakedButton, Table, Text, useTable } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-provider';
import { useNavigate } from '@melio/platform-utils';
import { useMemo, useState } from 'react';

import { AddressCell } from './components/AddressCell';
import { FooterContent } from './components/FooterContent';
import { RowTextWithState } from './components/RowTextWithState';
import { WarningBanner } from './components/WarningBanner';
import { ColumnId, VendorData } from './importVendorsTypes';
import { getStatusIcon } from './importVendorsUtils';

export type VendorDataWithId = VendorData & { id: string };

type ReviewVendorsTableProps = {
  vendorsData: VendorDataWithId[];
};

export const ReviewVendorsTable = ({ vendorsData }: ReviewVendorsTableProps) => {
  const [isImportVendorsModalOpen, setIsImportVendorsModalOpen] = useState<boolean>(false);
  const [vendors, setVendors] = useState<VendorDataWithId[]>(vendorsData);

  const navigate = useNavigate();
  const { formatMessage } = useMelioIntl();

  const onImportVendorsModalClosed = () => {
    setIsImportVendorsModalOpen(false);
  };

  const onVendorsFileSelected = (fileId: string) => {
    setIsImportVendorsModalOpen(false);
    navigate(`/vendors/import/${fileId}`);
  };

  const handleDeleteVendor = (vendorId: string) => {
    setVendors((prevVendors) => prevVendors.filter((vendor) => vendor.id !== vendorId));
  };

  const handleEditVendor = () => {};

  const onCancelFlowClick = () => {
    navigate(-1);
  };

  const onReuploadFileClick = () => {
    setIsImportVendorsModalOpen(true);
  };

  const onImportVendorsClick = () => {};

  const columns = useMemo(
    () => [
      {
        id: ColumnId.Status,
        size: 40,
        cell: ({ row }: { row: VendorDataWithId }) => {
          const { type, color } = getStatusIcon(row);
          return (
            <Table.Cell data-testid={`review-vendors-table-status-cell-${type}`}>
              <Icon color={color} type={type} size="small" />
            </Table.Cell>
          );
        },
      },
      {
        id: ColumnId.BusinessName,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.businessName'),
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-business-name-cell">
            <RowTextWithState textStyle="body3Semi">{row?.companyName || 'No business name'}</RowTextWithState>
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.ContactName,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.contactName'),
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-contact-name-cell">
            <RowTextWithState textStyle="body3">{row?.contactName}</RowTextWithState>
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.Email,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.email'),
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-contact-email-cell">
            <RowTextWithState textStyle="body3">{row?.contactEmail}</RowTextWithState>
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.Phone,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.phone'),
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-contact-phone-cell">
            <RowTextWithState textStyle="body3">{row?.contactPhone}</RowTextWithState>
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.Address,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.address'),
        cell: ({ row }: { row: VendorDataWithId }) => <AddressCell vendor={row} />,
      },
      {
        id: ColumnId.Contractor,
        header: formatMessage('activities.importEntities.reviewVendorsTable.column.contractor'),
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-contractor-cell">
            <RowTextWithState textStyle="body3">{row?.contractor ? 'Yes' : 'No'}</RowTextWithState>
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.EditRowAction,
        size: 80,
        cell: () => (
          <Table.Cell data-testid="review-vendors-table-edit-action-cell">
            <NakedButton
              variant="secondary"
              size="medium"
              data-testid="review-vendors-table-edit-action-cell-button"
              aria-label={formatMessage('activities.importEntities.reviewVendorsTable.column.actions.edit')}
              onClick={handleEditVendor}
              label={formatMessage('activities.importEntities.reviewVendorsTable.column.actions.edit')}
            />
          </Table.Cell>
        ),
      },
      {
        id: ColumnId.DeleteRowAction,
        size: 50,
        cell: ({ row }: { row: VendorDataWithId }) => (
          <Table.Cell data-testid="review-vendors-table-delete-action-cell">
            <IconButton
              aria-label={formatMessage('activities.importEntities.reviewVendorsTable.column.actions.delete')}
              icon="delete"
              size="small"
              variant="naked"
              data-testid={formatMessage('activities.importEntities.reviewVendorsTable.column.actions.delete')}
              onClick={() => handleDeleteVendor(row.id)}
            />
          </Table.Cell>
        ),
      },
    ],
    [formatMessage]
  );

  const tableProps = useTable({
    data: vendors,
    columns,
    getRowId: (row) => row?.id,
    headerVariant: 'dark',
  });

  const vendorsCount = vendors?.length || 0;
  const invalidVendorsCount = 2; // mock
  const validVendorsCount = vendorsCount - invalidVendorsCount;

  return (
    <Container height="full" data-testid="review-vendors-table-container">
      <Layout
        maxWidth="full"
        footer={{
          isSticky: true,
          content: (
            <FooterContent
              onCancelFlowClick={onCancelFlowClick}
              onReuploadFileClick={onReuploadFileClick}
              onImportVendorsClick={onImportVendorsClick}
              vendorsCount={vendors?.length || 0}
            />
          ),
        }}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="xs">
            <Text data-testid="review-vendors-table-title" as="h1" textStyle="heading1Semi">
              {formatMessage('activities.importEntities.reviewVendorsTable.title')}
            </Text>
            <Text
              data-testid="review-vendors-table-subtitle"
              as="header"
              color="semantic.text.secondary"
              textStyle="body2"
            >
              {formatMessage('activities.importEntities.reviewVendorsTable.description')}
            </Text>
          </Group>
          <WarningBanner
            vendorsCount={vendorsCount}
            invalidVendorsCount={invalidVendorsCount}
            validVendorsCount={validVendorsCount}
          />
          <Container data-testid="review-vendors-table" paddingBottom="xxl">
            <Table {...tableProps} />
          </Container>
        </Group>
      </Layout>
      <ImportEntitiesFromFileModal
        isOpen={isImportVendorsModalOpen}
        onFileSelected={onVendorsFileSelected}
        onClose={onImportVendorsModalClosed}
        title={formatMessage('activities.payDashboard.vendorsTab.importVendors.modal.title')}
        subtitle={formatMessage('activities.payDashboard.vendorsTab.importVendors.modal.subtitle')}
      />
    </Container>
  );
};
