import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import { ApiKeys, VendorGroupsApiClient } from '@melio/platform-api-axios-client';

export type UseVendorGroupsProps = UseCollectionApiProps<typeof VendorGroupsApiClient.getVendorGroups>;

export const useVendorGroups = (params: UseVendorGroupsProps = {}) =>
  useCollectionApi({
    queryKey: [ApiKeys.VendorGroupsApi],
    queryFn: () => VendorGroupsApiClient.getVendorGroups(),
    ...params,
  });
