import { ActionsDropdownMenuItemProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryMethod, DeliveryMethodType, DeliveryMethodTypeOption } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';

import { PaymentFlowOnChangeHandlers } from '../../../../../../../types';

export const useDeliveryMethodCardMenuItems = ({
  selectedDeliveryMethod,
  deliveryMethods,
  onDeliveryMethodChange,
  onEditDeliveryMethod,
  deliveryMethodTypeOptions,
}: {
  selectedDeliveryMethod: DeliveryMethod | undefined;
  deliveryMethods: DeliveryMethod[];
  onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'];
  onEditDeliveryMethod: (deliveryMethodType: DeliveryMethodType) => void;
  deliveryMethodTypeOptions: Array<DeliveryMethodTypeOption> | undefined;
}): ActionsDropdownMenuItemProps[] => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();

  const onSwitchActionClick = ({
    deliveryMethodType,
    targetDeliveryMethod,
  }: {
    deliveryMethodType: DeliveryMethodType;
    targetDeliveryMethod: DeliveryMethod | undefined;
  }) => {
    track('DeliveryMethod', 'Click', {
      Intent: 'switch-delivery-method',
      Cta: `switch-to-${deliveryMethodType}`,
      DeliveryMethodId: targetDeliveryMethod?.id,
      DeliveryMethodType: targetDeliveryMethod?.type,
    });
    if (!targetDeliveryMethod || targetDeliveryMethod.requireConfirmationForPayment) {
      onEditDeliveryMethod(deliveryMethodType);
    } else {
      onDeliveryMethodChange(targetDeliveryMethod);
    }
  };

  const createSwitchAction = (deliveryMethodType: 'paper-check' | 'bank-account') => {
    const targetDeliveryMethod = deliveryMethods.find((method) => method.type === deliveryMethodType);
    if (
      deliveryMethodTypeOptions &&
      !deliveryMethodTypeOptions.some((option) => option.type === deliveryMethodType && option.supported)
    ) {
      return undefined;
    }

    return {
      dataTestId: `switch-delivery-method-${deliveryMethodType}`,
      label: formatMessage(
        `activities.paymentFlow.form.content.deliveryMethodCard.action.switch.${deliveryMethodType}.label`
      ),
      onClick: () => onSwitchActionClick({ deliveryMethodType, targetDeliveryMethod }),
    };
  };

  const switchActions = compact([
    selectedDeliveryMethod && selectedDeliveryMethod.type !== DeliveryMethodType.PaperCheck
      ? createSwitchAction(DeliveryMethodType.PaperCheck)
      : undefined,
    selectedDeliveryMethod && selectedDeliveryMethod.type !== DeliveryMethodType.BankAccount
      ? createSwitchAction(DeliveryMethodType.BankAccount)
      : undefined,
  ]);

  const editAction = {
    dataTestId: 'edit-delivery-method',
    label: formatMessage(`activities.paymentFlow.form.content.deliveryMethodCard.action.edit.label`),
    onClick: () => {
      if (selectedDeliveryMethod) {
        track('DeliveryMethod', 'Click', {
          Intent: 'edit-delivery-method',
          Cta: 'edit',
          DeliveryMethodId: selectedDeliveryMethod.id,
          DeliveryMethodType: selectedDeliveryMethod.type,
        });
        return onEditDeliveryMethod(selectedDeliveryMethod.type);
      }
    },
  };

  return [editAction, ...switchActions];
};
