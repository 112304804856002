import { DSLSearchQuery } from '@melio/api-client/src/core/types';
import {
  PaymentExpandableFields,
  PaymentProcessStatusEnum,
  PaymentsApiClient,
  PaymentsApiGetPaymentsRequest,
  PaymentStatusEnum,
} from '@melio/platform-api-axios-client';

export type { Payment } from '@melio/javascript-sdk';
export { PaymentCreateMethodEnum, PaymentErrorCode } from '@melio/platform-api-axios-client';

export type ListFn = typeof PaymentsApiClient.getPayments;
export type CreateFn = typeof PaymentsApiClient.postPayment;
export type DeleteFn = typeof PaymentsApiClient.deletePaymentsPaymentId;
export type GetFn = typeof PaymentsApiClient.getPaymentsPaymentId;
export type UpdateFn = typeof PaymentsApiClient.updatePaymentsPaymentId;

export enum PaymentsExtendedStatusesEnum {
  Scheduled = 'scheduled',
  InProgress = 'in-progress',
  Paid = 'completed',
  Failed = 'failed',
  Canceled = 'canceled',
  MarkedAsPaid = 'markedAsPaid',
  Pending = 'pending',
  Declined = 'declined',
  Refunded = 'refunded',
}

export enum DebitDateFilterValues {
  Today = 'today',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Next7Days = 'next7Days',
  Next30Days = 'next30Days',
  Last90Days = 'last90Days',
  Next90Days = 'next90Days',
  Custom = 'custom',
}

export type PaymentsExtendedStatuses = `${PaymentsExtendedStatusesEnum}`;

export type Search = {
  'vendor.id'?: string | string[];
  'payment.status'?: PaymentStatusEnum[];
  'payment.fundingSourceId'?: (string | null) | Array<string | null> | Record<string, string | null>;
  'payment.extendedStatus'?: PaymentsExtendedStatuses[];
  'billSubscriptionOccurrence.id'?: null | Record<string, null>;
  'payment.createMethod'?: string;
  'payment.collectStatus'?:
    | PaymentProcessStatusEnum
    | PaymentProcessStatusEnum[]
    | Record<string, PaymentProcessStatusEnum>;
  'payment.isFinanced'?: boolean;
  'payment.id'?: string | string[];
  'deliveryMethod.deliveryType'?: string | string[];
  'managedAccount.deliveryType'?: string | string[];
};

export type Expand = PaymentExpandableFields | PaymentExpandableFields[] | 'none';

export type PaymentsListParams = Override<
  PaymentsApiGetPaymentsRequest,
  { searchTerm?: string; search?: DSLSearchQuery<Search>; expand?: Expand }
>;
