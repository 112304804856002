import { useMelioIntl } from '@melio/ar-domain';
import { Button, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { type PaymentLayoutProps, PaymentLayout } from '../../../layout';
import { CustomPaymentInstructions } from '../components';

export type NoPaymentOptionsScreenProps = PaymentLayoutProps;

export const NoPaymentOptionsScreen = forwardRef<NoPaymentOptionsScreenProps>(
  ({ onViewInvoice, isLoading, guestPaymentIntentData, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const customPayInstructions =
      guestPaymentIntentData?.vendorPaymentDetails?.paymentOptions.customPayInstructions?.trim();

    return (
      <PaymentLayout
        data-component={NoPaymentOptionsScreen.displayName}
        data-testid="no-payment-options-screen"
        guestPaymentIntentData={guestPaymentIntentData}
        isLoading={isLoading}
        onViewInvoice={onViewInvoice}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          {customPayInstructions && <CustomPaymentInstructions paymentInstructions={customPayInstructions} />}
          <Button
            data-testid="view-invoice-button"
            variant="tertiary"
            onClick={onViewInvoice}
            label={formatMessage('ar.guestPayment.previewInvoice.button.label')}
          />
        </Group>
      </PaymentLayout>
    );
  }
);
NoPaymentOptionsScreen.displayName = 'NoPaymentOptionsScreen';
