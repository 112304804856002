import {
  AmountCell,
  ApiPagination,
  ReceivablePayment,
  SortableHeaderCell,
  useMelioIntl,
  usePaymentStatusLabel,
} from '@melio/ar-domain';
import { Group, Pagination, Pill, Table, TableColumnDef, useTable, VisuallyHidden } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { PaymentActionsHandlers } from '../../../utils';
import { OnSort, SortParams } from '../types';
import { PayerCell, PaymentsTableRowsMobile } from './table-cells';

export type PaymentsTableProps = {
  payments: ReceivablePayment[];
  pagination: ApiPagination;
  tableActions: PaymentActionsHandlers;
  isLoading?: boolean;
  onRowClick?: (params: { rowId: string; rowData: ReceivablePayment }) => void;
  onSort: OnSort;
  sortParams?: SortParams;
  shouldDisplayTable?: boolean;
};

export const PaymentsTable = forwardRef<PaymentsTableProps>(
  (
    {
      payments,
      pagination,
      isLoading,
      onRowClick,
      tableActions,
      onSort,
      sortParams,
      shouldDisplayTable = true,
      ...props
    },
    ref
  ) => {
    const { formatMessage, formatDate } = useMelioIntl();

    const { getPaymentStatusLabel, getPaymentStatusVariant } = usePaymentStatusLabel();

    const sortableHeaderProps = useMemo(
      () => ({ order: sortParams?.sortOrder, selectedField: sortParams?.sortBy, onClick: onSort }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify([sortParams?.sortOrder, sortParams?.sortBy]), onSort]
    );

    const columns: TableColumnDef<ReceivablePayment, PaymentActionsHandlers>[] = useMemo(
      () => [
        {
          id: 'paymentDate',
          size: 's',
          header: (
            <SortableHeaderCell
              label={formatMessage('ar.dashboard.activities.paymentsTable.columns.paymentDate.label')}
              field="creationDate"
              {...sortableHeaderProps}
            />
          ),
          cell: ({ row }) => (
            <Table.Cell data-testid="payment-table-row-payment-date">{formatDate(row.creationDate)}</Table.Cell>
          ),
          isPinnedToLeft: true,
        },
        {
          id: 'payer',
          header: formatMessage('ar.dashboard.activities.paymentsTable.columns.payer.label'),
          cell: ({ row }) => <PayerCell row={row} />,
          size: 'l',
          isPinnedToLeft: true,
        },
        {
          id: 'invoiceNumber',
          header: formatMessage('ar.dashboard.activities.paymentsTable.columns.invoiceNumber.label'),
          cell: ({ row }) => (
            <Table.Cell data-testid="payment-table-row-invoice-number">
              {row.invoices?.[0]?.invoiceNumber ??
                formatMessage('ar.dashboard.activities.paymentsTable.columns.invoiceNumber.unapplied.text')}
            </Table.Cell>
          ),
          size: 's',
        },
        {
          id: 'status',
          header: formatMessage('ar.dashboard.activities.paymentsTable.columns.status.label'),
          cell: ({ row }) => (
            <Table.Cell>
              <Pill
                type="secondary"
                status={getPaymentStatusVariant(row.status)}
                label={getPaymentStatusLabel(row.status)}
                data-testid="payment-status-label"
                data-cy-status={row.status}
              />
            </Table.Cell>
          ),
          size: 's',
        },
        {
          id: 'amount',
          header: (
            <SortableHeaderCell
              label={formatMessage('ar.dashboard.activities.paymentsTable.columns.amount.label')}
              field="amount"
              {...sortableHeaderProps}
              textAlign="end"
            />
          ),
          cell: ({ row }) => <AmountCell value={row.amount} data-testid="payment-table-row-amount" />,
          size: 's',
          textAlign: 'end',
        },
        {
          id: 'actions',
          header: (
            <VisuallyHidden>
              {formatMessage('ar.dashboard.activities.paymentsTable.columns.actions.label')}
            </VisuallyHidden>
          ),
          cell: ({ row, meta }) =>
            meta ? (
              <Table.ActionsCell
                tooltipLabel={formatMessage('ar.dashboard.activities.paymentsTable.columns.actions.tooltipLabel')}
                options={[
                  {
                    label: formatMessage('ar.dashboard.activities.paymentsTable.columns.actions.view.label'),
                    onClick: () => tableActions.onViewPayment(row.id),
                    dataTestId: `payment-actions-cell-${row.id}`,
                  },
                ]}
                data-testid={`payment-actions-cell-${row.id}`}
              />
            ) : null,
          size: 'xs',
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formatMessage, formatDate, getPaymentStatusLabel, getPaymentStatusVariant, sortableHeaderProps, tableActions]
    );

    const mobileRowRenderer = useCallback(
      (payment: ReceivablePayment) => <PaymentsTableRowsMobile payment={payment} />,
      []
    );

    const tableProps = useTable<ReceivablePayment, PaymentActionsHandlers>({
      isLoading,
      data: payments,
      columns,
      headerVariant: 'dark',
      onRowClick,
      meta: tableActions,
      mobileRowRenderer,
    });

    return (
      <>
        {shouldDisplayTable && (
          <Group variant="vertical" alignItems="flex-end" spacing="l">
            <Table
              data-testid="payments-table"
              data-component={PaymentsTable.displayName}
              isLoading={isLoading}
              {...props}
              {...tableProps}
              ref={ref}
            />
            {!isLoading && pagination.totalCount > pagination.limit && (
              <Pagination
                currentPage={pagination.pageNumber}
                pageSize={pagination.limit}
                totalItems={pagination.totalCount}
                onPageChange={pagination.goToPage}
              />
            )}
          </Group>
        )}
      </>
    );
  }
);
PaymentsTable.displayName = 'PaymentsTable';
