// XXX move to shared
import { ExternalLayout } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { usePayment } from '@melio/platform-api';

import { ActionBlockedIneligibleScreen, ActionBlockedPaymentCanceledScreen } from './screens';
import { PushToDebitActivityProps } from './types';

// TODO: https://linear.app/meliopayments/issue/PLA-1224 add invoice number by sending payment intent id or by using "expand=billInfo" when backend ready
const BaseTBTPushToDebitActivity: React.VFC<PushToDebitActivityProps> = ({ paymentId, accountName }) => {
  const { data: payment, error: paymentError } = usePayment({ id: paymentId });

  if (paymentError?.code === '404') {
    return <ActionBlockedPaymentCanceledScreen accountName={accountName} />;
  }

  if (!payment) {
    return <ExternalLayout isLoading />;
  }

  return <ActionBlockedIneligibleScreen accountName={accountName} />;
};

export const TBTPushToDebitActivity = withAnalyticsContext(BaseTBTPushToDebitActivity);
