import { useRef } from 'react';

import { usePayDashboardPagination } from '../components/Pagination';

type LoadingState = {
  isEmptyState: boolean;
  isEmptySearchResult: boolean;
  isInitialLoading: boolean;
  isTableLoading: boolean;
  shouldShowTabHeader: boolean;
  shouldShowPaginationControls: boolean;
  isEmptyStateFilter: boolean;
};

export const useLoadingState = ({
  isLoading,
  searchTerm,
  items,
  isFiltered = false,
  paginationConfig,
}: {
  isLoading: boolean;
  searchTerm: string | null;
  items: unknown[];
  isFiltered?: boolean;
  paginationConfig?: {
    isPreviousData: boolean;
    isFetching: boolean;
    totalCount?: number;
  };
}) => {
  const { pageSize } = usePayDashboardPagination();
  const isInitialLoading = useRef(true);

  if (isInitialLoading.current && !isLoading) {
    isInitialLoading.current = false;
  }

  const isEmptyState =
    !searchTerm && items.length === 0 && !isLoading && !paginationConfig?.totalCount && !paginationConfig?.isFetching;
  const isEmptySearchResult = !!(searchTerm && items.length === 0 && !isLoading);
  const isEmptyStateWithoutFilter = isEmptyState && !isFiltered;
  const isEmptyStateWithFilter = isEmptyState && isFiltered;
  const shouldShowTabHeader = !isEmptyStateWithoutFilter && !isInitialLoading.current;

  const loadingState: LoadingState = {
    isEmptyState,
    isEmptySearchResult,
    isInitialLoading: isInitialLoading.current,
    shouldShowTabHeader,
    isTableLoading: isLoading,
    shouldShowPaginationControls: false,
    isEmptyStateFilter: isEmptyStateWithFilter,
  };

  if (paginationConfig) {
    const isTableLoading = isLoading || (paginationConfig.isFetching && paginationConfig.isPreviousData);
    const shouldShowPaginationControls = !!(
      !isEmptySearchResult &&
      !isTableLoading &&
      paginationConfig.totalCount &&
      paginationConfig.totalCount > pageSize
    );

    return {
      ...loadingState,
      isTableLoading,
      shouldShowPaginationControls,
    };
  }
  return loadingState;
};
