import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useShowVendorUniqueNameField, useVendorForm, useVendorUniqueNameEnabled } from '@melio/ap-widgets';
import {
  Container,
  Form,
  GridItem,
  Group,
  LoadingContainer,
  NakedButton,
  SimpleGrid,
  Text,
  useBreakpoint,
  VisuallyHidden,
} from '@melio/penny';
import { ModelError, useVendorEditableFields } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, useSystemMessage } from '@melio/platform-utils';

import { DataComponentEnum } from '@/types/vendors.types';
import { ConfirmVendorUpdateModal, useConfirmVendorUpdate } from '../../ConfirmVendorUpdateModal.widget';
import { preventFormSubmissionOnEnter } from '../utils';
import { VendorDetailsFormInnerProps, VendorDetailsMangedByFiservFormFields } from './types';
import { formErrors, useFormSchema } from './validation';

export const NewVendorDetailsMangedByFiservForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  (
    { vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, inlineApiErrorCodes, ...props },
    ref,
  ) => {
    const [isEditingBankAccount, setIsEditingBankAccount] = useState(false);
    const confirmVendorUpdate = useConfirmVendorUpdate({
      vendorId,
      initialFormData: defaultValues,
    });
    const { showMessage } = useSystemMessage();
    const config = useConfig();
    const shouldShowTooltips = config.settings.vendor.forms.shouldUseTooltipsForManagedVendorForm;
    const { formatMessage } = useMelioIntl();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isVendorUniqueNameEnabled = useVendorUniqueNameEnabled();
    const { isExtraSmallScreen } = useBreakpoint();
    const showUniqueNameField = useShowVendorUniqueNameField({ defaultValues, isVendorUniqueNameEnabled });

    const { data: editableFields, isLoading: isLoadingEditableFields } = useVendorEditableFields({
      disabled: !isEditable,
      vendorId,
    });

    const submitForm = async () => {
      const data = getValues();
      try {
        setIsSubmitting(true);
        await onSubmit(
          {
            accountNumber: data.accountNumber,
            nickname: data.nickname || '',
            uniqueName: !isEmpty(data.uniqueName) ? data.uniqueName : undefined,
          },
          true,
        );
        confirmVendorUpdate.onClose();
      } catch (err) {
        const e = err as ModelError;
        const errorType = formErrors[e.code];
        const knownError = !!errorType;
        if (!knownError || errorType === 'toast') {
          showMessage({
            type: 'error',
            title: formatMessage(`widgets.vendorDetails.form.errors.${knownError ? e.code : 'default'}` as MessageKey),
            id: 'edit-vendor-error',
          });
        } else {
          setError(errorType, {
            message: formatMessage(`widgets.vendorDetails.form.errors.${e.code}` as MessageKey),
          });
          confirmVendorUpdate.onClose();
        }
      } finally {
        setIsSubmitting(false);
      }
    };

    const { formProps, registerField, reset, getValues, setError } =
      useVendorForm<VendorDetailsMangedByFiservFormFields>({
        onSubmit: (formData) => {
          if (confirmVendorUpdate.shouldShowConfirm(formData)) {
            confirmVendorUpdate.onOpen();
          } else {
            submitForm();
          }
        },
        schema: useFormSchema({
          showUniqueNameField,
          isAccountNumberRequired: !defaultValues.accountNumber || isEditingBankAccount,
        }),
        isSaving,
        defaultValues,
        inlineApiErrorCodes,
        onSubmissionStateChange,
      });

    useEffect(() => {
      reset({
        ...defaultValues,
        viewAccountNumber: defaultValues?.accountNumber,
        accountNumber: undefined,
      });
      setIsEditingBankAccount(false);
    }, [defaultValues, isEditable, reset]);

    const showEnterAccountNumberFields =
      editableFields?.includes('accountNumber') && isEditable && (!defaultValues.accountNumber || isEditingBankAccount);

    return (
      <>
        <LoadingContainer isLoading={isLoadingEditableFields}>
          <Group variant="vertical" spacing="m">
            <Group variant="vertical" spacing="xs">
              {isEditable && (
                <Text color="global.neutral.900" textStyle="body4">
                  {formatMessage('widgets.vendorDetails.form.business.requiredFields')}
                </Text>
              )}
              <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-business-title">
                {formatMessage('widgets.vendorDetails.form.business.title')}
              </Text>
            </Group>
            <Form
              data-component={DataComponentEnum.VENDOR_DETAILS_NEW_MANAGED_BY_FISERV_FORM}
              {...props}
              {...formProps}
              ref={ref}
              columns={2}
              isReadOnly={!isEditable}
              isViewMode={!isEditable}
              {...preventFormSubmissionOnEnter()}
            >
              <Form.TextField
                labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
                isReadOnly
                isTruncated={!isEditable}
                colSpan={2}
                {...registerField('companyName')}
              />
              <Form.TextField
                labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
                viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
                autoFocus={isEditable}
                isTruncated={!isEditable}
                colSpan={2}
                {...registerField('nickname')}
              />
              {showEnterAccountNumberFields ? (
                <>
                  <Form.TextField
                    labelProps={{
                      label: formatMessage('widgets.vendorDetails.form.accountNumber.label'),
                      tooltipProps:
                        isEditable && shouldShowTooltips
                          ? {
                              content: (
                                <>
                                  <VisuallyHidden>
                                    {formatMessage('widgets.vendors.accountNumber.labelTooltip')} tooltip
                                  </VisuallyHidden>
                                  {formatMessage('widgets.vendors.accountNumber.labelTooltip')}
                                </>
                              ),
                              triggerAriaLabel: formatMessage('widgets.vendors.accountNumber.ariaLabelTooltip'),
                            }
                          : undefined,
                    }}
                    helperTextProps={{
                      label: formatMessage('widgets.vendors.accountNumber.helperText'),
                    }}
                    isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                    isTruncated={!isEditable}
                    colSpan={isEditable ? 1 : 2}
                    data-private
                    {...registerField('accountNumber')}
                  />
                  <Form.TextField
                    data-private
                    labelProps={{ label: formatMessage('widgets.vendorDetails.form.confirmAccountNumber.label') }}
                    isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                    isTruncated={!isEditable}
                    colSpan={1}
                    {...registerField('confirmAccountNumber')}
                  />
                </>
              ) : (
                <Form.TextField
                  data-private
                  colSpan={2}
                  {...registerField('viewAccountNumber')}
                  labelProps={{
                    label: formatMessage('widgets.vendorDetails.form.accountNumber.label'),
                  }}
                  isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                  isReadOnly
                  rightElement={
                    isEditable &&
                    editableFields?.includes('accountNumber') && (
                      <Container paddingRight="s">
                        <NakedButton
                          data-testid="account-number-edit"
                          label={formatMessage('widgets.vendorDetails.form.accountNumber.change')}
                          onClick={() => {
                            setIsEditingBankAccount(true);
                          }}
                          variant="secondary"
                        ></NakedButton>
                      </Container>
                    )
                  }
                />
              )}
              {showUniqueNameField ? (
                /* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */
                <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
                  <SimpleGrid columns={isExtraSmallScreen ? 1 : 2} spacing="m">
                    <GridItem colSpan={isExtraSmallScreen ? 1 : 2} paddingTop="m">
                      <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-accounting-software-title">
                        {formatMessage('widgets.vendorDetails.form.accountingSoftware.title')}
                      </Text>
                    </GridItem>
                    <Form.TextField
                      colSpan={2}
                      {...registerField('uniqueName')}
                      labelProps={{ label: formatMessage('widgets.vendorDetails.form.uniqueName.label') }}
                      viewModePlaceholder={formatMessage('widgets.vendorDetails.form.uniqueName.viewModePlaceholder')}
                      isTruncated={!isEditable}
                    />
                  </SimpleGrid>
                </Form.ContentBox>
              ) : null}
            </Form>
          </Group>
        </LoadingContainer>
        <ConfirmVendorUpdateModal
          isSubmitting={isSubmitting}
          submitForm={submitForm}
          onClose={confirmVendorUpdate.onClose}
          isOpen={confirmVendorUpdate.isOpen}
        />
      </>
    );
  },
);
