import { Group, Icon, Pill, Skeleton, Text, Tooltip } from '@melio/penny';

const RATIO_FRACTION = 1;
const formatPercents = (ratio: number) =>
  Number(ratio).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: RATIO_FRACTION });

const shouldDisplayRatioChange = (ratio: number | undefined): ratio is number =>
  typeof ratio === 'number' && Math.abs(ratio) >= RATIO_FRACTION / 1000;

export const OverviewItem = ({
  value,
  isEmpty,
  label,
  isLoading,
  changeRatio,
  tooltipContent,
  dataTestId,
}: {
  isEmpty: boolean;
  value: string | undefined;
  label: string;
  isLoading: boolean;
  changeRatio?: number;
  tooltipContent?: string;
  dataTestId: string;
}) => {
  const isNegativeChange = changeRatio && changeRatio < 0;

  return (
    <Group spacing="xxxs" variant="vertical" data-testid={dataTestId}>
      <Group spacing="xs" variant="horizontal" alignItems="center">
        {isLoading ? (
          <Skeleton
            height="1.5rem"
            // @ts-expect-error penny should support width
            sx={{
              width: '8.75rem',
              marginBottom: '0.25rem',
            }}
          />
        ) : (
          <Text
            textStyle="heading2Semi"
            color={isEmpty ? 'semantic.text.secondary' : 'semantic.text.primary'}
            data-testid={`${dataTestId}-value`}
          >
            {value}
          </Text>
        )}
        {!isLoading && shouldDisplayRatioChange(changeRatio) && (
          <Pill
            leftElement={
              <Icon
                size="small"
                type={isNegativeChange ? 'caret-down' : 'caret-up'}
                aria-label="todo"
                color={isNegativeChange ? 'critical' : 'success'}
              />
            }
            status={isNegativeChange ? 'critical' : 'success'}
            type="secondary"
            label={formatPercents(changeRatio)}
          />
        )}
      </Group>
      <Group spacing="xxs" alignItems="center" variant="horizontal">
        {isLoading ? (
          <Skeleton
            height="0.75rem"
            // @ts-expect-error penny should support width
            sx={{
              width: '6.25rem',
            }}
          />
        ) : (
          <Text textStyle="body4" data-testid={`${dataTestId}-label`}>
            {label}
          </Text>
        )}
        {tooltipContent && !isLoading && (
          <Tooltip content={tooltipContent}>
            <Icon size="small" type="info" aria-label={tooltipContent} />
          </Tooltip>
        )}
      </Group>
    </Group>
  );
};
