import { Group, Table, Text } from '@melio/penny';

import { VendorData } from '../importVendorsTypes';
import { getFullAddress } from '../importVendorsUtils';
import { RowTextWithState } from './RowTextWithState';

type Props = {
  vendor: VendorData;
};

export const AddressCell = ({ vendor }: Props) => {
  const { addressLine1, addressLine2 } = getFullAddress(vendor);
  return (
    <Table.Cell data-testid="review-vendors-table-address-cell">
      <Group variant="vertical" spacing="none">
        <RowTextWithState textStyle="body3">{addressLine1}</RowTextWithState>
        <Text color="semantic.text.secondary" textStyle="body4">
          {addressLine2}
        </Text>
      </Group>
    </Table.Cell>
  );
};
