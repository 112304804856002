import { screen } from '@testing-library/react';

import { Tier } from '../../../../api';

const testIds = {
  paywall: 'onboarding-paywall-activity',
  modalCloseButton: 'modal-close-button',
};

export class OnboardingPaywallModalDriver {
  getPaywall = () => screen.queryByTestId(testIds.paywall);

  choosePlan(planTier: Tier) {
    screen.getByTestId(`subscription-${planTier}-plan-card-button`).click();
  }
}

export class OnboardingPaywallModalCypressDriver {
  getPaywall = () => cy.getByTestId(testIds.paywall);

  choosePlan(planTier: Tier) {
    cy.getByTestId(`subscription-${planTier}-plan-card-button`).click();
  }
}
