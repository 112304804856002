import { useDisclosure } from '@chakra-ui/react';
// eslint-disable-next-line no-restricted-imports
import { Combobox as _Combobox, ComboboxOption, ComboboxProps as _ComboboxProps } from '@melio/penny';
import { forwardRef, useBoolean, useUpdateEffect } from '@melio/platform-utils';
import { useMemo, useRef, useState } from 'react';

type ComboboxProps<V, O extends ComboboxOption<V> = ComboboxOption<V>> = Omit<
  _ComboboxProps<V, O>,
  'isMenuOpen' | 'onMenuClose'
> & { debounceDelay?: number };

export type { ComboboxOption, ComboboxProps };

export const Combobox = forwardRef<Override<ComboboxProps<unknown>, { value?: ComboboxOption<unknown> }>, 'input'>(
  (
    { debounceDelay = 300, onInputChange, isLoadingOptions, options: _options, value: selectedOption, ...props },
    ref
  ) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { debouncedValue, isDebouncing } = useDebounce(searchTerm, debounceDelay);
    const { isOpen: isMenuOpen, onClose: onMenuClose, onOpen: onMenuOpen } = useDisclosure();

    useUpdateEffect(() => {
      if (debouncedValue) onInputChange?.(debouncedValue);
    }, [debouncedValue]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const value = useMemo(() => selectedOption, [JSON.stringify(selectedOption)]);

    const options = useMemo(
      () => (!isMenuOpen && value ? ([value] as ComboboxOption<unknown>[]) : _options),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(_options), isMenuOpen, value]
    );

    return (
      <_Combobox
        {...props}
        ref={ref}
        options={options}
        value={value?.value}
        isLoadingOptions={isDebouncing || isLoadingOptions}
        onMenuClose={onMenuClose}
        isMenuOpen={isMenuOpen}
        onInputChange={(input) => {
          if (input) {
            onMenuOpen();
            setSearchTerm(input);
          }
        }}
      />
    );
  }
) as <V, O extends ComboboxOption<V>>(
  props: Parameters<React.ForwardRefExoticComponent<Override<ComboboxProps<V, O>, { value?: O }>>>[0] &
    React.RefAttributes<HTMLInputElement>
) => JSX.Element;

function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isDebouncing, debouncing] = useBoolean(false);

  const timeoutRef = useRef<number>();

  useUpdateEffect(() => {
    debouncing.on();
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setDebouncedValue(value);
      debouncing.off();
    }, delay);

    return () => clearTimeout(timeoutRef.current);
  }, [JSON.stringify(value), delay]);

  return {
    debouncedValue,
    isDebouncing,
  };
}
