import { transformDuplicateVendorsResponseForMatching } from '@melio/ap-domain';
import { ContinueWithoutLinkingVendorsModal } from '@melio/ap-widgets';
import { BrandSymbolKey } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { AccountingSoftwareDuplicatedVendorMatch, PartnerName } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { VendorLinkingScreen } from '../screens/VendorLinking/VendorLinking.screen';
import { SyncFlowEnum } from '../types';

type Props = {
  partnerName: PartnerName;
  partnerDisplayName: string;
  flow?: SyncFlowEnum;
  isError: boolean;
  isLoading: boolean;
  duplicateVendors?: AccountingSoftwareDuplicatedVendorMatch[];
  accountingPlatformBrandSymbolKey: BrandSymbolKey;
  isConfirmLoading: boolean;
  onCancel: VoidFunction;
  accountingPlatformName?: string;
  onConfirm: (entities: { localId: string; externalId: string }[]) => Promise<void>;
};

export const VendorLinkingStep = ({
  partnerName,
  partnerDisplayName,
  flow,
  isError,
  isLoading,
  duplicateVendors,
  accountingPlatformBrandSymbolKey,
  isConfirmLoading,
  onCancel,
  onConfirm,
  accountingPlatformName,
}: Props) => {
  const [isCheckAllVendorsWhenMatchingFeatureEnabled] = usePartnerFeature('CheckAllVendorsWhenMatching', false);
  const [isAutoMatchHighConfidenceVendorsFeatureEnabled] = usePartnerFeature('AutoMatchHighConfidenceVendors', false);

  const { track, createTrackHandler } = useAnalytics();

  const entities = useMemo(
    () =>
      transformDuplicateVendorsResponseForMatching(
        partnerName,
        partnerDisplayName,
        accountingPlatformBrandSymbolKey,
        accountingPlatformName,
        duplicateVendors
      ),
    [partnerName, partnerDisplayName, accountingPlatformBrandSymbolKey, accountingPlatformName, duplicateVendors]
  );

  const [selectedEntities, setSelectedEntities] = useState<string[]>([]);

  const defaultAnalyticsFields = {
    PageName: 'vendors-with-similar-details-were-found',
    Flow: `sync`,
    Intent: `sync-vendors`,
    EntryPoint: flow === SyncFlowEnum.Setting ? 'settings-page' : 'dashboard',
    CountInTab: duplicateVendors?.length,
    VendorIds: duplicateVendors?.map((duplicateVendor) => duplicateVendor.localVendor.id),
    MultiSelection: selectedEntities.length > 1,
  };
  const trackAndHandleClick = createTrackHandler<{
    Cta: 'cancel' | 'confirm';
  }>('Vendor', 'Click');

  useAnalyticsView('Vendor', true, true, {
    ...defaultAnalyticsFields,
    ErrorType: isError ? 'could-not-confirm-and-continue' : null,
  });

  useEffect(() => {
    if (isError) {
      track('Vendor', 'View', {
        ...defaultAnalyticsFields,
        ErrorType: 'could-not-confirm-and-continue',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const [isContinueWithoutLinkingVendorsModalOpen, setIsContinueWithoutLinkingVendorsModalOpen] =
    useState<boolean>(false);
  const [countOfVendorsWithoutLinking, setCountOfVendorsWithoutLinking] = useState<number>(0);

  const openContinueWithoutLinkingVendorsModal = () => {
    setIsContinueWithoutLinkingVendorsModalOpen(true);

    track('Vendor', 'View', {
      ...defaultAnalyticsFields,
      PageName: 'continue-without-linking',
      Intent: 'continue-without-linking',
    });
  };

  const closeContinueWithoutLinkingVendorsModal = () => {
    track('Vendor', 'Click', {
      ...defaultAnalyticsFields,
      PageName: 'continue-without-linking',
      Cta: 'exit',
      Intent: 'continue-without-linking',
    });

    setIsContinueWithoutLinkingVendorsModalOpen(false);
  };

  const cancelContinueWithoutLinkingVendorsModal = () => {
    track('Vendor', 'Click', {
      ...defaultAnalyticsFields,
      PageName: 'continue-without-linking',
      Cta: 'cancel',
      Intent: 'continue-without-linking',
    });

    setIsContinueWithoutLinkingVendorsModalOpen(false);
  };

  const onCancelClick = () => {
    trackAndHandleClick({ Cta: 'cancel', ...defaultAnalyticsFields }, onCancel);
  };

  const entitiesToMerge = useMemo(() => {
    const highConfidenceIds = entities.filter((entity) => entity.confidence === 'high').map((entity) => entity.id);

    const selectedWithHighConfidence = isAutoMatchHighConfidenceVendorsFeatureEnabled
      ? [...selectedEntities, ...highConfidenceIds]
      : selectedEntities;

    return selectedWithHighConfidence.reduce<{ localId: string; externalId: string }[]>((acc, id) => {
      const entity = entities.find((entity) => entity.id === id);
      if (!entity) {
        return acc;
      }

      return [
        ...acc,
        {
          localId: entity?.entityA.id,
          externalId: entity?.entityB.id,
        },
      ];
    }, []);
  }, [entities, isAutoMatchHighConfidenceVendorsFeatureEnabled, selectedEntities]);

  const handleConfirm = async () => {
    track('Vendor', 'Click', {
      ...defaultAnalyticsFields,
      PageName: 'continue-without-linking',
      Cta: 'continue-to-connect',
      NumberOfVendorsSelected: entitiesToMerge.length,
    });

    setIsContinueWithoutLinkingVendorsModalOpen(false);
    await onConfirm(entitiesToMerge);
  };

  const handleSubmit = async () => {
    track('Vendor', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'confirm-and-continue',
      Intent: entitiesToMerge.length === 0 ? 'continue-without-linking' : 'sync-vendors',
      NumberOfVendorsSelected: entitiesToMerge.length,
    });

    if (entitiesToMerge.length < entities.length) {
      setCountOfVendorsWithoutLinking(entities.length - entitiesToMerge.length);
      openContinueWithoutLinkingVendorsModal();
    } else {
      await onConfirm(entitiesToMerge);
    }
  };

  const handleSelect = (id: string) => {
    setSelectedEntities((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((entity) => entity !== id) : [...prevSelected, id]
    );
  };

  const handleSelectAll = (isAllSelected: boolean) => {
    setSelectedEntities(isAllSelected ? entities.map((entity) => entity.id) : []);
  };

  const entitiesWithChecked = useMemo(() => {
    const filteredEntities = isAutoMatchHighConfidenceVendorsFeatureEnabled
      ? entities.filter((entity) => entity.confidence !== 'high')
      : entities;

    return filteredEntities.map((entity) => ({
      ...entity,
      isChecked: selectedEntities.includes(entity.id),
    }));
  }, [entities, selectedEntities, isAutoMatchHighConfidenceVendorsFeatureEnabled]);

  useOneTimeEffect(
    () => {
      handleSelectAll(true);
    },
    () => entities.length !== 0 && isCheckAllVendorsWhenMatchingFeatureEnabled,
    [entities, isCheckAllVendorsWhenMatchingFeatureEnabled]
  );

  const handleCardOpen = (index: number) => {
    track('Vendor', 'Click', {
      ...defaultAnalyticsFields,
      Cta: 'expand-vendor-details',
      VendorPosition: `${index + 1}/${entities.length}`,
      VendorId: entitiesWithChecked[index]?.entityA.id,
    });

    track('Vendor', 'View', {
      ...defaultAnalyticsFields,
      Intent: 'view-vendor-details',
      VendorPosition: `${index + 1}/${entities.length}`,
    });
  };

  return (
    <>
      <VendorLinkingScreen
        entities={entitiesWithChecked}
        isLoading={isLoading}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onCancel={onCancelClick}
        onConfirm={handleSubmit}
        isConfirmLoading={isConfirmLoading}
        onCardOpen={handleCardOpen}
      />
      <ContinueWithoutLinkingVendorsModal
        isOpen={isContinueWithoutLinkingVendorsModalOpen}
        onClose={closeContinueWithoutLinkingVendorsModal}
        onCancel={cancelContinueWithoutLinkingVendorsModal}
        vendorCount={countOfVendorsWithoutLinking}
        onSubmit={handleConfirm}
      />
    </>
  );
};
