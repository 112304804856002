import { Container, Form, Group, Modal, NakedButton, Text, useMelioForm } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useImportFile } from '@melio/platform-utils';
import { writeFileXLSX } from 'xlsx';

import { getSchema } from './importEntitiesFromFileModalShema';
import { createTemplateToDownload } from './importEntitiesFromFileUtils';

type FormFields = {
  file: File;
};

type ImportEntitiesFromFileModalProps = {
  isOpen: boolean;
  onFileSelected: (fileId: string) => void;
  onClose: () => void;
  title: string;
  subtitle: string;
};

export const ImportEntitiesFromFileModal = ({
  isOpen,
  onFileSelected,
  onClose,
  title,
  subtitle,
}: ImportEntitiesFromFileModalProps) => {
  const { uploadFile } = useImportFile();
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vendor: { importVendorFieldsList },
    },
  } = useConfig();

  const handleFileSubmit = ({ file }: { file: File | null }) => {
    if (file) {
      const fileId: string = uploadFile(file);
      onFileSelected(fileId);
    }
  };

  const handleDownloadTemplate = () => {
    const templateToDownload = createTemplateToDownload(importVendorFieldsList);
    writeFileXLSX(templateToDownload, 'import-template.xlsx');
  };

  const schema = getSchema({
    requiredError: formatMessage('activities.importEntities.importModal.missingFileError'),
    tooBigError: formatMessage('activities.importEntities.importModal.fileTooBig'),
  });
  const { registerField, submitButtonProps, formProps } = useMelioForm<FormFields>({
    schema,
    onSubmit: handleFileSubmit,
  });

  return (
    <Modal
      isOpen={isOpen}
      data-testid="import-entities-modal"
      onClose={onClose}
      header={title}
      primaryButton={{
        variant: 'primary',
        label: formatMessage('activities.importEntities.importModal.primaryLabel'),
        ...submitButtonProps,
      }}
      secondaryButton={{
        variant: 'tertiary',
        label: formatMessage('activities.importEntities.importModal.secondaryLabel'),
      }}
    >
      <Group variant="vertical">
        <Text>{subtitle}</Text>
        <Form {...formProps}>
          <Form.FileUpload
            acceptTypes={['csv']}
            data-testid="import-entities-file-upload"
            helperTextProps={{
              label: formatMessage('activities.importEntities.importModal.supportedFileTypes'),
            }}
            labelProps={{
              label: formatMessage('activities.importEntities.importModal.fileInputLabel'),
            }}
            {...registerField('file')}
          />
        </Form>
        <Container>
          <Text>{formatMessage('activities.importEntities.importModal.downloadPrefix')}</Text>
          <NakedButton
            onClick={handleDownloadTemplate}
            variant="secondary"
            data-testid="download-csv-template-file-button"
            label={formatMessage('activities.importEntities.importModal.downloadLabel')}
          />
        </Container>
      </Group>
    </Modal>
  );
};
