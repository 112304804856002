import { Form, VisuallyHidden } from '@melio/penny';
import { BusinessResultItem, MsnBusinessItem } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { FocusEventHandler, useEffect, useRef } from 'react';

import { BusinessSearchWidget, BusinessSearchWidgetProps } from '../../../../BusinessSearch';
import { AddManagedVendorFormProps } from './AddManagedVendorForm/types';

type CompanyFieldProps = Omit<
  BusinessSearchWidgetProps,
  'onCreateOption' | 'onSelectOption' | 'onClearField' | 'aria-label'
> & {
  onSelectCompanyName: AddManagedVendorFormProps['onSelectCompanyName'];
  autoFocus?: boolean;
  onClearSearchField: BusinessSearchWidgetProps['onClearField'];
  onBlur?: FocusEventHandler<HTMLInputElement>;
  isVendorSelected?: boolean;
};

export const CompanyField = ({
  onSelectCompanyName,
  onClearSearchField,
  autoFocus,
  isBusiness,
  isVendorSelected = false,
  onBlur,
  value,
  ...props
}: CompanyFieldProps) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      vendor: {
        createVendor: { showCompanyNameTooltip },
      },
    },
  } = useConfig();

  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isVendorSelected) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      });
    }
  }, [isVendorSelected]);

  const fieldProps = {
    labelProps: {
      label: formatMessage('widgets.vendors.companyName.label'),
      tooltipProps: showCompanyNameTooltip
        ? {
            content: (
              <>
                <VisuallyHidden>{formatMessage('widgets.vendors.companyName.labelTooltip')} tooltip</VisuallyHidden>
                {formatMessage('widgets.vendors.companyName.labelTooltip')}
              </>
            ),
          }
        : undefined,
    },
    placeholder: formatMessage('widgets.vendors.companyName.placeholder'),
    onBlur,
  };

  if (isSearchBusinessesInDirectoriesSupported) {
    return (
      <BusinessSearchWidget
        {...props}
        ref={searchInputRef}
        onCreateOption={(companyName) => {
          onSelectCompanyName?.({ companyName });
        }}
        onSelectOption={(business: BusinessResultItem | MsnBusinessItem) => {
          onSelectCompanyName?.({
            companyName: (business as BusinessResultItem).business.name,
            business: business as BusinessResultItem,
          });
        }}
        onClearField={onClearSearchField}
        isBusiness={isBusiness}
        autoFocus={autoFocus}
        {...fieldProps}
      />
    );
  }

  return <Form.TextField {...props} {...fieldProps} />;
};
