import { Loader } from '@melio/penny';
import { useImportFile } from '@melio/platform-utils';

import { enhanceVendorsWithIds, parseColumns } from './importVendorsUtils';
import { ReviewVendorsTable } from './ReviewVendorsTable';

type ImportVendorsActivityProps = {
  fileId: string;
};

export const ImportVendorsActivity = ({ fileId }: ImportVendorsActivityProps) => {
  const { loading: loadingFile, getFileData } = useImportFile();
  const fileData = getFileData(fileId) || [];

  if (loadingFile) {
    return <Loader />;
  }

  const parsedData = parseColumns(fileData);

  return <ReviewVendorsTable vendorsData={enhanceVendorsWithIds(parsedData)} />;
};
