import { screen } from '@testing-library/react';

const bannerId = 'subscription-promotion-banners';

export class PlansPromotionBannerJestDriver {
  getSubscriptionPromotionBanners() {
    return screen.queryByTestId(bannerId);
  }
}

export class PlansPromotionBannerCypressDriver {
  getSubscriptionPromotionBanners() {
    return screen.queryByTestId(bannerId);
  }
}
