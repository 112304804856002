import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';

export const Actions = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-component="NewSinglePaymentStepLayout.Actions"
    display="flex"
    justifyContent="center"
    width="100%"
    marginX="auto"
    {...props}
    ref={ref}
  />
));

Actions.displayName = 'NewSinglePaymentStepLayout.Actions';
