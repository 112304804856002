import { Box } from '@chakra-ui/react';
import { Container, Group, GroupProps, LayoutProps, useBreakpointValue } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { ReactElement } from 'react';

import { useIsStickyFooter } from '../../utils/pay-flow/useIsStickyFooter';
import { components } from './components';

type NewSinglePaymentStepLayoutProps = Pick<
  LayoutProps,
  'isLoading' | 'paddingContent' | 'backgroundColor' | 'children' | 'maxWidth'
> & {
  headerContent?: ReactElement;
  bottomContent?: ReactElement;
  footerContent?: ReactElement;
  isCentered?: boolean;
  spacing?: GroupProps['spacing'];
  isStickyFooter?: boolean;
  dataTestId?: string;
};

const NewSinglePaymentStep = forwardRef<NewSinglePaymentStepLayoutProps, 'div'>(
  (
    {
      headerContent,
      bottomContent,
      footerContent,
      isLoading,
      backgroundColor: _backgroundColor,
      isCentered,
      spacing,
      children,
      isStickyFooter: _isStickyFooter,
      dataTestId,
      ...props
    },
    ref
  ) => {
    const {
      settings: { singlePaymentStepLayout },
    } = useConfig();
    const backgroundColor = _backgroundColor ?? singlePaymentStepLayout.backgroundColor;

    const isStickyHeader = useBreakpointValue({ xs: true, s: true, m: false });
    const isStickyFooter = useIsStickyFooter() || _isStickyFooter;

    const footer = footerContent && <Box as="footer">{footerContent}</Box>;
    const defaultSpacing = useBreakpointValue<GroupProps['spacing']>({ xs: 'm', s: 'l', xl: 'l' });
    const mainSpacing = spacing || defaultSpacing;

    const mainContent = (
      <Group variant="vertical" spacing={mainSpacing}>
        {children}
        {!isStickyFooter && footer}
        {bottomContent}
      </Group>
    );

    return (
      <Box height="100%" data-testid={dataTestId}>
        <Layout
          maxWidth="600px"
          backgroundColor={backgroundColor}
          header={
            headerContent && {
              content: headerContent,
              isSticky: isStickyHeader,
            }
          }
          footer={
            footer && isStickyFooter
              ? {
                  content: footer,
                  isSticky: true,
                }
              : undefined
          }
          isLoading={isLoading}
          ref={ref}
          {...props}
        >
          {isCentered ? (
            <Container height="full" width="fit-content" alignItems="center">
              {mainContent}
            </Container>
          ) : (
            mainContent
          )}
        </Layout>
      </Box>
    );
  }
);

NewSinglePaymentStep.displayName = 'NewSinglePaymentStep';

export const NewSinglePaymentStepLayout = Object.assign(NewSinglePaymentStep, components);

NewSinglePaymentStepLayout.displayName = 'NewSinglePaymentStepLayout';
