import { usePrevious } from '@chakra-ui/react';
import { Button, Container, Form, Group, useWatch } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useCallback, useEffect, useState } from 'react';

import { PaymentMethodBanner } from '../../../activities/PaymentMethodBanner/PaymentMethodBanner';
import {
  useKeyDownHandlerButtonClickSimulation,
  useSubscriptionFundingSource,
  useTriggerFormValidationFields,
  useUpdateClientSubscriptionBillingOptions,
} from '../../../hooks';
import { useFocusStepOnError } from '../../../hooks/useFocusStepOnError';
import { useGetSearchParamsValues } from '../../../hooks/useGetSearchParamsValues';
import { SubscriptionBillingPayor } from '../../../types';
import { ManageClientFormFields, StepFormProps, useManageClientFormContext } from '..';

const stepFields: Array<keyof ManageClientFormFields> = ['whoPays'];

export const ManageClientBillingDetailsStep: React.FC<StepFormProps> = ({
  submitLabel,
  focusErrorStep,
  onContinue,
  onNavigateToClientPaymentMethodsSettings,
}) => {
  const { formatMessage } = useMelioIntl();
  const { isSubscriptionFundingSourceAlreadySelected } = useSubscriptionFundingSource();
  const { form: formContext, clientOrgId } = useManageClientFormContext();
  const { formState, control, setValue, registerField, submitButtonProps } = formContext;
  const { buttonRef, handleKeyDown } = useKeyDownHandlerButtonClickSimulation('Enter');
  const triggerFormValidationFields = useTriggerFormValidationFields(formContext);
  const { track } = useAnalytics();
  const [lastFirmFundingSourceIdUserSelection, setLastFirmFundingSourceIdUserSelection] = useState<string>();
  const [lastClientFundingSourceIdUserSelection, setLastClientFundingSourceIdUserSelection] = useState<string>();

  const {
    clientFundingSources,
    firmSubscriptionBillingFundingSourceId,
    clientSubscriptionBillingFundingSourceId,
    firmFundingSources,
    clientSubscription,
    currentPayor,
    suggestedClientFundingSource,
    suggestedFirmFundingSource,
    isAnonymousPayor,
    firmName,
    clientName: existingClientName,
  } = useUpdateClientSubscriptionBillingOptions(clientOrgId).data;

  const isUpdateMode = !!clientSubscription?.payingOrganization?.organizationId;
  const verifiedClientFundingSources = clientFundingSources?.filter((fs) => fs.isVerified);

  const handleFundingSourceChange = useCallback(
    (fundingSourceId: string | null) => {
      const payor = clientFundingSources?.find((fs) => fs.id === fundingSourceId)
        ? SubscriptionBillingPayor.Client
        : SubscriptionBillingPayor.AccountingFirm;

      if (payor === SubscriptionBillingPayor.Client) {
        setLastClientFundingSourceIdUserSelection(fundingSourceId ?? '');
      }
      if (payor === SubscriptionBillingPayor.AccountingFirm) {
        setLastFirmFundingSourceIdUserSelection(fundingSourceId ?? '');
      }
      setValue('fundingSourceId', fundingSourceId || '');
      setValue('whoPays', payor);
    },
    [setValue, clientFundingSources]
  );

  const { getParamsValues, removeParamsValues: removeFundingSourceFromUrl } = useGetSearchParamsValues([
    'fundingSourceId',
  ]);
  const { fundingSourceId: fundingSourceIdFromUrl } = getParamsValues();

  const isDrawerOpenByDefault = !!fundingSourceIdFromUrl;

  useEffect(() => {
    if (isDrawerOpenByDefault) {
      removeFundingSourceFromUrl();
    }
  }, [isDrawerOpenByDefault, removeFundingSourceFromUrl]);

  const [watchFundingSourceId, watchWhoPays] = useWatch({
    control,
    name: ['fundingSourceId', 'whoPays'],
  });

  const watchPreviousFundingSourceId = usePrevious(watchFundingSourceId);
  const watchPreviousWhoPays = usePrevious(watchWhoPays);

  const suggestedClientFundingSourceId = lastClientFundingSourceIdUserSelection || suggestedClientFundingSource?.id;
  const suggestedFirmFundingSourceId = lastFirmFundingSourceIdUserSelection || suggestedFirmFundingSource?.id;

  useEffect(() => {
    const shouldUpdateFundingSourceAfterPayorChanged =
      watchWhoPays && watchPreviousWhoPays !== watchWhoPays && watchPreviousFundingSourceId === watchFundingSourceId;

    if (shouldUpdateFundingSourceAfterPayorChanged) {
      setValue(
        'fundingSourceId',
        (watchWhoPays === SubscriptionBillingPayor.AccountingFirm
          ? firmSubscriptionBillingFundingSourceId || suggestedFirmFundingSourceId
          : suggestedClientFundingSourceId) || ''
      );
    }
  }, [
    watchPreviousFundingSourceId,
    watchPreviousWhoPays,
    watchWhoPays,
    watchFundingSourceId,
    setValue,
    firmSubscriptionBillingFundingSourceId,
    clientSubscriptionBillingFundingSourceId,
    suggestedClientFundingSourceId,
    suggestedFirmFundingSourceId,
  ]);

  useFocusStepOnError<ManageClientFormFields>({
    formState,
    stepFields,
    focusErrorStep,
  });

  const verifyBillingDetails = async () => {
    const whoPays = watchWhoPays === SubscriptionBillingPayor.Client ? 'client-pays-directly' : 'firm-pays-for-client';
    track('Organization', 'Click', {
      Cta: 'continue',
      PageName: 'billing-details',
      Intent: 'add-billing-details',
      OptionChosen: whoPays,
    });

    const isStepFormFieldsValid = await triggerFormValidationFields(stepFields);

    if (isStepFormFieldsValid) {
      onContinue();
    }
  };

  const commonFieldsProps = {
    onKeyDown: handleKeyDown,
  };

  const isWhoPaysChanged = watchWhoPays !== currentPayor;
  const isDisabled = isUpdateMode && (!isWhoPaysChanged || !watchFundingSourceId);

  return (
    <Group spacing="m" variant="vertical" width="full">
      <Form.RadioGroup
        options={[
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.manageClient.form.billingDetails.fields.clientPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                `activities.accountants.manageClient.form.billingDetails.fields.clientPays.description.text.${
                  existingClientName ? 'existingClient' : 'newClient'
                }`,
                {
                  clientName: existingClientName,
                }
              ),
            },
            disabled: { isDisabled: isUpdateMode && !clientFundingSources?.length },
            value: SubscriptionBillingPayor.Client,
          },
          {
            mainLabelProps: {
              label: formatMessage('activities.accountants.manageClient.form.billingDetails.fields.firmPays.label'),
            },
            descriptionProps: {
              label: formatMessage(
                'activities.accountants.manageClient.form.billingDetails.fields.firmPays.description.text',
                {
                  firmName,
                }
              ),
            },
            disabled: {
              isDisabled:
                (!isSubscriptionFundingSourceAlreadySelected && !firmFundingSources?.length) || isAnonymousPayor,
            },
            value: SubscriptionBillingPayor.AccountingFirm,
          },
        ]}
        variant="vertical"
        {...registerField('whoPays')}
        {...commonFieldsProps}
      />
      <PaymentMethodBanner
        isDrawerDefaultOpen={isDrawerOpenByDefault}
        isAllowedToUpdateFirmBillingFundingSource={!isSubscriptionFundingSourceAlreadySelected && !isAnonymousPayor}
        fundingSourceId={watchFundingSourceId}
        onFundingSourceChange={handleFundingSourceChange}
        subscriptionBillingPayor={watchWhoPays}
        clientFundingSources={verifiedClientFundingSources}
        onNavigateToClientPaymentMethodsSettings={onNavigateToClientPaymentMethodsSettings}
        clientOrgId={clientOrgId}
      />
      <Container justifyContent="flex-end">
        <Button
          ref={buttonRef}
          variant="primary"
          size="medium"
          label={submitLabel}
          data-testid="billing-details-continue-button"
          {...submitButtonProps}
          onClick={() => void verifyBillingDetails()}
          isLoading={submitButtonProps.isLoading}
          isDisabled={submitButtonProps.isDisabled || isDisabled}
        />
      </Container>
    </Group>
  );
};
