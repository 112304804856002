import { useBankAccountFundingSources, useMelioIntl, useReceivingMethods, useSystemMessage } from '@melio/ar-domain';
import { forwardRef, useBoolean, useDebounceCallback } from '@melio/platform-utils';
import { useCallback, useEffect } from 'react';

import { ConfirmReceivingAccountScreen } from './screens';
import { PlaidData, usePlaidDialog } from './utils';

export type ConfirmReceivingAccountActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  onBack: VoidFunction;
  onError?: ARErrorFunction;
};

export const ConfirmReceivingAccountActivity = forwardRef<ConfirmReceivingAccountActivityProps>(
  ({ onDone, onError: _onError, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const receivingMethods = useReceivingMethods();
    const fundingSources = useBankAccountFundingSources({
      enabled: receivingMethods.data?.length === 0,
    });
    const [isCreated, createdState] = useBoolean(false);

    const { triggerMessage, cleanMessage } = useSystemMessage();

    useEffect(() => {
      if (isCreated && !!receivingMethods.data?.[0]) {
        triggerMessage({
          title: formatMessage('ar.onboarding.activities.confirmReceivingAccount.success.message'),
          type: 'success',
        });
      }
    }, [isCreated, receivingMethods.data, triggerMessage, formatMessage]);

    const onError = useCallback((error: ARPlatformError) => {
      _onError?.(error);
      triggerMessage({
        title: formatMessage('ar.onboarding.activities.confirmReceivingAccount.error.message'),
        type: 'critical',
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const convertAccountToReceivingMethod = useDebounceCallback((fundingSourceId: string) => {
      cleanMessage();
      receivingMethods.createFromFundingSource({ fundingSourceId }).then(createdState.on).catch(onError);
    }, 100);

    const createPlaidAccount = useDebounceCallback(
      (details: PlaidData) =>
        void receivingMethods.create({ type: 'plaid', details }).then(createdState.on).catch(onError),
      100
    );

    const { onOpen: onPlaidDialogOpen } = usePlaidDialog({
      onSuccess: createPlaidAccount,
      onError,
      onOpen: cleanMessage,
    });

    return (
      <ConfirmReceivingAccountScreen
        receivingMethod={receivingMethods.data?.[0]}
        fundingSources={fundingSources.data}
        isSaving={receivingMethods.isMutating || (receivingMethods.isFetching && !receivingMethods.isLoading)}
        isLoading={fundingSources.isLoading || receivingMethods.isLoading}
        onAddPlaidAccount={onPlaidDialogOpen}
        onSubmit={(params) => {
          if (params?.fundingSourceId) convertAccountToReceivingMethod(params.fundingSourceId);
          else onDone();
        }}
        {...props}
        ref={ref}
      />
    );
  }
);
