import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useGuestPaymentIntent, usePaymentRequestStatus } from '../../hooks';
import { BaseLayout } from '../../layout';
import { GuestPaymentIntentParams } from '../../types';
import { useRedirectToPayDashboard } from './hooks';

export type PaymentOptionsTriageActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onDone: (next: 'card' | 'bank') => void;
  onError: VoidFunction;
  isNavigateApUsersToDashboardEnabled?: boolean;
  currentRoute?: 'Card' | 'Bank';
};

export const PaymentOptionsTriageActivity = forwardRef<PaymentOptionsTriageActivityProps>(
  (
    {
      guestPaymentIntentParams,
      onDone,
      onError,
      isNavigateApUsersToDashboardEnabled,
      currentRoute,
      children = <Outlet />,
      ...props
    },
    ref
  ) => {
    const { paymentRequestLink } = guestPaymentIntentParams;
    const { isLoading: isGuestPaymentIntentLoading, data } = useGuestPaymentIntent({
      guestPaymentIntentParams,
      onError,
    });
    const redirectModel = useRedirectToPayDashboard(
      guestPaymentIntentParams,
      isNavigateApUsersToDashboardEnabled && !!paymentRequestLink
    );
    const paymentRequestStatusModel = usePaymentRequestStatus(guestPaymentIntentParams);

    const { isAchAllowed, isCardAllowed } = data?.vendorPaymentDetails?.paymentOptions ?? {};
    const isLoading = isGuestPaymentIntentLoading || redirectModel.isLoading || paymentRequestStatusModel.isLoading;

    function reRoute() {
      if (isCardAllowed) onDone('card');
      else if (isAchAllowed) onDone('bank');
    }

    useEffect(() => {
      const { status } = paymentRequestStatusModel;
      if (isLoading || redirectModel.isRedirecting) return;
      if (status != 'unpaid' && status != 'vendor-handle') return onError();
      else if (currentRoute == 'Card' && !isCardAllowed) return reRoute();
      else if (currentRoute == 'Bank' && !isAchAllowed) return reRoute();
      else if (!currentRoute) return reRoute();
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    if (isLoading || redirectModel.isRedirecting) {
      return (
        <BaseLayout
          data-testid="payment-options-triage-activity"
          isLoading
          backgroundColor={undefined}
          {...props}
          ref={ref}
        />
      );
    }
    return <>{children}</>;
  }
);
PaymentOptionsTriageActivity.displayName = 'PaymentOptionsTriageActivity';
