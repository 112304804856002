import { useBoolean } from '@chakra-ui/react';
import { useAddressAutocomplete } from '@melio/form-controls';
import { _createFormFieldInput } from '@melio/penny';
import { Address, formatAddress } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { Combobox, ComboboxOption, ComboboxProps } from './Combobox';

type AddressSearchOption = ComboboxOption<Address>;

export type AddressFIeldInputProps = Omit<ComboboxProps<Address, AddressSearchOption>, 'options'>;

export const AddressFIeldInput = _createFormFieldInput(
  forwardRef<AddressFIeldInputProps, 'input'>(({ value, ...props }, ref) => {
    const { addressAutocomplete } = useAddressAutocomplete();
    const [options, setOptions] = useState<AddressSearchOption[]>([]);
    const [isLoadingOptions, loadingState] = useBoolean();

    return (
      <Combobox
        {...props}
        ref={ref}
        options={options}
        value={value && parseOption(value)}
        onInputChange={(searchTerm: string) => {
          loadingState.on();
          void addressAutocomplete(searchTerm)
            .then((results) => setOptions(results.map(parseOption)))
            .finally(loadingState.off);
        }}
        isLoadingOptions={isLoadingOptions}
      />
    );
  })
);

AddressFIeldInput.displayName = 'AddressFIeldInput';

const parseOption = (option: Address): AddressSearchOption => ({
  value: option,
  label: formatAddress(option),
});
