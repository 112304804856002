import { Box } from '@chakra-ui/react';
import { Icon, SectionBanner } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { FundingSource } from '@melio/platform-api/src/entities-v2/funding-source/types';
import { useState } from 'react';

import { AddPaymentMethodDrawer } from '../../components';
import { useUpdateClientSubscriptionBillingOptions } from '../../hooks';
import { AnalyticsPaymentDrawerActionType, SubscriptionBillingPayor } from '../../types';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { usePaymentMethodBannerProps } from './hooks/usePaymentMethodBannerProps';

type AccountantsAddPaymentMethodBannerProps = {
  fundingSourceId: string | null;
  subscriptionBillingPayor: SubscriptionBillingPayor;
  isAllowedToUpdateFirmBillingFundingSource: boolean;
  onFundingSourceChange: (fundingSourceId: string | null) => void;
  onNavigateToClientPaymentMethodsSettings?: VoidFunction;
  clientFundingSources?: Array<FundingSource>;
  clientOrgId?: string;
  isDrawerDefaultOpen?: boolean;
  onDrawerOpen?: VoidFunction;
};

export const PaymentMethodBanner = withAnalyticsContext<AccountantsAddPaymentMethodBannerProps>(
  ({
    isDrawerDefaultOpen,
    isAllowedToUpdateFirmBillingFundingSource,
    fundingSourceId,
    onFundingSourceChange,
    setAnalyticsProperties,
    subscriptionBillingPayor,
    onNavigateToClientPaymentMethodsSettings,
    clientOrgId,
  }) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(!!isDrawerDefaultOpen);
    const { track } = useAnalytics();
    const { goToSupportPage } = useAccountantsRoutes();

    const { firmFundingSources, clientFundingSources, suggestedClientFundingSource, suggestedFirmFundingSource } =
      useUpdateClientSubscriptionBillingOptions(clientOrgId).data;

    const fundingSourcesMapping: Record<SubscriptionBillingPayor, Array<FundingSource>> = {
      [SubscriptionBillingPayor.AccountingFirm]: firmFundingSources,
      [SubscriptionBillingPayor.Client]: clientFundingSources,
    };
    const fundingSources = fundingSourcesMapping[subscriptionBillingPayor];

    const suggestedFundingSourceMapping: Record<SubscriptionBillingPayor, FundingSource | undefined> = {
      [SubscriptionBillingPayor.AccountingFirm]: suggestedFirmFundingSource,
      [SubscriptionBillingPayor.Client]: suggestedClientFundingSource,
    };
    const suggestedFundingSource = suggestedFundingSourceMapping[subscriptionBillingPayor];

    const toggleAddFundingSourceDrawer = ({
      isOpen,
      action,
    }: {
      isOpen: boolean;
      action?: AnalyticsPaymentDrawerActionType;
    }) => {
      setIsDrawerOpen(isOpen);

      if (action !== 'continue') {
        track('Organization', 'Click', { Cta: action, Intent: action });
      }
    };

    const fundingSource =
      fundingSources?.find((fundingSource) => fundingSource.id === fundingSourceId) || suggestedFundingSource;

    const onSelectFundingSource = (fundingSourceId: string | null) => {
      onFundingSourceChange(fundingSourceId);

      track('Organization', 'Click', {
        Cta: 'continue',
        ChosenPaymentMethod: fundingSource?.type,
        Intent: 'invite–client',
      });
    };

    const handleClose = (action?: AnalyticsPaymentDrawerActionType) => {
      toggleAddFundingSourceDrawer({ isOpen: false, action });
    };

    const formatFundingSourceName = (fundingSourceDisplayName: string) => {
      const firstCommaIndex = fundingSourceDisplayName.indexOf(',');
      return fundingSourceDisplayName.slice(firstCommaIndex + 1).trim();
    };

    const { isHidden, nextPayor, bannerType, ...sectionBannerProps } = usePaymentMethodBannerProps({
      clientOrgId,
      subscriptionBillingPayor,
      onNavigateToClientPaymentMethodsSettings,
      fundingSourceDisplayName: fundingSource?.displayName ? formatFundingSourceName(fundingSource.displayName) : '',
      isAllowedToUpdateFirmBillingFundingSource,
      onOpenPaymentMethodDrawer: () => toggleAddFundingSourceDrawer({ isOpen: true }),
      goToSupportPage,
    });

    setAnalyticsProperties({
      PageName: 'add-payment-method',
      NumberPMExist: fundingSources?.length || 0,
      Payor: subscriptionBillingPayor,
      BannerVariant: bannerType,
    });

    useAnalyticsView('Organization', isDrawerOpen, true, {
      Intent: 'add-client-billing-details',
      Payor: subscriptionBillingPayor,
    });

    return (
      <>
        {sectionBannerProps && !isHidden && (
          <SectionBanner
            isCompact
            data-testid={bannerType}
            leftElement={
              sectionBannerProps.variant === 'neutral' ? (
                <Box>
                  <Icon size="small" type="info-fill" aria-hidden />
                </Box>
              ) : undefined
            }
            {...sectionBannerProps}
          />
        )}
        <AddPaymentMethodDrawer
          fundingSourceId={fundingSource?.id || fundingSourceId}
          isOpen={isDrawerOpen}
          setFundingSourceId={onSelectFundingSource}
          onClose={handleClose}
          subscriptionBillingPayor={nextPayor}
          clientFundingSources={clientFundingSources}
          onNavigateToClientPaymentMethodsSettings={onNavigateToClientPaymentMethodsSettings}
        />
      </>
    );
  }
);
