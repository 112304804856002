import { FormInputs, FormLayout, FormWidgetProps, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { BusinessDetailsFormFields } from '../types';
import { BusinessDetailsFormContext, useBusinessDetailsFormSchema } from './BusinessDetailsFormUtils';
import { TaxInfoFormSection } from './form-sections';

export type BusinessDetailsFormProps = FormWidgetProps<BusinessDetailsFormFields>;

export const BusinessDetailsForm = forwardRef<BusinessDetailsFormProps, 'form'>(
  ({ onSubmit, defaultValues, isSaving, onSubmissionStateChange, ...props }, ref) => {
    const form = useMelioForm<BusinessDetailsFormFields>({
      onSubmit,
      isSaving,
      onSubmissionStateChange,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
      schema: useBusinessDetailsFormSchema(),
    });
    const isMobile = useIsMobile();
    const { formatMessage } = useMelioIntl();

    return (
      <BusinessDetailsFormContext.Provider value={form}>
        <Form
          {...form.formProps}
          data-component="BusinessDetailsForm"
          data-testid="business-details-form"
          size={isMobile ? 'large' : 'small'}
          ref={ref}
          {...props}
        >
          <FormLayout.SectionHeader
            title={formatMessage('ar.onboarding.activities.businessDetails.form..label')}
            shouldDisplayRequiredFields
          />

          <TaxInfoFormSection />

          <FormInputs.AddressSearch
            {...form.registerField('legalAddress')}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalAddress.label'),
            }}
            placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalAddress.placeholder')}
            helperTextProps={{
              label: formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalAddress.helperText'),
            }}
          />

          <FormInputs.IndustrySearch
            {...form.registerField('industry')}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.businessDetails.form.fields.industry.label'),
            }}
            placeholder={formatMessage('ar.onboarding.activities.businessDetails.form.fields.industry.placeholder')}
            sixDigitOnly
          />
        </Form>
      </BusinessDetailsFormContext.Provider>
    );
  }
);
