import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type DeleteFutureClientPaymentsSectionBannerProps = {
  numberOfPaymentsToCancel: number;
  nextBillingDate: string;
};

export const DeleteFutureClientPaymentsSectionBanner = ({
  numberOfPaymentsToCancel,
  nextBillingDate,
}: DeleteFutureClientPaymentsSectionBannerProps) => {
  const { formatMessage } = useMelioIntl();

  const cancelPaymentsBannerMessage = {
    title:
      numberOfPaymentsToCancel > 0
        ? formatMessage('activities.subscription.cancelModal.cancelClientPlan.paymentsCancellationBanner.title', {
            paymentsCount: numberOfPaymentsToCancel,
          })
        : formatMessage(
            'activities.subscription.cancelModal.cancelClientPlan.paymentsCancellationBanner.noFuturePayments.title'
          ),
    description: formatMessage(
      'activities.subscription.cancelModal.cancelClientPlan.paymentsCancellationBanner.description',
      { nextBillingDate }
    ),
  };

  return (
    <SectionBanner
      data-testid="subscription-cancel-approval-modal-payments-cancellation-banner"
      variant="neutral"
      title={cancelPaymentsBannerMessage.title}
      description={cancelPaymentsBannerMessage.description}
      hideIcon
    />
  );
};
