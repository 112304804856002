import { Badge, Button, Icon, SelectableDropdownMenu, StatusIndicator } from '@melio/penny';
import { useState } from 'react';

import { DateRangePickerModal } from './DateRangePicker.modal';
import { DatePreset, DateRange, DateRangeDates, DateRangeFilterType } from './types';
import { useDateRangeFilter } from './useDateRangeFilter';

type DateRangeFilterProps = {
  config: DateRangeFilterType;
  selectedFilter?: string;
  selectedFilterRange?: DateRange;
  onApply: (dateRange: DateRange, datePreset: DatePreset) => void;
  dataTestId?: string;
};

export const DateRangeFilter = ({
  config,
  onApply,
  selectedFilterRange,
  selectedFilter,
  dataTestId = 'date-range-filter',
}: DateRangeFilterProps) => {
  const { presets, getPresetRange, getPresetLabel, filterLabel } = useDateRangeFilter(config);
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomDateModalOpen, setIsCustomDateModalOpen] = useState(false);

  const triggerElement = (
    <Badge mark={selectedFilterRange ? <StatusIndicator status="success" /> : undefined} placement="top-right">
      <Button
        data-testid={dataTestId}
        onClick={() => setIsOpen(!isOpen)}
        variant="tertiary"
        label={filterLabel}
        rightElement={<Icon size="small" type={isOpen ? 'caret-up' : 'caret-down'} color="inherit" aria-hidden />}
      />
    </Badge>
  );

  const actions = presets.map((preset) => ({
    label: getPresetLabel(preset),
    value: preset,
    dataTestId: `date-filter-preset-menu-item-${preset}`,
    onClick: () => {
      if (preset === 'Custom') {
        setIsOpen(false);
        setIsCustomDateModalOpen(true);
        return;
      }
      const dateRange = getPresetRange(preset);
      onApply(dateRange, preset);
      setIsOpen(false);
    },
  }));

  const handleCustomModalSubmit = (customRange: DateRangeDates) => {
    onApply(getPresetRange('Custom', customRange), 'Custom');
    setIsCustomDateModalOpen(false);
  };

  return (
    <>
      <SelectableDropdownMenu
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        trigger={triggerElement}
        items={actions}
        selectedItemValue={selectedFilter}
      />
      <DateRangePickerModal
        selectedDateRange={selectedFilterRange}
        isOpen={isCustomDateModalOpen}
        onClose={() => setIsCustomDateModalOpen(false)}
        onSubmit={handleCustomModalSubmit}
      />
    </>
  );
};
