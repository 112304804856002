import { useMelioIntl } from '@melio/platform-i18n';
import { Tier } from '@melio/subscriptions';

import { ManageClientFormFields, ManageClientFormStep, useManageClientForm } from '../../../../components';
import { ManageClientForm } from '../../../../components/ManageClientForm/ManageClientForm';
import { useSubscriptionFundingSource } from '../../../../hooks';
import { useGetFormSessionStorage, useSetFormSessionStorage } from '../../../../hooks/useFormSessionStorage';
import { useGetAccountingFirmClientPlanByTier } from '../../../../hooks/useGetAccountingFirmClientPlanByTier';
import { SubscriptionBillingPayor } from '../../../../types';
import { useNewFirmClientFormSchema, useNewFirmClientStepManager } from '../../hooks';

type NewFirmClientFormProps = {
  onSubmit: (data: ManageClientFormFields) => Promise<void>;
  isSaving: boolean;
};

type AdditionalWatchPropsFromStorage = { activeStep: ManageClientFormStep };
export const SESSION_STORAGE_FORM_DATA_KEY = 'new_firm_client';

export const NewFirmClientForm = ({ onSubmit, isSaving }: NewFirmClientFormProps) => {
  const { fundingSourceId: firmFundingSourceId } = useSubscriptionFundingSource();
  const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);
  const { formatMessage } = useMelioIntl();

  const {
    resumedFormValues,
    additionalPropValues: { activeStep: persistSessionActiveStep },
  } = useGetFormSessionStorage<ManageClientFormFields, AdditionalWatchPropsFromStorage>(SESSION_STORAGE_FORM_DATA_KEY);

  const form = useManageClientForm({
    isSaving,
    onSubmit,
    schema: useNewFirmClientFormSchema(),
    defaultValues: {
      businessDBA: '',
      businessName: '',
      clientEmailAddress: '',
      clientFirstName: '',
      clientLastName: '',
      shouldInviteClient: false,
      subscriptionPlanId: defaultAccountingFirmClientPlan?.id || '',
      ...resumedFormValues,
      whoPays: resumedFormValues.whoPays || SubscriptionBillingPayor.Client,
      fundingSourceId:
        resumedFormValues.fundingSourceId ||
        (resumedFormValues.whoPays === SubscriptionBillingPayor.AccountingFirm && firmFundingSourceId) ||
        undefined,
    },
  });

  const stepManager = useNewFirmClientStepManager({
    defaultActiveId: persistSessionActiveStep as ManageClientFormStep,
  });

  useSetFormSessionStorage<ManageClientFormFields, AdditionalWatchPropsFromStorage>(
    SESSION_STORAGE_FORM_DATA_KEY,
    form.watch,
    {
      activeStep: stepManager.expandedStep || persistSessionActiveStep || ManageClientFormStep.BasicDetails,
    }
  );

  return (
    <ManageClientForm
      form={form}
      isSaving={isSaving}
      stepManager={stepManager}
      labels={{ submit: formatMessage(`activities.accountants.addClient.form.submitButton.label`) }}
    />
  );
};
