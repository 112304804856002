import { MobileFilter, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, SearchBar as DSSearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MobileContainer } from '../../../components';
import { FilterParams, OnSort, QueryFilter, SortParams } from '../types';
import { SortMenuMobile } from './SortMenu.mobile';

export type FiltersToolBarMobileProps = {
  selectedFilter?: QueryFilter;
  onSelectFilter: (filters: FilterParams) => void;
  search?: string;
  onSearch: (value: string) => void;
  onSort?: OnSort;
  sortParams?: SortParams;
};

export const FiltersToolBarMobile = forwardRef<FiltersToolBarMobileProps>(
  ({ onSelectFilter, selectedFilter = 'all', onSearch, search, onSort, sortParams, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const filtersLabel: Record<QueryFilter, string> = {
      all: formatMessage('ar.dashboard.activities.paymentsTable.filters.all.label'),
      completed: formatMessage('ar.dashboard.activities.paymentsTable.filters.status.completed.label'),
      'in-progress': formatMessage('ar.dashboard.activities.paymentsTable.filters.status.in-progress.label'),
      scheduled: formatMessage('ar.dashboard.activities.paymentsTable.filters.status.scheduled.label'),
      failed: formatMessage('ar.dashboard.activities.paymentsTable.filters.status.failed.label'),
    };
    const orderedFilters: QueryFilter[] = ['all', 'completed', 'in-progress', 'scheduled'];

    return (
      <MobileContainer>
        <Group
          ref={ref}
          data-component={FiltersToolBarMobile.displayName}
          variant="horizontal"
          alignItems="center"
          justifyContent="space-around"
          data-testid="payments-dashboard-sub-and-search-tab"
          {...props}
        >
          <DSSearchBar
            value={search}
            onSearch={(value) => onSearch(String(value ?? ''))}
            placeholder={formatMessage('ar.dashboard.activities.paymentsTable.mobile.searchInput.placeholder')}
            onClear={() => onSearch('')}
            isFullWidth
            data-testid="payments-table-search-input"
            aria-label={formatMessage('ar.dashboard.activities.paymentsTable.mobile.searchInput.aria-label')}
            instructionsText={formatMessage('ar.dashboard.activities.paymentsTable.mobile.searchInput.instructions')}
          />
          <Group allowOverflowX={false} spacing="xs" width="fit-content" {...{ minWidth: 'fit-content' }}>
            <Container width="fit-content">
              <SortMenuMobile onSort={onSort} sortParams={sortParams} />
            </Container>
            <Container width="fit-content" overflow="initial">
              <MobileFilter
                activeFilter={selectedFilter}
                defaultFilter="all"
                options={orderedFilters.map((filter) => ({
                  id: filter,
                  label: filtersLabel[filter],
                }))}
                onChange={(filter) => {
                  filter === 'all'
                    ? onSelectFilter({ receivablePaymentStatus: [] })
                    : onSelectFilter({ receivablePaymentStatus: [filter] });
                }}
                title={formatMessage('ar.dashboard.activities.paymentsTable.filters.title')}
              />
            </Container>
          </Group>
        </Group>
      </MobileContainer>
    );
  }
);

FiltersToolBarMobile.displayName = 'FiltersToolBarMobile';
