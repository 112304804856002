import { StatusIconSolid } from '@melio/penny';
import { TodoOverdueInboxItems, TodoOverdueInboxItemsTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { useActivitiesNavigate } from '../../../../utils';
import { PayDashboardTabs } from '../../../PayDashboard';
import { useTodosDescriptions } from '../../hooks/useTodosDescriptions';
import { EnrichedTodo, normalizeInboxItems } from '../../utils';
import { TodoSection } from '../TodoSection';

export type TodoSectionOverdueInboxItemsProps = {
  todo?: EnrichedTodo<TodoOverdueInboxItems>;
  onNavigate?: VoidFunction;
};

export const TodoSectionOverdueInboxItems = ({ todo, onNavigate }: TodoSectionOverdueInboxItemsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToTab } = useActivitiesNavigate();
  const { createAmountDescription, createViewBillsAction, createDueDateDescription } = useTodosDescriptions();
  const [isBillsTabFiltersInfraEnabled] = usePartnerFeature('BillsTabFiltersInfra', false);

  if (!todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null) {
    return null;
  }
  const totalItems = todo.items.pagination.totalCount || 0;

  const data = normalizeInboxItems(todo);
  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.overdueBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.overdueBills.plural.title', {
          count: totalItems,
        });

  const onClickAction = () => {
    onNavigate?.();
    setTimeout(() => {
      navigateToTab(
        PayDashboardTabs.Bills,
        totalItems === 1
          ? { entityId: todo.items.data[0]?.payload.id }
          : {
              urlParams: isBillsTabFiltersInfraEnabled ? [{ key: 'inboxItem.dueDate', value: 'overdue' }] : [],
            }
      );
    });
  };
  const action = createViewBillsAction(totalItems, onClickAction);

  const description = [...createAmountDescription(totalItems, data)];
  if (totalItems === 1 && data[0]?.dueDate) {
    description.push(...createDueDateDescription(totalItems, data.length, data[0].dueDate, 'dueDate'));
  }

  return (
    <TodoSection
      key={`section-${TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}`}
      icon={<StatusIconSolid variant="warning" size="medium" />}
      title={title}
      description={description}
      action={action}
      type={TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}
      newItemsCount={todo.enrichment.newItemsCount}
    />
  );
};
