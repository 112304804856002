export enum OverviewScope {
  Days30 = 'days-30',
  Days60 = 'days-60',
  Days90 = 'days-90',
  Days365 = 'days-365',
  ThisMonth = 'this-month',
  ThisYear = 'this-year',
  LastMonth = 'last-month',
  LastYear = 'last-year',
}
