import { CheckAccountFormModel, VendorCheckDetailsPayorForm, VendorCheckDetailsPayorFormV2 } from '@melio/ap-widgets';
import { Container, Group, SectionBanner, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { DeliveryMethodFlowOrigin } from '../../../types';

export type AddVendorCheckDetailsScreenProps = {
  isLoading?: boolean;
  vendor?: Vendor;
  isSaving?: boolean;
  defaultValues?: Partial<CheckAccountFormModel>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (
    data: CheckAccountFormModel,
    event?: React.MouseEvent<HTMLButtonElement>,
    target?: 'continue' | 'close'
  ) => void;
  origin: DeliveryMethodFlowOrigin;
};

export const AddVendorCheckDetailsScreen = forwardRef<AddVendorCheckDetailsScreenProps, 'div'>(
  ({ isLoading, vendor, isSaving, defaultValues, onBack, onClose, onDone, origin, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { line1, line2, city, postalCode, printName, state } = defaultValues ?? {};
    const {
      line1: vendorLine1,
      city: vendorCity,
      postalCode: vendorPostalCode,
      state: vendorState,
      countryCode: vendorCountryCode,
    } = vendor?.contact?.address ?? {};
    const showPreFilledBanner = !!(line1 || line2 || city || postalCode || state);
    const { onSubmissionStateChange, cancelButtonProps, submitButtonProps, handleSubmit } =
      useFormSubmissionController<CheckAccountFormModel>();
    const [shouldCollectVendorAddress] = usePartnerFeature('CollectVendorAddress', false);
    const shouldShowAddressOptions = Boolean(
      shouldCollectVendorAddress &&
        vendorLine1 &&
        vendorCity &&
        vendorPostalCode &&
        vendorState &&
        vendorCountryCode === 'US'
    );
    const title = shouldShowAddressOptions
      ? formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetails.title')
      : formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetailsNew.title');

    useAnalyticsView('AddVendorCheckDetails');

    return (
      <NewSinglePaymentStepLayout
        isLoading={isLoading}
        data-component="AddVendorCheckDetailsActivity.AddVendorCheckDetailsScreen"
        data-testid="add-vendor-check-details-activity-add-vendor-check-details-screen"
        {...props}
        ref={ref}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.BackButton {...cancelButtonProps} onClick={onBack} />
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            {origin === 'vendor' ? (
              <Group variant="vertical" spacing="m" width="full">
                <NewSinglePaymentStepLayout.NextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('continue')}
                  label={formatMessage(
                    'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.vendorOrigin.continue'
                  )}
                />
                <NewSinglePaymentStepLayout.SecondaryNextButton
                  {...submitButtonProps}
                  onClick={handleSubmit?.('close')}
                  variant="tertiary"
                  label={formatMessage(
                    'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.vendorOrigin.close'
                  )}
                />
              </Group>
            ) : (
              <NewSinglePaymentStepLayout.NextButton
                {...submitButtonProps}
                onClick={handleSubmit?.('continue')}
                label={formatMessage(
                  'activities.addVendorCheckDetails.screens.addVendorCheckDetails.actions.paymentOrigin.continue'
                )}
              />
            )}
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        {showPreFilledBanner ? (
          <SectionBanner
            variant="informative"
            description={formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetails.sectionBanner')}
          />
        ) : null}
        <NewSinglePaymentStepLayout.Title>{title}</NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Content>
          {shouldShowAddressOptions && (
            <Container textAlign="center" paddingBottom="xl">
              {formatMessage('activities.addVendorCheckDetails.screens.addVendorCheckDetailsNew.description', {
                vendorName: vendor?.name,
              })}
            </Container>
          )}
          <Group variant="vertical">
            <SystemMessageDisplay data-testid="add-vendor-check-details-activity-system-message-display" />
            {shouldShowAddressOptions ? (
              <VendorCheckDetailsPayorFormV2
                onSubmit={onDone}
                isSaving={isSaving}
                defaultValues={{ line1, line2, city, postalCode, printName, state }}
                vendorAddress={vendor?.contact?.address}
                onSubmissionStateChange={onSubmissionStateChange}
              />
            ) : (
              <VendorCheckDetailsPayorForm
                onSubmit={onDone}
                isSaving={isSaving}
                defaultValues={{ line1, line2, city, postalCode, printName, state }}
                onSubmissionStateChange={onSubmissionStateChange}
              />
            )}
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

AddVendorCheckDetailsScreen.displayName = 'AddVendorCheckDetailsActivity.AddVendorCheckDetailsScreen';
