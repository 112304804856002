import { Account, PayorPaymentRequestDetails, PayorVendorPaymentDetails } from '@melio/ar-domain';
import { DeliveryPreferenceType } from '@melio/platform-api';

export type PaymentDraft = {
  note?: string;
  fundingSourceId?: string;
  scheduledDate?: Date;
  billId?: string;
  deliveryMethodId?: string;
  deliveryPreferenceType?: DeliveryPreferenceType;
};

export type GuestPayorFundingSourceTypes = 'bank' | 'card';
export type GuestPayorPaymentOptions = GuestPayorFundingSourceTypes | 'custom';
export type GuestPayorUserNameDetails = Pick<Account['user'], 'firstName' | 'lastName'>;
export type GuestPayorUserNameAndEmailDetails = { email: string } & GuestPayorUserNameDetails;

export const ErrorType = {
  EXPIRED: 'expired',
  INVOICE_CANCELLED: 'invoice-cancelled',
  GENERAL_ERROR: 'general-error',
} as const;
export type ErrorType = (typeof ErrorType)[keyof typeof ErrorType];

export const PaymentRequestStatus = {
  UNPAID: 'unpaid',
  UPDATED: 'updated',
  PAID: 'paid',
  CANCELLED: 'cancelled',
  NO_PAYMENT_OPTION: 'no-payment-option',
  ERROR: 'error',
  VENDOR_HANDLE: 'vendor-handle',
} as const;

export type PaymentRequestStatusType = (typeof PaymentRequestStatus)[keyof typeof PaymentRequestStatus];

type BillDetails = {
  vendorHandle: string;
  amount: number;
  invoiceNumber?: string;
  file?: File;
  note?: string;
};

export type GuestPaymentIntentParams =
  | { paymentRequestLink: string; billDetails?: never }
  | { paymentRequestLink?: never; billDetails: BillDetails };

export type PaymentRequestResult = PayorPaymentRequestDetails & {
  type: 'payment-request-link';
  vendorPaymentDetails?: PayorVendorPaymentDetails;
};

export type VendorHandleResult = {
  type: 'vendor-handle';
  vendorPaymentDetails?: PayorVendorPaymentDetails;
  isPayable: boolean;
  invoice: {
    totalAmount: number;
    fileInfo?: never;
    dueDate?: never;
    editedAt?: never;
  };
  paymentRequestId?: never;
  receivablePaymentDetails?: never;
};

export type GuestPaymentIntentData = PaymentRequestResult | VendorHandleResult;
