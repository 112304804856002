import { MsnBusinessPreviewCard, useBannerApiError, VendorFormBannerApiErrorCode } from '@melio/ap-widgets';
import { Container, Group, SectionBanner, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { MsnBusinessItem } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../../../promote-accounting-software-sync';
import { FooterContent } from '../../components/FooterContent';
import { FooterContentForMsnBusiness } from '../../components/FooterContentForMsnBusiness';
import { HeaderContent } from '../../components/HeaderContent';
import { SubmitTarget } from '../../types';
import { BusinessSearchForm, BusinessSearchFormProps } from './components/BusinessSearchForm';

type SearchBusinessScreenProps = BusinessSearchFormProps & {
  onClose: VoidFunction;
  onConfirmOption: (target: SubmitTarget) => void;
  stepCompleted: boolean;
  selectedMsnBusinessItem?: MsnBusinessItem;
  isLoading: boolean;
  bannerApiErrorCodes?: VendorFormBannerApiErrorCode[];
};

export const SearchBusinessScreen = withAnalyticsContext<SearchBusinessScreenProps>(
  ({
    setAnalyticsProperties,
    onSelectOption,
    onCreateOption,
    onConfirmOption,
    onClearField,
    stepCompleted,
    onClose,
    selectedMsnBusinessItem,
    isLoading,
    bannerApiErrorCodes,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const [isNewBusinessesSearchEnabled] = usePartnerFeature('NewBusinessesSearch', false);

    setAnalyticsProperties({
      PageName: 'search-vendor',
      Intent: 'add-a-vendor',
    });

    const { track } = useAnalytics();
    const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ variant: 'vendor' });

    const { error } = useBannerApiError(bannerApiErrorCodes);

    useEffect(() => {
      track('Vendor', 'View', viewAnalyticsProps);
    }, [track, viewAnalyticsProps]);

    return (
      <NewSinglePaymentStepLayout
        data-component="AddVendorActivity.SearchBusinessScreen"
        data-testid="add-vendor-activity-search-business-screen"
        isStickyFooter
        headerContent={<HeaderContent onClose={onClose} />}
        footerContent={
          isNewBusinessesSearchEnabled ? (
            <FooterContentForMsnBusiness
              isLoading={isLoading}
              isDisabled={!selectedMsnBusinessItem}
              onSubmit={onConfirmOption}
              onClose={onClose}
            />
          ) : (
            <FooterContent onClose={onClose} isButtonsDisabled />
          )
        }
        {...props}
      >
        {isMobile ? null : (
          <NewSinglePaymentStepLayout.Title textAlign="start" autoFocus={!stepCompleted} onClose={onClose}>
            {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        {error && <SectionBanner variant="critical" description={error?.description} />}
        <PromoteAccountingSoftwareSyncBanner
          variant="vendor"
          analyticsProps={{
            EntryPoint: 'dashboard-vendors',
            Flow: 'dashboard-user-message',
            PageName: 'add-vendor',
          }}
        />
        <NewSinglePaymentStepLayout.Description
          as="p"
          paddingTop={isMobile ? 'xs' : undefined}
          textStyle={isMobile ? 'body3' : undefined}
          textAlign="start"
        >
          {isNewBusinessesSearchEnabled
            ? formatMessage('activities.addVendor.searchAndAddVendorDetails.description.msn')
            : formatMessage('activities.addVendor.searchAndAddVendorDetails.description')}
        </NewSinglePaymentStepLayout.Description>
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="xs" width="full">
            <Text color="global.neutral.900" textStyle={isMobile ? 'caption1' : 'body4'}>
              {formatMessage('activities.addVendor.searchAndAddVendorDetails.requiredFields')}
            </Text>
            <BusinessSearchForm
              onSelectOption={onSelectOption}
              onCreateOption={onCreateOption}
              onClearField={onClearField}
              stepCompleted={stepCompleted}
            />
            {selectedMsnBusinessItem && (
              <Container paddingTop="m">
                <Group variant="vertical" spacing="m">
                  <SectionBanner
                    description={formatMessage('activities.addVendor.searchAndAddVendorDetails.sectionBanner.msn', {
                      name: selectedMsnBusinessItem.business.name ?? '',
                    })}
                  />
                  <MsnBusinessPreviewCard msnBusiness={selectedMsnBusinessItem.business} />
                </Group>
              </Container>
            )}
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

SearchBusinessScreen.displayName = 'AddVendorActivity.SearchBusinessScreen';
