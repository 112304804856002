import { screen } from '@testing-library/react';

export class SubscriptionSelectFundingSourceDriver {
  getComponent() {
    return screen.getByTestId('subscription-payment-method-select');
  }

  getFundingSourceCardById(fundingSourceId: string) {
    return screen.getByTestId(`funding-source-card-${fundingSourceId}`);
  }

  queryFundingSourceCardById(fundingSourceId: string) {
    return screen.queryByTestId(`funding-source-card-${fundingSourceId}`);
  }

  getFundingSourceInputById(fundingSourceId: string) {
    return screen.getByTestId(`funding-source-card-${fundingSourceId}-input`);
  }

  getSaveButton() {
    return screen.getByTestId('subscription-payment-method-select-save-button');
  }

  getFailureStateBanner() {
    return screen.getByTestId('subscription-select-funding-source-failure-state-banner');
  }
}

export class SubscriptionSelectFundingSourceCypressDriver {
  saveFundingSource() {
    return cy.getByTestId('subscription-payment-method-select-save-button').click();
  }

  getSubscriptionSelectFundingSourceSuccess() {
    return cy.getByTestId('subscription-select-funding-source-success');
  }

  clickSubscriptionSelectFundingSourceSuccessButton() {
    return cy.getByTestId('subscription-select-funding-source-success-button').click();
  }

  getSubscriptionSelectFundingSourceError() {
    return cy.getByTestId('subscription-select-funding-source-error');
  }
}
