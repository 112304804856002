import { useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useEffect } from 'react';

import { GuestPaymentIntentParams } from '../types';
import { useGuestPaymentIntent } from './useGuestPaymentIntent';

export const useUpdatedInvoiceSystemMessage = (guestPaymentIntentParams: GuestPaymentIntentParams) => {
  const { data: guestPaymentIntentData } = useGuestPaymentIntent({ guestPaymentIntentParams });

  const { triggerMessage } = useSystemMessage();
  const { formatMessage, formatDate } = useMelioIntl();

  useEffect(() => {
    if (
      guestPaymentIntentData?.vendorPaymentDetails &&
      !guestPaymentIntentData.receivablePaymentDetails &&
      guestPaymentIntentData.invoice.editedAt
    ) {
      triggerMessage({
        title: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
          updatedAtDate: formatDate(guestPaymentIntentData.invoice.editedAt),
          companyName: guestPaymentIntentData.vendorPaymentDetails.payeeDetails.companyName,
        }),
        type: 'informative',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guestPaymentIntentData]);
};
