import { useAccount, useGuestPayorSetup } from '@melio/ar-domain';
import { useEffect, VFC } from 'react';

import { useGuestPaymentIntent } from '../../hooks';
import { GuestPaymentIntentParams, GuestPayorUserNameDetails } from '../../types';

type OnboardingActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  userDetails: GuestPayorUserNameDetails;
  onDone: (vendorId: string, isMissingMcc: boolean, guestPaymentIntentId: string) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
};

export const OnboardingActivity: VFC<OnboardingActivityProps> = ({
  guestPaymentIntentParams,
  onDone,
  onError,
  onClose,
  userDetails,
}) => {
  const { create: createGuestPaymentIntent } = useGuestPaymentIntent({ guestPaymentIntentParams });
  const { setup } = useGuestPayorSetup();
  const { update: updateAccount } = useAccount({ id: 'me', enabled: false });

  const handleSetup = async () => {
    const {
      data: { guestPaymentIntentId },
    } = await createGuestPaymentIntent();
    const {
      data: { vendorId, isMissingMcc },
    } = await setup({ guestPaymentIntentId });

    return { guestPaymentIntentId, vendorId, isMissingMcc };
  };

  const updateUserDetails = () => updateAccount({ user: userDetails });

  useEffect(() => {
    Promise.all([handleSetup(), updateUserDetails()])
      .then(([{ vendorId, isMissingMcc, guestPaymentIntentId }]) =>
        onDone(vendorId, isMissingMcc, guestPaymentIntentId)
      )
      .catch((error) => {
        onClose?.();
        onError?.(error as ARPlatformError);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

OnboardingActivity.displayName = 'OnboardingActivity';
