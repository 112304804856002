import { PillProps } from '@melio/penny';
import { PaymentFee } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

const isFastFeeDelivery = (fee: PaymentFee) => {
  switch (fee.type) {
    case 'expedited-ach':
    case 'express-check':
    case 'rtp-organization':
    case 'overnight-check':
    case 'express-domestic-wire':
      return true;
  }

  return false;
};

type BadgeProps = Pick<PillProps, 'status' | 'label'>;

export const useFastDeliveryBadge = (paymentFees: PaymentFee[]): BadgeProps | undefined => {
  const { formatMessage } = useMelioIntl();

  if (paymentFees.some(isFastFeeDelivery)) {
    return {
      status: 'brand',
      label: formatMessage('widgets.deliveryDateListItemByPayor.fastBadgeText'),
    };
  }
  return void 0;
};
