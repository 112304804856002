/**
 * Represents a request to show a system message
 */
export type SystemMessageRequest<T = unknown> = Readonly<{
  /**
   * Type of the message request
   * Must be one of: 'success' | 'error' | 'informative'
   */
  type: 'success' | 'error' | 'informative';

  /**
   * Title of the message to display
   */
  title: string;

  /**
   * Optional content of the message
   */
  content?: string;

  /**
   * Optional metadata associated with the message
   */
  metadata?: T;

  /**
   * Optional unique identifier for the message
   * Can be used by target routes to handle specific message types
   * Example: 'payment-success', 'vendor-created', etc.
   */
  messageId?: string;
}>;

/**
 * Type for location state that includes a system message request
 */
export type LocationStateWithMessageRequest = Readonly<{
  systemMessageRequest?: SystemMessageRequest;
  [key: string]: unknown;
}>;

/**
 * Type guard to check if a value is a location state with a system message request
 */
export function isLocationStateWithMessageRequest(state: unknown): state is LocationStateWithMessageRequest {
  if (!state || typeof state !== 'object') {
    return false;
  }

  const possibleState = state as Record<string, unknown>;
  if (!('systemMessageRequest' in possibleState)) {
    return false;
  }

  const request = possibleState['systemMessageRequest'];
  if (!request || typeof request !== 'object') {
    return false;
  }

  const typedRequest = request as Record<string, unknown>;
  return (
    'type' in typedRequest &&
    typeof typedRequest['type'] === 'string' &&
    'title' in typedRequest &&
    typeof typedRequest['title'] === 'string'
  );
}
