import { ApiPagination, CustomersDashboardSortQueryParam, DashboardCustomer, useIsMobile } from '@melio/ar-domain';
import { Group, LoadingContainer } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import {
  CustomersEmptyState,
  CustomersTable,
  CustomersTableMobileToolbar,
  CustomersTableToolbar,
  CustomersTableZeroState,
} from '../components';
import { CustomerActions, SetCustomersDashboardParams } from '../types';

export type CustomersTableScreenProps = {
  customers: DashboardCustomer[];
  tableActions: CustomerActions;
  pagination: ApiPagination;
  isLoading?: boolean;
  isFetching?: boolean;
  search?: string;
  sortParams?: CustomersDashboardSortQueryParam;
  onParamsChange: SetCustomersDashboardParams;
  onCreateCustomer?: VoidFunction;
  onImportCustomers: VoidFunction;
  id?: string;
};
export const CustomersTabScreen = forwardRef<CustomersTableScreenProps>(
  (
    {
      customers,
      tableActions,
      pagination,
      isLoading,
      isFetching,
      onCreateCustomer,
      search = '',
      onParamsChange,
      sortParams,
      onImportCustomers,
      ...props
    },
    ref
  ) => {
    const isResultsEmpty = customers.length === 0 && !isFetching;
    const isZeroState = isResultsEmpty && !search;
    const isEmptyState = isResultsEmpty && search;

    useAnalyticsView('Dashboard', !isFetching, true, {
      ProductName: 'ar',
      PageName: 'dashboard-customers',
      CountInTab: pagination.totalCount,
      Intent: 'view-ar-customers',
    });

    const { track } = useAnalytics();
    const isMobile = useIsMobile();

    return (
      <LoadingContainer isLoading={isLoading}>
        <Group
          width="full"
          variant="vertical"
          spacing={isMobile ? 's' : 'm'}
          data-component={CustomersTabScreen.displayName}
          data-testid="customers-tab-screen"
          {...props}
          ref={ref}
        >
          {isMobile ? (
            <CustomersTableMobileToolbar
              search={search}
              onSearch={(searchTerm) => onParamsChange({ searchTerm })}
              sortParams={sortParams}
              onSort={(sortBy, sortOrder) => onParamsChange({ sortBy, sortOrder })}
              shouldDisplayFilters={!isZeroState}
            />
          ) : (
            <CustomersTableToolbar
              onImportCustomers={() => {
                track('Dashboard', 'Click', {
                  ProductName: 'ar',
                  PageName: 'dashboard-customers',
                  TabName: 'customers',
                  Flow: 'manage-customers',
                  Intent: 'upload-customer-file',
                  Cta: 'upload',
                });
                onImportCustomers();
              }}
              search={search}
              onSearch={(searchTerm) => onParamsChange({ searchTerm })}
              onCreateCustomer={() => {
                track('Dashboard', 'Click', {
                  ProjectName: 'ar',
                  PageName: 'dashboard',
                  TabName: 'customers',
                  Flow: 'add-customer',
                  Intent: 'create-customer',
                  Cta: 'new-customer',
                });
                onCreateCustomer?.();
              }}
              shouldDisplayFilters={!isZeroState}
            />
          )}

          <CustomersTable
            customers={customers}
            tableActions={tableActions}
            pagination={pagination}
            isLoading={isFetching}
            sortParams={sortParams}
            onSort={(sortBy, sortOrder) => onParamsChange({ sortBy, sortOrder })}
            shouldDisplayTable={!isZeroState && !isEmptyState}
          />

          {isZeroState && (
            <CustomersTableZeroState
              onCreateCustomer={onCreateCustomer}
              onImportCustomers={onImportCustomers}
              ref={ref}
              {...props}
            />
          )}
          {isEmptyState && (
            <CustomersEmptyState
              search={search}
              onClearSearch={() => onParamsChange({ searchTerm: '' })}
              ref={ref}
              {...props}
            />
          )}
        </Group>
      </LoadingContainer>
    );
  }
);
CustomersTabScreen.displayName = 'CustomersTabScreen';
