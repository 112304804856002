import { screen } from '@testing-library/react';

export class ReactivatePlanFundingSourceRequiredModalDriver {
  getModal() {
    return screen.queryByTestId('reactivate-plan-funding-source-required-modal');
  }
}
export class ReactivatePlanFundingSourceRequiredModalCypressDriver {
  getModal() {
    return cy.getByTestId('reactivate-plan-funding-source-required-modal');
  }
}
