import { Container, Group, SectionBanner, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export function FailedToDeliveryToBankAccountWarning({
  accountNumber,
  routingNumber,
}: {
  accountNumber: string | undefined;
  routingNumber: string | undefined;
}) {
  const { formatMessage } = useMelioIntl();
  return (
    <SectionBanner
      data-testid="failed-to-delivery-to-bank-account-warning"
      variant="warning"
      description={
        <Group variant="vertical" spacing="xxxs">
          {formatMessage(
            'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.line1'
          )}
          <Container paddingX="m">
            <ul>
              {!!routingNumber && (
                <li>
                  {formatMessage(
                    'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.routingNumber',
                    {
                      routingNumber: (
                        <Text
                          textStyle="body3Semi"
                          data-testid="failed-to-delivery-to-bank-account-warning-routing-number"
                        >
                          {routingNumber}
                        </Text>
                      ),
                    }
                  )}
                </li>
              )}
              {!!accountNumber && (
                <li>
                  {formatMessage(
                    'activities.editVendorBankDetails.screens.editVendorBankDetails.failedBankAccountWarning.accountNumber',
                    {
                      accountNumber: (
                        <Text
                          textStyle="body3Semi"
                          data-testid="failed-to-delivery-to-bank-account-warning-account-number"
                        >
                          {accountNumber}
                        </Text>
                      ),
                    }
                  )}
                </li>
              )}
            </ul>
          </Container>
        </Group>
      }
    />
  );
}
