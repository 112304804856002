import { DateRangeFilter, useMelioIntl } from '@melio/ar-domain';
import { Group, IconButton, NakedButton, SearchBar as DSSearchBar, Text } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { FilterParams, QueryParams } from '../types';
import { PaymentStatusFilter } from './PaymentStatusFilter';

export type FiltersToolBarProps = {
  search?: string;
  onSearch: (value: string) => void;
  selectedFilters: FilterParams;
  onParamsChange: (filters: QueryParams) => void;
  onClearFilters?: () => void;
  onExport?: VoidFunction;
};

export const FiltersToolBar = forwardRef<FiltersToolBarProps>(
  ({ onSearch, search, selectedFilters, onParamsChange, onClearFilters, onExport, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const {
      settings: { arDashboardSearchLabelDisabled },
    } = useConfig();
    const hideSearchLabel = arDashboardSearchLabelDisabled;

    return (
      <Group justifyContent="space-between" data-component="FiltersToolBar">
        <Group
          variant="horizontal"
          justifyContent="flex-start"
          alignItems="center"
          width="full"
          spacing="s"
          {...props}
          ref={ref}
        >
          <DSSearchBar
            data-testid="payments-table-search-input"
            value={search}
            onSearch={(value) => onSearch(String(value ?? ''))}
            label={
              hideSearchLabel
                ? undefined
                : formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.label')
            }
            placeholder={
              hideSearchLabel
                ? formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.placeholder')
                : undefined
            }
            onClear={() => onSearch('')}
            aria-label={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.aria-label')}
            instructionsText={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.searchInput.instructions')}
          />
          <Text {...{ minWidth: 'fit-content' }} textStyle="body3Semi" color="global.neutral.900">
            {formatMessage('ar.dashboard.activities.paymentsTable.filters.title')}
          </Text>
          <DateRangeFilter
            config={{
              filterLabel: formatMessage('ar.dashboard.activities.paymentsTable.filters.paymentDate.label'),
              presets: [
                'ThisMonth',
                'ThisWeek',
                'Last30Days',
                'PreviousMonth',
                'ThisQuarter',
                'PreviousQuarter',
                'ThisYear',
                'PreviousYear',
                'Custom',
              ],
            }}
            selectedFilterRange={selectedFilters.creationDateRange}
            selectedFilter={selectedFilters.creationDate}
            onApply={(dateRange, datePreset) =>
              onParamsChange({ creationDateRange: dateRange, creationDate: datePreset })
            }
            dataTestId="ar-dashboard-filter-menu-creationDateRange"
          />
          <PaymentStatusFilter
            selectedStatusFilters={selectedFilters.receivablePaymentStatus}
            onApply={(filter) => onParamsChange({ receivablePaymentStatus: filter })}
          />
          {!isEmpty(selectedFilters) && onClearFilters && (
            <NakedButton
              data-testid="payments-table-clear-filters"
              variant="secondary"
              label={formatMessage('ar.dashboard.activities.paymentsTable.filters.clear.label')}
              onClick={onClearFilters}
            />
          )}
        </Group>
        {onExport && (
          <IconButton
            onClick={onExport}
            aria-label={formatMessage('ar.dashboard.activities.paymentsTable.toolbar.export.label')}
            icon="download"
            data-testid="export-payments-button"
          />
        )}
      </Group>
    );
  }
);
FiltersToolBar.displayName = 'FiltersToolBar';
