import { StringSchema, useMelioIntl, yup } from '@melio/ar-domain';
import { createContext, useContext } from 'react';

import { BusinessDetailsForm, BusinessDetailsFormFields } from '../types';

export const BusinessDetailsFormContext = createContext<BusinessDetailsForm>({} as never);
export const useBusinessDetailsForm = () => useContext<BusinessDetailsForm>(BusinessDetailsFormContext);

export const useBusinessDetailsFormSchema = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    legalName: yup
      .string()
      .nullable()
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.validation.required'))
      .mtl_legalCompanyName(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalName.validation.invalid')
      ),

    businessType: yup
      .string()
      .nullable()
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.businessType.validation.required'))
      .mtl_validBusinessType(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.businessType.validation.invalid')
      ),

    isEin: yup.boolean().nullable(),

    taxIdNumber: yup
      .string()
      .nullable()
      .when('isEin', (isEin: boolean, schema: StringSchema) =>
        schema.mtl_validTaxId(
          { businessType: 'businessType', taxIdType: isEin ? 'EIN' : 'SSN' },
          formatMessage('ar.onboarding.activities.businessDetails.form.fields.taxIdNumber.validation.invalid')
        )
      )
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.taxIdNumber.validation.required')),

    legalDateOfBirth: yup
      .string()
      .nullable()
      .required(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalDateOfBirth.validation.required')
      )
      .when('isEin', (isEin: boolean, schema: StringSchema) =>
        schema.mtl_legalDateOfBirth(
          isEin ? 'EIN' : 'SSN',
          'MM/dd/yyyy',
          formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalDateOfBirth.validation.invalid')
        )
      ),

    legalAddress: yup
      .object()
      .nullable()
      .required(formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalAddress.validation.required'))
      .shape({ city: yup.string(), state: yup.string(), line1: yup.string(), postalCode: yup.string() })
      .mtl_validBusinessAddress(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.legalAddress.validation.invalid')
      ),

    industry: yup
      .object()
      .nullable()
      .shape({ naicsCode: yup.number(), name: yup.string() })
      .required(
        formatMessage('ar.onboarding.activities.businessDetails.form.fields.industry.validation.required')
      ) as yup.SchemaOf<BusinessDetailsFormFields['industry']>,
  }) as yup.SchemaOf<BusinessDetailsFormFields>;
};
