import {
  getFastestDeliveryPreferenceOption,
  useDeliveryPreferenceOption,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import { Form, Group, useMelioForm } from '@melio/penny';
import { DeliveryPreference, FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import * as yup from 'yup';

type Fields = {
  deliveryPreferenceType: DeliveryPreference['type'];
};

export type DeliveryPreferenceFormProps = {
  fundingSource: FundingSource;
  deliveryPreferences: DeliveryPreference[];
  deliveryPreferenceType: DeliveryPreference['type'];
  withTitle?: boolean;
  isDisabled?: boolean;
  onChange: (type: DeliveryPreference['type']) => unknown;
};

const schema = yup.object().shape({
  deliveryPreferenceType: yup.string().required(),
}) as yup.SchemaOf<Fields>;

export const DeliveryPreferenceForm = forwardRef<DeliveryPreferenceFormProps, 'form'>(
  (
    { deliveryPreferences, fundingSource, isDisabled, onChange, deliveryPreferenceType, withTitle = true, ...props },
    ref
  ) => {
    const { registerField, watch } = useMelioForm({
      schema,
      onSubmit: () => null,
      defaultValues: { deliveryPreferenceType },
      subscribeToDefaultValuesChanges: true,
    });
    const { formatMessage } = useMelioIntl();
    const { getOption } = useDeliveryPreferenceOption();
    const { scheduleBy } = usePaymentSchedulingPreference();

    const fastestOption = getFastestDeliveryPreferenceOption(deliveryPreferences);
    useEffect(() => {
      const subscription = watch(({ deliveryPreferenceType }) => onChange(deliveryPreferenceType as never));
      return () => subscription.unsubscribe();
    }, [watch, onChange]);

    return (
      <Form
        data-component="DeliveryPreferenceForm"
        data-testid="delivery-preference-form"
        {...props}
        ref={ref}
        isDisabled={isDisabled}
      >
        <Group variant="vertical">
          <Form.RadioGroup
            {...registerField('deliveryPreferenceType')}
            {...(withTitle
              ? {
                  labelProps: {
                    label: formatMessage(`widgets.deliveryDate.deliveryPreferenceForm.${scheduleBy}.title`),
                  },
                }
              : { 'aria-label': 'deliveryPreferenceType' })}
            options={deliveryPreferences.map((deliveryPreference) => getOption(deliveryPreference, fastestOption))}
            variant="vertical"
          />
        </Group>
      </Form>
    );
  }
);
DeliveryPreferenceForm.displayName = 'DeliveryPreferenceForm';
