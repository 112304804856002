/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Container, Group, Table, TableProps, Text, useBreakpoint } from '@melio/penny';
import { StickyHorizontalScroller } from '@melio/platform-sdk';

type APTableProps<T> = TableProps<T> & {
  captionLabel?: string;
};

export function APTable<T>({ captionLabel, ...rest }: APTableProps<T>) {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group variant="vertical" width="full" spacing="s">
      {captionLabel && !isExtraSmallScreen ? (
        <Container paddingLeft="s">
          <Text id={rest.captionId} textStyle="body3Semi" color="global.neutral.800">
            {captionLabel}
          </Text>
        </Container>
      ) : null}
      <StickyHorizontalScroller isVisible={!rest.isLoading}>
        <Table
          {...rest}
          overflowX={rest.isLoading ? 'hidden' : undefined}
          overflowY={rest.isLoading ? 'hidden' : undefined}
        />
      </StickyHorizontalScroller>
    </Group>
  );
}
