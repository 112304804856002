import { useIsMobile } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { FilterParams, OnSort, QueryParams, SortParams } from '../types';
import { FiltersToolBar } from './FiltersToolbar';
import { FiltersToolBarMobile } from './FiltersToolBar.mobile';

export type PaymentsTableToolbarProps = {
  isLoading?: boolean;
  searchTerm?: string;
  onSearch: (value: string) => void;
  onParamsChange: (filters: QueryParams) => void;
  filters: FilterParams;
  onClearFilters?: () => void;
  onExport?: VoidFunction;
  onSort?: OnSort;
  sortParams?: SortParams;
  shouldDisplayFilters?: boolean;
};

export const PaymentsTableToolbar = forwardRef<PaymentsTableToolbarProps>(
  (
    {
      searchTerm,
      onSearch,
      filters,
      onParamsChange,
      onClearFilters,
      onSort,
      sortParams,
      shouldDisplayFilters = true,
      onExport,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();

    if (!shouldDisplayFilters) return null;

    return !isMobile ? (
      <FiltersToolBar
        data-component={PaymentsTableToolbar.displayName}
        search={searchTerm}
        onSearch={onSearch}
        selectedFilters={filters}
        onParamsChange={onParamsChange}
        onClearFilters={onClearFilters}
        onExport={onExport}
        {...props}
        ref={ref}
      />
    ) : (
      <FiltersToolBarMobile
        data-component={PaymentsTableToolbar.displayName}
        selectedFilter={filters.receivablePaymentStatus?.[0]}
        onSelectFilter={(newFilters) => onParamsChange(newFilters)}
        search={searchTerm}
        onSearch={onSearch}
        onSort={onSort}
        sortParams={sortParams}
        {...props}
        ref={ref}
      />
    );
  }
);

PaymentsTableToolbar.displayName = 'PaymentsTableToolbar';
