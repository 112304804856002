import { Container, Group, SearchBar, Switch, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-provider';

import { AddNewClientButton } from '../AddNewClientButton/AddNewClientButton';
import { useFirmClientsTableContext } from './FirmClientsTableContext';

export const ClientsFilters = () => {
  const {
    isLoading,
    filters: { searchText, showHiddenClients },
    handlers: { handleInputChange, handleToggleHiddenClients },
  } = useFirmClientsTableContext();
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();

  const isSmallVariant = isExtraSmallScreen || isSmallScreen;

  return (
    <Container paddingX={['s', null, '0'] as never} overflow="initial">
      <Group variant="horizontal">
        <Group.Item grow={1}>
          <Group variant={isSmallVariant ? 'vertical' : 'horizontal'} spacing="s" alignItems="center">
            <Container maxWidth={isSmallVariant ? undefined : '200'} overflow="initial">
              <SearchBar
                isFullWidth
                placeholder={formatMessage('activities.accountants.firmDashboard.v2.tableFilters.search.placeholder')}
                isDisabled={isLoading}
                value={searchText}
                onChange={handleInputChange}
              />
            </Container>
            <Container>
              <Switch
                label={formatMessage(
                  'activities.accountants.firmDashboard.v2.tableFilters.showHiddenClientsSwitcher.label'
                )}
                isDisabled={isLoading}
                value={!!showHiddenClients}
                onChange={handleToggleHiddenClients}
                size="large"
              />
            </Container>
          </Group>
        </Group.Item>
        <Group.Item>
          <Container justifyContent="flex-end">
            <AddNewClientButton isDisabled={isLoading} />
          </Container>
        </Group.Item>
      </Group>
    </Container>
  );
};
