import { useModelApi } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountingClientsOverviewScopeEnum, AccountingFirmClientApiClient } from '@melio/platform-api-axios-client';

type QueryFN = () => ReturnType<typeof AccountingFirmClientApiClient.getAccountingClientsOverview>;

export const useClientsOverview = (params: { scope: AccountingClientsOverviewScopeEnum }) =>
  useModelApi<QueryFN, never, never, never>({
    id: 'me',
    queryKey: ['AccountantsFirmClientApi', 'ClientsOverview', params],
    queryFn: () => AccountingFirmClientApiClient.getAccountingClientsOverview(params.scope),
  });
