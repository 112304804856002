import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useGuestPaymentIntent, usePaymentRequestStatus } from '../../hooks';
import { GuestPaymentIntentParams } from '../../types';
import { InvoicePaidScreen } from './screens';

export type InvoicePaidActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
};

export const InvoicePaidActivity = forwardRef<InvoicePaidActivityProps>(
  ({ guestPaymentIntentParams, onViewInvoice, onError }, ref) => {
    const { isLoading: isGuestPaymentIntentLoading, data } = useGuestPaymentIntent({
      guestPaymentIntentParams,
      onError,
    });

    const { status } = usePaymentRequestStatus(guestPaymentIntentParams);
    useEffect(() => {
      if (status && status != 'paid') onError?.({ code: 'UNKNOWN_ERROR', message: 'Unknown error' });
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <InvoicePaidScreen
        isLoading={isGuestPaymentIntentLoading || !data?.receivablePaymentDetails}
        amount={data?.invoice.totalAmount}
        invoicePreviewUrl={data?.invoice.fileInfo?.previewUrls[0]}
        isMarkedAsPaid={data?.receivablePaymentDetails?.source === 'manual'}
        onViewInvoice={onViewInvoice}
        backgroundColor={data?.vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
        ref={ref}
      />
    );
  }
);

InvoicePaidActivity.displayName = 'InvoicePaidActivity';
