import { Vendor, VendorEBillStatusEnum } from '@melio/platform-api';

import { PaymentDetails } from './PaymentsScheduled.types';

export const getSinglePaymentDetails = (payments: PaymentDetails[]) => {
  if (payments.length > 1 || !payments.length) {
    return;
  }
  return payments[0];
};

export const isVendorCapableEBillSubscription = (vendor?: Vendor) =>
  vendor?.eBillStatus === VendorEBillStatusEnum.Capable;

export const isRtpDeliveryPreferenceTypePayment = (payment: PaymentDetails | undefined): boolean =>
  !!payment && payment.deliveryPreferenceType === 'rtp';
