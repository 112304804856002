import { Container, Group, Link, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

export type TodoSectionProps = {
  type: string;
  icon: JSX.Element;
  title: string;
  action: { label: string; onClick: (e: React.MouseEvent) => void };
};

export const TodoSection = withAnalyticsContext<TodoSectionProps>(({ icon, title, action, type }: TodoSectionProps) => {
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    track('Dashboard', 'Click', { Cta: type });
    action.onClick(e);
  };

  const renderTitle = () => (
    <Group>
      <Text textStyle="body3Semi" data-testid="title">
        {isExtraSmallScreen ? (
          <Link href="#" onClick={onClick} label={title} size="medium" color="secondary" data-testid="title-mobile" />
        ) : (
          title
        )}
      </Text>
    </Group>
  );

  return (
    <Container data-testid={`todo-section-${type}`} paddingY="xxs" paddingX="xxs">
      <Group variant="horizontal" justifyContent="space-between" width="full" height="full" alignItems="center">
        <Group.Item>
          <Group alignItems="center">
            {icon}
            <Group variant="vertical" spacing="xxs">
              {renderTitle()}
            </Group>
          </Group>
        </Group.Item>

        <Group.Item shrink={0}>
          <Group alignItems="center">
            {isExtraSmallScreen ? null : (
              <Text textStyle="body3Semi">
                <Link
                  href="#"
                  onClick={onClick}
                  label={action.label}
                  aria-label={formatMessage('widgets.todosDrawer.section.actionsAriaLabel', {
                    action: action.label,
                    title,
                  })}
                  size="medium"
                  color="secondary"
                  data-testid="action"
                />
              </Text>
            )}
          </Group>
        </Group.Item>
      </Group>
    </Container>
  );
});
