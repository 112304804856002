import { Container, FloatingMenu, Group, Icon, IconButton, Link, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';

export const HelpMenu = () => {
  const [isHelpMenuOpened, setIsHelpMenuOpened] = useState<boolean>(false);
  const { goToSupportPage } = useAccountantsRoutes();
  const { formatMessage } = useMelioIntl();

  const helpMenuItems: {
    id: string;
    label: string;
    description: string;
    link: React.ReactNode;
    leftElement: React.ReactNode;
  }[] = [
    {
      id: 'help-center',
      leftElement: <Icon type="help-circle" size="large" />,
      label: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.helpCenter.linkText'),
      description: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.helpCenter.description'),
      link: (
        <Link
          color="secondary"
          href="#"
          label={formatMessage('activities.accountants.firmDashboard.v2.helpMenu.helpCenter.linkText')}
          variant="standalone"
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            // TODO: change link - will be provided later
            goToSupportPage();
          }}
        />
      ),
    },
    {
      id: 'contact-support',
      leftElement: <Icon type="support" size="large" />,
      label: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.contactSupport.label'),
      description: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.contactSupport.description'),
      link: (
        <Link
          color="secondary"
          href="#"
          label={formatMessage('activities.accountants.firmDashboard.v2.helpMenu.contactSupport.linkText')}
          variant="standalone"
          size="medium"
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            goToSupportPage();
          }}
        />
      ),
    },
    {
      id: 'melio-academy',
      leftElement: <Icon type="book" size="large" />,
      label: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.melioAcademy.label'),
      description: formatMessage('activities.accountants.firmDashboard.v2.helpMenu.melioAcademy.description'),
      link: (
        <Link
          color="secondary"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            // TODO: change link - will be provided later
            goToSupportPage();
          }}
          size="medium"
          label={formatMessage('activities.accountants.firmDashboard.v2.helpMenu.melioAcademy.linkText')}
          variant="standalone"
        />
      ),
    },
  ];

  return (
    <FloatingMenu
      isOpen={isHelpMenuOpened}
      onOpenChange={setIsHelpMenuOpened}
      width="362px"
      maxHeight="500px"
      trigger={<IconButton aria-label="Toggle help menu" icon="help-circle" size="large" variant="primary" />}
      content={helpMenuItems.map((item) => (
        <Container key={`help-menu-item-${item.id}`} paddingX="m" paddingY="m">
          <Group data-testid={item.id}>
            {item.leftElement}
            <Group variant="vertical" spacing="xxs">
              <Text textStyle="body3Semi" color="semantic.text.primary">
                {item.label}
              </Text>
              <Text textStyle="body4" color="semantic.text.secondary">
                {item.description}
              </Text>
              {item.link}
            </Group>
          </Group>
        </Container>
      ))}
    />
  );
};
