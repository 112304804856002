import { Button, Container, Grid, Group, Icon, Illustration, LoadingContainer, NakedButton, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';
import { Navigate } from 'react-router-dom';

import { useAcceptSubscriptionDowngrade } from '../../../api/entities/subscriptions/useAcceptSubscriptionDowngrade';
import { useIsSubscriptionsEnabled, usePlanInfo, useSubscription } from '../../../hooks';
import { useSubscriptionRouter } from '../../utils';
import { useDowngradeLostFeaturesList } from './hooks/useDowngradeLostFeaturesList';

export const ConfirmDowngradeOwnerScreen = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { formatMessage } = useMelioIntl();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });
  const { planName: newPlanName } = usePlanInfo(subscription?.planId);
  const { goToSubscriptionPlans } = useSubscriptionRouter();
  const { getLostFeaturesByPreviousPlanTier } = useDowngradeLostFeaturesList();
  const lastPaidSubscription = subscription?.lastPaidSubscription;
  const {
    acceptDowngrade,
    isError: isAcceptDowngradeError,
    isLoading: isAcceptDowngradeLoading,
    isSuccess: isAcceptDowngradeSuccess,
    isDowngradeCompleted,
  } = useAcceptSubscriptionDowngrade();
  const { track } = useAnalytics();

  const shouldShowLoadingIndicator = isAcceptDowngradeLoading || isAcceptDowngradeSuccess;
  setAnalyticsProperties({
    PageName: 'your-plan-has-been-downgraded',
    Flow: 'subscription-downgrade',
    Intent: 'subscription-downgrade',
  });

  useAnalyticsView('Organization', true, true);

  if (!lastPaidSubscription) {
    return <Navigate to="/pay-dashboard" />;
  }

  const companyName = accountData?.company.name;
  const lostFeatures = getLostFeaturesByPreviousPlanTier(lastPaidSubscription.planTier);

  if (!isSubscriptionsEnabled || isDowngradeCompleted) {
    return <Navigate to="/pay-dashboard" />;
  }

  if (isAcceptDowngradeError) {
    return <Navigate to="/error" />;
  }

  return (
    <Layout maxWidth="600px" backgroundColor="white">
      {/* Waiting for isAcceptDowngradeSuccess to show loading indicator when navigating out of this page */}
      <LoadingContainer isLoading={shouldShowLoadingIndicator}>
        <Container
          paddingX="xxs"
          paddingY="xxs"
          height="full"
          textAlign="center"
          data-testid="subscription-confirm-downgrade"
        >
          <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="m">
            <Illustration size="large" type="blocked" />
            <Grid gap="s">
              <PageTitle textStyle="heading2Semi">
                {formatMessage('activities.subscription.planDowngraded.title')}
              </PageTitle>
              <Text color="global.neutral.900" data-testid="subscription-confirm-downgrade-description">
                {formatMessage('activities.subscription.planDowngraded.description', {
                  companyName,
                  planName: newPlanName,
                })}
              </Text>
            </Grid>

            <Container paddingY="s" color="global.critical.700">
              <Group variant="vertical" spacing="xs">
                {lostFeatures.map((item) => (
                  <Group alignItems="center" spacing="xs" key={item}>
                    <Icon type="cancel-circle" color="inherit" size="small" aria-hidden="true" />
                    <Text textStyle="body3" color="global.neutral.900">
                      {item}
                    </Text>
                  </Group>
                ))}
              </Group>
            </Container>

            <Button
              isFullWidth
              label={formatMessage('activities.subscription.planDowngraded.seeUpgradeOptions')}
              data-testid="subscription-confirm-downgrade-see-upgrade-options-button"
              variant="primary"
              size="large"
              onClick={() => {
                track('Organization', 'Click', {
                  Intent: 'upgrade-plan',
                  Cta: 'upgrade-plan',
                });
                goToSubscriptionPlans({
                  analyticsEntryPoint: 'plan-downgraded-modal',
                  analyticsFlow: 'subscription-downgrade',
                });
              }}
            />

            <NakedButton
              label={formatMessage('activities.subscription.planDowngraded.keepLimitedFunctionality')}
              data-testid="subscription-confirm-downgrade-keep-limited-functionality-button"
              size="medium"
              onClick={async () => {
                track('Organization', 'Click', {
                  Intent: 'exit',
                  Cta: 'exit',
                });
                await acceptDowngrade();
              }}
            />
          </Grid>
        </Container>
      </LoadingContainer>
    </Layout>
  );
});
