import { Modal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

export type ReactivatePlanFundingSourceRequiredModalProps = {
  isOpen: boolean;
  onClose: () => void;
  flowAnalyticsProperties: Record<string, string>;
};

export const ReactivatePlanFundingSourceRequiredModal =
  withAnalyticsContext<ReactivatePlanFundingSourceRequiredModalProps>(
    ({ isOpen, onClose, flowAnalyticsProperties, setAnalyticsProperties }) => {
      setAnalyticsProperties({
        PageName: 'cant-reactivate-subscription',
        ...flowAnalyticsProperties,
      });

      const { track } = useAnalytics();
      const { formatMessage } = useMelioIntl();
      useAnalyticsView('Settings', isOpen, true);

      return (
        <Modal
          data-testid="reactivate-plan-funding-source-required-modal"
          closeButtonAriaLabel={formatMessage('activities.subscription.reactivationPlanFundingSourceRequiredModal.ok')}
          isOpen={isOpen}
          onClose={() => {
            track('Settings', 'Click', {
              Cta: 'exit',
            });
            onClose();
          }}
          header={formatMessage('activities.subscription.reactivationPlanFundingSourceRequiredModal.title')}
          primaryButton={{
            label: formatMessage('activities.subscription.reactivationPlanFundingSourceRequiredModal.ok'),
            onClick: () => {
              track('Settings', 'Click', {
                Cta: 'ok',
              });
              onClose();
            },
            variant: 'primary',
          }}
        >
          <Text>{formatMessage('activities.subscription.reactivationPlanFundingSourceRequiredModal.description')}</Text>
        </Modal>
      );
    }
  );
