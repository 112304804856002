import { screen } from '@testing-library/react';

const contextualPaywallModalScreenTestId = 'contextual-paywall-modal-screen';
const accountUpgradeRequestModalScreenTestId = 'request-subscription-upgrade-modal';
const upgradeButtonTestId = 'contextual-paywall-card-upgrade-button';

export class ContextualPaywallModalActivityCypressDriver {
  assertContextualPaywallVisible = () => cy.getByTestId(contextualPaywallModalScreenTestId).should('be.visible');
  assertAccountUpgradeRequestVisible = () =>
    cy.getByTestId(accountUpgradeRequestModalScreenTestId).should('be.visible');

  assetContextualPaywallNotVisible = () => cy.getByTestId(contextualPaywallModalScreenTestId).should('not.exist');

  close = () => cy.getByTestId('modal-close-button').click();

  assertClosed = () => {
    cy.getByTestId(contextualPaywallModalScreenTestId).should('not.exist');
    cy.getByTestId(accountUpgradeRequestModalScreenTestId).should('not.exist');
  };

  upgradePlan = () => cy.getByTestId(upgradeButtonTestId).click();
}

export class ContextualPaywallModalActivityJestDriver {
  getModal = () => screen.getByTestId(contextualPaywallModalScreenTestId);
  upgradePlan = () => screen.getByTestId(upgradeButtonTestId).click();
}
