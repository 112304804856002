import { screen, within } from '@testing-library/react';

export const getVipModal = () => screen.queryByTestId('vip-welcome-modal');
export const getVipModalPrimaryButton = () => screen.getByTestId('modal-btn-primary');
export const getVipModalHeader = () =>
  within(screen.getByTestId('vip-welcome-modal')).getByTestId('vip-welcome-modal-header-text');
export const getVipLearnMoreLink = () =>
  within(screen.getByTestId('vip-welcome-modal')).queryByTestId('vip-learn-more-link');

export class VipWelcomeModalCypressDriver {
  getVipModal = () => cy.getByTestId('vip-welcome-modal');
  getVipModalPrimaryButton = () => cy.getByTestId('modal-btn-primary');
}
