import { identity, pickBy } from 'lodash';
import { CheckAccountFormModel } from '@melio/ap-widgets';
import { getAddressPayload } from '@melio/ap-widgets/src/components/Vendors/AddVendor/AddVendorForm/components/AddUnmanagedVendorForm/presets/AddUnmanagedVendorFormMinimal/utils';
import { Address } from '@melio/platform-api';

import { VendorDetailsFormFields } from '@/types/vendors.types';

export const getUpdateVendorPayload = ({
  data,
  shouldCollectAddress,
  shouldCollectCountry,
}: {
  data: VendorDetailsFormFields;
  shouldCollectAddress: boolean;
  shouldCollectCountry: boolean;
}) => {
  const { companyName, fullName, email, phone, accountNumber } = data;

  const address: Address | null = getAddressPayload({
    data,
    shouldCollectAddress,
    shouldCollectCountry,
  });

  const contact = pickBy(
    {
      name: fullName,
      email,
      phoneNumber: phone,
      address,
    },
    identity,
  );

  return {
    name: companyName || '',
    contact,
    accountNumber: accountNumber || null,
  };
};

export const getUpdatePaperCheckPayload = (data: VendorDetailsFormFields): CheckAccountFormModel => {
  const { companyName, line1, line2, city, state, postalCode, countryCode } = data;

  const address = {
    printName: companyName ?? '',
    line1: line1 ?? '',
    line2: line2 ?? '',
    city: city ?? '',
    state: state ?? '',
    postalCode: postalCode ?? '',
    countryCode: countryCode ?? '',
  };

  return address;
};
