import { Button, Container, Grid, Illustration, LoadingContainer, Text } from '@melio/penny';
import { useAccount, useOrganizations } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { PageTitle, useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled } from '../../../hooks';

type SwitchOrganizationParams = {
  organizationId: string;
  switchAccessTokenOnly?: boolean;
  isAccountingFirm: boolean;
  loadingState?: 'default' | 'always' | 'none';
};

type GoToAssignClientPlanParams = {
  accountingClientId: string;
  refresh: boolean;
};

export type AccountingFirmClientMandatoryPlanRestrictionProps = {
  goToAssignClientPlan: (props: GoToAssignClientPlanParams) => void;
  switchOrganization: (props: SwitchOrganizationParams) => Promise<void>;
};

export const AccountingFirmClientMandatoryPlanRestriction = ({
  goToAssignClientPlan,
  switchOrganization,
}: AccountingFirmClientMandatoryPlanRestrictionProps) => {
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });
  const { data: organizations = [], isLoading } = useOrganizations();

  const accountingFirmOrganization = !isLoading
    ? organizations.find((org) => org.companyType === 'accounting-firm')
    : undefined;

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  const companyName = accountData?.company.name;

  const onHandleClick = async () => {
    setIsSwitching(true);
    try {
      const accountingFirmOrganizationId = accountingFirmOrganization?.id;
      const accountingClientId = accountData?.organizationId;
      if (!accountingFirmOrganizationId || !accountingClientId) {
        throw new Error('Missing AccountingFirmOrganizationId or accountingClientId');
      }
      await switchOrganization({
        organizationId: accountingFirmOrganizationId,
        switchAccessTokenOnly: true,
        isAccountingFirm: false,
        loadingState: 'always',
      });
      goToAssignClientPlan({ accountingClientId, refresh: true });
    } catch (error) {
      setIsSwitching(false);
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.changeClientPlan.toast.error'),
      });
      Logger.log(`Error while trying switch to accounting firm organization: ${(error as Error).message}`);
    }
  };

  return (
    <LoadingContainer isLoading={isSwitching}>
      <Layout data-testid="subscription-grace-end-period-accounting-firm" maxWidth="600px" backgroundColor="white">
        <Container
          paddingX="xxs"
          paddingY="xxs"
          height="full"
          textAlign="center"
          data-testid="subscription-grace-end-period-non-owner"
        >
          <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="s">
            <Illustration size="large" type="blocked" />
            <Grid gap="s">
              <PageTitle data-testid="subscription-grace-end-period-accounting-firm-title" textStyle="heading2Semi">
                {formatMessage('activities.subscription.graceEndPeriod.accountingFirm.title')}
              </PageTitle>

              <Text color="global.neutral.900" data-testid="subscription-grace-end-period-non-owner-description">
                {formatMessage('activities.subscription.graceEndPeriod.accountingFirm.description', {
                  companyName,
                })}
              </Text>
              <Container>
                <Button
                  data-testid="subscription-grace-end-period-accounting-firm-button"
                  label={formatMessage('activities.subscription.graceEndPeriod.accountingFirm.action.primary')}
                  onClick={() => void onHandleClick()}
                />
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </LoadingContainer>
  );
};
