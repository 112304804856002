import { forwardRef } from '@melio/platform-utils';

import { usePaymentRequestStatus } from '../../hooks';
import { ErrorType, GuestPaymentIntentParams } from '../../types';
import { GuestPaymentRequestErrorScreen } from './screens';

type GuestPaymentRequestErrorsActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onError?: ARErrorFunction;
};

export const GuestPaymentRequestErrorsActivity = forwardRef<GuestPaymentRequestErrorsActivityProps>(
  ({ guestPaymentIntentParams, onError, ...props }, ref) => {
    const { status, isLoading } = usePaymentRequestStatus(guestPaymentIntentParams);
    const errorType = status == 'cancelled' ? ErrorType.INVOICE_CANCELLED : ErrorType.GENERAL_ERROR;

    return <GuestPaymentRequestErrorScreen isLoading={isLoading} errorType={errorType} {...props} ref={ref} />;
  }
);

GuestPaymentRequestErrorsActivity.displayName = 'GuestPaymentRequestErrorsActivity';
