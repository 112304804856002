import { BrandSymbolKey } from '@melio/penny';
import {
  AccountingPlatform,
  AccountingPlatformSlug,
  AccountingSoftwareDuplicatedVendorMatch,
  InternationalAddress,
  PartnerName,
} from '@melio/platform-api';
import { isEmpty } from 'lodash';

export const getAccountingPlatformNameForAnalytics = (
  accountingSlug?: AccountingPlatform['accountingSlug']
): string => {
  if (!accountingSlug) {
    return '';
  }

  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
      return 'qbo';
    case AccountingPlatformSlug.Xero:
      return 'xero';
    case AccountingPlatformSlug.QuickBooksDesktop:
      return 'qbdt';
    default:
      return '';
  }
};

export const getSyncMonitoringActionName = (accountingSlug?: AccountingPlatformSlug, isFirstSync?: boolean): string => {
  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
      return isFirstSync ? 'first_accounting_software_sync_quickbooks' : 'accounting_software_sync_quickbooks';
    case AccountingPlatformSlug.QuickBooksDesktop:
      return isFirstSync
        ? 'first_accounting_software_sync_quickbooks_desktop'
        : 'accounting_software_sync_quickbooks_desktop';
    case AccountingPlatformSlug.Xero:
      return isFirstSync ? 'first_accounting_software_sync_xero' : 'accounting_software_sync_xero';
    default:
      return '';
  }
};

export const getAccountingPlatformBrandSymbolType = (
  accountingSlug?: AccountingPlatform['accountingSlug']
): BrandSymbolKey => {
  switch (accountingSlug) {
    case AccountingPlatformSlug.QuickBooksOnline:
    case AccountingPlatformSlug.QuickBooksDesktop:
      return 'quickbooks';
    case AccountingPlatformSlug.Xero:
      return 'xero';
    default:
      return 'melio';
  }
};

export const formatPhone = (phone?: string) => {
  if (!phone) {
    return undefined;
  }

  const [, partOne, partTwo, partThree] = phone.match(/(\d{1,3})(\d{0,3})(\d*)/) || [];
  if (!partOne || !partTwo) {
    return phone;
  }

  return `(${partOne}) ${partTwo}${partThree ? '-' + partThree : ''}`;
};

const mapVendorToMatchingVendorEntity = (
  vendor: {
    id: string;
    name: string;
    nickname?: string;
    accountNumber?: string;
    contact: {
      phoneNumber?: string;
      email?: string | null;
      address?: InternationalAddress | null;
    };
  },
  brandSymbolKey: BrandSymbolKey,
  providerName?: string
) => {
  const phoneNumber = !isEmpty(vendor.contact.phoneNumber) ? formatPhone(vendor.contact.phoneNumber) : undefined;
  const email = !isEmpty(vendor.contact.email) ? vendor.contact.email : undefined;
  const address = vendor.contact.address
    ? `${vendor.contact.address.line1}, ${vendor.contact.address.city}, ${vendor.contact.address.state}, ${vendor.contact.address.postalCode}`
    : undefined;

  const accountNumber = vendor.accountNumber ? `Account ****${vendor.accountNumber.slice(-4)}` : undefined;
  const nickname = vendor.nickname ? `Nickname ${vendor.nickname}` : undefined;

  return {
    id: vendor.id,
    brandSymbolKey,
    title: vendor.name,
    subTitles: [nickname, accountNumber],
    bodyItems: [phoneNumber, email ?? undefined, address],
    providerName,
  };
};

type MatchingVendorsEntity = {
  id: string;
  entityA: {
    id: string;
    brandSymbolKey: BrandSymbolKey;
    title: string;
    subTitles: (string | undefined)[];
    bodyItems: (string | undefined)[];
    providerName?: string;
  };
  entityB: {
    id: string;
    brandSymbolKey: BrandSymbolKey;
    title: string;
    subTitles: (string | undefined)[];
    bodyItems: (string | undefined)[];
    providerName?: string;
  };
  confidence: AccountingSoftwareDuplicatedVendorMatch['confidence'];
};

const mapPartnerNameToBrandSymbolKey = (partnerSymbolKey: PartnerName) => {
  let partnerName: BrandSymbolKey = partnerSymbolKey as BrandSymbolKey;
  const partnerNameArray = partnerSymbolKey.split('_');
  if (partnerNameArray?.[0] === PartnerName.Fiserv && partnerNameArray?.[1]) {
    partnerName = partnerNameArray?.[1] as BrandSymbolKey;
  }
  return partnerName;
};

export const transformDuplicateVendorsResponseForMatching = (
  partnerName: PartnerName,
  partnerDisplayName: string,
  accountingSoftwareBrandSymbolKey: BrandSymbolKey,
  accountingPlatformName?: string,
  duplicateVendors?: AccountingSoftwareDuplicatedVendorMatch[]
): MatchingVendorsEntity[] => {
  if (!duplicateVendors) {
    return [];
  }

  return duplicateVendors.map((match) => ({
    id: `${match.localVendor.id}+${match.externalVendor.id}`,
    entityA: mapVendorToMatchingVendorEntity(
      match.localVendor,
      mapPartnerNameToBrandSymbolKey(partnerName),
      partnerDisplayName
    ),
    entityB: mapVendorToMatchingVendorEntity(
      match.externalVendor,
      accountingSoftwareBrandSymbolKey,
      accountingPlatformName
    ),
    confidence: match.confidence,
  }));
};
