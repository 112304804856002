import { Box } from '@chakra-ui/react';
import { Button, Container, Form, Group, Link, SectionBanner, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { Plan, Tier } from '@melio/subscriptions';
import { useGetOffer } from '@melio/subscriptions/src/api';
import { useLearnMoreLink, usePlansTiers } from '@melio/subscriptions/src/hooks';
import { useState } from 'react';

import { FirmClientSubscriptionPlanSelectionActivity } from '../../../activities/FirmClientSubscriptionPlanSelection';
import { FIRM_DISCOUNT_PERCENTAGE_DEFAULT } from '../../../consts';
import { useFocusStepOnError } from '../../../hooks/useFocusStepOnError';
import { AccountingClientPlanSelectionStepDiscountBanner } from '../../AccountingClientPlanSelectionStepDiscountBanner/AccountingClientPlanSelectionStepDiscountBanner';
import { ManageClientFormFields, StepFormProps } from '../manage-client-form.types';
import { useManageClientFormContext } from '../ManageClientFormContext';

const stepFields: Array<keyof ManageClientFormFields> = ['subscriptionPlanId'];

export const ManageClientPlanSelectionStep = withAnalyticsContext<StepFormProps>(
  ({ submitLabel, focusErrorStep, onContinue, isSaving, setAnalyticsProperties }) => {
    const [currentPlanTier, setCurrentPlanTier] = useState<Tier>();
    const {
      form: { formState, setValue, watch, registerField, submitButtonProps },
    } = useManageClientFormContext();
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const learnMoreLink = useLearnMoreLink();
    const clientPlans = usePlansTiers().getClientPlans();
    const { data: activePlanOffer, isLoading: isActiveOfferLoading } = useGetOffer({
      id: clientPlans[0]?.offerId || '',
    });
    const planDiscountPercentage = activePlanOffer?.integratedDiscount || FIRM_DISCOUNT_PERCENTAGE_DEFAULT;

    useFocusStepOnError<ManageClientFormFields>({
      formState,
      stepFields,
      focusErrorStep,
    });

    setAnalyticsProperties({
      Intent: 'subscribe',
      BillingCycle: 'monthly',
    });

    const validationErrorMessage = formState.errors.subscriptionPlanId?.message;

    const verifyPlanSelection = () => {
      track('Organization', 'Click', {
        Cta: 'continue',
        PlanChosen: currentPlanTier,
      });

      onContinue();
    };

    const handleSelectSubscriptionPlanId = ({ id: planId, tier }: Plan) => {
      track('Organization', 'Click', {
        Cta: 'subscribe',
        PlanChosen: tier,
      });
      setValue('subscriptionPlanId', planId);
      setCurrentPlanTier(tier);
    };

    const handleOnLearnMoreClick = () => {
      track('Organization', 'Click', {
        Cta: 'learn-more',
      });
    };

    return (
      <Group spacing="s" variant="vertical" width="full">
        <Box hidden>
          <Form.TextField isRequired type="hidden" {...registerField('subscriptionPlanId')} />
        </Box>
        {validationErrorMessage && (
          <SectionBanner
            data-testid="plan-selection-validation-error"
            description={validationErrorMessage}
            variant="critical"
          />
        )}
        <AccountingClientPlanSelectionStepDiscountBanner
          discountPercent={planDiscountPercentage}
          isLoading={isActiveOfferLoading}
        />
        {!isActiveOfferLoading && (
          <FirmClientSubscriptionPlanSelectionActivity
            selectedSubscriptionPlanId={watch('subscriptionPlanId')}
            onSelect={handleSelectSubscriptionPlanId}
            isSaving={isSaving}
            discountPercentage={planDiscountPercentage}
          />
        )}
        <Text textStyle="body3">
          <Link
            label={formatMessage('activities.accountants.manageClient.form.plan.viewAllFeatures')}
            href={learnMoreLink}
            color="secondary"
            onClick={handleOnLearnMoreClick}
            newTab
          />
        </Text>
        <Container justifyContent="flex-end" paddingTop="s">
          <Button
            variant="primary"
            size="medium"
            label={submitLabel}
            data-testid="plan-selection-continue-button"
            {...submitButtonProps}
            onClick={verifyPlanSelection}
          />
        </Container>
      </Group>
    );
  }
);
