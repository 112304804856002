import { StopFormPropagation } from '@melio/ap-widgets';
import { usePartnerFeature } from '@melio/platform-provider';

import { DeliveryMethodSectionWithDrawer } from './DeliveryMethodSectionWithDrawer/DeliveryMethodSectionWithDrawer';
import { DeliveryMethodSectionWithModals } from './DeliveryMethodSectionWithModals/DeliveryMethodSectionWithModals';
import { DeliveryMethodSectionProps } from './types';

export const DeliveryMethodSection = (props: DeliveryMethodSectionProps) => {
  const [vendorDeliveryMethodsFeature] = usePartnerFeature('VendorDeliveryMethodCombinedExperience', false);

  const sectionImplementation = vendorDeliveryMethodsFeature ? (
    <DeliveryMethodSectionWithDrawer {...props} />
  ) : (
    <DeliveryMethodSectionWithModals {...props} />
  );

  return <StopFormPropagation>{sectionImplementation}</StopFormPropagation>;
};
