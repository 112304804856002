import { FundingSource } from '@melio/platform-api';
import { usePartnerGroups } from '@melio/subscriptions/src/hooks';

const ALLOWED_BILLING_PAYMENT_METHOD_TYPES: Array<FundingSource['type']> = ['card', 'bank-account'];
const FISERV_ALLOWED_BILLING_PAYMENT_METHOD_TYPES: Array<FundingSource['type']> = ['bank-account'];

/* this hook will be moved to subscription package in the following task */
export const useIsValidFirmClientSubscriptionBillingMethodType = () => {
  const { isFiservPartner } = usePartnerGroups();

  const isValidSubscriptionBillingMethod = (fundingSource: FundingSource) =>
    (isFiservPartner ? FISERV_ALLOWED_BILLING_PAYMENT_METHOD_TYPES : ALLOWED_BILLING_PAYMENT_METHOD_TYPES).includes(
      fundingSource.type
    );

  return { isValidSubscriptionBillingMethod };
};
