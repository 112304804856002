import { Container, Group, Illustration, IllustrationProps, Text } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import React from 'react';

type FullScreenLayoutProps = {
  title: string;
  content: string;
  actions: React.ReactNode;
  illustration: IllustrationProps['type'];
  dataTestId?: string;
};
export const FullScreenLayout = ({ illustration, title, content, actions, dataTestId }: FullScreenLayoutProps) => (
  <Layout data-testid={dataTestId}>
    <Container alignItems="center" justifyContent="center" height="full">
      <Group
        textAlign="center"
        spacing="m"
        variant="vertical"
        alignItems="center"
        width={{ xs: '100%', s: '488px' } as never}
      >
        <Illustration type={illustration} />
        <Text textStyle="heading3Semi">{title}</Text>
        <Text textStyle="body2">{content}</Text>
        <Group justifyContent="center" spacing="s">
          {actions}
        </Group>
      </Group>
    </Container>
  </Layout>
);
