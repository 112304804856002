import { useAROrganizationPreferences } from '@melio/ar-domain';
import { usePartnerFeature } from '@melio/platform-provider';

export const useFeeAbsorptionOrganizationPreference = () => {
  const [isFeeAbsorptionSettingsEnabled] = usePartnerFeature('IsArFeeAbsorptionSettingsEnabled', false);
  const { data, ...rest } = useAROrganizationPreferences({ enabled: isFeeAbsorptionSettingsEnabled });
  const isVendorAbsorbedFee = data?.some(({ key, value }) => key === 'isVendorAbsorbedFee' && value === 'true');

  return { isVendorAbsorbedFee, ...rest };
};
