export type VendorAddress = {
  city: string;
  state: string;
  zipCode: string;
  addressLine1: string;
  addressLine2?: string;
  countryCode?: string;
};

export type VendorData = {
  companyName: string | null;
  contactEmail?: string;
  contactName?: string;
  contactPhone?: string;
  accountIdentifier?: string;
  nickname?: string;
  accountNumber?: string;
  contractor?: boolean;
  city?: string;
  state?: string;
  zipCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  countryCode?: string;
};

export enum ColumnId {
  Status = 'status',
  BusinessName = 'businessName',
  ContactName = 'contactName',
  Email = 'email',
  Phone = 'phone',
  Address = 'address',
  Contractor = 'contractor',
  EditRowAction = 'editRowAction',
  DeleteRowAction = 'deleteRowAction',
}
