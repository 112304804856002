import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useNavigate } from 'react-router-dom';

import { useActivitiesNavigate } from '../../../utils';
import { PayDashboardTabs } from '../../PayDashboard';
import { PaymentFlowDoneAction } from '../../types';

type HandleDoneParams = { paymentId: string | undefined; isPendingApproval: boolean; action: PaymentFlowDoneAction };

export const useCreatePaymentHandlers = ({
  billId,
  returnUrl,
  onLoginToAccountPlatformAuth,
  showGlobalMessage,
}: {
  billId: string | undefined;
  returnUrl: string | undefined;
  onLoginToAccountPlatformAuth: VoidFunction;
  showGlobalMessage: ReturnType<typeof useSystemMessage>['showMessage'];
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { navigateToTab, navigateToScheduleNewPayment } = useActivitiesNavigate();

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }

    navigateToTab(PayDashboardTabs.Bills, {
      entityId: billId,
    });
  };

  const handleDone = ({ action, paymentId, isPendingApproval }: HandleDoneParams) => {
    switch (action) {
      case 'goToDashboard':
        return handleGoToDashboard({ paymentId, isPendingApproval });
      case 'createNewPayment':
        return navigateToScheduleNewPayment({});
      case 'syncQBOAccountingPlatform':
        return onLoginToAccountPlatformAuth();
      default:
        break;
    }
  };

  const handleGoToDashboard = ({
    paymentId,
    isPendingApproval,
  }: Pick<HandleDoneParams, 'paymentId' | 'isPendingApproval'>) => {
    if (isPendingApproval) {
      showGlobalMessage({
        type: 'informative',
        title: formatMessage('screens.scheduleSinglePayment.toast.success.single.pending.title'),
        dataTestId: 'create-payment-success-message',
        action: paymentId
          ? {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast: () => void) => {
                navigateToTab(PayDashboardTabs.Payments, { entityId: paymentId });
                closeToast();
                track('Dashboard', 'Click', {
                  PageName: 'payment-pending-approval',
                  Flow: 'dashboard',
                  Intent: 'view-approvals',
                  Status: 'success',
                  Cta: 'view-payment',
                  PaymentId: paymentId,
                });
              },
            }
          : undefined,
      });
    } else {
      showGlobalMessage({
        type: 'success',
        title: formatMessage('screens.scheduleSinglePayment.toast.success.single.title'),
        dataTestId: 'create-payment-success-message',
        action: paymentId
          ? {
              type: 'button',
              text: formatMessage('screens.scheduleSinglePayment.toast.success.single.action'),
              onAction: (closeToast: () => void) => {
                navigateToTab(PayDashboardTabs.Payments, { entityId: paymentId });
                closeToast();
              },
            }
          : undefined,
      });
    }

    if (returnUrl) {
      return navigate(returnUrl, { state: { keepToast: true } });
    }

    return navigateToTab(PayDashboardTabs.Payments, { keepToast: true });
  };

  const handleSubmit = () => navigate('success', { replace: true });

  return { handleClose, handleDone, handleSubmit };
};
