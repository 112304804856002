import { NakedButton, SectionBanner } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useContext } from 'react';

import { useIsMobile } from '../useIsMobile';
import { SystemMessageContext } from './SystemMessageContext';

export const SystemMessage = forwardRef((_, ref) => {
  const { clear, message } = useContext(SystemMessageContext);

  const isMobile = useIsMobile();

  if (!message) {
    return null;
  }

  const action = message.action ? (
    <NakedButton
      data-testid="ar-system-message-action"
      onClick={() => {
        message.action?.onAction();
        clear();
      }}
      size="medium"
      variant="primary"
      label={message.action.text}
    />
  ) : undefined;

  return (
    <SectionBanner
      data-testid="ar-system-message"
      description={message.title}
      variant={message.type}
      action={action}
      showCloseIcon
      isCompact={!isMobile}
      onClose={clear}
      ref={ref}
    />
  );
});
