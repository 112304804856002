import {
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  Button,
  Container,
  Group,
  Icon,
  Link,
  Pill,
  SectionBanner,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { compact } from 'lodash';
import { useRef, useState } from 'react';

import { TaxPayerInfoSectionTitle } from '../TaxPayerInfoSectionTitle';
import { useScrollToIfVisible } from './useScrollTo';

type TaxPayerRequestStatusProps = {
  vendor: Vendor;
  formVisibility: 'hidden' | 'visible';
  onSendReminder: () => void;
  onShowForm: () => void;
  onCancelRequest: () => void;
  isMutating: boolean;
};

export const TaxPayerRequestStatus = ({
  vendor,
  onSendReminder,
  onShowForm,
  onCancelRequest,
  formVisibility,
  isMutating = false,
}: TaxPayerRequestStatusProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { lastTaxpayerIdentificationRequest } = vendor;
  const { formatMessage, formatDate } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const {
    settings: { tax1099LearnMoreLink },
  } = useConfig();
  const { createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');

  const isFormVisible = formVisibility === 'visible';

  const resendFormRef = useRef<HTMLDivElement>(null);
  const scrollToResendForm = useScrollToIfVisible(resendFormRef, isFormVisible);

  const actionMenuItems = compact([
    {
      label: formatMessage('widgets.taxPayerInfo.statusForm.reminder.label'),
      onClick: () => {
        onShowForm();
        trackActionClick({
          TaxId: 'request-sent',
          Cta: 'request-send-reminder',
        });
        scrollToResendForm();
      },
      textStyle: 'body4Semi',
      dataTestId: 'taxpayer-info-menu-resend',
    },
    {
      label: formatMessage('widgets.taxPayerInfo.statusForm.cancel.label'),
      onClick: () => {
        onCancelRequest();
        trackActionClick({
          TaxId: 'request-sent',
          Cta: 'cancel-request',
        });
      },
      textStyle: 'body4Semi',
      variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
      dataTestId: 'taxpayer-info-menu-cancel',
    },
  ]);

  return (
    <Group spacing="m" variant="vertical">
      <TaxPayerInfoSectionTitle />
      <Container data-testid="tax-payer-info-status-container" border="regular" paddingX="m" paddingY="m">
        <Group hasDivider variant="vertical" spacing="m" ref={resendFormRef}>
          <Group variant="horizontal" alignItems="flex-start" justifyContent="space-between" spacing="s">
            <Group variant="horizontal" alignItems="center" justifyContent="space-between" spacing="s">
              <Icon type="stopwatch" size="large" color="default" />
              <Group variant="vertical" spacing="xxs">
                <Group
                  variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                  alignItems={isExtraSmallScreen ? 'flex-start' : 'center'}
                  spacing="xs"
                >
                  <Text color="global.neutral.1000" textStyle="body2Semi">
                    {formatMessage('widgets.taxPayerInfo.statusForm.heading.status.sent')}
                  </Text>
                  <Pill
                    type="secondary"
                    label={formatMessage('widgets.taxPayerInfo.statusForm.badge.status.pending')}
                    status="warning"
                  />
                </Group>

                <Container data-testid="tax-payer-info-status-recipient">
                  <Text textStyle="body4" color="global.neutral.800">
                    {formatMessage('widgets.taxPayerInfo.info.label.sent', {
                      email: lastTaxpayerIdentificationRequest?.email,
                      date: (
                        <Text textStyle="body4Semi" color="global.neutral.800">
                          {formatDate(lastTaxpayerIdentificationRequest?.history?.updatedAt ?? undefined, {
                            dateStyle: 'medium',
                          })}
                        </Text>
                      ),
                    })}
                  </Text>
                </Container>
              </Group>
            </Group>

            <Group>
              <ActionsDropdownMenu
                size="small"
                data-testid="taxpayer-info-menu"
                label={formatMessage('widgets.taxPayerInfo.form.menu.label')}
                items={actionMenuItems}
                isOpen={isMenuOpen}
                onOpenChange={setIsMenuOpen}
              />
            </Group>
          </Group>

          {isFormVisible && (
            <Group
              ref={resendFormRef}
              variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
              justifyContent="space-between"
              spacing="s"
            >
              <SectionBanner
                isCompact
                description={
                  <Text textStyle={isExtraSmallScreen ? 'body4' : 'body3'}>
                    {formatMessage('widgets.taxPayerInfo.info.label.resend', {
                      learnMoreLink: (
                        <Link
                          color="inherit"
                          href={tax1099LearnMoreLink}
                          label={formatMessage('widgets.taxPayerInfo.info.link')}
                          newTab
                        />
                      ),
                    })}
                  </Text>
                }
              />
              <Button
                variant="tertiary"
                leftElement={<Icon size="small" type="send" color="inherit" aria-hidden />}
                isLoading={isMutating}
                label={formatMessage('widgets.taxPayerInfo.form.reminder.request')}
                data-testid="taxpayer-send-reminder-btn"
                onClick={onSendReminder}
                style={{
                  height: isExtraSmallScreen ? undefined : '46px',
                }}
              />
            </Group>
          )}
        </Group>
      </Container>
    </Group>
  );
};
