import { useDeliveryMethodDecryptedAccountNumber } from '@melio/ap-domain';
import { BankAccountFormModel, VendorBankDetailsPayorForm } from '@melio/ap-widgets';
import { ApiError } from '@melio/api-client';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { BankAccountDeliveryMethod, useDeliveryMethod, useDeliveryMethods, useVendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { ComponentProps, useCallback, useRef, useState } from 'react';

import {
  EffectedPaymentsDisclaimerModal,
  useEffectedPaymentsDisclaimerModal,
} from '../components/EffectedPaymentsDisclaimerModal';
import { DeliveryMethodsFailureCodeErrors } from '../types';
import { FailedToDeliveryToBankAccountWarning } from './components/FailedToDeliveryToBankAccountWarning';
import { ReusingFailedBankAccountError } from './components/ReusingFailedBankAccountError';
import { EditVendorBankDetailsScreen } from './screens';
import { CustomValidation } from './screens/EditVendorBankDetails/EditVendorBankDetails.screen';
import { EditVendorBankDetailsActivityProps } from './types';

type OnValidation = Required<ComponentProps<typeof VendorBankDetailsPayorForm>>['onValidation'];

const REUSE_FAILED_DELIVERY_METHOD_ERROR = 'isUsingFailed';
export const EditVendorBankDetailsActivity = withAnalyticsContext<EditVendorBankDetailsActivityProps>(
  ({ deliveryMethodId, vendorId, onBack: onFirstStepBack, onClose, onError, onDone, texts }) => {
    const deliveryMethod = useDeliveryMethod({ id: deliveryMethodId });
    const deliveryMethodCollection = useDeliveryMethods({ vendorId, enabled: false });
    const vendorModel = useVendor({ id: vendorId });
    const effectedPaymentsDisclaimerModal = useEffectedPaymentsDisclaimerModal(vendorId);
    const [attemptedToReusedFailedBankAccount, setAttemptedToReusedFailedBankAccount] = useState<boolean>(false);
    const bankAccount = deliveryMethod.data as BankAccountDeliveryMethod | undefined;
    const isDmFilledByVendor = deliveryMethod.data?.isFilledByVendor;
    const { bankAccountNumber, isLoading: isBankAccountNumberLoading } = useDeliveryMethodDecryptedAccountNumber({
      bankAccount,
      enabled: !isDmFilledByVendor,
    });
    const bankRoutingNumber = bankAccount?.details.routingNumber;
    const formData = useRef<BankAccountFormModel>();
    const { showMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();

    const handleFail = (error: PlatformError) => {
      const alreadyExists = error?.code === '409';
      if (alreadyExists) {
        onDone(deliveryMethod.data as BankAccountDeliveryMethod);
        return;
      }

      const errorCode = (error as ApiError).errorCode;
      const message =
        errorCode === DeliveryMethodsFailureCodeErrors.InvalidRouteNumber
          ? formatMessage('activities.editVendorBankDetails.activity.errorRoutingFormat')
          : error.message;

      showMessage({ type: 'error', title: message });

      onError?.(error);
    };

    const { createTrackHandler, track } = useAnalytics();
    const submitHandler = createTrackHandler<{
      Status: 'failed' | 'succeeded';
      DeliveryMethodChosen: 'bank-account';
    }>('DeliveryMethodEdited', 'Saved', { DeliveryMethodChosen: 'bank-account' });
    const trackAndHandleClick = createTrackHandler<{
      PageName: 'edit-vendor-bank-details';
      Cta: 'back' | 'update-bank-details' | 'exit';
    }>('DeliveryMethod', 'Click');

    const onFirstStepBackClick = () => {
      trackAndHandleClick({ Cta: 'back' }, onFirstStepBack);
    };

    const onCloseClick = () => {
      trackAndHandleClick({ Cta: 'exit' }, onClose);
    };

    const updateDeliveryMethod = (data: BankAccountFormModel) => {
      trackAndHandleClick({ Cta: 'update-bank-details' });
      deliveryMethodCollection
        .create({ type: 'bank-account', details: { ...data, accountType: 'checking' } })
        .then((data) => submitHandler({ Status: 'succeeded' }, () => onDone(data as BankAccountDeliveryMethod)))
        .catch((...args) => submitHandler({ Status: 'failed' }, () => handleFail(...args)));
    };

    const handleSubmit = (data: BankAccountFormModel) => {
      if (effectedPaymentsDisclaimerModal.showDisclaimer) {
        formData.current = data;
        effectedPaymentsDisclaimerModal.onOpen();
      } else {
        updateDeliveryMethod(data);
      }
    };

    const handleConfirm = () => {
      if (formData.current) {
        updateDeliveryMethod(formData.current);
        effectedPaymentsDisclaimerModal.onClose();
      }
    };

    const accountDetails = deliveryMethod.data;
    const hasFailedPayments = accountDetails?.hasFailedPayments;

    const banner =
      accountDetails?.type === 'bank-account' ? (
        attemptedToReusedFailedBankAccount ? (
          <ReusingFailedBankAccountError />
        ) : (
          <FailedToDeliveryToBankAccountWarning
            accountNumber={isDmFilledByVendor ? undefined : bankAccountNumber}
            routingNumber={isDmFilledByVendor ? undefined : bankRoutingNumber}
          />
        )
      ) : null;

    const isReusingFailedBankAccountValidation: CustomValidation = {
      name: REUSE_FAILED_DELIVERY_METHOD_ERROR,
      test: (bankAccount) => {
        if (accountDetails?.type === 'bank-account') {
          return (
            bankAccount.accountNumber !== accountDetails.details.accountNumber ||
            bankAccount.routingNumber !== accountDetails.details.routingNumber
          );
        }
        return false;
      },
    };

    const onFormChange = () => {
      if (attemptedToReusedFailedBankAccount) {
        setAttemptedToReusedFailedBankAccount(false);
      }
    };

    const onValidation: OnValidation = useCallback(
      (errors: Parameters<OnValidation>[0]) => {
        const isReuseFailedAccount = errors.account?.type === REUSE_FAILED_DELIVERY_METHOD_ERROR;
        setAttemptedToReusedFailedBankAccount(isReuseFailedAccount);
        if (isReuseFailedAccount) {
          track('DeliveryMethod', 'Status', {
            ErrorType: 'incorrect-bank-details',
          });
        }
      },
      [setAttemptedToReusedFailedBankAccount, track]
    );

    return (
      <>
        <EditVendorBankDetailsScreen
          onValidation={onValidation}
          customValidation={hasFailedPayments ? isReusingFailedBankAccountValidation : undefined}
          onChange={onFormChange}
          banner={hasFailedPayments ? banner : undefined}
          isLoading={
            deliveryMethod.isLoading ||
            vendorModel.isLoading ||
            effectedPaymentsDisclaimerModal.isLoading ||
            isBankAccountNumberLoading
          }
          defaultValues={
            hasFailedPayments || !!isDmFilledByVendor
              ? undefined
              : {
                  accountNumber: bankAccountNumber,
                  routingNumber: bankRoutingNumber,
                }
          }
          onBack={onFirstStepBackClick}
          onClose={onCloseClick}
          onDone={handleSubmit}
          isSaving={deliveryMethodCollection.isMutating}
          vendorName={vendorModel.data?.name || ''}
          texts={texts} // should be refactored with designed texts override solution
          disableNext={attemptedToReusedFailedBankAccount}
        />
        <EffectedPaymentsDisclaimerModal
          isOpen={effectedPaymentsDisclaimerModal.isOpen}
          onClose={effectedPaymentsDisclaimerModal.onClose}
          onConfirm={handleConfirm}
        />
      </>
    );
  }
);

EditVendorBankDetailsActivity.displayName = 'EditVendorBankDetailsActivity';
