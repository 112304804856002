import { Button, Container, Grid, Illustration, Text } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { PageTitle } from '@melio/platform-utils';

export const TermsAndConditionsNonOwnerScreen = withAnalyticsContext(
  ({ onClose, isFirstTime }: { onClose: () => void; isFirstTime: boolean }) => {
    const { formatMessage } = useMelioIntl();

    const tabTitle = formatMessage('activities.termsAndConditions.title');
    useSetDocumentTitle(tabTitle);

    return (
      <Layout maxWidth="600px" backgroundColor="white">
        <Container
          paddingX="xl"
          paddingY="xxs"
          height="full"
          textAlign="center"
          data-testid="terms-and-conditions-non-owner"
        >
          <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="l">
            <Illustration size="large" type="no-items" />

            <Grid gap="s" width="full">
              {isFirstTime ? (
                <PageTitle textStyle="heading1Semi" data-testid="terms-and-conditions-non-owner-title">
                  {formatMessage('activities.termsAndConditions.nonOwner.title')}
                </PageTitle>
              ) : (
                <PageTitle textStyle="heading1Semi" data-testid="terms-and-conditions-non-owner-updated-title">
                  {formatMessage('activities.termsAndConditions.nonOwner.updated.title')}
                </PageTitle>
              )}

              <Grid gap="none">
                {isFirstTime ? (
                  <Text data-testid="terms-and-conditions-non-owner-description">
                    {formatMessage('activities.termsAndConditions.nonOwner.description')}
                  </Text>
                ) : (
                  <Text data-testid="terms-and-conditions-non-owner-updated-description">
                    {formatMessage('activities.termsAndConditions.nonOwner.updated.description')}
                  </Text>
                )}
              </Grid>
            </Grid>

            <Button
              data-testid="terms-and-conditions-non-owner-close"
              label={formatMessage('activities.termsAndConditions.nonOwner.close')}
              size="medium"
              onClick={onClose}
            />
          </Grid>
        </Container>
      </Layout>
    );
  },
);
