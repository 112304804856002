import { Group, Icon, IconKey, Text } from '@melio/penny';

type MigratedUserExperienceRowProps = { description: string; iconType: IconKey };

export const MigratedUserExperienceRow = ({ description, iconType }: MigratedUserExperienceRowProps) => (
  <Group variant="horizontal" spacing="xs" alignItems="center">
    <Icon type={iconType} color="brand" />
    <Text textStyle="body3" color="semantic.text.primary">
      {description}
    </Text>
  </Group>
);
