import { useTheme } from '@melio/penny';
import { EventProperties } from '@melio/platform-analytics';
import { useUserPreference } from '@melio/platform-api';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useCallback, useEffect, useState } from 'react';
import Joyride from 'react-joyride';

import { FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY } from '../consts';
import { JoiRideToolTip } from './JoiRideToolTip';
import { UseNewDashboardMigratedTourSteps } from './useNewDashboardMigratedTourSteps';

export const DashboardMigratedUserTour = () => {
  const {
    settings: { migrationUserTour },
  } = useConfig();
  const [stepIndex, setStepIndex] = useState(0);
  const { colors } = useTheme();
  const [run, setRun] = useState(false);

  const [isMigratedUserTourTripEnabled] = usePartnerFeature('MigratedUserTourTripEnabled', false);
  const { data: userPreference, create: createUserPreference } = useUserPreference({
    id: FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY,
    enabled: true,
  });

  useEffect(() => {
    const isTourActive =
      userPreference?.value === 'true' && isMigratedUserTourTripEnabled && migrationUserTour.isEnabled;
    if (isTourActive) {
      setRun(isTourActive);
    }
  }, [userPreference, isMigratedUserTourTripEnabled, migrationUserTour]);

  useEffect(() => {
    //once the tour is active if the user will refresh we don't want to show it again
    if (run && migrationUserTour.shouldFinishOnSessionEnd) {
      createUserPreference({
        userPreference: { key: FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY, value: 'false' },
      });
    }
  }, [run, createUserPreference, migrationUserTour]);

  const onDone = useCallback(() => {
    createUserPreference({
      userPreference: { key: FISERV_DASHBOARD_MIGRATED_USER_TOUR_PREFERENCE_KEY, value: 'false' },
    });
    setRun(false);
  }, [createUserPreference]);

  const analyticsProperties: EventProperties = {
    Flow: 'fte-new-user',
    Intent: 'fte-guide',
    EntryPoint: 'login-page',
  };
  const { steps, numberOfSteps } = UseNewDashboardMigratedTourSteps();

  if (!migrationUserTour.isEnabled || !isMigratedUserTourTripEnabled) {
    return null;
  }
  return (
    <Joyride
      steps={steps}
      run={run}
      continuous
      showProgress
      hideCloseButton
      hideBackButton
      stepIndex={stepIndex}
      disableScrollParentFix
      tooltipComponent={(props) => (
        <JoiRideToolTip
          joiRideProps={props}
          stepIndex={stepIndex}
          numberOfSteps={numberOfSteps}
          setStepIndex={setStepIndex}
          onDone={onDone}
          analyticsProperties={analyticsProperties}
        />
      )}
      styles={{
        options: {
          arrowColor: `${colors.semantic.surface.inverse}`, // Change background color
        },
        tooltip: {
          backgroundColor: '#333', // Change background color
          color: '#fff', // Change text color
          borderRadius: '10px',
        },

        buttonNext: {
          display: 'none', // Hide next button
        },
        buttonBack: {
          display: 'none', // Hide next button
        },
      }}
    />
  );
};
