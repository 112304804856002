import { BankAccountDeliveryMethod, useGetDeliveryMethodDecryptedAccountNumberQuery } from '@melio/platform-api';

export const useDeliveryMethodDecryptedAccountNumber = ({
  bankAccount,
  enabled = true,
  suspense,
}: {
  bankAccount: BankAccountDeliveryMethod | undefined;
  enabled?: boolean;
  suspense?: boolean;
}) => {
  const shouldDecrypt = enabled && !!bankAccount?.id;

  const { data: decryptedBankAccountNumber, isLoading } = useGetDeliveryMethodDecryptedAccountNumberQuery({
    deliveryMethodId: bankAccount?.id,
    enabled: shouldDecrypt,
    suspense,
  });

  return {
    bankAccountNumber: shouldDecrypt ? decryptedBankAccountNumber : bankAccount?.details.accountNumber,
    isLoading: shouldDecrypt && isLoading,
  };
};
