import { useIsAccountingFirm } from '@melio/platform-api';
import { Tier } from '@melio/platform-api-axios-client';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { Plan } from '../api';

export const usePlanName = (plan?: Plan) => {
  const { formatMessage } = useMelioIntl();
  const isAccountingFirm = useIsAccountingFirm();

  const [isSubscriptionsRemoveAchTPlus2FromSmbVip] = useDevFeature(
    FeatureFlags.SubscriptionsRemoveAchTPlus2FromSmbVip,
    false
  );

  // note: when the FF isSubscriptionsRemoveAchTPlus2FromSmbVip is merged we will need to copy the content from activities.subscription.plans.MELIO_VIP.title.withoutTPlus2 to activities.subscription.plans.MELIO_VIP.title
  if (isSubscriptionsRemoveAchTPlus2FromSmbVip && !isAccountingFirm && plan?.tier === Tier.MelioVip) {
    return formatMessage('activities.subscription.plans.MELIO_VIP.title.withoutTPlus2');
  }

  return plan ? formatMessage(`activities.subscription.plans.${plan.tier}.title`) : '';
};
