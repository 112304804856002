import { usePermissions } from '@melio/platform-permissions';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';

export const useCardWritePermissions = () => {
  const { settings } = useConfig();

  const [allowCaratIntegration] = usePartnerFeature('AllowCaratIntegration', false);
  const isCaratTokenProvider = settings.tokenProvider === 'carat';

  const { can } = usePermissions();
  const canCreateCard = can({ action: 'create', subject: 'fundingSource', subjectData: { fundingType: 'card' } });

  if (isCaratTokenProvider && !allowCaratIntegration) {
    return false;
  }

  return canCreateCard;
};
