import { forwardRef } from '@melio/platform-utils';

import { ActivationSuccessScreen } from './screens';

export type ActivationSuccessActivityProps = {
  onGoToDashboard?: VoidFunction;
  onCreateInvoice?: VoidFunction;
};

export const ActivationSuccessActivity = forwardRef<ActivationSuccessActivityProps>(
  ({ onCreateInvoice, onGoToDashboard, ...props }, ref) => (
    <ActivationSuccessScreen onCreateInvoice={onCreateInvoice} onGoToDashboard={onGoToDashboard} {...props} ref={ref} />
  )
);

ActivationSuccessActivity.displayName = 'ActivationSuccessActivity';
