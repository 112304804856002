import { Drawer, Text } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef } from 'react';

import { TodosDrawerBody } from './components/TodosDrawerBody';
import { useEnrichedTodos } from './hooks/useEnrichedTodos';
import { getAnalyticsData } from './utils';

type Props = { isOpen: boolean; onClose: VoidFunction };
export const TodosDrawerActivity = withAnalyticsContext<Props>(({ isOpen, onClose, setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const { data: todos, isLoading, isFetching, totalCount, markAsSeen, refetch } = useEnrichedTodos();
  const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);
  const { track } = useAnalytics();
  setAnalyticsProperties({
    PageName: 'todos-view',
    Intent: 'view-todos',
    Flow: 'view-todos',
  });

  useEffect(() => {
    if (isOpen) {
      refetch().then(({ data }) => {
        setAnalyticsProperties({ ...getAnalyticsData(data) });
        track('Dashboard', 'View');
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleClose = () => {
    track('Dashboard', 'click', { Cta: 'exit' });
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      shouldReturnFocus={shouldReturnFocus.current}
      onClose={handleClose}
      data-testid="todos-drawer"
      isLoading={isLoading || isFetching}
      closeButtonAriaLabel={formatMessage('widgets.todosDrawer.closeButtonAriaLabel')}
      header={
        <Text as="h2" textStyle="heading2Semi" data-testid="todos-drawer-header-title">
          {formatMessage('widgets.todosDrawer.header.title')}
        </Text>
      }
      body={<TodosDrawerBody todos={todos ?? []} totalCount={totalCount} markAsSeen={markAsSeen} onClose={onClose} />}
    />
  );
});
