import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { groupBy } from 'lodash';

import {
  getArePaymentsToCombineContainEBills,
  getCanCombinePayments,
  getThereIsSameVendorForMultipleBills,
} from '../../../../BatchPayments.activity.utils';
import { SchedulePaymentIntent } from '../../../../types';

export const useCombinePaymentsToggle = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) => {
  const { formatMessage } = useMelioIntl();
  const {
    settings: {
      batchPayments: { maxBillsToCombine },
    },
  } = useConfig();
  const [isEbillsFFEnabled] = useDevFeature(FeatureFlags.EBills, false);

  const bills = paymentIntentsWithDerivatives?.flatMap(({ bills }) => bills);

  const hasEBills = isEbillsFFEnabled && getArePaymentsToCombineContainEBills(bills);

  const vendorBills = Object.values(groupBy(bills, (bill) => bill.vendorId));
  const hasReachedMaxCombinedBills = vendorBills.some((bills) => bills.length > maxBillsToCombine);

  const isDisabled = !getCanCombinePayments(bills) || hasEBills || hasReachedMaxCombinedBills;

  const getTooltip = () => {
    if (!getThereIsSameVendorForMultipleBills(bills)) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.hasNoCommonVendor'
      );
    }
    if (hasEBills) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.hasEBills'
      );
    }
    if (hasReachedMaxCombinedBills) {
      return formatMessage(
        'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.upToMaxBills',
        { maxBills: maxBillsToCombine }
      );
    }
    return formatMessage(
      'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.tooltip.active'
    );
  };

  return { isDisabled, getTooltip };
};
