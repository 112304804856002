import { Button, Container, Divider, Grid, Group, Illustration, Link, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled } from '../../../hooks';

export const DowngradeFlowNonOwnerScreen = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });
  const { track } = useAnalytics();
  const {
    settings: {
      vex: { zdSupportFormUrl },
    },
  } = useConfig();

  setAnalyticsProperties({
    PageName: 'account-owner-action-required',
    Flow: 'subscription-none-tier',
    Intent: 'subscription-downgrade',
  });

  useAnalyticsView('Organization', true, true);

  if (!accountData?.organizationOwner) {
    return <Navigate to="/pay-dashboard" />;
  }

  const companyName = accountData.company.name;
  const owner = accountData.organizationOwner;
  const ownerEmail = owner.email;
  // account owner first/last name might be undefined on some edge cases
  const ownerName = `${owner.firstName || ''} ${owner.lastName || ''}`;

  return (
    <Layout maxWidth="600px" backgroundColor="white">
      <Container
        paddingX="xxs"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="subscription-downgrade-non-owner"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="s">
          <Illustration size="large" type="blocked" />
          <Group width="fit-content" variant="vertical" alignItems="center" justifyContent="center" spacing="l">
            <Group spacing="xs" variant="vertical">
              <PageTitle textStyle="heading2Semi">
                {formatMessage('activities.subscription.downgrade.nonOwner.title')}
              </PageTitle>

              <Text color="global.neutral.900" data-testid="subscription-downgrade-non-owner-description">
                {formatMessage('activities.subscription.downgrade.nonOwner.description', {
                  companyName,
                  ownerName,
                  ownerEmail,
                })}
              </Text>
            </Group>

            {ownerEmail && (
              <Button
                onClick={() => {
                  track('Organization', 'Click', {
                    Intent: 'email-account-owner',
                    Cta: 'email-account-owner',
                  });
                }}
                variant="primary"
                size="large"
                data-testid="subscription-downgrade-non-owner-email-account-owner-button"
                label={formatMessage('activities.subscription.downgrade.nonOwner.emailAccountOwner')}
                link={{
                  href: `mailto:${ownerEmail}`,
                  target: '_blank',
                }}
              />
            )}

            <Divider />
            <Text>
              {formatMessage('activities.subscription.downgrade.nonOwner.contact', {
                linkToSupport: (
                  <Link
                    onClick={() => {
                      track('Organization', 'Click', {
                        Intent: 'contact-support',
                        Cta: 'contact-support',
                      });
                    }}
                    data-testid="subscription-downgrade-non-owner-support-link"
                    href={zdSupportFormUrl}
                    newTab
                    color="default"
                    label={formatMessage('activities.subscription.downgrade.nonOwner.linkToSupportText')}
                  />
                ),
              })}
            </Text>
          </Group>
        </Grid>
      </Container>
    </Layout>
  );
});
