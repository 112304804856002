import { Box } from '@chakra-ui/react';
import { Card, Container, Group, Icon, Modal, RadioGroup, RadioOption, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  isLoading?: boolean;
  address: string;
  onSave: (selectedOption: string) => void;
  onClose: () => void;
};

const variants = ['vendorBusinessAddressOnly', 'vendorAddressAndPaperCheckDeliveryAddress'] as const;
type Variant = (typeof variants)[number];

export const VendorAddressUpdateModal = ({ isOpen, isLoading, address, onSave, onClose }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track, createTrackHandler } = useAnalytics();
  const trackActionClick = createTrackHandler('Vendor', 'Click');
  const [selectedOption, setSelectedOption] = useState('vendorBusinessAddressOnly');
  const defaultAnalyticsFields = useMemo(
    () => ({
      PageName: 'vendor-address-update',
      Flow: 'edit-vendor',
      Intent: 'edit-vendor-details',
    }),
    []
  );

  const getVariantMessage = (variant: Variant) => formatMessage(`widgets.vendorAddressUpdateModal.options.${variant}`);

  const options: RadioOption[] = variants.map((variant) => ({
    value: variant,
    mainLabelProps: { label: getVariantMessage(variant) },
    id: variant,
  }));

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', { ...defaultAnalyticsFields });
    }
  }, [isOpen, track, defaultAnalyticsFields]);

  const handleOnClose = useCallback(
    (isExit: boolean) => {
      trackActionClick({ Cta: isExit ? 'exit' : 'cancel', ...defaultAnalyticsFields }, onClose);
    },
    [onClose, trackActionClick, defaultAnalyticsFields]
  );

  return (
    <Modal
      data-testid="vendor-address-update-modal"
      isOpen={isOpen}
      onClose={() => handleOnClose(true)}
      shouldReturnFocus
      header={formatMessage('widgets.vendorAddressUpdateModal.title')}
      primaryButton={{
        label: formatMessage('widgets.vendorAddressUpdateModal.buttons.primary'),
        onClick: () => onSave(selectedOption),
        variant: 'primary',
        isLoading,
      }}
      secondaryButton={{
        label: formatMessage('widgets.vendorAddressUpdateModal.buttons.secondary'),
        onClick: () => handleOnClose(false),
        variant: 'secondary',
        isLoading,
      }}
    >
      <Group variant="vertical" spacing="l">
        <Card data-testid="vendor-edit-not-verified-card" variant="flat">
          <Container width="full" justifyContent="flex-start" alignItems="center">
            <Box height="full" pr="s">
              <Icon type="vendor" size="large" aria-hidden />
            </Box>
            <Group variant="vertical" spacing="none">
              <Box>
                <Text textStyle="body4Semi" color="global.neutral.900">
                  {formatMessage('widgets.vendorAddressUpdateModal.card.title')}
                </Text>
              </Box>
              <Box>
                <Text textStyle="body2Semi">{address}</Text>
              </Box>
            </Group>
          </Container>
        </Card>
        <Group variant="vertical" spacing="s">
          <Text textStyle="body2Semi">{formatMessage('widgets.vendorAddressUpdateModal.description')}</Text>
          <RadioGroup
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            options={options}
            variant="horizontal"
            gridGap="16px"
          />
        </Group>
      </Group>
    </Modal>
  );
};
