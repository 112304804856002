import { Icon } from '@melio/penny';
import { TodoActivateCollaborators, TodoActivateCollaboratorsTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { isEmptySection } from '../utils';
import { TodoSection } from './TodoSection';

export type TodoSectionActivateCollaboratorsProps = {
  todo?: TodoActivateCollaborators;
  clientOrganizationId: string;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodoSectionActivateCollaborators = ({
  clientOrganizationId,
  todo,
  onSwitchOrganization,
}: TodoSectionActivateCollaboratorsProps) => {
  const { formatMessage } = useMelioIntl();
  const { goToCollaboratorsSettings } = useAccountantsRoutes();

  if (isEmptySection(todo)) {
    return null;
  }

  const count = todo?.items.pagination.totalCount;
  const title = formatMessage('widgets.todosDrawer.section.activateCollaborators.title', { count });

  const action = {
    label: formatMessage('widgets.todosDrawer.section.action.manageCollaborators', { count }),
    onClick: async () => {
      await onSwitchOrganization({
        organizationId: clientOrganizationId,
        keepLoadingState: true,
        switchAccessTokenOnly: true,
      });
      goToCollaboratorsSettings({ refresh: true });
    },
  };

  return (
    <TodoSection
      key={`section-${TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}`}
      icon={<Icon type="user-add" color="brand" />}
      title={title}
      action={action}
      type={TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}
    />
  );
};
