import { Container, Group, Text, useBreakpoint } from '@melio/penny';
import React, { useState } from 'react';

import { CarouselItem } from './components/CarouselItem';
import { CarouselNavigation } from './components/CarouselNavigation';

type CarouselProps = {
  title: string;
};

export const Carousel: React.FC<CarouselProps> & { Item: React.FC } = ({ title, children }) => {
  const { isLargeScreen, isExtraLargeScreen } = useBreakpoint();
  const isLargeView = isLargeScreen || isExtraLargeScreen;
  const itemsCount = React.Children.count(children);
  const [currentIndex, setCurrentIndex] = useState<number>(isLargeView ? 0 : Math.floor(Math.random() * itemsCount));

  return (
    <Container height="full">
      <Group variant="vertical">
        <Group spacing="xs" alignItems="center">
          <Container>
            <Text textStyle="heading3Semi">{title}</Text>
          </Container>
          {isLargeView ? (
            <Container justifyContent="flex-end">
              <CarouselNavigation
                itemsCount={itemsCount - 1}
                currentIndex={currentIndex}
                setCurrentIndex={setCurrentIndex}
              />
            </Container>
          ) : null}
        </Group>
        <Group spacing="m" variant="horizontal">
          {React.Children.toArray(children)[currentIndex]}
        </Group>
      </Group>
    </Container>
  );
};

Carousel.Item = CarouselItem;
