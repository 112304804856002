import {
  Todo,
  TodoActivateCollaborators,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPayment,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItems,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApprove,
  TodoPaymentsToApproveTypeEnum,
} from '@melio/platform-api';

export const useTypedTodos = (todos: Todo[]) => {
  const failedPaymentTodo = todos.find(
    (t): t is TodoFailedPayment => t.type === TodoFailedPaymentTypeEnum.FailedPayments
  );
  const paymentsToApprove = todos.find(
    (t): t is TodoPaymentsToApprove => t.type === TodoPaymentsToApproveTypeEnum.PaymentsToApprove
  );
  const overdueInboxItems = todos.find(
    (t): t is TodoOverdueInboxItems => t.type === TodoOverdueInboxItemsTypeEnum.OverdueInboxItems
  );
  const overdueIn7Days = todos.find(
    (t): t is TodoOverdueIn7DaysInboxItems => t.type === TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems
  );
  const overdueInMoreThan7Days = todos.find(
    (t): t is TodoOverdueInMoreThan7DaysInboxItems =>
      t.type === TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems
  );
  const activateCollaborator = todos.find(
    (t): t is TodoActivateCollaborators => t.type === TodoActivateCollaboratorsTypeEnum.ActivateCollaborators
  );
  return {
    failedPaymentTodo,
    paymentsToApprove,
    overdueInboxItems,
    overdueIn7Days,
    overdueInMoreThan7Days,
    activateCollaborator,
  };
};
