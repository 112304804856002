import { createContext, ReactNode, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { WorkSheet } from 'xlsx';
import * as XLSX from 'xlsx';
// import { parseColumns } from "./utils.ts";

export type FileRawEntity = Record<string, string>;

type FileData = {
  id: string;
  entities: FileRawEntity[];
};

type ImportFileContextType = {
  uploadFile: (file: File) => string;
  getFileData: (id: string) => FileRawEntity[] | undefined;
  loading: boolean;
};

const ImportFileContext = createContext<ImportFileContextType | undefined>(undefined);

export const ImportFileProvider = ({ children }: { children: ReactNode }) => {
  const [files, setFiles] = useState<FileData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadFile = (file: File) => {
    const fileId = uuidv4();
    setLoading(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const workbook = XLSX.read(e.target?.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0] || '';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const sheet: WorkSheet = workbook.Sheets[sheetName]!;
      const sheetData = XLSX.utils.sheet_to_json(sheet) as unknown as FileRawEntity[];
      const fileData: FileData = {
        id: fileId,
        entities: sheetData,
      };
      setFiles((prevFiles) => [...prevFiles, fileData]);
      setLoading(false);
    };
    reader.readAsArrayBuffer(file);
    return fileId;
  };

  const getFileData = (id: string): FileRawEntity[] | undefined => {
    const file = files.find((file) => file.id === id);
    return file?.entities;
  };

  return (
    <ImportFileContext.Provider value={{ uploadFile, getFileData, loading }}>{children}</ImportFileContext.Provider>
  );
};

export const useImportFile = (): ImportFileContextType => {
  const context = useContext(ImportFileContext);
  if (context === undefined) {
    throw new Error('useImportedFileData must be used within a ImportFileProvider');
  }
  return context;
};
