import {
  FormattedCurrency,
  FormattedMessage,
  ReceivablePayment,
  useMelioIntl,
  usePaymentStatusLabel,
} from '@melio/ar-domain';
import { Group, Icon, Pill, Text, Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MobileContainer } from '../../../../components';

export type PaymentsTableRowsMobileProps = { payment: ReceivablePayment };

export const PaymentsTableRowsMobile = forwardRef<PaymentsTableRowsMobileProps>(({ payment, ...props }, ref) => {
  const { formatDate } = useMelioIntl();
  const { getPaymentStatusLabel, getPaymentStatusVariant } = usePaymentStatusLabel();
  return (
    <MobileContainer>
      <Group
        data-testid={`table-mobile-row-${payment.id}`}
        variant="horizontal"
        justifyContent="space-between"
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="xs">
          <Typography.MainLabel
            label={payment.payorDetails?.companyName || ''}
            variant="bold"
            data-testid="payer-name"
          />
          <Group variant="vertical" spacing="xxxs">
            <Text textStyle="body3" color="global.neutral.800" data-testid="payment-table-row-payment-date">
              {formatDate(payment.creationDate)}
            </Text>
            <Text textStyle="body3" color="global.neutral.800" data-testid="payment-table-row-invoice-number">
              {payment.invoices?.[0]?.invoiceNumber || (
                <FormattedMessage id="ar.dashboard.activities.paymentsTable.columns.invoiceNumber.unapplied.text" />
              )}
            </Text>
          </Group>
          <Pill
            type="secondary"
            status={getPaymentStatusVariant(payment.status)}
            label={getPaymentStatusLabel(payment.status)}
            data-testid="payment-status-label"
            data-cy-status={payment.status}
          />
        </Group>
        <Group variant="horizontal" alignItems="center" height="fit-content" spacing="xxxs">
          <Text textStyle="body2Semi" color="global.neutral.900" data-testid="payment-table-row-amount">
            <FormattedCurrency amount={payment.amount} />
          </Text>
          <Icon type="chevron-right" size="small" />
        </Group>
      </Group>
    </MobileContainer>
  );
});

PaymentsTableRowsMobile.displayName = 'PaymentsTableRowsMobile';
