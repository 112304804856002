import { Container } from '@melio/penny';
import { cloneElement, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { useSticky } from '../hooks/sticky/useSticky';
export function StickyHorizontalScroller({
  children,
  isVisible,
}: {
  children: React.ReactElement;
  isVisible: boolean;
}) {
  const tableRef = useRef<HTMLElement>();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [showScrollbar, setShowScrollbar] = useState<boolean>(true);
  const { ref: scrollContainerRef, isControlled } = useSticky({ position: 'bottom' });
  useLayoutEffect(() => {
    let observer: IntersectionObserver;
    const anchorEl = anchorRef.current;
    const canHideScrollbarViaCSS = 'scrollbarWidth' in document.body.style;
    if (isVisible && !canHideScrollbarViaCSS) {
      observer = new IntersectionObserver(
        ([entry]) => {
          setShowScrollbar(!entry?.isIntersecting);
          if (entry?.isIntersecting) {
            tableRef.current?.style.setProperty('overflow-x', 'auto');
          } else {
            tableRef.current?.style.setProperty('overflow-x', 'hidden');
          }
        },
        {
          threshold: 1,
        }
      );

      if (anchorEl) {
        observer.observe(anchorEl);
      }
    }

    return () => {
      if (anchorEl) {
        observer?.unobserve(anchorEl);
      }
    };
  }, [isVisible]);

  const onScrollerMount = useCallback((ref: HTMLDivElement) => {
    scrollRef.current = ref;
    if (ref && tableRef.current) {
      ref.scrollLeft = tableRef.current.scrollLeft;
    }
  }, []);

  const onContentMount = useCallback((ref: HTMLElement) => {
    tableRef.current = ref;
    if (ref) {
      ref.style.setProperty('scrollbar-width', 'none');
      ref.addEventListener('scroll', (e) => {
        if (
          scrollRef.current &&
          e.target instanceof HTMLElement &&
          scrollRef.current.scrollLeft !== e.target?.scrollLeft
        ) {
          scrollRef.current.scrollLeft = e.target?.scrollLeft;
        }
      });
    }
  }, []);

  const ContentContainer = cloneElement(children, { ref: onContentMount });

  return (
    <Container position="relative" width="full" overflow="initial">
      {ContentContainer}
      <div
        data-testid="sticky-scroller-anchor"
        ref={anchorRef}
        style={{ position: 'absolute', bottom: 0, left: 0, width: '1px', height: '50px' }}
      ></div>
      {isVisible && showScrollbar && (
        <div
          ref={scrollContainerRef}
          style={{
            width: '100%',
            ...(isControlled ? {} : { position: 'sticky' }),
            bottom: 0,
            height: '14px',
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor: 'white',
          }}
        >
          <div
            data-testid="sticky-scrollbar"
            onScroll={(e) => {
              if (tableRef.current) {
                if (e.target instanceof HTMLDivElement && tableRef.current.scrollLeft !== e.target.scrollLeft) {
                  tableRef.current.scrollLeft = e.target.scrollLeft;
                }
              }
            }}
            ref={onScrollerMount}
            style={{
              width: '100%',
              height: '6px',
              scrollbarColor: 'auto',
              overflowX: 'scroll',
              overflowY: 'hidden',
            }}
          >
            <div
              style={{
                width: tableRef.current?.scrollWidth ? `${tableRef.current.scrollWidth}px` : 0,
              }}
            ></div>
          </div>
        </div>
      )}
    </Container>
  );
}
