import { Container, Group, Illustration, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { AddNewClientButton } from '../AddNewClientButton/AddNewClientButton';
import { useFirmClientsTableContext } from '../FirmClients/FirmClientsTableContext';

export const FirmDashboardV2TableEmptyStateGuard: React.FC = ({ children }) => {
  const { formatMessage } = useMelioIntl();
  const { shouldShowEmptyState, isLoading: isClientsLoading, error } = useFirmClientsTableContext();
  const isEmptyState = !isClientsLoading && !error && shouldShowEmptyState;

  return (
    <>
      {isEmptyState ? (
        <Container
          maxWidth="462"
          textAlign="center"
          data-testid="firm-dashboard-v2-table-empty-state-guard"
          paddingY="s"
        >
          <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical">
            <Illustration type="customer-add" size="small" />
            <Text textStyle="heading3Semi">
              {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.emptyState.header')}
            </Text>
            <Text textStyle="body3">
              {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.emptyState.description')}
            </Text>
            <AddNewClientButton withIcon={false} />
          </Group>
        </Container>
      ) : (
        children
      )}
    </>
  );
};
