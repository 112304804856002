import type { PayorVendorPaymentDetails } from '@melio/ar-domain';

import type { GuestPayorFundingSourceTypes } from '../types';

export const generateDeepLinkToPaymentRequestPayDashboard = ({
  paymentRequestId,
  paymentRequestLink,
  vendorName,
}: {
  paymentRequestId: string;
  paymentRequestLink?: string;
  vendorName?: string;
}) => {
  const isDev = /^localhost$|^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(window.location.hostname);
  const envName = isDev ? '' : window.location.hostname.split('.')[1];
  const isMelioProd = envName === 'melio';
  const isAlpha = envName === 'alpha';
  const basePath = isMelioProd
    ? 'https://app.meliopayments.com'
    : isAlpha
    ? 'https://alpha-app.meliopayments.com'
    : envName
    ? `https://app.${envName}.meliopayments.com`
    : '';
  const payload = btoa(
    JSON.stringify({ paymentRequestDetails: { ref: paymentRequestLink }, vendorDetails: { name: vendorName } })
  );
  return `${basePath}/orgs/payment-request/${paymentRequestId}/pay?payload=${payload}`;
};

export const getGuestPayorAvailableFundingSourceTypes = (
  vendorPaymentDetails?: PayorVendorPaymentDetails
): GuestPayorFundingSourceTypes[] => {
  const target: GuestPayorFundingSourceTypes[] = [];
  const { isAchAllowed, isCardAllowed } = vendorPaymentDetails?.paymentOptions ?? {};
  if (isAchAllowed) target.push('bank');
  if (isCardAllowed) target.push('card');
  return target;
};
