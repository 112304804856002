import { useAccountingFirmClientsExpanded } from '../api/entities';

/* will be refactored to use find-one api after developing dashboard v2 */
export const useFirmClient = (clientOrgId: string | undefined) => {
  const { data: clients, ...query } = useAccountingFirmClientsExpanded({ enabled: !!clientOrgId });
  const client = clients?.find((client) => client.organization.id === clientOrgId);

  return {
    ...query,
    data: client,
  };
};
