/* eslint-disable no-restricted-imports */
// eslint-disable-next-line no-restricted-imports
import { useMelioQueryClient } from '@melio/api-client';
import { Table } from '@melio/penny';
import { ActionItemWithoutIndexProps } from '@melio/penny/dist/containers/menus/ActionsDropdownMenu/ActionItem';
import { useJoinOrganizationRequest } from '@melio/platform-api';
import { AccountingFirmClient, AccountingFirmClientTypeEnum } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { Props as DashboardProps } from '../../../../types';
import { useClientActions } from '../../../hooks';

type Props = Pick<
  DashboardProps,
  'goToSettingsAndRefresh' | 'goToSettingsSubscriptionPlansAndRefresh' | 'onSwitchOrganization'
> & {
  client: AccountingFirmClient['client'];
  subscriptionInfo?: AccountingFirmClient['subscriptionInfo'];
  hasPermissions: boolean;
  clientType: AccountingFirmClient['type'];
  addToLoadingRowIds: (id: string) => void;
  removeFromLoadingRowIds: (id: string) => void;
};

export const ClientActions = ({
  client,
  subscriptionInfo,
  hasPermissions,
  clientType,
  goToSettingsSubscriptionPlansAndRefresh,
  goToSettingsAndRefresh,
  onSwitchOrganization,
  addToLoadingRowIds,
  removeFromLoadingRowIds,
}: Props) => {
  const queryClient = useMelioQueryClient();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const { resendJoinOrganizationRequestAccount, cancelJoinOrganizationRequestAccount } = useJoinOrganizationRequest({
    id: client.id,
    enabled: false,
  });
  const { goToClientCompanyPage, onGoToClientAccount, onToggleHiddenState } = useClientActions({
    goToSettingsSubscriptionPlansAndRefresh,
    goToSettingsAndRefresh,
    onSwitchOrganization,
  });

  const handleToggleHiddenState = async (client: AccountingFirmClient['client']) => {
    addToLoadingRowIds(client.id);
    await onToggleHiddenState(client);
    await queryClient.invalidateQueries('AccountingFirmClientsApi', 'collection');
    removeFromLoadingRowIds(client.id);
  };

  const handleCancelRequest = async () => {
    addToLoadingRowIds(client.id);
    try {
      await cancelJoinOrganizationRequestAccount();
      await queryClient.invalidateQueries('AccountingFirmClientsApi', 'collection');
      showMessage({
        type: 'success',
        title: formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.toast.success'),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.cancelJoinOrganizationRequestModal.toast.error'),
      });
    } finally {
      removeFromLoadingRowIds(client.id);
    }
  };

  const handleResendRequest = async () => {
    addToLoadingRowIds(client.id);
    try {
      await resendJoinOrganizationRequestAccount();
      await queryClient.invalidateQueries('AccountingFirmClientsApi', 'collection');
      showMessage({
        type: 'success',
        title: formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.toast.success'),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.resendJoinOrganizationRequestModal.toast.error'),
      });
    } finally {
      removeFromLoadingRowIds(client.id);
    }
  };

  const goToClientAccount = {
    label: formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.goToClientAccount'),
    onClick: () => void onGoToClientAccount(client.id, subscriptionInfo),
    disabled: { isDisabled: !hasPermissions },
    dataTestId: 'menu-item-go-to-client',
  };

  const editClient = {
    label: formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.editClient'),
    onClick: () => void goToClientCompanyPage(client.id),
    disabled: { isDisabled: !hasPermissions },
    dataTestId: 'menu-item-edit-client',
  };

  const toggleHidden = {
    label: client.isHidden
      ? formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.unHideClient')
      : formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.actions.hideClient'),
    onClick: () => void handleToggleHiddenState(client),
    dataTestId: 'menu-item-toggle-hidden-state',
  };

  const resendRequest = {
    label: formatMessage('widgets.joinOrganizationRequestCard.menu.resend'),
    onClick: () => void handleResendRequest(),
    dataTestId: 'menu-item-join-organization-request-resend',
  };

  const cancelRequest = {
    label: formatMessage('widgets.joinOrganizationRequestCard.menu.cancel'),
    onClick: () => void handleCancelRequest(),
    variant: 'critical',
    dataTestId: 'menu-item-join-organization-request-cancel',
  };

  const statusActions: Record<AccountingFirmClientTypeEnum, Array<ActionItemWithoutIndexProps>> = {
    client: [goToClientAccount, editClient, toggleHidden],
    joinOrganizationRequest: [resendRequest, cancelRequest],
  };

  return <Table.ActionsCell data-testid={`clients-table-actions-${client.id}`} options={statusActions[clientType]} />;
};
