/* eslint-disable max-lines */

import {
  getDefaultDeliveryPreferenceByDeliveryMethodType,
  usePaymentFeesDetails,
  usePaymentFlowContext,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { useMelioForm, useWatch } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  ApiError,
  BillSubscription,
  Payment,
  PaymentCalendarCalculationDates,
  PaymentSettingsCalculations,
  useAccountingPlatforms,
  useCheckApprovalRequirement,
  useFeeCatalog,
  useFundingSource,
  useFundingSources,
  usePaymentCalendar,
  usePaymentSettings,
  useVendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useShouldCollectLegalInfoOnPaymentFlow } from '@melio/platform-kyc';
import { useConfig } from '@melio/platform-provider';
import {
  converDateToStringRepresentation,
  useFormBanner,
  withFormBannerAnnouncementProvider,
} from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { isSameDay } from 'date-fns';
import { isNil, omit } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AddBillV2Activity } from '../../add-bill/AddBillV2/AddBillV2.activity';
import { CompleteLegalInfoActivity } from '../../business-legal-info';
import { EditBillActivity } from '../../edit-bill';
import { ReconciliationModalActivity, useReconciliationModal, VendorDetailsModalActivity } from '../../funding-sources';
import { GoodsReceivedModalScreen } from '../../funding-sources/FundingSourceSelection/screens';
import { useGoodsReceived } from '../../funding-sources/FundingSourceSelection/useGoodsReceived';
import { useVendorDetailsModal } from '../../funding-sources/FundingSourceSelection/useVendorDetailsModal.hook';
import { PaymentFlowLoader } from '../components/PaymentFlowLoader';
import {
  PaymentFlowFormFields,
  PaymentFlowOnChangeHandlers,
  PaymentFlowSubmitFields,
  PaymentSettingsState,
} from '../types';
import { DeliveryMethodChangedModal } from './components/DeliveryMethodChangedModal/DeliveryMethodChangedModal';
import { UpdatedDeliveryMethodInfo } from './components/DeliveryMethodChangedModal/types';
import { useDeliveryMethodChangedModal } from './components/DeliveryMethodChangedModal/useDeliveryMethodChangedModal/useDeliveryMethodChangedModal';
import { LoadingStateContextProvider } from './LoadingStates';
import { PaymentFlowForm } from './PaymentFlowForm/PaymentFlowForm';
import { useAmountSchemas } from './PaymentFlowForm/schema/useAmountSchemas';
import { usePaymentFlowFormSchema } from './PaymentFlowForm/schema/usePaymentFlowSchema';
import { useFrequencySelectorSettings } from './useFrequencySelectorSettings';
import { usePaymentCalendarChangeEffect } from './usePaymentCalendarChangeEffect';
import { usePaymentFlowAnalytics } from './usePaymentFlowAnalytics';
import { usePaymentFlowBillDetails } from './usePaymentFlowBillDetails';
import { usePaymentFlowDefaultValues } from './usePaymentFlowDefaultValues';
import { usePaymentFlowNavigation } from './usePaymentFlowNavigation';
import { useSubmitErrorHandlerEffect } from './useSubmitErrorHandler/useSubmitErrorHandler';
import { useCollapsedState } from './util/useCollapsedState/useCollapsedState';

export type PaymentFlowActivityProps = {
  title: string;
  defaultValues: Partial<PaymentFlowFormFields>;
  billId?: string;
  payment?: Payment;
  billSubscription?: BillSubscription;
  isSubmitting: boolean;
  submitError?: ApiError | null;
  onSubmit: (data: PaymentFlowSubmitFields) => Promise<unknown>;
  onClose: VoidFunction;
};

export const PaymentFlowActivity = withFormBannerAnnouncementProvider(
  withAnalyticsContext<PaymentFlowActivityProps>((props) => {
    const navigate = usePaymentFlowNavigation();
    const { track } = useAnalytics();
    const { showMessage } = useSystemMessage();
    const { announceBanner } = useFormBanner();
    const { formatMessage } = useMelioIntl();
    const { paymentSessionId } = usePaymentFlowContext();
    const { minDeliveryDate, maxDeliveryDate } = usePaymentSchedulingPreference();
    const {
      settings: {
        payment: {
          scheduling: { initialFormCollapsedState },
        },
      },
    } = useConfig();

    const {
      title,
      defaultValues,
      billId,
      payment,
      billSubscription,
      isSubmitting,
      submitError,
      onSubmit,
      onClose,
      setAnalyticsProperties,
    } = props;

    const [selectedPaymentDates, setSelectedPaymentDates] = useState<PaymentCalendarCalculationDates>();
    const [paymentSettings, setPaymentSettings] = useState<PaymentSettingsCalculations>();
    const [paymentSettingsState, setPaymentSettingsState] = useState<PaymentSettingsState>({
      fetchParams: defaultValues,
      triggerField: 'initial',
    });

    const { bill, file, isLoading: isLoadingBillDetails } = usePaymentFlowBillDetails({ billId });
    const { activeAccountingPlatform, isLoading: isLoadingAccountingPlatform } = useAccountingPlatforms();
    const { data: feesCatalog, isLoading: isLoadingFees } = useFeeCatalog();
    const { isValidAmount } = useAmountSchemas({ bill, payment, paymentRestrictions: paymentSettings?.restrictions });
    const { data: [paymentSettingsData] = [], isLoading: isLoadingPaymentSettings } = usePaymentSettings({
      enabled: Boolean(paymentSettingsState.fetchParams.vendorId),
      params: {
        fillWithDefaults: true,
        expand: ['fundingSourceTypesOptions', 'deliveryMethodTypeOptions', 'restrictions'],
        paymentSessionId,
      },
      payload: paymentSettingsState.fetchParams.vendorId
        ? [
            {
              vendorId: paymentSettingsState.fetchParams.vendorId,
              deliveryMethodId: paymentSettingsState.fetchParams.deliveryMethodId,
              fundingSourceId: paymentSettingsState.fetchParams.fundingSourceId,
              amountToPay:
                paymentSettingsState.fetchParams.amountToPay &&
                isValidAmount(paymentSettingsState.fetchParams.amountToPay)
                  ? Number(paymentSettingsState.fetchParams.amountToPay)
                  : 0,
              deliveryPreferenceType: paymentSettingsState.fetchParams.deliveryPreference,
              dueDate: converDateToStringRepresentation(
                paymentSettingsState.fetchParams.deliveryDate || minDeliveryDate
              ),
              invoicesData: [],
              isRecurring: paymentSettingsState.fetchParams.recurrenceType === 'recurring',
              billId,
              paymentId: payment?.id,
            },
          ]
        : [],
      onError: () => {
        showMessage({
          type: 'error',
          title: formatMessage('activities.paymentFlow.errors.default'),
        });
      },
    });
    const formCollapsedState = useCollapsedState({
      isLoading: isLoadingPaymentSettings,
      deps: {
        vendorId: paymentSettingsState.fetchParams.vendorId,
        amountToPay: paymentSettingsState.fetchParams.amountToPay
          ? Number(paymentSettingsState.fetchParams.amountToPay)
          : undefined,
      },
    });
    const { data: fundingSources = [], isFetching: isLoadingFundingSources } = useFundingSources({
      // we will fetch funding sources only if the form is initialized in expanded mode or if the form is expanded
      enabled: initialFormCollapsedState === 'full' || formCollapsedState === 'open',
      staleTime: 0, //should ignore what's in cache
    });

    const { isLoadingShouldCollectLegalInfoOnPaymentFlow, shouldCollectLegalInfoOnPaymentFlow } =
      useShouldCollectLegalInfoOnPaymentFlow({
        billIds: bill && [bill.id],
      });

    const {
      goodsReceived,
      goodsReceivedFormWasFilled,
      shouldRunGoodsReceived,
      runGoodsReceivedFlow,
      shouldOpenGoodsReceivedModal,
      goodsReceivedAmountThreshold,
      handleCloseGoodsReceived,
      handleDoneGoodsReceived,
    } = useGoodsReceived();

    const {
      shouldRunReconciliation,
      runReconciliationFlow,
      shouldShowReconciliationModal,
      onDoneReconciliationFlow,
      onCloseReconciliationFlow,
    } = useReconciliationModal();

    const { isVendorHasMissingDetails, onOpenVendorDetailsModal, vendorDetailsModalState, onCloseVendorDetailsModal } =
      useVendorDetailsModal();

    const handleSubmit: PaymentFlowActivityProps['onSubmit'] = async (data) => {
      if (!isVendorDirectoryInfoCompleted) {
        return announceBanner();
      }

      const amountToPay = Number(data.amountToPay);

      if (selectedFundingSource && amountToPay && shouldRunGoodsReceived(selectedFundingSource, amountToPay)) {
        return runGoodsReceivedFlow();
      }

      if (selectedFundingSource && shouldRunReconciliation(selectedFundingSource)) {
        return runReconciliationFlow();
      }

      if (selectedFundingSource && isVendorHasMissingDetails({ vendor: selectedVendor, selectedFundingSource })) {
        return onOpenVendorDetailsModal(selectedVendor, selectedFundingSource);
      }

      if (selectedVendor && shouldCollectLegalInfoOnPaymentFlow) {
        return navigate.toLegal();
      }

      if (data.vendorEmail && selectedVendor?.contact?.email && data.vendorEmail !== selectedVendor?.contact?.email) {
        await updateVendor({ contact: { email: data.vendorEmail } });
      }

      track('Payment', 'Click', {
        Intent: 'confirm-and-pay',
        Cta: 'confirm-and-pay',
        MemoToVendor: !!data.noteToVendor,
        VendorId: data.vendorId,
        PaymentAmount: data.amountToPay,
        PaymentMethodId: data.fundingSourceId,
        DeliveryMethodId: data.deliveryMethodId,
        DeductionDate: data.scheduleDate?.toISOString(),
        ...(recurrenceType === 'recurring'
          ? {
              StartDate: data.startDate?.toISOString(),
              PaymentFrequency: data.intervalType,
              PaymentDuration: data.endPolicy,
              EndDate: data.endDate?.toISOString(),
              NumOfOccurrences: data.numOfOccurrences,
            }
          : { DeliveryDate: data.deliveryDate?.toISOString() }),
      });

      return onSubmit({
        ...data,
        feeQuoteId,
        datesQuoteId: selectedPaymentDates?.quoteId,
      });
    };

    const form = useMelioForm<PaymentFlowFormFields>({
      onSubmit: handleSubmit,
      defaultValues: usePaymentFlowDefaultValues({ defaultValues }),
      schema: usePaymentFlowFormSchema({
        bill,
        payment,
        billSubscription,
        paymentRestrictions: paymentSettings?.restrictions,
      }),
      isSaving: isSubmitting,
    });

    const { control, getValues, setValue, trigger } = form;
    const [
      vendorId,
      amountToPay,
      recurrenceType,
      fundingSourceId,
      deliveryMethodId,
      deliveryPreference,
      deliveryDate,
      startDate,
      intervalType,
      endPolicy,
      endDate,
      numOfOccurrences,
    ] = useWatch({
      control,
      name: [
        'vendorId',
        'amountToPay',
        'recurrenceType',
        'fundingSourceId',
        'deliveryMethodId',
        'deliveryPreference',
        'deliveryDate',
        'startDate',
        'intervalType',
        'endPolicy',
        'endDate',
        'numOfOccurrences',
      ],
    });

    const setValues = useCallback(
      (formValues: PaymentFlowFormFields) => {
        Object.entries(formValues).forEach(([key, value]) => {
          setValue(key as keyof PaymentFlowFormFields, value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
          });
        });
      },
      [setValue]
    );

    const { data: selectedFundingSource } = useFundingSource({
      enabled: Boolean(fundingSourceId),
      id: fundingSourceId ?? undefined,
    });

    const {
      data: selectedVendor,
      update: updateVendor,
      isLoading: isLoadingVendor,
    } = useVendor({
      enabled: Boolean(vendorId),
      id: vendorId,
      onUpdateError: () =>
        showMessage({
          type: 'error',
          title: formatMessage('activities.paymentFlow.errors.default'),
        }),
    });

    const { data: approvalRequirement } = useCheckApprovalRequirement({
      enabled: Boolean(vendorId),
      amount: Number(amountToPay) || 0,
      vendorId: vendorId || '',
    });

    const { data: paymentCalendar, isLoading: isLoadingPaymentCalendar } = usePaymentCalendar({
      payload: {
        startDate: minDeliveryDate,
        endDate: maxDeliveryDate,
        ...(amountToPay && isValidAmount(amountToPay) && { amount: Number(amountToPay) }),
        fundingSourceId,
        vendorId,
        deliveryMethodId,
        deliveryPreference,
      },
      params: { paymentSessionId },
    });

    const selectedDeliveryMethod = selectedVendor?.deliveryMethods.find(
      (deliveryMethod) => deliveryMethod.id === deliveryMethodId
    );

    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(selectedVendor);

    const frequencySelectorSettings = useFrequencySelectorSettings({
      bill,
      payment,
      billSubscription,
      selectedVendor,
      selectedDeliveryMethod,
      paymentRestrictions: paymentSettings?.restrictions,
    });

    const paymentSubmitErrors = useMemo(() => {
      const { scheduleDate, startDate, deliveryDate, deliveryMethodId, recurrenceType, amountToPay } = getValues();
      const _deliveryDate = recurrenceType === 'recurring' ? startDate : deliveryDate;

      return [
        {
          error: submitError,
          paymentData: {
            deliveryDate: _deliveryDate,
            deliveryMethodId,
            scheduleDate,
            amountToPay,
            invoiceNumber: bill?.invoice.number,
            id: selectedVendor?.id,
          },
          vendor: selectedVendor,
        },
      ];
    }, [bill?.invoice.number, getValues, submitError, selectedVendor]);

    const deliveryMethodChangedModal = useDeliveryMethodChangedModal({
      paymentSubmitErrors,
      onSubmit: (updatedDeliveryMethodInfo: UpdatedDeliveryMethodInfo[]) => {
        const updatedFields = omit({ ...updatedDeliveryMethodInfo[0] }, 'id');
        return handleSubmit({ ...getValues(), ...updatedFields });
      },
    });

    useEffect(() => {
      if (paymentSettingsData) {
        setPaymentSettings(paymentSettingsData);
        setValues({
          fundingSourceId: paymentSettingsData.fundingSourceId,
          deliveryMethodId: paymentSettingsData.deliveryMethodId,
          deliveryPreference: paymentSettingsData.deliveryPreferenceType,
        });

        if (!isNil(amountToPay) && amountToPay !== '') {
          void trigger('amountToPay');
        }
      }
    }, [paymentSettingsData, setValues, trigger, amountToPay]);

    useEffect(() => {
      if (paymentCalendar) {
        const _deliveryDate = recurrenceType === 'recurring' ? startDate : deliveryDate;
        const dates = _deliveryDate
          ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, _deliveryDate))
          : undefined;

        setSelectedPaymentDates(dates);
      }
    }, [paymentCalendar, startDate, deliveryDate, recurrenceType]);

    usePaymentCalendarChangeEffect({
      form,
      paymentCalendar,
      selectedFundingSource,
    });

    useSubmitErrorHandlerEffect(submitError, { fundingSourceId, fundingSources });

    usePaymentFlowAnalytics({
      setAnalyticsProperties,
      payment,
      bill,
      billSubscription,
      vendor: selectedVendor,
      fundingSource: selectedFundingSource,
      deliveryMethod: selectedDeliveryMethod,
      deliveryPreference,
      selectedPaymentDates,
      amountToPay,
      recurrenceType,
      numOfOccurrences,
      intervalType,
      endPolicy,
      endDate,
    });

    const { quoteId: feeQuoteId } = usePaymentFeesDetails({
      amount: Number(amountToPay),
      deliveryPreferenceType: deliveryPreference,
      deliveryMethodId,
      fundingSourceId,
      billId,
    });

    const onChangeCallback = (
      triggerField: PaymentSettingsState['triggerField'],
      overrides?: Partial<PaymentSettingsState['fetchParams']>
    ) => {
      const [
        vendorId,
        amountToPay,
        fundingSourceId,
        deliveryMethodId,
        deliveryPreference,
        recurrenceType,
        deliveryDate,
        startDate,
      ] = getValues([
        'vendorId',
        'amountToPay',
        'fundingSourceId',
        'deliveryMethodId',
        'deliveryPreference',
        'recurrenceType',
        'deliveryDate',
        'startDate',
      ]);

      setPaymentSettingsState({
        fetchParams: {
          vendorId,
          amountToPay,
          fundingSourceId,
          deliveryMethodId,
          deliveryPreference,
          recurrenceType,
          deliveryDate: recurrenceType === 'recurring' ? startDate : deliveryDate,
          ...overrides,
        },
        triggerField,
      });
    };

    const onVendorChange: PaymentFlowOnChangeHandlers['onVendorChange'] = (_vendorId) => {
      if (_vendorId === vendorId) {
        /**
         * This condition prevents unwanted calls to this function caused by an onChange event triggered on mount in VendorSelect.widget.tsx.
         * onChange triggered on mount is a result of an accessibility fix.
         */
        return;
      }
      setValues({
        vendorId: _vendorId,
        deliveryMethodId: undefined,
        deliveryPreference: undefined,
        scheduleDate: undefined,
        recurrenceType: defaultValues.recurrenceType || 'one_time',
      });

      onChangeCallback('vendor', { fundingSourceId: undefined });
    };

    const onAmountChange: PaymentFlowOnChangeHandlers['onAmountChange'] = (amountToPay) => {
      // amount input sends onChange events even on blur, so we need to check if the value has changed
      if (Number(paymentSettingsState.fetchParams.amountToPay) === Number(amountToPay)) {
        return;
      }
      setValues({
        amountToPay,
      });
      onChangeCallback('amount');
    };

    const onFundingSourceChange: PaymentFlowOnChangeHandlers['onFundingSourceChange'] = (fundingSourceId) => {
      setValues({
        fundingSourceId,
        scheduleDate: undefined,
      });
      onChangeCallback('fs');
    };

    const onDeliveryMethodChange: PaymentFlowOnChangeHandlers['onDeliveryMethodChange'] = (deliveryMethod) => {
      setValues({
        deliveryPreference: deliveryMethod
          ? getDefaultDeliveryPreferenceByDeliveryMethodType(deliveryMethod.type)
          : undefined,
        deliveryMethodId: deliveryMethod?.id,
        scheduleDate: undefined,
      });
      onChangeCallback('dm');
    };

    const onDeliveryDateChange: PaymentFlowOnChangeHandlers['onDeliveryDateChange'] = (deliveryDate) => {
      const dates = deliveryDate
        ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, deliveryDate))
        : null;

      if (dates) {
        setValues({
          deliveryDate: dates.minDeliveryDate,
          scheduleDate: dates.scheduleDate,
        });
        onChangeCallback('date');
      } else {
        setValues({
          deliveryDate,
          scheduleDate: undefined,
        });
      }
    };

    const onStartDateChange: PaymentFlowOnChangeHandlers['onStartDateChange'] = (startDate) => {
      const dates = startDate
        ? paymentCalendar?.dates.find((dates) => isSameDay(dates.minDeliveryDate, startDate))
        : null;

      if (dates) {
        setValues({
          startDate: dates.minDeliveryDate,
          scheduleDate: dates.scheduleDate,
        });
        onChangeCallback('date');
      } else {
        setValues({
          startDate,
          scheduleDate: undefined,
        });
      }
    };

    const onRecurrenceTypeChange: PaymentFlowOnChangeHandlers['onRecurrenceTypeChange'] = (recurrenceType) => {
      setValues({
        recurrenceType,
        scheduleDate: undefined,
      });
      onChangeCallback('recurrenceType');
    };

    const onBillChange: PaymentFlowOnChangeHandlers['onBillChange'] = (bill) => {
      setValues({
        vendorId: bill.vendorId,
        amountToPay: bill.amount,
        noteToVendor: bill.invoiceNumber
          ? formatMessage('activities.paymentFlow.form.content.defaultMemo', { invoiceNumber: bill.invoiceNumber })
          : null,
      });
      onChangeCallback('bill', {
        fundingSourceId: undefined,
        deliveryMethodId: undefined,
        deliveryPreference: undefined,
      });
    };

    const isLoading =
      isLoadingShouldCollectLegalInfoOnPaymentFlow ||
      isLoadingAccountingPlatform ||
      isLoadingBillDetails ||
      isLoadingFees ||
      (isLoadingFundingSources && initialFormCollapsedState === 'full');

    if (isLoading) {
      return <PaymentFlowLoader />;
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <>
              <LoadingStateContextProvider
                isLoading={isLoadingPaymentSettings}
                triggerField={paymentSettingsState.triggerField}
              >
                <PaymentFlowForm
                  collapsedState={formCollapsedState}
                  form={form}
                  handlers={{
                    onFundingSourceChange,
                    onVendorChange,
                    onDeliveryMethodChange,
                    onAmountChange,
                    onDeliveryDateChange,
                    onBillChange,
                    onRecurrenceTypeChange,
                    onStartDateChange,
                  }}
                  onBillButtonClick={() => navigate.toBill(bill)}
                  fundingSources={fundingSources}
                  isFundingSourcesLoading={isLoadingFundingSources}
                  selectedVendor={selectedVendor}
                  paymentCalendarDates={paymentCalendar?.dates}
                  selectedPaymentDates={selectedPaymentDates}
                  payment={payment}
                  bill={bill}
                  file={file}
                  billSubscription={billSubscription}
                  paymentSettings={paymentSettings}
                  frequencySelectorSettings={frequencySelectorSettings}
                  feeCatalog={feesCatalog}
                  isDatesLoading={isLoadingPaymentCalendar}
                  isSubmitting={isSubmitting}
                  isSubmitButtonDisabled={isLoadingVendor || isLoadingPaymentSettings || isLoadingPaymentCalendar}
                  onClose={onClose}
                  title={title}
                  shouldRunReconciliation={shouldRunReconciliation}
                  runReconciliationFlow={runReconciliationFlow}
                  isGoodsReceivedConfirmed={goodsReceivedFormWasFilled ? goodsReceived : undefined}
                  activeAccountingPlatform={activeAccountingPlatform}
                  approvalRequirementStatus={approvalRequirement?.approvalRequirementStatus}
                />
              </LoadingStateContextProvider>
              <GoodsReceivedModalScreen
                amountThreshold={goodsReceivedAmountThreshold}
                isOpen={shouldOpenGoodsReceivedModal}
                onClose={handleCloseGoodsReceived()}
                onDone={handleDoneGoodsReceived}
              />
              {fundingSourceId && (
                <ReconciliationModalActivity
                  selectedFundingSourceId={fundingSourceId}
                  isOpen={shouldShowReconciliationModal}
                  onClose={onCloseReconciliationFlow()}
                  onDone={onDoneReconciliationFlow}
                />
              )}
              {vendorId && !!selectedFundingSource && (
                <VendorDetailsModalActivity
                  onClose={onCloseVendorDetailsModal}
                  vendorDetailsModalState={vendorDetailsModalState}
                  onError={onCloseVendorDetailsModal}
                  onDone={onCloseVendorDetailsModal}
                  vendorId={vendorId}
                  onLoadingVendorDetailsFailure={() => {
                    showMessage({
                      type: 'error',
                      title: formatMessage('activities.paymentFlow.errors.paymentMethodUnavailable.title'),
                    });
                  }}
                />
              )}
              <DeliveryMethodChangedModal
                isOpen={deliveryMethodChangedModal.open}
                deliveryMethodChangeTableData={deliveryMethodChangedModal.deliveryMethodChangeTableData}
                headers={deliveryMethodChangedModal.headers}
                onSubmit={deliveryMethodChangedModal.confirm}
                isSubmitting={isSubmitting}
                onClose={deliveryMethodChangedModal.close}
              />
            </>
          }
        />
        <Route
          path="/bill/add"
          element={
            <AddBillV2Activity
              initialValues={{
                vendorId,
                amount: amountToPay,
              }}
              onDone={(data, billId, saveType, message) => {
                if (billId) {
                  if (saveType === 'close') {
                    return onClose();
                  }
                  onBillChange({
                    vendorId: data.vendorId,
                    amount: data.billAmount,
                    invoiceNumber: data.billNumber,
                  });
                  navigate.toRoot({ search: new URLSearchParams({ billId }) });
                  if (message) {
                    showMessage(message);
                  }
                }
              }}
              onClose={navigate.toRoot}
              onBack={navigate.toRoot}
              hideSecondaryButton
            />
          }
        />
        <Route
          path="/bill/edit"
          element={
            bill ? (
              <EditBillActivity
                id={bill.id}
                onClose={navigate.toRoot}
                onBack={navigate.toRoot}
                onDone={(data, saveType) => {
                  if (saveType === 'close') {
                    return onClose();
                  }
                  onBillChange({
                    vendorId: data.vendorId,
                    amount: data.balance?.toString(),
                    invoiceNumber: data.invoiceNumber,
                  });
                  navigate.toRoot();
                }}
                hideSecondaryButton
              />
            ) : (
              <Navigate to=".." />
            )
          }
        />
        <Route
          path="/legal"
          element={
            <CompleteLegalInfoActivity
              onBack={navigate.toRoot}
              onClose={navigate.toRoot}
              onDone={() => handleSubmit(form.getValues())}
            />
          }
        />

        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    );
  })
);

PaymentFlowActivity.displayName = 'PaymentFlowActivity';
