import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { FormattedMessage, MessageKey, useMelioIntl } from './i18n';

export type SharedEmptyStateProps = {
  search?: string;
  filters?: Record<string, unknown>;
  searchMessage: MessageKey;
  defaultMessage: MessageKey;
  'data-testid': string;
  actions?: {
    label: MessageKey;
    onClick: () => void;
    variant?: 'primary' | 'secondary';
    testId: string;
  }[];
};

export const SharedEmptyState = forwardRef<SharedEmptyStateProps>(
  (
    { search, filters = {}, defaultMessage, searchMessage, actions = [], ['data-testid']: dataTestId, ...props },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const hasOtherFilters = !isEmpty(filters);
    const showSearchMessage = search && !hasOtherFilters;

    return (
      <Container
        overflow="initial"
        paddingX="s"
        data-component={SharedEmptyState.displayName}
        paddingY="xxxl"
        {...props}
        ref={ref}
        data-testid={dataTestId}
      >
        <Group spacing="m" alignItems="center" justifyContent="center" variant="vertical">
          <Illustration type="no-items" size="medium" aria-hidden />
          <Text>
            {showSearchMessage ? formatMessage(searchMessage, { search }) : <FormattedMessage id={defaultMessage} />}
          </Text>
          {actions.length > 0 && (
            <Group spacing="xs" alignItems="center" justifyContent="center" variant="horizontal">
              {actions.map(({ label, onClick, variant = 'primary', testId }) => (
                <Button
                  key={testId}
                  variant={variant}
                  label={formatMessage(label)}
                  onClick={onClick}
                  data-testid={testId}
                />
              ))}
            </Group>
          )}
        </Group>
      </Container>
    );
  }
);

SharedEmptyState.displayName = 'SharedEmptyState';
