import {
  Button,
  Container,
  Group,
  GroupProps,
  Illustration,
  IllustrationProps,
  useBreakpoint,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type Props = {
  companyName?: string;
  onDone: VoidFunction;
};

export const JoinOrganizationRequestCreatedScreen = withAnalyticsContext<Props>(
  ({ companyName, onDone, setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'request-pending-approval',
      CompanyOwner: false,
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const illustrationBottomGap = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 's', m: 'm' });
    const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
    const { isExtraSmallScreen } = useBreakpoint();

    const handleDone = () => {
      track('User', 'Click', {
        Cta: 'go-to-companies',
      });
      onDone();
    };

    return (
      <Layout
        maxWidth="600px"
        data-component="AddCompanyActivity.JoinOrganizationRequestCreatedScreen"
        data-testid="add-company-activity-join-organization-request-created-screen"
        footer={{
          isSticky: isExtraSmallScreen,
          content: (
            <Container justifyContent="center">
              <Button
                variant="primary"
                isFullWidth={isExtraSmallScreen}
                size="large"
                data-testid="layout-next-button"
                label={formatMessage('activities.addCompany.screens.joinOrganizationRequestCreated.backToPay')}
                onClick={handleDone}
              />
            </Container>
          ),
        }}
        {...props}
      >
        <Container paddingTop="xxl">
          <Group variant="vertical" spacing={illustrationBottomGap} alignItems="center">
            <Illustration type="small-business" size={illustrationSize} />
            <Group variant="vertical">
              <NewSinglePaymentStepLayout.Title textAlign="center">
                {formatMessage('activities.addCompany.screens.joinOrganizationRequestCreated.title')}
              </NewSinglePaymentStepLayout.Title>
              <NewSinglePaymentStepLayout.Description data-testid="layout-description" textAlign="center">
                {formatMessage('activities.addCompany.screens.joinOrganizationRequestCreated.description', {
                  companyName,
                })}
              </NewSinglePaymentStepLayout.Description>
            </Group>
          </Group>
        </Container>
      </Layout>
    );
  }
);

JoinOrganizationRequestCreatedScreen.displayName = 'AddCompanyActivity.JoinOrganizationRequestCreatedScreen';
