import { Tier } from '../api';

// Add firm plans here (cross partners)
const accountantFirmClientPlanTiers: Array<Tier> = [
  Tier.MelioAccountingClientCore,
  Tier.MelioAccountingClientBoost,
  Tier.MelioAccountingClientUnlimitedAch,
];
const accountantFirmPlanTiers: Array<Tier> = [Tier.MelioVip];

export const isAccountantFirmClientPlanTier = (tier: Tier) => accountantFirmClientPlanTiers.includes(tier);
export const isAccountantFirmPlanTier = (tier: Tier) => accountantFirmPlanTiers.includes(tier);
