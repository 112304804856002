import { Box } from '@chakra-ui/react';
import { Group } from '@melio/penny';
import { EventProperties, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useEffect, useMemo } from 'react';
import FocusLock from 'react-focus-lock';
import { TooltipRenderProps } from 'react-joyride';

import { StepperButtonTour } from './StepperButtonsTour';

type JoiRideToolTipProps = {
  joiRideProps: TooltipRenderProps;
  stepIndex: number;
  numberOfSteps: number;
  setStepIndex: (step: number) => void;
  onDone: () => void;
  analyticsProperties: EventProperties;
};

export const JoiRideToolTip = withAnalyticsContext<JoiRideToolTipProps>(
  ({ stepIndex, numberOfSteps, setStepIndex, onDone, joiRideProps, analyticsProperties, setAnalyticsProperties }) => {
    const kababCaseTitle = useMemo(
      () =>
        (joiRideProps.step.title as string)
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/\s+/g, '-')
          .toLowerCase(),
      [joiRideProps.step.title]
    );

    useEffect(() => {
      setTimeout(() => {
        //we need to do it for ada react joiride support focus only if you use there tool tip
        const tooltipWrapper = document.getElementById('migrated-user-tour-tooltip');
        if (tooltipWrapper) {
          tooltipWrapper.focus();
        }
      }, 500);
    }, []);

    setAnalyticsProperties({ ...analyticsProperties, PageName: kababCaseTitle });

    const { track } = useAnalytics();

    useEffect(() => {
      track('Dashboard', 'View', {
        StepOfTotal: `${stepIndex + 1}/${numberOfSteps}`,
      });
    }, [stepIndex, track, numberOfSteps]);

    return (
      <FocusLock>
        <Box
          backgroundColor="semantic.surface.inverse"
          padding="16px"
          borderRadius="8px"
          maxW="264"
          {...joiRideProps.tooltipProps}
          aria-label={joiRideProps.step.title as string}
          id="migrated-user-tour-tooltip"
          role="dialog"
          tabIndex={-1}
        >
          <Group data-testid="joi-ride-tool-tip" variant="vertical" spacing="s">
            {joiRideProps.step.content}

            <StepperButtonTour
              currentStep={stepIndex}
              numberOfSteps={numberOfSteps}
              setStep={setStepIndex}
              onDone={onDone}
            />
          </Group>
        </Box>
      </FocusLock>
    );
  }
);
