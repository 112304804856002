import { TabFilter } from '../components/Filters/types';
import { NestedFiltersProps } from './types';

export const getSelectedOptionsFromActiveFilter = <T extends string>(
  filter?: TabFilter<T>,
  activeFilters?: NestedFiltersProps<T>['activeFilters']
) => {
  if (!filter || !activeFilters) {
    return [];
  }
  return filter.options
    .filter((option) => activeFilters[filter.key]?.includes(option.value))
    .map((option) => option.value);
};

export const getFilterByKey = <T extends string>(filters: TabFilter<T>[], key: string) =>
  filters.find((f) => f.key === key);

const formatDate = (dateStr: string) => {
  const [month, day, year] = dateStr.split('/');
  if (month && day && year) {
    return `${year}-${month}-${day}`;
  }
  return dateStr;
};

export const formatDateRangeStrToDates = (dateRange: string) => {
  const [startDateStr, endDateStr] = dateRange.split('-');
  if (startDateStr && endDateStr) {
    const startFormatted = formatDate(startDateStr);
    const endFormatted = formatDate(endDateStr);
    return { startFormatted, endFormatted };
  } else {
    return { startFormatted: '', endFormatted: '' };
  }
};
