import { useToast } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { usePayment, usePaymentIntent } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useCreatePaymentIntentFromPayment } from '../../../utils/useCreatePaymentIntentFromPayment';
import { SinglePaymentFlowActivity, SinglePaymentFlowActivityProps } from '../SinglePaymentFlow';

export type EditSinglePaymentActivityProps = Omit<
  SinglePaymentFlowActivityProps,
  'actionToPerform' | 'onConfirm' | 'paymentIntentModel' | 'paymentIntentId'
> & {
  paymentId: string;
};

export const EditSinglePaymentActivity = withAnalyticsContext<EditSinglePaymentActivityProps>(
  ({ paymentId, onError, isLoading, ...props }) => {
    const {
      update: updatePayment,
      isMutating: isUpdatingPayment,
      data: payment,
    } = usePayment({ id: paymentId, enabled: false });
    const [addDeliveryDateToCreateAndUpdate] = useDevFeature<boolean>(
      FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
      false
    );
    const { toast } = useToast();

    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message || 'Failed to create the payment intent' });
      onError?.(error);
    };

    const { paymentIntentId, isLoading: isLoadingPaymentIntentFromPayment } = useCreatePaymentIntentFromPayment({
      paymentId,
      onError: handleFail,
    });

    const analyticsContext = {
      PaymentIntentId: paymentIntentId,
      IsEditFlow: 'True',
      PaymentId: paymentId,
      BillIds: payment?.bills?.map((bill) => bill.id),
      IsCombined: payment?.bills && payment?.bills.length > 1,
    };

    useAnalyticsContext({ globalProperties: analyticsContext });

    const paymentIntentModel = usePaymentIntent({ id: paymentIntentId });

    const {
      data: paymentIntent,
      isLoading: isLoadingPaymentIntent,
      isUpdating: isUpdatingPaymentIntent,
    } = paymentIntentModel;

    const handleConfirm = () =>
      updatePayment({
        scheduledDate: paymentIntent?.effectiveScheduledDate?.toISOString(),
        deliveryDate: addDeliveryDateToCreateAndUpdate
          ? paymentIntent?.effectiveDeliveryDate?.toISOString()
          : undefined,
        fundingSourceId: paymentIntent?.fundingSourceId || void 0,
        deliveryMethodId: paymentIntent?.deliveryMethodId || void 0,
        deliveryPreferenceType: paymentIntent?.selectedDeliveryPreferenceType || void 0,
        note: paymentIntent?.noteToVendor || void 0,
        paymentPurpose: paymentIntent?.paymentPurpose,
        datesQuoteId: paymentIntent?.deliveryPreferenceOptions?.find(
          ({ type }) => type === paymentIntent?.selectedDeliveryPreferenceType
        )?.quoteId,
      });

    return (
      <SinglePaymentFlowActivity
        {...props}
        onError={onError}
        actionToPerform="edit"
        paymentIntentId={paymentIntentId}
        onConfirm={handleConfirm}
        isLoading={
          isLoading ||
          isLoadingPaymentIntent ||
          isUpdatingPaymentIntent ||
          isUpdatingPayment ||
          isLoadingPaymentIntentFromPayment
        }
        paymentIntentModel={paymentIntentModel}
      />
    );
  }
);
