import {
  Button,
  Collapse,
  Container,
  Form,
  FormField,
  Group,
  IconButton,
  NakedButton,
  Skeleton,
  Text,
  TextField,
  useMelioForm,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { PromoCode, PromoCodeStatusEnum, SubscriptionBillingCycleEnum } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useOneTimeEffect } from '@melio/platform-utils';
import React, { KeyboardEvent, useState } from 'react';

import { SubscriptionPreview, usePromoCode } from '../../../../api';
import { usePromoCodeUpToPeriodsTitle } from '../hooks/usePromoCodeUpToPeriods';

type SubscriptionCheckoutPromoCodeProps = {
  promoCode?: string | null;
  isLoading: boolean;
  shouldPrefetchPromoCode: boolean;
  preview?: SubscriptionPreview;
  onChangePromoCode: (promoCode?: string) => void;
  onDoneFetchingPromoCodeData: () => void;
  currentCycle: SubscriptionBillingCycleEnum;
  isNestedForm?: boolean; //  temporary fix to support nested form structure - will be fixed on:https://meliorisk.atlassian.net/browse/ME-74828
};
export const SubscriptionCheckoutPromoCode = ({
  promoCode,
  shouldPrefetchPromoCode,
  isLoading,
  preview,
  onChangePromoCode,
  onDoneFetchingPromoCodeData,
  currentCycle,
  isNestedForm,
}: SubscriptionCheckoutPromoCodeProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const [promoCodeData, setPromoCodeData] = useState<PromoCode | undefined>();
  const [isFormDisabled, setIsFormDisabled] = useState<boolean | undefined>(!promoCode);
  const [isPromoCodeFetching, setIsPromoCodeFetching] = useState<boolean>(false);
  const [showPromoCodeInput, setShowPromoCodeInput] = useState<boolean>(!!promoCode);
  const [errorPromoCode, setErrorPromoCode] = useState<string | undefined>();
  const getPromoCode = usePromoCode();
  const upToPeriodsTitle = usePromoCodeUpToPeriodsTitle({
    promoCode: promoCodeData,
    currentCycle,
  });

  const { track } = useAnalytics();
  const { discounts } = preview || {};
  const discount = discounts?.reduce((acc, curr) => acc + curr.amount, 0) || 0;

  const getErrorMessage = (status: PromoCodeStatusEnum) => {
    const errorMessage = status === PromoCodeStatusEnum.Expired ? 'expired' : 'invalid';

    return formatMessage(`activities.subscription.checkout.summary.promocode.error.${errorMessage}`);
  };

  const trackPromoCodeResult = ({ status, errorMessage }: { status: PromoCodeStatusEnum; errorMessage?: string }) => {
    track('Organization', 'Status', {
      Intent: 'add-promo',
      Cta: 'apply',
      Status: status === 'valid' ? 'success' : 'failure',
      ...(status !== 'valid' && { ErrorType: errorMessage || getErrorMessage(status) }),
    });
  };

  const updateData = (data: PromoCode) => {
    if (data.status === PromoCodeStatusEnum.Valid) {
      setPromoCodeData(data);
      onChangePromoCode(data.code);
    } else {
      const errorMessage = getErrorMessage(data.status);

      setPromoCodeData(undefined);
      setErrorPromoCode(errorMessage);
      onChangePromoCode();
    }

    trackPromoCodeResult({ status: data.status });
  };

  const fetchPromoCode = (promoCode?: string | null) => {
    if (!promoCode) {
      return;
    }
    setIsPromoCodeFetching(true);
    getPromoCode(promoCode)
      .then(updateData)
      .catch(() => {
        const errorMessage = formatMessage('activities.subscription.checkout.summary.promocode.notfound');
        setErrorPromoCode(errorMessage);
        onChangePromoCode();
        trackPromoCodeResult({ status: 'invalid', errorMessage });
      })
      .finally(() => {
        setIsPromoCodeFetching(false);
        onDoneFetchingPromoCodeData();
      });
  };

  useOneTimeEffect(
    () => {
      fetchPromoCode(promoCode);
    },
    () => !!promoCode && !!shouldPrefetchPromoCode,
    [shouldPrefetchPromoCode, promoCode]
  );

  const handleSubmit = ({ promoCode }: { promoCode: string }) => {
    track('Organization', 'Click', {
      Intent: 'add-promo',
      Cta: 'apply',
    });
    fetchPromoCode(promoCode);
  };

  const { registerField, formProps, setValue, watch } = useMelioForm({
    onSubmit: handleSubmit,
    defaultValues: { promoCode: promoCode || '' },
  });

  const togglePromoCodeInput = () => {
    if (!showPromoCodeInput) {
      track('Organization', 'Click', {
        Intent: 'add-promo',
        Cta: 'add-promo-code',
      });
    }
    setShowPromoCodeInput((prev) => !prev);
  };

  const promoCodeChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: currentVal } = e.target;
    setIsFormDisabled(!currentVal);
    setValue('promoCode', currentVal);
    setErrorPromoCode(undefined);
    setPromoCodeData(undefined);
  };

  const removePromoCode = () => {
    setErrorPromoCode(undefined);
    setShowPromoCodeInput(true);
    setPromoCodeData(undefined);
    setIsFormDisabled(true);

    onChangePromoCode();
    setValue('promoCode', '');
    track('Organization', 'Click', {
      Intent: 'remove-promo',
      Cta: 'remove-promo',
    });
  };

  const handleNestedFormKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit({ promoCode: watch('promoCode') });
    }
  };

  if (isLoading || (isPromoCodeFetching && shouldPrefetchPromoCode)) {
    return (
      <Container paddingY="xs">
        <Skeleton height="12px" />
      </Container>
    );
  }

  if (promoCodeData) {
    return (
      <Container>
        <Group variant="vertical" spacing="xxxs">
          <Group justifyContent="space-between">
            <Button
              data-testid="subscription-checkout-promocode"
              variant="tertiary"
              label={promoCodeData.code}
              rightElement={
                <IconButton
                  data-testid="subscription-checkout-promocode-remove"
                  icon="close"
                  variant="naked"
                  size="small"
                  color="inherit"
                  onClick={removePromoCode}
                  aria-hidden
                />
              }
            />
            <Text color="global.success.700" textStyle="body3" data-testid="subscription-checkout-promocode-discount">
              {formatCurrency(discount)}
            </Text>
          </Group>
          {upToPeriodsTitle && (
            <Text
              color="global.neutral.800"
              textStyle="body4"
              data-testid="subscription-checkout-promocode-up-to-periods"
            >
              {upToPeriodsTitle}
            </Text>
          )}
        </Group>

        {promoCodeData?.message && (
          <Container paddingTop="xs">
            <Text color="global.neutral.800" textStyle="body4" data-testid="subscription-checkout-promocode-message">
              {promoCodeData?.message}
            </Text>
          </Container>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <NakedButton
        label={formatMessage('activities.subscription.checkout.summary.promocode.label')}
        variant="secondary"
        data-testid="subscription-checkout-toggle-promocode"
        onClick={togglePromoCodeInput}
      />
      <Collapse in={showPromoCodeInput} data-testid="subscription-checkout-promocode-form">
        <Form paddingTop="xs" paddingLeft="xxs" paddingBottom="xxs" columns={3} gap="s" {...formProps}>
          <FormField
            size="small"
            onKeyPress={isNestedForm ? handleNestedFormKeyPress : undefined}
            onChange={promoCodeChanged}
            labelProps={{
              label: formatMessage('activities.subscription.checkout.summary.promocode.label'),
              isHidden: true,
            }}
            data-testid="promoCode"
            {...registerField('promoCode')}
            colSpan={2}
            render={(formFieldProps) => <TextField {...formFieldProps} />}
            error={(errorPromoCode && { message: errorPromoCode }) || undefined}
          />
          <Button
            label={formatMessage('activities.subscription.checkout.summary.promocode.submit')}
            onClick={(e) => {
              formProps.onSubmit(e);
            }}
            isDisabled={isFormDisabled || !!errorPromoCode}
            isLoading={isPromoCodeFetching}
            data-testid="subscription-checkout-apply-promocode"
          />
        </Form>
      </Collapse>
    </Container>
  );
};
