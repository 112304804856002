import {
  useAROrganizationPreferences,
  useInvoicePreference,
  useMelioIntl,
  useReceivingMethods,
  useSystemMessage,
} from '@melio/ar-domain';
import { Loader } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { every, matchesProperty } from 'lodash';

import { useHandleUpdateInvoicePreferences } from './hooks';
import {
  AcceptedPaymentMethodsScreen,
  GlobalFeeAbsorptionSettingsModalScreen,
  RemoveCustomPaymentDetailsModalScreen,
} from './screens';
import { FeeAbsorptionSettings, FeesPaidBy } from './types';

export type AcceptedPaymentMethodsActivityProps = {
  onError?: ARErrorFunction;
  onEditCustomPaymentDetails: VoidFunction;
};

export const AcceptedPaymentMethodsActivity = forwardRef<AcceptedPaymentMethodsActivityProps>(
  ({ onError, onEditCustomPaymentDetails }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { triggerMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { data: invoicePreferences, isFetching, isMutating } = useInvoicePreference({ onError });
    const {
      data: orgPreferences,
      isLoading: isOrganizationPreferencesLoading,
      create: createOrganizationPreferences,
      refetch: refetchOrganizationPreferences,
    } = useAROrganizationPreferences();
    const [isSettingFeeAbsorption, setIsSettingFeeAbsorption] = useBoolean(false);

    const { data: receivingMethods, isLoading: isReceivingMethodsLoading } = useReceivingMethods();

    const isVendorAbsorbedFee = !!orgPreferences?.find(
      ({ key, value }) => key === 'isVendorAbsorbedFee' && value === 'true'
    );

    const hasVerifiedDeliveryMethod = !every(
      receivingMethods,
      (dm) =>
        matchesProperty('details.verifiedStatus', 'plaid-not-verified')(dm) ||
        matchesProperty('details.verifiedStatus', 'not-verified')(dm)
    );

    const feesPaidBy: FeesPaidBy = isVendorAbsorbedFee ? 'vendor' : 'payor';

    const [isRemoveCustomPaymentDetailsModalOpen, removeCustomPaymentDetailsModalOpen] = useBoolean(false);
    const [isGlobalFeeAbsorptionSettingsModalOpen, globalFeeAbsorptionSettingsModalOpen] = useBoolean(false);
    const handleUpdateInvoicePreferences = useHandleUpdateInvoicePreferences(onError);

    const handleRemoveCustomPaymentDetails = () => {
      handleUpdateInvoicePreferences(
        { customPayInstructions: '' },
        'ar.settings.activities.acceptedPaymentMethods.removeCustomPaymentDetails.toast.success.label',
        'ar.settings.activities.acceptedPaymentMethods.removeCustomPaymentDetails.toast.failure.label'
      ).finally(() => removeCustomPaymentDetailsModalOpen.off());
    };

    const handleTogglePaymentOption = (key: 'allowACHPayment' | 'allowCardPayment', isEnabled: boolean) => {
      handleUpdateInvoicePreferences(
        { [key]: isEnabled },
        isEnabled
          ? 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.activated.success.label'
          : 'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.deactivated.success.label',
        'ar.settings.activities.acceptedPaymentMethods.editPaymentOption.toast.failure.label'
      ).finally(() => removeCustomPaymentDetailsModalOpen.off());
    };

    const handleOnEditFeeAbsorption = () => {
      track('Settings', 'Click', {
        Intent: 'change-card-fee-assignment',
        Cta: feesPaidBy === 'payor' ? 'charge-fees-to-my-customers' : 'charge-fees-to-my-business',
        PageName: 'receiving-method',
      });
      globalFeeAbsorptionSettingsModalOpen.on();
    };

    const handleFeeAbsorptionSettings = async (values: FeeAbsorptionSettings) => {
      try {
        setIsSettingFeeAbsorption.on();

        if (feesPaidBy === values.feesPaidBy) {
          return;
        }

        await createOrganizationPreferences({
          key: 'isVendorAbsorbedFee',
          value: String(values.feesPaidBy === 'vendor'),
        });
        await refetchOrganizationPreferences();
        triggerMessage({
          type: 'success',
          title: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.toast.success.label'
          ),
        });
      } catch (err) {
        triggerMessage({
          type: 'critical',
          title: formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.toast.failure.label'
          ),
        });
        onError?.(err as ARPlatformError);
      } finally {
        setIsSettingFeeAbsorption.off();
        globalFeeAbsorptionSettingsModalOpen.off();
      }
    };

    const isLoading = isFetching || isOrganizationPreferencesLoading || isReceivingMethodsLoading;

    if (isLoading || !invoicePreferences) {
      return <Loader />;
    }

    return (
      <>
        <AcceptedPaymentMethodsScreen
          invoicePreferences={invoicePreferences}
          onEditCustomPaymentDetails={onEditCustomPaymentDetails}
          onRemoveCustomPaymentDetails={() => removeCustomPaymentDetailsModalOpen.on()}
          onToggleACHPaymentsAllowed={(isEnabled) => handleTogglePaymentOption('allowACHPayment', isEnabled)}
          onToggleCardPaymentsAllowed={(isEnabled) => handleTogglePaymentOption('allowCardPayment', isEnabled)}
          isUpdating={isMutating}
          feesPaidBy={feesPaidBy}
          onEditFeeAbsorption={handleOnEditFeeAbsorption}
          hasVerifiedDeliveryMethod={hasVerifiedDeliveryMethod}
          ref={ref}
        />
        <RemoveCustomPaymentDetailsModalScreen
          isSubmitting={isMutating}
          isOpen={isRemoveCustomPaymentDetailsModalOpen}
          onClose={() => removeCustomPaymentDetailsModalOpen.off()}
          onCloseModal={() => removeCustomPaymentDetailsModalOpen.off()}
          onSubmit={handleRemoveCustomPaymentDetails}
        />
        <GlobalFeeAbsorptionSettingsModalScreen
          isOpen={isGlobalFeeAbsorptionSettingsModalOpen}
          onClose={() => globalFeeAbsorptionSettingsModalOpen.off()}
          isSaving={isSettingFeeAbsorption}
          defaultValues={{ feesPaidBy }}
          onSubmit={handleFeeAbsorptionSettings}
        />
      </>
    );
  }
);

AcceptedPaymentMethodsActivity.displayName = 'AcceptedPaymentMethodsActivity';
