import { IconKey } from '@melio/penny';
import { FileRawEntity } from '@melio/platform-utils';

import { VendorData } from './importVendorsTypes';

type ExpectedFields = {
  [key in keyof VendorData]: string[];
};

const expectedFields: ExpectedFields = {
  city: ['city'],
  addressLine1: ['addressline1', 'address', 'street'],
  addressLine2: ['addressline2', 'address line 2', 'address 2'],
  state: ['state'],
  zipCode: ['zip code', 'zip', 'postal code'],
  companyName: ['business name', 'company name', 'vendor', 'company'],
  contactEmail: ['email'],
  contactName: ['contactName', 'contact name', 'full name', 'first name', 'last name'],
  contactPhone: ['phone'],
  contractor: ['1099', 'contractor', 'track 1099'],
};

export const parseColumns = (rawData: FileRawEntity[]): VendorData[] => {
  const first = rawData[0];
  if (!first) {
    return [];
  }
  const rawKeys = Object.keys(first);
  const expectedFieldsToRawFieldsMap: Record<string, string | undefined> = Object.fromEntries(
    Object.entries(expectedFields).map(([expectedFieldName, keyWords]: [string, string[]]) => {
      const matchingField = rawKeys.find((rawKey) =>
        keyWords.some((keyWord) => rawKey.toLowerCase().includes(keyWord.toLowerCase()))
      );
      return [expectedFieldName, matchingField];
    })
  );
  return rawData.map((rawEntity) => {
    const resultVendorData = Object.fromEntries(
      Object.keys(expectedFields).map((expectedField: string) => {
        const rawValue = rawEntity[expectedFieldsToRawFieldsMap[expectedField] as string];
        return [expectedField, rawValue || ''];
      })
    ) as { [key in keyof typeof expectedFields]: string };

    const isContractor: boolean =
      resultVendorData.contractor?.toLowerCase() === 'yes' ||
      resultVendorData.contractor === '1' ||
      resultVendorData.contractor?.toLowerCase() === 'true';
    return {
      ...resultVendorData,
      companyName: resultVendorData.companyName || null,
      contractor: isContractor,
    };
  });
};

export const getFullAddress = (vendor: VendorData) => {
  const addressLine1 = `${vendor?.addressLine1 || ''} ${vendor?.addressLine2 || ''}`.trim();
  const addressLine2 = `${vendor?.city || ''}, ${vendor?.state || ''} ${vendor?.zipCode || ''}`.trim();

  return {
    addressLine1,
    addressLine2,
  };
};

export const isValidVendorData = (vendor: VendorData): boolean => !!vendor.companyName;

export const getStatusIcon = (vendor: VendorData): { type: IconKey; color: 'success' | 'critical' } => {
  if (isValidVendorData(vendor)) {
    return {
      type: 'checked-circle',
      color: 'success',
    };
  }
  return {
    type: 'error-fill',
    color: 'critical',
  };
};

export const enhanceVendorsWithIds = (vendors: VendorData[]): (VendorData & { id: string })[] =>
  vendors.map((vendor, index) => ({
    ...vendor,
    id: `vendor-${index}`,
  }));
