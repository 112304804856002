import { Tourtip, TourtipSteps, useBreakpoint } from '@melio/penny';
import { useUserPreference } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { ComponentProps } from 'react';

import { createTestIdSelector } from '../../../../../../../utils';

const IS_ACH_TOURTIP_DISMISSED = 'isAchTourtipDismissed';

export const useFirstTimeAchDmTourtip = () => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const {
    settings: {
      payment: { firstTimeAchDeliveryMethodTourtip },
    },
  } = useConfig();

  const {
    data: isAchTourtipDismissed,
    create,
    isLoading,
  } = useUserPreference({
    id: IS_ACH_TOURTIP_DISMISSED,
  });

  const handleAchTourtipDismiss = () => {
    create({ userPreference: { key: IS_ACH_TOURTIP_DISMISSED, value: 'true' } });
  };

  const steps: TourtipSteps = [
    {
      title: formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.tourtip.title'),
      description: formatMessage(
        'activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.tourtip.description'
      ),
      target: isExtraSmallScreen
        ? createTestIdSelector('mobile-tourtip-target')
        : createTestIdSelector('tourtip-target'),
      placement: 'top-start',
      icon: 'promote',
    },
  ];

  const props: ComponentProps<typeof Tourtip> = {
    defaultLabels: {
      nextButtonLabel: formatMessage(
        'activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.tourtip.dismissButton'
      ),
      prevButtonLabel: '',
    },
    steps,
    disableScrolling: true,
    onTourDismiss: handleAchTourtipDismiss,
    onTourComplete: handleAchTourtipDismiss,
    'data-testid': 'ach-dm-tourtip',
  };

  return {
    show: firstTimeAchDeliveryMethodTourtip.enabled && isAchTourtipDismissed?.value !== 'true',
    isLoading,
    props,
  };
};
