import { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Group, SectionBanner, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics/src/hooks/useAnalytics';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onVerifyClick: () => void;
  onClose: () => void;
  vendorName: string;
};

export const VendorMidConfidenceBanner = ({ onVerifyClick, onClose, vendorName }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  useEffect(() => {
    track('Vendor', 'View', {
      PageName: 'vendor-details',
      UserMessage: 'vendor-match-found',
      Flow: 'manage-vendor',
      Intent: 'match-vendor',
      EntryPoint: 'dashboard-vendors',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerifyClick = () => {
    track('Vendor', 'Click', {
      PageName: 'vendor-details',
      UserMessage: 'vendor-match-found',
      Flow: 'manage-vendor',
      Intent: 'review-match',
      Cta: 'review-matched-vendor',
    });
    onVerifyClick();
  };

  const handleOnClose = () => {
    track('Vendor', 'Click', {
      PageName: 'vendor-details',
      UserMessage: 'vendor-match-found',
      Flow: 'manage-vendor',
      Intent: 'skip',
      Cta: 'close',
    });
    onClose();
  };

  return (
    <SectionBanner
      variant="informative"
      onClose={handleOnClose}
      showCloseIcon
      title={formatMessage('widgets.vendorDrawer.vendorMidConfidenceBanner.title')}
      data-testid="vendor-mid-confidence-banner"
      description={
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="body3">
            {formatMessage('widgets.vendorDrawer.vendorMidConfidenceBanner.description', { vendorName })}
          </Text>
          <Text textStyle="body3">
            <Box
              as="u"
              data-testid="vendor-mid-confidence-banner-verify"
              cursor="pointer"
              _hover={{ textDecoration: 'none' }}
              onClick={handleVerifyClick}
            >
              {formatMessage('widgets.vendorDrawer.vendorMidConfidenceBanner.verifyLink')}
            </Box>
          </Text>
        </Group>
      }
    />
  );
};
