/* eslint-disable max-lines */
import {
  DeliveryMethodType,
  DeliveryPreferenceType,
  PaymentsExtendedStatusesEnum,
  useDeliveryMethodTypeOptions,
  useVendors,
} from '@melio/platform-api';
import { DebitDateFilterValues } from '@melio/platform-api/src/entities-v2/payment/types';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { addDays, endOfDay, endOfToday, startOfDay, startOfToday, subDays } from 'date-fns';
import compact from 'lodash/compact';
import { useMemo } from 'react';

import { PayDashboardFilterType } from '../../types';
import { CalcQueryParamsData, CalcQueryParamsResult, TabFilter } from '../Filters/types';
import { PaymentsTabFrequencyFilter } from './types';
export const supportedSearchParamKeys = [
  'payment.status',
  'payment.scheduledDate',
  'payment.frequency',
  'payment.deliveryMethod',
  'payment.vendorId',
] as const;
export type SupportedSearchParamKeysType = (typeof supportedSearchParamKeys)[number];
export const useNewPaymentFilters = ({ filterContext }: { filterContext?: PayDashboardFilterType }) => {
  const { formatMessage } = useMelioIntl();
  const { createDate } = useDateUtils('MM/dd/yyyy');
  const startOfTodayDate = startOfToday();
  const endOfTodayDate = endOfToday();
  const startOfTodayTimestamp = startOfTodayDate.getTime();
  const endOfTodayTimestamp = endOfTodayDate.getTime();
  const [isPaymentsTabNewFiltersPhase2Enabled] = usePartnerFeature('PaymentsTabFiltersPhase2', false);
  const { data: dmTypeOptions } = useDeliveryMethodTypeOptions({});
  const {
    settings: {
      PaymentsTabFiltersPhase2: { supportedStatuses },
    },
  } = useConfig();
  const [isAutoPayEnabled] = useDevFeature(FeatureFlags.AutoPay, false);

  const activeFilters = filterContext?.filters ?? {};
  const vendorsActiveFilterValues = activeFilters['payment.vendorId'] ?? [];
  const { data: vendors = [], isLoading: isFetchingVendors } = useVendors({
    enabled: !!vendorsActiveFilterValues.length,
    params: { search: { 'vendor.id': vendorsActiveFilterValues }, expand: 'none' },
  });

  return useMemo(() => {
    const paymentsNewFilters: TabFilter<SupportedSearchParamKeysType>[] = compact([
      {
        key: 'payment.status',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.status.title'),
        type: 'multi',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const statusValues = values.filter((value) =>
            Object.values(PaymentsExtendedStatusesEnum).includes(value as PaymentsExtendedStatusesEnum)
          );
          if (statusValues.length) {
            return { filterQueryParams: { 'payment.extendedStatus': statusValues } };
          }
          return {};
        },
        options: compact([
          supportedStatuses.scheduled && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.scheduled.label'),
            value: PaymentsExtendedStatusesEnum.Scheduled,
            backgroundColor: 'informative',
          },
          supportedStatuses.pending && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.pending.label'),
            value: PaymentsExtendedStatusesEnum.Pending,
            backgroundColor: 'warning',
          },
          supportedStatuses.inProgress && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.inProgress.label'),
            value: PaymentsExtendedStatusesEnum.InProgress,
            backgroundColor: 'success',
          },
          supportedStatuses.paid && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.paid.label'),
            value: PaymentsExtendedStatusesEnum.Paid,
            backgroundColor: 'success',
          },
          supportedStatuses.markedAsPaid && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.markedAsPaid.label'),
            value: PaymentsExtendedStatusesEnum.MarkedAsPaid,
            backgroundColor: 'success',
          },
          supportedStatuses.refunded && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.refunded.label'),
            value: PaymentsExtendedStatusesEnum.Refunded,
            backgroundColor: 'success',
          },
          supportedStatuses.failed && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.failed.label'),
            value: PaymentsExtendedStatusesEnum.Failed,
            backgroundColor: 'critical',
          },
          supportedStatuses.declined && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.declined.label'),
            value: PaymentsExtendedStatusesEnum.Declined,
            backgroundColor: 'critical',
          },
          supportedStatuses.canceled && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.status.canceled.label'),
            value: PaymentsExtendedStatusesEnum.Canceled,
            backgroundColor: 'critical',
          },
        ]),
      },
      isPaymentsTabNewFiltersPhase2Enabled
        ? {
            key: 'payment.scheduledDate',
            label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.title'),
            type: 'single',
            calcQueryParams: (values: string[]): CalcQueryParamsResult => {
              switch (values[0]) {
                case DebitDateFilterValues.Today:
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [startOfTodayTimestamp, endOfTodayTimestamp],
                      },
                    },
                  };
                case DebitDateFilterValues.Last7Days: {
                  const last7DaysTimestamp = subDays(startOfTodayDate, 6).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [last7DaysTimestamp, endOfTodayTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Last30Days: {
                  const last30DaysTimestamp = subDays(startOfTodayDate, 29).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [last30DaysTimestamp, endOfTodayTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Next7Days: {
                  const next7DaysTimestamp = addDays(endOfTodayDate, 6).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [startOfTodayTimestamp, next7DaysTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Next30Days: {
                  const next30DaysTimestamp = addDays(endOfTodayDate, 29).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [startOfTodayTimestamp, next30DaysTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Last90Days: {
                  const last90DaysTimestamp = subDays(startOfTodayDate, 89).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [last90DaysTimestamp, endOfTodayTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Next90Days: {
                  const next90DaysTimestamp = addDays(endOfTodayDate, 89).getTime();
                  return {
                    filterQueryParams: {
                      'payment.scheduledDate': {
                        $between: [startOfTodayTimestamp, next90DaysTimestamp],
                      },
                    },
                  };
                }
                case DebitDateFilterValues.Custom: {
                  const datesRange = values[1]?.split('-');
                  const dates = datesRange?.map((date) => createDate(date));
                  const startDate = dates?.[0];
                  const endDate = dates?.[1];
                  const startDateTimestamp = startDate ? startOfDay(startDate).getTime() : undefined;
                  const endDateTimestamp = endDate ? endOfDay(endDate).getTime() : undefined;
                  if (startDateTimestamp && endDateTimestamp) {
                    return {
                      filterQueryParams: {
                        'payment.scheduledDate': {
                          $between: [startDateTimestamp, endDateTimestamp],
                        },
                      },
                    };
                  }
                  return {};
                }
              }
              return {};
            },
            options: [
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.any'),
                value: 'all',
                selectWhenNothingSelected: true,
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.today'),
                value: 'today',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last7Days'),
                value: 'last7Days',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last30Days'),
                value: 'last30Days',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next7Days'),
                value: 'next7Days',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next30Days'),
                value: 'next30Days',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.last90Days'),
                value: 'last90Days',
              },
              {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.debitDate.next90Days'),
                value: 'next90Days',
              },
              {
                label: formatMessage('activities.payDashboard.filters.custom'),
                value: 'custom',
                calcCustomUrlParams: (data: CalcQueryParamsData): string => {
                  if (data?.customStartDate && data?.customEndDate) {
                    return `${data.customStartDate}-${data.customEndDate}`;
                  }
                  return '';
                },
              },
            ],
          }
        : undefined,
      isPaymentsTabNewFiltersPhase2Enabled
        ? {
            key: 'payment.deliveryMethod',
            label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.title'),
            type: 'multi',
            calcQueryParams: (values: string[]): CalcQueryParamsResult => {
              const deliveryMethodTypesValues = values.filter((value) =>
                Object.values(DeliveryPreferenceType).includes(value as DeliveryPreferenceType)
              );
              const managedConditions = deliveryMethodTypesValues.filter(
                (value) => value === DeliveryPreferenceType.Check || value === DeliveryPreferenceType.Ach
              );
              if (deliveryMethodTypesValues.length) {
                return {
                  filterQueryParams: {
                    $or: {
                      $or: [{ 'deliveryMethod.deliveryType': deliveryMethodTypesValues }],
                      ...(managedConditions.length
                        ? {
                            $and: [
                              { 'deliveryMethod.deliveryType': 'managed' },
                              { 'managedAccount.deliveryType': managedConditions },
                            ],
                          }
                        : {}),
                    },
                  },
                };
              }
              return {};
            },
            options: compact([
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.BankAccount)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.bankTransfer.label'),
                value: DeliveryPreferenceType.Ach,
              },
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.PaperCheck)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.paperCheck.label'),
                value: DeliveryPreferenceType.Check,
              },
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.InternationalAccount)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.international.label'),
                value: DeliveryPreferenceType.International,
              },
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.DomesticWireAccount)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.wireTransfer.label'),
                value: DeliveryPreferenceType.DomesticWire,
              },
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.VirtualCard)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.virtualCard.label'),
                value: DeliveryPreferenceType.VirtualCard,
              },
              dmTypeOptions?.find((option) => option.type === DeliveryMethodType.Card)?.supported && {
                label: formatMessage('activities.payDashboard.paymentsTab.filters.deliveryMethod.pushToDebit.label'),
                value: DeliveryPreferenceType.Card,
              },
            ]),
          }
        : undefined,
      {
        key: 'payment.frequency',
        label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.title'),
        type: 'single',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const billSubOccKey = 'billSubscriptionOccurrence.id';
          const paymentMethodKey = 'payment.createMethod';

          if (values.includes(PaymentsTabFrequencyFilter.Recurring)) {
            return {
              filterQueryParams: { [billSubOccKey]: { $ne: null } },
            };
          }
          if (values.includes(PaymentsTabFrequencyFilter.AutoPay)) {
            return {
              filterQueryParams: { [paymentMethodKey]: 'auto_payment' },
            };
          } else if (values.includes(PaymentsTabFrequencyFilter.OneTime)) {
            return { filterQueryParams: { [billSubOccKey]: null, [paymentMethodKey]: { $ne: 'auto_payment' } } };
          }
          return {};
        },
        options: compact([
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.all.label'),
            value: PaymentsTabFrequencyFilter.All,
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.oneTime.label'),
            value: PaymentsTabFrequencyFilter.OneTime,
          },
          {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.recurring.label'),
            value: PaymentsTabFrequencyFilter.Recurring,
          },
          isAutoPayEnabled && {
            label: formatMessage('activities.payDashboard.paymentsTab.filters.frequency.autoPay.label'),
            value: PaymentsTabFrequencyFilter.AutoPay,
          },
        ]),
      },
      isPaymentsTabNewFiltersPhase2Enabled
        ? {
            key: 'payment.vendorId',
            label: formatMessage('activities.payDashboard.paymentsTab.filters.vendor'),
            type: 'multi',
            calcQueryParams: (values: string[]): CalcQueryParamsResult => {
              if (values.length) {
                return {
                  filterQueryParams: { 'vendor.id': values },
                };
              }
              return { filterQueryParams: {}, queryParamsOptions: {} };
            },
            options: [],
            hidden: true,
            calcOptions: (values: string[]): TabFilter<SupportedSearchParamKeysType>['options'] => {
              if (!isFetchingVendors && vendors.length) {
                return vendors.map((vendor) => {
                  if (values.includes(vendor.id)) {
                    return {
                      label: vendor.name,
                      value: vendor.id,
                    };
                  }
                  return { label: '', value: '' };
                });
              }
              return [];
            },
          }
        : undefined,
    ]);
    return paymentsNewFilters;
  }, [
    createDate,
    formatMessage,
    isPaymentsTabNewFiltersPhase2Enabled,
    startOfTodayTimestamp,
    endOfTodayTimestamp,
    startOfTodayDate,
    endOfTodayDate,
    isFetchingVendors,
    vendors,
    dmTypeOptions,
    supportedStatuses,
    isAutoPayEnabled,
  ]);
};
