import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { usePayDashboardFilters } from '../PayDashboardFilterProvider';

export const EmptyFilterResult = () => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const filterContext = usePayDashboardFilters();

  const handleClear = () => {
    filterContext?.resetFilters();
    track('Dashboard', 'Click', { Intent: 'filter', Cta: 'clear-filter' });
  };

  return (
    <Container data-testid="pay-dashboard-empty-filter-result" paddingY="xxxl" paddingX="m">
      <Group spacing="s" alignItems="center" justifyContent="center" variant="vertical" textAlign="center">
        <Illustration type="no-items" size="small" />
        <Text aria-live="assertive">{formatMessage('activities.payDashboard.filter.noResult.body')}</Text>
        <Button
          data-testid="pay-dashboard-clear-filter-no-filter-result"
          variant="tertiary"
          size="large"
          onClick={handleClear}
          label={formatMessage('activities.payDashboard.filter.noResult.clear')}
        />
      </Group>
    </Container>
  );
};
