import { useUserPreference } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useCallback, useEffect } from 'react';

import { useOrganizationSelectWidgetData } from './useOrganizationSelectWidgetData';

const multiAccountTourPreferenceKey = 'multiAccountTourActive';
const tourActiveValue = 'true';
const tourDismissedValue = 'false';

export const useTriggerMultiAccountTour = () => {
  const [isFeatureEnabled] = usePartnerFeature('MultiAccountBanner', false);
  const { organizations } = useOrganizationSelectWidgetData({ enabled: isFeatureEnabled });

  const {
    data: userPreference,
    isFetched: isFetchedUserPreference,
    create,
  } = useUserPreference({
    id: multiAccountTourPreferenceKey,
  });

  const wasDismissed = userPreference?.value === tourDismissedValue;
  const hasMultipleAccounts = organizations?.length > 1;

  const shouldTrigger = isFetchedUserPreference && isFeatureEnabled && hasMultipleAccounts && !wasDismissed;

  useEffect(() => {
    if (shouldTrigger) {
      create({ userPreference: { key: multiAccountTourPreferenceKey, value: tourActiveValue } });
    }
  }, [shouldTrigger, create]);
};

export const useMultiAccountTourtip = () => {
  useTriggerMultiAccountTour();
  const [isFeatureEnabled] = usePartnerFeature('MultiAccountBanner', false);

  const { data: userPreference, create } = useUserPreference({
    id: multiAccountTourPreferenceKey,
  });

  const shouldShow = isFeatureEnabled && userPreference?.value === tourActiveValue;

  const handleDismiss = useCallback(() => {
    create({ userPreference: { key: multiAccountTourPreferenceKey, value: tourDismissedValue } });
  }, [create]);

  return { shouldShow, handleDismiss };
};
