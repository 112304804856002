import { Button, Group, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isLoading: boolean;
  onCancel: VoidFunction;
  onSubmit: VoidFunction;
};

export const AddMsnVendorDrawerFooter = ({ isLoading = false, onCancel, onSubmit }: Props) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  return (
    <Group width="full" justifyContent="space-between" spacing="s">
      <Button
        data-testid="add-msn-vendor-drawer-footer-cancel-button"
        isFullWidth={isMobile}
        variant="tertiary"
        label={formatMessage('widgets.vendorDrawer.add.msn.button.secondary.label')}
        onClick={onCancel}
      />
      <Button
        data-testid="add-msn-vendor-drawer-footer-submit-button"
        isFullWidth={isMobile}
        isLoading={isLoading}
        variant="primary"
        label={formatMessage('widgets.vendorDrawer.add.msn.button.primary.label')}
        onClick={onSubmit}
      />
    </Group>
  );
};
