import { Container, Pagination, useBreakpoint } from '@melio/penny';
import { PaginationResponsePagination, UsePaginatedCollection } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect, useRef } from 'react';

import { usePayDashboardPagination } from './PayDashboardPaginationProvider';

type Props = {
  paginatedCollection: UsePaginatedCollection<{ data: unknown[]; pagination: PaginationResponsePagination }>;
  isVisible: boolean;
};
export const PayDashboardPagination = ({ paginatedCollection, isVisible }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const { resetToFirstPage, setPreviousPage, setNextPage, currentPage, lastVisitedPage, pageSize } =
    usePayDashboardPagination();

  const previousButtonRef = useRef<HTMLButtonElement | null>(null);
  const nextButtonRef = useRef<HTMLButtonElement | null>(null);
  const activePaginationControlRef = useRef<'next' | 'prev' | null>(null);

  const { data: paginationResult, fetchPreviousPage, fetchNextPage, fetchFirstPage, isFetching } = paginatedCollection;
  const { data, pagination } = paginationResult ?? {};

  useEffect(() => {
    // in case we delete the only item in the last page, we need to fetch the first page
    if (data?.length === 0 && pagination?.totalCount && pagination.totalCount > 0) {
      resetToFirstPage();
      fetchFirstPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFetchPreviousPage = () => {
    setPreviousPage();
    fetchPreviousPage();
  };

  const handleFetchNextPage = () => {
    setNextPage();
    fetchNextPage();
  };

  const handlePageChange = (newPageNumber: number) => {
    if (newPageNumber > currentPage) {
      handleFetchNextPage();
      activePaginationControlRef.current = 'next';
    } else {
      handleFetchPreviousPage();
      activePaginationControlRef.current = 'prev';
    }
  };

  const paginationIndex = getPaginationIndex(currentPage, pageSize, pagination?.totalCount ?? 0);

  useEffect(() => {
    // lastVisitedPage is used as an indication for initial page load
    if (!isFetching && lastVisitedPage !== null) {
      if (pagination?.totalCount && Math.ceil(pagination.totalCount / pageSize) === currentPage) {
        previousButtonRef.current?.focus({ preventScroll: true });
      } else if (currentPage === 1) {
        nextButtonRef.current?.focus({ preventScroll: true });
      }
    }
  }, [isFetching, pagination?.totalCount, pageSize, currentPage, lastVisitedPage]);

  const previousButtonRefCallback = (element: HTMLButtonElement | null) => {
    previousButtonRef.current = element;
    if (activePaginationControlRef.current === 'prev' && element) {
      element.focus({ preventScroll: true });
      activePaginationControlRef.current = null;
    }
  };
  const nextButtonRefCallback = (element: HTMLButtonElement | null) => {
    nextButtonRef.current = element;
    if (activePaginationControlRef.current === 'next' && element) {
      element.focus({ preventScroll: true });
      activePaginationControlRef.current = null;
    }
  };

  return isVisible ? (
    <Container justifyContent={isMobile ? 'center' : 'flex-end'} overflow="initial">
      <Pagination
        data-testid="pay-dashboard-pagination-controls"
        pageSize={pageSize}
        totalItems={pagination?.totalCount ?? 0}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        previousButtonRef={previousButtonRefCallback}
        nextButtonRef={nextButtonRefCallback}
        ariaLabels={{
          chevronLeftLabel: formatMessage('activities.payDashboard.pagination.button.ariaLabel', {
            startIndex: paginationIndex.startIndex,
            endIndex: paginationIndex.endIndex,
            totalResults: pagination?.totalCount ?? 0,
            chevronDirection: formatMessage('activities.payDashboard.pagination.button.previous.ariaLabel'),
          }),
          chevronRightLabel: formatMessage('activities.payDashboard.pagination.button.ariaLabel', {
            startIndex: paginationIndex.startIndex,
            endIndex: paginationIndex.endIndex,
            totalResults: pagination?.totalCount ?? 0,
            chevronDirection: formatMessage('activities.payDashboard.pagination.button.next.ariaLabel'),
          }),
        }}
      />
    </Container>
  ) : null;
};

export const getPaginationIndex = (currentPage: number, pageSize: number, totalItems: number) => {
  const getStartIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const totalPages = getTotalPages(totalItems, pageSize);
    const pageForCalculation = Math.min(currentPage, totalPages);
    return Math.max(1, 1 + pageSize * (pageForCalculation - 1));
  };

  const getEndIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const pageForCalculation = Math.max(currentPage, 1);
    return Math.min(pageSize * pageForCalculation, totalItems);
  };

  const getTotalPages = (totalItems: number, pageSize: number): number => Math.ceil(totalItems / pageSize);

  return {
    startIndex: getStartIndex(pageSize, currentPage, totalItems),
    endIndex: getEndIndex(pageSize, currentPage, totalItems),
  };
};
