/* eslint-disable no-restricted-imports */
import { StatusIconSolid } from '@melio/penny';
import { TodoFailedPayment, TodoFailedPaymentTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { isEmptySection } from '../utils';
import { TodoSection } from './TodoSection';

export type TodoSectionFailedPaymentsProps = {
  todo?: TodoFailedPayment;
  clientOrganizationId: string;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodoSectionFailedPayments = ({
  todo,
  clientOrganizationId,
  onSwitchOrganization,
}: TodoSectionFailedPaymentsProps) => {
  const { formatMessage } = useMelioIntl();
  const [isPaymentsTabNewFiltersPhase2Enabled] = usePartnerFeature('PaymentsTabFiltersPhase2', false);
  const { goToPayDashboardPaymentsTab } = useAccountantsRoutes();

  if (isEmptySection(todo)) {
    return null;
  }

  const totalItems = todo?.items.pagination.totalCount || 0;
  const firstItem = todo?.items.data[0];

  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.failePayments.singular.title', {
          vendorName: firstItem?.vendor?.name ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.failePayments.plural.title', {
          count: totalItems,
        });

  const onClickAction = async () => {
    await onSwitchOrganization({
      organizationId: clientOrganizationId,
      keepLoadingState: true,
      switchAccessTokenOnly: true,
    });
    goToPayDashboardPaymentsTab({
      refresh: true,
      query: isPaymentsTabNewFiltersPhase2Enabled ? 'payment.status=failed' : 'status=failed',
    });
  };

  return (
    <TodoSection
      key={`section-${TodoFailedPaymentTypeEnum.FailedPayments}`}
      icon={<StatusIconSolid variant="alert" size="medium" />}
      title={title}
      action={{ label: 'view', onClick: () => void onClickAction() }}
      type={TodoFailedPaymentTypeEnum.FailedPayments}
    />
  );
};
