/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
import { isDeliveryPreferenceTypeFast } from '@melio/ap-domain';
import { useAccountingPlatformName, useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Bill,
  DeliveryMethod,
  DeliveryPreferenceType,
  FundingSource,
  PaymentIntent,
  useAccountingPlatforms,
} from '@melio/platform-api';
import { useFormBanner } from '@melio/platform-utils';
import { map, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { getCreditCardFundingSourceNetwork } from '../../../funding-sources/fundingSources.utils';
import { useGetDeductionDateIfAllEqual } from './components/PaymentIntentsTable/PaymentIntentsTable.utils';
import {
  getBillsIds,
  getCardNetworkMissingDetails,
  getFundingSourceById,
  getFundingSourceType,
  getNumberOfBills,
  getPaymentIntentById,
  getPaymentIntentsExposedToFastACH,
  getPaymentIntentsExposedToFastCheck,
  getPaymentIntentsWithCriticalMissingData,
  getTotalEstimatedFees,
  getTotalPaymentVolume,
  getUniqueDeliveryMethodsIds,
  getUniqueFundingSources,
  mapPaymentIntentsForAnalytics,
} from './PaymentIntentsTable.screen.analytics-utils';
import { PaymentIntentsAnalyticsEvent, PaymentIntentsTableScreenProps } from './types';

type UseEventHandlersProps = Pick<
  PaymentIntentsTableScreenProps,
  | 'paymentIntentsWithDerivatives'
  | 'fundingSources'
  | 'onBack'
  | 'onDone'
  | 'onAddDeliveryMethodClick'
  | 'onAddFundingSourceClick'
  | 'onUpdateAllFundingSources'
  | 'onUpdateAllDeductionDatesToTheSameDate'
  | 'onUpdateAllDeductionDatesToTheEarliestDate'
  | 'onUpdateAllDeductionDatesToArriveByDueDate'
  | 'onUpdateAllDeliveryDatesToTheDueDate'
  | 'onUpdateSingleDeliveryMethod'
  | 'onUpdateSingleFundingSource'
  | 'onOpenPaymentPurposeModal'
  | 'onOpenInvoiceAttachmentModal'
  | 'onUpdateSingleDeliveryPreferenceType'
  | 'onUpdateSingleScheduledDate'
  | 'onUpdateSingleDeliveryDate'
  | 'onRemoveBillsClick'
  | 'onAddMemoToVendorClick'
  | 'onSetInvoiceNumberClick'
  | 'onViewBillDetailsClick'
  | 'onOpenReconciliationModal'
  | 'arePaymentsCombined'
  | 'selectedDeliveryDateHeaderCellOption'
  | 'onEditAmountClick'
  | 'missingVendorDirectoryDetailsBannerProps'
> & {
  setShouldDisplayStatus: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfUnreadyPaymentIntents: number;
  isByDueDate?: boolean;
};

const useEventHandlers = (props: UseEventHandlersProps) => {
  const {
    paymentIntentsWithDerivatives,
    fundingSources,
    numberOfUnreadyPaymentIntents,
    isByDueDate,
    setShouldDisplayStatus,
    onDone,
    onBack,
    onAddDeliveryMethodClick,
    onAddFundingSourceClick,
    onUpdateAllFundingSources,
    onOpenReconciliationModal,
    onOpenPaymentPurposeModal,
    onOpenInvoiceAttachmentModal,
    onUpdateSingleFundingSource,
    onUpdateSingleDeliveryMethod,
    onUpdateAllDeductionDatesToTheSameDate,
    onUpdateAllDeductionDatesToTheEarliestDate,
    onUpdateAllDeductionDatesToArriveByDueDate,
    onUpdateAllDeliveryDatesToTheDueDate,
    onUpdateSingleDeliveryPreferenceType,
    onUpdateSingleScheduledDate,
    onUpdateSingleDeliveryDate,
    onRemoveBillsClick,
    onAddMemoToVendorClick,
    onSetInvoiceNumberClick,
    onViewBillDetailsClick,
    arePaymentsCombined,
    selectedDeliveryDateHeaderCellOption,
    missingVendorDirectoryDetailsBannerProps,
    onEditAmountClick,
  } = props;

  const paymentIntents = paymentIntentsWithDerivatives.map(({ paymentIntent }) => paymentIntent);

  const [headerCellPaymentMethod, setHeaderCellPaymentMethod] = useState<FundingSource | undefined | null>(null);
  const { activeAccountingPlatform } = useAccountingPlatforms();

  const { getDeductionDateIfAllEqual } = useGetDeductionDateIfAllEqual();
  const { announceBanner } = useFormBanner();

  const vendors = uniqBy(
    paymentIntentsWithDerivatives.map(({ vendor }) => vendor),
    'id'
  );

  const firstVendor = vendors?.[0];
  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(firstVendor);

  const headerCellDeductionDateType = isByDueDate
    ? 'due-date'
    : getDeductionDateIfAllEqual(paymentIntents)
    ? 'same-date'
    : 'multiple';

  const globalAnalyticsData = {
    NumberOfBills: getNumberOfBills(paymentIntentsWithDerivatives),
    NumberOfPayments: paymentIntents.length,
    NumberOfVendors: vendors.length,
    TPV: getTotalPaymentVolume(paymentIntents),
    TotalFees: getTotalEstimatedFees(paymentIntents),
    StateBulkToggle: arePaymentsCombined,
    PaymentIntents: mapPaymentIntentsForAnalytics(paymentIntentsWithDerivatives, fundingSources),
    ExposedToFastACH: map(getPaymentIntentsExposedToFastACH(paymentIntents), 'id'),
    ExposedToFastCheck: map(getPaymentIntentsExposedToFastCheck(paymentIntents), 'id'),
    HeaderCellPaymentMethod: getFundingSourceType(headerCellPaymentMethod),
    HeaderCellDeductionDateType: headerCellDeductionDateType,
    DeliveryDateType: selectedDeliveryDateHeaderCellOption,
    BillIds: getBillsIds(paymentIntentsWithDerivatives),
    ...(isVendorDirectoryInfoCompleted
      ? {}
      : {
          UserMessage: 'missing-vendor-details',
        }),
  };

  const { track } = useAnalytics();

  useEffect(() => {
    track(PaymentIntentsAnalyticsEvent.BatchTable, 'Viewed', globalAnalyticsData);
  }, []);

  const handleRowExpand = (paymentIntentId: PaymentIntent['id']) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (paymentIntentWithDerivatives) {
      const { paymentIntent, vendor, bills } = paymentIntentWithDerivatives;

      track(PaymentIntentsAnalyticsEvent.BatchTableCombinedPaymentsExpandRow, 'Chose', {
        VendorId: vendor.id,
        BillCount: bills.length,
        TPV: paymentIntent.amountToPay,
      });
    }
  };

  const onConfirmClick = () => {
    setShouldDisplayStatus(true);

    const cardNetworkMissingDetails = getCardNetworkMissingDetails(paymentIntentsWithDerivatives, fundingSources);

    track(PaymentIntentsAnalyticsEvent.BatchTableSchedulePayment, 'Submitted', {
      ...globalAnalyticsData,
      NumberOfPaymentMethods: getUniqueFundingSources(paymentIntents, fundingSources).length,
      NumberOfDeliveryMethods: getUniqueDeliveryMethodsIds(paymentIntents).length,
      MissingDataCritialError: getPaymentIntentsWithCriticalMissingData(paymentIntents),
      ...(cardNetworkMissingDetails.length ? { MissingVendorDetails: cardNetworkMissingDetails } : {}),
    });

    if (missingVendorDirectoryDetailsBannerProps) {
      announceBanner();
      return;
    }

    if (!numberOfUnreadyPaymentIntents) {
      onDone();
    }
  };

  const handleAddFundingSourceClick = () => {
    track(PaymentIntentsAnalyticsEvent.BatchTableBatchAddPaymentMethod, 'Chose');
    onAddFundingSourceClick();
  };

  const handleCancel = () => {
    track(PaymentIntentsAnalyticsEvent.CloseBatchTable, 'Chose', globalAnalyticsData);
    onBack();
  };

  const handleUpdateAllFundingSources = (newFundingSource: FundingSource) => {
    onUpdateAllFundingSources(newFundingSource);
    const currentFundingSource = fundingSources.find((fs) => fs.id === headerCellPaymentMethod?.id);

    track(PaymentIntentsAnalyticsEvent.BatchTableBatchEditPaymentMethod, 'Chose', {
      InitialPaymentMethodId: currentFundingSource?.id,
      InitialPaymentMethod: getFundingSourceType(currentFundingSource),
      NewPaymentMethodId: newFundingSource?.id,
      NewPaymentMethod: getFundingSourceType(newFundingSource),
    });

    setHeaderCellPaymentMethod(fundingSources.find((fs) => fs.id === newFundingSource.id));
  };

  const handleUpdateAllDeductionDatesToTheSameDate = (date: Date) => {
    onUpdateAllDeductionDatesToTheSameDate(date);
    track(PaymentIntentsAnalyticsEvent.BatchTableBatchEditDeductionDate, 'Chose', {
      InitialDeductionDateType: headerCellDeductionDateType,
      NewDeductionDateType: 'same-date',
    });
  };

  const handleUpdateAllDeductionDatesToTheEarliestDate = () => {
    onUpdateAllDeductionDatesToTheEarliestDate();

    track('Payment', 'Click', {
      cta: 'delivery-header-earliest-as-possible',
    });
  };

  const handleUpdateAllDeliveryDatesToArriveByDueDate = () => {
    onUpdateAllDeliveryDatesToTheDueDate();

    track('Payment', 'Click', {
      cta: 'delivery-header-by-due-date',
    });
  };

  const handleUpdateAllDeductionDatesToArriveByDueDate = () => {
    onUpdateAllDeductionDatesToArriveByDueDate();

    track(PaymentIntentsAnalyticsEvent.BatchTableBatchEditDeductionDate, 'Chose', {
      InitialDeductionDateType: headerCellDeductionDateType,
      NewDeductionDateType: 'due-date',
    });
  };

  const handleUpdateSingleFundingSource = (
    paymentIntentId: PaymentIntent['id'],
    fundingSourceId: PaymentIntent['fundingSourceId']
  ) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (paymentIntentWithDerivatives) {
      const { paymentIntent, vendor, bills } = paymentIntentWithDerivatives;

      if (paymentIntent.fundingSourceId) {
        const currentFundingSource = getFundingSourceById(fundingSources, paymentIntent.fundingSourceId);
        const newFundingSource = fundingSources.find((fs) => fs.id === fundingSourceId);

        track(PaymentIntentsAnalyticsEvent.BatchTableEditPaymentMethod, 'Chose', {
          BillIds: bills?.map(({ id }) => id),
          VendorId: vendor.id,
          InitialPaymentMethodId: currentFundingSource?.id,
          InitialPaymentMethod: getFundingSourceType(currentFundingSource),
          NewPaymentMethodId: newFundingSource?.id,
          NewPaymentMethod: getFundingSourceType(newFundingSource),
          CardNetwork: getCreditCardFundingSourceNetwork({
            fundingSources,
            fundingSourceId: newFundingSource?.id,
          }),
        });

        onUpdateSingleFundingSource(paymentIntentId, fundingSourceId);
      }
    }
  };

  const handleUpdateSingleDeliveryMethod = (
    paymentIntentId: PaymentIntent['id'],
    newDeliveryMethodId: DeliveryMethod['id']
  ) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { paymentIntent, vendor, bills } = paymentIntentWithDerivatives;

    const deliveryMethods = vendor.deliveryMethods;
    const prevDeliveryMethod = deliveryMethods?.find((dm) => dm.id === paymentIntent?.deliveryMethodId);
    const newDeliveryMethod = deliveryMethods?.find((dm) => dm.id === newDeliveryMethodId);

    track(PaymentIntentsAnalyticsEvent.BatchTableEditDeliveryMethod, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
      VendorId: vendor.id,
      InitialDeliveryMethodId: prevDeliveryMethod?.id,
      InitialDeliveryMethod: prevDeliveryMethod?.type,
      NewDeliveryMethodId: newDeliveryMethod?.id,
      NewDeliveryMethod: newDeliveryMethod?.type,
    });
    onUpdateSingleDeliveryMethod(paymentIntentId, newDeliveryMethodId, deliveryMethods);
  };

  const handleUpdateSingleScheduledDate = (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['scheduledDate']>
  ) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { paymentIntent, vendor, bills } = paymentIntentWithDerivatives;

    track(PaymentIntentsAnalyticsEvent.BatchTableEditDeductionDate, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
      VendorId: vendor.id,
      InitialDeductionDate: paymentIntent?.scheduledDate,
      NewDeductionDate: date,
    });

    onUpdateSingleScheduledDate(paymentIntentId, date);
  };

  const handleUpdateSingleDeliveryDate = (
    paymentIntentId: PaymentIntent['id'],
    date: NonNullable<PaymentIntent['deliveryDate']>
  ) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { paymentIntent, vendor, bills } = paymentIntentWithDerivatives;

    track('Payment', 'Click', {
      BillIds: bills?.map(({ id }) => id),
      VendorId: vendor.id,
      InitialDeliveryDate: paymentIntent?.deliveryDate,
      NewDeliveryDate: date,
      cta: 'choose-delivery-date',
    });

    onUpdateSingleDeliveryDate(paymentIntentId, date);
  };

  const handleAddDeliveryMethodClick = (paymentIntent: PaymentIntent) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntent.id);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { bills } = paymentIntentWithDerivatives;

    track(PaymentIntentsAnalyticsEvent.BatchTableAddDeliveryMethod, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
    });
    onAddDeliveryMethodClick(paymentIntent);
  };

  const handleUpdateSingleDeliveryPreferenceType = (
    paymentIntentId: string,
    type: NonNullable<DeliveryPreferenceType>
  ) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { bills } = paymentIntentWithDerivatives;

    const isFast = isDeliveryPreferenceTypeFast(type);
    if (isFast) {
      track(PaymentIntentsAnalyticsEvent.BatchTableFastPayment, 'Chose', {
        BillIds: bills?.map(({ id }) => id),
        DeliveryType: type,
      });
    } else {
      track(PaymentIntentsAnalyticsEvent.BatchTableCancelFastPaymentCancel, 'Chose', {
        BillIds: bills?.map(({ id }) => id),
        DeliveryType: type,
      });
    }

    onUpdateSingleDeliveryPreferenceType(paymentIntentId, type);
  };

  const accountingPlatformName = useAccountingPlatformName(activeAccountingPlatform?.accountingSlug);

  const handleOpenPaymentPurposeModal = (paymentIntent: PaymentIntent) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntent.id);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    onOpenPaymentPurposeModal(paymentIntent);
  };

  const handleOpenInvoiceAttachmentModal = (paymentIntent: PaymentIntent, bill: Bill) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntent.id);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    onOpenInvoiceAttachmentModal(paymentIntent, bill);
  };

  const handleOpenReconciliationModal = (paymentIntent: PaymentIntent) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntent.id);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { bills } = paymentIntentWithDerivatives;

    track(PaymentIntentsAnalyticsEvent.BatchTableReconciliation, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
      PaymentMethodType: getFundingSourceType(paymentIntent?.fundingSource),
      AccountingSoftwareName: accountingPlatformName,
      AccountingSoftwareId: activeAccountingPlatform?.id,
    });
    onOpenReconciliationModal(paymentIntent);
  };

  const handleRemoveBillsClick = (paymentIntentId: PaymentIntent['id'], billId?: Bill['id']) => {
    track(PaymentIntentsAnalyticsEvent.BatchTableRemoveBillFromList, 'Chose', {
      billIds: [billId],
    });
    onRemoveBillsClick(paymentIntentId, billId);
  };

  const handleAddMemoToVendorClick = (paymentIntentId: PaymentIntent['id']) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { bills } = paymentIntentWithDerivatives;

    track(PaymentIntentsAnalyticsEvent.BatchTableAddMemoToVendor, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
    });
    onAddMemoToVendorClick(paymentIntentId);
  };

  const handleEditAmountClick = (paymentIntentId: PaymentIntent['id'], bill: Bill) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    onEditAmountClick(paymentIntentId, bill);
  };

  const handleSetInvoiceNumberClick = (paymentIntentId: PaymentIntent['id']) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const selectedSchedulePaymentIntent = paymentIntentsWithDerivatives?.find(
      ({ paymentIntent }) => paymentIntent.id === paymentIntentId
    );

    const existingInvoiceNumber = selectedSchedulePaymentIntent?.paymentIntent.billInfo?.invoice?.number;

    track(PaymentIntentsAnalyticsEvent.BatchTableSetInvoiceNumber, 'Click', {
      Cta: existingInvoiceNumber ? 'edit-invoice-number' : 'add-invoice-number',
    });
    onSetInvoiceNumberClick(paymentIntentId);
  };

  const handleViewBillDetailsClick = (paymentIntentId: PaymentIntent['id'], bill: Bill) => {
    const paymentIntentWithDerivatives = getPaymentIntentById(paymentIntentsWithDerivatives, paymentIntentId);
    if (!paymentIntentWithDerivatives) {
      return;
    }

    const { bills, paymentIntent } = paymentIntentWithDerivatives;

    track(PaymentIntentsAnalyticsEvent.BatchTableViewBillDetails, 'Chose', {
      BillIds: bills?.map(({ id }) => id),
      PaymentRequestId: bill.paymentRequest?.id,
    });
    onViewBillDetailsClick(paymentIntent.id, bill);
  };

  return {
    onConfirmClick,
    handleCancel,
    handleOpenReconciliationModal,
    handleOpenPaymentPurposeModal,
    handleAddMemoToVendorClick,
    handleSetInvoiceNumberClick,
    handleUpdateAllFundingSources,
    handleViewBillDetailsClick,
    handleAddFundingSourceClick,
    handleRemoveBillsClick,
    handleUpdateAllDeductionDatesToTheSameDate,
    handleUpdateAllDeductionDatesToTheEarliestDate,
    handleUpdateAllDeductionDatesToArriveByDueDate,
    handleUpdateAllDeliveryDatesToArriveByDueDate,
    handleUpdateSingleFundingSource,
    handleUpdateSingleDeliveryMethod,
    handleUpdateSingleScheduledDate,
    handleUpdateSingleDeliveryDate,
    handleAddDeliveryMethodClick,
    handleUpdateSingleDeliveryPreferenceType,
    handleRowExpand,
    handleOpenInvoiceAttachmentModal,
    handleEditAmountClick,
  };
};

export { useEventHandlers };
export type { UseEventHandlersProps };
