import { FormDialog, FormWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, NakedButton, SectionBanner, useMelioForm } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import * as yup from 'yup';

const SUPPORTED_FORMATS = ['text/csv'];

export type ImportCustomersFormFields = {
  file: File | null;
};

const useValidationSchema = (fileSizeLimit: number) => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    file: yup
      .mixed<File>()
      .nullable()
      .required(formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.required.text'))
      .test(
        'is-valid-file-type',
        formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.fileType.text'),
        (value) => (value instanceof File ? SUPPORTED_FORMATS.includes(value.type) : false)
      )
      .test(
        'is-file-size-below-limit',
        formatMessage('ar.modals.activities.importCustomers.modal.form.file.validations.fileSize.text'),
        (file) => {
          if (file) {
            return file.size <= fileSizeLimit;
          }

          return true;
        }
      ),
  }) as yup.SchemaOf<ImportCustomersFormFields>;
};

export type ImportCustomersScreenProps = Pick<FormWidgetProps<ImportCustomersFormFields>, 'isSaving' | 'onSubmit'> & {
  onClose: VoidFunction;
  onDownloadCSVTemplateFile: VoidFunction;
  isOpen: boolean;
  fileSizeLimit: number;
};
export const ImportCustomersScreen = forwardRef<ImportCustomersScreenProps>(
  ({ onSubmit, onDownloadCSVTemplateFile, isSaving, fileSizeLimit, onClose, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const { registerField, setValue, formState, watch, ...rest } = useMelioForm<ImportCustomersFormFields>({
      schema: useValidationSchema(fileSizeLimit),
      isSaving,
      onSubmit,
    });

    useAnalyticsView('Customer', true, true);

    useEffect(() => {
      if (formState.isSubmitting) {
        track('Customer', 'Click', {
          Cta: 'submit',
        });
      }
    }, [formState.isSubmitting]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (formState.errors.file) {
        track('Customer', 'Status', {
          StatusType: 'failure',
          ErrorType: formState.errors.file.message,
          PageName: 'upload-customers',
          Cta: 'select-csv-file',
        });
      }
    }, [formState.errors.file]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormDialog
        closeButtonAriaLabel={formatMessage('ar.modals.activities.importCustomers.modal.closeButtonAriaLabel')}
        data-component={ImportCustomersScreen.displayName}
        size="large"
        onClose={() => !isSaving && onClose()}
        header={formatMessage('ar.modals.activities.importCustomers.modal.header.text')}
        description={!isSaving ? formatMessage('ar.modals.activities.importCustomers.modal.header.description') : ''}
        primaryButton={{
          label: formatMessage('ar.modals.activities.importCustomers.modal.primaryButton.label'),
        }}
        secondaryButton={{
          label: formatMessage('ar.modals.activities.importCustomers.modal.secondaryButton.label'),
        }}
        useFormResults={rest}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="m">
          <SectionBanner
            description={formatMessage('ar.modals.activities.importCustomers.modal.sectionBanner.text')}
            variant="informative"
          />
          <Form.FileUpload
            acceptTypes={['csv']}
            onDelete={() => setValue('file', null)}
            helperTextProps={{
              label: formatMessage('ar.modals.activities.importCustomers.modal.form.file.helperText'),
            }}
            labelProps={{
              label: formatMessage('ar.modals.activities.importCustomers.modal.form.file.label'),
            }}
            placeholder={formatMessage('ar.modals.activities.importCustomers.modal.file.placeholder')}
            {...registerField('file')}
          />
          <NakedButton
            onClick={onDownloadCSVTemplateFile}
            variant="secondary"
            data-testid="download-csv-template-file-button"
            label={formatMessage('ar.modals.activities.importCustomers.modal.downloadTemplate.button.label')}
          />
        </Group>
      </FormDialog>
    );
  }
);
ImportCustomersScreen.displayName = 'ImportCustomersScreen';
