import { ActionsDropdownMenuItemProps, PillProps } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { FirmClientSubscriptionInfo, RoleUniqueNames } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';
import {
  getSubscriptionAllowedActions,
  isAccountantFirmClientPlanTier,
  useIsSubscriptionsEnabled,
} from '@melio/subscriptions';
import { MouseEventHandler } from 'react';

import { isEligibleForAssignPlan } from '../v1/utils/assign-plan.utils';

type SubscriptionPlanState = 'missing' | 'activated' | 'awaiting-activation';
export type LimitedBillingOptionType = 'anonymous-payor' | 'operation-not-available';

export const useFirmClientSubscriptionActionsProps = ({
  clientOrgId,
  subscriptionInfo,
  onGoToAssignPlan,
  onManageClientPlan,
  onProceedToCheckout,
  onManageClientBillingOption,
  onViewBillingHistoryOption,
  isDataFetchedSuccessfully,
  firmOrganizationId,
}: {
  clientOrgId: string;
  subscriptionInfo?: FirmClientSubscriptionInfo;
  firmOrganizationId: string;
  onManageClientPlan: VoidFunction;
  onProceedToCheckout: VoidFunction;
  onGoToAssignPlan: VoidFunction;
  onManageClientBillingOption: VoidFunction;
  onViewBillingHistoryOption?: ({
    isClientPayingForSubscription,
    isFirmPayingForSubscription,
  }: {
    isClientPayingForSubscription: boolean;
    isFirmPayingForSubscription: boolean;
  }) => void;
  isDataFetchedSuccessfully: boolean;
}) => {
  const { formatMessage } = useMelioIntl();
  const { isAwaitingActivation: isSubscriptionAwaitingActivation, planMetadata, subscription } = subscriptionInfo || {};
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();

  const [manageBillingOptionEnabled] = usePartnerFeature(
    'AccountantsManageClientSubscriptionBillingOptionEnabled',
    false
  );

  const subscriptionPayorOrganizationId = subscription?.payingOrganization?.organizationId;
  const isClientPayingForSubscription = subscriptionPayorOrganizationId === clientOrgId;
  const isFirmPayingForSubscription = subscriptionPayorOrganizationId === firmOrganizationId;
  const isAnonymousPayor =
    !!subscriptionPayorOrganizationId && !isClientPayingForSubscription && !isFirmPayingForSubscription;

  const { actorRole } = usePermissions();

  const canUpdateBillingMethod = [RoleUniqueNames.MelioAdmin, RoleUniqueNames.MelioOwner].includes(
    // @ts-expect-error TODO: Will be replaced with 'can' function after implement in backend
    actorRole
  );
  const isUpdateBillingMethodAllowed =
    !!subscription && getSubscriptionAllowedActions(subscription).updatePaymentMethod;

  const isAccountingFirmClientPlan = planMetadata?.planTier
    ? isAccountantFirmClientPlanTier(planMetadata.planTier)
    : false;

  const subscriptionPlanDropdownActionsPropsMapping: Record<
    SubscriptionPlanState,
    Array<ActionsDropdownMenuItemProps>
  > = {
    missing: [
      {
        label: formatMessage('activities.accountants.firmClient.actions.assignClientPlan'),
        onClick: () => onGoToAssignPlan(),
        dataTestId: `organization-assign-plan-${clientOrgId}`,
      },
    ],
    activated: [
      {
        label: formatMessage('activities.accountants.firmClient.actions.manageClientPlan'),
        onClick: onManageClientPlan,
        dataTestId: `organization-manage-plan-${clientOrgId}`,
      },
      ...(manageBillingOptionEnabled && !isAnonymousPayor && canUpdateBillingMethod
        ? [
            {
              label: formatMessage('activities.accountants.firmClient.actions.manageClientBilling'),
              onClick: onManageClientBillingOption,
              dataTestId: `organization-manage-billing-option-${clientOrgId}`,
              disabled: { isDisabled: !isUpdateBillingMethodAllowed },
            },
          ]
        : []),
      ...(!isAnonymousPayor && onViewBillingHistoryOption
        ? [
            {
              label: formatMessage('activities.accountants.firmClient.actions.viewBillingHistory'),
              onClick: () => onViewBillingHistoryOption({ isClientPayingForSubscription, isFirmPayingForSubscription }),
              dataTestId: `organization-view-billing-history-${clientOrgId}`,
            },
          ]
        : []),
    ],
    'awaiting-activation': [
      {
        label: formatMessage('activities.accountants.firmClient.actions.proceedToCheckout'),
        onClick: onProceedToCheckout,
        dataTestId: `organization-processed-to-checkout-${clientOrgId}`,
      },
    ],
  };

  const subscriptionPlanPillPropsMapping: Record<
    SubscriptionPlanState,
    (PillProps & { tooltip?: string; onClick?: MouseEventHandler }) | null
  > = {
    missing: {
      label: formatMessage('activities.accountants.OrganizationCard.pill.assignPlan.label'),
      status: 'neutral',
      type: 'secondary',
      tooltip: planMetadata?.isFreePlan
        ? formatMessage('activities.accountants.OrganizationCard.pill.assignPlan.upgrade.tooltip')
        : undefined,
      onClick: onGoToAssignPlan,
    },
    'awaiting-activation': {
      label: formatMessage('activities.accountants.OrganizationCard.pill.awaitingActivation.label'),
      status: 'neutral',
      type: 'secondary',
    },
    activated: null,
  };

  const subscriptionPlanState = ((): SubscriptionPlanState | null => {
    if (!isDataFetchedSuccessfully) {
      return null;
    }
    if (isSubscriptionsEnabled) {
      if (isAccountingFirmClientPlan) {
        return 'activated';
      }
      if (isSubscriptionAwaitingActivation) {
        // There is no active subscription, and the chosen client plan is waiting for checkout
        return 'awaiting-activation';
      }
      if (isEligibleForAssignPlan(subscriptionInfo)) {
        // There is no active subscription and no tier (in case of Melio_Basic)
        return 'missing';
      }
    }
    return null;
  })();

  const getLimitedBillingOptionsType = (): LimitedBillingOptionType | undefined => {
    if (!manageBillingOptionEnabled || subscriptionPlanState !== 'activated' || !canUpdateBillingMethod) {
      return undefined;
    }
    if (!isUpdateBillingMethodAllowed) {
      return 'operation-not-available';
    }
    return undefined;
  };

  const subscriptionPlanDropdownActionsProps = subscriptionPlanState
    ? subscriptionPlanDropdownActionsPropsMapping[subscriptionPlanState]
    : [];
  const subscriptionPlanPillProps = subscriptionPlanState && subscriptionPlanPillPropsMapping[subscriptionPlanState];

  return {
    subscriptionPlanDropdownActionsProps,
    limitedBillingOptionsType: getLimitedBillingOptionsType(),
    subscriptionPlanPillProps,
    subscriptionPlanState,
    isAnonymousPayor,
  };
};
