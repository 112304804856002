import { InternationalAddress as GoogleAddress, parseGoogleAddress } from '@melio/form-controls';
import { _createFormFieldInput } from '@melio/penny';
import { formatAddress, InternationalAddress } from '@melio/platform-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useConfig } from '@melio/platform-provider';
import { forwardRef, useDebouncedObject } from '@melio/platform-utils';
// eslint-disable-next-line import/no-extraneous-dependencies, import/no-internal-modules
import usePlacesAutocompleteService from '@melio/react-google-autocomplete/lib/usePlacesAutocompleteService';
import { useEffect, useState } from 'react';

import { Combobox, ComboboxOption, ComboboxProps } from './Combobox';

type InternationalAddressOption = ComboboxOption<InternationalAddress>;

export type InternationalAddressFieldInputProps = Omit<ComboboxProps<InternationalAddress>, 'options'> & {
  restrictedCountry?: string[] | string | null;
};

export const InternationalAddressFieldInput = _createFormFieldInput(
  forwardRef<InternationalAddressFieldInputProps, 'input'>(({ value, restrictedCountry = null, ...props }, ref) => {
    const { googleMaps } = useConfig().services;

    const autocompleteService = usePlacesAutocompleteService({ apiKey: googleMaps.apiKey, debounce: 10 });

    const [_options, setOptions] = useState<InternationalAddressOption[]>([]);
    // debounce options to prevent flickering
    const options = useDebouncedObject(_options, 50);

    useEffect(() => {
      autocompleteService.placePredictions.forEach((p) => {
        autocompleteService.placesService?.getDetails({ placeId: p.place_id }, (placeResult) => {
          if (placeResult) {
            setOptions((prevOptions) => [...prevOptions, parsePlaceResult(placeResult)]);
          }
        });
      });
    }, [autocompleteService.placePredictions, autocompleteService.placesService]);

    return (
      <Combobox
        {...props}
        ref={ref}
        debounceDelay={250}
        options={options}
        isLoadingOptions={
          autocompleteService.isPlacePredictionsLoading || autocompleteService.isQueryPredictionsLoading
        }
        value={value && parsInternationalAddress(value)}
        onInputChange={(searchTerm: string) => {
          setOptions([]);
          autocompleteService.getPlacePredictions({
            input: searchTerm,
            types: ['address'],
            componentRestrictions: { country: restrictedCountry },
          });
        }}
      />
    );
  })
);

InternationalAddressFieldInput.displayName = 'InternationalAddressFieldInput';

const parsInternationalAddress = (option: InternationalAddress): InternationalAddressOption => ({
  value: option,
  label: formatAddress(option),
});

const parseOption = (option: GoogleAddress): InternationalAddressOption => ({
  value: {
    city: option.city.name,
    countryCode: option.country.code,
    postalCode: option.postalCode,
    state: option.state.code,
    line1: option.line1,
  },
  label: option.formattedAddress,
});

const parsePlaceResult = (placeResult: google.maps.places.PlaceResult): InternationalAddressOption =>
  parseOption(parseGoogleAddress(placeResult));
