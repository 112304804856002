import { ReactivatePlanFundingSourceRequiredModalDriver } from '@melio/subscriptions/test';
import { act, screen, within } from '@testing-library/react';

import { Tier } from '../../../../api';
import { PlansPromotionBannerJestDriver } from '../../../components/PlansPromotionBanner/PlansPromotionBanner.driver';

export class SubscriptionPlansDriver {
  reactivatePlanFundingSourceRequiredModalDriver = new ReactivatePlanFundingSourceRequiredModalDriver();
  plansPromotionBannerDriver = new PlansPromotionBannerJestDriver();

  getPageContainer() {
    return screen.getByTestId('subscription-plans-screen');
  }
  getPlanCard(id: string) {
    return screen.getByTestId(`subscription-plan-card-${id}`);
  }

  getPlanCardPrice(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).getByTestId('plan-card-price');
  }

  getPlanCardPriceBeforeDiscount(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).getByTestId('plan-card-price-before-discount');
  }

  findPlanCardPriceBeforeDiscount(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).queryByTestId('plan-card-price-before-discount');
  }

  getPlanCardUnitPrice(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).getByTestId('plan-card-unit-price');
  }

  getPlanButton(tier: Tier) {
    return screen.getByTestId(`subscription-${tier}-plan-card-button`);
  }

  getPlanButtonTooltip(tier: Tier) {
    return screen.getByTestId(`subscription-${tier}-plan-card-button-tooltip`);
  }

  getPlanBadge(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).queryByTestId('subscription-plan-badge');
  }

  getPlanHeader(id: string) {
    return within(screen.getByTestId(`subscription-plan-card-${id}`)).getByTestId('subscription-plan-card-header');
  }

  getBillingCycleSwitch() {
    return screen.getByTestId('subscription-plans-cycle-switch');
  }

  switchBillingCycle() {
    act(() => {
      this.getBillingCycleSwitch().click();
    });
  }

  getPendingSubscriptionChangeMessage() {
    return screen.queryByTestId('pending-plan-change-section-banner');
  }

  getReactivatePlanModal() {
    return screen.queryByTestId('reactivate-plan-modal');
  }

  getReactivationRequiresFundingSourceModal() {
    return this.reactivatePlanFundingSourceRequiredModalDriver.getModal();
  }

  getSubscriptionPromotionBanners() {
    return this.plansPromotionBannerDriver.getSubscriptionPromotionBanners();
  }

  getBackButton() {
    return within(screen.getByTestId('subscription-plans-screen')).getByTestId('back-button');
  }

  getPlanPricingInfoLink() {
    return this.getPageContainer().querySelector('[data-testid="plan-pricing-info-link"]');
  }
}

export class SubscriptionPlansCypressDriver {
  plansPromotionBannerDriver = new PlansPromotionBannerJestDriver();

  getPageContainer() {
    return cy.getByTestId('subscription-plans-screen');
  }
  getPlanCard(id: string) {
    return cy.getByTestId(`subscription-plan-card-${id}`);
  }

  clickPlanButton(tier: Tier) {
    return cy.getByTestId(`subscription-${tier}-plan-card-button`).click();
  }

  getBillingCycleSwitch() {
    return cy.getByTestId('subscription-plans-cycle-switch');
  }

  switchBillingCycle() {
    this.getBillingCycleSwitch().click();
  }

  getPendingSubscriptionChangeMessage() {
    return screen.queryByTestId('pending-plan-change-section-banner');
  }

  getReactivatePlanModal() {
    return screen.queryByTestId('reactivate-plan-modal');
  }

  getSubscriptionPromotionBanners() {
    return this.plansPromotionBannerDriver.getSubscriptionPromotionBanners();
  }

  clickBackButton() {
    return cy.getByTestId('back-button').click();
  }
}
