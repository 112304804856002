import {
  AddressSearchWidget,
  AddressSearchWidgetProps,
  useCheckValidationSchema,
  useStateSelect,
} from '@melio/ap-widgets';
import { useAddDeliveryMethodUtils } from '@melio/ap-widgets/src/components/delivery-methods/cards/AddDeliveryMethodCard/utils';
import { Form, Text, useMelioForm } from '@melio/penny';
import { Address, DeliveryMethodType, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { StopFormPropagation } from '../../../../form-controls';
import { DeliveryMethodCardProps, PaperCheckForm } from '../../types';
import { DeliveryMethodSectionCard } from '../DeliveryMethodSectionCard';

export const PaperCheckAccountCard = ({
  onSubmit,
  isExpanded,
  onAdd,
  onCancel,
  isDisabled,
  values,
  description,
}: DeliveryMethodCardProps<PaperCheckForm>) => {
  const schema = useCheckValidationSchema();
  const { formProps, registerField, handleSubmit, setValue } = useMelioForm<PaperCheckForm>({
    onSubmit,
    schema,
    values,
  });
  const { formatMessage } = useMelioIntl();
  // TODO: MarikSh Change supported to come from supported types
  const { getIconKey, getLabel, getFee } = useAddDeliveryMethodUtils({
    type: DeliveryMethodType.PaperCheck,
    supported: true,
    suspense: true,
  });
  const stateOptions = useMemo(
    () =>
      US_STATES.map((state) => ({
        value: state,
        label: formatMessage(`local.USA.states.${state}`),
      })),
    [formatMessage]
  );
  const { filteredStateOptions, handleChange: handleStateSearch } = useStateSelect(stateOptions);

  const _onSubmit = handleSubmit();

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const isFormValueCleared = address === null;

    if (isFormValueCleared) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' search works properly only after 2nd search input
      setValue('line1', null);
    }

    const setFormFieldValue = (field: keyof Omit<Address, 'countryCode'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };
    setFormFieldValue('line1');
    setFormFieldValue('line2');
    setFormFieldValue('city');
    setFormFieldValue('state');
    setFormFieldValue('postalCode');
  };

  const fee = getFee(DeliveryMethodType.PaperCheck);

  return (
    <DeliveryMethodSectionCard
      isExpanded={isExpanded}
      onAdd={onAdd}
      onCancel={onCancel}
      onSubmit={_onSubmit}
      isDisabled={isDisabled}
      label={getLabel()}
      icon={getIconKey()}
      pillProps={
        fee
          ? {
              label: fee,
            }
          : undefined
      }
      description={description}
      testId="paper-check-account-card"
    >
      <StopFormPropagation>
        <Form {...formProps} columns={2} size="small">
          <Form.ContentBox colSpan={2}>
            <Text textStyle="body3" color="semantic.text.secondary">
              {formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.description')}
            </Text>
          </Form.ContentBox>
          <Form.TextField
            colSpan={2}
            {...registerField('printName')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.name.label'),
            }}
            helperTextProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.name.helper'),
            }}
          />
          <AddressSearchWidget
            {...registerField('line1')}
            onChange={handleAddressChange}
            colSpan={2}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.address.label'),
            }}
            placeholder={formatMessage(
              'activities.deliveryMethodsWidget.forms.paperCheckAccount.field.address.placeholder'
            )}
          />
          <Form.TextField
            colSpan={2}
            {...registerField('line2')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.addressLine2.label'),
            }}
            placeholder={formatMessage(
              'activities.deliveryMethodsWidget.forms.paperCheckAccount.field.addressLine2.placeholder'
            )}
          />
          <Form.TextField
            colSpan={2}
            {...registerField('city')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.city.label'),
            }}
            placeholder={formatMessage(
              'activities.deliveryMethodsWidget.forms.paperCheckAccount.field.city.placeholder'
            )}
          />
          <Form.SelectNew
            colSpan={1}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.state.label'),
            }}
            placeholder={formatMessage(
              'activities.deliveryMethodsWidget.forms.paperCheckAccount.field.state.placeholder'
            )}
            {...registerField('state')}
            options={US_STATES.map((state) => ({
              value: state,
              label: formatMessage(`local.USA.states.${state}`),
            }))}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
            searchBarProps={{
              placeholder: formatMessage('widgets.vendors.state.placeholder'),
              options: filteredStateOptions,
              onChange: handleStateSearch,
            }}
            shouldHideClearButton
          />
          <Form.TextField
            colSpan={1}
            {...registerField('postalCode')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.paperCheckAccount.field.postalCode.label'),
            }}
            placeholder={formatMessage(
              'activities.deliveryMethodsWidget.forms.paperCheckAccount.field.postalCode.placeholder'
            )}
          />
        </Form>
      </StopFormPropagation>
    </DeliveryMethodSectionCard>
  );
};
