import { Button, Container, Divider, Grid, Group, Illustration, Link, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { PageTitle } from '@melio/platform-utils';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useIsSubscriptionsEnabled } from '../../../hooks';

export const GraceEndPeriodPlanIsRequired = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { track } = useAnalytics();
  const { data: accountData } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });

  const {
    settings: {
      vex: { zdSupportFormUrl },
      subscriptions,
    },
  } = useConfig();

  useEffect(() => {
    if (!track) {
      return;
    }
    track('Organization', 'View', {
      PageName: 'account-owner-action-required',
      Flow: 'subscription-none-tier',
      Intent: 'fix-subscription-payment-error',
    });
  }, [track]);

  const seeAllFeaturesLink = subscriptions?.links?.seeAllFeatures;

  if (!isSubscriptionsEnabled) {
    return <Navigate to="/error" />;
  }

  const companyName = accountData?.company.name;
  const owner = accountData?.organizationOwner;
  const ownerName = `${owner?.firstName || ''} ${owner?.lastName || ''}`;
  const ownerEmail = owner?.email;

  return (
    <Layout maxWidth="600px" backgroundColor="white">
      <Container
        paddingX="xxs"
        paddingY="xxs"
        height="full"
        textAlign="center"
        data-testid="subscription-grace-end-period-plan-is-required"
      >
        <Grid justifyItems="center" alignContent="center" height="full" textAlign="center" gap="s">
          <Illustration size="large" type="blocked" />
          <Group width="fit-content" variant="vertical" alignItems="center" justifyContent="center" spacing="l">
            <Group spacing="xs" variant="vertical">
              <PageTitle textStyle="heading2Semi">
                {formatMessage('activities.subscription.graceEndPeriod.planIsRequired.title')}
              </PageTitle>

              <Text color="global.neutral.900" data-testid="subscription-grace-end-period-plan-is-required-description">
                {formatMessage('activities.subscription.graceEndPeriod.planIsRequired.description', {
                  companyName,
                  ownerName,
                  ownerEmail,
                  linkToPremiumPlans: (
                    <Link
                      onClick={() => {
                        track('Organization', 'Click', {
                          PageName: 'account-owner-action-required',
                          Flow: 'subscription-none-tier',
                          Intent: 'premium-plans',
                          Cta: 'premium-plans',
                        });
                      }}
                      data-testid="subscription-grace-end-period-plan-is-required-premium-plans-link"
                      href={seeAllFeaturesLink || ''}
                      newTab
                      color="secondary"
                      label={formatMessage(
                        'activities.subscription.graceEndPeriod.planIsRequired.linkToPremiumPlansText'
                      )}
                    />
                  ),
                })}
              </Text>
            </Group>

            <Button
              onClick={() => {
                track('Organization', 'Click', {
                  PageName: 'account-owner-action-required',
                  Flow: 'subscription-none-tier',
                  Intent: 'email-account-owner',
                  Cta: 'email-account-owner',
                });
              }}
              variant="primary"
              size="large"
              data-testid="subscription-grace-end-period-plan-is-required-email-account-owner-button"
              label={formatMessage('activities.subscription.graceEndPeriod.planIsRequired.emailAccountOwner')}
              link={{
                href: `mailto:${ownerEmail || ''}`,
                target: '_blank',
              }}
            />

            <Divider />
            <Text>
              {formatMessage('activities.subscription.graceEndPeriod.planIsRequired.contact', {
                linkToSupport: (
                  <Link
                    onClick={() => {
                      track('Organization', 'Click', {
                        PageName: 'account-owner-action-required',
                        Flow: 'subscription-none-tier',
                        Intent: 'contact-support',
                        Cta: 'contact-support',
                      });
                    }}
                    data-testid="subscription-grace-end-period-plan-is-required-support-link"
                    href={zdSupportFormUrl}
                    newTab
                    color="default"
                    label={formatMessage('activities.subscription.graceEndPeriod.planIsRequired.linkToSupportText')}
                  />
                ),
              })}
            </Text>
          </Group>
        </Grid>
      </Container>
    </Layout>
  );
};
