// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Container, ContainerProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
type Variant = 'default' | 'warning';

type Props = {
  content: ReactNode;
  sticky?: boolean;
  variant?: Variant;
  'data-testid'?: string;
  centered?: boolean;
  rounded?: boolean;
  narrow?: boolean;
};

const variantPropsMap: Record<Variant, ContainerProps> = {
  default: { backgroundColor: 'global.brand.100', borderColor: 'global.neutral.400' },
  warning: { backgroundColor: 'global.warning.100', border: 'none' },
};

export const NotificationBanner = ({
  content,
  sticky,
  variant = 'default',
  'data-testid': dataTestId = 'notification-banner',
  rounded,
  centered = true,
  narrow,
  ...rest
}: Props) => {
  const variantProps = variantPropsMap[variant];
  return (
    <Container
      data-testid={dataTestId}
      border="1px"
      justifyContent="space-between"
      textAlign="center"
      {...(narrow ? { paddingX: 'm', paddingY: '20px' } : { padding: 'm' })}
      {...variantProps}
      {...(sticky && { overflow: 'visible', position: 'sticky', top: 0 })}
      {...(rounded && { borderRadius: 8 })}
      maxWidth="unset"
      {...rest}
    >
      <Box display="flex" justifyContent="center" gap="4" {...(centered && { flex: 1, alignItems: 'center' })}>
        <Box>{content}</Box>
      </Box>
    </Container>
  );
};
