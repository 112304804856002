import { FilterOption, MultiSelectFilter, ReceivablePaymentStatus, useMelioIntl } from '@melio/ar-domain';

type PaymentStatusFilterProps = {
  onApply: (filter?: ReceivablePaymentStatus[]) => void;
  selectedStatusFilters?: ReceivablePaymentStatus[];
};

export const PaymentStatusFilter = ({ selectedStatusFilters, onApply }: PaymentStatusFilterProps) => {
  const { formatMessage } = useMelioIntl();
  const filters: ReceivablePaymentStatus[] = ['completed', 'scheduled', 'in-progress'];

  const filterOptions: FilterOption<ReceivablePaymentStatus>[] = filters.map((status) => ({
    filter: status,
    label: formatMessage(`ar.dashboard.activities.paymentsTable.filters.status.${status}.label`),
  }));

  return (
    <MultiSelectFilter
      label={formatMessage('ar.dashboard.activities.paymentsTable.filters.status.plural.label')}
      options={filterOptions}
      selectedFilters={selectedStatusFilters}
      onApply={onApply}
    />
  );
};
