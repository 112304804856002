import { OrganizationKeyIndividualValidations } from '@melio/compliance-validator';
import { useMelioIntl } from '@melio/platform-i18n';
import { isValidInternationalPhoneNumber } from '@melio/platform-utils';
import * as yup from 'yup';

import { AccountHolderForm } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

export const useAccountHolderFormSchema = () => {
  const { addressSchema, emailSchema, residencySchema, riskDateOfBirthSchema } = useFxCommonValidations();
  const { formatMessage } = useMelioIntl();
  const { riskValidationTest } = useFxCommonValidations();

  const getSchema = () =>
    yup.object().shape({
      firstName: yup
        .string()
        .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualFirstName))
        .trim(),
      lastName: yup
        .string()
        .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualLastName))
        .trim(),
      dayOfBirth: riskDateOfBirthSchema,
      phoneNumber: yup
        .string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required'
          )
        )
        .test(
          'valid-intl-phone',
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.personalDetailsCard.phoneNumber.invalid'
          ),
          isValidInternationalPhoneNumber
        ),
      address: addressSchema,
      isUbo: yup.boolean().nullable(),
      email: emailSchema.trim(),
      residencyDetails: residencySchema,
    });

  const schema = getSchema();
  return schema as yup.SchemaOf<AccountHolderForm>;
};
