import { SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  vendorsCount: number;
  validVendorsCount: number;
  invalidVendorsCount: number;
};

export const WarningBanner = ({ vendorsCount, validVendorsCount, invalidVendorsCount }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      variant="critical"
      description={formatMessage('activities.importEntities.reviewVendorsTable.warningBanner.description', {
        plural: validVendorsCount > 1 && 's',
        validVendorsCount,
      })}
      title={formatMessage('activities.importEntities.reviewVendorsTable.warningBanner.title', {
        invalidVendorsCount,
        vendorsCount,
      })}
    />
  );
};
