import { Group, NakedButton, Table } from '@melio/penny';

import { TransactionType } from '../mocks';

export type InvoicesCellProps = {
  transactionType: TransactionType;
  invoiceNumber?: string;
  onClick?: VoidFunction;
};

export const PayoutTypeCell = ({ transactionType, invoiceNumber, onClick }: InvoicesCellProps) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick?.();
  };

  return (
    <Table.Cell data-testid="payout-type-cell">
      <Group spacing="xxs" variant="vertical">
        {transactionType}
        {invoiceNumber && (
          <NakedButton
            variant="secondary"
            label={invoiceNumber}
            data-testid="payout-invoice-link"
            onClick={handleClick}
          />
        )}
      </Group>
    </Table.Cell>
  );
};

PayoutTypeCell.displayName = 'PayoutTypeCell';
