import {
  InboxItemBill,
  InboxItemPaymentRequest,
  InboxItemScannedInvoice,
  Todo,
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueInboxItems,
  TodoOverdueInMoreThan7DaysInboxItems,
} from '@melio/platform-api';

export const normalizeInboxItems = (
  todo: TodoOverdueInboxItems | TodoOverdueIn7DaysInboxItems | TodoOverdueInMoreThan7DaysInboxItems | undefined
) => {
  if (!todo) {
    return [];
  }
  return todo.items.data
    .map((d) => {
      switch (d.type) {
        case 'bill': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
            usdToForeignRate: payload.usdToForeignRate == null ? undefined : payload.usdToForeignRate,
            type: 'bill',
          };
        }
        case 'paymentRequest': {
          const payload = d.payload;
          return {
            amount: payload.totalAmount,
            currency: payload.currency,
            vendorName: payload.vendor?.name ?? '',
            dueDate: payload.dueDate,
            type: 'paymentRequest',
          };
        }
        case 'scannedInvoice': {
          const payload = d.payload;
          return {
            amount: payload.amount,
            currency: 'USD',
            vendorName: payload.vendorName ?? '',
            dueDate: payload.dueDate,
            type: 'scannedInvoice',
          };
        }
        case 'payment':
          return null;
      }
    })
    .filter(
      (
        d
      ): d is {
        amount: number;
        currency: string;
        vendorName: string;
        dueDate: Date;
        usdToForeignRate: number | undefined;
        type: InboxItemBill['type'] | InboxItemPaymentRequest['type'] | InboxItemScannedInvoice['type'];
      } => !!d
    );
};

export const isEmptySection = (todo?: Todo) =>
  !todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null;
