import { useMelioQueryClient, useNonEntityMutation } from '@melio/api-client';
import { OnboardingApiClient, OnboardingStatusApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

export type UseOrganizationEnrollmentStatusProps = UseModelProps<typeof OnboardingStatusApiClient.getOnboardingStatus>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getOnboardingStatus = (_id: string) => OnboardingStatusApiClient.getOnboardingStatus();

export const useOrganizationEnrollmentStatus = (props: UseOrganizationEnrollmentStatusProps = {}) => {
  const model = useModel({
    ...props,
    queryKey: 'OnboardingStatusApi',
    queryFn: getOnboardingStatus,
    id: 'onboarding-status',
  });

  const queryClient = useMelioQueryClient();

  const activateMutation = useNonEntityMutation(OnboardingApiClient.postOnboarding, model.queryKey, {
    onSuccess: () => {
      void queryClient.invalidateQueries('OnboardingStatusApi');
    },
  });

  return {
    ...model,
    activateOrganization: () => activateMutation.mutateAsync(''),
  };
};

export type OrganizationEnrollmentStatusModel = ReturnType<typeof useOrganizationEnrollmentStatus>;
