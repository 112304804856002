import { useMelioIntl, useNavigate } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { usePartnerFeature } from '@melio/platform-provider';
import { useMatch, useResolvedPath } from 'react-router-dom';

import { TabItem } from '../../../layouts';
import {
  customers_screen_id,
  customers_tab_panel_id,
  invoices_screen_id,
  invoices_tab_panel_id,
  payments_screen_id,
  payments_tab_panel_id,
  payouts_screen_id,
  payouts_tab_panel_id,
} from '../../../utils';
import { ARTab, DefaultARTab } from '../types';

export const useArDashboardTabs = () => {
  const navigate = useNavigate<ARTab>();
  const { formatMessage } = useMelioIntl();
  const resolvedPathUrl = useResolvedPath('');
  const match = useMatch({ path: `${resolvedPathUrl.pathname}/:tab/*`, end: true });
  const { track } = useAnalytics();
  const activeTab = (match?.params.tab || DefaultARTab) as ARTab;
  const [isPaymentsTabEnabled] = usePartnerFeature('ARPaymentsTab', false);
  const [isPayoutsTabEnabled] = usePartnerFeature('ARPayoutsTab', false);

  const arDashboardTabs: TabItem<ARTab>[] = [
    {
      name: 'invoices',
      label: formatMessage('ar.dashboard.activities.tabsLayout.tabs.invoices.label'),
      'aria-controls': invoices_screen_id,
      'aria-label': formatMessage('ar.dashboard.activities.tabsLayout.tabs.invoices.aria-label'),
      id: invoices_tab_panel_id,
    },
    {
      name: 'customers',
      label: formatMessage('ar.dashboard.activities.tabsLayout.tabs.customers.label'),
      'aria-controls': customers_screen_id,
      'aria-label': formatMessage('ar.dashboard.activities.tabsLayout.tabs.customers.aria-label'),
      id: customers_tab_panel_id,
    },
    ...(isPayoutsTabEnabled
      ? [
          {
            name: ARTab.Payouts,
            label: 'Payouts',
            'aria-controls': payouts_screen_id,
            'aria-label': formatMessage('ar.dashboard.activities.tabsLayout.tabs.payouts.aria-label'),
            id: payouts_tab_panel_id,
          },
        ]
      : []),
    ...(isPaymentsTabEnabled
      ? [
          {
            name: ARTab.Payments,
            label: formatMessage('ar.dashboard.activities.tabsLayout.tabs.payments.label'),
            'aria-controls': payments_screen_id,
            'aria-label': formatMessage('ar.dashboard.activities.tabsLayout.tabs.payments.aria-label'),
            id: payments_tab_panel_id,
          },
        ]
      : []),
  ];

  const handleTabChange = (tab: ARTab) => {
    track('Dashboard', 'Click', {
      Intent: `${tab}-tab`,
      Cta: tab,
    });

    navigate(tab);
  };

  return { tabs: arDashboardTabs, activeTab, handleTabChange, isPaymentsTabEnabled };
};
