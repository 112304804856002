import { StatusIconSolid } from '@melio/penny';
import { TodoPaymentsToApprove, TodoPaymentsToApproveTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { PayDashboardTabs } from '../../../PayDashboard';
import { useTodosDescriptions } from '../../hooks/useTodosDescriptions';
import { EnrichedTodo, normalizePayments } from '../../utils';
import { TodoSection } from '../TodoSection';

export type TodoSectionPaymentsToApproveProps = {
  todo?: EnrichedTodo<TodoPaymentsToApprove>;
  onNavigate?: VoidFunction;
};

export const TodoSectionPaymentsToApprove = ({ todo, onNavigate }: TodoSectionPaymentsToApproveProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToTab } = useActivitiesNavigate();
  const { createAmountDescription, createViewPaymentAction, createDueDateDescription } = useTodosDescriptions();

  if (!todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null) {
    return null;
  }
  const totalItems = todo.items.pagination.totalCount || 0;

  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.paymentsToApprove.singular.title', {
          vendorName: todo.items.data[0]?.vendor?.name ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.paymentsToApprove.plural.title', {
          count: totalItems,
        });

  const onClickAction = () => {
    onNavigate?.();
    setTimeout(() => {
      navigateToTab(PayDashboardTabs.Approvals, totalItems === 1 ? { entityId: todo.items.data[0]?.id } : {});
    });
  };
  const action = createViewPaymentAction(totalItems, onClickAction);

  const data = normalizePayments(todo);
  const description = [...createAmountDescription(totalItems, data)];
  if (totalItems === 1 && todo.items.data[0]?.scheduledDate) {
    description.push(
      ...createDueDateDescription(totalItems, data.length, todo.items.data[0].scheduledDate, 'debitDate')
    );
  }

  return (
    <TodoSection
      key={`section-${TodoPaymentsToApproveTypeEnum.PaymentsToApprove}`}
      icon={<StatusIconSolid variant="warning" size="medium" />}
      title={title}
      description={description}
      action={action}
      type={TodoPaymentsToApproveTypeEnum.PaymentsToApprove}
      newItemsCount={todo.enrichment.newItemsCount}
    />
  );
};
