import { Badge, Button, Checkbox, Container, FloatingMenu, Group, Icon, StatusIndicator, Text } from '@melio/penny';
import { xor } from 'lodash';
import { useEffect, useState } from 'react';

import { useMelioIntl } from '../i18n';

export type FilterOption<T> = {
  filter: T;
  label: string;
};

type MultiSelectFilterProps<T> = {
  onApply: (filter?: T[]) => void;
  options: FilterOption<T>[];
  label: string;
  selectedFilters?: T[];
  'data-testid'?: string;
};

const getSelectedFiltersState = <T extends string>(selectedFilters?: T[]) =>
  selectedFilters ? selectedFilters.reduce((acc, filter) => ({ ...acc, [filter]: true }), {}) : {};

export const MultiSelectFilter = <T extends string>({
  selectedFilters: preSelectedFilters,
  onApply,
  options,
  label,
  'data-testid': dataTestId,
}: MultiSelectFilterProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Partial<Record<T, boolean>>>(getSelectedFiltersState);

  useEffect(() => {
    const initialSelectedFilters = getSelectedFiltersState<T>(preSelectedFilters);
    setSelectedFilters(initialSelectedFilters);
  }, [preSelectedFilters]);

  const { formatMessage } = useMelioIntl();
  const buttonLabel = preSelectedFilters?.length
    ? formatMessage('ar.domain.components.multiSelectFilter.plural.label', {
        label,
        count: preSelectedFilters.length,
      })
    : label;

  const triggerElement = (
    <Badge mark={preSelectedFilters?.length ? <StatusIndicator status="success" /> : undefined} placement="top-right">
      <Button
        data-testid={dataTestId || 'multi-select-filter-menu-button'}
        variant="tertiary"
        label={buttonLabel}
        rightElement={<Icon size="small" type={isOpen ? 'caret-up' : 'caret-down'} color="inherit" aria-hidden />}
      />
    </Badge>
  );

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    if (!isOpen) {
      const initialSelectedFilters = getSelectedFiltersState(preSelectedFilters);
      setSelectedFilters(initialSelectedFilters);
    }
  };

  const hasChanges = xor(Object.keys(selectedFilters), preSelectedFilters || []).length > 0;

  return (
    <FloatingMenu
      maxHeight={320}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      trigger={triggerElement}
      content={
        <Container paddingY="xs">
          <Group variant="vertical" spacing="none">
            {options.map(({ filter, label }) => (
              <Container paddingX="s" paddingY="xs-s" key={filter}>
                <Group spacing="xs-s" alignItems="center">
                  <Checkbox
                    data-testid={`multi-select-filter-${filter}`}
                    isChecked={!!selectedFilters[filter]}
                    onChange={() =>
                      setSelectedFilters((prevState) => {
                        const newState = { ...prevState };
                        if (filter in newState) {
                          delete newState[filter];
                        } else {
                          newState[filter] = true;
                        }
                        return newState;
                      })
                    }
                  />
                  <Text textStyle="body3">{label}</Text>
                </Group>
              </Container>
            ))}
          </Group>
        </Container>
      }
      footer={
        <Container paddingY="s">
          <Group variant="horizontal" spacing="s" justifyContent="center">
            <Button
              label={formatMessage('ar.domain.components.multiSelectFilter.footer.cancel')}
              size="small"
              variant="tertiary"
              onClick={() => handleOpenChange(false)}
              data-testid="multi-select-filter-menu-button-cancel"
              isDisabled={!hasChanges}
            />
            <Button
              label={formatMessage('ar.domain.components.multiSelectFilter.footer.apply')}
              size="small"
              onClick={() => {
                setIsOpen(false);
                onApply(Object.keys(selectedFilters) as T[]);
              }}
              data-testid="multi-select-filter-menu-button-apply"
            />
          </Group>
        </Container>
      }
    />
  );
};
