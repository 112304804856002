import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useGetSearchParamsValues = (paramsName: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getParamsValues = () => {
    const paramsObject: Record<string, string> = {};
    paramsName.forEach((key) => {
      const searchParamValue = searchParams.get(key);
      if (searchParamValue) {
        paramsObject[key] = searchParamValue;
      }
    });
    return paramsObject;
  };

  const urlSearchParams = searchParams.toString();

  const removeParamsValues = useCallback(() => {
    const searchParams = new URLSearchParams(urlSearchParams);
    paramsName.forEach((key) => {
      searchParams.delete(key);
    });
    return setSearchParams(searchParams);
  }, [paramsName, setSearchParams, urlSearchParams]);

  return { getParamsValues, removeParamsValues };
};
