import { useMelioIntl } from '@melio/ar-domain';
import { Group, IconButton } from '@melio/penny';
import { forwardRef, PageTitle } from '@melio/platform-utils';

type InvoiceFormDesktopHeaderProps = {
  headerText: string;
  onClose?: VoidFunction;
};
export const InvoiceFormHeader = forwardRef<InvoiceFormDesktopHeaderProps>(({ headerText, onClose }) => {
  const { formatMessage } = useMelioIntl();
  return onClose ? (
    <Group justifyContent="space-between">
      <PageTitle textStyle="heading1Semi">{headerText}</PageTitle>
      <IconButton
        aria-label={formatMessage('ar.form.actions.close.label')}
        icon="close"
        size="large"
        variant="primary"
        onClick={onClose}
        data-testid="invoice-form-close-button"
      />
    </Group>
  ) : (
    <PageTitle textStyle="heading1Semi">{headerText}</PageTitle>
  );
});
InvoiceFormHeader.displayName = 'InvoiceFormHeader';
