import { useMelioIntl } from '@melio/ar-domain';
import { Avatar, Group, Icon, Link, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { MouseEventHandler } from 'react';

export type PaymentDetailsHeaderProps = {
  companyName?: string;
  companyLogoUrl?: string;
  totalAmount?: number;
  onEditPayment: VoidFunction;
};

export const PaymentDetailsHeader = forwardRef<PaymentDetailsHeaderProps>(
  ({ companyName = '', companyLogoUrl, totalAmount, onEditPayment }, ref) => {
    const { formatCurrency, formatMessage } = useMelioIntl();

    const handleEditPayment: MouseEventHandler<HTMLLinkElement> = (e) => {
      e.preventDefault();
      onEditPayment();
    };

    return (
      <Group justifyContent="space-between" variant="vertical" ref={ref}>
        <Avatar data-testid="company-logo" name={companyName} size="large" src={companyLogoUrl} variant="square" />
        <Text textStyle="heading3Semi" data-testid="company-name">
          {companyName}
        </Text>
        <Group variant="vertical" spacing="xs">
          <Text textStyle="heading1Semi" data-testid="payment-amount">
            {formatCurrency(totalAmount ?? 0)}
          </Text>
          <Group alignItems="center" spacing="xxs">
            <Icon type="edit" size="small" />
            <Text textStyle="body3">
              <Link
                href="#"
                onClick={handleEditPayment}
                label={formatMessage('ar.guestPayment.header.editPaymentDetailsLink.text')}
              />
            </Text>
          </Group>
        </Group>
      </Group>
    );
  }
);

PaymentDetailsHeader.displayName = 'PaymentDetailsHeader';
