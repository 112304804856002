import { Box } from '@chakra-ui/react';
import { CardHolderDetailsForm } from '@melio/ap-widgets';
import { Button, Group, Text, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { CardHolderDetails } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';
import { forwardRef, SystemMessageDisplay } from '@melio/platform-utils';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CaratLayout } from '../shared/CaratLayoutForm';

export type CaratCardHolderDetailsScreenProps = {
  isSaving?: boolean;
  defaultValues?: Partial<CardHolderDetails>;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (data: CardHolderDetails) => void;
  setCardAssignRedirect: (value: boolean) => void;
};

export const CaratCardHolderDetailsScreen: React.VFC<CaratCardHolderDetailsScreenProps> = forwardRef<
  CaratCardHolderDetailsScreenProps,
  'div'
>(({ defaultValues, isSaving, onBack, onClose, onDone, setCardAssignRedirect, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<CardHolderDetails>();
  const isMobile = useBreakpointValue({ xs: true, s: false });

  useAnalyticsView('AddCCHolderDetails');

  return (
    <CaratLayout
      data-component="CaratCardHolderDetailsScreen.CardHolderDetailsScreen"
      data-testid="carat-add-card-funding-source-activity-card-holder-details-screen"
      ref={ref}
      {...props}
      title={formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.title')}
      onClose={onClose}
      footerContent={
        <>
          {!isMobile && (
            <Button
              data-component="CaratCardHolderDetailsScreen.closeButton"
              data-testid="layout-close-button"
              tabIndex={0}
              variant="tertiary"
              label={formatMessage(`widgets.carat.form.button.cancel`)}
              onClick={onClose}
            />
          )}
          <Group
            variant="horizontal"
            justifyContent={isMobile ? 'space-between' : 'flex-end'}
            width={isMobile ? 'full' : undefined}
          >
            <Button
              {...submitButtonProps}
              variant="tertiary"
              data-testid="carat-card-holder-details-screen-save-no-assign"
              onClick={() => {
                setCardAssignRedirect(false);
                submitButtonProps?.onClick();
              }}
              label={formatMessage('widgets.carat.form.cardHolderDetails.save')}
            />

            <Button
              {...submitButtonProps}
              data-testid="carat-card-holder-details-screen-save-assign"
              onClick={() => {
                setCardAssignRedirect(true);

                submitButtonProps?.onClick();
              }}
              label={formatMessage('widgets.carat.form.cardHolderDetails.SaveAssignUser')}
            />
          </Group>
        </>
      }
    >
      <Group variant="vertical" spacing="s" data-testid="carat-holder-details-content">
        <SystemMessageDisplay data-testid="carat-form-details-failed-alert" />
        {!isMobile && (
          <NewSinglePaymentStepLayout.Title textAlign="start" onClose={onClose}>
            {formatMessage('activities.addCardFundingSource.screens.cardHolderDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        <NewSinglePaymentStepLayout.Content>
          <Box maxWidth="600px" width="100%">
            <CardHolderDetailsForm
              headerContent={
                <Text color="global.neutral.900" textStyle="body4">
                  {formatMessage('widgets.cardHolderDetailsForm.requiredFields')}
                </Text>
              }
              onSubmit={onDone}
              defaultValues={defaultValues}
              isSaving={isSaving}
              onSubmissionStateChange={onSubmissionStateChange}
            />
          </Box>
        </NewSinglePaymentStepLayout.Content>
      </Group>
    </CaratLayout>
  );
});

CaratCardHolderDetailsScreen.displayName = 'CaratCardHolderDetailsScreen.CardHolderDetailsScreen';
