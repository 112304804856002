import { Button, Container, Group, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { SubscriptionBillingCycleEnum } from '@melio/subscriptions/src/api';
import React from 'react';

import {
  FirmClientSubscriptionReviewAndConfirmActivity,
  PreviewState,
} from '../../../activities/FirmClientSubscriptionReviewAndConfirm';
import { useFirmClient } from '../../../hooks';
import { SubscriptionBillingPayor } from '../../../types';
import { StepFormProps, useManageClientFormContext } from '..';

export const ManageClientPlanReviewAndConfirmStep: React.FC<StepFormProps> = ({
  submitLabel,
  onContinue,
  isSaving,
}) => {
  const {
    form: { watch, submitButtonProps, setValue, getFieldState },
    clientOrgId,
  } = useManageClientFormContext();
  const [previewState, setPreviewState] = React.useState<PreviewState | null>(null);
  const { formatMessage, formatDate } = useMelioIntl();

  const { data: client } = useFirmClient(clientOrgId);

  const currentSubscription = client?.subscriptionInfo?.subscription;
  const watchPlanId = watch('subscriptionPlanId');
  const watchWhoPays = watch('whoPays');
  const planId = watchPlanId || currentSubscription?.planId;
  const promoCode = watch('subscriptionPromoCode');
  const isFirmBilled = watchWhoPays === SubscriptionBillingPayor.AccountingFirm;

  if (!planId) {
    return <></>;
  }

  const handlePromoCodeChange = (value?: string) => {
    setValue('subscriptionPromoCode', value);
  };

  const isUpdateMode = !!currentSubscription?.id;
  const currentPayorOrganization = currentSubscription?.payingOrganization?.organizationId;
  const isAboutToChangePayor = currentPayorOrganization && currentPayorOrganization !== watchWhoPays;
  const shouldPrefetchPromoCode = !isUpdateMode && !!promoCode && !getFieldState('subscriptionPromoCode').isDirty;
  const nextBillingDate = currentSubscription?.nextBillingDate;

  return (
    <Group spacing="m" variant="vertical" width="full">
      {isAboutToChangePayor && watchWhoPays && nextBillingDate && (
        <SectionBanner
          data-testid="payor-change-information-banner"
          description={formatMessage(
            `activities.accountants.manageClient.form.reviewAndConfirm.billingMethodChange.${watchWhoPays}.banner.text`,
            {
              nextBillingDate: formatDate(nextBillingDate, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
              }),
            }
          )}
        />
      )}
      {planId && (
        <FirmClientSubscriptionReviewAndConfirmActivity
          organizationId={clientOrgId}
          planId={planId}
          seatsQuantity={0}
          fundingSourceId={watch('fundingSourceId')}
          isFirmBilled={isFirmBilled}
          planCycle={SubscriptionBillingCycleEnum.Monthly}
          onPreviewStateChange={setPreviewState}
          promoCode={promoCode}
          onPromoCodeChange={handlePromoCodeChange}
          shouldPrefetchPromoCode={shouldPrefetchPromoCode}
          suppressPromoCode={isUpdateMode}
        />
      )}
      <Container justifyContent="flex-end">
        <Button
          data-testid="review-and-confirm-continue-button"
          variant="primary"
          size="medium"
          label={submitLabel}
          {...submitButtonProps}
          onClick={onContinue}
          isLoading={previewState === 'loading' || isSaving}
          isDisabled={previewState === 'error'}
        />
      </Container>
    </Group>
  );
};
