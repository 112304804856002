import { Group, Icon, Pill, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { MigratedUserExperienceRow } from './MigratedUserExperienceRow';

export const MigratedUserExperiencePillRow = () => {
  const { formatMessage } = useMelioIntl();
  return (
    <Group variant="horizontal" spacing="xxs" alignItems="center">
      <MigratedUserExperienceRow
        description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row3')}
        iconType="checked"
      />
      <Pill
        type="secondary"
        rightElement={
          <Text textStyle="body4" color="semantic.text.brand.onColor">
            {formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row3.pill')}
          </Text>
        }
        leftElement={<Icon type="premium" color="brand" size="small" />}
        status="brand"
        label=""
      />
    </Group>
  );
};
