import { useDisclosure } from '@chakra-ui/react';
import { PlaidAccountData, usePlaid } from '@melio/ar-domain';
import { useEffect } from 'react';

export type PlaidData = { plaidAccountId: string; plaidToken: string };

export type UsePlaidDialogProps = {
  onSuccess: (params: PlaidData) => void;
  onError?: ARErrorFunction;
  onOpen?: VoidFunction;
};

export const usePlaidDialog = ({ onSuccess, onOpen, onError }: UsePlaidDialogProps) => {
  const plaidDialogState = useDisclosure({ onOpen });

  const onPlaidSuccess = (params: PlaidAccountData) => {
    exit();
    onSuccess({ plaidAccountId: params.accounts[0]?.id as string, plaidToken: params.public_token });
  };

  const { open, ready, exit } = usePlaid({
    onSuccess: onPlaidSuccess,
    onError,
    onExit: plaidDialogState.onClose,
  });

  useEffect(() => {
    if (ready && plaidDialogState.isOpen) open();
  }, [ready, open, plaidDialogState.isOpen]);

  return plaidDialogState;
};
