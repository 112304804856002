import { ReviewScannedInvoiceActivity } from '@melio/ap-activities';
import { OriginFlow, withAnalyticsContext } from '@melio/platform-analytics';
import { SystemMessageRequest } from '@melio/platform-sdk';
import { useLocation, useNavigate } from '@melio/platform-utils';

import { ReviewScannedInvoiceStateType, useRouter } from '@/hooks/router.hooks';
import { ScannedInvoiceFilePreview } from '@/widgets/pay-dashboard/review-scanned-invoice/components/ScannedInvoiceFilePreview';
import { MarkAsPaidDialog } from '../bill-details/components/MarkAsPaidDialog';

export const NewReviewScannedInvoiceScreen = withAnalyticsContext<{ scannedInvoiceId: string }>(
  ({ scannedInvoiceId, setAnalyticsProperties }) => {
    const { navigateToSchedulePayment, generateNPEDashboardLink } = useRouter();
    const navigate = useNavigate();
    const { state } = useLocation<ReviewScannedInvoiceStateType>();

    const entryPoint =
      state?.origin === OriginFlow.VendorsFlow
        ? 'dashboard - vendors'
        : state?.origin === OriginFlow.BillPage
        ? 'dashboard - bills'
        : null;

    setAnalyticsProperties({
      EntryPoint: entryPoint,
    });

    const onSchedulePayment = (billId: string) => {
      navigateToSchedulePayment({
        billId,
        returnUrl: generateNPEDashboardLink('payments'),
        keepToast: true,
      });
    };

    const handleGoToBillsTab = (message?: SystemMessageRequest) => {
      navigate(generateNPEDashboardLink('bills'), {
        state: message
          ? {
              systemMessageRequest: message,
            }
          : undefined,
      });
    };

    const onClose = () => {
      if (state?.returnUrl) {
        return navigate(state?.returnUrl);
      }

      handleGoToBillsTab();
    };

    return (
      <ReviewScannedInvoiceActivity
        scannedInvoiceId={scannedInvoiceId}
        goToSchedulePayment={onSchedulePayment}
        markAsPaidDialog={MarkAsPaidDialog}
        invoicePreview={ScannedInvoiceFilePreview}
        goToBillsTab={handleGoToBillsTab}
        onClose={onClose}
      />
    );
  },
);
