/* eslint-disable max-lines */
import {
  TaxIdTypeEnum as CompTaxIdTypeEnum,
  validateBusinessType,
  validateCompanyBusinessAddress,
  validateContactFirstName,
  validateContactLastName,
  validateDateOfBirth,
  validateLegalCompanyName,
  validateLegalDateOfBirth,
  validateTaxId as _validateTaxId,
  validateTaxIdType,
} from '@melio/compliance-validator';
import { OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api-axios-client';
import { format as formatDate, isValid as isValidDate, parse as parseDate } from 'date-fns';
import * as yup from 'yup';
import { AssertsShape, ObjectShape, TypeOfShape } from 'yup/lib/object'; // eslint-disable-line import/no-internal-modules
import { AnyObject, Maybe, Optionals } from 'yup/lib/types'; // eslint-disable-line import/no-internal-modules

import { Address } from './api-hooks';

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_contactFirstName', function (message?: string) {
  return this.test({
    message: '${path} must be a valid first name',
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required
      return validateContactFirstName(value).isValid || ctx.createError({ path: ctx.path, message });
    },
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_contactLastName', function (message?: string) {
  return this.test({
    message: '${path} must be a valid last name',
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required
      return validateContactLastName(value).isValid || ctx.createError({ path: ctx.path, message });
    },
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_legalCompanyName', function (message?: string) {
  return this.test({
    message: '${path} must be a valid company name',
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required
      return validateLegalCompanyName(value).isValid || ctx.createError({ path: ctx.path, message });
    },
  });
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'mtl_validTaxId',
  function ({ businessType, taxIdType }: ValidTaxIdParams, message?: string) {
    return this.test({
      message: '${path} is not a valid tax ID',
      test: (value: string | undefined, ctx) => {
        if (!value) return true; // may not be required

        const targetTaxIdType = getParentTaxIdType(ctx, taxIdType);
        const targetBusinessType = getParentBusinessType(ctx, businessType);

        return (
          (targetTaxIdType &&
            targetBusinessType &&
            _validateTaxId(targetBusinessType, targetTaxIdType, value).isValid) ||
          ctx.createError({ path: ctx.path, message })
        );
      },
    });
  }
);

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_validBusinessType', function (message?: string) {
  return this.test({
    message: '${path} is not a valid business type',
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required
      return (
        validateBusinessType(businessTypeMap[value as OrganizationBusinessType]).isValid ||
        ctx.createError({ path: ctx.path, message })
      );
    },
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_validTaxIdType', function (message?: string) {
  return this.test({
    message: '${path} is not a valid tax ID type',
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required
      return (
        validateTaxIdType(taxIdTypeMap[value as TaxIdTypeEnum]).isValid || ctx.createError({ path: ctx.path, message })
      );
    },
  });
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'mtl_legalDateOfBirth',
  function (taxIdType: TaxIdTypeParam, dateFormat?: string, message?: string) {
    return this.test({
      message: '${path} must be a valid ${dateFormat} legal date of birth',
      params: { taxIdType, dateFormat },
      test: (value: string | undefined, ctx) => {
        if (!value) return true; // may not be required
        const targetDate = parseDate(value, dateFormat ?? 'yyyy-MM-dd', Date.now());
        const targetType = getParentTaxIdType(ctx, taxIdType);
        return (
          (targetType && validateLegalDateOfBirth(targetType, targetDate).isValid) ||
          ctx.createError({ path: ctx.path, message })
        );
      },
    });
  }
);

yup.addMethod<yup.StringSchema>(yup.string, 'mtl_dateOfBirth', function (dateFormat: string, message?: string) {
  return this.test({
    message: '${path} must be a valid ${dateFormat} date',
    params: { dateFormat },
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required

      // check if value matches dateFormat
      const parsedDate = parseDate(value, dateFormat, Date.now());
      return validateDateOfBirth(parsedDate).isValid || ctx.createError({ path: ctx.path, message });
    },
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'dateFormat', function (dateFormat: string, message?: string) {
  return this.test({
    message: '${path} must be a valid ${dateFormat} date',
    params: { dateFormat },
    test: (value: string | undefined, ctx) => {
      if (!value) return true; // may not be required

      // check if value matches dateFormat
      const parsedDate = parseDate(value, dateFormat, Date.now());
      return (
        (isValidDate(parsedDate) && formatDate(parsedDate, dateFormat) === value) ||
        ctx.createError({ path: ctx.path, message })
      );
    },
  });
});

yup.addMethod<yup.DateSchema>(yup.date, 'mtl_legalDateOfBirth', function (taxIdType: TaxIdTypeParam, message?: string) {
  return this.test({
    message: '${path} must be a valid ${dateFormat} legal date of birth',
    test: (value: Date | undefined, ctx) => {
      if (!value) return true; // may not be required
      const targetType = getParentTaxIdType(ctx, taxIdType);
      return validateLegalDateOfBirth(targetType, value).isValid || ctx.createError({ path: ctx.path, message });
    },
  });
});

const addressSchema = yup.object().shape({
  line1: yup.string(),
  city: yup.string(),
  state: yup.string(),
  postalCode: yup.string(),
  countryCode: yup.string(),
});

const getParentTaxIdType = (ctx: yup.TestContext<AnyObject>, taxIdType: TaxIdTypeParam) => {
  const parent = (ctx.parent || {}) as Record<string, string>;
  const taxIdTypeValue = [taxIdType, parent[taxIdType]].filter(isTaxIdType)[0];
  return taxIdTypeValue && taxIdTypeMap[taxIdTypeValue];
};

const getParentBusinessType = (ctx: yup.TestContext<AnyObject>, businessType: TaxIdTypeParam) => {
  const parent = (ctx.parent || {}) as Record<string, string>;
  const businessTypeValue = [businessType, parent[businessType]].filter(isOrganizationBusinessType)[0];
  return businessTypeValue && businessTypeMap[businessTypeValue];
};

yup.addMethod<yup.ObjectSchema<yup.InferType<typeof addressSchema>>>(
  yup.object,
  'mtl_validBusinessAddress',
  function (message?: string) {
    return this.test({
      message: '${path} must be a valid business address',
      test: (value: Address | undefined, ctx) => {
        if (!value) return true; // may not be required
        const { line1: addressLine1, city, state, postalCode: zipCode, countryCode = 'US' } = value;
        return (
          validateCompanyBusinessAddress({ addressLine1, city, state, countryCode, zipCode }).isValid ||
          ctx.createError({ path: ctx.path, message })
        );
      },
    });
  }
);
// eslint-disable-next-line @typescript-eslint/ban-types
type TaxIdTypeParam = TaxIdTypeEnum | (string & {});
// eslint-disable-next-line @typescript-eslint/ban-types
type BusinessTypeParam = OrganizationBusinessType | (string & {});

/** */
type ValidTaxIdParams = {
  /**
   * A TaxIdType or a field name that contains the TaxIdType
   */
  taxIdType: TaxIdTypeParam;
  /**
   * A BusinessType or a field name that contains the BusinessType
   */
  businessType: BusinessTypeParam;
};

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    mtl_legalCompanyName(message?: string): StringSchema<TType, TContext>;
    mtl_contactFirstName(message?: string): StringSchema<TType, TContext>;
    /**
     *
     * @param message The error message
     */
    mtl_contactFirstName(message?: string): StringSchema<TType, TContext>;
    /**
     *
     * @param message The error message
     */
    mtl_contactLastName(message?: string): StringSchema<TType, TContext>;
    /**
     *
     * @param taxIdType A TaxIdType or a field name that contains the TaxIdType
     * @param dateFormat The format of the date. Defaults to 'yyyy-MM-dd'
     * @param message The error message
     */
    mtl_legalDateOfBirth(
      taxIdType: TaxIdTypeParam,
      dateFormat?: string,
      message?: string
    ): StringSchema<TType, TContext>;
    mtl_validBusinessType(message?: string): StringSchema<TType, TContext>;
    mtl_validTaxIdType(message?: string): StringSchema<TType, TContext>;
    mtl_validTaxId(params: ValidTaxIdParams, message?: string): StringSchema<TType, TContext>;
    /**
     *
     * @param dateFormat The format of the date using date-fns format. (aka 'yyyy-MM-dd')
     * @param message The error message
     */
    mtl_dateOfBirth(dateFormat: string, message?: string): StringSchema<TType, TContext>;
    dateFormat(dateFormat: string, message?: string): StringSchema<TType, TContext>;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface DateSchema<
    TType extends Maybe<Date> = Date | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    /**
     *
     * @param taxIdType A TaxIdType or a field name that contains the TaxIdType
     * @param message The error message
     */

    mtl_legalDateOfBirth(taxIdType: TaxIdTypeParam, message?: string): DateSchema<TType, TContext>;
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ObjectSchema<
    TShape extends ObjectShape,
    TContext extends AnyObject = AnyObject,
    TIn extends Maybe<TypeOfShape<TShape>> = TypeOfShape<TShape>,
    TOut extends Maybe<AssertsShape<TShape>> = AssertsShape<TShape> | Optionals<TIn>
  > extends yup.BaseSchema<TIn, TContext, TOut> {
    mtl_validBusinessAddress: (message?: string) => ObjectSchema<TShape, TContext, TIn, TOut>;
  }
}

export { yup };

const isOrganizationBusinessType = (value: unknown): value is OrganizationBusinessType =>
  !!value && Object.values(OrganizationBusinessType).includes(value as OrganizationBusinessType);

const isTaxIdType = (value: unknown): value is TaxIdTypeEnum =>
  !!value && Object.values(TaxIdTypeEnum).includes(value as TaxIdTypeEnum);

enum CompanyBusinessTypes {
  SoleProprietorship = 'sole-proprietorship',
  Partnership = 'partnership',
  LimitedLiabilityCompany = 'llc',
  Corporation = 'corporation',
  NonProfit = 'non-profit',
  NonGovernmentalOrganization = 'ngo',
  Municipal = 'municipal',
  Municipality = 'municipality',
  Trust = 'trust',
}

const businessTypeMap: Record<OrganizationBusinessType, CompanyBusinessTypes> = {
  'limited-liability-company': CompanyBusinessTypes.LimitedLiabilityCompany,
  corporation: CompanyBusinessTypes.Corporation,
  'non-profit': CompanyBusinessTypes.NonProfit,
  'non-governmental-organization': CompanyBusinessTypes.NonGovernmentalOrganization,
  'municipal-corporation': CompanyBusinessTypes.Municipal,
  'sole-proprietorship': CompanyBusinessTypes.SoleProprietorship,
  partnership: CompanyBusinessTypes.Partnership,
  'trust-or-estate': CompanyBusinessTypes.Trust,
};

const taxIdTypeMap: Record<TaxIdTypeEnum, CompTaxIdTypeEnum> = {
  EIN: CompTaxIdTypeEnum.Ein,
  ITIN: CompTaxIdTypeEnum.Itin,
  SSN: CompTaxIdTypeEnum.Ssn,
};

export type StringSchema = yup.StringSchema<string | null | undefined, AnyObject, string | null | undefined>;
