// eslint-disable-next-line no-restricted-imports
import { IndustriesApiInstance } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseIndustryTypesProps = UseCollectionProps<typeof IndustriesApiInstance.getIndustryTypes>;

export const useIndustryTypes = ({ enabled = true, ...props }: UseIndustryTypesProps) =>
  useCollection({
    ...props,
    enabled: enabled && !!props.params?.q,
    queryKey: 'IndustriesApi',
    queryFn: IndustriesApiInstance.getIndustryTypes,
  });

export type IndustryTypesCollection = ReturnType<typeof useIndustryTypes>;
