import { Box } from '@chakra-ui/react';
import { NewSinglePaymentStepLayout } from '@melio/ap-activities';
import { Layout } from '@melio/platform-ds';

import { BlockedInternationalPaymentsForStateContent } from './BlockedInternationalPaymentsForStateContent';
type BlockedInternationalPaymentsForStateProps = {
  onClose: () => void;
  blockReason: string;
};
export const BlockedInternationalPaymentsForState = ({
  onClose,
  blockReason,
}: BlockedInternationalPaymentsForStateProps) => {
  return (
    <Box height="100%" data-testid="blocked-international-payments-for-state">
      <Layout
        header={{
          content: (
            <NewSinglePaymentStepLayout.Header>
              <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
            </NewSinglePaymentStepLayout.Header>
          ),
          isSticky: true,
        }}
      >
        <BlockedInternationalPaymentsForStateContent blockReason={blockReason} />
      </Layout>
    </Box>
  );
};
