import { PaymnetReportParams, useMelioIntl, useReceivablePaymentsReport, useSystemMessage } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { PaymentActionsHandlers } from '../../utils';
import { useExportFileName, usePaymentsFiltering } from './hooks';
import { ExportReportModal, PaymentsTabScreen } from './screens';

export type PaymentsTabActivityProps = {
  onViewPayment: (id: string) => void;
  onCreateInvoice?: () => void;
  onError?: ARErrorFunction;
};

export const PaymentsTabActivity = forwardRef<PaymentsTabActivityProps>(
  ({ onError, onViewPayment, onCreateInvoice }, ref) => {
    const {
      payments,
      pagination,
      isFetching,
      isLoading,
      error,
      filters,
      searchTerm,
      setSearch,
      setParams,
      sortParams,
      clearFilterParams,
      clearAllParams,
    } = usePaymentsFiltering();

    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const filename = useExportFileName(filters.creationDateRange);
    const { triggerMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();

    const exportRequestParams: PaymnetReportParams = {
      searchTerm: searchTerm || undefined,
      receivablePaymentStatus: filters.receivablePaymentStatus,
      creationDateRange: filters.creationDateRange,
    };

    const { getReceivablePaymentsReport, isLoading: isLoadingReport } = useReceivablePaymentsReport();

    const paymentActions: PaymentActionsHandlers = {
      onViewPayment,
    };

    useEffect(() => {
      if (error) {
        onError?.(error);
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpenExportModal = () => {
      setIsExportModalOpen(true);
    };

    const handleCloseExportModal = () => {
      setIsExportModalOpen(false);
    };

    const handleExportReport = async () => {
      try {
        const data = await getReceivablePaymentsReport(exportRequestParams);

        if (data) {
          const blob = new Blob([data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = filename;
          link.style.visibility = 'hidden';

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } else {
          triggerMessage({
            title: formatMessage('ar.dashboard.activities.paymentsTable.exportReport.error.noData.title'),
            type: 'critical',
          });
        }
      } catch (error) {
        triggerMessage({
          title: formatMessage('ar.dashboard.activities.paymentsTable.exportReport.error.title'),
          type: 'critical',
        });

        onError?.(error as ARPlatformError);
      } finally {
        setIsExportModalOpen(false);
      }
    };

    return (
      <>
        <PaymentsTabScreen
          pagination={pagination}
          payments={payments}
          onSearch={setSearch}
          filters={filters}
          onParamsChange={setParams}
          onClearFilters={clearFilterParams}
          onClearAllParams={clearAllParams}
          searchTerm={searchTerm}
          tableActions={paymentActions}
          isLoading={isLoading}
          isFetching={isFetching}
          sortParams={sortParams}
          onCreateInvoice={onCreateInvoice}
          onExport={handleOpenExportModal}
          ref={ref}
          onError={onError}
        />
        <ExportReportModal
          isOpen={isExportModalOpen}
          onClose={handleCloseExportModal}
          onConfirm={handleExportReport}
          isExporting={isLoadingReport}
        />
      </>
    );
  }
);
