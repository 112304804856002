// eslint-disable-next-line no-restricted-imports
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ApiError, CollectionProps, CreateFn, UseCollectionApiResult } from './types';
import { createQueryKey } from './util';

export const useCollectionApi = <TQueryFnData, CData = never, UBatchData = never, TData = TQueryFnData>({
  queryKey: _queryKey,
  scope = 'default',
  ...options
}: CollectionProps<TQueryFnData, TData>): UseCollectionApiResult<TQueryFnData, CData, UBatchData, never, TData> => {
  const queryKey = createQueryKey({ queryKey: _queryKey, role: 'collection', scope });
  const queryClient = useQueryClient();

  const create: CreateFn<TQueryFnData, CData> = () => {
    throw new Error('create method not implemented');
  };

  const query = useQuery<TQueryFnData[], ApiError, TData[]>(queryKey, {
    ...options,
    onError: (error) => {
      const defaultOnError = queryClient.getDefaultOptions().queries?.onError as (
        error: unknown,
        callback?: (error: ApiError<string>) => void
      ) => void;

      if (defaultOnError) {
        defaultOnError(error, options.onError);
      }
    },
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
    queryKey,
    create,
    isMutating: queryClient.isMutating({ mutationKey: queryKey }) > 0,
    isFetching: queryClient.isFetching(queryKey) > 0,
    update: () => {
      throw new Error('update method not implemented');
    },
  };
};
