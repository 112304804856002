import { useCollectionApi } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountantsFirmClientApiClient } from '@melio/platform-api-axios-client';

type QueryFn = typeof AccountantsFirmClientApiClient.getAccountantFirmClientFundingSources;

export const useAccountingFirmClientFundingSources = (clientOrgId: string, options?: { enabled?: boolean }) =>
  useCollectionApi<QueryFn, never, never, never>({
    queryKey: ['AccountantsFirmClientApi', 'FirmClientFundingSources', clientOrgId],
    queryFn: () => AccountantsFirmClientApiClient.getAccountantFirmClientFundingSources(clientOrgId),
    enabled: options?.enabled ?? true,
  });
