import { ApiKeys, DeliveryMethodsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type Props = Pick<UseQueryOptions, 'enabled' | 'suspense'> & {
  deliveryMethodId?: string;
};

export const useGetDeliveryMethodDecryptedAccountNumberQuery = ({ deliveryMethodId, enabled, suspense }: Props) => {
  const query = useQuery({
    enabled: !!deliveryMethodId && enabled,
    queryKey: [ApiKeys.DeliveryMethodsApi, 'unmasked-account-number', deliveryMethodId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => DeliveryMethodsApiClient.getDeliveryMethodUnmaskedAccountNumber(deliveryMethodId!),
    select: (response) => response.data.data.accountNumber,
    suspense,
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
