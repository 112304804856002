import { formTextStyle, useBreakpointValue, useTheme } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { CardBrand, useConfig } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { keys, pickBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { CaratCardFormObject, CaratFieldsInputValidType, CaratInputValidType, CaratValidEventType } from './types';

type UseCaratFormProps = {
  setSelectedBrand: (value: CardBrand) => void;
  setCardErrorsCallback: (event: CaratInputValidType) => void;
};

export const useCaratForm = ({ setSelectedBrand, setCardErrorsCallback }: UseCaratFormProps) => {
  const [inputFormFocusPerField, setInputFormFocusPerField] = useState<{
    cardNumber?: boolean;
    expiration?: boolean;
    securityCode?: boolean;
  }>({});
  const loadCaratForm = useRef(false);
  const { services } = useConfig();
  const supportedCardProviders = pickBy<typeof services.carat.supportedCardProviders>(
    services.carat.supportedCardProviders
  ) as unknown as { [K in CardBrand]: boolean };

  const cardFieldsInputDataRef = useRef<CaratFieldsInputValidType>({
    cardNumber: {
      field: 'cardNumber',
      hasData: false,
      isPotentiallyValid: false,
      isValid: false,
      shouldShowError: false,
    },
    expiration: {
      field: 'expiration',
      hasData: false,
      isPotentiallyValid: false,
      isValid: false,
      shouldShowError: false,
    },
    securityCode: {
      field: 'securityCode',
      hasData: false,
      isPotentiallyValid: false,
      isValid: false,
      shouldShowError: false,
    },
  });
  const cardFromObjectRef = useRef<CaratCardFormObject | null>(null);
  const { colors, textStyles, fonts } = useTheme();
  const textStyle = textStyles[formTextStyle];
  const fontSize = useBreakpointValue(textStyle?.fontSize as never);
  const { formatMessage } = useMelioIntl();
  const [isLoading, loading] = useBoolean(true);

  useEffect(() => {
    const addingCaratForm = () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      window.fiserv.components
        .paymentFields({
          hooks: {
            onCardBrandChange: (cardBrand: CardBrand) => {
              setSelectedBrand(cardBrand);
            },
            onFocus: (fieldName: 'cardNumber' | 'securityCode' | 'expiration') => {
              setInputFormFocusPerField({ [fieldName]: true });
            },
            onLostFocus: (fieldName: 'cardNumber' | 'securityCode' | 'expiration') => {
              setInputFormFocusPerField({ [fieldName]: false });
            },
            onFieldValidityChange: (event: CaratInputValidType, fieldsData: CaratValidEventType) => {
              //we get more data from fieldsData
              cardFieldsInputDataRef.current.cardNumber = { ...fieldsData.fields.cardNumber, field: 'cardNumber' };
              cardFieldsInputDataRef.current.expiration = { ...fieldsData.fields.expiration, field: 'expiration' };
              cardFieldsInputDataRef.current.securityCode = {
                ...fieldsData.fields.securityCode,
                field: 'securityCode',
              };

              setCardErrorsCallback(event);
            },
          },
          data: {
            additionalFrameAncestors: ['cfc-demo.webflow.io'],
            supportedCardBrands: keys(supportedCardProviders),
            environment: 'CERT',
            paymentMethod: 'CREDIT_CARD',
            fields: {
              cardNumber: {
                parentElementId: 'carat-card-number',
                placeholder: formatMessage('widgets.tbtForm.cardNumber.placeholder'),
                dynamicPlaceholderCharacter: ' ',

                masking: {
                  mode: 'NO_MASKING',
                  shrunkLength: 4,
                },
              },
              expiration: {
                parentElementId: 'carat-expiration-date',
                placeholder: formatMessage('widgets.tbtForm.cardExpiration.placeholder'),
              },
              securityCode: {
                parentElementId: 'carat-security-code',
                placeholder: formatMessage('widgets.tbtForm.cardVerificationCode.placeholder'),
                masking: {
                  mode: 'ALWAYS_MASK_ALL',
                },
              },
            },
            contextualCssClassNames: {
              invalid: 'invalid',
              valid: 'valid',
            },
            css: {
              input: {
                'font-size': '16px',
                color: colors.global.neutral['1000'],
                background: 'white',
                width: '100%',
                border: 'none',
                outline: 'none',
                padding: '0',
                fontFamily: fonts['primary'],
                fontSize,
                'font-weight': textStyle?.fontWeight,
              },
              '.invalid': {
                color: colors.global.critical['700'],
              },
              'input::placeholder': {
                color: '#aaa',
              },
            },
          },
        })
        .then((formObject: CaratCardFormObject) => {
          cardFromObjectRef.current = formObject;
          loading.off();
        });
    };
    if (!loadCaratForm.current && !window.fiserv?.components) {
      loadCaratForm.current = true;
      const script = document.createElement('script');
      script.src = 'https://commercehub-secure-data-capture.fiservapps.com/3.1.9/checkout.js';
      script.async = true;
      script.onload = () => {
        addingCaratForm();
      };

      document.body.appendChild(script);
    } else {
      addingCaratForm();
    }
    // This hook should occur only once on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    inputFormFocusPerField,
    cardFieldsInputDataRef,
    cardFromObjectRef,
    isFormLoading: isLoading,
  };
};
