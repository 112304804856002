import {
  Button,
  CollapsibleCard,
  Divider,
  Form,
  FormField,
  NakedButton,
  Text,
  useBreakpoint,
  useFieldArray,
  useWatch,
} from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment, useEffect, useRef } from 'react';

import { getNewUboField, getUboId, NEW_UBO_ID_PREFIX } from '../hooks/useOwnershipDetailsForm';
import {
  FXFormSectionCardBaseProps,
  OwnershipDetails,
  OwnershipDetailsForm,
  Residency,
  UBO_FROM_ACCOUNT_HOLDER_ID,
} from '../types';
import { OwnerDetailsForm } from './OwnerDetailsForm';

export type OwnershipDetailsCardProps = FXFormSectionCardBaseProps<OwnershipDetailsForm> & {
  verifiedPersonasLength: number;
};

const MAX_ALLOWED_UBOS = 4;
export const FXOwnershipDetailsCard = ({
  form,
  isCompleted,
  onExpandChange,
  isExpanded,
  isDisabled,
  verifiedPersonasLength,
}: OwnershipDetailsCardProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const { fields, append, remove } = useFieldArray<OwnershipDetailsForm>({
    control: form.control,
    name: 'ubos',
  });
  const [enableNoIndividualsOwnerCheckbox] = useDevFeature<boolean>(
    FeatureFlags.UboFormEnableNoIndividualsOwnerCheckbox,
    false
  );
  const noIndividualsOwnerValue = useWatch({ control: form.control, name: 'noIndividualsOwner' });
  const ownershipPercentageRef = useRef<HTMLInputElement>(null);
  const accountHolderIsUbo = fields.find((x) => x.uboId === UBO_FROM_ACCOUNT_HOLDER_ID);

  useEffect(() => {
    if (isExpanded && accountHolderIsUbo && ownershipPercentageRef.current && fields.length === 1) {
      setTimeout(() => {
        ownershipPercentageRef.current?.focus();
      }, 0);
    }
  }, [isExpanded, accountHolderIsUbo, fields]);

  useAnalyticsView('Organization', isExpanded, true, {
    PageName: 'ownership-details',
    Intent: 'set-ownership-details',
    IsUboCollectBefore: verifiedPersonasLength > 0,
  });

  const onRemoveUbo = (index: number) => {
    remove(index);
  };

  const onAddAdditionalOwner = () => {
    append({
      uboId: getUboId(),
      ownerFirstName: '',
      ownerLastName: '',
      address: {
        line1: '',
        postalCode: '',
        state: '',
        city: '',
      },
      ownershipPercentage: '',
      dateOfBirth: undefined,
      residencyDetails: {
        usResidence: 'usResidence',
        taxId: '',
      } as Residency,
    });
  };

  const onClickSaveAndContinue = () => {
    track('Organization', 'Click', {
      Cta: 'save-and-continue',
      Intent: 'set-ownership-details',
      PageName: 'ownership-details',
      Flow: 'UBO',
      OwnerDetails: fields,
      NumberOfOwner: fields.length,
    });
    form.submitButtonProps.onClick();
  };

  const columns = isExtraSmallScreen ? 1 : 6;
  const getOnRemoveUboFunction = (felid: OwnershipDetails, index: number) => {
    if (fields.length > 1 && felid.uboId !== UBO_FROM_ACCOUNT_HOLDER_ID) {
      return () => onRemoveUbo(index);
    }
    return;
  };
  const onNoIndividualsOwnerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;
    form.setValue('noIndividualsOwner', value);
    if (value) {
      remove();
    } else {
      append(getNewUboField());
    }
  };

  return (
    <CollapsibleCard
      isDisabled={isDisabled || (!isExpanded && !isCompleted)}
      data-testid="fx-ownership-details-card"
      isExpanded={isExpanded}
      status={isCompleted ? 'success' : undefined}
      onExpandChange={onExpandChange}
      title={formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.title')}
      description={
        isExpanded
          ? formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.subtitle')
          : undefined
      }
    >
      <Form {...form.formProps} columns={columns}>
        {enableNoIndividualsOwnerCheckbox && (!accountHolderIsUbo || fields.length === 0) && (
          <FormField
            colSpan={columns}
            labelProps={{ label: '', isHidden: true }}
            render={() => (
              <Form.Checkbox
                value={noIndividualsOwnerValue}
                onChange={onNoIndividualsOwnerChange}
                label={formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.noIndividualsOwner.checkbox'
                )}
                {...form.registerField(`noIndividualsOwner`)}
                colSpan={3}
              />
            )}
          />
        )}
        {fields.map((f, i) => (
          <Fragment key={f.id}>
            <FormField
              data-testid={`fx-ownership-details-card-header-${i}`}
              colSpan={columns}
              labelProps={{ label: '', isHidden: true }}
              render={() => (
                <Text>
                  {formatMessage(
                    fields.length > 1
                      ? 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.card.multipleOwner.title'
                      : 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.card.title',
                    { index: i + 1 }
                  )}
                </Text>
              )}
            />
            {!noIndividualsOwnerValue && (
              <>
                <OwnerDetailsForm
                  form={form}
                  index={i}
                  ownershipPercentageRef={f.uboId === UBO_FROM_ACCOUNT_HOLDER_ID ? ownershipPercentageRef : undefined}
                  onRemove={getOnRemoveUboFunction(f, i)}
                  isViewMode={!f.uboId.startsWith(NEW_UBO_ID_PREFIX)}
                />
                {fields.length > 1 && i < fields.length - 1 && (
                  <FormField
                    size="small"
                    colSpan={columns}
                    labelProps={{ label: '', isHidden: true }}
                    render={() => <Divider />}
                  />
                )}
              </>
            )}
          </Fragment>
        ))}
        {fields.length < MAX_ALLOWED_UBOS && (
          <Form.ContentBox colSpan={columns}>
            <NakedButton
              label={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.addOwnerButton'
              )}
              onClick={onAddAdditionalOwner}
              variant="secondary"
              data-testid="add-additional-owner"
            />
          </Form.ContentBox>
        )}
      </Form>
      <Button
        label={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.submitButton.label'
        )}
        data-testid="save-and-continue-ubo"
        {...form.submitButtonProps}
        onClick={onClickSaveAndContinue}
      />
    </CollapsibleCard>
  );
};
