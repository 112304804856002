import { Icon, IconProps } from '@chakra-ui/react';

export const BannerIcon = (props: IconProps) => (
  <Icon width="96px" height="96px" viewBox="0 0 96 96" fill="currentColor" {...props}>
    <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.6995 71.8398C14.6038 66.0393 9.3102 56.405 9.3102 45.5016C9.3102 27.7745 23.3078 13.4028 40.5735 13.4028C48.5334 13.4028 55.7943 16.4592 61.3144 21.4823"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M54.4347 56.0788C63.3005 56.1053 68.0311 45.5209 64.0771 36.3583C62.5887 32.9099 61.2398 28.4456 61.984 24.5719C61.984 24.5719 66.5283 33.1525 72.2237 32.2687C74.7928 31.8701 77.2144 29.6263 78.4699 27.9851C81.1102 24.55 86.0018 27.794 83.4873 31.057C74.7574 42.3856 89.2702 61.5537 58.0263 72.1714"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M59.4424 78.3784C53.9093 81.7338 47.4573 83.6606 40.5653 83.6606C20.038 83.6606 3.40001 66.578 3.40001 45.5022C3.40001 24.4263 20.038 7.34369 40.5653 7.34369"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M29.4928 93.6011C30.3794 89.4285 35.4141 87.3687 40.5653 87.3687C45.7166 87.3687 51.1267 89.6012 51.6379 93.6011H29.4992H29.4928Z"
        fill="#212124"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path d="M50.3155 76.0448L51.9334 81.8387" stroke="#212124" strokeWidth="1.8" strokeLinejoin="round" />
      <path d="M31.4229 8.50983L33.0473 14.3037" stroke="#212124" strokeWidth="1.8" strokeLinejoin="round" />
      <path d="M40.5659 87.3692V83.6617" stroke="#212124" strokeWidth="1.8" strokeLinejoin="round" />
      <path
        d="M39.3567 77.5742C39.758 77.5875 40.1592 77.6008 40.5604 77.6008C46.9412 77.6008 52.8755 75.634 57.8261 72.2587L57.852 71.8401C54.5581 61.0298 51.7106 36.2264 48.1967 27.7948C44.4562 18.805 32.7947 15.7153 25.4885 23.9676C18.5835 31.7614 26.5999 51.8939 12.7531 59.8627"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M32.5279 68.9428C34.7735 67.8664 36.8508 68.2252 37.8862 71.1222C38.5334 72.9294 38.9799 74.0789 39.3488 76.5506C39.8017 79.607 35.0776 81.1418 34.2234 77.1619"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M26.9893 68.3179C29.5066 66.803 31.9334 66.8894 33.0789 70.0986C33.726 71.9058 34.1725 72.8626 34.5414 75.3343C34.9944 78.3907 30.2703 79.9256 29.416 75.9456"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M18.563 68.3501C21.8893 66.6624 25.1703 65.2937 26.9888 68.3168C28.8073 71.34 29.0079 71.752 29.4868 74.5492C30.0627 77.9644 24.8209 79.2003 24.0249 75.7053C23.546 73.5924 22.53 70.9214 22.53 70.9214"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M37.9599 26.3037C38.5449 29.8612 39.6673 31.9375 42.7563 31.685C48.042 31.2606 48.4864 37.8344 44.2302 38.5722C41.3651 39.0689 38.4309 39.6247 38.4309 39.6247"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <path
        d="M41.8795 26.4583L42.3301 29.0766"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.9793 27.9174L34.4288 30.5292"
        stroke="#212124"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.1284 23.1165C75.8307 23.5285 75.7142 23.5684 75.4877 23.575C75.313 23.575 75.1836 23.5019 75.1512 23.7013C75.1253 23.8607 75.6366 24.1863 75.9666 24.2793C77.0991 24.5982 77.8239 24.1796 78.141 23.7411C78.581 23.1298 78.581 22.399 77.5197 21.183L76.1284 23.1099V23.1165Z"
        fill="#7849FF"
      />
      <path
        d="M79.0206 15.5219C78.134 15.3425 77.7004 15.7611 77.4675 16.08C77.2216 16.4189 76.9627 17.0568 77.9011 18.1996L78.9365 16.7711C79.2342 16.3591 79.3571 16.2794 79.6225 16.2528C79.7972 16.2262 79.9007 16.2594 79.9331 16.1266C79.9848 15.9405 79.4283 15.6083 79.0206 15.5219Z"
        fill="#7849FF"
      />
      <path
        d="M94.4192 17.3427C93.7527 12.7182 91.3712 8.63858 87.7084 5.8546C84.0585 3.06397 79.5609 1.90786 75.0568 2.59223C70.5527 3.27659 66.5793 5.72171 63.8678 9.47577C60.6256 13.9673 59.6613 19.8343 61.3051 25.183C61.318 25.2494 61.3439 25.3092 61.3763 25.369C61.881 26.2727 66.4628 34.1794 72.4682 33.2426C75.0892 32.8306 77.6389 30.5782 79.1532 28.6048C80.0721 27.4088 81.0687 27.3557 81.58 27.4288C82.2854 27.5351 82.9002 28.0002 83.1396 28.6181C83.379 29.2427 83.2302 29.9271 82.7125 30.6048C81.5023 32.1728 80.6416 33.9867 80.0786 36.1395C80.0139 36.3854 80.0786 36.6511 80.2469 36.8372C80.3828 36.99 80.5769 37.0697 80.7711 37.0697C80.8164 37.0697 80.8681 37.0697 80.9134 37.0564C85.0551 36.1993 88.7244 33.814 91.2418 30.3257C93.9533 26.5717 95.0793 21.9538 94.4128 17.3294L94.4192 17.3427ZM82.719 20.7645L80.8875 19.3692C81.3017 18.7978 81.5412 17.7613 80.7063 16.7845L78.9267 19.2496C80.8099 21.1565 81.7353 22.9306 80.1627 25.1033C79.0367 26.6647 76.7976 27.1364 74.3579 25.5684L73.4066 26.8839L72.2676 26.0202L73.2189 24.7046C70.9863 22.6382 71.0963 20.0735 72.3712 18.3127L74.2479 19.7413C73.769 20.4655 73.2513 21.7877 74.4097 23.0568L76.5193 20.1333C74.4226 17.8809 74.3126 16.3527 75.4969 14.7115C76.5517 13.2498 78.6161 12.9242 80.7711 14.2464L81.7224 12.9308L82.8613 13.7946L81.91 15.1102C83.9679 16.9905 83.8126 19.1898 82.7254 20.7579L82.719 20.7645Z"
        fill="#7849FF"
      />
    </svg>
  </Icon>
);
