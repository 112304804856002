import { IconKey } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodByPayor, DeliveryMethodType, formatAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

const DELIVERY_METHOD_TYPES_ALLOWED_TO_ADD: DeliveryMethodType[] = [
  'bank-account',
  'paper-check',
  'domestic-wire-account',
];

export const allowAddNewDeliveryMethod = (deliveryMethods: DeliveryMethod[]) => {
  const vendorAlreadyHasAllowedBatchDms = DELIVERY_METHOD_TYPES_ALLOWED_TO_ADD.every((type) =>
    deliveryMethods.find((dm) => dm.type === type)
  );

  return !vendorAlreadyHasAllowedBatchDms;
};

export const useDeliveryMethodOptions = () => {
  const { formatMessage } = useMelioIntl();

  return {
    getOptionLabel: (deliveryMethod: DeliveryMethodByPayor) => {
      switch (deliveryMethod.type) {
        case 'bank-account':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.bank-account.option`,
            { last4digits: deliveryMethod.details.accountNumberLast4Digits }
          );
        case 'paper-check':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.paper-check.option`,
            { address: formatAddress(deliveryMethod.details.address) }
          );
        case 'domestic-wire-account':
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.domestic-wire-account.option`,
            { last4digits: deliveryMethod.details.accountNumber.slice(-4) }
          );
        default:
          return formatMessage(
            `activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.${deliveryMethod.type}.option`
          );
      }
    },

    getOptionToDisplayOnSelectLabel: ({ type }: DeliveryMethod) =>
      formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliveryMethodCell.types.${type}.selected`),

    getOptionIconKey: ({ type }: DeliveryMethodByPayor): IconKey => {
      switch (type) {
        case 'bank-account':
          return 'bank';
        case 'paper-check':
          return 'paper-check';
        case 'international-account':
          return 'international';
        case 'virtual-card':
          return 'single-use-card';
        case 'domestic-wire-account':
          return 'funds';
        default:
          return 'email';
      }
    },
  };
};
