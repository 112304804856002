import { DeliveryMethodCollection, DeliveryMethodType, Vendor } from '@melio/platform-api';
import { identity } from 'lodash';

export const getVendorNameForNotificationMessage = (vendor?: Pick<Vendor, 'accountNumber' | 'nickname' | 'name'>) => {
  if (!vendor) {
    return '';
  }

  const vendorDescription = [vendor?.nickname, vendor?.accountNumber].filter(identity).join(' | ');
  return vendorDescription ? `${vendor.name} (${vendorDescription})` : vendor.name;
};

export const findDeliveryMethodByType = (collection: DeliveryMethodCollection, type: DeliveryMethodType) =>
  collection.data?.find((deliveryMethod) => deliveryMethod.type === type);
