import { Container, ContainerProps, Group, IconButton, TextProps, useBreakpointValue } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle } from '@melio/platform-utils';

type TitleProps = Pick<TextProps, 'as' | 'textStyle' | 'children' | 'tabIndex'> &
  Pick<ContainerProps, 'textAlign' | 'paddingTop'> & {
    autoFocus?: boolean;
    onClose?: VoidFunction;
  };

export const Title = ({ children, autoFocus = true, onClose, ...props }: TitleProps) => {
  const headingTextStyle = useBreakpointValue({
    xs: 'heading2Semi',
    m: 'heading1Semi',
  });
  const textAlign = useBreakpointValue<ContainerProps['textAlign']>({ xs: 'start', s: 'center' });
  const { formatMessage } = useMelioIntl();

  const renderPageTitle = () => (
    <PageTitle
      autoFocus={autoFocus}
      data-component="NewSinglePaymentStepLayout.Title"
      data-testid="layout-title"
      textStyle={headingTextStyle}
    >
      {children}
    </PageTitle>
  );

  return (
    <Container textAlign={textAlign} {...props}>
      {onClose ? (
        <Group justifyContent="space-between" alignItems="center">
          {renderPageTitle()}
          <IconButton
            aria-label={formatMessage('app.payDashboard.form.actions.close.label')}
            icon="close"
            size="large"
            variant="primary"
            onClick={onClose}
            data-testid="layout-title-close-button"
          />
        </Group>
      ) : (
        renderPageTitle()
      )}
    </Container>
  );
};

Title.displayName = 'NewSinglePaymentStepLayout.Title';
