import { useAccount, useFundingSources } from '@melio/platform-api';
import { usePlan } from '@melio/subscriptions';

import { useAccountingFirmClientFundingSources } from '../api/entities';
import { SubscriptionBillingPayor } from '../types';
import { useFirmClient } from './useFirmClient';
import { useIsValidFirmClientSubscriptionBillingMethodType } from './useIsValidFirmClientSubscriptionBillingMethodType';
import { useSubscriptionFundingSource } from './useSubscriptionFundingSource';

export enum SubscriptionBillingOptionFlow {
  Create = 'create-payor',
  FirmToClient = 'update-payor-from-firm-to-client',
  ClientToFirm = 'update-payor-from-client-to-firm',
  AnonymousToClient = 'update-payor-from-anonymous-to-client',
}

export const useUpdateClientSubscriptionBillingOptions = (clientOrgId: string | undefined) => {
  const { data: client, isLoading: isClientLoading, error: clientError } = useFirmClient(clientOrgId);
  const { data: firmAccount, isLoading: isAccountLoading, error: accountError } = useAccount({ id: 'me' });
  const { fundingSourceId: firmSubscriptionBillingFundingSourceId } = useSubscriptionFundingSource();
  const {
    data: allFirmFundingSources = [],
    isLoading: isLoadingFirmFundingSources,
    error: firmFundingSourcesError,
  } = useFundingSources();
  const {
    data: allClientFundingSources = [],
    isLoading: isLoadingClientFundingSources,
    error: clientFundingSourcesError,
  } = useAccountingFirmClientFundingSources(clientOrgId ?? '', {
    enabled: !!clientOrgId,
  });
  const clientSubscription = client?.subscriptionInfo?.subscription;

  const {
    data: clientPlan,
    isFetching: isFetchingPlan,
    error: planError,
  } = usePlan({ id: clientSubscription?.planId ?? '', enabled: !!clientSubscription?.planId });

  const { isValidSubscriptionBillingMethod } = useIsValidFirmClientSubscriptionBillingMethodType();

  const clientAllowedFundingSources = allClientFundingSources.filter(isValidSubscriptionBillingMethod);
  const firmAllowedFundingSources = allFirmFundingSources.filter(isValidSubscriptionBillingMethod);

  const clientSubscriptionBillingFundingSourceId = clientSubscription?.fundingSourceId;

  const getCurrentClientSubscriptionPayor = () => {
    if (!clientSubscription?.payingOrganization) {
      return undefined;
    }
    if (clientSubscription.payingOrganization.organizationId === clientOrgId) {
      return SubscriptionBillingPayor.Client;
    }
    if (clientSubscription.payingOrganization.organizationId === firmAccount?.organizationId) {
      return SubscriptionBillingPayor.AccountingFirm;
    }
    return null; // Has anonymous payor
  };

  const currentPayor = getCurrentClientSubscriptionPayor();
  const suggestedClientFundingSource =
    allClientFundingSources.find((fs) => fs.id === clientSubscriptionBillingFundingSourceId) ??
    clientAllowedFundingSources.find((fs) => fs.isDefault) ??
    clientAllowedFundingSources[0];

  const suggestedFirmFundingSource =
    allFirmFundingSources.find((fs) => fs.id === firmSubscriptionBillingFundingSourceId) ??
    firmAllowedFundingSources.find((fs) => fs.isDefault) ??
    firmAllowedFundingSources[0];

  const isAnonymousPayor = currentPayor === null;

  const getSubscriptionBillingOptionFlow = () => {
    if (isAnonymousPayor) {
      return SubscriptionBillingOptionFlow.AnonymousToClient;
    }
    if (currentPayor === SubscriptionBillingPayor.Client) {
      return SubscriptionBillingOptionFlow.ClientToFirm;
    }
    if (currentPayor === SubscriptionBillingPayor.AccountingFirm) {
      return SubscriptionBillingOptionFlow.FirmToClient;
    }

    return SubscriptionBillingOptionFlow.Create;
  };

  return {
    data: {
      clientFundingSources: clientAllowedFundingSources,
      firmFundingSources: firmAllowedFundingSources,
      firmSubscriptionBillingFundingSourceId,
      clientSubscriptionBillingFundingSourceId,
      suggestedClientFundingSource,
      suggestedFirmFundingSource,
      currentPayor,
      isAnonymousPayor,
      clientSubscription,
      clientPlan,
      subscriptionBillingOptionFlow: getSubscriptionBillingOptionFlow(),
      clientName: client?.organization.name ?? '',
      firmName: firmAccount?.company.name ?? '',
    },
    isLoading:
      isClientLoading ||
      isAccountLoading ||
      isAccountLoading ||
      isLoadingClientFundingSources ||
      isLoadingFirmFundingSources ||
      isFetchingPlan,
    error:
      clientError || accountError || clientError || clientFundingSourcesError || firmFundingSourcesError || planError,
  };
};
