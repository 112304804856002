import { Card } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { SelectableItemList, SelectableList } from './SelectableList/SelectableList';

type Props = {
  onSelectClients: (selectedClients: SelectableList) => void;
  clientsList: SelectableList;
};

export const SelectClients = ({ onSelectClients, clientsList }: Props) => {
  const { formatMessage } = useMelioIntl();

  const selectAllLabel = useMemo(() => {
    if (clientsList.selectedCount === 0) {
      return formatMessage('activities.accountants.billingSettings.clientSelection.selectAll.noSelection.label');
    }

    return formatMessage('activities.accountants.billingSettings.clientSelection.selectAll.selected.label', {
      count: clientsList.selectedCount,
    });
  }, [clientsList.selectedCount, formatMessage]);

  return (
    <Layout maxWidth="480px">
      <Card width="full" paddingX="none" paddingY="m">
        <SelectableItemList list={clientsList} onSelect={onSelectClients} selectAllLabel={selectAllLabel} />
      </Card>
    </Layout>
  );
};
