/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PartnerName } from '@melio/platform-api';

import { BASE_PATH } from '@/api/consts';
import { usePartnerConfig } from '@/hooks/partners';

export const StartRoute = () => {
  const { partnerName } = usePartnerConfig();
  const { state } = useLocation<{ redirectUrl: string }>();

  useEffect(() => {
    if (partnerName === PartnerName.Melio) {
      window.location.href = `${BASE_PATH}/auth/providers/${partnerName}/start${
        state?.redirectUrl ? `?redirectUrl=${encodeURIComponent(state.redirectUrl)}` : ''
      }`;
    } else {
      window.location.href = `${BASE_PATH}/auth/providers/${partnerName}/start`;
    }
  }, []);

  return <></>;
};
