import { DeliveryPreferenceType, ServicesApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useQuery } from '@tanstack/react-query';

import { PostPaymentCalendarRequest } from './types';
import { preparePaymentCalendarRequest } from './utils';

export type UsePaymentCalendarProps = {
  payload: Pick<PostPaymentCalendarRequest, 'startDate' | 'endDate' | 'amount' | 'isFinancing' | 'currency'> & {
    fundingSourceId: string | undefined | null;
    vendorId: string | undefined;
    deliveryMethodId: string | undefined;
    deliveryPreference: DeliveryPreferenceType | undefined;
  };
  params?: {
    paymentSessionId?: string;
  };
};

export const usePaymentCalendar = ({ payload, params }: UsePaymentCalendarProps) => {
  const { fundingSourceId, vendorId, deliveryMethodId, deliveryPreference } = payload;

  const query = useQuery({
    enabled: Boolean(fundingSourceId && vendorId && deliveryMethodId && deliveryPreference),
    queryKey: ['ServicesApi', 'PaymentCalendar', payload],
    queryFn: async () => {
      if (fundingSourceId && vendorId && deliveryMethodId && deliveryPreference) {
        const result = await ServicesApiClient.postServicesPaymentCalendar(
          preparePaymentCalendarRequest({
            ...payload,
            fundingSourceId,
            vendorId,
            deliveryMethodId,
            deliveryPreference,
          }),
          undefined,
          undefined,
          params?.paymentSessionId
        );
        return result.data.data;
      }
      return;
    },
  });

  return {
    ...query,
    isLoading: query.isInitialLoading,
  };
};
