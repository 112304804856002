import { Box } from '@chakra-ui/react';
import { CaratFormWidget, PCIComplienceInfo, TBTFormWidgetFields } from '@melio/ap-widgets';
import { CaratCardFormObject } from '@melio/ap-widgets/src/components/carat/CaratForm/types';
import { Button, Group, LoadingContainer, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, SystemMessageDisplay, useBoolean } from '@melio/platform-utils';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { CaratLayout } from '../shared/CaratLayoutForm';

export type CaratCreateCardDetailsScreenProps = {
  onClose: VoidFunction;
  onDone: (formInputObject: CaratCardFormObject) => void;
  onError?: ErrorFunction;
  isSaving?: boolean;
  isLoading?: boolean;
};

export const CaratCreateCardDetailsScreen: React.VFC<CaratCreateCardDetailsScreenProps> = forwardRef<
  CaratCreateCardDetailsScreenProps,
  'div'
>(({ onClose, onDone, onError, isLoading: isParentLoading, isSaving, ...props }, ref) => {
  const [isLoading, loading] = useBoolean(true);
  useAnalyticsView('AddCCDetails', !isLoading);
  const { formatMessage } = useMelioIntl();
  const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<TBTFormWidgetFields>();
  const isMobile = useBreakpointValue({ xs: true, s: false });
  return (
    <LoadingContainer isLoading={isLoading || isParentLoading}>
      <CaratLayout
        data-component="CaratAddCardFundingSourceActivity.CaratCreateCardDetailsScreen"
        data-testid="carat-add-card-funding-source-activity-create-card-details-screen"
        ref={ref}
        {...props}
        title={formatMessage('activities.caratAddCardFundingSource.screens.createCardDetails.title')}
        onClose={onClose}
        footerContent={
          <>
            <Button
              data-component="CaratAddCardFundingSourceActivity.closeButton"
              data-testid="layout-close-button"
              tabIndex={0}
              variant="tertiary"
              label={formatMessage(`widgets.carat.form.button.cancel`)}
              onClick={onClose}
            />

            <Button
              {...submitButtonProps}
              label={formatMessage(`activities.caratAddCardFundingSource.screens.createCardDetails.continue`)}
            />
          </>
        }
      >
        <Group variant="vertical" spacing="l" data-testid="payment-flow-form-content">
          <SystemMessageDisplay data-testid="carat-form-details-failed-alert" />
          {!isMobile && (
            <NewSinglePaymentStepLayout.Title textAlign="start" onClose={onClose}>
              {formatMessage('activities.caratAddCardFundingSource.screens.createCardDetails.title')}
            </NewSinglePaymentStepLayout.Title>
          )}
          <NewSinglePaymentStepLayout.Content>
            <Box maxWidth="600px" width="100%">
              <CaratFormWidget
                onSubmit={onDone}
                onSubmissionStateChange={onSubmissionStateChange}
                onReady={loading.off}
                isSaving={isSaving}
              />
            </Box>
            <Box paddingY="m">
              <PCIComplienceInfo />
            </Box>
          </NewSinglePaymentStepLayout.Content>
        </Group>
      </CaratLayout>
    </LoadingContainer>
  );
});
CaratCreateCardDetailsScreen.displayName = 'CaratAddCardFundingSourceActivity.CaratCreateCardDetailsScreen';
