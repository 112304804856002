/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-lines */
import {
  NO_END_DATE,
  NUM_OF_OCCURRENCES_END_POLICY,
  RecurringBillProps,
  RecurringEndDateFields,
  RecurringNumOfOccurrencesFields,
} from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BillSubscription,
  BillSubscriptionApprovalDecisionStatusEnum,
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  Currency,
  DeliveryMethod,
  DeliveryMethodByPayor,
  FundingSource,
  PostBillSubscriptionsRequest,
  useBillSubscription,
  useDeliveryMethods,
  useFeeCatalog,
  useFiles,
  useFundingSources,
  useInvalidateCache,
  usePaymentSettings,
  useVendor,
} from '@melio/platform-api';
import { useMissingCompanyLegalInfoFields, useShouldCollectLegalInfoOnPaymentFlow } from '@melio/platform-kyc';
import { useConfig } from '@melio/platform-provider';
import { converDateToStringRepresentation, getCentsFromDollars } from '@melio/platform-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getDefaultPaymentPurposeFormFields } from '../../../utils/pay-flow/paymentPurpose';
import { useVendorUnMaskedAccountNumber } from '../../../utils/pay-flow/useVendorAccountNumber';
import { getDefaultFundingSource } from '../../BatchPayments/BatchPayments.activity.utils';
import { CompleteLegalInfoActivity } from '../../business-legal-info';
import { DeliveryMethodSelectionActivity } from '../../delivery-methods';
import { FundingSourceSelectionActivity } from '../../funding-sources';
import {
  InternationalPaymentPurposeActivity,
  PaymentPurposeUpdateData,
} from '../../international-payment/payment-purpose/PaymentPurpose.activity';
import { MemoToVendorActivity } from '../../MemoToVendor';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { RecurringPaymentScheduledActivity } from '../../RecurringPaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { RecurringReviewAndConfirmActivity } from '../RecurringReviewAndConfirm';
import { getDefaultMemo } from './RecurringPaymentFlow.activity.utils';
import { RecurringPaymentFlowActivityProps, RecurringPaymentFlowActivityStep } from './types';
import { useRecurringPaymentFlowActivityStep } from './useRecurringPaymentFlowActivityStep';

export const RecurringPaymentFlowActivity = withAnalyticsContext<RecurringPaymentFlowActivityProps>(
  ({ onBack: onFirstStepBack, onClose, onDone, actionToPerform = 'schedule', isLoading, billFormFields, flowUuid }) => {
    const { vendorId, billNumber, billAmount, startDate, endPolicy, paymentFrequency, note, invoice } = billFormFields;
    const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<FundingSource['id']>();
    const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState<DeliveryMethod['id']>();
    const [memoToVendor, setMemoToVendor] = useState<string | null>(null);
    const [billSubscription, setBillSubscription] = useState<BillSubscription | null>(null);
    const [isLoadingReviewAndConfirmButton, setIsLoadingReviewAndConfirmButton] = useState<boolean>(false);
    const [paymentPurposeData, setPaymentPurposeData] = useState<PaymentPurposeUpdateData | null>(null);
    const { isLoading: isUnmaskAccountNumberLoading, unmaskedAccountNumber } = useVendorUnMaskedAccountNumber({
      vendorId,
    });

    const { toast } = useToast();
    const { settings } = useConfig();

    const { data: vendor, isLoading: isLoadingVendor } = useVendor({ id: vendorId });
    const { data: fundingSources, isLoading: isLoadingFS } = useFundingSources();
    const {
      data: vendorDeliveryMethods,
      isLoading: isLoadingDM,
      confirm: confirmDeliveryMethod,
    } = useDeliveryMethods({ vendorId });
    const { create: createBillSubscription } = useBillSubscription({ enabled: false });
    const { create: uploadFile } = useFiles({ enabled: false });
    const { missingCompanyLegalInfoFields, isLoading: isMissingCompanyLegalInfoFieldsLoading } =
      useMissingCompanyLegalInfoFields() || {};
    const { isLoadingShouldCollectLegalInfoOnPaymentFlow, shouldCollectLegalInfoOnPaymentFlow } =
      useShouldCollectLegalInfoOnPaymentFlow({});
    const {
      data: [paymentSettings] = [],
      isFetching: isPaymentSettingsFetching,
      isLoading: isPaymentSettingsLoading,
      error: paymentSettingsError,
    } = usePaymentSettings({
      payload: [
        {
          amountToPay: Number(billAmount),
          vendorId,
          dueDate: converDateToStringRepresentation(startDate),
          isRecurring: true,
          fundingSourceId: selectedFundingSourceId,
          deliveryMethodId: selectedDeliveryMethodId,
          invoicesData: [{ invoiceNumber: billNumber, amount: Number(billAmount) }],
        },
      ],
      params: {
        fillWithDefaults: true,
      },
    });

    const defaultPaymentPurposeValues = useMemo(
      () => getDefaultPaymentPurposeFormFields(paymentPurposeData?.paymentPurpose),
      [paymentPurposeData?.paymentPurpose]
    );

    useInvalidateCache();

    // pre-fetch queries
    usePreFetchWithoutVendor();

    const { currentStep, getConditionalStepsCallback, goToStep, goToPreviousStep, calculateStepNumber } =
      useRecurringPaymentFlowActivityStep({
        onFirstStepBack,
        actionToPerform,
        selectedDeliveryMethod: vendorDeliveryMethods?.find((dm) => dm.id === paymentSettings?.deliveryMethodId),
        isDeliveryMethodSelectionStepRequired: !paymentSettings?.deliveryMethodId && !selectedDeliveryMethodId,
      });

    useAnalyticsContext({
      globalProperties: {
        VendorId: billFormFields.vendorId,
        Amount: Number(billFormFields.billAmount),
        PaymentFrequency:
          billFormFields.paymentFrequency &&
          billFormFields.paymentFrequency.charAt(0).toUpperCase() + billFormFields.paymentFrequency.slice(1),
        DueDate: billFormFields.startDate.toISOString(),
        Currency: Currency.Usd,
        Note: !!billFormFields?.note,
        Flow: 'recurring-payment',
        RecurringPaymentEndBy: billFormFields.endPolicy,
        RecurringPaymentEndValue:
          billFormFields.endPolicy === BillSubscriptionEndPolicyEnum.EndDate
            ? (billFormFields as RecurringEndDateFields).endDate?.toISOString()
            : (billFormFields as RecurringNumOfOccurrencesFields).numOfOccurrences,
        FlowUid: flowUuid,
        RecurringStartDate: billFormFields.startDate,
      },
    });

    const confirmRequiredDeliveryMethods = () => {
      if (vendorDeliveryMethods) {
        const dmRequireConfirmation = vendorDeliveryMethods.filter((dm) => dm.requireConfirmationForPayment);
        Promise.all(dmRequireConfirmation.map((dm) => confirmDeliveryMethod(dm.id)));
      }
    };

    const handleFail = useCallback(
      (error: PlatformError) => {
        toast({ type: 'error', title: error.message });
      },
      [toast]
    );

    const handlePaymentSettingsChange = useCallback(() => {
      const allowedSteps: RecurringPaymentFlowActivityStep[] = [
        'FUNDING_SOURCE_SELECTION',
        'DELIVERY_METHOD_SELECTION',
      ];

      // Once funding source is selected then we handle the payment settings change and next step
      if (selectedFundingSourceId && allowedSteps.includes(currentStep)) {
        const deliveryMethodId = paymentSettings?.deliveryMethodId ?? selectedDeliveryMethodId;
        const deliveryMethod = vendorDeliveryMethods?.find((dm) => dm.id === deliveryMethodId);
        const { hasPaymentPurposeStep, hasDeliveryMethodStep, hasMemoToVendorStep } = getConditionalStepsCallback({
          deliveryMethod,
        });

        goToStep(
          hasDeliveryMethodStep && (currentStep === 'FUNDING_SOURCE_SELECTION' || !deliveryMethodId)
            ? 'DELIVERY_METHOD_SELECTION'
            : hasPaymentPurposeStep
            ? 'PAYMENT_PURPOSE'
            : hasMemoToVendorStep
            ? 'MEMO_TO_VENDOR'
            : 'RECURRING_REVIEW_AND_CONFIRM'
        );
      }
    }, [
      selectedFundingSourceId,
      currentStep,
      paymentSettings,
      selectedDeliveryMethodId,
      vendorDeliveryMethods,
      getConditionalStepsCallback,
    ]);

    const handleSetFundingSource = useCallback(
      ({ fundingSourceId }: { fundingSourceId?: FundingSource['id'] }) => {
        setSelectedFundingSourceId(fundingSourceId);
        if (fundingSourceId === selectedFundingSourceId) {
          // Since we are not changing the funding source, we need to handle the payment settings change manually
          handlePaymentSettingsChange();
        }
      },
      [selectedFundingSourceId, setSelectedFundingSourceId, handlePaymentSettingsChange]
    );

    const handleSetDeliveryMethod = useCallback(
      ({ id: deliveryMethodId }: DeliveryMethod) => {
        setSelectedDeliveryMethodId(deliveryMethodId);

        if (deliveryMethodId === selectedDeliveryMethodId) {
          // Since we are not changing the delivery method, we need to handle the payment settings change manually
          handlePaymentSettingsChange();
        }
      },
      [selectedDeliveryMethodId, setSelectedDeliveryMethodId, handlePaymentSettingsChange]
    );

    const handleSetPaymentPurpose = useCallback(
      (paymentPurpose: PaymentPurposeUpdateData) => {
        setPaymentPurposeData(paymentPurpose);
        const { hasMemoToVendorStep } = getConditionalStepsCallback();
        goToStep(hasMemoToVendorStep ? 'MEMO_TO_VENDOR' : 'RECURRING_REVIEW_AND_CONFIRM');
      },
      [setPaymentPurposeData, goToStep]
    );

    const handleSetMemoToVendor = useCallback(
      (noteToVendor?: string) => {
        setMemoToVendor(noteToVendor || null);
        goToStep('RECURRING_REVIEW_AND_CONFIRM');
      },
      [setMemoToVendor, goToStep, selectedDeliveryMethodId]
    );

    const handleConfirmRecurringPayment = useCallback(async () => {
      if (currentStep == 'RECURRING_REVIEW_AND_CONFIRM' && shouldCollectLegalInfoOnPaymentFlow) {
        goToStep('COMPLETE_LEGAL_INFO');
      } else {
        const params = {
          vendorId,
          amount: Number(billAmount),
          currency: 'USD',
          note,
          invoice,
          invoiceNumber: billNumber,
          memoToVendor,
          fundingSourceId: paymentSettings?.fundingSourceId,
          deliveryMethodId: paymentSettings?.deliveryMethodId,
          intervalType: paymentFrequency.toLowerCase(),
          startDate,
          paymentPurpose: paymentPurposeData?.paymentPurpose || undefined,
          endPolicy,
          ...(endPolicy === NUM_OF_OCCURRENCES_END_POLICY
            ? { numOfOccurrences: (billFormFields as RecurringNumOfOccurrencesFields).numOfOccurrences }
            : { endDate: (billFormFields as RecurringEndDateFields).endDate }),
          ...(endPolicy !== NO_END_DATE && billFormFields.lastAmount
            ? { lastAmount: Number(billFormFields.lastAmount) }
            : {}),
        };
        setIsLoadingReviewAndConfirmButton(true);
        const uploadedFile = params.invoice ? await uploadFile(params.invoice) : undefined;
        const fileId = uploadedFile?.id || paymentPurposeData?.billInfo?.invoice.fileId;
        createBillSubscription({ ...(params as PostBillSubscriptionsRequest), fileId })
          .then((billSubscription: BillSubscription) => {
            setBillSubscription(billSubscription);
            if (settings.promoteAchDeliveryMethod) {
              confirmRequiredDeliveryMethods();
            }
            goToStep('RECURRING_PAYMENT_SCHEDULED');
          })
          .catch(handleFail)
          .finally(() => setIsLoadingReviewAndConfirmButton(false));
      }
    }, [
      vendorId,
      billAmount,
      note,
      billNumber,
      memoToVendor,
      paymentPurposeData,
      paymentSettings,
      paymentFrequency,
      startDate,
      endPolicy,
      billFormFields,
      setIsLoadingReviewAndConfirmButton,
      setBillSubscription,
      handleFail,
      goToStep,
      currentStep,
      missingCompanyLegalInfoFields,
      createBillSubscription,
      getCentsFromDollars,
    ]);

    useEffect(() => {
      if (!paymentSettings) {
        return;
      }
      handlePaymentSettingsChange();
    }, [paymentSettings]);

    useEffect(() => {
      if (paymentSettingsError) {
        handleFail(paymentSettingsError);
      }
    }, [paymentSettingsError]);

    if (
      isLoading ||
      isPaymentSettingsLoading ||
      isPaymentSettingsFetching ||
      isMissingCompanyLegalInfoFieldsLoading ||
      isLoadingVendor ||
      isLoadingFS ||
      isLoadingDM ||
      isLoadingReviewAndConfirmButton ||
      isLoadingShouldCollectLegalInfoOnPaymentFlow ||
      isUnmaskAccountNumberLoading
    ) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const totalSteps = calculateStepNumber('RECURRING_REVIEW_AND_CONFIRM');

    // The selected entities from payment settings
    const selectedFundingSource = fundingSources?.find((fs) => fs.id === paymentSettings?.fundingSourceId);
    const selectedDeliveryMethod = vendorDeliveryMethods?.find((dm) => dm.id === paymentSettings?.deliveryMethodId);

    const isPendingApproval =
      billSubscription?.approvalDecisionStatus === BillSubscriptionApprovalDecisionStatusEnum.Pending;

    switch (currentStep) {
      case 'FUNDING_SOURCE_SELECTION':
      default:
        return (
          <FundingSourceSelectionActivity
            step={calculateStepNumber('FUNDING_SOURCE_SELECTION')}
            totalSteps={totalSteps}
            vendorId={vendorId}
            selectedId={paymentSettings?.fundingSourceId || getDefaultFundingSource(fundingSources || [])?.id}
            paymentAmount={Number(billAmount) || 0}
            recurringPaymentDetails={{ paymentFrequency: paymentFrequency as BillSubscriptionIntervalTypeEnum }}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={handleFail}
            onDone={handleSetFundingSource}
            fundingSourceTypesOptions={paymentSettings?.fundingSourceTypesOptions}
          />
        );
      case 'DELIVERY_METHOD_SELECTION':
        return (
          <DeliveryMethodSelectionActivity
            step={calculateStepNumber('DELIVERY_METHOD_SELECTION')}
            totalSteps={totalSteps}
            vendorId={vendorId}
            selectedId={paymentSettings?.deliveryMethodId}
            fundingSourceId={selectedFundingSourceId || void 0}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={handleFail}
            onDone={handleSetDeliveryMethod}
            deliveryMethodTypeOptions={paymentSettings?.deliveryMethodTypeOptions}
          />
        );

      case 'PAYMENT_PURPOSE':
        return (
          <InternationalPaymentPurposeActivity
            step={calculateStepNumber('PAYMENT_PURPOSE')}
            totalSteps={totalSteps}
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={handleSetPaymentPurpose}
            defaultValues={defaultPaymentPurposeValues}
            vendorId={vendorId}
            deliveryMethod={selectedDeliveryMethod}
            hasInvoice={!!invoice}
            variation="screen"
          />
        );

      case 'MEMO_TO_VENDOR':
        return (
          <MemoToVendorActivity
            step={calculateStepNumber('MEMO_TO_VENDOR')}
            totalSteps={totalSteps}
            memo={memoToVendor || void 0}
            vendorId={vendorId}
            defaultMemo={getDefaultMemo(unmaskedAccountNumber, billNumber)}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={handleFail}
            onDone={handleSetMemoToVendor}
          />
        );

      case 'COMPLETE_LEGAL_INFO':
        return (
          <CompleteLegalInfoActivity
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={handleFail}
            onDone={handleConfirmRecurringPayment}
          />
        );

      case 'RECURRING_REVIEW_AND_CONFIRM':
        return (
          <RecurringReviewAndConfirmActivity
            step={calculateStepNumber('RECURRING_REVIEW_AND_CONFIRM')}
            totalSteps={totalSteps}
            recurringPayment={
              {
                paymentFrequency,
                ...(endPolicy === BillSubscriptionEndPolicyEnum.NumOfOccurrences
                  ? { numOfOccurrences: (billFormFields as RecurringNumOfOccurrencesFields).numOfOccurrences }
                  : { endDate: (billFormFields as RecurringEndDateFields).endDate }),
                ...(endPolicy !== NO_END_DATE && billFormFields.lastAmount
                  ? { lastAmount: Number(billFormFields.lastAmount) }
                  : {}),
              } as RecurringBillProps
            }
            amount={Number(billAmount)}
            memoToVendor={memoToVendor}
            invoiceNumber={billNumber}
            fundingSource={selectedFundingSource!}
            deliveryMethod={selectedDeliveryMethod as DeliveryMethodByPayor}
            startDate={startDate}
            vendor={vendor!}
            onEditFundingSource={
              isLoadingReviewAndConfirmButton ? undefined : () => goToStep('FUNDING_SOURCE_SELECTION')
            }
            onEditDeliveryMethod={
              isLoadingReviewAndConfirmButton ? undefined : () => goToStep('DELIVERY_METHOD_SELECTION')
            }
            onEditMemoToVendor={isLoadingReviewAndConfirmButton ? undefined : () => goToStep('MEMO_TO_VENDOR')}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={handleConfirmRecurringPayment}
            onError={handleFail}
            isLoadingButton={isLoadingReviewAndConfirmButton}
          />
        );

      case 'RECURRING_PAYMENT_SCHEDULED':
        return billSubscription ? (
          <RecurringPaymentScheduledActivity
            billSubscriptionId={billSubscription.id}
            onClose={onClose}
            onError={handleFail}
            onDone={(action: PaymentFlowDoneAction) => onDone(billSubscription, action, isPendingApproval)}
          />
        ) : null;
    }
  }
);

function usePreFetchWithoutVendor() {
  useFeeCatalog();
  useFundingSources();
}
