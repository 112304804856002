import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Illustration, Layout, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ActivationSuccessScreenProps = {
  onGoToDashboard?: VoidFunction;
  onCreateInvoice?: VoidFunction;
};

export const ActivationSuccessScreen = forwardRef<ActivationSuccessScreenProps>(
  ({ onCreateInvoice, onGoToDashboard, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Layout maxWidth="480px" {...props} ref={ref}>
        <Group variant="vertical" spacing="xxl" justifyContent="center" alignItems="center" width="full" height="full">
          <Group variant="vertical" spacing="m" alignItems="center" width="fit-content">
            <Illustration type="success" size="medium" />
            <Group variant="vertical" spacing="xs" alignItems="center">
              <Text textStyle="heading1Semi">
                <FormattedMessage id="ar.onboarding.activities.activationSuccess.header.text" />
              </Text>
              <Text textStyle="body2">
                <FormattedMessage id="ar.onboarding.activities.activationSuccess.description.text" />
              </Text>
            </Group>
          </Group>
          <Group variant="horizontal" spacing="m" justifyContent="space-between">
            <Button
              variant="secondary"
              onClick={onGoToDashboard}
              label={formatMessage('ar.onboarding.activities.businessDetails.buttons.goToDashboard.label')}
            />
            <Button
              variant="primary"
              onClick={onCreateInvoice}
              label={formatMessage('ar.onboarding.activities.businessDetails.buttons.createInvoice.label')}
            />
          </Group>
        </Group>
      </Layout>
    );
  }
);
