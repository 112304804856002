import { useFeedbackApi } from '@melio/platform-provider';
import { useEffect } from 'react';

import { PaymentScheduledSuccessfullyActivity } from './NewPaymentScheduled';
import { PaymentScheduledActivityProps } from './types';

export const PaymentScheduledActivity = (props: PaymentScheduledActivityProps) => {
  const { triggerPaymentSuccessFeedback } = useFeedbackApi();

  useEffect(() => {
    triggerPaymentSuccessFeedback();
  }, [triggerPaymentSuccessFeedback]);

  return <PaymentScheduledSuccessfullyActivity {...props} />;
};
