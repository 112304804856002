import { useAnalytics } from '@melio/platform-analytics';
import { useUserPreference } from '@melio/platform-api';
import { Logger } from '@melio/platform-logger';
import { usePartnerFeature } from '@melio/platform-provider';
import { useEffect, useState } from 'react';

import { AnalyticsProperties } from './types';

const BANNER_PREFERENCE_ID = 'dismissedPushFxForInternationalOrgsBanner';
const DISMISS_VALUE = 'true';

export const usePushFxForInternationalOrgsBanner = ({ analyticsProps }: { analyticsProps: AnalyticsProperties }) => {
  const [isPushFxForInternationalOrgsEnabled] = usePartnerFeature('PushFxForInternationalOrgs', false);
  const { create, data: { value: preference } = {} } = useUserPreference({
    id: BANNER_PREFERENCE_ID,
  });
  const [isSavingUserPreference, setIsSavingUserPreference] = useState(false);
  const wasBannerDismissed = preference === DISMISS_VALUE;
  const isEnabled = isPushFxForInternationalOrgsEnabled && !wasBannerDismissed && !isSavingUserPreference;

  const { track } = useAnalytics();

  const { viewContext, ...commonAnalytics } = analyticsProps;
  const bannerType = 'pay-vendors-in-fx';

  useEffect(() => {
    if (isEnabled) {
      track(viewContext, 'View', {
        ...commonAnalytics,
        BannerType: bannerType,
      });
    }
  }, [track, viewContext, commonAnalytics, isEnabled]);

  const handleCloseBanner = async () => {
    try {
      track(viewContext, 'Click', {
        ...commonAnalytics,
        BannerType: bannerType,
        Cta: 'exit',
      });
      setIsSavingUserPreference(true);
      await create({ userPreference: { key: BANNER_PREFERENCE_ID, value: DISMISS_VALUE } });
    } catch (e) {
      Logger.handleException(
        `Failed while trying to update userPreferences ${BANNER_PREFERENCE_ID} value to ${DISMISS_VALUE}: ${
          (e as Error).message
        }`
      );
    }
  };

  return {
    isEnabled,
    handleCloseBanner,
  };
};
