/* eslint-disable no-restricted-imports */
import { Container, Group } from '@melio/penny';
import {
  Todo,
  TodoActivateCollaboratorsTypeEnum,
  TodoFailedPaymentTypeEnum,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItemsTypeEnum,
  TodoPaymentsToApproveTypeEnum,
  todosOrder,
} from '@melio/platform-api';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';

import { TodoSectionActivateCollaborators } from './section-types/TodoSectionActivateCollaborators';
import { TodoSectionFailedPayments } from './section-types/TodoSectionFailedPayments';
import { TodoSectionOverdueInboxItems } from './section-types/TodoSectionOverdueInboxItems';
import { TodoSectionOverdueInFutureInboxItems } from './section-types/TodoSectionOverdueInFutureInboxItems';
import { TodoSectionPaymentsToApprove } from './section-types/TodoSectionPaymentsToApprove';
import { useTypedTodos } from './useTypedTodos';

type TodosDrawerBodyProps = {
  todos: Todo[];
  client: AccountingFirmClientExpanded;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodosContent = ({ client, todos, ...callbacks }: TodosDrawerBodyProps) => {
  const {
    failedPaymentTodo,
    paymentsToApprove,
    overdueInboxItems,
    overdueIn7Days,
    overdueInMoreThan7Days,
    activateCollaborator,
  } = useTypedTodos(todos);

  const renderTodosSection = () => {
    if (!todos) {
      return null;
    }
    let isOverdueInFutureInboxItemsHandled = false;
    return todosOrder
      .map((type) => {
        switch (type) {
          case TodoFailedPaymentTypeEnum.FailedPayments:
            return (
              <TodoSectionFailedPayments
                todo={failedPaymentTodo}
                clientOrganizationId={client.organizationInfo.id}
                key={TodoFailedPaymentTypeEnum.FailedPayments}
                {...callbacks}
              />
            );
          case TodoPaymentsToApproveTypeEnum.PaymentsToApprove:
            return (
              <TodoSectionPaymentsToApprove
                todo={paymentsToApprove}
                clientOrganizationId={client.organizationInfo.id}
                key={TodoPaymentsToApproveTypeEnum.PaymentsToApprove}
                {...callbacks}
              />
            );
          case TodoOverdueInboxItemsTypeEnum.OverdueInboxItems:
            return (
              <TodoSectionOverdueInboxItems
                todo={overdueInboxItems}
                clientOrganizationId={client.organizationInfo.id}
                key={TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}
                {...callbacks}
              />
            );
          case TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems:
          case TodoOverdueInMoreThan7DaysInboxItemsTypeEnum.OverdueInMoreThan7DaysInboxItems: {
            if (isOverdueInFutureInboxItemsHandled) {
              return null;
            }
            isOverdueInFutureInboxItemsHandled = true;
            return (
              <TodoSectionOverdueInFutureInboxItems
                overdueIn7Days={overdueIn7Days}
                overdueInMoreThan7Days={overdueInMoreThan7Days}
                clientOrganizationId={client.organizationInfo.id}
                key={TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}
                {...callbacks}
              />
            );
          }
          case TodoActivateCollaboratorsTypeEnum.ActivateCollaborators:
            return (
              <TodoSectionActivateCollaborators
                todo={activateCollaborator}
                clientOrganizationId={client.organizationInfo.id}
                key={TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}
                {...callbacks}
              />
            );
          default:
            return null;
        }
      })
      .filter((section) => !!section);
  };

  return (
    <Container paddingTop="s">
      <Group variant="vertical" spacing="s">
        {renderTodosSection()}
      </Group>
    </Container>
  );
};
