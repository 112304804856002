import { FormWidgetProps, SharePaymentLink, useMelioIntl } from '@melio/ar-domain';
import {
  Container,
  Form,
  FormField,
  Group,
  NakedButton,
  RadioGroup,
  TextArea,
  TextField,
  useMelioForm,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect } from 'react';
import { boolean, object, SchemaOf, string } from 'yup';

import { IssueInvoiceFormFields, SendEmailOptions } from '../types';

export type IssueInvoiceFormProps = FormWidgetProps<IssueInvoiceFormFields> & {
  error?: ARPlatformError;
  onSelectSendEmail: (isSendInvoice: boolean) => void;
  onCopyPaymentLink?: () => Promise<void>;
  paymentRequestLink?: string;
};

const useSchema = (): SchemaOf<IssueInvoiceFormFields> => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    email: string()
      .nullable()
      .email(formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.validation.text')),
    emailMessage: string()
      .optional()
      .transform((value?: string | null) => (!value ? undefined : value)),
    shouldSendEmail: string()
      .oneOf(Object.values(SendEmailOptions))
      .default(SendEmailOptions.Yes)
      .required(formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.required.text')),
    isAutoRemindersEnabled: boolean(),
  }) as SchemaOf<IssueInvoiceFormFields>;
};

export const IssueInvoiceForm = forwardRef<IssueInvoiceFormProps, 'form'>(
  (
    { onSubmit, onSubmissionStateChange, onSelectSendEmail, onCopyPaymentLink, paymentRequestLink, defaultValues },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { registerField, watch, formProps } = useMelioForm({
      defaultValues: {
        shouldSendEmail: SendEmailOptions.Yes,
        isAutoRemindersEnabled: true,
        ...defaultValues,
      },
      onSubmit,
      schema: useSchema(),
      onSubmissionStateChange,
      subscribeToDefaultValuesChanges: true,
    });
    const [isCustomizedEmailMessageExpanded, expandCustomizedEmailMessage] = useBoolean(false);
    const { track } = useAnalytics();

    const shouldSendEmail = watch('shouldSendEmail') === 'yes';

    useEffect(() => {
      onSelectSendEmail(shouldSendEmail);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSendEmail]);

    return (
      <Form {...formProps} ref={ref}>
        <FormField
          labelProps={{ isHidden: true, label: '' }}
          data-testid="form-field-shouldSendEmail"
          {...registerField('shouldSendEmail')}
          render={(formFieldProps) => (
            <RadioGroup
              {...formFieldProps}
              options={[
                {
                  mainLabelProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.label.text'
                    ),
                  },
                  value: SendEmailOptions.Yes,
                  descriptionProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.description.text'
                    ),
                  },
                },
              ]}
              aria-label={formatMessage(
                'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.yes.aria-label.text'
              )}
            />
          )}
        />
        {shouldSendEmail && (
          <Container paddingLeft="l" data-testid="send-email-info-container">
            <Group spacing="m" variant="vertical">
              <FormField
                {...registerField('email')}
                data-testid="form-field-email"
                labelProps={{
                  label: formatMessage('ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.label.text'),
                }}
                render={(formFieldProps) => (
                  <TextField
                    {...formFieldProps}
                    isReadOnly={!!defaultValues?.email || formFieldProps.isReadOnly}
                    placeholder={formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.email.placeholder.text'
                    )}
                  />
                )}
              />
              {!isCustomizedEmailMessageExpanded && (
                <NakedButton
                  data-testid="customize-email-button"
                  onClick={() => {
                    track('Invoice', 'Click', {
                      Intent: 'edit-customer-email',
                      Cta: 'customize-the-email',
                    });
                    expandCustomizedEmailMessage.on();
                  }}
                  label={formatMessage(
                    'ar.invoiceLifecycle.activities.issueInvoice.form.buttons.customizeEmailMessage.label'
                  )}
                  variant="secondary"
                />
              )}
              <FormField
                {...registerField('emailMessage')}
                data-testid="form-field-emailMessage"
                hidden={!isCustomizedEmailMessageExpanded}
                labelProps={{
                  label: formatMessage(
                    'ar.invoiceLifecycle.activities.issueInvoice.form.fields.emailMessage.label.text'
                  ),
                }}
                data-private
                maxChars={250}
                render={(formFieldProps) => (
                  <TextArea
                    {...formFieldProps}
                    numberOfRows={3}
                    placeholder={formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.emailMessage.placeholder.text'
                    )}
                  />
                )}
              />
            </Group>
          </Container>
        )}
        <FormField
          {...registerField('shouldSendEmail')}
          data-testid="form-field-shouldSendEmail"
          labelProps={{ isHidden: true, label: '' }}
          render={(formFieldProps) => (
            <RadioGroup
              {...formFieldProps}
              options={[
                {
                  mainLabelProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.label.text'
                    ),
                  },
                  descriptionProps: {
                    label: formatMessage(
                      'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.description.text'
                    ),
                  },
                  value: SendEmailOptions.No,
                },
              ]}
              aria-label={formatMessage(
                'ar.invoiceLifecycle.activities.issueInvoice.form.fields.shouldSendEmail.no.aria-label.text'
              )}
            />
          )}
        />
        {!shouldSendEmail && paymentRequestLink && (
          <SharePaymentLink link={paymentRequestLink} onCopyPaymentLink={onCopyPaymentLink}></SharePaymentLink>
        )}
      </Form>
    );
  }
);
IssueInvoiceForm.displayName = 'IssueInvoiceForm';
