import { useSendMessage } from '@melio/partner-bridge';
import { getReturnUrl } from '@melio/partner-navigation-manager';
import { FundingSourceType } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';

import {
  APActivitiesOutgoingMessagePayload,
  APActivitiesOutgoingMessageTypes,
} from '../../../../../../../utils/partner-messages';
import { FundingSourceSectionProps } from '../FundingSourceSection';

type Args = {
  selectedType: FundingSourceType;
  onClick: VoidFunction;
};

export function useAddFundingSourceButtonProps({
  selectedType,
  onClick,
}: Args): FundingSourceSectionProps['selector']['addButton'] {
  const {
    settings: { fundingSourcePolicy },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const sendMessage = useSendMessage();

  const fsTypePolicy = fundingSourcePolicy?.[selectedType];
  const defaults: FundingSourceSectionProps['selector']['addButton'] = {
    variant: 'secondary',
    label: formatMessage(`activities.paymentFlow.form.content.fundingSourceCard.${selectedType}.add.label`),
    onClick,
    'data-testid': `add-funding-source-button`,
  };

  if (!fsTypePolicy?.write) {
    return;
  }

  if (fsTypePolicy?.creationHosting === 'app') {
    return defaults;
  }

  if (fsTypePolicy?.creationHosting === 'iframe') {
    return {
      ...defaults,
      onClick: () =>
        sendMessage<APActivitiesOutgoingMessagePayload['ADD_FUNDING_SOURCE_CLICK']>(
          APActivitiesOutgoingMessageTypes.ADD_FUNDING_SOURCE_CLICK,
          {
            data: {
              fundingSourceType: selectedType,
              returnUrl: getReturnUrl(),
            },
          }
        ),
    };
  }

  return defaults;
}
