import { useListenToEvent } from '@melio/partner-bridge';
import { useConfig } from '@melio/platform-provider';
import { debounce } from 'lodash';
import { useCallback, useRef } from 'react';

import {
  PlatformAppSDKIncomingMessagePayload as MessagePayload,
  PlatformAppSDKIncomingMessageTypes as MessageTypes,
} from '../../partner-messages';
import { useViewport, ViewportState } from '../../viewport/Viewport';

function positionElement(
  elemRect: DOMRect,
  containerData: {
    rect: ViewportState['rect'];
    scrollY: ViewportState['scrollY'];
    viewportHeight: ViewportState['viewportHeight'];
  },
  stickyPosition: 'top' | 'bottom'
) {
  if (stickyPosition === 'bottom') {
    const shouldFollowScroll = elemRect?.bottom + containerData.rect.top > containerData.viewportHeight;
    const nextPosition = containerData.viewportHeight - (elemRect?.bottom + containerData.rect.top);
    return shouldFollowScroll ? nextPosition : 0;
  } else if (stickyPosition === 'top') {
    const shouldFollowScroll = elemRect?.top + containerData.rect.top <= 0;
    const nextPosition = Math.abs(containerData.rect.top) - elemRect?.top - 10;
    return shouldFollowScroll ? nextPosition : 0;
  }

  return 0;
}

export function useSticky({ position }: { position: 'top' | 'bottom' }) {
  //This is not a hack, but a bypass to a bug in eslint-plugin-react-hooks that doesnt recognize the dependencies of debounced function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const restore = useCallback(
    debounce((el: HTMLElement) => {
      el.style.opacity = '1';
    }, 100),
    []
  );
  const {
    settings: { embeddedExperience },
  } = useConfig();
  const elementRef = useRef<HTMLElement | null>();
  const initialElementBoundingRect = useRef<DOMRect | null>();
  const rafId = useRef<number | null>(null);
  const isControlled = embeddedExperience?.enabled && embeddedExperience.singleScrollOwnedByHost;

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { getDocumentBoundingClientRect, getScrollY, getViewportHeight } = useViewport();
  useListenToEvent<MessagePayload['USER_SCROLL']>(MessageTypes.USER_SCROLL, (_, data) => {
    if (isControlled && elementRef.current && initialElementBoundingRect.current) {
      const rect = getDocumentBoundingClientRect(data);
      rafId.current && cancelAnimationFrame(rafId.current);
      const offset = positionElement(
        initialElementBoundingRect.current,
        {
          rect,
          scrollY: data.scrollY,
          viewportHeight: getViewportHeight(),
        },
        position
      );
      if (offset) {
        rafId.current = requestAnimationFrame(() => {
          if (elementRef.current) {
            elementRef.current.style.opacity = '0';
            elementRef.current.style.transform = `translateY(${offset}px)`;

            restore(elementRef.current);
          }
        });
      } else {
        elementRef.current.style.transform = `translateY(${0}px)`;
        elementRef.current.style.opacity = '1';
      }
      restore.cancel();
    }
  });

  useListenToEvent<MessagePayload['DIMENSIONS_CHANGED']>(MessageTypes.DIMENSIONS_CHANGED, (_, data) => {
    if (isControlled && initialElementBoundingRect.current && elementRef.current) {
      const rect = getDocumentBoundingClientRect(undefined, data);
      elementRef.current.style.transform = `translateY(0px)`;
      initialElementBoundingRect.current = elementRef.current.getBoundingClientRect();

      const offset = positionElement(
        initialElementBoundingRect.current,
        {
          rect,
          scrollY: getScrollY(),
          viewportHeight: getViewportHeight(data),
        },
        position
      );
      elementRef.current.style.transform = `translateY(${offset}px)`;
    }
  });

  const onChildMount = useCallback(
    (elemRef: HTMLElement | null) => {
      if (isControlled && elemRef) {
        elementRef.current = elemRef;
        initialElementBoundingRect.current = elemRef.getBoundingClientRect();
        const offset = positionElement(
          initialElementBoundingRect.current,
          {
            rect: getDocumentBoundingClientRect(),
            scrollY: getScrollY(),
            viewportHeight: getViewportHeight(),
          },
          position
        );
        elemRef.style.transition = 'opacity 0.2s';

        elemRef.style.transform = `translateY(${offset}px)`;
      } else {
        initialElementBoundingRect.current = null;
        elementRef.current = null;
      }
    },
    [getDocumentBoundingClientRect, getScrollY, getViewportHeight, position, isControlled]
  );

  return { ref: onChildMount, isControlled };
}
