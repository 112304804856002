import { CountryData, Currency, getTransferRequiredFields } from '@melio/international-payments-utils';
import { Money } from '@melio/money';
import { getFxProhibitedNaicsCodes } from '@melio/naics-code-utils';
import {
  Bill,
  BillInfo,
  CountryInternationalDeliveryDetails,
  DeliveryMethod,
  DeliveryMethodType,
  ExchangeRateBase,
  Industry,
  InternationalAccountBicSwiftIdentifierTypeEnum,
  InternationalAccountIbanIdentifierTypeEnum,
  InternationalAccountIdentifierTypeEnum,
  OrganizationBusinessType,
  Payment,
  PaymentIntent,
} from '@melio/platform-api';

export const FX_ALLOWED_BUSINESS_TYPES: OrganizationBusinessType[] = [
  OrganizationBusinessType.SoleProprietorship,
  OrganizationBusinessType.LimitedLiabilityCompany,
  OrganizationBusinessType.Corporation,
  OrganizationBusinessType.MunicipalCorporation,
  OrganizationBusinessType.TrustOrEstate,
];

const NAICS_CODE_LENGTH = 6;
const INVALID_CODES = [999999, 454111];

export const isIndustryShouldPrefilled = (industry: Industry) =>
  industry.naicsCode?.toString().length === NAICS_CODE_LENGTH && !INVALID_CODES.includes(industry.naicsCode);

export const isNaicsCodeAllowedForFX = (naicsCode: number) => !getFxProhibitedNaicsCodes().includes(naicsCode);

export const isBusinessTypeAllowedForFX = (businessType?: OrganizationBusinessType | null) =>
  businessType ? FX_ALLOWED_BUSINESS_TYPES.includes(businessType) : true;

export const DEFAULT_CURRENCY = 'USD';

export const convertFxAmountToUsdAmount = ({
  paymentAmount,
  currency,
  fxRate,
}: {
  paymentAmount: number;
  currency?: string;
  fxRate: ExchangeRateBase;
}) => {
  const amountInUsd = Money.fromBaseUnit(paymentAmount, currency ?? DEFAULT_CURRENCY).convert(
    DEFAULT_CURRENCY,
    fxRate.usdToForeignRate
  );
  return amountInUsd.amount.toNumber();
};

export const getInternationalDmDetails = (deliveryMethod?: DeliveryMethod | null) => {
  if (deliveryMethod?.type === 'international-account') {
    return {
      identifierType: deliveryMethod?.details.identifierType,
      internationalCountryCode: deliveryMethod?.details.identifierDetails.bankCountryCode,
      currency: deliveryMethod.details.currency,
    };
  }
  return { identifierType: undefined, internationalCountryCode: undefined, currency: undefined };
};
export const isIdentifierTypeInternational = (identifierType: InternationalIdentifierType) => identifierType === 'v2';

export const isFXCurrency = (currency?: string | null) => !!(currency && currency !== DEFAULT_CURRENCY);

export const isFxBill = (bill?: Bill | BillInfo) => isFXCurrency(bill?.currency);

export const isFxPayment = (payment: Payment) => isFXCurrency(payment.currency);

export const isFxPaymentIntent = (paymentIntent: PaymentIntent) => isFxBill(paymentIntent.billInfo);

export const getDMCurrency = (deliveryMethod?: DeliveryMethod | null) =>
  deliveryMethod?.type === DeliveryMethodType.InternationalAccount ? deliveryMethod.details.currency : DEFAULT_CURRENCY;

export const isFxDM = (deliveryMethod: DeliveryMethod) => isFXCurrency(getDMCurrency(deliveryMethod));

export const fxMemoRegex = /^[A-Za-z0-9\-./?:(), +]*$/;

export const isTransferRequireForCurrency = (currency: Currency) =>
  getTransferRequiredFields(currency).alternatives.length > 0;

export const checkCountryRiskLevel = ({
  country,
  paymentsData,
  hasInvoice,
  vendorId,
}: {
  country: CountryData | CountryInternationalDeliveryDetails | undefined;
  paymentsData: Payment[] | undefined;
  hasInvoice: boolean;
  vendorId?: string;
}) => {
  if (!country || hasInvoice) {
    return false;
  }

  if (country.risk === 'high') {
    return true;
  }

  if (country.risk === 'medium') {
    return !paymentsData?.some((payment) => payment.vendorId === vendorId);
  }

  return false;
};

export type InternationalIdentifierType =
  | InternationalAccountIdentifierTypeEnum
  | InternationalAccountBicSwiftIdentifierTypeEnum
  | InternationalAccountIbanIdentifierTypeEnum;
