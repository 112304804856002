import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { usePendingSubscription } from './usePendingSubscription';
import { usePlansTiers } from './usePlansTiers';
import { useSubscription } from './useSubscription';

export const useAllowRemoveSubscriptionFundingSource = ({ fundingSourceId }: { fundingSourceId: string }) => {
  const subscription = useSubscription();
  const [allowRemoveFreeSubscriptionFundingSource] = useDevFeature<boolean>(
    FeatureFlags.AllowRemoveFreeSubscriptionFundingSource,
    false
  );
  const pendingSubscription = usePendingSubscription();
  const { getIsFreePlan } = usePlansTiers();

  if (!subscription || subscription.fundingSourceId !== fundingSourceId) {
    return true;
  }

  const isFreePlan = getIsFreePlan(subscription.planId);

  return allowRemoveFreeSubscriptionFundingSource && (isFreePlan || pendingSubscription.isPendingPlanFree);
};
