/* eslint-disable no-restricted-imports */
import { BrandSymbol } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api-axios-client';
import { PropsWithChildren } from 'react';

import { CompanyAvatar } from '../../../v1/components/CompanyAvatar';

export const ClientLogo = ({
  accountingPlatformSlug,
  client: { name, logoUrl, isHidden },
}: PropsWithChildren<{
  client: {
    logoUrl?: string;
    name: string | null;
    isHidden?: boolean;
  };
  accountingPlatformSlug?: AccountingPlatformSlug;
}>) => {
  const brandSymbolType = accountingPlatformSlug && accountingSoftwareSlugToBrandSymbolType[accountingPlatformSlug];

  return (
    <CompanyAvatar
      badge={brandSymbolType ? <BrandSymbol size="small" type={brandSymbolType} /> : undefined}
      size="medium"
      src={logoUrl}
      name={name || ''}
      isDisabled={isHidden}
    />
  );
};

export const accountingSoftwareSlugToBrandSymbolType: Record<
  AccountingPlatformSlug,
  Parameters<typeof BrandSymbol>[0]['type'] | undefined
> = {
  ['xero']: undefined,
  ['quickBooksOnline']: 'quickbooks',
  ['quickBooksDesktop']: 'quickbooks',
  ['quickBooksDesktopInApp']: 'quickbooks',
  ['dynamicsBusinessCentral']: undefined,
  ['freshBooks']: undefined,
};
