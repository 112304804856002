import {
  getFastDeliveryPreference,
  isDeliveryPreferenceTypeFast,
  usePaymentSchedulingPreference,
  useRelativeDateText,
} from '@melio/ap-domain';
import { Group, Switch, Text } from '@melio/penny';
import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';

import { getRelatedSlowOption, useFastOptionLabel } from '../DeliverySpeedCell.utils';

type FastToggleProps = {
  deliveryPreferenceOptions: PaymentIntent['deliveryPreferenceOptions'];
  selectedDeliveryPreferenceType: PaymentIntent['selectedDeliveryPreferenceType'];
  onSelectDeliveryPreferenceType?: (type: DeliveryPreference['type']) => void;
};
export const FastSwitch = ({
  deliveryPreferenceOptions,
  selectedDeliveryPreferenceType,
  onSelectDeliveryPreferenceType,
}: FastToggleProps) => {
  const { formatRelativeDate } = useRelativeDateText();
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const fastOptionLabel = useFastOptionLabel(deliveryPreferenceOptions);

  const { type: availableFastType, earliestDeliveryDate: availableEarliestDeliveryDate } =
    (deliveryPreferenceOptions && getFastDeliveryPreference(deliveryPreferenceOptions)) || {};

  const isFast = selectedDeliveryPreferenceType ? isDeliveryPreferenceTypeFast(selectedDeliveryPreferenceType) : false;
  const fastDate = availableEarliestDeliveryDate ? formatRelativeDate({ date: availableEarliestDeliveryDate }) : null;

  const showFastDatePreview = isByDeliveryDate && !isFast && fastDate;

  const handleChange = (isChecked: boolean) => {
    if (availableFastType) {
      const selectedType = isChecked ? availableFastType : getRelatedSlowOption(availableFastType);
      onSelectDeliveryPreferenceType?.(selectedType);
    }
  };

  return (
    <Group width="full" justifyContent="space-between">
      <Group spacing="xxs" variant="vertical">
        <Text textStyle="body3" color="inherit" shouldSupportEllipsis>
          {fastOptionLabel}
        </Text>
        {showFastDatePreview ? (
          <Text
            textStyle="body4"
            color="global.neutral.900"
            shouldSupportEllipsis
            data-testid="delivery-speed-cell-fast-date-preview"
          >
            {fastDate}
          </Text>
        ) : null}
      </Group>
      <Switch
        size="small"
        data-testid={`delivery-speed-switch-${availableFastType ?? ''}`}
        defaultIsChecked={isFast}
        onChange={handleChange}
      />
    </Group>
  );
};
