import { useCustomers, useMelioIntl, useSystemMessage } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { ImportCustomersFormFields, ImportCustomersScreen } from './screens';
import { onDownloadCSVTemplateFile } from './utils';

type ImportCustomersActivityProps = {
  onClose: VoidFunction;
  onDone: VoidFunction;
  isOpen: boolean;
  onError?: ARErrorFunction;
  onRetry?: VoidFunction;
};

export const ImportCustomersActivity = withAnalyticsContext<ImportCustomersActivityProps>(
  forwardRef(({ setAnalyticsProperties, onDone, onError, onClose, onRetry, ...props }, ref) => {
    const { fileSizeLimit } = useConfig().settings;
    const { importCustomers, isMutating: isImportingCustomers } = useCustomers({ enabled: false, onError });
    const { triggerMessage } = useSystemMessage();
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();

    setAnalyticsProperties({
      ProductName: 'ar',
      PageName: 'import-customer-list',
      Flow: 'add-customer',
      Intent: 'upload-customer-file',
      EntryPoint: 'customers',
    });

    function getErrorMessageLabels(err: ARPlatformError) {
      if (err.errorCode === 'invalidCsvCustomersFile') {
        return {
          title: formatMessage(
            'ar.modals.activities.importCustomers.sectionBannerSummary.title.error.invalidFile.text'
          ),
          action: formatMessage(
            'ar.modals.activities.importCustomers.sectionBannerSummary.title.error.invalidFile.action'
          ),
        };
      } else {
        return {
          title: formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.error.generic.text'),
          action: formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.error.generic.action'),
        };
      }
    }

    const handleSubmit = ({ file }: ImportCustomersFormFields) => {
      importCustomers(file)
        .then(({ data }) => {
          const { totalCount, successCount } = data;
          const fullSuccess = totalCount > 0 && successCount === totalCount;
          const type = fullSuccess ? 'success' : 'informative';
          const title =
            totalCount > 1
              ? formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.success.plural.text', {
                  successCount,
                  totalCount,
                })
              : formatMessage('ar.modals.activities.importCustomers.sectionBannerSummary.title.success.singular.text', {
                  successCount,
                  totalCount,
                });

          onDone();
          triggerMessage({ type, title });
        })
        .catch((err: ARPlatformError) => {
          onError?.(err);
          onClose();
          const { title: errorTextLabel, action: errorActionLabel } = getErrorMessageLabels(err);
          triggerMessage({
            type: 'critical',
            title: errorTextLabel,
            action: onRetry
              ? {
                  text: errorActionLabel,
                  onAction: onRetry,
                }
              : undefined,
          });
        });
    };

    return (
      <ImportCustomersScreen
        data-testid="import-customers-dialog"
        fileSizeLimit={fileSizeLimit}
        onSubmit={handleSubmit}
        isSaving={isImportingCustomers}
        ref={ref}
        onDownloadCSVTemplateFile={() => {
          track('Customer', 'Click', { Intent: 'download-template', Cta: 'download-template-file' });
          onDownloadCSVTemplateFile();
        }}
        onClose={() => {
          track('Customer', 'Click', { Intent: 'exit-flow', Cta: 'cancel' });
          onClose();
        }}
        {...props}
      />
    );
  })
);
ImportCustomersActivity.displayName = 'UpdateCustomerActivity';
