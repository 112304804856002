import { BaseModal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useLocation, useOneTimeEffect } from '@melio/platform-utils';

import {
  useFreeTrialEligibility,
  useIsSubscriptionsEnabled,
  useRedirects,
  useSubscribeToFreePlan,
  useSubscription,
} from '../../../hooks';
import { SubscriptionMonitoringAction } from '../../../monitoring';
import { isRouteAllowedForNotSubscribedOrg } from '../../utils';
import { SubscriptionPlansActivity, SubscriptionPlansVariant } from '../SubscriptionPlans';

export const OnboardingPaywallModalActivity = () => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const subscription = useSubscription();
  const { pathname } = useLocation();
  const { subscribeToFreePlan, isSubscribingToFreePlan } = useSubscribeToFreePlan();
  const { goToReturnUrlOrDashboard } = useRedirects();
  const { isEligibleForFreeTrial, isFetching: isFetchingFreeTrialEligibilityData } = useFreeTrialEligibility();
  const { track } = useAnalytics();
  const { startAction } = useMonitoring<SubscriptionMonitoringAction>();
  const [enableUnlimitedAchPlan] = useDevFeature(FeatureFlags.UnlimitedAchPlan, false);

  useOneTimeEffect(
    () => {
      startAction('subscription_onboarding');
    },
    () => !!startAction,
    [startAction]
  );

  const modalProps = { maxW: enableUnlimitedAchPlan ? '1448px' : '1100px' };

  const handleOnClose = () => {
    if (isSubscribingToFreePlan) {
      return;
    }

    if (!subscription) {
      track('Organization', 'Click', {
        PageName: 'plan-selection',
        Flow: 'subscription',
        Intent: 'exit',
        Cta: 'exit',
        PlanChosen: 'free',
      });

      void subscribeToFreePlan();
    } else {
      goToReturnUrlOrDashboard();
    }
  };

  if (!isSubscriptionsEnabled) {
    return null;
  }

  const isOpen = !subscription && !isRouteAllowedForNotSubscribedOrg(pathname);

  const header = formatMessage(
    isEligibleForFreeTrial
      ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
      : 'activities.subscription.plans.title'
  );

  return (
    <BaseModal
      {...modalProps}
      isOpen={isOpen}
      onClose={handleOnClose}
      data-testid="onboarding-paywall-activity"
      size="large"
      isLoading={isFetchingFreeTrialEligibilityData}
    >
      <BaseModal.Header>
        <Text as="h2" textStyle="heading2Semi">
          {header}
        </Text>
      </BaseModal.Header>
      <BaseModal.Body>
        <SubscriptionPlansActivity
          returnUrl={pathname}
          variant={SubscriptionPlansVariant.MODAL}
          isSubscribingToFreePlan={isSubscribingToFreePlan}
          isOnboarding
        />
      </BaseModal.Body>
    </BaseModal>
  );
};
