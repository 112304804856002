import {
  InternationalAddress,
  InternationalIdType,
  OwnerDetailsTypesEnum,
  OwnerJobTitle,
  TaxIdType,
} from '@melio/ar-domain';
import { UseMelioFormResults } from '@melio/penny';
import { RefObject } from 'react';

export const UsResident = {
  Yes: 'yes',
  No: 'no',
} as const;
export type UsResident = (typeof UsResident)[keyof typeof UsResident];

export type OwnerDetailsFormValues = {
  id?: number;
  jobTitle?: OwnerJobTitle;
  isUbo?: boolean;
  isDirector?: boolean;

  address: InternationalAddress;
  /**
   * A date in YYYY-MM-DD format
   */
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  ownershipPercentage?: number | null;
  usResident: UsResident;
  taxIdType?: TaxIdType;
  taxId?: string;
  internationalIdType?: InternationalIdType;
  internationalId?: string | null;
};

export type BusinessOwnerForm = UseMelioFormResults<OwnerDetailsFormValues>;

export type OwnerDetailsFormValuesWithRefId = Partial<Pick<OwnerDetailsFormValues, 'firstName' | 'lastName'>> & {
  refId: string;
  type: OwnerDetailsTypesEnum;
};

export type BusinessOwnerFormRef = {
  formRef: RefObject<BusinessOwnerForm>;
  visualRef: RefObject<HTMLDivElement>;
  id: string;
  type: OwnerDetailsTypesEnum;
};
