import { Button, Container, Group, Illustration, Text } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';

import { useSubscriptionRouter } from '../../utils';

export const SubscriptionsSelectFundingSourceError = () => {
  const { formatMessage } = useMelioIntl();
  const { goToPaymentMethodSelect } = useSubscriptionRouter();

  useAnalyticsView('PaymentMethod', true, true, {
    PageName: 'something-went-wrong',
    Flow: 'subscription-payment-method-updated',
    Intent: 'update-pm-for-subscription-payment',
  });

  return (
    <Layout maxWidth="600px" data-testid="subscription-select-funding-source-error">
      <Group variant="vertical" alignItems="center">
        <Illustration type="declined" size="medium" />
        <Text textStyle="heading1Semi">{formatMessage('activities.subscription.selectPaymentMethod.error.title')}</Text>
        <Container textAlign="center" paddingY="s">
          <Text textStyle="body2" color="global.neutral.900">
            {formatMessage('activities.subscription.selectPaymentMethod.error.description')}
          </Text>
        </Container>
        <Button
          variant="primary"
          label={formatMessage('activities.subscription.selectPaymentMethod.error.button')}
          onClick={goToPaymentMethodSelect}
          size="large"
          data-testid="subscription-select-funding-source-error-button"
        />
      </Group>
    </Layout>
  );
};
