import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, Group, Icon, Link, Text } from '@melio/penny';
import {
  AccountingPlatformInitQuickBooksDesktopResponseData,
  AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { saveAs } from 'file-saver';
import React from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ConnectionScreenCtaType } from '../../types';
import { ErrorBanner } from './components/ErrorBanner';
import { FooterContent } from './components/FooterContent';
import { PasswordSection } from './components/PasswordSection';
import { WarningBanner } from './components/WarningBanner';

type Props = {
  isError: boolean;
  isLoading: boolean;
  isVerifyLoading: boolean;
  initQuickBooksDesktopData?: AccountingPlatformInitQuickBooksDesktopResponseData;
  verifyQuickBooksDesktopData?: AccountingPlatformVerifyQuickBooksDesktopConnectionResponseData;
  shouldShowResetConnectionWarning: boolean;
  onCancel: VoidFunction;
  onCopyPassword: (value?: string) => void;
  onGuideLinkClick: (cta: ConnectionScreenCtaType) => void;
  onVerifyConnection: VoidFunction;
  onDownloadWebConnector: VoidFunction;
};

const DownloadFileButton = ({
  onClick,
  label,
  testId,
  isDisabled,
}: {
  onClick: VoidFunction;
  label: string;
  testId: string;
  isDisabled: boolean;
}) => (
  <Button
    variant="secondary"
    leftElement={<Icon size="small" type="download" color="inherit" aria-hidden />}
    onClick={onClick}
    label={label}
    isDisabled={isDisabled}
    data-testid={testId}
  />
);

const ConnectToQbdtLink = ({
  href,
  label,
  shouldDownload,
  isDisabled,
  onGuideLinkClick,
}: {
  href: string;
  label: string;
  shouldDownload: boolean;
  isDisabled: boolean;
  onGuideLinkClick: (cta: ConnectionScreenCtaType) => void;
}) =>
  shouldDownload ? (
    <DownloadFileButton
      label={label}
      isDisabled={isDisabled}
      testId="download-connecting-melio-and-qbdt-guide"
      onClick={() => {
        onGuideLinkClick('download-file');
        saveAs(href, 'Connecting-Pay-Bills-and-QuickBooks-Desktop.pdf');
      }}
    />
  ) : (
    <Link
      newTab
      color="secondary"
      href={href}
      isBold
      label={label}
      variant="standalone"
      data-testid="connecting-melio-and-qbdt-guide-link"
      onClick={() => onGuideLinkClick('connecting-melio-and-qbdt-guide')}
    />
  );

export const ConnectToQuickBooksDesktopScreen = ({
  isError,
  isLoading,
  isVerifyLoading,
  initQuickBooksDesktopData,
  verifyQuickBooksDesktopData,
  shouldShowResetConnectionWarning,
  onCancel,
  onCopyPassword,
  onGuideLinkClick,
  onVerifyConnection,
  onDownloadWebConnector,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const {
    links,
    settings: { partnerProductName, shouldDownloadQbdtConnectArticle },
  } = useConfig();

  return (
    <NewSinglePaymentStepLayout
      backgroundColor="white"
      data-component="AccountingPlatformConnectActivity.ConnectToQuickBooksDesktopScreen"
      data-testid="accounting-platform-connect-quickbooks-desktop-screen"
      headerContent={<Box paddingBottom="l" />}
      isStickyFooter
      isLoading={isLoading}
      footerContent={<FooterContent onClose={onCancel} onSubmit={onVerifyConnection} isLoading={isVerifyLoading} />}
    >
      <Container>
        {isError && <ErrorBanner verifyQuickBooksDesktopData={verifyQuickBooksDesktopData} />}

        <Group variant="vertical" spacing="s">
          <NewSinglePaymentStepLayout.Title textAlign="start" tabIndex={-1} onClose={onCancel}>
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.title')}
          </NewSinglePaymentStepLayout.Title>
          <NewSinglePaymentStepLayout.Description textAlign="start" as="p">
            {formatMessage('activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.description')}
          </NewSinglePaymentStepLayout.Description>
          <SystemMessageDisplay data-testid="connect-qbo-notification" />
        </Group>

        {shouldShowResetConnectionWarning && <WarningBanner />}

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.description'
              )}
            </Text>
          </Box>
          <DownloadFileButton
            onClick={onDownloadWebConnector}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepOne.cta'
            )}
            testId="download-web-connector-button"
            isDisabled={isVerifyLoading}
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.description',
                { partnerProductName }
              )}
            </Text>
          </Box>
          <ConnectToQbdtLink
            href={links['quickBooksDesktopWebConnectorGuide']}
            label={formatMessage(
              'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepTwo.cta',
              { partnerProductName }
            )}
            shouldDownload={shouldDownloadQbdtConnectArticle}
            isDisabled={isVerifyLoading}
            onGuideLinkClick={onGuideLinkClick}
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.title'
              )}
            </Text>
          </Box>
          <Box pt="s" pb="m">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepThree.description'
              )}
            </Text>
          </Box>
          <PasswordSection
            password={initQuickBooksDesktopData?.password}
            isLoading={isVerifyLoading}
            onCopy={onCopyPassword}
          />
        </Container>

        <Divider />

        <Container paddingY="m">
          <Box pl="xs">
            <Text as="h2" textStyle="heading3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.title'
              )}
            </Text>
          </Box>
          <Box pt="s">
            <Text textStyle="body2">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.stepFour.description',
                { partnerProductName }
              )}
            </Text>
          </Box>
        </Container>

        <Divider />

        <Container data-testid="accounting-platform-connect-quickbooks-notice" paddingY="m">
          <Box>
            <Text as="h3" textStyle="body3Semi">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.title'
              )}
            </Text>
          </Box>
          <Box>
            <Text textStyle="body3">
              {formatMessage(
                'activities.accountingPlatformConnectCallback.screens.connectQuickBooksDesktop.notice.description'
              )}
            </Text>
          </Box>
        </Container>
      </Container>
    </NewSinglePaymentStepLayout>
  );
};
