/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';
import {
  FormFieldsGroup,
  useVendorAddressUpdate,
  useVendorForm,
  validateInvalidChars,
  VendorAddressUpdateModal,
} from '@melio/ap-widgets';
import { CountrySelect } from '@melio/ap-widgets/src/components/Vendors/fields';
import { InternationalAddressFieldNamesEnum } from '@melio/ap-widgets/src/components/Vendors/fields/InternationalVendorAddressFields/const';
import { InternationalVendorAddressFields } from '@melio/ap-widgets/src/components/Vendors/fields/InternationalVendorAddressFields/InternationalVendorAddressFields.widget';
import { useInternationalVendorAddressSchema } from '@melio/ap-widgets/src/components/Vendors/fields/InternationalVendorAddressFields/internationalVendorAddressSchema';
import { Divider, Form, Group, Text, useWatch, VisuallyHidden } from '@melio/penny';
import { useVendor } from '@melio/platform-api';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, VendorDetailsFormFields } from '@/types/vendors.types';
import { optionalEmailIsValid } from '@/utils/email.utils';
import { optionalPhoneIsValid } from '@/utils/phone.utils';
import { ConfirmVendorUpdateModal, useConfirmVendorUpdate } from '../../ConfirmVendorUpdateModal.widget';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { getUpdatePaperCheckPayload, getUpdateVendorPayload } from './utils';

type VendorDetailsMinimalUnmanagedFields = Pick<
  VendorDetailsFormFields,
  | 'companyName'
  | 'fullName'
  | 'email'
  | 'phone'
  | 'accountNumber'
  | 'countryCode'
  | 'line1'
  | 'line2'
  | 'city'
  | 'state'
  | 'postalCode'
>;

const maskAccountNumber = (accountNumber?: string | null) =>
  accountNumber ? `****${accountNumber.slice(-4)}` : accountNumber;

export const VendorDetailsUnmanagedForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  (
    { vendorId, defaultValues, isSaving, inlineApiErrorCodes, onSubmit, onSubmissionStateChange, isEditable, ...props },
    ref,
  ) => {
    const [shouldCollectVendorAddress] = usePartnerFeature('CollectVendorAddress', false);
    const { formatMessage } = usePlatformIntl();
    const confirmVendorUpdate = useConfirmVendorUpdate({
      vendorId,
      initialFormData: defaultValues,
    });
    const vendorAddressUpdate = useVendorAddressUpdate({
      vendorId,
      shouldCollectVendorAddress,
    });
    const {
      settings: {
        vendor: {
          createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
          forms: { shouldRequireEmail, shouldDisableEmailEdit, shouldCollectCountry },
        },
      },
    } = useConfig();

    const useSchema = (): SchemaOf<VendorDetailsMinimalUnmanagedFields> => {
      const { formatMessage } = usePlatformIntl();
      const { addressFields, countryCodeSchema } = useInternationalVendorAddressSchema();
      const extraFields = shouldCollectVendorAddress
        ? addressFields
        : {
            [InternationalAddressFieldNamesEnum.CountryCode]: shouldCollectCountry
              ? countryCodeSchema
              : string().nullable(),
            [InternationalAddressFieldNamesEnum.Line1]: string().nullable(),
            [InternationalAddressFieldNamesEnum.Line2]: string().nullable(),
            [InternationalAddressFieldNamesEnum.City]: string().nullable(),
            [InternationalAddressFieldNamesEnum.State]: string().nullable(),
            [InternationalAddressFieldNamesEnum.PostalCode]: string().nullable(),
          };

      let email = string()
        .trim()
        .optional()
        .nullable()
        .test(
          'validAddress',
          formatMessage('widgets.vendorDetails.form.email.validation.format'),
          optionalEmailIsValid,
        );

      if (shouldRequireEmail) {
        email = email.required();
      }

      return object().shape({
        companyName: string()
          .trim()
          .required(formatMessage('widgets.vendorDetails.form.companyName.validation.required')),
        fullName: string().trim().optional().nullable(),
        email,
        phone: string()
          .trim()
          .optional()
          .nullable()
          .test(
            'validPhone',
            formatMessage('widgets.vendorDetails.form.phone.validation.format'),
            optionalPhoneIsValid,
          ),
        accountNumber: string()
          .max(32, formatMessage('widgets.vendors.accountNumber.validation.maxLength'))
          .test(
            'validate-invalid-chars',
            '',
            validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
              formatMessage('widgets.vendors.accountNumber.validation.format', {
                invalidChars,
              }),
            ),
          ),
        countryCode: string(),
        ...extraFields,
      }) as SchemaOf<VendorDetailsMinimalUnmanagedFields>;
    };

    const handleSubmit = async (shouldUpdatePaperCheckDM?: boolean) => {
      const data = getValues();
      await onSubmit(
        getUpdateVendorPayload({
          data,
          shouldCollectAddress: shouldCollectVendorAddress,
          shouldCollectCountry: shouldCollectCountry,
        }),
      );
      shouldUpdatePaperCheckDM && (await vendorAddressUpdate.updateDeliveryMethod(getUpdatePaperCheckPayload(data)));
      vendorAddressUpdate.onClose();
      confirmVendorUpdate.onClose();
    };

    const form = useVendorForm<VendorDetailsMinimalUnmanagedFields>({
      onSubmit: (data) => {
        if (vendorAddressUpdate.shouldShowModal()) {
          vendorAddressUpdate.onOpen();
        } else if (confirmVendorUpdate.shouldShowConfirm(data)) {
          confirmVendorUpdate.onOpen();
        } else {
          handleSubmit();
        }
      },
      schema: useSchema(),
      isSaving,
      defaultValues: {
        ...defaultValues,
      },
      inlineApiErrorCodes,
      onSubmissionStateChange,
    });
    const [line1, city, state, postalCode, countryCode] = useWatch({
      control: form.control,
      name: ['line1', 'city', 'state', 'postalCode', 'countryCode'],
    });
    const { formProps, registerField, reset, formState, getValues } = form;
    const { getVendorUnmaskedAccountNumber } = useVendor({ id: vendorId });
    const [isLoadingUnmaskedValues, setIsLoadingUnmaskedValues] = useState(false);
    const [unmaskedAccountNumber, setUnmaskedAccountNumber] = useState<string>();

    const renderAddressFields = () => {
      if (shouldCollectVendorAddress) {
        return (
          <>
            <Divider />
            <FormFieldsGroup label={formatMessage('widgets.vendors.addressDetailsGroup.title')} colSpan={2}>
              <InternationalVendorAddressFields<VendorDetailsMinimalUnmanagedFields>
                form={form}
                colSpan={2}
                label="activities.fundingSources.vendorDetailsForm.fields.line1.label"
                isViewMode={!isEditable}
              />
            </FormFieldsGroup>
          </>
        );
      }

      if (shouldCollectCountry) {
        return <CountrySelect {...registerField('countryCode')} isReadOnly={isEditable} />;
      }

      return null;
    };

    useEffect(() => {
      if (!formState.isSubmitting) {
        const accountNumber = isEditable ? unmaskedAccountNumber : maskAccountNumber(defaultValues?.accountNumber);

        reset({
          ...defaultValues,
          accountNumber: accountNumber ?? defaultValues?.accountNumber ?? '',
        });
      }
    }, [defaultValues, isEditable, reset]);

    useEffect(() => {
      const unmaskValues = async () => {
        setIsLoadingUnmaskedValues(true);

        try {
          const vendorUnmaskedAccountNumber = await getVendorUnmaskedAccountNumber();

          setUnmaskedAccountNumber(vendorUnmaskedAccountNumber.accountNumber);
        } finally {
          setIsLoadingUnmaskedValues(false);
        }
      };

      if (isEditable && shouldCollectAccountNumberForUnmanagedVendor) {
        unmaskValues();
      }
    }, [isEditable, getVendorUnmaskedAccountNumber, defaultValues?.accountNumber]);

    const handleVendorAddressUpdate = (selectedOption: string) => {
      if (selectedOption === 'vendorBusinessAddressOnly') {
        return handleSubmit();
      }

      if (selectedOption === 'vendorAddressAndPaperCheckDeliveryAddress') {
        return handleSubmit(true);
      }
    };

    return (
      <Group variant="vertical" spacing="m">
        <Group variant="vertical" spacing="xs">
          {isEditable && (
            <Text color="global.neutral.900" textStyle="body4">
              {formatMessage('widgets.vendorDetails.form.business.requiredFields')}
            </Text>
          )}
          <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-business-title">
            {formatMessage('widgets.vendorDetails.form.business.title')}
          </Text>
        </Group>
        <Form
          data-component={DataComponentEnum.VENDOR_DETAILS_FORM}
          isLoading={isLoadingUnmaskedValues}
          {...props}
          {...formProps}
          ref={ref}
          isViewMode={!isEditable}
          {...preventFormSubmissionOnEnter()}
        >
          <Form.TextField
            {...registerField('companyName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
            placeholder={formatMessage('widgets.vendorDetails.form.companyName.placeholder')}
            autoFocus={isEditable}
            isTruncated={!isEditable}
          />
          <Form.TextField
            {...registerField('fullName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.name.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.name.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.name.placeholder')}
            isTruncated={!isEditable}
          />
          {shouldCollectAccountNumberForUnmanagedVendor && (
            <Form.TextField
              {...registerField('accountNumber')}
              data-private
              labelProps={{
                label: formatMessage('widgets.vendors.accountNumber.label'),
                tooltipProps: {
                  content: (
                    <>
                      <VisuallyHidden>
                        {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')} tooltip
                      </VisuallyHidden>
                      {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')}
                    </>
                  ),
                },
              }}
              isTruncated={!isEditable}
              viewModePlaceholder={formatMessage('widgets.vendors.accountNumber.optional.viewModePlaceholder')}
            />
          )}
          <Form.TextField
            {...registerField('email')}
            labelProps={{
              label: formatMessage('widgets.vendorDetails.form.email.label'),
              tooltipProps:
                isEditable && shouldDisableEmailEdit
                  ? {
                      content: (
                        <>
                          <VisuallyHidden>
                            {formatMessage('widgets.vendorDetails.form.email.labelTooltip')} tooltip
                          </VisuallyHidden>
                          {formatMessage('widgets.vendorDetails.form.email.labelTooltip')}
                        </>
                      ),
                    }
                  : undefined,
            }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.email.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.email.placeholder')}
            isTruncated={!isEditable}
            isReadOnly={shouldDisableEmailEdit}
          />
          <Form.PhoneField
            {...registerField('phone')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.phone.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.phone.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.phone.placeholder')}
          />
          {renderAddressFields()}
        </Form>
        <ConfirmVendorUpdateModal
          submitForm={handleSubmit}
          isSubmitting={formState.isSubmitting}
          onClose={confirmVendorUpdate.onClose}
          isOpen={confirmVendorUpdate.isOpen}
        />
        <VendorAddressUpdateModal
          address={`${line1}, ${city}, ${state} ${postalCode}, ${countryCode}`}
          isOpen={vendorAddressUpdate.isOpen}
          isLoading={isSaving}
          onClose={vendorAddressUpdate.onClose}
          onSave={handleVendorAddressUpdate}
        />
      </Group>
    );
  },
);
