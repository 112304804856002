import { Address, CreateVendorParams, DeliveryMethodType } from '@melio/platform-api';
import { identity, pickBy } from 'lodash';

import {
  AddVendorPaypalAccountForm,
  BankAccountFormModel,
  DeliveryMethod,
  PaperCheckForm,
} from '../../../../../../VendorDeliveryMethodsSection/types';
import { AddVendorFormFields } from '../../../../types';

export const getAddressPayload = ({
  data,
  shouldCollectAddress,
  shouldCollectCountry,
}: {
  data: AddVendorFormFields;
  shouldCollectAddress: boolean;
  shouldCollectCountry: boolean;
}) => {
  const { line1, line2, city, state, postalCode, countryCode } = data;

  if (shouldCollectAddress) {
    const address = {
      line1: line1 ?? '',
      line2: line2 ?? '',
      city: city ?? '',
      state: state ?? '',
      postalCode: postalCode ?? '',
      countryCode: countryCode ?? '',
    };

    return Object.values(address).every((value) => !value?.trim()) ? null : address;
  }

  return shouldCollectCountry
    ? {
        line1: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode: countryCode ?? '',
      }
    : null;
};

export const getCreateVendorPayload = ({
  data,
  shouldCollectAddress,
  shouldCollectCountry,
  deliveryMethods,
}: {
  data: AddVendorFormFields;
  shouldCollectAddress: boolean;
  shouldCollectCountry: boolean;
  deliveryMethods?: DeliveryMethod[];
}): CreateVendorParams => {
  const { companyName, email, phone, fullName, accountNumber } = data;

  const address: Address | null = getAddressPayload({
    data,
    shouldCollectAddress,
    shouldCollectCountry,
  });

  const contact = pickBy(
    {
      name: fullName,
      email,
      phoneNumber: phone,
      address,
    },
    identity
  );

  const bankAccountDeliveryMethod = deliveryMethods?.length
    ? (deliveryMethods.find((dm) => dm.type === DeliveryMethodType.BankAccount)?.values as BankAccountFormModel)
    : undefined;
  const paperCheckDeliveryMethod = deliveryMethods?.length
    ? (deliveryMethods.find((dm) => dm.type === DeliveryMethodType.PaperCheck)?.values as PaperCheckForm)
    : undefined;
  const paypalBalanceDeliveryMethod = deliveryMethods?.length
    ? (deliveryMethods.find((dm) => dm.type === DeliveryMethodType.PaypalBalance)?.values as AddVendorPaypalAccountForm)
    : undefined;
  const bankAccount = bankAccountDeliveryMethod
    ? {
        routingNumber: bankAccountDeliveryMethod.routingNumber,
        accountNumber: bankAccountDeliveryMethod.accountNumber,
      }
    : undefined;
  const paperCheck = paperCheckDeliveryMethod
    ? {
        printName: paperCheckDeliveryMethod.printName,
        address: {
          line1: paperCheckDeliveryMethod.line1,
          line2: paperCheckDeliveryMethod.line2,
          city: paperCheckDeliveryMethod.city,
          state: paperCheckDeliveryMethod.state,
          postalCode: paperCheckDeliveryMethod.postalCode,
        },
      }
    : undefined;
  const paypalBalance = paypalBalanceDeliveryMethod
    ? {
        paypalAccountEmail: paypalBalanceDeliveryMethod.email,
      }
    : undefined;

  return {
    name: companyName ?? '',
    contact,
    accountNumber: accountNumber || undefined,
    bankAccount,
    paperCheck,
    paypalBalance,
  };
};
