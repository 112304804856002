import {
  InternationalIdType as ComplianceInternationalIdType,
  TaxIdTypeEnum as ComplianceTaxIdTypeEnum,
} from '@melio/compliance-validator';
import { FieldValues, UseMelioFormResults } from '@melio/penny';
import {
  AccountAddress,
  Industry,
  InternationalAddress,
  InternationalIdType,
  OkiTaxIdType,
  OrganizationBusinessType,
  TaxIdTypeEnum,
} from '@melio/platform-api';
export type WithInternationalAddress<T> = T extends { address: InternationalAddress } ? T : never;

export type USResidency = {
  usResidence: 'usResidence';
  taxIdType: TaxIdType;
  taxId: string;
};

export type NonUSResidency = {
  usResidence: 'nonUsResidence';
  idType: IdType;
  idNumber: string;
};

export type Residency = USResidency | NonUSResidency;

export type BusinessDetailsForm = {
  companyName: string;
  companyLegalName: string;
  legalBusinessAddress: AccountAddress;
  phone: string;
  businessIndustry: Industry;
  businessType: OrganizationBusinessType;
  taxIdType: TaxIdTypeEnum;
  businessTaxId: string;
};

export type AccountHolderForm = WithInternationalAddress<{
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address: InternationalAddress;
  dayOfBirth: Date;
  isUbo: boolean;
  residencyDetails: Residency;
}>;

export type DecisionMakerForm = {
  uboId: string | null;
  companyRole: string;
  decisionMakerFirstName: string;
  decisionMakerLastName: string;
  decisionMakerDayOfBirth: Date;
  decisionMakerAddress: InternationalAddress;
  decisionMakerResidencyDetails: Residency;
};

export type TermsAndConditionsForm = {
  termsAndConditions: boolean;
};

export type OwnershipDetailsForm = {
  ubos: OwnershipDetails[];
  noIndividualsOwner: boolean;
};

export enum TaxIdType {
  Ssn = 'SSN',
  Itin = 'ITIN',
}
export enum IdType {
  Passport = 'Passport',
  DiverLicense = 'DriverLicense',
  NationalId = 'NationalId',
}

export const mapBusinessTaxIdToRiskBusinessTaxType = {
  [TaxIdTypeEnum.Ein]: ComplianceTaxIdTypeEnum.Ein,
  [TaxIdTypeEnum.Itin]: ComplianceTaxIdTypeEnum.Itin,
  [TaxIdTypeEnum.Ssn]: ComplianceTaxIdTypeEnum.Ssn,
};
export const mapInternationalTypeToInternationalIdType = {
  [IdType.Passport]: ComplianceInternationalIdType.Passport,
  [IdType.DiverLicense]: ComplianceInternationalIdType.DriversLicense,
  [IdType.NationalId]: ComplianceInternationalIdType.NationalId,
};

export const mapTaxIdTypeToOkiTaxIdType = {
  [TaxIdType.Itin]: ComplianceTaxIdTypeEnum.Itin,
  [TaxIdType.Ssn]: ComplianceTaxIdTypeEnum.Ssn,
};

export const mapInternationalTypeToOkiInternationalType: Record<IdType, InternationalIdType> = {
  [IdType.Passport]: 'Passport',
  [IdType.DiverLicense]: 'DriverLicense',
  [IdType.NationalId]: 'NationalId',
};

export const TaxIdTypeMap: Record<TaxIdType, OkiTaxIdType> = {
  [TaxIdType.Ssn]: 'SSN',
  [TaxIdType.Itin]: 'ITIN',
};

export const TaxIdTypeReverseMap: Record<OkiTaxIdType, TaxIdType> = {
  SSN: TaxIdType.Ssn,
  ITIN: TaxIdType.Itin,
};

export const InternationalIdTypeMap: Record<IdType, InternationalIdType> = {
  [IdType.Passport]: 'Passport',
  [IdType.DiverLicense]: 'DriverLicense',
  [IdType.NationalId]: 'NationalId',
};

export const InternationalIdTypeReverseMap: Record<InternationalIdType, IdType> = {
  Passport: IdType.Passport,
  DriverLicense: IdType.DiverLicense,
  NationalId: IdType.NationalId,
};

export type OwnershipDetails = {
  uboId: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownershipPercentage: string;
  address: InternationalAddress;
  dateOfBirth?: Date;
  residencyDetails: Residency;
};

export type UnsupportedModalValues = { businessType: OrganizationBusinessType; businessIndustryName: Industry };

export enum FXBusinessDetailsFormSteps {
  BUSINESS_DETAILS = 0,
  ACCOUNT_HOLDER = 1,
  UBO = 2,
  DECISION_MAKER = 3,
  TERMS_AND_CONDITIONS = 4,
}

export type FXFormSectionCardBaseProps<T extends FieldValues> = {
  form: UseMelioFormResults<T>;
  isExpanded: boolean;
  onExpandChange: (state: boolean) => void;
  isCompleted?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

export const OTHER_DECISION_MAKER_KEY = 'OTHER';
export const ACCOUNTHOLDER_DECISION_MAKER_KEY = 'ACCOUNT_HOLDER';
export const UBO_FROM_ACCOUNT_HOLDER_ID = 'ubo-from-account-holder';
export enum DecisionMakerRoles {
  CEO = 'CEO',
  CFO = 'CFO',
  COO = 'COO',
  MANAGING_MEMBER = 'MANAGING_MEMBER',
  GENERAL_PARTNER = 'GENERAL_PARTNER',
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  TREASURER = 'TREASURER',
}
