/* eslint-disable no-restricted-imports */
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';

import { useAccountantsRoutes } from '../../../../utils/useAccountantsRoutes';
import { PaymentOverviewContainer } from './PaymentOverviewContainer';

export const OpenBalance = ({
  client,
  onSwitchOrganization,
}: {
  client: AccountingFirmClientExpanded;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
}) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { goToPayDashboardBillsTab } = useAccountantsRoutes();

  const title = formatMessage(
    'activities.accountants.firmDashboard.v2.clientDrawer.paymentsOverview.openBalance.semiHeader.label'
  );
  const description = formatMessage(
    'activities.accountants.firmDashboard.v2.clientDrawer.paymentsOverview.openBalance.description.label',
    { count: client.paymentsOverview.openBalance?.count || 0 }
  );

  const onClick = async () => {
    try {
      await onSwitchOrganization({
        organizationId: client.organizationInfo.id,
        keepLoadingState: true,
        switchAccessTokenOnly: true,
      });
      goToPayDashboardBillsTab({ refresh: true });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.switchOrganization.toast.error'),
      });
    }
  };
  return (
    <PaymentOverviewContainer
      title={title}
      amount={client.paymentsOverview.openBalance?.amount || 0}
      description={description}
      dataTestId="drawer-payments-overview-open-balance"
      onClick={() => void onClick()}
    />
  );
};
