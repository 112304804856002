import { CheckAccountFormModel } from '@melio/ap-widgets';
import { DeliveryMethodType, useDeliveryMethods } from '@melio/platform-api';
import { useState } from 'react';

export const useVendorAddressUpdate = ({
  vendorId,
  shouldCollectVendorAddress,
}: {
  vendorId: string;
  shouldCollectVendorAddress: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: deliveryMethods, isLoading: isDeliveryMethodsLoading, create } = useDeliveryMethods({ vendorId });
  const isPaperCheckDeliveryMethodExists =
    deliveryMethods?.find((deliveryMethod) => deliveryMethod.type === DeliveryMethodType.PaperCheck) || false;

  const shouldShowModal = () => {
    if (!shouldCollectVendorAddress) {
      return false;
    }

    if (shouldCollectVendorAddress && isPaperCheckDeliveryMethodExists) {
      return true;
    }

    return false;
  };

  const updateDeliveryMethod = async (data: CheckAccountFormModel) => {
    const { printName, ...address } = data;

    await create({
      type: DeliveryMethodType.PaperCheck,
      details: {
        printName,
        address,
      },
    });
  };

  return {
    isLoading: isDeliveryMethodsLoading,
    shouldShowModal,
    updateDeliveryMethod,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
    isOpen,
  };
};
