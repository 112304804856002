import { DatePreset, useInvoiceReport, useInvoices, UseInvoicesProps, useSearchParams } from '@melio/ar-domain';
import { isArray, isNil } from 'lodash';
import { useMemo } from 'react';

import { DateRange, Filter, Order, SortFields, SortParams, UseSortableInvoicesParams } from '../types';

type QueryParams = {
  sortOrder: Order;
  sortBy: SortFields;
  pageNumber: number;
  invoiceStatus?: Filter[] | Filter;
  searchTerm: string;
  customerId: string;
  dueDate?: DatePreset;
  updatedAt?: DatePreset;
  dueDateRange?: DateRange;
  updatedAtRange?: DateRange;
};

type InvoiceParams = Omit<NonNullable<UseInvoicesProps['params']>, 'sort'> & {
  sort: SortParams;
};

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams<QueryParams>();

  const {
    invoiceStatus,
    pageNumber = 1,
    searchTerm,
    sortBy = 'updatedAt',
    sortOrder = 'Desc',
    customerId,
    dueDate,
    updatedAt,
    dueDateRange,
    updatedAtRange,
  } = searchParams;

  return {
    searchParams: useMemo(
      () => ({
        sortOrder,
        sortBy,
        invoiceStatus: invoiceStatus || undefined,
        searchTerm,
        pageNumber,
        customerId,
        dueDate,
        updatedAt,
        dueDateRange,
        updatedAtRange,
      }),
      [
        invoiceStatus,
        pageNumber,
        searchTerm,
        sortBy,
        sortOrder,
        customerId,
        dueDateRange,
        updatedAtRange,
        dueDate,
        updatedAt,
      ]
    ),
    setSearchParams,
  };
};

export const useInvoicesFiltering = () => {
  const { searchParams, setSearchParams } = useQueryParams();

  const { searchTerm, customerId } = searchParams;
  const invoiceStatus =
    isArray(searchParams.invoiceStatus) || !searchParams.invoiceStatus
      ? searchParams.invoiceStatus
      : [searchParams.invoiceStatus];

  const filterParams = {
    searchTerm: customerId ? undefined : searchTerm,
    invoiceStatus,
    updatedAtRange: searchParams.updatedAtRange,
    dueDateRange: searchParams.dueDateRange,
    customerId: customerId || undefined,
  };

  const isFiltered = Object.values(filterParams).some((value) => value !== undefined);

  const invoiceParams: InvoiceParams = {
    ...filterParams,
    sort: { field: searchParams.sortBy, order: searchParams.sortOrder },
  };

  const { data, isFetching, isLoading, error, pagination } = useInvoices({
    params: invoiceParams,
    pageNumber: searchParams.pageNumber,
    onPageChange: (pageNumber: number) => setSearchParams((prev) => ({ ...prev, pageNumber })),
  });

  const {
    data: reportsData,
    isFetching: isReportsFetching,
    isLoading: isReportsLoading,
  } = useInvoiceReport(filterParams, isFiltered);

  return {
    invoices: useMemo(() => data ?? [], [data]),
    pagination,
    summary: reportsData ? { totalAmount: reportsData.totalAmount, totalCount: reportsData.count } : undefined,
    isFetching,
    isLoading,
    isReportsFetching: isReportsFetching || isReportsLoading,
    invoiceStatus,
    updatedAt: searchParams.updatedAt,
    dueDate: searchParams.dueDate,
    dueDateRange: searchParams.dueDateRange,
    updatedAtRange: searchParams.updatedAtRange,
    search: searchParams.searchTerm || '',
    error,
    sortParams: invoiceParams.sort,
    setParams: (params: UseSortableInvoicesParams) => {
      const { searchTerm, ...rest } = params;
      if (Object.keys(rest).length > 0) {
        setSearchParams((prev) => ({
          ...prev,
          ...toQueryParams(rest),
        }));
      }
      if (!isNil(searchTerm) && searchTerm != searchParams.searchTerm) {
        setSearchParams((prev) => ({
          ...prev,
          searchTerm,
          customerId: '',
        }));
      }
    },
    clearFilterParams: () => {
      const { invoiceStatus, updatedAtRange, dueDateRange, dueDate, updatedAt, ...rest } = searchParams;
      setSearchParams({
        invoiceStatus: undefined,
        updatedAtRange: undefined,
        dueDateRange: undefined,
        dueDate: undefined,
        updatedAt: undefined,
        ...rest,
      });
    },
    clearAllParams: () => {
      setSearchParams({});
    },
  };
};

const toQueryParams = ({ sort, order, ...rest }: UseSortableInvoicesParams) => ({
  ...rest,
  ...(sort && { sortBy: sort }),
  ...(order && { sortOrder: order }),
});
