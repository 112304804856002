import { Box } from '@chakra-ui/react';
import { Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../api';
import { usePredefinedDiscountedPlanPricing } from '../../../../hooks';
import { getAnnualPricePerMonth } from '../../../utils';

type UsePriceBreakdownProps = {
  plan: Plan;
  cycle: SubscriptionBillingCycleEnum;
  paidSeatsCount: number;
  predefinedDiscountPercentage?: number;
};
export const usePriceBreakdown = ({
  plan,
  cycle,
  paidSeatsCount,
  predefinedDiscountPercentage,
}: UsePriceBreakdownProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const predefinedDiscountedPlanPricing = usePredefinedDiscountedPlanPricing({
    planCycle: plan.cycles?.[cycle],
    discountPercentage: predefinedDiscountPercentage,
  });
  const { basePrice: currentBasePrice = 0, unitPrice: currentUnitPrice = 0 } = plan.cycles?.[cycle] || {};

  const isAnnualCycle = cycle === SubscriptionBillingCycleEnum.Annual;
  const basePricePerMonth = isAnnualCycle ? getAnnualPricePerMonth(currentBasePrice) : currentBasePrice;
  const additionalSeatPricePerMonth = isAnnualCycle ? getAnnualPricePerMonth(currentUnitPrice) : currentUnitPrice;

  const getPriceWithDecimalOption = (price: number) => {
    const isNumberWithoutDecimal = price % 1 === 0;

    return formatCurrency(price, undefined, isNumberWithoutDecimal ? { notation: 'compact' } : undefined);
  };

  const overrideTextStyle = (price: number) => (
    <Text textStyle="body3" color="global.neutral.500">
      <Box textDecoration="line-through" as="span">
        {getPriceWithDecimalOption(price)}
      </Box>
    </Text>
  );

  const basePriceBeforeDiscount = isAnnualCycle
    ? plan.cycles.monthly.basePrice
    : predefinedDiscountedPlanPricing?.priceBeforeDiscount
    ? getAnnualPricePerMonth(predefinedDiscountedPlanPricing.priceBeforeDiscount)
    : null;

  const basePriceBreakdown = formatMessage(`activities.subscription.checkout.basePriceBreakdown.${cycle}`, {
    basePrice: getPriceWithDecimalOption(basePricePerMonth),
    basePriceBeforeDiscount: basePriceBeforeDiscount ? overrideTextStyle(basePriceBeforeDiscount) : null,
  });

  const additionalSeatsPriceBreakDown = formatMessage(
    `activities.subscription.checkout.additionalSeatsPriceBreakdown.${cycle}`,
    {
      additionalSeatPrice: getPriceWithDecimalOption(additionalSeatPricePerMonth),
      additionalUsers: paidSeatsCount,
      basePriceBeforeDiscount: isAnnualCycle ? overrideTextStyle(plan.cycles.monthly.unitPrice || 0) : null,
    }
  );

  return {
    basePriceBreakdown,
    additionalSeatsPriceBreakDown: paidSeatsCount > 0 ? additionalSeatsPriceBreakDown : null,
  };
};
