import { Link, Table, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Bill, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useNavigate } from 'react-router-dom';

export const VendorsTabUnpaidBillsCell = ({ vendor, unpaidBills }: { vendor: Vendor; unpaidBills: Bill[] }) => {
  const { formatMessage } = useMelioIntl();
  const [isBillsTabFiltersInfraEnabled] = usePartnerFeature('BillsTabFiltersInfra', false);

  const navigate = useNavigate();
  const { track } = useAnalytics();

  const noUnpaidBills = unpaidBills.length === 0;
  const unpaidBillsText = noUnpaidBills
    ? formatMessage('activities.payDashboard.vendorsTab.table.cells.unpaidBills.none')
    : formatMessage('activities.payDashboard.vendorsTab.table.cells.unpaidBills.multiple', {
        billsCount: unpaidBills.length,
        plural: unpaidBills.length > 1 && 's',
      });

  const onUnpaidbillsClick = (e: React.MouseEvent<Element, MouseEvent>, vendorName: string) => {
    e.preventDefault();
    e.stopPropagation();
    track('Dashboard', 'Click', {
      Intent: 'view-bills',
      Cta: 'bills-details',
      VendorId: vendor.id,
    });
    return navigate(
      isBillsTabFiltersInfraEnabled
        ? `../bills?inboxItem.vendorId=${vendor.id}`
        : `../bills?search=${encodeURIComponent(vendorName)}`
    );
  };

  return (
    <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-unpaid-bills-cell`}>
      {noUnpaidBills ? (
        <Text
          textStyle="body3"
          color="global.neutral.800"
          data-testid={`vendors-tab-row-${vendor.id}-unpaid-bills-cell-text`}
        >
          {unpaidBillsText}
        </Text>
      ) : (
        <Link
          isBold
          variant="standalone"
          color="secondary"
          size="medium"
          href={
            isBillsTabFiltersInfraEnabled ? `bills/?inboxItem.vendorId=${vendor.id}` : `bills/search?=${vendor.name}`
          }
          label={unpaidBillsText}
          data-testid={`vendors-tab-row-${vendor.id}-unpaid-bills-cell-link`}
          onClick={(e) => onUnpaidbillsClick(e, vendor.name)}
          aria-label={formatMessage('activities.payDashboard.vendorsTab.table.cells.unpaidBills.link.ariaLabel', {
            vendorName: vendor.name,
            billsCount: unpaidBills.length,
            plural: unpaidBills.length > 1 && 's',
          })}
        />
      )}
    </Table.Cell>
  );
};
