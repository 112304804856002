import {
  ARCustomerTypeEnum,
  ARErrorCode,
  FormDialog,
  FormDialogWidgetProps,
  useMelioIntl,
  useRiskMtlSchemaValidations,
  yup,
} from '@melio/ar-domain';
import { FormField, PhoneField, RadioGroup, TextField, useMelioForm, VisuallyHidden } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';

export type CustomerFormFields = {
  customerName: string;
  email: string;
  customerType: ARCustomerTypeEnum;
  customerFirstName: string;
  customerLastName: string;
  phone: string;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { customerName } = useRiskMtlSchemaValidations({ customerName: true });

  return yup.object().shape({
    customerName: customerName(),
    email: yup
      .string()
      .email(formatMessage('ar.modals.activities.customerManagement.form.email.validation.general.label'))
      .required(formatMessage('ar.modals.activities.customerManagement.form.email.required.label')),
    customerType: yup
      .string()
      .oneOf(Object.values(ARCustomerTypeEnum))
      .required(formatMessage('ar.modals.activities.customerManagement.form.customerType.required.label')),
    phone: yup
      .string()
      .required(formatMessage('ar.modals.activities.customerManagement.form.phone.validation.format'))
      .test(
        'valid-phone',
        formatMessage('ar.modals.activities.customerManagement.form.phone.validation.format'),
        (phone = '') => phone.length === 10
      ),
    customerFirstName: yup
      .string()
      .required()
      .when('customerType', {
        is: ARCustomerTypeEnum.Consumer,
        then: (schema) =>
          schema
            .required(
              formatMessage('ar.modals.activities.customerManagement.form.firstName.consumerType.required.text')
            )
            .mtl_contactFirstName(
              formatMessage('ar.modals.activities.customerManagement.form.firstName.consumerType.invalid.text')
            ),
        otherwise: (schema) =>
          schema
            .required(
              formatMessage('ar.modals.activities.customerManagement.form.firstName.businessType.required.text')
            )
            .mtl_contactFirstName(
              formatMessage('ar.modals.activities.customerManagement.form.firstName.businessType.invalid.text')
            ),
      }),
    customerLastName: yup
      .string()
      .required()
      .when('customerType', {
        is: ARCustomerTypeEnum.Consumer,
        then: (schema) =>
          schema
            .required(formatMessage('ar.modals.activities.customerManagement.form.lastName.consumerType.required.text'))
            .mtl_contactLastName(
              formatMessage('ar.modals.activities.customerManagement.form.lastName.consumerType.invalid.text')
            ),
        otherwise: (schema) =>
          schema
            .required(formatMessage('ar.modals.activities.customerManagement.form.lastName.businessType.required.text'))
            .mtl_contactLastName(
              formatMessage('ar.modals.activities.customerManagement.form.lastName.businessType.invalid.text')
            ),
      }),
  }) as yup.SchemaOf<CustomerFormFields>;
};

export type CustomerFormDialogProps = FormDialogWidgetProps<
  CustomerFormFields,
  {
    header: string;
    primaryButtonLabel: string;
    secondaryButtonLabel: string;
    error?: ARPlatformError;
  }
>;

export const CustomerFormDialog = forwardRef<CustomerFormDialogProps>(
  (
    { onSubmit, defaultValues: _defaultValues, isSaving, primaryButtonLabel, secondaryButtonLabel, error, ...props },
    ref
  ) => {
    const defaultValues = useMemo<NonNullable<CustomerFormDialogProps['defaultValues']>>(
      () => ({
        customerType: 'business',
        customerName: '',
        email: '',
        phone: '',
        customerFirstName: '',
        customerLastName: '',
        ..._defaultValues,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.stringify(_defaultValues)]
    );

    const { registerField, watch, setError, ...useFormResults } = useMelioForm({
      schema: useSchema(),
      onSubmit,
      defaultValues,
      isSaving,
      subscribeToDefaultValuesChanges: true,
    });
    const { formatMessage } = useMelioIntl();
    const customerType = watch('customerType');
    const isConsumerType = customerType === ARCustomerTypeEnum.Consumer;

    useEffect(() => {
      if (error?.errorCode === ARErrorCode.CustomerEmailNotUnique) {
        setError('email', {
          message: formatMessage('ar.modals.activities.customerManagement.form.email.validation.nonUniqueEmail.label'),
        });
      }
    }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormDialog
        columns={2}
        useFormResults={useFormResults}
        size="small"
        primaryButton={{ label: primaryButtonLabel }}
        secondaryButton={{ label: secondaryButtonLabel }}
        closeButtonAriaLabel={formatMessage('ar.modals.activities.customerManagement.modal.closeButtonAriaLabel')}
        {...props}
        ref={ref}
      >
        <FormField
          colSpan={2}
          data-testid="form-field-customerType"
          {...registerField('customerType')}
          labelProps={{
            label: formatMessage('ar.modals.activities.customerManagement.form.customerType.label'),
            tooltipProps: {
              content: (
                <>
                  <VisuallyHidden>
                    {formatMessage('ar.modals.activities.customerManagement.form.customerType.tooltip.label')} tooltip
                  </VisuallyHidden>
                  {formatMessage('ar.modals.activities.customerManagement.form.customerType.tooltip.label')}
                </>
              ),
              triggerAriaLabel: formatMessage(
                'ar.modals.activities.customerManagement.form.customerType.tooltip.ariaLabel',
                {
                  tooltipContent: formatMessage(
                    'ar.modals.activities.customerManagement.form.customerType.tooltip.label'
                  ),
                }
              ),
            },
          }}
          render={(formFieldProps) => (
            <RadioGroup
              {...formFieldProps}
              options={[
                {
                  value: ARCustomerTypeEnum.Business,
                  ariaLabel: formatMessage(
                    'ar.modals.activities.customerManagement.form.customerType.option.ariaLabel',
                    { customerType: ARCustomerTypeEnum.Business }
                  ),
                  mainLabelProps: {
                    label: formatMessage(
                      'ar.modals.activities.customerManagement.form.customerType.option.business.label'
                    ),
                  },
                },
                {
                  value: ARCustomerTypeEnum.Consumer,
                  ariaLabel: formatMessage(
                    'ar.modals.activities.customerManagement.form.customerType.option.ariaLabel',
                    { customerType: ARCustomerTypeEnum.Consumer }
                  ),
                  mainLabelProps: {
                    label: formatMessage(
                      'ar.modals.activities.customerManagement.form.customerType.option.consumer.label'
                    ),
                  },
                },
              ]}
            />
          )}
        />
        <FormField
          colSpan={2}
          data-testid="form-field-customerName"
          {...registerField('customerName')}
          labelProps={{
            label: formatMessage('ar.modals.activities.customerManagement.form.customerName.label'),
          }}
          helperText={formatMessage(
            isConsumerType
              ? 'ar.modals.activities.customerManagement.form.customerName.customerType.helperText'
              : 'ar.modals.activities.customerManagement.form.customerName.businessType.helperText'
          )}
          render={(formFieldProps) => <TextField {...formFieldProps} />}
        />
        <FormField
          data-testid="form-field-customerFirstName"
          {...registerField('customerFirstName')}
          colSpan={1}
          labelProps={{
            label: formatMessage(
              isConsumerType
                ? 'ar.modals.activities.customerManagement.form.firstName.customerType.label'
                : 'ar.modals.activities.customerManagement.form.firstName.businessType.label'
            ),
          }}
          render={(formFieldProps) => <TextField {...formFieldProps} />}
        />
        <FormField
          data-testid="form-field-customerLastName"
          colSpan={1}
          {...registerField('customerLastName')}
          labelProps={{
            label: formatMessage(
              isConsumerType
                ? 'ar.modals.activities.customerManagement.form.lastName.customerType.label'
                : 'ar.modals.activities.customerManagement.form.lastName.businessType.label'
            ),
          }}
          render={(formFieldProps) => <TextField {...formFieldProps} />}
        />
        <FormField
          data-testid="form-field-email"
          colSpan={2}
          {...registerField('email')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.email.label') }}
          render={(formFieldProps) => <TextField {...formFieldProps} />}
        />
        <FormField
          data-testid="form-field-phone"
          colSpan={2}
          {...registerField('phone')}
          labelProps={{ label: formatMessage('ar.modals.activities.customerManagement.form.phone.label') }}
          render={(formFieldProps) => <PhoneField {...formFieldProps} />}
        />
      </FormDialog>
    );
  }
);
