import { getFastDeliveryPreferenceOptionType, usePaymentSchedulingPreference } from '@melio/ap-domain';
import { DeliveryPreference, PaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isEqual } from 'lodash';

export const useDeliveryPreferenceMessages = () => {
  const { formatMessage } = useMelioIntl();
  const { isByScheduleDate } = usePaymentSchedulingPreference();

  return {
    getLabel: ({ totalBusinessDays, totalMaxBusinessDays }: DeliveryPreference) => {
      const showDateRange = isByScheduleDate && !isEqual(totalBusinessDays, totalMaxBusinessDays);
      const isSameDayDelivery = totalMaxBusinessDays === 0;

      if (showDateRange) {
        return formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.range.label', {
          totalBusinessDays,
          totalMaxBusinessDays,
        });
      }

      if (isSameDayDelivery) {
        return formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.single.same.day.label'
        );
      }

      return formatMessage('activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.single.label', {
        totalBusinessDays,
      });
    },
  };
};

type FastType = 'express-check' | 'expedited-ach';
export const useFastOptionLabel = (deliveryPreferencesOptions: PaymentIntent['deliveryPreferenceOptions']) => {
  const { formatMessage } = useMelioIntl();
  const fastType = getFastDeliveryPreferenceOptionType(deliveryPreferencesOptions) as FastType;
  return (
    (fastType &&
      formatMessage(`activities.batchPayments.screens.paymentIntentsTable.deliverySpeedCell.fast.${fastType}.label`)) ||
    ''
  );
};

export const getRelatedSlowOption = (type: DeliveryPreference['type']) => (type === 'express-check' ? 'check' : 'ach');
