/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Box } from '@chakra-ui/react';
import { Tag, Text, useBreakpoint } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { FilterChipProps } from './FilterChip.types';

const useGetTextFromValues = (values: string[]) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const MAX_VISIBLE_VALUES_NUM = isExtraSmallScreen ? 1 : 2;

  if (values[0] === formatMessage('activities.payDashboard.filters.custom')) {
    const datesRange = values[1];
    return `${datesRange}`;
  }
  if (values.length <= MAX_VISIBLE_VALUES_NUM) {
    return values.join(', ');
  }
  return `${values.slice(0, MAX_VISIBLE_VALUES_NUM).join(', ')} +${values.length - MAX_VISIBLE_VALUES_NUM}`;
};

export const FilterChip = ({ label, values, onDeleteFilter, deleteAriaLabel, id }: FilterChipProps) => {
  const { formatMessage } = useMelioIntl();
  const value = useGetTextFromValues(values);

  const onClearFilter = () => {
    onDeleteFilter();
  };

  return (
    <Box
      as="li"
      role="listitem"
      data-testid={`filter-chip-item-${id}`}
      aria-label={formatMessage('activities.payDashboard.filterChip.text.ariaLabelPrefix', {
        label,
        filters: values.join(', '),
      })}
      listStyleType="none"
    >
      <Tag
        removeButtonProps={{
          onClick: onClearFilter,
          'aria-label':
            deleteAriaLabel ??
            formatMessage('activities.payDashboard.filterChip.delete.ariaLabel', { filterName: label }),
        }}
      >
        <Text textStyle="body3" aria-hidden>
          {label}:
        </Text>
        <Text textStyle="body3Semi" aria-hidden>
          {value}
        </Text>
      </Tag>
    </Box>
  );
};
