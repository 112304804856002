import { useModelApi, UseModelApiProps } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClientApiClient, AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';

type QueryFN = (id: string) => ReturnType<typeof AccountingFirmClientApiClient.getAccountingFirmClient>;

export type UseGetAccountingFirmClientProps = UseModelApiProps<QueryFN, AccountingFirmClientExpanded> & {
  id?: string;
};

export const useAccountingFirmClient = (props: UseGetAccountingFirmClientProps) =>
  useModelApi<QueryFN, never, never, never>({
    ...props,
    queryKey: ['AccountingFirmClientApi'],
    queryFn: () => AccountingFirmClientApiClient.getAccountingFirmClient(props.id || ''),
  });
