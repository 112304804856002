import { _TableHeaderCell, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';
import { CombinedPaymentsToggle } from './CombinedPaymentsToggle';

export type BillDetailsHeaderCellProps = {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  arePaymentsCombined?: boolean;
  onToggleCombinedPayments: (isChecked: boolean) => void;
  isToggling?: boolean;
};
export const BillDetailsHeaderCell = forwardRef<BillDetailsHeaderCellProps, 'div'>(
  ({ isToggling, paymentIntentsWithDerivatives, arePaymentsCombined, onToggleCombinedPayments, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <_TableHeaderCell
        {...props}
        ref={ref}
        aria-label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.header')}
      >
        <Group width="full" variant="vertical" spacing="none">
          <Text textStyle="inline" color="inherit" shouldSupportEllipsis>
            {formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.header')}
          </Text>
          <CombinedPaymentsToggle
            isToggling={isToggling}
            paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
            arePaymentsCombined={arePaymentsCombined}
            onToggleCombinedPayments={onToggleCombinedPayments}
          />
        </Group>
      </_TableHeaderCell>
    );
  }
);

BillDetailsHeaderCell.displayName = 'BillDetailsHeaderCell';
