import { Box } from '@chakra-ui/react';
import { useVendorNameConstraints } from '@melio/ap-domain';
import { VendorDeliveryMethodsSection } from '@melio/ap-widgets';
import { Divider, Form, Group, SectionBanner, VisuallyHidden } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useEffect } from 'react';

import { FormFieldsGroup } from '../../../../../../../form-controls/FormFieldsGroup';
import { CountrySelect } from '../../../../../../fields';
import { InternationalVendorAddressFields } from '../../../../../../fields/InternationalVendorAddressFields/InternationalVendorAddressFields.widget';
import { useVendorForm } from '../../../../../../useVendorForm';
import {
  UnmanagedVendorFormMinimalFormFields,
  useUnmanagedVendorFormMinimalSchema,
} from '../../../../../../VendorDetailsBaseSchema';
import { AddVendorFormWidgetProps } from '../../../../types';
import { CompanyField } from '../../../CompanyField';
import { getCreateVendorPayload } from './utils';

export const AddUnmanagedVendorFormMinimal = ({
  onClearCompanyNameSearchField,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  deliveryMethods,
  onSelectCompanyName,
  onSubmit,
  onSubmissionStateChange,
  onDeliveryMethodChanged,
  shouldHideCompanyNameField,
  shouldShowDeliveryMethodsSection,
  shouldEnforceAddingDeliveryMethod,
  defaultValues,
  isSaving,
  titlesAs,
  isEmailRequired,
  emailRequiredMessage,
  ...props
}: AddVendorFormWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const isManaged = !!props.managed;
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const [shouldCollectVendorAddress] = usePartnerFeature('CollectVendorAddress', false);

  const {
    settings: {
      vendor: {
        createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
        forms: { shouldRequireEmail, shouldCollectCountry },
      },
    },
  } = useConfig();

  const form = useVendorForm<UnmanagedVendorFormMinimalFormFields>({
    inlineApiErrorCodes,
    bannerApiErrorCodes,
    onSubmit: (data, _e, target) =>
      onSubmit(
        getCreateVendorPayload({
          data,
          shouldCollectAddress: shouldCollectVendorAddress,
          shouldCollectCountry,
          deliveryMethods,
        }),
        target
      ),
    schema: useUnmanagedVendorFormMinimalSchema({
      showCompanyField: !shouldHideCompanyNameField,
      isManaged,
      isEmailRequired: shouldRequireEmail || isEmailRequired,
      emailRequiredMessage,
      shouldCollectCountry,
      shouldCollectVendorAddress,
    }),
    isSaving,
    defaultValues: {
      ...defaultValues,
      ...(shouldCollectCountry && !shouldCollectVendorAddress ? { countryCode: 'US' } : {}),
    },
    onSubmissionStateChange,
  });

  const { formProps, registerField, watch, setValue, reset, formState } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const companyNameInputProps = registerField('companyName');

  const { vendorNameBannerDescription, vendorNameConstraintType } = useVendorNameConstraints({
    vendorName: watch('companyName') ?? undefined,
    vendorNameFieldErrorType: companyNameInputProps?.error?.type,
    isManaged,
    isFormSubmitted: formState.isSubmitted,
    isFreeTextInput: !isSearchBusinessesInDirectoriesSupported,
  });

  const renderAddressFields = () => {
    if (shouldCollectVendorAddress) {
      return (
        <FormFieldsGroup label={formatMessage('widgets.vendors.addressDetailsGroup.title')} colSpan={2}>
          <InternationalVendorAddressFields<UnmanagedVendorFormMinimalFormFields>
            form={form}
            colSpan={2}
            label="activities.fundingSources.vendorDetailsForm.fields.line1.label"
          />
        </FormFieldsGroup>
      );
    }

    if (shouldCollectCountry) {
      return <CountrySelect {...registerField('countryCode')} isReadOnly />;
    }

    return null;
  };

  const businessFields = (
    <>
      {!shouldHideCompanyNameField && (
        <Form.ContentBox>
          <Group spacing="xs" variant="vertical">
            <CompanyField
              onSelectCompanyName={onSelectCompanyName}
              onClearSearchField={() => {
                setValue('companyName', null);
                onClearCompanyNameSearchField?.();
              }}
              {...companyNameInputProps}
              autoFocus
            />
            {vendorNameBannerDescription && (
              <SectionBanner
                data-testid={`company-name-suggestion-${vendorNameConstraintType}`}
                description={vendorNameBannerDescription}
              />
            )}
          </Group>
        </Form.ContentBox>
      )}
      <Form.TextField
        {...registerField('fullName')}
        labelProps={{ label: formatMessage('widgets.vendors.fullName.label') }}
        placeholder={formatMessage('widgets.vendors.fullName.placeholder')}
        autoFocus={shouldHideCompanyNameField}
      />
      {shouldCollectAccountNumberForUnmanagedVendor && (
        <Form.TextField
          data-private
          {...registerField('accountNumber')}
          labelProps={{
            label: formatMessage('widgets.vendors.accountNumber.label'),
            tooltipProps: {
              content: (
                <>
                  <VisuallyHidden>
                    {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')} tooltip
                  </VisuallyHidden>
                  {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')}
                </>
              ),
            },
          }}
          autoFocus={shouldHideCompanyNameField}
        />
      )}
      <Form.TextField
        {...registerField('email')}
        labelProps={{ label: formatMessage('widgets.vendors.email.label') }}
        placeholder={formatMessage('widgets.vendors.email.placeholder')}
      />
      <Form.PhoneField
        {...registerField('phone')}
        labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
        placeholder={formatMessage('widgets.vendors.phone.placeholder')}
      />
    </>
  );

  return (
    <>
      <Form
        rowGap="m"
        data-component="AddVendorFormWidget"
        {...props}
        {...formProps}
        columnGap={shouldCollectVendorAddress ? 0 : 'm'}
      >
        {shouldCollectVendorAddress ? (
          <FormFieldsGroup label={formatMessage('widgets.vendors.vendorDetailsGroup.title')} colSpan={1} spacingY="m">
            {businessFields}
          </FormFieldsGroup>
        ) : (
          businessFields
        )}
        {renderAddressFields()}
      </Form>
      {shouldShowDeliveryMethodsSection ? (
        <>
          <Box pt="s" pb="l">
            <Divider />
          </Box>
          <VendorDeliveryMethodsSection
            isSaving={isSaving}
            onDeliveryMethodChanged={(updatedMethods) => {
              onDeliveryMethodChanged?.(updatedMethods);
            }}
            shouldShowEmptyDeliveryMethodsError={formState.isSubmitted && shouldEnforceAddingDeliveryMethod}
          />
        </>
      ) : null}
    </>
  );
};

AddUnmanagedVendorFormMinimal.displayName = 'AddVendorFormWidget';
