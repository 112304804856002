import { CalendarLegendProps, Group, SectionBanner, Text, UseMelioFormResults } from '@melio/penny';
import {
  DeliveryMethod,
  FundingSource,
  PaymentCalendarCalculation,
  PaymentCalendarCalculationDates,
  Vendor,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { first, last } from 'lodash';

import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../../types';
import { DateField } from '../../../components/DateField';
import { AriaLive } from '../util/AriaLive';
import { FlowOfFunds } from './components/FlowOfFunds/FlowOfFunds';
import { useDeliveryDateTooltipText } from './useDeliveryDateTooltipText';

type DeliveryDateInputProps = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  paymentCalendarDates: PaymentCalendarCalculation['dates'] | undefined;
  selectedPaymentDates?: PaymentCalendarCalculationDates | null;
  vendor: Vendor | undefined;
  fundingSource: FundingSource | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  onChange: PaymentFlowOnChangeHandlers['onDeliveryDateChange'];
  isLoading: boolean;
};

export const DeliveryDateInput = (props: DeliveryDateInputProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    settings: {
      payment: {
        flowOfFundsIndicator,
        scheduling: { disableEditDeliveryDateForCards },
      },
    },
  } = useConfig();

  const {
    form,
    vendor,
    fundingSource,
    deliveryMethod,
    paymentCalendarDates,
    selectedPaymentDates,
    onChange,
    isLoading,
  } = props;
  const { registerField, getValues } = form;
  const [deliveryDate, scheduleDate, deliveryPreference] = getValues([
    'deliveryDate',
    'scheduleDate',
    'deliveryPreference',
  ]);

  const tooltipText = useDeliveryDateTooltipText({ vendor, deliveryMethod });

  const legendItems: CalendarLegendProps[] = [
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.today`),
      variant: 'today',
    },
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.secondary`),
      variant: 'secondary',
    },
    {
      label: formatMessage(`activities.paymentFlow.form.content.deliveryDate.legend.primary`),
      variant: 'primary',
    },
  ];

  const labelKey: MessageKey =
    deliveryMethod?.type === 'paper-check'
      ? 'activities.paymentFlow.form.content.deliveryDate.label.check'
      : 'activities.paymentFlow.form.content.deliveryDate.label';

  const debitText = formatMessage('activities.paymentFlow.form.content.deliveryDate.helperText', {
    debitDate: formatDate(scheduleDate, { dateStyle: 'medium' }),
  });

  const ariaLabel = formatMessage(
    deliveryMethod?.type === 'paper-check'
      ? 'activities.paymentFlow.form.content.deliveryDate.check.aria'
      : 'activities.paymentFlow.form.content.deliveryDate.aria',
    { debitAria: debitText }
  );

  const isFutureCardPayment = disableEditDeliveryDateForCards && fundingSource?.type === 'card';

  return (
    <Group variant="vertical" width="full" spacing="xs-s">
      <AriaLive shown={isFutureCardPayment}>
        <SectionBanner
          data-testid="future-card-payment-banner"
          variant="informative"
          description={formatMessage('activities.paymentFlow.form.content.deliveryDate.tooltip.disabledForCard')}
        />
      </AriaLive>
      <DateField
        {...registerField('deliveryDate')}
        labelProps={{
          label: formatMessage(labelKey),
          tooltipProps: tooltipText ? { content: tooltipText } : undefined,
        }}
        placeholder={formatMessage('activities.paymentFlow.form.content.deliveryDate.placeholder')}
        legendItems={legendItems}
        paymentCalendarDates={paymentCalendarDates}
        minDate={first(paymentCalendarDates)?.minDeliveryDate}
        maxDate={last(paymentCalendarDates)?.minDeliveryDate}
        isDisabled={(!isLoading && !paymentCalendarDates) || !!tooltipText}
        isReadOnly={isFutureCardPayment}
        isLoading={isLoading}
        value={deliveryDate}
        onChange={onChange}
        excludeHolidays
        hideClear
        showToday
        aria-label={ariaLabel}
        secondaryDateAriaLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.selectedDebit.aria')}
        selectedDateAriaLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.selected.aria')}
        currentDateAriaLabel={formatMessage('activities.paymentFlow.form.content.deliveryDate.today.aria')}
      />
      {!flowOfFundsIndicator.enabled && scheduleDate && (
        <Text textStyle="body2" color="global.neutral.800" data-testid="deliveryDate-debitDate-text">
          {debitText}
        </Text>
      )}
      {flowOfFundsIndicator.enabled && selectedPaymentDates && deliveryPreference && (
        <FlowOfFunds {...selectedPaymentDates} deliveryPreference={deliveryPreference} />
      )}
    </Group>
  );
};
