import {
  AvatarWithDetails,
  Button,
  Container,
  Group,
  Icon,
  Modal,
  SectionBanner,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PostSearchMatchingVendorResponseData, Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps, useEffect, useMemo } from 'react';

import { formatPhoneNumber, getFormattedVendorAddress } from './MidConfidenceMatchingModal.utils';

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen'> & {
  onClose: () => Promise<void>;
  onConfirm: () => Promise<void>;
  onDecline: () => Promise<void>;
  isSubmittingVendorRecommendationDecision: boolean;
  localVendor: Vendor;
  externalVendor: PostSearchMatchingVendorResponseData;
  analyticsFields?: {
    Flow: string;
    PageName: string;
    EntryPoint: string;
    localVendorId: string;
  };
};

export const MidConfidenceMatchingModal = ({
  isOpen,
  onClose,
  localVendor,
  externalVendor,
  onDecline,
  onConfirm,
  analyticsFields,
  isSubmittingVendorRecommendationDecision,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const localVendorAddress = useMemo(() => getFormattedVendorAddress(localVendor?.contact?.address), [localVendor]);
  const externalVendorAddress = useMemo(() => getFormattedVendorAddress(externalVendor?.address), [externalVendor]);

  useEffect(() => {
    if (isOpen) {
      track('Vendor', 'View', { ...analyticsFields, Intent: 'match-vendor-to-network' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, analyticsFields?.localVendorId, isOpen]);

  const handleClose = () => {
    void onClose();
    track('Vendor', 'Click', { ...analyticsFields, Intent: 'skip', Cta: 'close' });
  };

  const handleConfirm = () => {
    void onConfirm();
    track('Vendor', 'Click', {
      ...analyticsFields,
      Intent: 'match-vendor-to-network',
      Cta: 'use-verified-vendor-record',
    });
  };

  const handleDecline = () => {
    void onDecline();
    track('Vendor', 'Click', {
      ...analyticsFields,
      Intent: 'stay-with-the-current-record',
      Cta: 'keep-existing-record',
    });
  };

  return (
    <Modal
      data-testid="mid-confidence-matching-modal"
      isOpen={isOpen}
      onClose={handleClose}
      header={formatMessage('widgets.midConfidenceMatchingModal.title')}
      size="medium"
    >
      <Group variant="vertical" spacing="m">
        <Text textStyle="body3">
          {formatMessage('widgets.midConfidenceMatchingModal.subtitle', { vendorName: localVendor.name })}
        </Text>
        <SectionBanner
          variant="brand"
          title={formatMessage('widgets.midConfidenceMatchingModal.banner.title')}
          description={formatMessage('widgets.midConfidenceMatchingModal.banner.subtitle', {
            externalVendorName: externalVendor?.companyName || '',
          })}
          leftElement={<Icon type="verified" color="brand" />}
        />
        <Group variant={isMobile ? 'vertical' : 'horizontal'} spacing="m" width="full">
          <Container
            border="regular"
            paddingX="m"
            paddingY="s"
            width={isMobile ? 'full' : 'fit-content'}
            style={{ flex: 1, order: isMobile ? 2 : undefined }}
          >
            <Group alignItems="stretch" variant="vertical" spacing="l">
              <Group variant="vertical" spacing="m">
                <Text color="semantic.text.secondary" textStyle="body3Semi">
                  {formatMessage('widgets.midConfidenceMatchingModal.existingVendor')}
                </Text>
                <AvatarWithDetails
                  data-testid="local-vendor-avatar-details"
                  mainLabelProps={{
                    label: localVendor.name,
                  }}
                  avatarProps={{ name: localVendor.name }}
                />
                <Group variant="vertical" spacing="none" width="full">
                  <Text
                    data-testid="local-vendor-address"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {localVendorAddress || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="local-vendor-email"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {localVendor?.contact?.email || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="local-vendor-contact-name"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {localVendor?.contact?.name || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="local-vendor-phone"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {formatPhoneNumber(localVendor?.contact?.phoneNumber) || '\u00A0'}
                  </Text>
                </Group>
              </Group>
              <Button
                data-testid="keep-existing-vendor"
                variant="secondary"
                label={formatMessage('widgets.midConfidenceMatchingModal.keepExisting')}
                onClick={handleDecline}
                isLoading={isSubmittingVendorRecommendationDecision}
                isFullWidth={isMobile}
              />
            </Group>
          </Container>
          <Container
            border="regular"
            paddingX="m"
            paddingY="s"
            width={isMobile ? 'full' : 'fit-content'}
            style={{ flex: 1, order: isMobile ? 1 : undefined }}
          >
            <Group alignItems="stretch" variant="vertical" spacing="l">
              <Group variant="vertical" spacing="m">
                <Text color="semantic.text.secondary" textStyle="body3Semi">
                  {formatMessage('widgets.midConfidenceMatchingModal.verifierVendor')}
                </Text>
                <AvatarWithDetails
                  data-testid="external-vendor-avatar-details"
                  mainLabelProps={{
                    label: externalVendor?.companyName || '',
                  }}
                  avatarProps={{
                    name: externalVendor?.companyName || '',
                    badge: <Icon color="brand" size="small" type="verified" />,
                  }}
                />
                <Group variant="vertical" spacing="none" width="full">
                  <Text
                    data-testid="external-vendor-address"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {externalVendorAddress || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="external-vendor-email"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {externalVendor?.email || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="external-vendor-contact-name"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {externalVendor?.contactName || '\u00A0'}
                  </Text>
                  <Text
                    data-testid="external-vendor-phone"
                    color="global.neutral.800"
                    textStyle="body3"
                    shouldSupportEllipsis
                  >
                    {formatPhoneNumber(externalVendor?.phone) || '\u00A0'}
                  </Text>
                </Group>
              </Group>
              <Group variant="vertical" spacing="xxs" width="full">
                <Button
                  data-testid="use-verified-vendor"
                  variant="primary"
                  label={formatMessage('widgets.midConfidenceMatchingModal.useVerified')}
                  onClick={handleConfirm}
                  isLoading={isSubmittingVendorRecommendationDecision}
                  isFullWidth={isMobile}
                />
                <Text color="global.neutral.800" textStyle="body4">
                  {formatMessage('widgets.midConfidenceMatchingModal.useVerified.hint')}
                </Text>
              </Group>
            </Group>
          </Container>
        </Group>
      </Group>
    </Modal>
  );
};
