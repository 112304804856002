import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { PayDashboardTabs } from '@melio/ap-activities/src/components/PayDashboard/types';
import {
  Collapse,
  Container,
  Group,
  Icon,
  Image,
  NakedButton,
  NavigationItem,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRouter } from '@/hooks/router.hooks';
import { useIsArEnabled } from '@/hooks/useIsArEnabled';

export const EmbeddedNavigation = ({ navLogoIcon }: { navLogoIcon?: string }) => {
  const navItems = useNavigationItems();
  const { isExtraSmallScreen } = useBreakpoint();
  const { formatMessage } = useMelioIntl();
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);

  if (isExtraSmallScreen) {
    const selectedItem = navItems.find((navItem) => navItem.isSelected);
    return (
      <Box
        paddingX="s"
        paddingY={navLogoIcon ? 's' : 'xs'}
        backgroundColor="white"
        overflow="initial"
        borderBottom={navLogoIcon ? '1px solid' : undefined}
        borderColor={navLogoIcon ? 'global.neutral.400' : undefined}
      >
        <Container width="full" onClick={() => setIsMobileExpanded(!isMobileExpanded)} overflow="initial">
          <Group
            spacing="s"
            alignItems="center"
            as="button"
            aria-expanded={isMobileExpanded}
            aria-label={formatMessage('activities.payDashboard.navigation.ariaLabel', {
              navigationLabel: selectedItem?.label,
            })}
          >
            <Icon
              type={isMobileExpanded ? 'close' : 'menu'}
              size={navLogoIcon ? 'large' : 'small'}
              color={navLogoIcon ? undefined : 'brand'}
            />

            {navLogoIcon ? (
              <Box position="absolute" left="50%" transform="translateX(-50%)">
                <Image src={navLogoIcon} alt="logo" />
              </Box>
            ) : (
              <Text textStyle="body2Semi" color="global.brand.700">
                {selectedItem?.label}
              </Text>
            )}
          </Group>
          <Collapse in={isMobileExpanded}>
            <Container paddingX="xs" paddingTop="s" overflow="initial">
              <Group variant="vertical" as="ul">
                {navItems.map((navItem) => (
                  <Container paddingY="xxs" key={navItem.href} overflow="initial" as="li">
                    <NakedButton
                      label={navItem.label}
                      onClick={() => {
                        setIsMobileExpanded(false);
                        navItem.onClick();
                      }}
                      aria-current={navItem.isSelected ? 'true' : undefined}
                    />
                  </Container>
                ))}
              </Group>
            </Container>
          </Collapse>
        </Container>
      </Box>
    );
  }

  return (
    <Container paddingBottom="xl" backgroundColor="white" overflow="initial">
      <Group spacing="xxs" as="ul">
        {navLogoIcon && (
          <Box alignSelf="center" marginInlineEnd="xs">
            <Image src={navLogoIcon} alt="logo" />
          </Box>
        )}
        {navItems.map((navItem) => (
          <Group as="li" key={navItem.href}>
            <NavigationItem
              isSelected={navItem.isSelected}
              aria-current={navItem.isSelected ? 'true' : undefined}
              onClick={(e) => {
                e?.preventDefault();
                e?.stopPropagation();
                navItem.onClick();
              }}
              aria-label={navItem.label}
              data-testid={navItem.id}
            >
              {navItem.label}
            </NavigationItem>
          </Group>
        ))}
      </Group>
    </Container>
  );
};

type NavItem = {
  href: string;
  isSelected: boolean;
  label: string;
  onClick: () => void;
  id: string;
};

const useNavigationItems = (): NavItem[] => {
  const { formatMessage } = useMelioIntl();
  const match = useMatch({ path: `:pathName/*` });
  const { navigateToSettingsPage, navigateToTab, goToArDashboard } = useRouter();
  const { track } = useAnalytics();
  const { isArEnabled } = useIsArEnabled();

  const navItems = [
    {
      href: 'pay-dashboard',
      isSelected: match?.params.pathName === 'pay-dashboard',
      label: formatMessage('app.navigation.pay'),
      onClick: () => {
        track('Dashboard', 'Click', { Cta: 'Pay' });
        navigateToTab(PayDashboardTabs.Vendors);
      },
      id: 'dashboard-nav-pay-dashboard',
    },
    isArEnabled && {
      href: 'ar-dashboard',
      isSelected: match?.params.pathName === 'ar',
      label: formatMessage('app.navigation.getPaid'),
      onClick: () => {
        track('Dashboard', 'Click', { Cta: formatMessage('app.navigation.getPaid') });
        goToArDashboard();
      },
      id: 'dashboard-nav-ar',
    },
    {
      href: 'settings',
      isSelected: match?.params.pathName === 'settings',
      label: formatMessage('app.navigation.settings'),
      onClick: () => {
        track('Dashboard', 'Click', { Cta: 'Settings' });
        navigateToSettingsPage();
      },
      id: 'dashboard-nav-settings',
    },
  ];

  return navItems.filter((navItem): navItem is NavItem => !!navItem);
};
