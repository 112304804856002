import { Card, Container, Group, Icon, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

export function NotSelected(props: { onBankClick: VoidFunction; onPaperCheckClick: VoidFunction }) {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();

  return (
    <Group data-testid="delivery-method-empty-state" variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}>
      <Card
        data-testid="bank-account-empty-state"
        paddingX="s"
        paddingY="s"
        role="button"
        variant="flat"
        onClick={() => {
          track('DeliveryMethod', 'Click', {
            Intent: 'add-ach-details',
            Cta: 'add-method',
          });
          props.onBankClick();
        }}
        aria-haspopup="dialog"
        aria-label={formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.aria')}
      >
        <Group variant="vertical" spacing="xs">
          <Group variant="horizontal" spacing="xs">
            <Icon type="bank-out" aria-hidden="true" />
            <Group.Item data-testid="mobile-tourtip-target" />
          </Group>
          <Group variant="vertical" spacing="xxs">
            <Text as="span" textStyle="body3Semi">
              {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.title')}
            </Text>
            <Text as="span" textStyle="body4">
              {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.bank.subtitle')}
            </Text>
            <Container paddingY="xxs">
              <Text as="span" textStyle="body4Semi" color="global.brand.700">
                {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.addMethod.label')}
              </Text>
            </Container>
          </Group>
        </Group>
      </Card>
      <Card
        data-testid="paper-check-empty-state"
        paddingX="s"
        paddingY="s"
        role="button"
        variant="flat"
        onClick={() => {
          track('DeliveryMethod', 'Click', {
            Intent: 'add-check-address',
            Cta: 'add-method',
          });
          props.onPaperCheckClick();
        }}
        aria-haspopup="dialog"
        aria-label={formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.paper-check.aria')}
      >
        <Group variant="vertical" spacing="xs">
          <Icon type="paper-check" aria-hidden="true" />
          <Group variant="vertical" spacing="xxs">
            <Text as="span" textStyle="body3Semi">
              {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.paper-check.title')}
            </Text>
            <Text as="span" textStyle="body4">
              {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.paper-check.subtitle')}
            </Text>
            <Container paddingY="xxs">
              <Text as="span" textStyle="body4Semi" color="global.brand.700">
                {formatMessage('activities.paymentFlow.form.content.deliveryMethod.empty-state.addMethod.label')}
              </Text>
            </Container>
          </Group>
        </Group>
      </Card>
    </Group>
  );
}
