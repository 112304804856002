import { Group, Modal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  vendorCount: number;
  onSubmit: () => void;
  onCancel: () => void;
};

export const ContinueWithoutLinkingVendorsModal = ({ isOpen, vendorCount, onSubmit, onClose, onCancel }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Modal
      data-testid="continue-without-linking-vendors-modal"
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage('widgets.continueWithoutLinkingVendorsModal.title', { vendorCount })}
      primaryButton={{
        label: formatMessage('widgets.continueWithoutLinkingVendorsModal.buttons.primary'),
        onClick: onSubmit,
        variant: 'primary',
      }}
      secondaryButton={{
        label: formatMessage('widgets.continueWithoutLinkingVendorsModal.buttons.secondary'),
        onClick: onCancel,
        variant: 'tertiary',
      }}
    >
      <Group variant="vertical" spacing="m">
        <Text>{formatMessage('widgets.continueWithoutLinkingVendorsModal.description')}</Text>
      </Group>
    </Modal>
  );
};
