import { FundingSource, FundingSourceType } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { useBoolean } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';

export const useGoodsReceived = () => {
  const [goodsReceived, setGoodsReceived] = useState<boolean>(true);
  const [goodsReceivedFormWasFilled, setGoodsReceivedFormWasFilled] = useState(false);
  const [shouldOpenGoodsReceivedModal, openGoodsReceivedModal] = useBoolean(false);
  const { enabled: goodsReceivedModalEnabled, amountThreshold } =
    useConfig().settings.payment.scheduling.goodsReceivedModal;

  const [disableGoodsReceivedFundingSourcesTypes, setDisableGoodsReceivedFundingSourcesTypes] = useState<
    FundingSourceType[]
  >([]);

  useEffect(() => {
    setDisableGoodsReceivedFundingSourcesTypes(goodsReceived ? [] : [FundingSourceType.Card]);
  }, [goodsReceived]);

  const handleDoneGoodsReceived = (selectedGoodsReceived: boolean) => {
    setGoodsReceived(selectedGoodsReceived);
    setGoodsReceivedFormWasFilled(true);
    openGoodsReceivedModal.off();
  };

  const handleCloseGoodsReceived = (onClose?: VoidFunction) => () => {
    onClose?.();
    openGoodsReceivedModal.off();
  };

  const shouldRunGoodsReceived = useCallback(
    ({ type }: FundingSource, paymentAmount: number) =>
      goodsReceivedModalEnabled && !goodsReceivedFormWasFilled && paymentAmount > amountThreshold && type === 'card',
    [goodsReceivedFormWasFilled, goodsReceivedModalEnabled, amountThreshold]
  );

  const runGoodsReceivedFlow = () => {
    openGoodsReceivedModal.on();
  };

  return {
    goodsReceived,
    shouldOpenGoodsReceivedModal,
    shouldRunGoodsReceived,
    runGoodsReceivedFlow,
    disableGoodsReceivedFundingSourcesTypes,
    goodsReceivedFormWasFilled,
    goodsReceivedAmountThreshold: amountThreshold,
    handleDoneGoodsReceived,
    handleCloseGoodsReceived,
  };
};
