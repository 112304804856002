/* eslint-disable max-lines */
import { InboxItemBillTypeEnum, InboxItemPaymentRequestTypeEnum, useVendors } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import { addDays, endOfDay, endOfToday, startOfDay, startOfToday, subDays, subMonths } from 'date-fns';
import { useMemo } from 'react';

import { PayDashboardFilterType } from '../../types';
import { CalcQueryParamsData, CalcQueryParamsResult, TabFilter } from '../Filters/types';
import { BillsTabBillTypeFilter, BillsTabCreationDateFilterValues, BillsTabDueDateFilterOptions } from './types';

export const supportedSearchParamKeys = [
  'inboxItem.vendorId',
  'inboxItem.type',
  'inboxItem.creationDate',
  'inboxItem.dueDate',
] as const;

export type SupportedSearchParamKeysType = (typeof supportedSearchParamKeys)[number];

export const useBillsTabFilters = ({ filterContext }: { filterContext?: PayDashboardFilterType }) => {
  const { formatMessage } = useMelioIntl();

  const activeFilters = filterContext?.filters ?? {};
  const vendorsActiveFilterValues = activeFilters['inboxItem.vendorId'] ?? [];

  const { createDate } = useDateUtils('MM/dd/yyyy');

  const { data: vendors = [], isLoading: isFetchingVendors } = useVendors({
    enabled: !!vendorsActiveFilterValues.length,
    params: { search: { 'vendor.id': vendorsActiveFilterValues }, expand: 'none' },
  });

  return useMemo(() => {
    const billsTabFilters: TabFilter<SupportedSearchParamKeysType>[] = [
      {
        key: 'inboxItem.vendorId',
        hidden: true,
        label: formatMessage('activities.payDashboard.billsTab.filters.vendor.title'),
        type: 'multi',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          if (values.length) {
            return {
              filterQueryParams: { 'inboxItem.vendorId': values },
              queryParamsOptions: {
                inboxItemTypes: [InboxItemBillTypeEnum.Bill, InboxItemPaymentRequestTypeEnum.PaymentRequest],
              },
            };
          }
          return { filterQueryParams: {}, queryParamsOptions: {} };
        },
        options: [],
        calcOptions: (values: string[]): TabFilter<SupportedSearchParamKeysType>['options'] => {
          if (!isFetchingVendors && vendors.length) {
            return vendors.map((vendor) => {
              if (values.includes(vendor.id)) {
                return {
                  label: vendor.name,
                  value: vendor.id,
                };
              }
              return { label: '', value: '' };
            });
          }
          return [];
        },
      },
      {
        key: 'inboxItem.dueDate',
        label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.title'),
        type: 'single',
        options: [
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.any.label'),
            value: BillsTabDueDateFilterOptions.Any,
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.overdue.label'),
            value: BillsTabDueDateFilterOptions.Overdue,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.dueToday.label'),
            value: BillsTabDueDateFilterOptions.DueToday,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.next7Days.label'),
            value: BillsTabDueDateFilterOptions.Next7Days,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.dueDate.next30Days.label'),
            value: BillsTabDueDateFilterOptions.Next30Days,
          },
          {
            label: formatMessage('activities.payDashboard.filters.custom'),
            value: BillsTabDueDateFilterOptions.Custom,
            calcCustomUrlParams: (data: CalcQueryParamsData): string => {
              if (data?.customStartDate && data?.customEndDate) {
                return `${data.customStartDate}-${data.customEndDate}`;
              }
              return '';
            },
          },
        ],
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const startOfTodayDate = startOfToday();
          const endOfTodayDate = endOfToday();
          const startOfTodayTimestamp = startOfTodayDate.getTime();
          const endOfTodayTimestamp = endOfTodayDate.getTime();
          switch (values[0]) {
            case BillsTabDueDateFilterOptions.Overdue:
              return {
                filterQueryParams: {
                  'inboxItem.dueDate': {
                    $lt: startOfTodayTimestamp,
                  },
                },
              };
            case BillsTabDueDateFilterOptions.DueToday:
              return {
                filterQueryParams: {
                  'inboxItem.dueDate': {
                    $between: [startOfTodayTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            case BillsTabDueDateFilterOptions.Next7Days:
              return {
                filterQueryParams: {
                  'inboxItem.dueDate': {
                    $between: [startOfTodayTimestamp, addDays(startOfTodayTimestamp, 7).getTime()],
                  },
                },
              };
            case BillsTabDueDateFilterOptions.Next30Days:
              return {
                filterQueryParams: {
                  'inboxItem.dueDate': {
                    $between: [startOfTodayTimestamp, addDays(startOfTodayTimestamp, 30).getTime()],
                  },
                },
              };
            case BillsTabDueDateFilterOptions.Custom: {
              const datesRange = values[1]?.split('-');
              const dates = datesRange?.map((date) => createDate(date));
              const startDate = dates?.[0];
              const endDate = dates?.[1];
              const startDateTimestamp = startDate ? startOfDay(startDate).getTime() : undefined;
              const endDateTimestamp = endDate ? endOfDay(endDate).getTime() : undefined;
              if (startDateTimestamp && endDateTimestamp) {
                return {
                  filterQueryParams: {
                    'inboxItem.dueDate': {
                      $between: [startDateTimestamp, endDateTimestamp],
                    },
                  },
                };
              }
              return {};
            }
            default:
              return {};
          }
        },
      },
      {
        key: 'inboxItem.creationDate',
        label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.title'),
        type: 'single',
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const startOfTodayDate = startOfToday();
          const endOfTodayDate = endOfToday();
          const startOfTodayTimestamp = startOfTodayDate.getTime();
          const endOfTodayTimestamp = endOfTodayDate.getTime();
          switch (values[0]) {
            case BillsTabCreationDateFilterValues.Today:
              return {
                filterQueryParams: {
                  'inboxItem.createdAt': {
                    $between: [startOfTodayTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            case BillsTabCreationDateFilterValues.Last7Days: {
              const last7DaysTimestamp = subDays(startOfTodayDate, 6).getTime();
              return {
                filterQueryParams: {
                  'inboxItem.createdAt': {
                    $between: [last7DaysTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case BillsTabCreationDateFilterValues.Last30Days: {
              const last30DaysTimestamp = subDays(startOfTodayDate, 29).getTime();
              return {
                filterQueryParams: {
                  'inboxItem.createdAt': {
                    $between: [last30DaysTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case BillsTabCreationDateFilterValues.Last12Months: {
              const last12MonthsTimestamp = subMonths(endOfTodayDate, 12).getTime();
              return {
                filterQueryParams: {
                  'inboxItem.createdAt': {
                    $between: [last12MonthsTimestamp, endOfTodayTimestamp],
                  },
                },
              };
            }
            case BillsTabCreationDateFilterValues.Custom: {
              const datesRange = values[1]?.split('-');
              const dates = datesRange?.map((date) => createDate(date));
              const startDate = dates?.[0];
              const endDate = dates?.[1];
              const startDateTimestamp = startDate ? startOfDay(startDate).getTime() : undefined;
              const endDateTimestamp = endDate ? endOfDay(endDate).getTime() : undefined;
              if (startDateTimestamp && endDateTimestamp) {
                return {
                  filterQueryParams: {
                    'inboxItem.createdAt': {
                      $between: [startDateTimestamp, endDateTimestamp],
                    },
                  },
                };
              }
              return {};
            }
          }
          return {};
        },
        options: [
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.any'),
            value: 'all',
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.today'),
            value: 'today',
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.last7Days'),
            value: 'last7Days',
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.last30Days'),
            value: 'last30Days',
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.creationDate.last12Months'),
            value: 'last12Months',
          },

          {
            label: formatMessage('activities.payDashboard.filters.custom'),
            value: 'custom',
            calcCustomUrlParams: (data: CalcQueryParamsData): string => {
              if (data?.customStartDate && data?.customEndDate) {
                return `${data.customStartDate}-${data.customEndDate}`;
              }
              return '';
            },
          },
        ],
      },
      {
        key: 'inboxItem.type',
        label: formatMessage('activities.payDashboard.billsTab.filters.billType.title'),
        type: 'single',
        options: [
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.billType.all.label'),
            value: BillsTabBillTypeFilter.All,
            selectWhenNothingSelected: true,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.billType.unpaid.label'),
            value: BillsTabBillTypeFilter.Unpaid,
          },
          {
            label: formatMessage('activities.payDashboard.billsTab.filters.billType.review.label'),
            value: BillsTabBillTypeFilter.ForReview,
          },
        ],
        calcQueryParams: (values: string[]): CalcQueryParamsResult => {
          const syncedBillsOrigin = ['amazon-business', 'meta', 'google-adwords'];
          if (values.includes(BillsTabBillTypeFilter.Unpaid)) {
            return {
              filterQueryParams: {
                'inboxItem.origin': { $nin: syncedBillsOrigin },
                'inboxItem.balance': { $gt: 0 },
              },
              queryParamsOptions: { inboxItemTypes: [InboxItemBillTypeEnum.Bill] },
            };
          }
          if (values.includes(BillsTabBillTypeFilter.ForReview)) {
            return {
              filterQueryParams: {
                'inboxItem.origin': { $in: syncedBillsOrigin },
              },
              queryParamsOptions: {},
            };
          }
          return {};
        },
      },
    ];
    return billsTabFilters;
  }, [formatMessage, isFetchingVendors, vendors, createDate]);
};
