import { FormLayout, useMelioIntl } from '@melio/ar-domain';
import { Form, useMelioForm, UseMelioFormResults } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

export const Own25PercentField = {
  Yes: 'yes',
  No: 'no',
} as const;
export type Own25PercentField = (typeof Own25PercentField)[keyof typeof Own25PercentField];

export type CompanyOwnersFormFields = {
  own25Percent?: Own25PercentField;
  soleOwner?: boolean;
  noOwners?: boolean;
};

export type UseCompanyOwnersFormProps = {
  isSaving?: boolean;
  defaultValues?: Partial<CompanyOwnersFormFields>;
};

export const useCompanyOwnersForm = ({ defaultValues, isSaving }: UseCompanyOwnersFormProps) => {
  const companyOwnersForm = useMelioForm<CompanyOwnersFormFields>({
    defaultValues,
    isSaving,
    subscribeToDefaultValuesChanges: true,
    onSubmit: () => null,
  });

  const own25PercentFieldValue = companyOwnersForm.watch('own25Percent');
  const noOwnersFieldValue = companyOwnersForm.watch('noOwners');
  const isSoleOwner = companyOwnersForm.watch('soleOwner') && own25PercentFieldValue === 'yes';

  return useMemo(
    () => ({
      hasOwners: own25PercentFieldValue && (own25PercentFieldValue !== 'no' || !noOwnersFieldValue),
      isSoleOwner,
      noOwners: own25PercentFieldValue == 'no' && noOwnersFieldValue,
      companyOwnersForm,
      isInitialState: !own25PercentFieldValue,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [own25PercentFieldValue, noOwnersFieldValue, isSoleOwner, companyOwnersForm]
  );
};

type CompanyOwnersFormProps = {
  form: UseMelioFormResults<CompanyOwnersFormFields>;
  onCHange?: (type: 'noOwners' | 'soleOwner') => void;
};

export const CompanyOwnersForm = forwardRef<CompanyOwnersFormProps, 'form', false>(({ form, ...props }, ref) => {
  const own25PercentFieldValue = form.watch('own25Percent');
  const { formatMessage } = useMelioIntl();

  return (
    <Form {...form.formProps} data-component={CompanyOwnersForm.displayName} {...props} ref={ref}>
      <FormLayout>
        <FormLayout.SectionHeader
          title={formatMessage('ar.onboarding.activities.ownershipDetails.form.companyOwners.title')}
          shouldDisplayRequiredFields
        />
        <Form.RadioGroup
          {...form.registerField('own25Percent')}
          labelProps={{
            label: formatMessage(
              'ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.own25Percent.label'
            ),
          }}
          options={[
            {
              value: Own25PercentField.Yes,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.own25Percent.options.yes'
              ),
              ariaLabel: formatMessage(
                'ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.own25Percent.options.yes'
              ),
            },
            {
              value: Own25PercentField.No,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.own25Percent.options.no'
              ),
              ariaLabel: formatMessage(
                'ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.own25Percent.options.no'
              ),
            },
          ]}
        />
        <Form.Checkbox
          {...form.registerField('soleOwner')}
          label={formatMessage('ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.soleOwner.label')}
          isHidden={own25PercentFieldValue !== 'yes'}
        />
        <Form.Checkbox
          {...form.registerField('noOwners')}
          label={formatMessage('ar.onboarding.activities.ownershipDetails.form.companyOwners.fields.noOwners.label')}
          isHidden={own25PercentFieldValue !== 'no'}
        />
      </FormLayout>
    </Form>
  );
});
CompanyOwnersForm.displayName = 'CompanyOwnersForm';
