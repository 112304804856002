import { OnBoardingApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';
import { OwnerDetails } from './types';

const putOwnershipDetails = (
  _id: string,
  data: NonNullable<Parameters<typeof OnBoardingApiClient.putOwnershipDetails>[0]>
) => OnBoardingApiClient.putOwnershipDetails(data);

type QueryFN = typeof OnBoardingApiClient.getOwnershipDetails;
type UpdateFN = typeof putOwnershipDetails;

export type UseOwnershipDetailsProps = UseModelProps<QueryFN, UpdateFN, never, OwnerDetails[]>;

export const useOwnershipDetails = (props: UseOwnershipDetailsProps = {}) =>
  useModel<QueryFN, UpdateFN, never, never, OwnerDetails[]>({
    ...props,
    id: 'OnboardingApi',
    queryKey: ['OnboardingApi'],
    queryFn: OnBoardingApiClient.getOwnershipDetails,
    updateFn: putOwnershipDetails,
  });

export type OwnershipDetailsCollection = ReturnType<typeof useOwnershipDetails>;
