import { PublicPayorApiClient } from '@melio/ar-api-axios-client';

import { useModel, UseModelProps } from '../../api-client';

type UseGuestPayorVendorPaymentDetailsProps = Omit<
  UseModelProps<typeof PublicPayorApiClient.getVendorPaymentDetails>,
  'id'
>;

export const useGuestPayorVendorPaymentDetailsByPaymentRequestLink = ({
  paymentRequestLink,
  ...props
}: UseGuestPayorVendorPaymentDetailsProps & { paymentRequestLink?: string }) =>
  useModel({
    ...props,
    id: paymentRequestLink,
    queryKey: ['PublicPayorApi', 'VendorPaymentDetails', paymentRequestLink],
    queryFn: (paymentRequestLink: string) => PublicPayorApiClient.getVendorPaymentDetails(paymentRequestLink),
  });

export const useGuestPayorVendorPaymentDetailsByVendorHandle = ({
  vendorHandle,
  ...props
}: UseGuestPayorVendorPaymentDetailsProps & { vendorHandle?: string }) =>
  useModel({
    ...props,
    id: vendorHandle,
    queryKey: ['PublicPayorApi', 'VendorPaymentDetails', undefined, vendorHandle],
    queryFn: (vendorHandle: string) => PublicPayorApiClient.getVendorPaymentDetails(undefined, vendorHandle),
  });
