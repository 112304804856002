import { Container, Group, useBreakpoint } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useEffect } from 'react';

import { ConversionUtils } from '../../../../../utils/ConversionUtils';
import { OverviewErrorState, OverviewHeader, OverviewItem } from './components';
import { useDashboardOverview } from './useDashboardOverview';

export const Overview = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();

  const isVerticalView = isExtraSmallScreen || isSmallScreen;

  const { data, error, isFetching, refetch, onScopeFilterChange, scope } = useDashboardOverview();

  useEffect(() => {
    if (data) {
      track('Organization', 'View');
    }
  }, [track, data]);

  const paymentsCountChangeRatio = data?.comparedOverview.paymentsCount
    ? (data.overview.paymentsCount - data.comparedOverview.paymentsCount) / data.comparedOverview.paymentsCount
    : undefined;

  const tpvChangeRatio = data?.comparedOverview.totalPaymentsVolume
    ? (data.overview.totalPaymentsVolume - data.comparedOverview.totalPaymentsVolume) /
      data.comparedOverview.totalPaymentsVolume
    : undefined;

  const activeClientsChangeRatio = data?.comparedOverview.activeClientsCount
    ? (data.overview.activeClientsCount - data.comparedOverview.activeClientsCount) /
      data.comparedOverview.activeClientsCount
    : undefined;

  const handleRetryClick = () => {
    track('Organization', 'Click', {
      Cta: formatMessage('activities.accountants.firmDashboard.v2.overview.error.tryAgain.button.label'),
      Intent: 'overview-retry',
    });
    void refetch();
  };

  setAnalyticsProperties({
    OverviewStats: {
      Scope: scope,
      ActiveClients: data?.overview.activeClientsCount,
      ActiveClientsRef: data?.comparedOverview.activeClientsCount,
      ActiveClientsRefChangeRatio: activeClientsChangeRatio,
      PaymentsCount: data?.overview.paymentsCount,
      PaymentsCountRef: data?.comparedOverview.paymentsCount,
      PaymentsCountRefChangeRatio: paymentsCountChangeRatio,
      TotalPaymentsVolume: data?.overview.totalPaymentsVolume,
      TotalPaymentsVolumeRef: data?.comparedOverview.totalPaymentsVolume,
      TotalPaymentsVolumeRefChangeRatio: tpvChangeRatio,
      StartDate: data?.timeframe.startDate,
      EndDate: data?.timeframe.endDate,
      StartDateRef: data?.timeframe.comparedPeriodStartDate,
      EndDateRef: data?.timeframe.comparedPeriodEndDate,
    },
  });

  return (
    <Container height="full" data-testid="clients-overview">
      <Group variant="vertical" spacing={error ? 'xs' : isVerticalView ? 'm' : 'xxl'}>
        <OverviewHeader
          isFetching={isFetching}
          onScopeFilterChange={onScopeFilterChange}
          scope={scope}
          isError={!!error}
        />
        <Group spacing="m" variant="horizontal">
          {error ? (
            <OverviewErrorState onTryAgain={handleRetryClick} />
          ) : (
            <Group
              justifyContent="space-between"
              width="full"
              hasDivider={!isVerticalView}
              alignItems="stretch"
              variant={isVerticalView ? 'vertical' : 'horizontal'}
              spacing={isVerticalView ? 'm' : undefined}
            >
              <Group.Item basis="30%">
                <OverviewItem
                  isEmpty={data?.overview.activeClientsCount === 0}
                  isLoading={isFetching}
                  value={data?.overview.activeClientsCount.toString()}
                  label={formatMessage(
                    'activities.accountants.firmDashboard.v2.overview.item.activeClientsCount.label'
                  )}
                  tooltipContent={formatMessage(
                    'activities.accountants.firmDashboard.v2.overview.item.activeClientsCount.tooltip'
                  )}
                  dataTestId="clients-overview-active-clients-count"
                />
              </Group.Item>
              <Group.Item basis="30%">
                <OverviewItem
                  isEmpty={data?.overview.paymentsCount === 0}
                  isLoading={isFetching}
                  value={data?.overview.paymentsCount.toString()}
                  label={formatMessage('activities.accountants.firmDashboard.v2.overview.item.paymentsCount.label')}
                  changeRatio={paymentsCountChangeRatio}
                  dataTestId="clients-overview-payments-count"
                />
              </Group.Item>
              <Group.Item basis="30%">
                <OverviewItem
                  isEmpty={data?.overview.totalPaymentsVolume === 0}
                  isLoading={isFetching}
                  value={
                    data?.overview.totalPaymentsVolume !== undefined
                      ? formatCurrency(ConversionUtils.fromCentsToDollars(data.overview.totalPaymentsVolume), 'USD')
                      : undefined
                  }
                  label={formatMessage(
                    'activities.accountants.firmDashboard.v2.overview.item.totalPaymentVolume.label'
                  )}
                  changeRatio={tpvChangeRatio}
                  dataTestId="clients-overview-total-payments-volume"
                />
              </Group.Item>
            </Group>
          )}
        </Group>
      </Group>
    </Container>
  );
});
