import { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { Buffer } from 'buffer';
import { PaymentRequestSelectCompanyActivity } from '@melio/ap-activities';
import { useGuestPayorPaymentIntent, useGuestPayorSetup } from '@melio/ar-domain';
import { ExternalLayout } from '@melio/penny';
import { useAccount, useOrganizations, usePaymentRequest } from '@melio/platform-api';

import { useRouter } from '../../hooks/router.hooks';
import { useSwitchOrganization } from '../../hooks/useSwitchOrganization.hooks';
import { PayDashboardTabs } from '../../types/payDashboard.types';

type DecodedPayload = {
  vendorDetails?: { name?: string };
  paymentRequestDetails?: { ref?: string };
};

const decodePayload = (payload: string | null): DecodedPayload | undefined => {
  try {
    return payload ? (JSON.parse(Buffer.from(payload, 'base64').toString('utf-8')) as DecodedPayload) : undefined;
  } catch {
    return undefined;
  }
};

const PAYLOAD_QUERY_PARAM_NAME = 'payload';

type PaymentRequestEntryPointScreenProps = { paymentRequestId: string };

export const PaymentRequestEntryPointScreen = ({ paymentRequestId }: PaymentRequestEntryPointScreenProps) => {
  const [userSelectedOrganizationId, setUserSelectedOrganizationId] = useState<string>();
  const { goToPayDashboardTabLinkAndRefresh } = useRouter();

  const { setup } = useGuestPayorSetup();
  const { switchOrganization } = useSwitchOrganization();
  const { data: account, isLoading: isLoadingAccount } = useAccount({ id: 'me' });
  const { data: organizations, isLoading: isLoadingOrganizations } = useOrganizations();
  const { data: paymentRequest, isLoading: isLoadingPaymentRequest } = usePaymentRequest({ id: paymentRequestId });

  const [queryParams] = useSearchParams();
  const payload = queryParams.get(PAYLOAD_QUERY_PARAM_NAME);

  const decodedPayload = decodePayload(payload);
  const vendorName = decodedPayload?.vendorDetails?.name;
  const paymentRequestRef = decodedPayload?.paymentRequestDetails?.ref as string;

  const { createGuestPaymentIntent } = useGuestPayorPaymentIntent();

  const isSingleOrgUser = organizations?.length === 1;
  const autoSelectedOrganizationId = isSingleOrgUser ? organizations?.[0].id : paymentRequest?.organizationId;

  const selectedOrganizationId = autoSelectedOrganizationId ?? userSelectedOrganizationId;

  const redirectToPaymentRequestInTheDashboard = () =>
    goToPayDashboardTabLinkAndRefresh(PayDashboardTabs.Inbox, paymentRequestId);

  const handleOrganizationSelected = async () => {
    const shouldSwitchOrganization = selectedOrganizationId !== account?.organizationId;
    if (shouldSwitchOrganization) {
      await switchOrganization({
        organizationId: selectedOrganizationId as string,
        isAccountingFirm: false,
        skipPayDashboardRedirect: true,
      });
    }

    const { data } = await createGuestPaymentIntent({ paymentRequestLink: paymentRequestRef });
    await setup({ guestPaymentIntentId: data.guestPaymentIntentId });

    redirectToPaymentRequestInTheDashboard();
  };

  useEffect(() => {
    if (selectedOrganizationId) {
      handleOrganizationSelected();
    }
    // selectedOrganizationId is the only dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  const isPaymentRequestFetchedSuccessfully = !!paymentRequest;

  const shouldDisplayLoader =
    isLoadingOrganizations ||
    isLoadingPaymentRequest ||
    isLoadingAccount ||
    selectedOrganizationId ||
    isPaymentRequestFetchedSuccessfully;

  if (shouldDisplayLoader) {
    return <ExternalLayout isLoading />;
  }

  if (!organizations?.length || !vendorName || !paymentRequestRef) {
    return <Navigate to="/404" />;
  }

  return (
    <PaymentRequestSelectCompanyActivity
      organizations={organizations}
      vendorName={vendorName}
      selectOrganization={(orgId) => setUserSelectedOrganizationId(orgId)}
      paymentRequestRef={paymentRequestRef}
    />
  );
};

PaymentRequestEntryPointScreen.displayName = 'PaymentRequestEntryPointScreen';
