import { useNonEntityMutation } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import {
  AccountantsFirmClientApiClient,
  PatchFirmClientRequest,
  PatchFirmClientResponse,
} from '@melio/platform-api-axios-client';

export const updateFunction = ({ id, data }: { id: string; data: PatchFirmClientRequest }) =>
  // TODO: change to v2 when available
  AccountantsFirmClientApiClient.patchAccountantFirmClient(id, data);
type UpdateFunction = typeof updateFunction;

export const usePatchAccountingFirmClient = () => {
  const updateMutation = useNonEntityMutation<UpdateFunction, Parameters<UpdateFunction>[0], PatchFirmClientResponse>(
    updateFunction,
    ['AccountantsFirmClientApi', 'collection', 'default']
  );

  return updateMutation;
};
