import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';
import { Step } from 'react-joyride';

import { createTourIdSelector } from '../../../../../utils';
import { useIsMobile } from '../../../../../utils/viewport-utils/useIsMobile';
import { MigrationUserTourStep } from './MigrationUserTourStep';

export const UseNewDashboardMigratedTourSteps = (): { steps: Step[]; numberOfSteps: number } => {
  const isMobile = useIsMobile();
  const numberOfSteps = isMobile ? 5 : 6;
  const { formatMessage } = useMelioIntl();
  const {
    settings: { migrationUserTour },
  } = useConfig();

  const steps: Array<Step> = useMemo(() => {
    const step1Title = formatMessage('activities.migrationUserDashboardTour.step.step1.title');
    const firstStep: Step = {
      disableBeacon: true,
      disableOverlayClose: true,
      target: createTourIdSelector('pay-dashboard-tab-vendors'),
      title: step1Title,
      placement: 'bottom',
      content: (
        <MigrationUserTourStep
          title={step1Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step1.description')}
          animationSrc={migrationUserTour.stepsAnimations.step1}
          firstAnimationSrc={migrationUserTour.stepImages.step1}
          stepNumber={1}
        />
      ),
    };
    const step2Title = formatMessage('activities.migrationUserDashboardTour.step.step2.title');
    const secondStep: Step = {
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'bottom',
      target: createTourIdSelector('pay-dashboard-tab-bills'),
      title: step2Title,
      content: (
        <MigrationUserTourStep
          title={step2Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step2.description')}
          animationSrc={migrationUserTour.stepsAnimations.step2}
          firstAnimationSrc={migrationUserTour.stepImages.step2}
          stepNumber={2}
        />
      ),
    };
    const step3Title = formatMessage('activities.migrationUserDashboardTour.step.step3.title');
    const thirdStep: Step = {
      disableBeacon: true,
      disableOverlayClose: true,
      placement: isMobile ? 'bottom' : 'bottom-start',
      //in table we can't add more properties that why we need to use this selector
      target: isMobile
        ? '[data-testid^="table-row-vendor_"]:first-of-type'
        : '[data-component="TableRow"]:first-of-type',
      title: step3Title,
      content: (
        <MigrationUserTourStep
          title={step3Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step3.description')}
          animationSrc={migrationUserTour.stepsAnimations.step3}
          firstAnimationSrc={migrationUserTour.stepImages.step3}
          stepNumber={3}
        />
      ),
    };
    const step4Title = formatMessage('activities.migrationUserDashboardTour.step.step4.title');
    const forthStep: Step = {
      disableBeacon: true,
      //in table we can't add more properties that why we need to use this selector
      target: '[data-component="TableRow"]:first-of-type',
      placement: 'bottom-end',
      disableOverlayClose: true,
      title: step4Title,
      content: (
        <MigrationUserTourStep
          title={step4Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step4.description')}
          animationSrc={migrationUserTour.stepsAnimations.step4}
          firstAnimationSrc={migrationUserTour.stepImages.step4}
          stepNumber={4}
        />
      ),
    };
    const step5Title = formatMessage('activities.migrationUserDashboardTour.step.step5.title');
    const fifthStep: Step = {
      disableBeacon: true,
      disableOverlayClose: true,
      //in table we can't add more properties that why we need to use this selector
      target: createTourIdSelector('new-dashboard-pay-button'),
      title: step5Title,
      placement: 'bottom-end',
      content: (
        <MigrationUserTourStep
          title={step5Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step5.description')}
          animationSrc={migrationUserTour.stepsAnimations.step5}
          firstAnimationSrc={migrationUserTour.stepImages.step5}
          stepNumber={5}
        />
      ),
    };
    const step6Title = formatMessage('activities.migrationUserDashboardTour.step.step6.title');
    const sixthStep: Step = {
      disableBeacon: true,
      disableOverlayClose: true,
      target: createTourIdSelector('pay-dashboard-tab-payments'),
      title: step6Title,
      placement: 'bottom',
      content: (
        <MigrationUserTourStep
          title={step6Title}
          description={formatMessage('activities.migrationUserDashboardTour.step.step6.description')}
          animationSrc={migrationUserTour.stepsAnimations.step6}
          firstAnimationSrc={migrationUserTour.stepImages.step6}
          stepNumber={6}
        />
      ),
    };
    if (isMobile) {
      return [firstStep, secondStep, thirdStep, fifthStep, sixthStep];
    }
    return [firstStep, secondStep, thirdStep, forthStep, fifthStep, sixthStep];
  }, [formatMessage, migrationUserTour.stepsAnimations, migrationUserTour.stepImages, isMobile]);

  return {
    steps,
    numberOfSteps,
  };
};
