/* eslint-disable no-restricted-imports */
import { Text } from '@melio/penny';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { EmptyState } from '../EmptyState/EmptyState';
import { SectionWrapper } from '../SectionWrapper';
import { useTodosEnabled } from './hooks';
import { TodosContent } from './TodosContent';

type Props = {
  client: AccountingFirmClientExpanded;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodosSection = ({ client, ...callbacks }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const totalCount: number = client.todos?.totalCount?.reduce((total, item) => total + item.count, 0) || 0;

  return (
    <SectionWrapper dataTestId="drawer-todos-section">
      <Text textStyle="heading3Semi">
        {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.Todos.semiHeader.title', {
          count: totalCount,
        })}
      </Text>
      {isTodosEnabled && client.todos?.data && totalCount > 0 ? (
        <TodosContent client={client} todos={client.todos?.data} {...callbacks} />
      ) : (
        <EmptyState
          dataTestId="drawer-todos-empty-state"
          text={formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.Todos.emptyState.label')}
        />
      )}
    </SectionWrapper>
  );
};
