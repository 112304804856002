import { ApiError } from '@melio/api-client';
import { SortingState } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import {
  AccountingFirmClient,
  PatchFirmClientRequest,
  PatchFirmClientResponse,
} from '@melio/platform-api-axios-client';

export type ClientData = AccountingFirmClient & { hasPermissions: boolean };
export type FirmClientsTableFilters = {
  clients: Array<ClientData>;
  shouldShowEmptyState: boolean;
  isLoading: boolean;
  error: ApiError<string> | null;
  filters: {
    searchText: string;
    showHiddenClients?: boolean;
  };
  pagination: {
    currentPage: number;
    pageSize: number;
    totalCount: number;
  };
  sorting?: SortingState;
  handlers: {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handlePageChange: (newPageNumber: number) => void;
    handleSortChange: (sortingState?: SortingState) => void;
    handleToggleHiddenClients: (toggled?: boolean) => void;
  };
  clientActions: {
    updateClient?: ({ id, data }: { id: string; data: PatchFirmClientRequest }) => Promise<PatchFirmClientResponse>;
  };
};

export enum SortableClientsTableColumns {
  CLIENT = 'client',
  ACCOUNT_STATUS = 'accountStatus',
  BILLS_DUE = 'billsDue',
}
