import { useIsMobile } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { Filters, OnSort, SortParams } from '../types';
import { FiltersToolBar } from './FiltersToolBar';
import { FiltersToolBarMobile } from './FiltersToolBar.mobile';

export type InvoiceTableToolbarProps = {
  isLoading?: boolean;
  onFilter: (filters: Filters) => void;
  onClearFilters: () => void;
  filters: Filters;
  search?: string;
  onSearch: (value: string) => void;
  onSort: OnSort;
  sortParams?: SortParams;
  shouldDisplayFilters?: boolean;
};

export const InvoiceTableToolbar = forwardRef<InvoiceTableToolbarProps>(
  (
    {
      onFilter,
      filters,
      onClearFilters,
      isLoading,
      search,
      onSearch,
      onSort,
      sortParams,
      shouldDisplayFilters = true,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();

    if (!shouldDisplayFilters) return null;

    return !isMobile ? (
      <FiltersToolBar
        data-component={InvoiceTableToolbar.displayName}
        selectedFilters={filters}
        onSelectFilters={onFilter}
        onClearFilters={onClearFilters}
        search={search}
        onSearch={onSearch}
        {...props}
        ref={ref}
      />
    ) : (
      <FiltersToolBarMobile
        data-component={InvoiceTableToolbar.displayName}
        selectedFilter={filters.invoiceStatus?.[0]}
        onSelectFilter={onFilter}
        search={search}
        onSearch={onSearch}
        onSort={onSort}
        sortParams={sortParams}
        {...props}
        ref={ref}
      />
    );
  }
);
InvoiceTableToolbar.displayName = 'InvoiceTableToolbar';
