/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  convertFxAmountToUsdAmount,
  getDMCurrency,
  isDeliveryPreferenceTypeFast,
  isFXCurrency,
  useInternationalFxFeature,
} from '@melio/ap-domain';
import { useMelioIntl } from '@melio/i18n-tools/src/useMelioIntl';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import {
  useCheckApprovalRequirement,
  useInternationalQuote,
  useOrgBillingFeeSettings,
  usePaymentIntent,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useSystemMessage } from '@melio/platform-utils';
import { isAfter } from 'date-fns';
import { useEffect } from 'react';

import { useInternationalCountryDisplayName } from '../../../utils/pay-flow/deliveryMethods';
import { useExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';
import { ReviewAndConfirmLoadingScreen, ReviewAndConfirmScreen } from './screens';
import { ReviewAndConfirmActivityProps } from './types';
import { usePaymentFeesBreakdown } from './utils';

export const ReviewAndConfirmActivity: React.VFC<ReviewAndConfirmActivityProps> = ({
  step,
  totalSteps,
  paymentIntentId,
  onEditAmount,
  onEditFundingSource,
  onEditRepaymentTerm,
  onEditDate,
  onEditDeliveryMethod,
  onEditMemoToVendor,
  onBack,
  onClose,
  onError,
  isEditPaymentFlow,
  onDone: _onDone,
  updatedFields,
  isLoadingButton,
  enableLateTag,
  hideMemoSection,
  hideFromSection,
  hideFeesSection: _hideFeesSection,
  remainingAmount,
  texts,
  isFinancingPaymentFlow,
  selectedRepaymentOption,
  analyticsProperties: _analyticsProperties,
}) => {
  const { data: basicPaymentIntent, isLoading: isPaymentIntentLoading } = usePaymentIntent({ id: paymentIntentId });
  const { track, createTrackHandler } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const { formatMessage } = useMelioIntl();
  const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);
  const deliveryMethod = paymentIntent?.deliveryMethod || undefined;
  const { countryDisplayName, isCountriesDetailsLoading } = useInternationalCountryDisplayName(deliveryMethod);
  const [isBillingFeeSettingsEnabled] = useDevFeature(FeatureFlags.BillingFees, false);
  const isFxPaymentEnabled = useInternationalFxFeature();
  const { data: orgBillingFeeSettings = [], isLoading: isBillingFeeSettingIsLoading } = useOrgBillingFeeSettings({
    enabled: isBillingFeeSettingsEnabled,
  });
  const currency = getDMCurrency(deliveryMethod);
  const isFxPayment = isFXCurrency(currency);
  const trackAndHandleClick = createTrackHandler<{
    PageName: 'review-and-confirm';
    Cta: 'back' | 'edit' | 'confirm-and-reschedule-payment' | 'exit';
  }>('Payment', 'Click');

  const deliveryPreference = (paymentIntent?.deliveryPreferenceOptions ?? []).find(
    (deliveryPreferenceOption) => deliveryPreferenceOption.type === paymentIntent?.selectedDeliveryPreferenceType
  );

  const { data: quote, isFetching: isQuoteFetching } = useInternationalQuote({
    enabled: isFxPaymentEnabled && isFxPayment && !!paymentIntent,
    foreignAmount: paymentIntent?.amountToPay ?? 0,
    foreignCurrency: currency,
    vendorId: paymentIntent?.vendorId ?? '',
    onError: () => {
      showMessage({
        type: 'error',
        title: formatMessage('activities.reviewAndConfirm.screens.reviewAndConfirm.toast.quoteError'),
      });
    },
  });

  const paymentAmountInUsd =
    quote &&
    convertFxAmountToUsdAmount({
      paymentAmount: paymentIntent?.amountToPay ?? 0,
      fxRate: quote,
      currency,
    });
  const {
    paymentFees,
    isLoading: isFeesLoading,
    error: feesBreakdownError,
  } = usePaymentFeesBreakdown(
    {
      fundingSource: paymentIntent?.fundingSource,
      deliveryMethod,
      paymentAmount: paymentAmountInUsd ?? paymentIntent?.amountToPay,
      deliveryPreference,
      paymentId: paymentIntent?.paymentId,
      isFinancing: !!paymentIntent?.financingEligibilityToken,
      billIds: paymentIntent?.billPayments?.map((billPayment) => billPayment.billId),
    },
    paymentIntent?.estimatedFees
  );

  useEffect(() => {
    if (feesBreakdownError) {
      onError(feesBreakdownError);
      onBack();
    }
  }, [feesBreakdownError, onBack, onError]);

  const { data: approvalRequirement } = useCheckApprovalRequirement({
    amount: paymentIntent?.amountToPay || 0,
    vendorId: paymentIntent?.vendorId || '',
    enabled: !!paymentIntent?.vendorId,
  });

  const isLoadingActivity =
    isBillingFeeSettingIsLoading ||
    isPaymentIntentLoading ||
    isCountriesDetailsLoading ||
    !approvalRequirement ||
    !paymentIntent ||
    isFeesLoading ||
    !deliveryPreference;

  const fxAnalyticsProperties = {
    Currency: currency,
    Amount: paymentIntent?.amountToPay,
    UsdAmount: paymentAmountInUsd,
  };

  useAnalyticsView('Payment', getShouldTrackViewEvent({ isLoadingActivity, isFxPayment, isQuoteFetching }), true, {
    PageName: 'review-and-confirm',
    ..._analyticsProperties,
    ...fxAnalyticsProperties,
  });

  if (isLoadingActivity) {
    return <ReviewAndConfirmLoadingScreen onBack={onBack} onClose={onClose} />;
  }

  const hasFastOption = deliveryPreference && isDeliveryPreferenceTypeFast(deliveryPreference.type);
  const dueDate = paymentIntent.billInfo.dueDate;
  const isDueDatePassed = dueDate ? isAfter(deliveryPreference.minDeliveryDate, dueDate) : false;
  const analyticsProperties = {
    IsSpecialDelivery: hasFastOption,
    SpecialDeliveryMethod: hasFastOption ? deliveryPreference?.type : null,
    ...fxAnalyticsProperties,
    ..._analyticsProperties,
  };

  const onDone = (fxQuoteToken?: string) => {
    track('Payment', 'Click', analyticsProperties);
    _onDone(fxQuoteToken);
  };

  const invoiceNumbers = paymentIntent.billPayments
    ? paymentIntent.billPayments?.map((billPayment) => billPayment.invoiceNumber)
    : [paymentIntent.billInfo?.invoice.number]; //TODO: remove billInfo with the rest of migration removal

  const isNoFees = !paymentFees || paymentFees.length === 0;
  const isFxPaymentWithoutFees = isFxPayment && isNoFees;
  const hideFeesSection = _hideFeesSection || isFxPaymentWithoutFees;

  return (
    <ReviewAndConfirmScreen
      step={step}
      totalSteps={totalSteps}
      amount={paymentIntent.amountToPay || 0}
      fundingSource={paymentIntent.fundingSource!}
      deliveryMethod={deliveryMethod!}
      memoToVendor={paymentIntent.noteToVendor}
      invoiceNumbers={invoiceNumbers}
      vendorName={paymentIntent.billInfo.vendor.name}
      countryDisplayName={countryDisplayName}
      paymentFees={paymentFees || []}
      scheduledDate={deliveryPreference.effectiveScheduleDate}
      deliveryPreference={deliveryPreference}
      orgBillingFeeSettings={orgBillingFeeSettings}
      onEditAmount={onEditAmount ? () => trackAndHandleClick({ Cta: 'edit' }, onEditAmount) : undefined}
      onEditFundingSource={
        onEditFundingSource ? () => trackAndHandleClick({ Cta: 'edit' }, onEditFundingSource) : undefined
      }
      onEditRepaymentTerm={
        onEditRepaymentTerm ? () => trackAndHandleClick({ Cta: 'edit' }, onEditRepaymentTerm) : undefined
      }
      onEditDate={onEditDate ? () => trackAndHandleClick({ Cta: 'edit' }, onEditDate) : undefined}
      onEditDeliveryMethod={
        onEditDeliveryMethod ? () => trackAndHandleClick({ Cta: 'edit' }, onEditDeliveryMethod) : undefined
      }
      onEditMemoToVendor={
        onEditMemoToVendor ? () => trackAndHandleClick({ Cta: 'edit' }, onEditMemoToVendor) : undefined
      }
      onBack={() => trackAndHandleClick({ Cta: 'back' }, onBack)}
      onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
      onDone={onDone}
      updatedFields={updatedFields}
      isLoadingButton={isLoadingButton}
      dueDate={dueDate}
      enableLateTag={enableLateTag && isDueDatePassed}
      hideMemoSection={hideMemoSection}
      hideFromSection={hideFromSection}
      hideFeesSection={hideFeesSection}
      remainingAmount={remainingAmount}
      isEditPaymentFlow={isEditPaymentFlow}
      texts={texts} // TODO: should be refactored with designed texts override solution
      isFinancingPaymentFlow={isFinancingPaymentFlow}
      selectedRepaymentOption={selectedRepaymentOption}
      approvalRequirementStatus={approvalRequirement.approvalRequirementStatus}
      vendorId={paymentIntent.billInfo.vendor.id}
      quote={quote}
      isQuoteFetching={isQuoteFetching}
    />
  );
};
ReviewAndConfirmActivity.displayName = 'ReviewAndConfirmActivity';

function getShouldTrackViewEvent({
  isLoadingActivity,
  isFxPayment,
  isQuoteFetching,
}: {
  isLoadingActivity: boolean;
  isFxPayment: boolean;
  isQuoteFetching: boolean;
}) {
  if (isLoadingActivity) {
    return false;
  }

  if (isFxPayment) {
    return !isQuoteFetching;
  }

  return true;
}
