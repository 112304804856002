import { useCustomersDashboard, UseCustomersDashboardProps, useSearchParams } from '@melio/ar-domain';
import { useMemo } from 'react';

import { UseCustomersFilteringParams } from '../types';

const PAGE_SIZE = 10;

export const useCustomersFiltering = ({ onError }: UseCustomersDashboardProps) => {
  const [searchParams, setSearchParams] = useSearchParams<UseCustomersFilteringParams>();

  const { searchTerm = '', sortBy = 'companyName', sortOrder = 'Asc' } = searchParams;

  const params: UseCustomersDashboardProps['params'] = {
    searchTerm: searchTerm || void 0,
    sort: { field: sortBy, order: sortOrder },
  };

  const { data, isFetching, isLoading, pagination } = useCustomersDashboard({
    params,
    onError,
    limit: PAGE_SIZE,
    pageNumber: searchParams.pageNumber ?? 1,
    onPageChange: (pageNumber: number) => setSearchParams((prev) => ({ ...prev, pageNumber })),
  });

  return {
    customers: useMemo(() => data ?? [], [data]),
    isLoading,
    isFetching,
    search: searchTerm,
    setParams: setSearchParams,
    pagination,
    sortParams: params.sort,
  };
};
