import { useEffect } from 'react';
import { BannersOptions, SingularConfig, singularSdk } from 'singular-sdk';
import { sanitizeId } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { usePartnerConfig } from '@/hooks/partners';

export const useSingular = () => {
  const { data: account } = useAccount({ id: 'me' });
  const { partnerConfig } = usePartnerConfig();
  const [enableMobileAppBanner] = useDevFeature(FeatureFlags.MobileAppBanner, false);
  const singularSdkConfig = partnerConfig.config.services.singular;
  const userId = account?.userId;

  useEffect(() => {
    if (!singularSdkConfig || !userId || singularSdk._isInitialized || !enableMobileAppBanner) {
      return;
    }

    // https://support.singular.net/hc/en-us/articles/360039991491-Singular-Website-SDK-Native-Integration
    const config = new SingularConfig(
      singularSdkConfig.sdkKey,
      singularSdkConfig.sdkSecret,
      singularSdkConfig.productId,
    );
    config.withCustomUserId(`${sanitizeId(userId)}`);

    // https://support.singular.net/hc/en-us/articles/11031683195163-How-to-Enable-Singular-Banners-Developers-Guide
    // Web-to-app is required if you want to track which ad network led the user to your site.
    const bannersOptions = new BannersOptions().withWebToAppSupport();
    config.withBannersSupport(bannersOptions);

    singularSdk.init(config);
    singularSdk.showBanner();

    return () => {
      try {
        singularSdk.hideBanner();
      } catch (e) {
        /* singular sdk has a bug */
      }
    };
  }, [singularSdkConfig, userId, enableMobileAppBanner]);
};
