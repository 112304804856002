import { useGuestPayorUserDetails } from '@melio/ar-domain';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { useEffect, useMemo } from 'react';

import { useGuestPaymentIntent, usePaymentRequestStatus } from '../../../hooks';
import { GuestPaymentIntentParams } from '../../../types';
import { generateDeepLinkToPaymentRequestPayDashboard } from '../../../utils';

export const useRedirectToPayDashboard = (guestPaymentIntentParams: GuestPaymentIntentParams, _isEnabled?: boolean) => {
  const { paymentRequestLink } = guestPaymentIntentParams;
  const { data: userDetails, isLoading: isUserDetailsLoading } = useGuestPayorUserDetails({ id: paymentRequestLink });

  const { isLoading: isGuestPaymentIntentLoading, data: guestPaymentIntentData } = useGuestPaymentIntent({
    guestPaymentIntentParams,
  });

  const { status, isLoading: isPaymentRequestStatusLoading } = usePaymentRequestStatus(guestPaymentIntentParams);
  const { shouldGenerateDeepLinkToPaymentRequestPayDashboard } = useConfig().settings.guestPaymentFlow;
  const { trackAsync } = useAnalytics();

  const isEnabled = useMemo(
    () =>
      _isEnabled &&
      status === 'unpaid' &&
      shouldGenerateDeepLinkToPaymentRequestPayDashboard &&
      userDetails?.userType === 'registered-and-matched' &&
      guestPaymentIntentData?.isPayable,
    [
      _isEnabled,
      status,
      shouldGenerateDeepLinkToPaymentRequestPayDashboard,
      userDetails?.userType,
      guestPaymentIntentData?.isPayable,
    ]
  );

  const dashboardUrl = useMemo(
    () =>
      isEnabled && guestPaymentIntentData?.paymentRequestId && guestPaymentIntentData.vendorPaymentDetails
        ? generateDeepLinkToPaymentRequestPayDashboard({
            paymentRequestId: guestPaymentIntentData.paymentRequestId,
            paymentRequestLink,
            vendorName: guestPaymentIntentData.vendorPaymentDetails.payeeDetails.companyName,
          })
        : null,
    [isEnabled, guestPaymentIntentData, paymentRequestLink]
  );

  useEffect(() => {
    if (dashboardUrl) {
      trackAsync('Connection', 'Status', {
        UserType: userDetails?.userType,
        PageName: undefined,
      }).finally(() => window.location.assign(dashboardUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardUrl]);

  if (!paymentRequestLink) {
    return { isRedirecting: false, isLoading: false };
  }

  return {
    isRedirecting: !!dashboardUrl,
    isLoading: isGuestPaymentIntentLoading || isUserDetailsLoading || isPaymentRequestStatusLoading,
  };
};
