import { isFieldReadonly as _isFieldReadonly } from '@melio/ap-domain';
import { FieldValues, Form, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Path, useWatch } from 'react-hook-form';

import { IdType, Residency, TaxIdType } from '../types';

export type ResidencyFieldsProps<T extends FieldValues = FieldValues> = {
  form: UseMelioFormResults<T>;
  usResidencyFieldName: Path<T>;
  taxIdTypeFieldName: Path<T>;
  taxIdNumberFieldName: Path<T>;
  idTypeFieldName: Path<T>;
  idNumberFieldName: Path<T>;
  shouldShowReadOnly?: boolean;
  shouldShowResidencyReadOnly?: boolean;
};

export const ResidencyFields = ({
  form,
  usResidencyFieldName,
  taxIdTypeFieldName,
  taxIdNumberFieldName,
  idTypeFieldName,
  idNumberFieldName,
  shouldShowReadOnly = false,
  shouldShowResidencyReadOnly = false,
}: ResidencyFieldsProps) => {
  const [usResidency, taxIdType] = useWatch({
    control: form.control,
    name: [usResidencyFieldName, taxIdTypeFieldName],
  }) as [Residency['usResidence'], TaxIdType];

  const { formatMessage } = useMelioIntl();
  const { setValue, unregister, resetField } = form;
  const isFieldReadonly = (fieldKey: string) =>
    _isFieldReadonly({
      form,
      fieldKey,
    });

  const residencyOption = [
    {
      mainLabelProps: {
        label: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.USResidency.usResidence.option'
        ),
      },
      value: 'usResidence',
    },
    {
      mainLabelProps: {
        label: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.USResidency.non.usResidence.option'
        ),
      },
      value: 'nonUsResidence',
    },
  ];

  const taxIdTypesOptions = [
    {
      value: TaxIdType.Ssn,
      label: formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdType.ssn.option'
      ),
      testId: TaxIdType.Ssn,
    },
    {
      value: TaxIdType.Itin,
      label: formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdType.itin.option'
      ),
      testId: TaxIdType.Itin,
    },
  ];

  const idTypesOptions = [
    {
      value: IdType.Passport,
      label: formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.idType.passport.option'
      ),
      testId: IdType.Passport,
    },
    {
      value: IdType.DiverLicense,
      label: formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.idType.diverLicense.option'
      ),
      testId: IdType.DiverLicense,
    },
    {
      value: IdType.NationalId,
      label: formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.idType.nationalId.option'
      ),
      testId: IdType.NationalId,
    },
  ];

  const getTaxIdPlaceholder = () => {
    switch (taxIdType) {
      case TaxIdType.Itin:
        return formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdType.itin.placeholder'
        );
      case TaxIdType.Ssn:
        return formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdType.ssn.placeholder'
        );
    }
  };

  const usResidencyFields = () => (
    <>
      <Form.SelectNew
        {...form.registerField(taxIdTypeFieldName)}
        colSpan={3}
        options={taxIdTypesOptions}
        labelProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdType.label`
          ),
        }}
        isViewMode={shouldShowReadOnly && isFieldReadonly(taxIdTypeFieldName)}
        isRequired
      />
      <Form.TextField
        {...form.registerField(taxIdNumberFieldName)}
        colSpan={3}
        isRequired
        labelProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.taxIdNumber.label`
          ),
        }}
        placeholder={getTaxIdPlaceholder()}
        isViewMode={shouldShowReadOnly && isFieldReadonly(taxIdNumberFieldName)}
      />
    </>
  );

  const nonUsResidencyFields = () => (
    <>
      <Form.SelectNew
        {...form.registerField(idTypeFieldName)}
        colSpan={3}
        options={idTypesOptions}
        labelProps={{
          label: formatMessage(`activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.idType.label`),
        }}
        isRequired
        isViewMode={shouldShowReadOnly && isFieldReadonly(idTypeFieldName)}
      />
      <Form.TextField
        {...form.registerField(idNumberFieldName)}
        colSpan={3}
        isRequired
        labelProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.idNumber.label`
          ),
        }}
        isViewMode={shouldShowReadOnly && isFieldReadonly(idNumberFieldName)}
      />
    </>
  );

  const onUsResidencyChanged = (value: React.ChangeEvent<HTMLInputElement>) => {
    const residency = value.target.value;
    setValue(usResidencyFieldName, residency);
    resetField(taxIdTypeFieldName);
    resetField(taxIdNumberFieldName);
    resetField(idTypeFieldName);
    resetField(idNumberFieldName);
    if (residency === 'usResidence') {
      unregister([idTypeFieldName, idNumberFieldName]);
    } else if (residency === 'nonUsResidence') {
      unregister([taxIdNumberFieldName, taxIdType]);
    }
  };

  return (
    <>
      <Form.RadioGroup
        colSpan={6}
        {...form.registerField(usResidencyFieldName)}
        labelProps={{
          label: formatMessage(
            `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.residency.label`
          ),
          tooltipProps: {
            content: formatMessage(
              `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.residency.residency.tooltip`
            ),
          },
        }}
        isRequired
        isViewMode={shouldShowReadOnly && shouldShowResidencyReadOnly}
        value={usResidency}
        options={residencyOption}
        onChange={onUsResidencyChanged}
      />
      {usResidency === 'usResidence' ? usResidencyFields() : nonUsResidencyFields()}
    </>
  );
};
