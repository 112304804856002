import { DashboardCustomer, useMelioIntl } from '@melio/ar-domain';
import { ActionsDropdownMenu, Avatar, Group, Icon, Text, Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { MobileContainer } from '../../../components';
import { useCustomerActionsDropdownMenuItems } from '../hooks';
import { CustomerActions } from '../types';

export type CustomersTableListItemProps = { customer: DashboardCustomer; customerActions?: CustomerActions };

export const CustomersTableListItem = forwardRef<CustomersTableListItemProps>(
  ({ customer, customerActions, ...props }, ref) => {
    const [isActionsMenuOpen, setIsActionsMenuOpen] = useState(false);
    const { formatMessage } = useMelioIntl();
    const actions = useCustomerActionsDropdownMenuItems({ customer, customerActions });

    return (
      <ActionsDropdownMenu
        isOpen={isActionsMenuOpen}
        onOpenChange={setIsActionsMenuOpen}
        trigger={<CustomersTableListItemContent customer={customer} ref={ref} {...props} />}
        label={formatMessage('ar.dashboard.activities.customersTable.listItem.actionsMenu.label')}
        items={actions}
        ref={ref}
        {...props}
      />
    );
  }
);
CustomersTableListItem.displayName = 'CustomersMobileListItem';

const CustomersTableListItemContent = forwardRef<{ customer: DashboardCustomer }>(
  ({ customer: { invoicesOverview, ...customer }, ...props }, ref) => {
    const { formatCurrency, formatMessage } = useMelioIntl();

    const invoicesCount = invoicesOverview.paid.count + invoicesOverview.open.count;
    const invoicesText =
      invoicesCount === 0
        ? formatMessage('ar.dashboard.activities.customersTable.cells.invoices.noInvoices.text')
        : invoicesCount === 1
        ? formatMessage('ar.dashboard.activities.customersTable.cells.invoices.invoicesCount.single.text')
        : formatMessage('ar.dashboard.activities.customersTable.cells.invoices.invoicesCount.plural.text', {
            invoicesCount,
          });

    return (
      <MobileContainer>
        <Group
          data-testid="customers-table-list-item"
          variant="horizontal"
          justifyContent="space-between"
          {...props}
          ref={ref}
        >
          <Group variant="horizontal">
            <Avatar name={customer.companyName} size="medium" />
            <Group variant="vertical" spacing="xxs">
              <Group variant="horizontal" spacing="xxs">
                <Typography.MainLabel label={customer.companyName} variant="bold" />
              </Group>
              <Group variant="vertical" spacing="xxxs">
                <Text textStyle="body3" color="global.neutral.800" data-testid="customer-email">
                  {customer.email}
                </Text>
                <Text textStyle="body4Semi" color="global.neutral.900">
                  {formatMessage('ar.dashboard.activities.customersTable.listItem.openBalance.text', {
                    openBalance: formatCurrency(invoicesOverview.open.totalAmount),
                  })}
                </Text>
                <Text textStyle="body4" color="global.neutral.900">
                  {invoicesText}
                </Text>
              </Group>
            </Group>
          </Group>
          <Icon type="chevron-right" size="small" />
        </Group>
      </MobileContainer>
    );
  }
);
CustomersTableListItemContent.displayName = 'CustomersTableListItemContent';
