import { _createFormFieldInput, ListItem } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';
import { useState } from 'react';

import { Industry, IndustryType, useIndustryTypes } from '../api-hooks';
import { Combobox, ComboboxOption, ComboboxProps } from './Combobox';

type IndustrySearchOption = ComboboxOption<Industry>;

export type IndustrySearchInputProps = Omit<ComboboxProps<Industry, IndustrySearchOption>, 'options'> & {
  sixDigitOnly?: boolean;
};

export const IndustrySearchInput = _createFormFieldInput(
  forwardRef<IndustrySearchInputProps, 'input'>(({ sixDigitOnly = false, value: _value, ...props }, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { isFetching, data } = useIndustryTypes({ params: { q: searchTerm, sixDigitOnly } });

    const getDescription = (industry: Industry) => {
      const option = (data ?? []).filter(Boolean).find((option) => option.naicsCode === industry.naicsCode);
      return option?.subtitle ?? '';
    };

    return (
      <Combobox
        {...props}
        ref={ref}
        value={_value && parseOption(_value)}
        options={data?.map(parseOption) ?? []}
        isLoadingOptions={isFetching}
        optionRenderer={(option) => (
          <ListItem
            mainLabelProps={{ label: option.label }}
            descriptionProps={{ label: getDescription(option.value) }}
          />
        )}
        onInputChange={setSearchTerm}
      />
    );
  })
);

IndustrySearchInput.displayName = 'IndustrySearchInput';

const parseOption = (option: IndustryType | Industry): IndustrySearchOption => {
  const industry = toIndustry(option);
  return {
    label: industry.name,
    value: industry,
  };
};

const toIndustry = (option: IndustryType | Industry): Industry => {
  if ('title' in option) {
    return {
      naicsCode: option.naicsCode,
      name: option.title,
    };
  }
  return pick(option, 'naicsCode', 'name');
};
