import { Group, StatusIndicator, StatusIndicatorProps, Text, useBreakpoint } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClient } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  status: AccountingFirmClient['clientStatus'];
};

const statusIndicatorMap: Record<AccountingFirmClient['clientStatus'], StatusIndicatorProps['status']> = {
  activated: 'neutral',
  'in-setup': 'neutral',
  'request-expired': 'neutral',
  'request-pending': 'warning',
};
const statusIndicatorDisabledMap: Record<AccountingFirmClient['clientStatus'], boolean> = {
  activated: false,
  'in-setup': true,
  'request-expired': true,
  'request-pending': false,
};

export const StatusCell = ({ status }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Group spacing={isExtraSmallScreen ? 'xs' : 's'} alignItems="center">
      <StatusIndicator status={statusIndicatorMap[status]} isDisabled={statusIndicatorDisabledMap[status]} />
      <Text textStyle={isExtraSmallScreen ? 'body4' : 'body3'}>
        {formatMessage(`activities.accountants.firmDashboard.v2.firmClientsTable.${status}.label`)}
      </Text>
    </Group>
  );
};
