import { Box } from '@chakra-ui/react';
import { useGetCapOrMinFeeDescription } from '@melio/ap-domain/src/hooks/fees/fees';
import { Text } from '@melio/penny';
import {
  BillingFeeSetting,
  FeeCatalog,
  hasActiveBillingFeeMethod,
  PaymentFee,
  PaymentIntent,
  useFeeCatalog,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { sumBy } from '@melio/platform-utils';
import React, { Fragment } from 'react';

import { EbillPayOption } from '../../../../../edit-ebill-payment-option';

export const getTotalFee = (estimatedFees: PaymentIntent['estimatedFees']) =>
  sumBy(estimatedFees || [], (fee) => fee.amount);

export const getPercent = (paymentFee: PaymentFee, feeCatalogEntries?: FeeCatalog[]) => {
  const matchingEntry = feeCatalogEntries?.find((entry) => entry.feeType === paymentFee.type);
  if (!matchingEntry || matchingEntry.valueType !== 'percent') {
    return;
  }
  const isCapped = paymentFee.amount === matchingEntry.cap;
  return !isCapped ? matchingEntry.value : undefined;
};

export const useGetEbillAmountOptionText = (option?: EbillPayOption) => {
  const { formatMessage } = useMelioIntl();
  switch (option) {
    case 'amountDue':
      return {
        label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.amountDue.label'),
        tooltip: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.amountDue.tooltip'
        ),
      };
    case 'minimumAmount':
      return {
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.minimumAmount.label'
        ),
        tooltip: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.minimumAmount.tooltip'
        ),
      };
    case 'accountBalance':
      return {
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.accountBalance.label'
        ),
        tooltip: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.accountBalance.tooltip'
        ),
      };
    case 'customAmount':
      return {
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.customAmount.label'
        ),
        tooltip: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.amountCell.ebill.customAmount.tooltip'
        ),
      };
    default:
      return {
        label: '',
        tooltip: '',
      };
  }
};

export const getMessageKey = (hasFeeBillingMethod: boolean | undefined) =>
  hasFeeBillingMethod
    ? 'activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.title.withBillingFeeMethod'
    : 'activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.title';

export const usePaymentFees = (
  estimatedFees: PaymentIntent['estimatedFees'],
  orgBillingFeeSettings: BillingFeeSetting[]
) => {
  const { formatMessage, formatCurrency, formatPercents } = useMelioIntl();

  const { data: feeCatalogEntries } = useFeeCatalog();
  const {
    settings: {
      batchPayments: { showFeesTooltipTitle },
    },
  } = useConfig();
  const { getCapOrMinFeeDescription } = useGetCapOrMinFeeDescription();
  const hasActiveBillingFee = hasActiveBillingFeeMethod(orgBillingFeeSettings, estimatedFees);
  const tooltipMessageKey = getMessageKey(hasActiveBillingFee);
  const feesTooltipTitle = formatMessage(tooltipMessageKey);
  const feesWithAmount = estimatedFees?.filter((fee) => fee.amount);

  const getFeeDescriptionText = (fee: PaymentFee) => {
    const capOrMinHelperDescription = getCapOrMinFeeDescription(fee.type, fee.amount);
    return capOrMinHelperDescription;
  };

  const getFeesTooltip = () =>
    feesWithAmount?.length
      ? {
          content: (
            <>
              {showFeesTooltipTitle ? (
                <Box
                  display="inline-flex"
                  textStyle="body4Semi"
                  data-testid="batch-payments-amount-cell-fees-tooltip-title"
                >
                  {feesTooltipTitle}
                </Box>
              ) : undefined}
              <>
                {showFeesTooltipTitle && <br />}
                {feesWithAmount.map((fee, index) => {
                  const feeAmount = formatCurrency(fee.amount);
                  const feePercent = getPercent(fee, feeCatalogEntries);

                  const feeDescription = getFeeDescriptionText(fee);
                  const feeMessage = feePercent
                    ? formatMessage(
                        `activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.fees.${fee.type}.withPercent`,
                        {
                          feeAmount,
                          feePercent: feeDescription ? feeDescription : formatPercents(feePercent, { divide: true }),
                        }
                      )
                    : formatMessage(
                        `activities.batchPayments.screens.paymentIntentsTable.amountCell.detailedFeesTooltip.fees.${fee.type}.withoutPercent`,
                        {
                          feeAmount,
                          feeDescription,
                        }
                      );
                  return (
                    <Fragment key={fee.type}>
                      {index > 0 && <br />}
                      <Text textStyle="body4Semi" color="global.neutral.100">
                        {feeMessage}
                      </Text>
                    </Fragment>
                  );
                })}
              </>
            </>
          ),
        }
      : undefined;

  return { getFeesTooltip };
};
