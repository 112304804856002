import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, Icon, Text, Tooltip } from '@melio/penny';

export type PayoutsToolBarProps = {
  totalFundsHeld: number;
  onExportPayouts: VoidFunction;
};

export const PayoutsToolBar = ({ totalFundsHeld, onExportPayouts }: PayoutsToolBarProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  return (
    <Group variant="horizontal" justifyContent="flex-end">
      <Group spacing="m">
        <Container border="regular" backgroundColor="light" alignItems="center" paddingX="s" paddingY="xs">
          <Group variant="horizontal" alignItems="center" spacing="xs">
            <Text textStyle="body3Semi">
              {formatMessage('ar.dashboard.activities.payoutsTable.payoutsToolBar.reservedBalance.text', {
                totalFundsHeld: formatCurrency(totalFundsHeld),
              })}
            </Text>
            <Tooltip
              content={formatMessage('ar.dashboard.activities.payoutsTable.payoutsToolBar.reservedBalance.info.text')}
            >
              <Icon type="info" size="small" />
            </Tooltip>
          </Group>
        </Container>
        <Button onClick={onExportPayouts} label="Export" variant="tertiary" data-testid="export-payouts-button" />
      </Group>
    </Group>
  );
};

PayoutsToolBar.displayName = 'PayoutsToolBar';
