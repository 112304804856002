import { MessageKey, ZeroState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

export type ZeroStateProps = {
  onCreateCustomer?: () => void;
  onImportCustomers?: () => void;
};

export const CustomersTableZeroState = forwardRef<ZeroStateProps>(
  ({ onCreateCustomer, onImportCustomers, ...props }, ref) => {
    const actions: {
      label: MessageKey;
      onClick: () => void;
      variant?: 'primary' | 'secondary';
      testId: string;
    }[] = [
      onImportCustomers && {
        label: 'ar.dashboard.activities.customersTable.emptyState.import.button.label',
        onClick: onImportCustomers,
        variant: 'secondary',
        testId: 'zero-state-import-customers-button',
      },
      onCreateCustomer && {
        label: 'ar.dashboard.activities.customersTable.emptyState.create.button.label',
        onClick: onCreateCustomer,
        variant: 'primary',
        testId: 'zero-state-create-customer-button',
      },
    ].filter(Boolean) as [];

    return (
      <ZeroState
        ref={ref}
        illustrationType="customer-add"
        primaryLabel="ar.dashboard.activities.customersTable.emptyState.no-data.primary.label"
        secondaryLabel="ar.dashboard.activities.customersTable.emptyState.no-data.secondary.label"
        actions={actions}
        dataTestId="customers-dashboard-no-customers"
        {...props}
      />
    );
  }
);

CustomersTableZeroState.displayName = 'CustomersTableZeroState';
