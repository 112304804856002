import { useAccount, useFundingSources } from '@melio/platform-api';

import { useAccountingFirmClientFundingSources } from '../../../api/entities';
import { useSubscriptionFundingSource } from '../../../hooks';

export const useSubscriptionFundingSourceInfo = ({
  clientOrganizationId,
  payingOrganizationId,
  usedFundingSourceId,
}: {
  clientOrganizationId?: string;
  payingOrganizationId?: string;
  usedFundingSourceId?: string;
}) => {
  const { data: firm, isLoading: isAccountMeLoading, error: accountMeError } = useAccount({ id: 'me' });
  const { fundingSourceId: firmSubscriptionBillingFundingSourceId } = useSubscriptionFundingSource();
  const {
    data: allFirmFundingSources = [],
    isLoading: isLoadingFirmFundingSources,
    error: firmFundingSourcesError,
  } = useFundingSources({ enabled: true });
  const {
    data: allClientFundingSources = [],
    isLoading: isLoadingClientFundingSources,
    error: clientFundingSourcesError,
  } = useAccountingFirmClientFundingSources(clientOrganizationId || '', {
    enabled: !!clientOrganizationId,
  });

  const isFirmPayingForSubscription = payingOrganizationId === firm?.organizationId;
  const isClientPayingForSubscription = payingOrganizationId === clientOrganizationId;

  const usedFundingSourceForPayingSubscription = isClientPayingForSubscription
    ? allClientFundingSources.find((fs) => fs.id === usedFundingSourceId)
    : isFirmPayingForSubscription
    ? allFirmFundingSources.find((fs) => fs.id === firmSubscriptionBillingFundingSourceId)
    : undefined;

  return {
    usedFundingSourceForPayingSubscription,
    isFirmPayingForSubscription,
    isClientPayingForSubscription,
    isLoading: isLoadingFirmFundingSources || isAccountMeLoading || isLoadingClientFundingSources,
    error: accountMeError || firmFundingSourcesError || clientFundingSourcesError,
  };
};
