import { useEffect } from 'react';
import _ from 'lodash';
import { Account, useAccount, useIsAccountingClient, useIsOwner } from '@melio/platform-api';

import { usePartnerConfig } from '@/hooks/partners';
import { generateOnboardingLink } from '@/utils/generateMelioRedirectPath';

const REQUIRED_ORGANIZATION_FIELDS = [
  'name',
  'contactFirstName',
  'contactLastName',
  'address.line1',
  'address.city',
  'address.state',
  'address.postalCode',
];

const isOrganizationOnboarded = (account: Account) => {
  const organization = account.company;
  return REQUIRED_ORGANIZATION_FIELDS.every((field) => _.get(organization, field));
};

export const useOnboardingRedirect = () => {
  const { partnerConfig } = usePartnerConfig();
  const { data: account } = useAccount({ id: 'me' });
  const isOwner = useIsOwner();
  const isAccouningClient = useIsAccountingClient();

  useEffect(() => {
    if (
      partnerConfig.supportLegacyOrganizations &&
      account &&
      isOwner &&
      !isAccouningClient &&
      !isOrganizationOnboarded(account)
    ) {
      location.assign(generateOnboardingLink(account.organizationId));
    }
  }, [partnerConfig, account, isOwner, isAccouningClient]);
};
