/* eslint-disable max-lines */
import { buildInvoiceDataFromBill, isDeliveryPreferenceTypeFast, isEbill } from '@melio/ap-domain';
import {
  BatchUpdateParam,
  BatchUpdateResult,
  BatchUpdateResultSuccessItem,
  Bill,
  BulkPostPaymentSettingsCalculatorRequest,
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  PatchPaymentIntentsPaymentIntentIdRequest,
  PaymentIntent,
  PaymentSettingsCalculations,
  Vendor,
} from '@melio/platform-api';
import { sumBy } from '@melio/platform-utils';
// eslint-disable-next-line no-restricted-imports
import { isEqual } from 'date-fns';
import { chain, compact, isEmpty, isNil, isUndefined, map, maxBy, omitBy, pickBy, sortBy, uniq, zipWith } from 'lodash';

import { getEarliestBillDueDate } from '../../utils/getEarliestBillDueDate';
import { getDefaultMemoFromInvoiceNumbers } from '../../utils/pay-flow/defaultMemo';
import { SchedulePaymentIntent } from './types';

export const getPaymentIntentSortedByLatestDueDate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  sortBy(paymentIntentsWithDerivatives, ({ bills }) => getEarliestBillDueDate(bills));

export const getSuccessfullyConfirmedPaymentIntents = (confirmResults: BatchUpdateResult<PaymentIntent>) =>
  (confirmResults.filter((result) => result.status === 'success') as BatchUpdateResultSuccessItem<PaymentIntent>[]).map(
    (result) => result.data
  );

export const getCreatedPaymentId = (paymentIntents: PaymentIntent[]) =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  paymentIntents.map((paymentIntent) => maxBy(paymentIntent.payments, (payment) => payment.history.createdAt)!.id);

export const getDefaultFundingSource = (fundingSources: FundingSource[]) =>
  fundingSources.find((fundingSource) => fundingSource.type === 'card' && fundingSource.details.type === 'credit') ||
  fundingSources.find((fundingSource) => fundingSource.type === 'card' && fundingSource.details.type === 'debit') ||
  fundingSources.find((fundingSource) => fundingSource.type === 'bank-account' && fundingSource.isVerified) ||
  fundingSources[0];

export const getDefaultDeliveryMethod = (deliveryMethods: DeliveryMethod[], selectedFundingSource?: FundingSource) => {
  if (selectedFundingSource) {
    return (
      deliveryMethods.find((deliveryMethod) => deliveryMethod.type === 'bank-account') ||
      deliveryMethods.find((deliveryMethod) => deliveryMethod.type === 'paper-check') ||
      (selectedFundingSource.type !== 'bank-account' || selectedFundingSource.isVerified
        ? deliveryMethods.find((deliveryMethod) => deliveryMethod.type === 'virtual-account')
        : undefined)
    );
  }
  return;
};

export const getByDueDateDeductionDateCalculatorParams = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives.reduce(
    (calculatorParams: BulkPostPaymentSettingsCalculatorRequest, { paymentIntent, bills, vendor }) => {
      const paymentIntentDueDate = getEarliestBillDueDate(bills);
      if (paymentIntent.amountToPay && paymentIntentDueDate) {
        calculatorParams.push({
          amountToPay: paymentIntent.amountToPay,
          vendorId: vendor.id,
          dueDate: paymentIntentDueDate,
          ...omitBy(
            {
              fundingSourceId: paymentIntent.fundingSourceId,
              deliveryMethodId: paymentIntent.deliveryMethodId,
              deliveryPreferenceType: paymentIntent.selectedDeliveryPreferenceType,
            },
            isNil
          ),
          invoicesData: bills.map(buildInvoiceDataFromBill),
        });
      }
      return calculatorParams;
    },
    []
  );

const getPaymentIntentMinScheduleDate = (paymentIntent: PaymentIntent) =>
  paymentIntent.deliveryPreferenceOptions?.find(
    (option) => option.type === paymentIntent.selectedDeliveryPreferenceType
  )?.minScheduleDate;

export const getByEarliestDateDataToUpdate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives
    .map(({ paymentIntent }) => {
      const minScheduleDate = getPaymentIntentMinScheduleDate(paymentIntent);
      const shouldUpdateScheduledDate =
        minScheduleDate && (!paymentIntent.scheduledDate || !isEqual(minScheduleDate, paymentIntent.scheduledDate));

      const dataToUpdate = {
        scheduledDate: minScheduleDate,
      };

      return shouldUpdateScheduledDate ? { id: paymentIntent.id, data: dataToUpdate } : undefined;
    })
    .filter((value) => !isUndefined(value)) as BatchUpdateParam<PatchPaymentIntentsPaymentIntentIdRequest>;

export const getByDueDateDateToUpdate = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives
    .map(({ paymentIntent, bills }) => {
      const dueDate = getEarliestBillDueDate(bills);

      const shouldUpdateDeliveryDate =
        dueDate && (!paymentIntent.deliveryDate || !isEqual(dueDate, paymentIntent.deliveryDate));

      const dataToUpdate = {
        deliveryDate: dueDate,
      };

      return shouldUpdateDeliveryDate ? { id: paymentIntent.id, data: dataToUpdate } : undefined;
    })
    .filter((value) => !isUndefined(value)) as BatchUpdateParam<PatchPaymentIntentsPaymentIntentIdRequest>;

const getCalculatorResponseScheduledDate = (calculatorResponseItem: PaymentSettingsCalculations) =>
  calculatorResponseItem.deliveryPreferenceOptions?.find(
    (option) => option.type === calculatorResponseItem.deliveryPreferenceType
  )?.effectiveScheduleDate;

export const getDataToUpdateFromCalculatorResponse = (
  paymentIntents: PaymentIntent[],
  calculatorResponse: PaymentSettingsCalculations[]
): BatchUpdateParam<PatchPaymentIntentsPaymentIntentIdRequest> =>
  zipWith(paymentIntents, calculatorResponse, (paymentIntent, calculatorResponseItem) => {
    const scheduledDateFromCalculator = getCalculatorResponseScheduledDate(calculatorResponseItem);

    const shouldUpdateScheduledDate =
      scheduledDateFromCalculator &&
      (!paymentIntent.scheduledDate || !isEqual(scheduledDateFromCalculator, paymentIntent.scheduledDate));

    const shouldUpdateDeliveryMethod =
      calculatorResponseItem.deliveryMethodId &&
      paymentIntent.deliveryMethodId !== calculatorResponseItem.deliveryMethodId;

    const shouldUpdateFundingSource =
      calculatorResponseItem.fundingSourceId &&
      paymentIntent.fundingSourceId !== calculatorResponseItem.fundingSourceId;

    const shouldUpdateDeliveryPreferenceType =
      calculatorResponseItem.deliveryPreferenceType &&
      paymentIntent.selectedDeliveryPreferenceType !== calculatorResponseItem.deliveryPreferenceType;

    const dateToUpdate = pickBy(
      {
        scheduledDate: shouldUpdateScheduledDate ? scheduledDateFromCalculator : undefined,
        deliveryMethodId: shouldUpdateDeliveryMethod ? calculatorResponseItem.deliveryMethodId : undefined,
        fundingSourceId: shouldUpdateFundingSource ? calculatorResponseItem.fundingSourceId : undefined,
        selectedDeliveryPreferenceType: shouldUpdateDeliveryPreferenceType
          ? calculatorResponseItem.deliveryPreferenceType
          : undefined,
      },
      (property) => !isUndefined(property)
    );

    return Object.values(dateToUpdate).length ? { id: paymentIntent.id, data: dateToUpdate } : undefined;
  }).filter((value) => !isUndefined(value)) as BatchUpdateParam<PatchPaymentIntentsPaymentIntentIdRequest>;

function getHasDuplication<T>(arr: T[]) {
  return uniq(arr).length < arr.length;
}

export const getThereIsSameVendorForMultipleBills = (bills?: Bill[]) => {
  if (!bills) {
    return false;
  }
  const vendorIds = bills.map((bill) => bill.vendorId);

  return getHasDuplication(vendorIds);
};

export const getArePaymentsToCombineContainEBills = (bills?: Bill[]) => {
  if (!bills) {
    return false;
  }

  return bills.some((bill) => isEbill(bill));
};

export const getCanCombinePayments = getThereIsSameVendorForMultipleBills;

export const getPaymentIntentBills = (paymentIntent: PaymentIntent, bills: Bill[]) =>
  paymentIntent.billPayments?.reduce((acc: Bill[], billPayment) => {
    const bill = bills.find((bill) => billPayment.billId === bill.id);
    if (bill) {
      acc.push(bill);
    }
    return acc;
  }, []);

export const getPaymentIntentVendorId = (paymentIntent: PaymentIntent, bills: Bill[]) =>
  getPaymentIntentBills(paymentIntent, bills)?.[0]?.vendorId;

export const getUniqueVendorIds = (bills?: Bill[]) => uniq(map(bills, 'vendorId'));

const getCombinedPaymentPurpose = (currentPaymentIntents: PaymentIntent[] = []) =>
  currentPaymentIntents
    .map((paymentIntent) => paymentIntent.paymentPurpose)
    .filter((purpose) => !isEmpty(purpose))
    .join(', ');

export const createPaymentsIntentsWithDerivatives = (
  paymentIntents: PaymentIntent[],
  allBills: Bill[],
  allVendors: Vendor[]
): SchedulePaymentIntent[] =>
  compact(
    paymentIntents.map((paymentIntent) => createPaymentIntentWithDerivatives(paymentIntent, allBills, allVendors))
  );

export const createPaymentIntentWithDerivatives = (
  paymentIntent: PaymentIntent,
  allBills: Bill[],
  allVendors: Vendor[]
) => {
  const paymentIntentBills = sortBy(getPaymentIntentBills(paymentIntent, allBills), 'dueDate');

  if (!paymentIntentBills) {
    return;
  }

  const paymentIntentVendorId = getPaymentIntentVendorId(paymentIntent, paymentIntentBills);
  const paymentIntentVendor = allVendors.find((vendor) => vendor.id === paymentIntentVendorId);

  if (!paymentIntentVendor) {
    return;
  }

  return {
    paymentIntent: {
      ...paymentIntent,
      amountToPay: sumBy(paymentIntentBills, (bill) => bill.balance),
      billPayments: paymentIntentBills.map(({ id, balance }) => ({ billId: id, amount: balance })),
    },
    vendor: paymentIntentVendor,
    bills: paymentIntentBills,
  };
};

export const mapBillsToCreatePaymentIntentsPerVendor = (bills: Bill[], currentPaymentIntents?: PaymentIntent[]) =>
  chain(bills)
    .groupBy('vendorId')
    .flatMap((bills) => {
      const billsIds = bills.map(({ id }) => id);
      const billPaymentIntents = currentPaymentIntents?.filter((paymentIntent) =>
        paymentIntent.billPayments?.some(({ billId }) => billsIds.includes(billId))
      );

      const latestBillId = sortBy(bills, 'dueDate')[0]?.id;
      const latestBillPaymentIntent = billPaymentIntents?.find((paymentIntent) =>
        paymentIntent.billPayments?.some(({ billId }) => billId === latestBillId)
      );

      const { fundingSourceId, deliveryMethodId, scheduledDate, selectedDeliveryPreferenceType } =
        latestBillPaymentIntent || {};

      return {
        ...omitBy(
          {
            scheduledDate: scheduledDate || void 0,
            fundingSourceId,
            deliveryMethodId,
            selectedDeliveryPreferenceType: selectedDeliveryPreferenceType || void 0,
            purpose: getCombinedPaymentPurpose(billPaymentIntents) || void 0,
          },
          isNil
        ),
        amountToPay: sumBy(bills, (bill) => bill.balance),
        billPayments: bills.map((bill) => ({
          billId: bill.id,
          amount: bill.balance,
        })),
      };
    })
    .value();

export const mapBillToCreatePaymentIntentsPerBill = ({
  bills,
  vendors,
  currentPaymentIntents,
}: {
  bills: Bill[];
  currentPaymentIntents?: PaymentIntent[];
  vendors?: Vendor[];
}) =>
  bills.map((bill) => {
    const billPaymentIntent = currentPaymentIntents?.find((paymentIntent) =>
      paymentIntent.billPayments?.some(({ billId }) => billId === bill.id)
    );
    const vendor = vendors?.find((vendor) => vendor.id === bill.vendorId);

    const {
      fundingSourceId,
      deliveryMethodId,
      scheduledDate,
      selectedDeliveryPreferenceType = void 0,
      paymentPurpose,
    } = billPaymentIntent || {};

    return {
      ...omitBy(
        {
          scheduledDate: scheduledDate || void 0,
          fundingSourceId,
          deliveryMethodId,
          selectedDeliveryPreferenceType: vendor?.isManaged ? void 0 : selectedDeliveryPreferenceType,
          purpose: paymentPurpose,
        },
        isNil
      ),
      amountToPay: bill.balance,
      billPayments: [{ billId: bill.id, amount: bill.balance }],
    };
  });

export const getDefaultMemo = ({ bills }: SchedulePaymentIntent) => {
  const invoiceNumbers = compact(bills.map((bill) => bill.invoice.number));
  return getDefaultMemoFromInvoiceNumbers(invoiceNumbers);
};

export const getRegularPaymentIntents = (paymentIntentsWithDerivatives: SchedulePaymentIntent[]) =>
  paymentIntentsWithDerivatives.filter(
    ({ paymentIntent: { selectedDeliveryPreferenceType } }) =>
      selectedDeliveryPreferenceType && !isDeliveryPreferenceTypeFast(selectedDeliveryPreferenceType)
  );

export const getUpdateSingleDeliveryPreferenceUpdateData = (deliveryPreference: DeliveryPreference) => {
  const isFast = isDeliveryPreferenceTypeFast(deliveryPreference.type);
  return {
    selectedDeliveryPreferenceType: deliveryPreference.type,
    ...(isFast
      ? { scheduledDate: deliveryPreference.minScheduleDate }
      : { deliveryDate: deliveryPreference.earliestDeliveryDate }),
  };
};
