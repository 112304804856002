import { screen } from '@testing-library/react';
export class FundingSourceCardDriver {
  getCard(id: string) {
    return screen.getByTestId(`funding-source-card-${id}`);
  }

  getChangeButton() {
    return screen.queryByTestId('change-funding-source-button');
  }
}

export class SubscriptionFundingSourceCardCypressDriver {
  chooseFundingSource(id: string) {
    return cy.getByTestId(`funding-source-card-${id}`).click();
  }
}
