import { Button, Group, Icon, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const OverviewErrorState = ({ onTryAgain }: { onTryAgain: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" width="full" alignItems="center" spacing="s" data-testid="clients-overview-error">
      <Group.Item>
        <Icon type="error-fill" color="critical" size="large" />
      </Group.Item>
      <Group.Item>
        <Text data-testid="clients-overview-error-title">
          {formatMessage('activities.accountants.firmDashboard.v2.overview.error.title')}
        </Text>
      </Group.Item>
      <Group.Item>
        <Button
          data-testid="clients-overview-error-try-again"
          label={formatMessage('activities.accountants.firmDashboard.v2.overview.error.tryAgain.button.label')}
          onClick={onTryAgain}
          variant="tertiary"
          size="small"
        />
      </Group.Item>
    </Group>
  );
};
