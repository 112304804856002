/* eslint-disable no-restricted-imports */
import { Group, IconButton } from '@melio/penny';
import {
  AccountingFirmClientExpanded,
  AccountingPlatformConnectionStatus,
  AccountingPlatformSlug,
} from '@melio/platform-api-axios-client';
import { useState } from 'react';

import { ClientLogo } from '../../../FirmDashboard/v2/components/ClientLogo/ClientLogo';
import { SectionWrapper } from '../SectionWrapper';
import { CompanyDescription } from './CompanyDescription';

const disconnectedAccountingPlatformStatuses: Array<AccountingPlatformConnectionStatus> = [
  AccountingPlatformConnectionStatus.Unlinked,
  AccountingPlatformConnectionStatus.Disconnected,
];

const allowedAccountingPlatformSlugs: Array<AccountingPlatformSlug> = [
  'quickBooksDesktop',
  'quickBooksDesktopInApp',
  'quickBooksOnline',
];

export const ClientDetails = ({ client }: { client: AccountingFirmClientExpanded }) => {
  const [isHeaderOpen, setIsHeaderOpen] = useState<boolean>(false);

  const accountingPlatform = client.accountingPlatforms?.find(
    (ap) =>
      allowedAccountingPlatformSlugs.includes(ap.accountingSlug) &&
      !disconnectedAccountingPlatformStatuses.includes(ap.connectionStatus)
  );

  return (
    <SectionWrapper withSpacing={false}>
      <Group variant="horizontal" spacing="s">
        <Group.Item grow={1}>
          <Group alignItems="flex-start" variant={isHeaderOpen ? 'vertical' : 'horizontal'}>
            <ClientLogo
              client={{
                name: client.organizationInfo.companyName || null,
                logoUrl: client.organizationInfo.logoUrl || undefined,
              }}
              accountingPlatformSlug={accountingPlatform?.accountingSlug}
            />
            <CompanyDescription
              dataTestId={`drawer-client-details-${client.organizationInfo.id}`}
              client={client}
              accountingPlatform={accountingPlatform}
              expanded={isHeaderOpen}
            />
          </Group>
        </Group.Item>
        <Group.Item>
          <IconButton
            data-testid="client-details-expand-button"
            icon={isHeaderOpen ? 'chevron-up' : 'chevron-down'}
            aria-label="icon-button-up"
            variant="naked"
            onClick={() => setIsHeaderOpen((prev) => !prev)}
          />
        </Group.Item>
      </Group>
    </SectionWrapper>
  );
};
