import { Container, Group, LoadingContainer, Text } from '@melio/penny';
import { useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSetDocumentTitle } from '@melio/platform-sdk';
import { PageTitle, SettingsInternalPageHeader } from '@melio/platform-utils';
import { groupBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { usePartnerGroups, useSubscription } from '../../../hooks';
import { useSubscriptionOrganizationFailureState } from '../../../hooks/useSubscriptionOrganizationFailureState';
import { SETTINGS_SUBSCRIPTION_PLANS_ROUTE } from '../../../router/routes.consts';
import { FundingSourceGroupList } from '../../components';
import { useBillingFundingSources, useSubscriptionRouter } from '../../utils';
import { SubscriptionSelectFundingSourceFeeFailedBanner } from './SubscriptionSelectFundingSourceFeeFailedBanner';
import { SubscriptionSelectFundingSourceFooter } from './SubscriptionSelectFundingSourceFooter';
import { SubscriptionSelectFundingSourceHeader } from './SubscriptionSelectFundingSourceHeader';

export const SubscriptionSelectFundingSource = withAnalyticsContext(({ setAnalyticsProperties }) => {
  const [searchParams] = useSearchParams();

  const { fundingSources, isFetching: isFetchingFundingSources } = useBillingFundingSources();

  const fundingSourcesGroupedByType = useMemo(() => groupBy(fundingSources, 'type'), [fundingSources]);
  const { formatMessage } = useMelioIntl();
  const { goToAddBankAccount, goToAddCard } = useSubscriptionRouter();
  const subscription = useSubscription();
  const { isFiservPartner } = usePartnerGroups();
  const { failureState, isFetching: isFailureStateFetching } = useSubscriptionOrganizationFailureState();

  useSetDocumentTitle(formatMessage('activities.subscriptionPaymentMethod.screens.title'));

  setAnalyticsProperties({
    PageName: 'subscription-payment-method',
    Intent: 'add-payment-method',
    Flow: 'subscription-payment-method',
  });

  const shouldTrack = !isFetchingFundingSources && !isFailureStateFetching;
  useAnalyticsView('PaymentMethod', shouldTrack, true, {
    NumberOfPm: fundingSources?.length,
    ...(failureState && { ErrorType: failureState.reason }),
  });

  const newFundingSourceId = searchParams.get('fundingSourceId');
  const defaultFundingSourceToSelect =
    fundingSourcesGroupedByType['bank-account']?.[0] || fundingSourcesGroupedByType['card']?.[0];
  const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>(
    newFundingSourceId || subscription?.fundingSourceId || defaultFundingSourceToSelect?.id || ''
  );
  const location = useLocation();

  const navigateToAddBankAccount = () => goToAddBankAccount({ returnUrl: location.pathname });
  const navigateToAddCard = () => goToAddCard({ returnUrl: location.pathname });

  const disableSavebutton =
    failureState?.reason === 'ExpiredCard' && subscription?.fundingSourceId === selectedFundingSourceId;

  return (
    <Layout
      paddingContent={isFiservPartner ? 's' : 'l'}
      maxWidth="600px"
      data-testid="subscription-payment-method-select"
      {...(!isFiservPartner && {
        header: {
          isSticky: true,
          content: <SubscriptionSelectFundingSourceHeader />,
        },
      })}
      footer={{
        isSticky: true,
        content: (
          <SubscriptionSelectFundingSourceFooter
            fundingSources={fundingSources}
            selectedFundingSourceId={selectedFundingSourceId}
            isDisabled={disableSavebutton}
          />
        ),
      }}
    >
      <Group variant="vertical" alignItems="flex-start" spacing="s">
        <Group variant="vertical" alignItems="flex-start" spacing="s">
          {isFiservPartner ? (
            <SettingsInternalPageHeader
              title={formatMessage('activities.subscriptionPaymentMethod.screens.title')}
              goBackToRoute={SETTINGS_SUBSCRIPTION_PLANS_ROUTE}
            />
          ) : (
            <PageTitle textStyle="heading1Semi" id="subscritpions-choose-billing-account">
              {formatMessage('activities.subscriptionPaymentMethod.screens.title')}
            </PageTitle>
          )}

          <Text>{formatMessage('activities.subscriptionPaymentMethod.screens.description')}</Text>
          {failureState && (
            <Container paddingY="m">
              <SubscriptionSelectFundingSourceFeeFailedBanner failureState={failureState} />
            </Container>
          )}
        </Group>

        <LoadingContainer isLoading={isFetchingFundingSources || isFailureStateFetching}>
          <FundingSourceGroupList
            fundingSourcesGroupedByType={fundingSourcesGroupedByType}
            onSelected={(fundingSource) => setSelectedFundingSourceId(fundingSource.id)}
            selectedId={selectedFundingSourceId}
            onAddBank={navigateToAddBankAccount}
            onAddCard={navigateToAddCard}
            currentFundingSourceId={subscription?.fundingSourceId}
          />
        </LoadingContainer>
      </Group>
    </Layout>
  );
});
