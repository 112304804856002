import { Box } from '@chakra-ui/react';
import { Drawer, FormSearchProps, Text, useBreakpointValue } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BusinessResultItem, MsnBusinessItem } from '@melio/platform-api';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React, { useState } from 'react';

import { Search } from './components/Search.widget';
import { SearchToggle } from './components/SearchToggle.widget';
import { useBusinessSearchOptions } from './useBusinessSearchOptions';

export type BusinessSearchWidgetProps = Omit<FormSearchProps, 'creatableOption' | 'options' | 'emptyState'> & {
  onCreateOption?: (value: string) => void;
  onSelectOption: (value: BusinessResultItem | MsnBusinessItem) => void;
  onClearField: VoidFunction;
  isBusiness?: boolean;
  shouldShowModalOnMount?: boolean;
  shouldUseModalOnMobile?: boolean;
  shouldUseNewBusinessesSearch?: boolean;
};

export const BusinessSearchWidget = forwardRef<BusinessSearchWidgetProps, 'input'>(
  (
    {
      onCreateOption,
      onSelectOption,
      onClearField,
      isBusiness,
      shouldUseModalOnMobile,
      shouldShowModalOnMount = false,
      shouldUseNewBusinessesSearch = false,
      ...props
    },
    ref
  ) => {
    const { settings } = useConfig();
    const {
      isLoading: isLoadingBusinesses,
      isNewSearch,
      options,
      handleSearch,
      shouldSearch,
      getBusinessByOptionValue,
      getSelectedItemAnalyticsProps,
    } = useBusinessSearchOptions(shouldUseNewBusinessesSearch);
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(shouldShowModalOnMount);

    const handleCreateOption = (inputValue: string) => {
      track('Vendor', 'Click', getSelectedItemAnalyticsProps());
      onCreateOption?.(inputValue);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;

      if (value) {
        const selectedBusiness = getBusinessByOptionValue(value);
        if (selectedBusiness) {
          track('Vendor', 'Click', getSelectedItemAnalyticsProps(selectedBusiness));
          onSelectOption(selectedBusiness);
          isNewSearch && isDrawerOpen && handleDrawerClose();
          return;
        }
      }
    };

    const handleSearchToggleClick = () => setIsDrawerOpen(true);
    const handleDrawerClose = () => setIsDrawerOpen(false);

    if (shouldUseModalOnMobile && isMobile) {
      return (
        <>
          <SearchToggle name={props.name} control={props.control} onClick={handleSearchToggleClick} />
          <Drawer
            isOpen={isDrawerOpen}
            onClose={handleDrawerClose}
            header={
              <Text as="h2" textStyle="heading2Semi">
                {formatMessage('widgets.businessSearch.drawer.header')}
              </Text>
            }
            body={
              <Box>
                <Search
                  ref={ref}
                  isLoading={isLoadingBusinesses}
                  options={options}
                  onChange={handleChange}
                  onInputChange={handleSearch}
                  onCreateOption={handleCreateOption}
                  shouldSearch={shouldSearch}
                  shouldShowBadge={(isBusiness && settings.showCheckMarkIconForManagedVendor) ?? false}
                  {...props}
                  autoFocus
                />
              </Box>
            }
          />
        </>
      );
    }

    return (
      <Search
        ref={ref}
        isLoading={isLoadingBusinesses}
        options={options}
        onChange={handleChange}
        onInputChange={handleSearch}
        onCreateOption={handleCreateOption}
        onClear={onClearField}
        shouldSearch={shouldSearch}
        shouldShowBadge={(isBusiness && settings.showCheckMarkIconForManagedVendor) ?? false}
        {...props}
      />
    );
  }
);

BusinessSearchWidget.displayName = 'BusinessSearchWidget';
