import { Form, Group, Modal, useFormSubmissionController, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useCreateVendorsGroup, useVendorGroups } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useVendorsGroupFormSchema } from '../../../vendor-groups/vendors-group-form/schema';

type VendorGroupCreateNewGroupModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const VendorGroupCreateNewGroupModal = ({ isOpen, onClose }: VendorGroupCreateNewGroupModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { submitButtonProps, onSubmissionStateChange } = useFormSubmissionController<{ name: string }>();
  const { showMessage } = useSystemMessage();
  const { isLoading: isSaving, mutateAsync: create } = useCreateVendorsGroup();
  const { data: vendorGroups, isLoading: isVendorGroupsLoading } = useVendorGroups();

  useEffect(() => {
    if (isVendorGroupsLoading) {
      return;
    }
    track('Vendor', 'View', { PageName: 'create-groups', Flow: 'vendor', Intent: 'add-vendor-group' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVendorGroupsLoading]);

  const handleCreateVendorGroup = async (data: { name: string }) => {
    try {
      track('Vendor', 'Click', {
        PageName: 'create-groups',
        Flow: 'vendor',
        Intent: 'add-vendor-group',
        Cta: 'create',
      });

      const vendorsGroup = { name: data.name, vendorIds: [] };

      await create({ vendorsGroup });
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.success', { name: data.name }),
        type: 'success',
        dataTestId: 'create-vendors-group-success',
      });
      onClose();
    } catch (e) {
      showMessage({
        title: formatMessage('activities.createVendorsGroupModal.toasts.error', { name: data.name }),
        type: 'error',
        dataTestId: 'create-vendors-group-error',
      });
    }
  };

  const handleClose = () => {
    track('Vendor', 'Click', { PageName: 'create-groups', Flow: 'vendor', Intent: 'add-vendor-group', Cta: 'exit' });
    onClose();
  };

  const handleCancel = () => {
    track('Vendor', 'Click', { PageName: 'create-groups', Flow: 'vendor', Intent: 'add-vendor-group', Cta: 'cancel' });
    onClose();
  };

  const schema = useVendorsGroupFormSchema(vendorGroups?.map(({ name }) => name) ?? []);

  const { formProps, registerField } = useMelioForm<{ name: string }>({
    onSubmit: handleCreateVendorGroup,
    onError: (errors) => {
      if (errors.name?.message === formatMessage('activities.vendorsGroupForm.errors.name.exist')) {
        track('Vendor', 'Status', { PageName: 'create-groups', Status: 'failure', ErrorType: 'group-name-exists' });
      }
    },
    onSubmissionStateChange,
    schema,
    isSaving,
  });

  return (
    <Modal
      data-testid="vendor-group-create-new-group-modal"
      isLoading={isVendorGroupsLoading}
      isOpen={isOpen}
      onClose={handleClose}
      header={formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.modal.title')}
      primaryButton={{
        label: formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.primaryButton'),
        variant: 'primary',
        ...submitButtonProps,
      }}
      secondaryButton={{
        label: formatMessage('activities.addVendor.screens.addVendorDetails.vendorGroup.addNewGroup.secondaryButton'),
        onClick: handleCancel,
        variant: 'secondary',
      }}
    >
      <Group variant="vertical">
        <Form {...formProps}>
          <Form.TextField
            {...registerField('name')}
            labelProps={{ label: formatMessage('activities.vendorsGroupForm.fields.name.label') }}
            isRequired
          />
        </Form>
      </Group>
    </Modal>
  );
};
