import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  BrandSymbol,
  BrandSymbolKey,
  Card,
  Collapse,
  Container,
  Divider,
  Group,
  Icon,
  Text,
  VisuallyHidden,
} from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { compact, max, range } from 'lodash';
import { useMemo, useState } from 'react';

type Entity = {
  brandSymbolKey: BrandSymbolKey;
  providerName?: string;
  title: string;
  subTitles?: (string | undefined)[];
  bodyItems: (string | undefined)[];
};

export type EntityMatchingCardWidgetProps = {
  entityA: Entity;
  entityB: Entity;
  isHighlighted?: boolean;
  onCardOpen?: () => void;
  bodyId: string;
};

const EMPTY_ITEM_SYMBOL = '—';

export const EntityMatchingCardWidget = forwardRef<EntityMatchingCardWidgetProps>(
  ({ isHighlighted = false, entityA, entityB, onCardOpen, bodyId, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const [isOpened, setIsOpened] = useState(false);

    const titleAlignSelf = entityA.subTitles?.length || entityB.subTitles?.length ? 'stretch' : 'center';

    const getTitle = (titleText: string, brandSymbolKey: BrandSymbolKey, subTitles?: (string | undefined)[]) => {
      const titleTextColor = isHighlighted ? 'semantic.text.primary' : 'semantic.text.secondary';

      return (
        <Group.Item grow={1} shrink={1} basis={0} alignSelf={titleAlignSelf}>
          <Group spacing="xs" height="full">
            <Group.Item shrink={0}>
              <Container paddingTop="xxxs">
                <BrandSymbol aria-label="" type={brandSymbolKey} aria-hidden size="small" />
              </Container>
            </Group.Item>
            <Group variant="vertical" spacing="none">
              <Text textStyle="body2Semi" color={titleTextColor}>
                {titleText}
              </Text>

              {compact(subTitles).map((subTitle) => (
                <Text key={subTitle} textStyle="body3" color={titleTextColor}>
                  {subTitle}
                </Text>
              ))}
            </Group>
          </Group>
        </Group.Item>
      );
    };

    const maxIndex = max([entityA.bodyItems.length, entityB.bodyItems.length]) ?? 0;

    const body = useMemo(
      () => (
        <Table variant="unstyled">
          <VisuallyHidden>
            <Thead>
              <Tr display="flex" id={bodyId}>
                <Th width="50%">{`${entityA.providerName ?? ''} ${entityA.title}`}</Th>
                <Th width="50%">{`${entityB.providerName ?? ''} ${entityB.title}`}</Th>
              </Tr>
            </Thead>
          </VisuallyHidden>

          <Tbody>
            {range(maxIndex)
              .filter((i) => entityA.bodyItems[i] || entityB.bodyItems[i])
              .map((i) => (
                <Tr display="flex" key={i}>
                  <Td width="50%">
                    <Container paddingLeft="m" paddingRight="l" aria-hidden={!entityA.bodyItems[i]}>
                      <Box wordBreak="break-word">
                        <Text textStyle="body3" color="semantic.text.secondary">
                          {entityA.bodyItems[i] ?? EMPTY_ITEM_SYMBOL}
                        </Text>
                      </Box>
                    </Container>
                  </Td>
                  <Td width="50%">
                    <Container paddingLeft="m" paddingRight="l" aria-hidden={!entityB.bodyItems[i]}>
                      <Box wordBreak="break-word">
                        <Text textStyle="body3" color="semantic.text.secondary">
                          {entityB.bodyItems[i] ?? EMPTY_ITEM_SYMBOL}
                        </Text>
                      </Box>
                    </Container>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      ),
      [bodyId, entityA, entityB, maxIndex]
    );

    return (
      <Card
        ref={ref}
        variant="flat"
        onClick={() => {
          !isOpened && onCardOpen?.();
          setIsOpened(!isOpened);
        }}
        paddingX="s"
        paddingY="s"
        data-component="EntityMatchingCardWidget"
        aria-expanded={isOpened}
        {...props}
      >
        <Container width="full">
          <Group width="full" justifyContent="space-between" alignItems="center">
            {getTitle(entityA.title, entityA.brandSymbolKey, entityA.subTitles)}

            <Group.Item shrink={0}>
              <Container color="global.neutral.800" paddingTop="xs">
                <Icon type="sync" aria-label="icon-sync" color="inherit" />
              </Container>
            </Group.Item>

            {getTitle(entityB.title, entityB.brandSymbolKey, entityB.subTitles)}

            <Group.Item shrink={0}>
              <Container color="global.neutral.800" paddingTop="xs">
                <Icon
                  type={isOpened ? 'chevron-up' : 'chevron-down'}
                  aria-label={
                    isOpened
                      ? formatMessage('widgets.entityMatchingCard.collapseButton.ariaLabel.expanded')
                      : formatMessage('widgets.entityMatchingCard.collapseButton.ariaLabel.collapsed')
                  }
                  color="inherit"
                />
              </Container>
            </Group.Item>
          </Group>

          <Collapse in={isOpened}>
            <Container paddingY="s">
              <Divider />
            </Container>
            {body}
          </Collapse>
        </Container>
      </Card>
    );
  }
);

EntityMatchingCardWidget.displayName = 'EntityMatchingCardWidget';
