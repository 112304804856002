import { utils } from 'xlsx';

export const createTemplateToDownload = (fields: string[]) => {
  const template = Object.fromEntries(fields.map((fieldName) => [fieldName, '']));

  const ws = utils.json_to_sheet([template]);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Data');
  return wb;
};
