// eslint-disable-next-line no-restricted-imports
import {
  Container,
  Divider,
  Group,
  SplitScreen as PennySplitScreen,
  SplitScreenProps as PennySplitScreenProps,
  useBreakpoint,
} from '@melio/penny';
import { forwardRef, ReactNode } from 'react';

import { usePlatformDSConfig } from './PlatformDSProvider';

export const SplitScreen = forwardRef<HTMLDivElement, PennySplitScreenProps>(
  ({ panelA, panelB, footer, ...rest }, ref) => {
    const { isFullHeightMode } = usePlatformDSConfig();
    const { isExtraSmallScreen: isMobile } = useBreakpoint();
    const shouldRenderFullHeightMode = isFullHeightMode && !isMobile;

    const layoutFooter = (
      <Group variant="vertical" spacing="none" width="full">
        <Divider />
        <Container paddingY="s" paddingX={!shouldRenderFullHeightMode ? 's' : 'none'} width="full">
          {footer}
        </Container>
      </Group>
    );

    const getPanelFooter = (footer: ReactNode) =>
      shouldRenderFullHeightMode ? (
        <Container paddingTop="l" width="full">
          <Group variant="vertical" spacing="l" width="full">
            <Divider />
            {footer}
          </Group>
        </Container>
      ) : (
        <Group variant="vertical" spacing="none" width="full">
          <Divider />
          <Container paddingX="s" paddingY="s" width="full">
            {footer}
          </Container>
        </Group>
      );

    return shouldRenderFullHeightMode ? (
      <PennySplitScreen
        ref={ref}
        footer={footer && layoutFooter}
        panelA={
          panelA && {
            ...panelA,
            footer: undefined,
            content: (
              <>
                {panelA.content}
                {panelA.footer && getPanelFooter(panelA.footer)}
              </>
            ),
          }
        }
        panelB={
          panelB && {
            ...panelB,
            footer: undefined,
            content: (
              <>
                {panelB.content}
                {panelB.footer && getPanelFooter(panelB.footer)}
              </>
            ),
          }
        }
        {...rest}
      />
    ) : (
      <PennySplitScreen
        ref={ref}
        footer={footer && layoutFooter}
        panelA={
          panelA && {
            ...panelA,
            footer: panelA.footer && getPanelFooter(panelA.footer),
          }
        }
        panelB={
          panelB && {
            ...panelB,
            footer: panelB.footer && getPanelFooter(panelB.footer),
          }
        }
        {...rest}
      />
    );
  }
);
