import {
  InboxItem,
  InboxItemBillTypeEnum,
  InboxItemPayment,
  InboxItemPaymentRequestTypeEnum,
  Payment,
} from '@melio/platform-api';

import { PayDashboardTabSortableColumn } from '../../types';

export type SelectableInboxItemOptionsType = InboxItemPaymentRequestTypeEnum | InboxItemBillTypeEnum;

export type SelectedInboxItemType = {
  id: string;
  type: SelectableInboxItemOptionsType;
};

export type BillsTabItem = Exclude<InboxItem, InboxItemPayment>;

export type BillsTableMeta = {
  rowSelections: string[];
  onPayPaymentRequest: (paymentRequestId: string) => void;
  onReviewScannedInvoiceClick: (id: string) => void;
  onDeleteBillItemClick: ({ id, type }: SelectedInboxItemType) => void;
  onViewItemClick: ({ id }: { id: string }) => void;
  onEditItemClick: ({ id }: { id: string }) => void;
  onRejectClick: ({ id }: { id: string }) => void;
  onMarkAsPaid: ({ id }: { id: string }) => void;
};

export const CELLS_IDS = {
  VENDOR_NAME: 'vendorName',
  DELIVERY_METHOD: 'deliveryMethod',
  BILL: 'Bill',
  DUE_DATE: 'dueDate',
  STATUS: 'status',
  AMOUNT: 'amount',
  ACTION_BUTTON: 'actionButton',
  MORE_ACTIONS: 'moreActions',
  CREATED_AT: 'createdAt',
  INVOICE: 'invoice',
} as const;

export type BillsTabSortableCells = Extract<
  (typeof CELLS_IDS)[keyof typeof CELLS_IDS],
  | typeof CELLS_IDS.VENDOR_NAME
  | typeof CELLS_IDS.BILL
  | typeof CELLS_IDS.DUE_DATE
  | typeof CELLS_IDS.AMOUNT
  | typeof CELLS_IDS.CREATED_AT
>;

export type BillsTabSortableColumn = PayDashboardTabSortableColumn<BillsTabSortableCells>;

export type PaymentsTabListItemProps = {
  payment: Payment;
};

export enum BillsTabBillTypeFilter {
  All = 'all',
  Unpaid = 'unpaid',
  ForReview = 'forReview',
}

export enum BillsTabCreationDateFilterValues {
  Today = 'today',
  Last7Days = 'last7Days',
  Last30Days = 'last30Days',
  Last12Months = 'last12Months',
  Custom = 'custom',
}
export enum BillsTabDueDateFilterOptions {
  Any = 'any',
  Overdue = 'overdue',
  DueToday = 'dueToday',
  Next7Days = 'next7Days',
  Next30Days = 'next30Days',
  Custom = 'custom',
}
