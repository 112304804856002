import { Card, CardType, FeeType, FundingSource, FundingSourceType, PaymentFee } from '@melio/platform-api';
import { getDollarsFromCents } from '@melio/platform-utils';

export const convertPaymentFeeCentsToDollars = (paymentFees: PaymentFee[]) =>
  paymentFees.map((paymentFee) => ({
    type: paymentFee.type,
    amount: getDollarsFromCents(paymentFee.amount),
  }));

export const getFeeTypeByFundingSource = (fundingSource: FundingSource) =>
  fundingSource.type == FundingSourceType.BankAccount
    ? FeeType.Ach
    : (fundingSource?.details as Card).type === CardType.Credit
    ? FeeType.Credit
    : FeeType.Debit;

const payorFees = [
  'ach',
  'ach-to-ach',
  'ach-to-check',
  'credit',
  'debit',
  'domestic-wire',
  'express-domestic-wire',
  'expedited-ach',
  'expedited-ach-vendor',
  'express-check',
  'overnight-check',
  'international',
  'check',
  'rtp-organization',
  'paypal-balance-to-ach',
] as const;

type PayorFeeType = Extract<FeeType, (typeof payorFees)[number]>;
export type PayorPaymentFee = Override<PaymentFee, { type: PayorFeeType }>;

export const isPayorPaymentFee = (fee: PaymentFee): fee is PayorPaymentFee =>
  payorFees.some((payorFee) => payorFee === fee.type);

export const sumPaymentFees = (fees: PaymentFee[]): number =>
  fees.reduce((totalFees, fee) => totalFees + fee.amount, 0);
