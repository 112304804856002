import { Address } from '@melio/platform-api';

export const enum DataComponentEnum {
  VENDORS_PAGE = 'vendors-page',
  VENDORS_LIST = 'vendors-list',
  CARD_LIST = 'card-list',
  SORTED_CARD_LIST = 'sorted-card-list',
  SINGLE_VENDOR_PAGE = 'single-vendor-page',
  PAYMENT_SUMMARY = 'payment-summary',
  PAYMENT_OVERVIEW = 'payment-overview',
  RECURRING_PAYMENTS = 'recurring-payments',
  RECURRING_PAYMENT = 'recurring-payment',
  NEW_VENDOR_PAGE = 'new-vendor',
  DELIVERY_METHODS = 'delivery-methods',
  VENDOR_DETAILS = 'vendor-details',
  VENDOR_DETAILS_FORM = 'vendor-details-form',
  VENDOR_DETAILS_NEW_MANAGED_BY_FISERV_FORM = 'new-vendor-details-managed-by-fiserv-form',
  VENDOR_DETAILS_MANAGED_BY_FISERV_FORM = 'vendor-details-managed-by-fiserv-form',
  VENDOR_DETAILS_EXTENDED_UNMANAGED_FORM = 'vendor-details-extended-unmanaged-form',
}

export type VendorDetailsFormFields = {
  companyName: string | null;
  uniqueName?: string;
  nickname?: string | null;
  accountNumber?: string | null;
  address?: Address | null;
  fullName?: string | null;
  email?: string | null;
  phone?: string | null;
  confirmAccountNumber?: string | null;
  line1?: string;
  line2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string | null;
};

export interface VendorBillsTableBill {
  id: number;
  date: string;
  invoiceId: string;
  amount: string;
  category?: string;
}

export interface VendorBillsTableProps {
  vendor: { name: string; id: number };
  bills: VendorBillsTableBill[];
}
