// eslint-disable-next-line import/no-deprecated
import {
  addWildcardToRoutes,
  RouteElement,
  useCurrentRoute,
  useFlowRouting,
  useSystemMessage,
  withMemoryRouter,
  withOutlet,
} from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef, useDebounce } from '@melio/platform-utils';
import { useMemo, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AddBankAccountDetailsActivityProps,
  AddCardAccountDetailsActivity,
  AuthenticationModalActivity,
  CardDetailsFormValues,
  CreateCardPaymentActivity,
  IndustrySelectionModalActivity,
  OnboardingActivity,
} from '../activities';
import { useGetPaymentErrorNotification } from '../hooks';
import { GuestPaymentIntentParams, GuestPayorUserNameDetails } from '../types';

type CardPaymentFulfillmentFlowProps = Pick<
  AddBankAccountDetailsActivityProps,
  'onSelectFundingSource' | 'onViewInvoice'
> & {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (paymentId: string) => unknown;
  onError?: ARErrorFunction;
  partnerName: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
};

export const CardPaymentFulfillmentFlow = withMemoryRouter(
  withAnalyticsContext<CardPaymentFulfillmentFlowProps>(
    forwardRef(
      (
        {
          onViewInvoice,
          partnerName,
          onDone,
          guestPaymentIntentParams,
          setAnalyticsProperties,
          children,
          isNavigateApUsersToDashboardEnabled,
          onError,
          onLoggedIn,
          onSelectFundingSource,
          ...props
        },
        ref
      ) => {
        const { triggerMessage } = useSystemMessage();
        const { track } = useAnalytics();
        const [guestPaymentIntentId, setGuestPaymentIntentId] = useState<string>();

        const Router = useFlowNavigation();

        const getPaymentErrorNotification = useGetPaymentErrorNotification();
        const handleError = () => {
          track('PaymentRequest', 'Status', { ErrorType: 'payment-failed' });
          triggerMessage({ type: 'critical', title: getPaymentErrorNotification('card').description });
          Router.goToForm({ keepSystemMessage: true });
          document.querySelector('[data-testid="ar-system-message"]')?.scrollIntoView({ block: 'end' });
        };

        const [formValues, setFormValues] = useState<CardDetailsFormValues>();
        const email = useMemo(() => formValues?.email as string, [formValues]);
        const { firstName, lastName } = useMemo(
          () => (formValues?.cardOwner || {}) as GuestPayorUserNameDetails,
          [formValues]
        );

        return (
          <Routes>
            <Route
              path={Router.Paths.Form}
              element={withOutlet(
                <AddCardAccountDetailsActivity
                  onViewInvoice={onViewInvoice}
                  guestPaymentIntentParams={guestPaymentIntentParams}
                  isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                  onSelectFundingSource={onSelectFundingSource}
                  onError={onError}
                  isDisabled={Router.currentRoute !== 'Form'}
                  isHidden={Router.currentRoute === 'CreatePayment'}
                  onDone={(data) => {
                    setFormValues(data);
                    Router.goToAuth({ keepSystemMessage: true });
                  }}
                  {...props}
                  ref={ref}
                />
              )}
            >
              <Route
                path={Router.Paths.Auth}
                element={withOutlet(
                  <AuthenticationModalActivity
                    partnerName={partnerName}
                    email={email}
                    isLoading={Router.currentRoute != 'Auth'}
                    isOpen
                    onClose={() => Router.goToForm({ keepSystemMessage: true })}
                    onDone={() => Router.goToOnboarding({ keepSystemMessage: true })}
                    onError={onError}
                    onLoggedIn={onLoggedIn}
                    guestPaymentIntentParams={guestPaymentIntentParams}
                  />
                )}
              >
                <Route
                  path={Router.Paths.Onboarding}
                  element={
                    <OnboardingActivity
                      guestPaymentIntentParams={guestPaymentIntentParams}
                      userDetails={{ firstName, lastName }}
                      onClose={() => Router.goToForm({ keepSystemMessage: true })}
                      onError={handleError}
                      onDone={(vendorId, isMissingMcc, guestPaymentIntentId) => {
                        setGuestPaymentIntentId(guestPaymentIntentId);
                        if (isMissingMcc) {
                          Router.goToMCC(vendorId, { keepSystemMessage: true });
                        } else {
                          Router.goToCreatePayment();
                        }
                      }}
                    />
                  }
                />
              </Route>
              <Route
                path={Router.Paths.MCC}
                element={
                  <RouteElement
                    component={IndustrySelectionModalActivity}
                    pathToProps={{ vendorId: 'vendorId' }}
                    cardBrand={formValues?.cardParams.cardBrand}
                    // defer opening the modal to avoid flickering
                    isOpen={useDebounce(Router.currentRoute == 'MCC', 100)}
                    onDone={Router.goToCreatePayment}
                    onClose={() => Router.goToForm({ keepSystemMessage: true })}
                    onError={handleError}
                    guestPaymentIntentParams={guestPaymentIntentParams}
                  />
                }
              />
              <Route
                path={Router.Paths.CreatePayment}
                element={
                  <CreateCardPaymentActivity
                    formValues={formValues}
                    guestPaymentIntentParams={guestPaymentIntentParams}
                    onDone={onDone}
                    onClose={Router.goToForm}
                    onError={handleError}
                    guestPaymentIntentId={guestPaymentIntentId as string}
                  />
                }
              />
            </Route>
          </Routes>
        );
      }
    )
  )
);

CardPaymentFulfillmentFlow.displayName = 'CardPaymentFulfillmentFlow';

const useFlowNavigation = () => {
  enum Paths {
    Form = '',
    Auth = 'auth',
    Onboarding = 'onboarding',
    MCC = 'mcc/:vendorId',
    CreatePayment = 'create-payment',
  }

  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>();

  const currentRoute = useCurrentRoute(Paths);

  return {
    currentRoute,
    Paths: addWildcardToRoutes(Paths),
    goToForm: createCallback(Paths.Form),
    goToAuth: createCallback(Paths.Auth),
    goToOnboarding: createCallback(Paths.Onboarding, { pathPrefix: Paths.Auth }),
    goToMCC: createCallbackWithParam(Paths.MCC, 'vendorId'),
    goToCreatePayment: createCallback(Paths.CreatePayment),
  };
};
