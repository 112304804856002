import {
  FormattedMessage,
  InvoiceExpandedSummary,
  InvoiceTimeline,
  SharePaymentLink,
  useInvoiceStatusLabel,
} from '@melio/ar-domain';
import { Divider, Group, Icon, Pill, SectionBanner, Skeleton, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { InvoicePaymentSummeryDetails } from '../../../components';
import { InvoiceDrawerActionsHandlers } from '../../../utils';
import { useDrawerSectionBanner } from '../hooks';
import { InvoiceAttachments } from './InvoiceAttachments';
import { InvoiceBasicDetails } from './InvoiceBasicDetails';
import { InvoiceSummaryTitle } from './InvoiceSummaryTitle';

type DrawerBodyProps = {
  expandedInvoice: InvoiceExpandedSummary;
  invoiceTimeline?: InvoiceTimeline;
  companyName: string;
  invoiceActions: InvoiceDrawerActionsHandlers;
  isLoadingTimeline: boolean;
};

export const InvoiceSummary = forwardRef<DrawerBodyProps>(
  ({ companyName, expandedInvoice, invoiceTimeline, isLoadingTimeline, invoiceActions }, ref) => {
    const { getInvoiceStatusVariant, getInvoiceStatusLabel } = useInvoiceStatusLabel();
    const { title, description, variant, icon } = useDrawerSectionBanner(
      expandedInvoice,
      invoiceActions,
      invoiceTimeline
    );
    const hideSectionBanner =
      expandedInvoice.displayStatus == 'open' && !expandedInvoice.isAchAllowed && !expandedInvoice.isCardAllowed;

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        <Text as="h3" ref={ref} textStyle="heading3Semi">
          <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.header.label" />
        </Text>
        <Pill
          type="secondary"
          status={getInvoiceStatusVariant(expandedInvoice.displayStatus)}
          label={getInvoiceStatusLabel(expandedInvoice.displayStatus)}
          data-testid="invoice-drawer-badge"
        />
        {!hideSectionBanner &&
          (isLoadingTimeline ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Suppress TypeScript error for `Skeleton` component with `borderRadius`
            <Skeleton height="76px" borderRadius="8px" />
          ) : (
            <SectionBanner
              description={description}
              title={title}
              variant={variant}
              leftElement={icon ? <Icon type={icon} aria-hidden /> : undefined}
            />
          ))}
        <Group variant="vertical" spacing="m">
          {expandedInvoice.paymentRequest &&
            expandedInvoice.status !== 'draft' &&
            expandedInvoice.status !== 'fulfilled' && (
              <SharePaymentLink link={expandedInvoice.paymentRequest.link || ''} />
            )}
          <InvoiceSummaryTitle expandedInvoice={expandedInvoice} />
          <InvoiceBasicDetails expandedInvoice={expandedInvoice} companyName={companyName} />
          <InvoiceAttachments attachments={expandedInvoice.attachments} />
          <Divider />
          <InvoicePaymentSummeryDetails expandedInvoice={expandedInvoice} />
        </Group>
      </Group>
    );
  }
);
