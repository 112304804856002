import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import { CalendarLegendProps, Form, useMelioForm, UseMelioFormProps } from '@melio/penny';
import { PaymentFee } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';
import { object } from 'yup';

import { DeliveryDateFormFields } from '../types';

export type SingleDeliveryOptionFormProps = UseMelioFormProps<DeliveryDateFormFields> & {
  paymentFee: PaymentFee | null;
  debitDate: Date;
  minDate: Date;
  onSelectDate: (date: Date) => void;
  showHelperText: boolean;
};

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    deliveryDate: yup
      .date()
      .required(formatMessage('activities.deliveryDate.screens.deliveryDate.dateInput.requiredText')),
    deliveryPreferenceType: yup.string().required(),
  }) as yup.SchemaOf<DeliveryDateFormFields>;
};

export const SingleDeliveryOptionForm = ({
  paymentFee,
  debitDate,
  minDate,
  onSelectDate,
  onSubmit,
  onSubmissionStateChange,
  isLoading,
  values,
  showHelperText,
}: SingleDeliveryOptionFormProps) => {
  const { maxDeliveryDate } = usePaymentSchedulingPreference();
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { registerField, formProps, setValue, clearErrors, watch } = useMelioForm<DeliveryDateFormFields>({
    schema: useSchema(),
    onSubmit,
    onSubmissionStateChange,
    values,
    isSaving: isLoading,
  });

  const { amount: fee, type } = paymentFee || {};

  const isHelperTextShown = showHelperText && watch('deliveryDate') && !isLoading;

  const debitsOnHelperText = () =>
    formatMessage(
      type === 'virtual' || !fee
        ? 'activities.deliveryDate.screens.deliveryDate.dateInput.helperText'
        : 'activities.deliveryDate.screens.deliveryDate.dateInput.helperText.fee',
      {
        scheduleDate: formatDate(debitDate, { dateStyle: 'medium' }),
        fee: fee && formatCurrency(fee),
      }
    );

  const legendItems = [
    {
      label: formatMessage(`activities.deliveryDate.screens.deliveryDate.legend.deliveryDate`),
      variant: 'primary',
    },
  ] as CalendarLegendProps[];

  return (
    <Form data-testid="delivery-date-single-delivery-option-form" {...formProps}>
      <Form.DateField
        legendItems={legendItems}
        {...registerField('deliveryDate')}
        {...(isHelperTextShown && { helperTextProps: { label: debitsOnHelperText() } })}
        labelProps={{ label: formatMessage('activities.deliveryDate.screens.deliveryDate.dateInput.label') }}
        minDate={minDate}
        maxDate={maxDeliveryDate}
        placeholder={formatMessage('activities.deliveryDate.screens.deliveryDate.dateInput.placeholder')}
        onChange={(date) => {
          if (date) {
            onSelectDate(date);
            setValue('deliveryDate', date);
            clearErrors('deliveryDate');
          } else {
            setValue('deliveryDate', undefined);
          }
        }}
        showToday={false}
        excludeHolidays
        isRequired
        hideClear
      />
    </Form>
  );
};
