import { InternationalAddress, InternationalIdType, TaxIdType, useMelioIntl, yup } from '@melio/ar-domain';

import { OwnerDetailsFormValues, UsResident } from '../types';

export const useOwnerDetailsFormSchema = (isControlPerson = false) => {
  const { formatMessage } = useMelioIntl();

  const schema: yup.SchemaOf<OwnerDetailsFormValues> = yup.object().shape({
    firstName: yup
      .string()
      .mtl_contactFirstName(
        formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.firstName.validation.invalid')
      )
      .required(
        formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.firstName.validation.required')
      ),

    lastName: yup
      .string()
      .mtl_contactLastName(
        formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.firstName.validation.invalid')
      )
      .required(
        formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.lastName.validation.required')
      ),

    dateOfBirth: yup
      .string()
      .when('usResident', {
        is: UsResident.No,
        then: (schema) =>
          schema
            .dateFormat(
              'MM/dd/yyyy',
              formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.dateOfBirth.validation.invalid'
              )
            )
            .mtl_dateOfBirth(
              'MM/dd/yyyy',
              formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.dateOfBirth.validation.invalid'
              )
            ),
      })
      .when(['usResident', 'taxIdType'], {
        is: (usResident: UsResident, taxIdType?: TaxIdType) => usResident === 'yes' && !!taxIdType,
        then: (schema) =>
          schema.mtl_legalDateOfBirth(
            'taxIdType',
            'MM/dd/yyyy',
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.dateOfBirth.validation.invalid'
            )
          ),
      })
      .required(
        formatMessage(
          'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.dateOfBirth.validation.required'
        )
      ),

    ownershipPercentage: isControlPerson
      ? yup.number().nullable()
      : yup
          .number()
          .nullable()
          .required(
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.ownershipPercentage.validation.required'
            )
          )
          .typeError(
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.ownershipPercentage.validation.required'
            )
          )
          .min(
            0.25,
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.ownershipPercentage.validation.invalid'
            )
          )
          .max(
            1,
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.ownershipPercentage.validation.invalid'
            )
          ),

    usResident: yup.mixed().oneOf(Object.values(UsResident)).default(UsResident.Yes),

    address: (
      yup
        .object()
        .nullable()
        .shape({
          line1: yup.string(),
          line2: yup.string(),
          city: yup.string(),
          state: yup.string(),
          postalCode: yup.string(),
          countryCode: yup.string(),
        })
        .default(undefined) as yup.SchemaOf<InternationalAddress>
    ).required(
      formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.address.validation.required')
    ) as yup.SchemaOf<InternationalAddress>,

    taxIdType: yup
      .mixed()
      .oneOf(Object.values(TaxIdType))
      .when('usResident', {
        is: UsResident.Yes,
        then: (schema) =>
          schema.required(
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxIdType.validation.required'
            )
          ),
      }),

    taxId: yup.string().when('usResident', {
      is: UsResident.Yes,
      then: (schema) =>
        schema
          .mtl_validTaxId({ businessType: 'sole-proprietorship', taxIdType: 'taxIdType' })
          .required(
            formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxId.validation.required')
          ),
    }),

    internationalIdType: yup
      .mixed()
      .oneOf(Object.values(InternationalIdType))
      .when('usResident', {
        is: UsResident.No,
        then: (schema) =>
          schema.required(
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalIdType.validation.required'
            )
          ),
      }),

    internationalId: yup.string().when('usResident', {
      is: UsResident.No,
      then: (schema) =>
        schema
          .required(
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalId.validation.required'
            )
          )
          .matches(
            /^[0-9]{5,18}$/,
            formatMessage(
              'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalId.validation.invalid'
            )
          ),
    }),
    id: yup.number(),
    jobTitle: yup.mixed().nullable(),
    isDirector: yup.boolean(),
    isUbo: yup.boolean(),
  });

  return schema;
};
