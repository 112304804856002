import { isFieldReadonly as _isFieldReadonly } from '@melio/ap-domain';
import { InternationalAddressInput } from '@melio/ap-widgets';
import { Button, createNumberMask, Form, FormField, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment } from 'react';
import type { Path } from 'react-hook-form';

import { useTrackFormError } from '../hooks/useTrackFormError';
import { OwnershipDetailsForm } from '../types';
import { BirthdateField } from './BirthdateField';
import { ResidencyFields } from './ResidencyComponent';

export const OwnerDetailsForm = ({
  form,
  index,
  onRemove,
  ownershipPercentageRef,
  isViewMode,
}: {
  form: UseMelioFormResults<OwnershipDetailsForm>;
  index: number;
  onRemove?: VoidFunction;
  ownershipPercentageRef: React.RefObject<HTMLInputElement> | undefined;
  isViewMode: boolean;
}) => {
  const { formatMessage } = useMelioIntl();

  const isFieldReadonly = (fieldKey: Path<OwnershipDetailsForm>) =>
    _isFieldReadonly({
      form,
      fieldKey,
    });

  useTrackFormError<OwnershipDetailsForm>({
    form,
    pageName: 'ownership-details',
    intent: 'set-ownership-details',
    viewContext: 'Organization',
  });

  return (
    <Fragment>
      <Form.TextField
        isViewMode={isFieldReadonly(`ubos.${index}.ownerFirstName`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.firstNameField.label'
          ),
        }}
        {...form.registerField(`ubos.${index}.ownerFirstName`)}
        colSpan={3}
      />
      <Form.TextField
        isViewMode={isFieldReadonly(`ubos.${index}.ownerLastName`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.lastNameField.label'
          ),
        }}
        {...form.registerField(`ubos.${index}.ownerLastName`)}
        colSpan={3}
      />
      <BirthdateField
        isViewMode={isFieldReadonly(`ubos.${index}.dateOfBirth`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.dateOfBirthField.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.dateOfBirthField.placeholder'
        )}
        {...form.registerField(`ubos.${index}.dateOfBirth`)}
        colSpan={6}
      />
      <Form.TextField
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.label'
          ),
        }}
        ref={ownershipPercentageRef}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.placeholder'
        )}
        {...form.registerField(`ubos.${index}.ownershipPercentage`)}
        colSpan={6}
        isViewMode={isFieldReadonly(`ubos.${index}.ownershipPercentage`)}
        maskProps={{
          mask: createNumberMask({
            suffix: '%',
            prefix: '',
            thousandsSeparatorSymbol: '',
          }),
        }}
      />
      <ResidencyFields
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error*
        form={form}
        usResidencyFieldName={`ubos.${index}.residencyDetails.usResidence`}
        taxIdNumberFieldName={`ubos.${index}.residencyDetails.taxId`}
        taxIdTypeFieldName={`ubos.${index}.residencyDetails.taxIdType`}
        idTypeFieldName={`ubos.${index}.residencyDetails.idType`}
        idNumberFieldName={`ubos.${index}.residencyDetails.idNumber`}
        shouldShowReadOnly
        shouldShowResidencyReadOnly={isViewMode}
      />

      <InternationalAddressInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error*
        form={form}
        label="activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.address.label"
        addressLine1FieldName={`ubos.${index}.address.line1`}
        cityFieldName={`ubos.${index}.address.city`}
        countryCodeFieldName={`ubos.${index}.address.countryCode`}
        stateFieldName={`ubos.${index}.address.state`}
        postalCodeFieldName={`ubos.${index}.address.postalCode`}
      />
      {onRemove && (
        <FormField
          labelProps={{ label: '', isHidden: true }}
          size="small"
          colSpan={6}
          render={() => (
            <Button
              label={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.removeOwnerButton'
              )}
              onClick={onRemove}
              variant="tertiary"
              isFullWidth
              data-testid={`remove-additional-owner-${index}`}
            />
          )}
        />
      )}
    </Fragment>
  );
};
