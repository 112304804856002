import { MissingFieldsErrorsEnum, ValidationFieldsErrorsEnum, ValidationResponse } from '@melio/compliance-validator';
import { MessageKey } from '@melio/platform-i18n';

export const riskVallationResponseHandler = (validationResponse: ValidationResponse): MessageKey | undefined => {
  const { missingFieldError, InvalidFieldErrors } = validationResponse;
  const generalErrorText = 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.generalError';
  if (missingFieldError) {
    const missingFieldErrorMap: Record<MissingFieldsErrorsEnum, MessageKey> = {
      [MissingFieldsErrorsEnum.TaxIdMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxId.required',
      [MissingFieldsErrorsEnum.TaxIdTypeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdType.required',
      [MissingFieldsErrorsEnum.TaxIdTypeSsnMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeSsn.required',
      [MissingFieldsErrorsEnum.TaxIdTypeItinMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeItin.required',
      [MissingFieldsErrorsEnum.TaxIdTypeEinMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeEin.required',
      [MissingFieldsErrorsEnum.AddressLine1Missing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.addressField.required',
      [MissingFieldsErrorsEnum.CityMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.city.required',
      [MissingFieldsErrorsEnum.StateMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.state.required',
      [MissingFieldsErrorsEnum.ZipCodeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.zipCode.required',
      [MissingFieldsErrorsEnum.CountryCodeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.countryCode.required',
      [MissingFieldsErrorsEnum.LegalAddressLine1Missing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalAddressField.required',
      [MissingFieldsErrorsEnum.LegalCityMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCity.required',
      [MissingFieldsErrorsEnum.LegalStateMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalState.required',
      [MissingFieldsErrorsEnum.LegalZipCodeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalZipCode.required',
      [MissingFieldsErrorsEnum.LegalCountryCodeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCountryCode.required',
      [MissingFieldsErrorsEnum.ContactPhoneMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactPhone.required',
      [MissingFieldsErrorsEnum.ContactFirstNameMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactFirstName.required',
      [MissingFieldsErrorsEnum.ContactLastNameMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactLastName.required',
      [MissingFieldsErrorsEnum.ContactEmailMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactEmail.required',
      [MissingFieldsErrorsEnum.OrganizationUserDobMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.organizationUserDob.required',
      [MissingFieldsErrorsEnum.CompanyNameMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.companyName.required',
      [MissingFieldsErrorsEnum.LegalCompanyNameMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCompanyName.required',
      [MissingFieldsErrorsEnum.BusinessTypeMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessType.required',
      [MissingFieldsErrorsEnum.BusinessIndustryMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessIndustry.required',
      [MissingFieldsErrorsEnum.LegalDateOfBirthMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalDateOfBirth.required',
      [MissingFieldsErrorsEnum.OwnershipPercentageMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipPercentage.required',
    };

    return missingFieldErrorMap[missingFieldError] || generalErrorText;
  }
  if (InvalidFieldErrors && InvalidFieldErrors?.length > 0 && InvalidFieldErrors[0]) {
    const validationFieldErrorMap: Record<ValidationFieldsErrorsEnum, MessageKey> = {
      [ValidationFieldsErrorsEnum.TaxIdMustBe9Digits]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxId.mustBe9Digits',
      [ValidationFieldsErrorsEnum.TaxIdTypeSsnMustBe9Digits]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeSsn.mustBe9Digits',
      [ValidationFieldsErrorsEnum.TaxIdTypeEinMustBe9Digits]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeEin.mustBe9Digits',
      [ValidationFieldsErrorsEnum.TaxIdTypeItinMustBe9Digits]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeItin.mustBe9Digits',
      [ValidationFieldsErrorsEnum.TaxIdTypeSsnInvalidBusinessType]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeSsn.invalidBusinessType',
      [ValidationFieldsErrorsEnum.TaxIdTypeItinInvalidBusinessType]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeItin.invalidBusinessType',
      [ValidationFieldsErrorsEnum.TaxIdTypeItinInvalidTaxIdPrefix]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdTypeItin.invalidTaxIdPrefix',
      [ValidationFieldsErrorsEnum.TaxIdTypeNotAllowedValue]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxIdType.notAllowedValue',
      [ValidationFieldsErrorsEnum.StateMustBe2Chars]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.state.mustBe2Chars',
      [ValidationFieldsErrorsEnum.StateNotExists]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.state.notExists',
      [ValidationFieldsErrorsEnum.CountryCodeMustBeUsVariants]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.countryCode.mustBeUsVariants',
      [ValidationFieldsErrorsEnum.LegalStateMustBe2Chars]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalState.mustBe2Chars',
      [ValidationFieldsErrorsEnum.LegalStateNotExists]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalState.notExists',
      [ValidationFieldsErrorsEnum.LegalCountryCodeMustBeUsVariants]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCountryCode.mustBeUsVariants',
      [ValidationFieldsErrorsEnum.ContactPhoneInvalidFormat]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactPhone.invalidFormat',
      [ValidationFieldsErrorsEnum.ContactFirstNameMustBe2CharsOrMore]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactFirstName.mustBe2CharsOrMore',
      [ValidationFieldsErrorsEnum.ContactFirstNameMustAlphabeticSpaceHyphenDotComma]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactFirstName.mustBeAlphabetic',
      [ValidationFieldsErrorsEnum.ContactLastNameMustBe2CharsOrMore]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactLastName.mustBe2CharsOrMore',
      [ValidationFieldsErrorsEnum.ContactLastNameMustAlphabeticSpaceHyphenDotComma]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactLastName.mustBeAlphabetic',
      [ValidationFieldsErrorsEnum.ContactEmailFormatInvalid]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.contactEmail.invalidFormat',
      [ValidationFieldsErrorsEnum.OrganizationUserAgeMustBeAbove18]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.organizationUser.ageAbove18',
      [ValidationFieldsErrorsEnum.OrganizationUserAgeMustBeBelow120]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.organizationUser.ageBelow120',
      [ValidationFieldsErrorsEnum.LegalDateOfBirthAgeMustBeAbove18]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalDateOfBirth.ageAbove18',
      [ValidationFieldsErrorsEnum.LegalDateOfBirthAgeMustBeBelow120]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalDateOfBirth.ageBelow120',
      [ValidationFieldsErrorsEnum.CompanyNameMustBe3CharsOrMore]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.companyName.mustBe3CharsOrMore',
      [ValidationFieldsErrorsEnum.CompanyNameMustHaveAtLeast2CharsAlphanumeric]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.companyName.mustHaveAtLeast2AlphanumericChars',
      [ValidationFieldsErrorsEnum.LegalCompanyNameMustBe3CharsOrMore]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCompanyName.mustBe3CharsOrMore',
      [ValidationFieldsErrorsEnum.LegalCompanyNameMustHaveAtLeast2CharsAlphanumeric]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.legalCompanyName.mustHaveAtLeast2AlphanumericChars',
      [ValidationFieldsErrorsEnum.BusinessTypeNotAllowedValue]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessType.notAllowedValue',
      [ValidationFieldsErrorsEnum.BusinessIndustryMustBeUserProvided]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessIndustry.mustBeUserProvided',
      [ValidationFieldsErrorsEnum.BusinessIndustryMustHaveAtLeastOneNaicsCodeOrIndustryText]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessIndustry.mustHaveNaicsCodeOrIndustryText',
      [ValidationFieldsErrorsEnum.AddressLine1ContainsPOBox]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.addressField.containsPOBox',
      [ValidationFieldsErrorsEnum.UsResidentIsMissing]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.usResident.isMissing',
      [ValidationFieldsErrorsEnum.OwnershipPercentageMustBeBetween25And100]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipPercentage.mustBeBetween25And100',
      [ValidationFieldsErrorsEnum.OwnershipPercentageMaxSumMustBe100]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipPercentage.maxSumMustBe100',
      [ValidationFieldsErrorsEnum.InternationalIdTooShort]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipPercentage.internationalIdTooShort',
      [ValidationFieldsErrorsEnum.InternationalIdNotAllowedForUsResident]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.internationalId.notAllowedForUsResident',
      [ValidationFieldsErrorsEnum.InternationalIdTypeNotAllowedForUsResident]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.internationalId.typeNotAllowedForUsResident',
      [ValidationFieldsErrorsEnum.InternationalIdTypeInvalidValue]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.internationalId.invalidType',
      [ValidationFieldsErrorsEnum.TaxIdNotAllowedForNonUsResident]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxId.notAllowedForNonUsResident',
      [ValidationFieldsErrorsEnum.TaxIdTypeEinNotAllowedForIndividual]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxId.einNotAllowedForIndividual',
      [ValidationFieldsErrorsEnum.TaxIdTypeNotAllowedForNonUsResident]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.taxId.typeNotAllowedForNonUsResident',
      [ValidationFieldsErrorsEnum.OrganizationKeyIndividualTypeInvalidValue]:
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.organizationKey.invalidIndividualType',
    };
    return validationFieldErrorMap[InvalidFieldErrors[0]] || generalErrorText;
  }
  return;
};
