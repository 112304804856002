import { Button, Container, Group } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onCancelFlowClick: () => void;
  onReuploadFileClick: () => void;
  onImportVendorsClick: () => void;
  vendorsCount: number;
};

export const FooterContent = ({
  onCancelFlowClick,
  onReuploadFileClick,
  onImportVendorsClick,
  vendorsCount,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container justifyContent="space-between">
      <Group>
        <Group.Item>
          <Button
            variant="tertiary"
            onClick={onCancelFlowClick}
            label={formatMessage('activities.importEntities.reviewVendorsTable.footer.actions.cancel')}
            size="medium"
            data-testid="review-vendors-table-footer-button-cancel"
          />
        </Group.Item>
      </Group>
      <Group justifyContent="flex-end">
        <Group.Item>
          <Button
            variant="tertiary"
            onClick={onReuploadFileClick}
            label={formatMessage('activities.importEntities.reviewVendorsTable.footer.actions.reUpload')}
            size="medium"
            data-testid="review-vendors-table-footer-button-reupload"
          />
        </Group.Item>
        <Group.Item>
          <Button
            variant="primary"
            onClick={onImportVendorsClick}
            label={formatMessage('activities.importEntities.reviewVendorsTable.footer.actions.import', {
              count: vendorsCount,
              plural: vendorsCount > 1 && 's',
            })}
            size="medium"
            data-testid="review-vendors-table-footer-button-import"
          />
        </Group.Item>
      </Group>
    </Container>
  );
};
