import { Group, Link, Text } from '@melio/penny';
import React from 'react';

export type ProTipItem = {
  id: string;
  title: string;
  description: string;
  linkText: string;
  image: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
};

export const ProTipItem = ({ image, title, description, linkText, onClick }: ProTipItem) => (
  <>
    {image}
    <Group width="full" spacing="xs" variant="vertical">
      <Text textStyle="body3Semi">{title}</Text>
      <Text textStyle="body4">{description}</Text>
      <Link color="secondary" href="#" label={linkText} variant="standalone" size="medium" onClick={onClick} />
    </Group>
  </>
);
