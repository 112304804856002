import { FormattedMessage, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Avatar, Button, Container, Form, Group, Icon, LoadingContainer, Pill, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { date, object, SchemaOf } from 'yup';

import { ScheduledDateField, useEstimatedDeliveryDate } from '../../../../components';
import { PaymentLayout, PaymentLayoutProps } from '../../../../layout';
import { ScheduledDateDetails } from '../../../types';
import { getClosestBusinessDay } from '../../../utils';

const useSchema = () => {
  const { formatMessage } = useMelioIntl();

  return object().shape({
    scheduledDate: date().required(formatMessage('ar.guestPayment.activities.scheduledDate.required.label')),
  }) as SchemaOf<ScheduledDateDetails>;
};

export type PayByBankReviewAndConfirmScreenProps = Pick<
  PaymentLayoutProps,
  'onViewInvoice' | 'guestPaymentIntentData' | 'isLoading'
> & {
  onSubmit: ({ scheduledDate }: { scheduledDate: Date }) => void;
  fundingSourceName?: string;
  logo?: string;
};

export const PayByBankReviewAndConfirmScreen = forwardRef<PayByBankReviewAndConfirmScreenProps>(
  ({ logo, fundingSourceName, onSubmit, onViewInvoice, guestPaymentIntentData, isLoading, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { track } = useAnalytics();
    const minScheduledDate = getClosestBusinessDay();
    const { formatDate, formatCurrency, formatMessage } = useMelioIntl();
    const { formProps, registerField, watch } = useMelioForm<ScheduledDateDetails>({
      onSubmit,
      schema: useSchema(),
      defaultValues: { scheduledDate: minScheduledDate },
    });

    const handleOnSubmit = () => {
      track('PaymentRequest', 'Click', {
        Intent: 'pay-invoice',
        Cta: 'pay',
      });
      return onSubmit({ scheduledDate: watch('scheduledDate') });
    };

    return (
      <PaymentLayout
        data-testid="pay-by-bank-review-and-confirm-screen"
        guestPaymentIntentData={guestPaymentIntentData}
        onViewInvoice={onViewInvoice}
        shouldHidePaymentMethodSelection
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="m">
            <LoadingContainer isLoading={isLoading}>
              <Group variant="vertical" spacing="xxs">
                <Text textStyle="body4Semi" color="semantic.text.secondary">
                  <FormattedMessage id="ar.guestPayment.payByBank.form.bankAccountDetails.label" />
                </Text>
                <Container border="regular" paddingX="m" paddingY="m">
                  <Group variant="vertical">
                    <Group alignItems="center">
                      {logo ? <Avatar name={fundingSourceName || ''} src={logo} /> : <Icon type="bank" />}
                      <Text textStyle="body2Semi">{fundingSourceName}</Text>
                    </Group>
                  </Group>
                </Container>
              </Group>
            </LoadingContainer>
            <Form data-component="ScheduledDateForm" size={isMobile ? 'small' : 'large'} {...formProps}>
              <Group variant="vertical" spacing="xs-s" width="full">
                <ScheduledDateField
                  labelProps={{ label: formatMessage('ar.guestPayment.activities.scheduledDate.label') }}
                  fundingSourceType="bank-account"
                  minDate={minScheduledDate}
                  {...registerField('scheduledDate')}
                />
                <Pill
                  status="neutral"
                  type="secondary"
                  label={formatMessage('ar.guestPayment.activities.scheduledDate.estimation.label', {
                    date: formatDate(useEstimatedDeliveryDate(watch('scheduledDate'), 'bank-account')),
                  })}
                />
              </Group>
            </Form>
          </Group>
          <Button
            isDisabled={isLoading}
            data-testid="create-payment-submit-button"
            size="large"
            label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
              amount: formatCurrency(guestPaymentIntentData?.invoice.totalAmount ?? 0),
            })}
            onClick={handleOnSubmit}
          />
        </Group>
      </PaymentLayout>
    );
  }
);
PayByBankReviewAndConfirmScreen.displayName = 'PayByBankReviewAndConfirmScreen';
