import { usePaymentSchedulingPreference } from '@melio/ap-domain';
import {
  DeliveryMethod,
  DeliveryMethodType,
  DeliveryPreferenceType,
  usePayment,
  usePaymentIntent,
} from '@melio/platform-api';
import { useSystemMessage } from '@melio/platform-utils';
import { isEqual } from 'lodash';
import { useState } from 'react';

import { useCreatePaymentIntentFromPayment } from '../../../utils/useCreatePaymentIntentFromPayment';
import { checkHasDeliveryFastOption } from '../utils/checkHasDeliveryFastOption';
import { useVoidAndResendWizard } from './useVoidAndResendWizard';

type Props = {
  paymentId: string;
  onError?: ErrorFunction;
  goNextMap: ReturnType<typeof useVoidAndResendWizard>['goNextMap'];
  goBack: ReturnType<typeof useVoidAndResendWizard>['goBack'];
};

export const useVoidAndResendActivity = ({ paymentId, onError, goNextMap, goBack }: Props) => {
  const { showMessage } = useSystemMessage();

  const {
    data: payment,
    isLoading: isLoadingPayment,
    refetch: refetchPayment,
    voidAndResend,
    isMutating: isPaymentMutating,
  } = usePayment({ id: paymentId });
  const { paymentIntentId, isLoading: isLoadingPaymentIntentFromPayment } = useCreatePaymentIntentFromPayment({
    paymentId,
  });
  const {
    data: paymentIntent,
    update: updatePaymentIntent,
    isUpdating: isUpdatingPaymentIntent,
  } = usePaymentIntent({ id: paymentIntentId, enabled: !!paymentIntentId });
  const { isByDeliveryDate } = usePaymentSchedulingPreference();

  const handleFail = (error: PlatformError) => {
    showMessage({ type: 'error', title: error.message });

    onError?.(error);
  };

  const getDeliveryMethodByType = (type: DeliveryMethodType) =>
    payment?.vendor?.deliveryMethods.find((dm) => dm.type === type);

  const paperCheckDeliveryMethod = getDeliveryMethodByType(DeliveryMethodType.PaperCheck);
  const bankAccountDeliveryMethod = getDeliveryMethodByType(DeliveryMethodType.BankAccount);

  const [updatedFields, setUpdatedFields] = useState<Array<'deliveryMethod' | 'effectiveDeliveryDate'>>([
    'effectiveDeliveryDate',
  ]);

  const hasDeliveryFastOption = paymentIntent && checkHasDeliveryFastOption(paymentIntent);

  const handleResendPaperCheck = async () => {
    try {
      await updatePaymentIntent({ deliveryMethodId: paperCheckDeliveryMethod?.id });
      goNextMap['chooseHowToResend']({ navArgs: [{ isResendingPaperCheck: true }] });
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleSwitchToBankTransfer = async () => {
    try {
      if (bankAccountDeliveryMethod) {
        await updatePaymentIntent({ deliveryMethodId: bankAccountDeliveryMethod?.id });
      }

      goNextMap['chooseHowToResend']({ navArgs: [{ isBankAccountDeliveryMethodExist: !!bankAccountDeliveryMethod }] });
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleAddBankDetailsDone = async (newBankAccountDeliveryMethod: DeliveryMethod) => {
    try {
      await updatePaymentIntent({ deliveryMethodId: newBankAccountDeliveryMethod.id });
      refetchPayment();

      setUpdatedFields((prev) => [...prev, 'deliveryMethod']);

      goNextMap['addBankDetails']();
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleConfirmMailingAddressDone = async (newPaperCheckDeliveryMethod: DeliveryMethod) => {
    try {
      await updatePaymentIntent({ deliveryMethodId: newPaperCheckDeliveryMethod.id });
      refetchPayment();

      setUpdatedFields((prev) =>
        isEqual(newPaperCheckDeliveryMethod.details, paperCheckDeliveryMethod?.details)
          ? prev
          : [...prev, 'deliveryMethod']
      );

      goNextMap['confirmMailingAddress']({ navArgs: [{ hasDeliveryFastOption }] });
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleChooseDeliverySpeedDone = async (
    selectedDeliveryPreferenceType: DeliveryPreferenceType,
    schedulingDate: Date
  ) => {
    try {
      const paymentIntentUpdateData = {
        selectedDeliveryPreferenceType,
        ...(isByDeliveryDate ? { deliveryDate: schedulingDate } : { scheduledDate: schedulingDate }),
      };

      await updatePaymentIntent(paymentIntentUpdateData);
      refetchPayment();

      goNextMap['chooseDeliverySpeed']();
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleReviewAndConfirmPaymentDone = async () => {
    try {
      const availableDeliveryPreferenceType: DeliveryPreferenceType[] = [
        DeliveryPreferenceType.ExpressCheck,
        DeliveryPreferenceType.OvernightCheck,
      ];

      const canUseSelectedDeliveryPreferenceType =
        paymentIntent?.selectedDeliveryPreferenceType &&
        availableDeliveryPreferenceType.includes(paymentIntent?.selectedDeliveryPreferenceType);

      if (paymentIntent?.deliveryMethodId) {
        await voidAndResend({
          deliveryMethodId: paymentIntent.deliveryMethodId,
          deliveryPreference: canUseSelectedDeliveryPreferenceType
            ? paymentIntent.selectedDeliveryPreferenceType ?? undefined
            : undefined,
        });
      }

      goNextMap['reviewAndConfirmPayment']();
    } catch (err) {
      handleFail(err as PlatformError);
    }
  };

  const handleEditDate =
    paymentIntent?.deliveryMethod?.type === DeliveryMethodType.PaperCheck && hasDeliveryFastOption
      ? () => goBack()
      : undefined;

  return {
    isLoadingPayment,
    isLoadingPaymentIntentFromPayment,
    isUpdatingPaymentIntent,
    paymentIntent,
    payment,
    paymentIntentId,
    updatePaymentIntent,
    refetchPayment,
    handleFail,
    handleResendPaperCheck,
    handleSwitchToBankTransfer,
    handleAddBankDetailsDone,
    handleChooseDeliverySpeedDone,
    handleConfirmMailingAddressDone,
    handleEditDate,
    handleReviewAndConfirmPaymentDone,
    updatedFields,
    paperCheckDeliveryMethod,
    bankAccountDeliveryMethod,
    isPaymentMutating,
  };
};
