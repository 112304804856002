import { Text } from '@melio/penny';
import { ReactNode } from 'react';

type Props = {
  isValid?: boolean;
  children: ReactNode;
  textStyle?: string;
};

export const RowTextWithState = ({ isValid = true, children, textStyle = 'body3' }: Props) => (
  <Text textStyle={textStyle} color={isValid ? undefined : 'semantic.text.secondary'}>
    {children}
  </Text>
);
