import { Group, Image, Text } from '@melio/penny';
import { LazyAnimation } from '@melio/platform-utils';

type MigrationUserTourStepProps = {
  title: string;
  description: string;
  animationSrc: string;
  firstAnimationSrc: string;
  stepNumber: number;
};

export const MigrationUserTourStep = ({
  firstAnimationSrc,
  description,
  animationSrc,
  title,
  stepNumber,
}: MigrationUserTourStepProps) => (
  <Group variant="vertical" spacing="m">
    <Text
      data-testid={`migration-user-tour-step-title-${stepNumber}`}
      textStyle="body3Semi"
      color="semantic.text.inverse"
    >
      {title}
    </Text>
    <LazyAnimation
      id={`migration-user-tour-step-animation-${stepNumber}`}
      src={animationSrc}
      loop
      width="264px"
      height="142px"
      fallback={<Image alt="" src={firstAnimationSrc} aria-hidden width="264px" height="142px" />}
      aria-hidden
    />
    <Text
      data-testid={`migration-user-tour-step-description-${stepNumber}`}
      textStyle="body3"
      color="semantic.text.inverse"
    >
      {description}
    </Text>
  </Group>
);
