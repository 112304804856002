import { Collapse, SectionBanner, SectionBannerProps } from '@melio/penny';
import { MutableRefObject, useEffect, useRef } from 'react';

export const ErrorBanner = ({
  message,
  dataTestId,
  variant = 'critical',
}: {
  message?: string;
  dataTestId?: string;
  variant?: SectionBannerProps['variant'];
}) => {
  const errorBannerRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement | null>;

  useEffect(() => {
    if (message && errorBannerRef.current?.scrollIntoView) {
      const focusedElement = errorBannerRef.current.parentElement?.parentElement || errorBannerRef.current;
      focusedElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [message]);

  return (
    <Collapse in={!!message}>
      <SectionBanner data-testid={dataTestId} variant={variant} title={message} ref={errorBannerRef} />
    </Collapse>
  );
};
