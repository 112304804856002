import { FormattedCurrency, InvoiceSummary, useMelioIntl } from '@melio/ar-domain';
import { Container, Divider, Group, IconButton, NakedButton, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { DiscountProps, InvoiceFormMode, ServiceChargesProps, TaxRateProps } from '../../../types';

export type InvoiceItemsSummaryProps = {
  taxRate: TaxRateProps;
  discount: DiscountProps;
  serviceCharges: ServiceChargesProps;
  isDisabled?: boolean;
  isSaving?: boolean;
  invoiceSummary?: InvoiceSummary;
  mode?: InvoiceFormMode;
};

export const InvoiceItemsSummary = forwardRef<InvoiceItemsSummaryProps>(
  (
    {
      isDisabled,
      isSaving,
      discount: { onRemove: onDiscountRemove, onEdit: onDiscountEdit },
      taxRate: { onEdit: onTaxRateEdit },
      serviceCharges: { onAdd: onAddServiceCharge, onRemove: onRemoveServiceCharge },
      invoiceSummary: { serviceCharges, subTotal = 0, totalAmount = 0, discount, taxRate } = {},
      mode,
      ...props
    },
    ref
  ) => {
    const { formatCurrency, formatPercents } = useMelioIntl();
    const isEditable = !isDisabled && !isSaving;

    const containerProps = {
      role: 'group',
      opacity: isDisabled ? 0.3 : isSaving ? 0.6 : 1,
      pointerEvents: isDisabled || isSaving ? 'none' : 'all',
    };

    const { formatMessage } = useMelioIntl();

    const { track } = useAnalytics();

    return (
      <Container
        border="regular"
        paddingX="m"
        paddingY="m"
        data-component={InvoiceItemsSummary.displayName}
        {...containerProps}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="s" alignItems="flex-start">
          <EditableRow data-testid="editable-row-subtotal" label="Subtotal" value={formatCurrency(subTotal)} />
          {discount ? (
            <EditableRow
              data-testid="editable-row-discount"
              label={`${discount.name} ${discount.type == 'percentage' ? `(${formatPercents(discount.value)})` : ''}`}
              onEdit={mode == 'create' ? onDiscountEdit : undefined}
              value={formatCurrency(0 - discount.calculateValue)}
              onRemove={onDiscountRemove}
              isEditable={isEditable}
            />
          ) : (
            <NakedButton
              data-testid="add-discount-button"
              onClick={() => {
                onDiscountEdit?.();
                track('Invoice', 'Click', {
                  Intent: 'add-discount',
                  Cta: 'add-discount',
                });
              }}
              label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.addAnotherDiscount.label')}
              variant="secondary"
            />
          )}
          {taxRate?.calculateValue ? (
            <EditableRow
              data-testid="editable-row-tax-rate"
              label={`${taxRate.name} (${formatPercents(taxRate.rate)})`}
              value={formatCurrency(taxRate.calculateValue)}
              onEdit={onTaxRateEdit}
              isEditable={isEditable}
            />
          ) : null}
          {serviceCharges?.map((serviceCharge, index) => (
            <EditableRow
              key={[serviceCharge.name, serviceCharge.type, serviceCharge.value, index].join('.')}
              data-testid={`row-service-charge-${index}`}
              label={`${serviceCharge.name} ${
                serviceCharge.type == 'percentage' ? `(${formatPercents(serviceCharge.value)})` : ''
              }`}
              value={formatCurrency(serviceCharge.calculateValue)}
              onRemove={() => onRemoveServiceCharge?.(index)}
              isEditable={isEditable}
            />
          ))}
          <NakedButton
            data-testid="add-service-charge-button"
            onClick={() => {
              onAddServiceCharge?.();
              track('Invoice', 'Click', {
                Intent: 'add-service-charge',
                Cta: 'add-service-charge',
              });
            }}
            label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.addServiceCharge.label')}
            variant="secondary"
          />
          <Divider />
          <Group variant="horizontal" spacing="s" justifyContent="space-between" width="full">
            <Text textStyle="heading3Semi">Total</Text>
            <Text textStyle="heading3Semi" data-testid="total-amount-value">
              <FormattedCurrency amount={totalAmount} />
            </Text>
          </Group>
        </Group>
      </Container>
    );
  }
);
InvoiceItemsSummary.displayName = 'InvoiceItemsSummary';

type EditableRowProps = {
  label: string;
  value: string;
  onEdit?: VoidFunction;
  onRemove?: VoidFunction;
  isEditable?: boolean;
  isHidden?: boolean;
};

const EditableRow = forwardRef<EditableRowProps>(
  ({ label, value, onEdit, onRemove, isEditable, isHidden, ...props }, ref) => {
    const isActionable = isEditable && !!(onEdit || onRemove);
    const actionsContainerProps = {
      opacity: 0,
      pointerEvents: 'none',
      transition: 'opacity 0.3s',
      _groupHover: isActionable ? { opacity: 1, pointerEvents: 'all' } : undefined,
      _groupFocusWithin: isActionable ? { opacity: 1, pointerEvents: 'all' } : undefined,
    };

    return isHidden ? null : (
      <Group
        variant="horizontal"
        spacing="s"
        justifyContent="space-between"
        alignItems="center"
        width="full"
        data-component={EditableRow.displayName}
        {...props}
        ref={ref}
      >
        <Group variant="horizontal" alignItems="center" spacing="xs">
          <Text shouldSupportEllipsis textStyle="body3">
            {label}
          </Text>

          <Group variant="horizontal" alignItems="center" spacing="xs" {...actionsContainerProps}>
            {onEdit ? (
              <IconButton
                icon="edit"
                onClick={onEdit}
                aria-label="Edit"
                size="small"
                variant="naked"
                data-testid="edit-button"
              />
            ) : null}
            {onRemove ? (
              <IconButton
                icon="cancel-circle"
                onClick={onRemove}
                aria-label="Remove"
                size="small"
                variant="naked"
                data-testid="remove-button"
              />
            ) : null}
          </Group>
        </Group>
        <Text textStyle="body3" data-testid="row-value">
          {value}
        </Text>
      </Group>
    );
  }
);

EditableRow.displayName = 'EditableRow';
