import { Box, Flex } from '@chakra-ui/react';
import { Container, Grid, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import Big from 'big.js';

import { SubscriptionBillingCycleEnum } from '../../../../api';

export type PlanCardPriceProps = {
  isFreePlan: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  finalPrice: number;
  priceBeforeDiscount?: number;
  unitPrice?: number;
  showUnitPriceDetails?: boolean;
};

export const PlanCardPrice = ({
  isFreePlan,
  selectedBillingCycle,
  finalPrice,
  priceBeforeDiscount,
  unitPrice,
  showUnitPriceDetails,
}: PlanCardPriceProps) => {
  const { formatCurrency, formatMessage } = useMelioIntl();

  const gridItemsNumber = showUnitPriceDetails ? 3 : 2;

  return (
    <Grid gridTemplateRows="subgrid" gridRow={`auto / span ${gridItemsNumber}`} gap="none">
      <Container>
        {!!priceBeforeDiscount && (
          <Text textStyle="body2" color="global.neutral.600">
            <s data-testid="plan-card-price-before-discount">
              {formatCurrency(priceBeforeDiscount, undefined, { notation: 'compact' })}
            </s>
          </Text>
        )}
      </Container>

      <Group alignItems="center" spacing="xxs">
        <Text textStyle="display2Semi" data-testid="plan-card-price">
          {formatCurrency(new Big(finalPrice).round(0, 3).toNumber(), 'USD', {
            // Decimal places: 0, Rounding mode: 3 (up), once we upgrade to node 19 we can just do:
            // roundingMode: 'ceil'
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })}
        </Text>
        <Box as="span" textStyle="body2Semi" hidden={isFreePlan}>
          {formatMessage('activities.subscription.plans.planCard.price.perMonth')}
        </Box>
      </Group>

      {showUnitPriceDetails ? (
        <Container paddingTop="s">
          <Flex direction="column">
            {!isFreePlan && (
              <Text textStyle="body3" data-testid="plan-card-unit-price">
                {formatMessage('activities.subscription.plans.planCard.price.description.excessUser', {
                  unitPrice: formatCurrency(unitPrice ?? 0, undefined, { notation: 'compact' }),
                })}
              </Text>
            )}
            <Text textStyle="body3" color="global.neutral.800">
              {formatMessage(
                `activities.subscription.plans.planCard.price.description.${isFreePlan ? 'free' : selectedBillingCycle}`
              )}
            </Text>
          </Flex>
        </Container>
      ) : null}
    </Grid>
  );
};
