import { PaymentIntent } from '@melio/platform-api';
import { useState } from 'react';

import { checkHasDeliveryFastOption } from '../utils/checkHasDeliveryFastOption';
import { FailedToCollectPaymentFlowActivityProps, FailedToCollectPaymentFlowActivityStep } from './types';

const initialStep: FailedToCollectPaymentFlowActivityStep = 'FUNDING_SOURCE_SELECTION';
const lastStep: FailedToCollectPaymentFlowActivityStep = 'PAYMENT_SCHEDULED';

type UseFailedToCollectFlowActivityStepProps = {
  updatedPaymentIntent?: PaymentIntent;
  onFirstStepBack: FailedToCollectPaymentFlowActivityProps['onBack'];
};

export const useFailedToCollectActivityStep = ({
  updatedPaymentIntent,
  onFirstStepBack,
}: UseFailedToCollectFlowActivityStepProps) => {
  const [currentStep, setCurrentStep] = useState<FailedToCollectPaymentFlowActivityStep>(initialStep);

  const goToPreviousStep = (): void => {
    if (!updatedPaymentIntent) {
      return;
    }

    if (currentStep === initialStep) {
      return onFirstStepBack();
    }

    if (currentStep === lastStep) {
      // shouldn't happen
      return;
    }

    if (currentStep === 'REVIEW_AND_CONFIRM') {
      return setCurrentStep(
        checkHasDeliveryFastOption(updatedPaymentIntent) ? 'DELIVERY_SPEED_DATE_SELECTION' : 'FUNDING_SOURCE_SELECTION'
      );
    }

    const previousStepMap: Omit<
      Record<FailedToCollectPaymentFlowActivityStep, FailedToCollectPaymentFlowActivityStep>,
      typeof initialStep | typeof lastStep
    > = {
      DELIVERY_SPEED_DATE_SELECTION: 'FUNDING_SOURCE_SELECTION',
      REVIEW_AND_CONFIRM: checkHasDeliveryFastOption(updatedPaymentIntent)
        ? 'DELIVERY_SPEED_DATE_SELECTION'
        : 'FUNDING_SOURCE_SELECTION',
    };

    setCurrentStep(previousStepMap[currentStep]);
  };

  return { currentStep, goToStep: setCurrentStep, goToPreviousStep };
};
