import { DeliveryMethod, Vendor } from '@melio/platform-api';
import { SystemMessage } from '@melio/platform-utils';

export type AddVendorActivityStep = 'SEARCH_VENDOR' | 'ADD_VENDOR_DETAILS' | 'ADD_VENDOR_DELIVERY_METHOD';

export type AddVendorActivityProps = {
  onDone: (vendorId: Vendor['id'], deliveryMethodId?: DeliveryMethod['id'], target?: 'continue' | 'close') => void;
  onClose: VoidFunction;
  onError?: ErrorFunction;
};

export type AddVendorProps = AddVendorActivityProps & {
  onCreateVendorSuccess: (message: SystemMessage) => void;
};

export enum SubmitTarget {
  CLOSE = 'close',
  CONTINUE = 'continue',
}
