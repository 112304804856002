/* eslint-disable max-lines */
import { Container, Group, IconButton, SectionBanner, Text, useMelioForm } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount, useVerifiedPersonas } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useDevFeature } from '@melio/platform-feature-flags';
import { FeatureFlags } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { FXPersonalDetailsCard } from './components/AccountHolderDetailsCard';
import { FXBusinessDetailsCard } from './components/BusinessDetailsCard';
import { FXDecisionMakerCard } from './components/DecisionMakerCard';
import { FXOwnershipDetailsCard } from './components/OwnershipDetailsCard';
import { TermsAndConditionsSection } from './components/TermsAndConditionsSection';
import { UnsupportedFxTypeModal } from './components/UnsupportedFxTypeModal';
import { fXVerifiedPersonasFormToDataModel } from './fXVerifiedPersonasFormToDataModel';
import { useAccountHolderForm } from './hooks/useAccountHolderForm';
import { useDecisionMakerFormSchema } from './hooks/useDecisionMakerFormSchema';
import { useFxBusinessDetailsForm } from './hooks/useFxBusinessDetailsForm';
import { useGetUBOFormValues, useOwnershipDetailsForm } from './hooks/useOwnershipDetailsForm';
import { useTermsAndConditionsFormSchema } from './hooks/useTermsAndConditionsFormSchema';
import { DecisionMakerForm, FXBusinessDetailsFormSteps, TermsAndConditionsForm, UnsupportedModalValues } from './types';

export type AddFxDeliveryMethodBusinessDetailsActivityProps = {
  onDone?: VoidFunction;
  onClose?: VoidFunction;
  showExistingInternationalAccountBanner: boolean;
};

export const AddFxDeliveryMethodBusinessDetailsActivity = ({
  onClose,
  onDone,
  showExistingInternationalAccountBanner,
}: AddFxDeliveryMethodBusinessDetailsActivityProps) => {
  const { track } = useAnalytics();
  const { startAction, endAction, routeReady } = useMonitoring<MonitoredAction>();
  const { formatMessage } = useMelioIntl();
  const [currentStep, setCurrentStep] = useState<FXBusinessDetailsFormSteps>(
    FXBusinessDetailsFormSteps.BUSINESS_DETAILS
  );
  const [UboFormEnableNoIndividualsOwnerCheckbox] = useDevFeature<boolean>(
    FeatureFlags.UboFormEnableNoIndividualsOwnerCheckbox,
    false
  );
  const { showMessage } = useSystemMessage();
  const onFlowFinished = () => {
    endAction('fx_verified_persona_submit');
    showMessage({
      dataTestId: 'fx-update-ubo-success-toast',
      type: 'success',
      title: formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.success.banner'),
    });
    track('Organization', 'Status', {
      Status: 'success',
      PageName: 'account-holder',
      Intent: 'update-verified-personas',
    });
    onDone?.();
  };

  const onCloseWithAnalytics = () => {
    track('Organization', 'Click', {
      Cta: 'X',
      Intent: 'back',
      PageName: 'ownership-details',
    });
    onClose?.();
  };

  const {
    data: verifiedPersonas,
    isLoading: isUboLoading,
    batchUpsertMutate: updateVerifiedPersonasMutate,
    create: createVerifiedPersonas,
    isCreating: isCreatingVerifiedPersonas,
  } = useVerifiedPersonas({
    onCreate: onFlowFinished,
    onCreateError: () => {
      track('Organization', 'Status', {
        Status: 'failure',
        PageName: 'account-holder',
        Intent: 'update-verified-personas',
        ErrorType: `some-thing-went-wrong`,
      });
      showMessage({
        dataTestId: 'fx-update-update-ubo-failure-toast',
        type: 'error',
        title: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.errors.general-error'
        ),
      });
    },
  });
  const { data: account, isLoading: isAccountLoading } = useAccount({
    id: 'me',
  });

  const [isBusinessDetailsCompleted, setBusinessDetailsCompleted] = useState(false);
  const [isAccountHolderFormCompleted, setIsAccountHolderFormCompleted] = useState(false);
  const [isDecisionMakerCompleted, setIsDecisionMakerCompleted] = useState(false);
  const [isUBOFormCompleted, setIsUBOFormCompleted] = useState(false);
  const [showExistingAccountSectionBanner, setShowExistingAccountSectionBanner] = useState(
    showExistingInternationalAccountBanner
  );
  const [unsupportedTypeModal, setIsUnsupportedModalOpen] = useState<{
    isUnsupportedBusinessType: boolean;
    isUnsupportedIndustryType: boolean;
    values?: UnsupportedModalValues;
  }>({
    isUnsupportedBusinessType: false,
    isUnsupportedIndustryType: false,
  });
  const [didShowUnsupportedTypeModal, setDidShowUnsupportedTypeModal] = useState(false);
  const getUboFormValues = useGetUBOFormValues();

  const {
    form: businessDetailsForm,
    isLoading: isLoadingBusinessDetailsForm,
    isMutating: isMutatingBusiness,
  } = useFxBusinessDetailsForm({
    onUnsupportedBusinessType: ({ isUnsupportedBusinessType, isUnsupportedIndustryType, values }) => {
      setIsUnsupportedModalOpen(() => ({ isUnsupportedBusinessType, isUnsupportedIndustryType, values }));
      setDidShowUnsupportedTypeModal(true);
    },
    onDone: () => {
      setBusinessDetailsCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.ACCOUNT_HOLDER);
    },
  });

  const {
    form: accountHolderForm,
    isMutating: isUpdatingAccountHolder,
    isLoading: isLoadingAccountHolderForm,
  } = useAccountHolderForm({
    onDone: (values) => {
      const ubos = getUboFormValues({ accountHolderFormValues: values, verifiedPersonas });
      uboForm.reset({ ubos, noIndividualsOwner: false });
      setIsAccountHolderFormCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.UBO);
    },
  });

  const { form: uboForm } = useOwnershipDetailsForm({
    onDone: () => {
      setIsUBOFormCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.DECISION_MAKER);
    },
  });
  const decisionMakerFormSchema = useDecisionMakerFormSchema();
  const decisionMakerForm = useMelioForm<DecisionMakerForm>({
    onSubmit: () => {
      setIsDecisionMakerCompleted(true);
      setCurrentStep(FXBusinessDetailsFormSteps.TERMS_AND_CONDITIONS);
    },
    defaultValues: {
      decisionMakerResidencyDetails: {
        usResidence: 'usResidence',
        taxId: '',
      },
      decisionMakerFirstName: '',
      decisionMakerLastName: '',
      decisionMakerAddress: {
        line1: '',
        city: '',
        state: '',
        countryCode: '',
        postalCode: '',
      },
    },
    schema: decisionMakerFormSchema,
  });

  const termsAndConditionsFormSchema = useTermsAndConditionsFormSchema();
  const termsAndConditionsForm = useMelioForm<TermsAndConditionsForm>({
    onSubmit: () => {
      startAction('fx_verified_persona_submit');
      const request = fXVerifiedPersonasFormToDataModel({
        accountHolderForm,
        ownershipDetailsForm: uboForm,
        decisionMakerForm,
        existingPersonas: verifiedPersonas,
      });
      if (UboFormEnableNoIndividualsOwnerCheckbox) {
        createVerifiedPersonas(request);
      } else {
        updateVerifiedPersonasMutate.mutate(request.personas);
      }
    },
    schema: termsAndConditionsFormSchema,
  });
  const onBusinessDetailsExpandChanged = (step: FXBusinessDetailsFormSteps) => (isExpanded: boolean) => {
    if (isExpanded) {
      setCurrentStep(step);
    }
  };
  const onClosExistingInternationalSectionBanner = () => {
    setShowExistingAccountSectionBanner(false);
  };

  const isLoading = isUboLoading || isAccountLoading || isLoadingAccountHolderForm || isLoadingBusinessDetailsForm;
  return (
    <>
      <Layout
        maxWidth="600px"
        ref={routeReady}
        data-testid="fx-dm-business-details-activity"
        isLoading={isLoading}
        header={{
          content: (
            <Container paddingRight="xl" paddingTop="xl">
              <Group justifyContent="flex-end" width="full">
                <IconButton
                  icon="close"
                  onClick={onCloseWithAnalytics}
                  variant="naked"
                  size="medium"
                  aria-label="Go back"
                />
              </Group>
            </Container>
          ),
        }}
      >
        <Group variant="vertical" spacing="xl">
          <Group variant="vertical">
            <Group alignItems="center" variant="vertical" textAlign="center">
              <Text textStyle="heading1Semi">
                {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.title')}
              </Text>
              <Text data-testid="fx-dm-business-details-activity-subtitle">
                {formatMessage(
                  showExistingInternationalAccountBanner
                    ? 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.existingVendor.subtitle'
                    : 'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.subtitle'
                )}
              </Text>
            </Group>
            {showExistingAccountSectionBanner && (
              <SectionBanner
                showCloseIcon
                onClose={onClosExistingInternationalSectionBanner}
                data-testid="fx-dm-business-details-activity-additionalDetailsRequire-banner"
                variant="informative"
                description={formatMessage(
                  'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.additionalDetailsRequire.banner'
                )}
              />
            )}
          </Group>
          <SystemMessageDisplay data-testid="fx-business-details-failure-message" />
          <Group variant="vertical">
            <FXBusinessDetailsCard
              form={businessDetailsForm}
              isExpanded={currentStep === FXBusinessDetailsFormSteps.BUSINESS_DETAILS}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.BUSINESS_DETAILS)}
              isCompleted={isBusinessDetailsCompleted}
              businessName={account?.company.name ?? ''}
              isLoading={isMutatingBusiness}
              isBlockedFromSubmitting={didShowUnsupportedTypeModal}
            />
            <FXPersonalDetailsCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.ACCOUNT_HOLDER}
              form={accountHolderForm}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.ACCOUNT_HOLDER)}
              isCompleted={isAccountHolderFormCompleted}
              isAllowedToBeUbo={!verifiedPersonas?.length}
              isLoading={isUpdatingAccountHolder}
            />
            <FXOwnershipDetailsCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.UBO}
              form={uboForm}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.UBO)}
              isDisabled={currentStep < FXBusinessDetailsFormSteps.UBO}
              isCompleted={isUBOFormCompleted}
              verifiedPersonasLength={verifiedPersonas?.length ?? 0}
            />
            <FXDecisionMakerCard
              isExpanded={currentStep === FXBusinessDetailsFormSteps.DECISION_MAKER}
              form={decisionMakerForm}
              owners={uboForm.getValues('ubos')}
              accountHolder={accountHolderForm.getValues()}
              onExpandChange={onBusinessDetailsExpandChanged(FXBusinessDetailsFormSteps.DECISION_MAKER)}
              isCompleted={isDecisionMakerCompleted}
              isDisabled={currentStep < FXBusinessDetailsFormSteps.DECISION_MAKER}
            />
          </Group>

          <TermsAndConditionsSection
            form={termsAndConditionsForm}
            isDisabled={currentStep < FXBusinessDetailsFormSteps.TERMS_AND_CONDITIONS}
            isLoading={isCreatingVerifiedPersonas || updateVerifiedPersonasMutate.isLoading}
          />
        </Group>
      </Layout>
      <UnsupportedFxTypeModal
        isOpen={unsupportedTypeModal.isUnsupportedBusinessType || unsupportedTypeModal.isUnsupportedIndustryType}
        onClose={() => {
          setIsUnsupportedModalOpen({ isUnsupportedBusinessType: false, isUnsupportedIndustryType: false });
        }}
        unsupportedTypeModalOpen={unsupportedTypeModal}
      />
    </>
  );
};
