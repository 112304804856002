import { ColorPickerInput } from './color-picker';
export type { AddressFIeldInputProps } from './AddressField.input';
import { AddressFIeldInput } from './AddressField.input';
export type { CatalogItemSelectInputProps } from './CatalogItemSelect.input';
import { CatalogItemSelectInput } from './CatalogItemSelect.input';
export type { CustomerSelectInputProps } from './CustomersSelect.input';
import { CustomerSelectInput } from './CustomersSelect.input';
import { MultipleAttachmentsSelectInput } from './MultipleAttachmentsSelect.input';
export type { NumberFieldInputProps } from './NumberField.input';
import { NumberFieldInput } from './NumberField.input';
export type { PercentageFieldInputProps } from './PercentageField.input';
import { AmountFieldInput } from './AmountField.input';
export type { FormGridItemProps, FormGridProps, FormSectionProps } from './FormSection';
import { FormGrid as _FormGrid, FormGridItem, FormSection, FormSectionHeader } from './FormSection';
import { PercentageFieldInput } from './PercentageField.input';
export type { SelectInputProps } from './Select.input';
import { SelectInput } from './Select.input';
export type { IndustrySearchInputProps } from './IndustrySearch.input';
import { IndustrySearchInput } from './IndustrySearch.input';
export type { InternationalAddressFieldInputProps } from './InternationalAddressField.input';
import { InternationalAddressFieldInput } from './InternationalAddressField.input';
import { TaxIdNumberInput } from './TaxIdNumber.input';
export type { TaxIdNumberInputProps } from './TaxIdNumber.input';

export const FormInputs = {
  AddressSearch: AddressFIeldInput,
  AmountField: AmountFieldInput,
  CatalogItemSelect: CatalogItemSelectInput,
  ColorPicker: ColorPickerInput,
  CustomerSelect: CustomerSelectInput,
  IndustrySearch: IndustrySearchInput,
  InternationalAddressSearch: InternationalAddressFieldInput,
  MultipleAttachmentSelect: MultipleAttachmentsSelectInput,
  NumberField: NumberFieldInput,
  PercentageField: PercentageFieldInput,
  Select: SelectInput,
  TaxIdNumber: TaxIdNumberInput,
};

const FormLayoutElements = {
  GridItem: FormGridItem,
  Section: FormSection,
  SectionHeader: FormSectionHeader,
};

type FormLayoutType = typeof _FormGrid & typeof FormLayoutElements;

const FormLayout = _FormGrid as FormLayoutType;
Object.assign(FormLayout, FormLayoutElements);

export { FormLayout };
