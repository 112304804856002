/* eslint-disable no-restricted-imports */
import { Group, Text, useBreakpoint } from '@melio/penny';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { SectionWrapper } from '../SectionWrapper';
import { BillsDue } from './BillsDue';
import { OpenBalance } from './OpenBalance';
import { ProcessedPayments } from './ProcessedPayments';

type Props = {
  client: AccountingFirmClientExpanded;
  onSwitchOrganization: ({ organizationId }: { organizationId: string }) => Promise<void>;
};

export const PaymentsOverviewSection = ({ client, onSwitchOrganization }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <SectionWrapper dataTestId="drawer-payments-overview-section">
      <Group variant="vertical" spacing="m">
        <Group.Item grow={1}>
          <Text textStyle="heading3Semi">
            {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.paymentsOverview.semiHeader.title')}
          </Text>
        </Group.Item>
        <Group.Item>
          <Group
            alignItems="stretch"
            hasDivider={!isExtraSmallScreen}
            spacing="m"
            textAlign="start"
            variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
            width="full"
          >
            <Group.Item basis="30%">
              <OpenBalance client={client} onSwitchOrganization={onSwitchOrganization} />
            </Group.Item>
            <Group.Item basis="30%">
              <BillsDue client={client} onSwitchOrganization={onSwitchOrganization} />
            </Group.Item>
            <Group.Item basis="30%">
              <ProcessedPayments client={client} onSwitchOrganization={onSwitchOrganization} />
            </Group.Item>
          </Group>
        </Group.Item>
      </Group>
    </SectionWrapper>
  );
};
