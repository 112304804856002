import { isDeliveryPreferenceTypeFast } from '@melio/ap-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { DeliveryPreference, Payment, usePaymentIntent } from '@melio/platform-api';

import { getPaymentAnalyticsFields } from '../../../../utils';
import { useExtendedPaymentIntent } from '../../../../utils/pay-flow/useExtendedPaymentIntent';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { ReviewAndConfirmActivity, ReviewAndConfirmActivityProps } from '../../ReviewAndConfirm';

export type ReviewAndConfirmPaymentStepProps = Omit<
  ReviewAndConfirmActivityProps,
  'step' | 'totalSteps' | 'hideFromSection' | 'hideMemoSection' | 'enableLateTag'
> & {
  payment: Payment;
};

export const ReviewAndConfirmPaymentStep = withAnalyticsContext<ReviewAndConfirmPaymentStepProps>(
  ({ payment, paymentIntentId, setAnalyticsProperties, onBack, onClose, onDone, ...props }) => {
    const { createTrackHandler } = useAnalytics();

    const trackAndHandleClick = createTrackHandler<{
      PageName: 'review-and-confirm';
      Cta: 'back' | 'edit' | 'exit';
    }>('Payment', 'Click');

    const { data: basicPaymentIntent, isLoading: isPaymentIntentLoading } = usePaymentIntent({ id: paymentIntentId });
    const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);
    const deliveryPreference = (paymentIntent?.deliveryPreferenceOptions ?? []).find(
      (deliveryPreferenceOption) => deliveryPreferenceOption.type === paymentIntent?.selectedDeliveryPreferenceType
    ) as DeliveryPreference;
    const hasFastOption = deliveryPreference && isDeliveryPreferenceTypeFast(deliveryPreference.type);

    setAnalyticsProperties({
      ...getPaymentAnalyticsFields(payment),
      Intent: 'void-and-resend-check',
      EntryPoint: 'dashboard-payment',
      Flow: 'void-and-resend-check',
      PageName: 'review-and-confirm-payment',
      EligibilityType: null,
      IsSpecialDelivery: hasFastOption,
      SpecialDeliveryMethod: hasFastOption ? deliveryPreference?.type : null,
    });

    if (isPaymentIntentLoading) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    return (
      <ReviewAndConfirmActivity
        {...props}
        step={2}
        totalSteps={2}
        hideFromSection
        hideMemoSection
        enableLateTag={false}
        paymentIntentId={paymentIntentId}
        onDone={onDone}
        onClose={() => trackAndHandleClick({ Cta: 'exit' }, onClose)}
        onBack={() => trackAndHandleClick({ Cta: 'back' }, onBack)}
        analyticsProperties={{ Cta: 'confirm-and-schedule-payment' }}
      />
    );
  }
);
