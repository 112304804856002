import { OrganizationKeyIndividualValidations } from '@melio/compliance-validator';
import { OrganizationKeyIndividualType } from '@melio/compliance-validator/build/src/types/organization-key-individual-type';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { OwnershipDetails, OwnershipDetailsForm } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

type ContextOptionsFromValueUbos = {
  ownershipPercentage: string;
};

type ContextOptionsFromValue = {
  ubos: ContextOptionsFromValueUbos[];
};

type ContextOptionsFrom = {
  value: ContextOptionsFromValue;
};

type ContextOptions = {
  index: number;
  from: ContextOptionsFrom[];
};

const getOwnershipPercentageUbos = (from: ContextOptionsFrom[]): ContextOptionsFromValueUbos[] =>
  from
    .filter(({ value: { ubos } }: ContextOptionsFrom) => !!ubos)
    .map(({ value: { ubos } }: ContextOptionsFrom) => ubos)
    .at(0) || [];

const getOwnershipPercentageValues = (ownershipPercentageUbos: ContextOptionsFromValueUbos[]): number[] => {
  const ownershipPercentageValues = ownershipPercentageUbos.map((item: ContextOptionsFromValueUbos) =>
    parseInt(item.ownershipPercentage || '0', 10)
  );
  return ownershipPercentageValues;
};

const getOwnershipPercentageTotal = (from: ContextOptionsFrom[]): number[] => {
  const ownershipPercentageUbos = getOwnershipPercentageUbos(from);
  const ownershipPercentageValues = getOwnershipPercentageValues(ownershipPercentageUbos);
  return ownershipPercentageValues;
};

export const useOwnershipDetailsFormSchema = () => {
  const {
    addressSchema,
    residencySchema,
    riskDateOfBirthSchema,
    riskValidationTest,
    createErrorFromValidationResponse,
  } = useFxCommonValidations();
  const { formatMessage } = useMelioIntl();

  const getSchema = () =>
    yup.object().shape({
      uboId: yup.string().required(),
      ownerFirstName: yup
        .string()
        .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualFirstName)),
      ownerLastName: yup
        .string()
        .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualLastName)),
      ssn: yup.string().nullable(),
      ownershipPercentage: yup
        .string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.required'
          )
        )
        .test((ownershipPercentage: string | undefined, context) => {
          if (!ownershipPercentage) {
            return false;
          }
          const valAsNumber = parseInt(ownershipPercentage, 10);
          const validationResponse = OrganizationKeyIndividualValidations.validateKeyIndividualOwnershipPercentage(
            [OrganizationKeyIndividualType.Ubo],
            valAsNumber
          );
          return createErrorFromValidationResponse(validationResponse, context);
        })
        .test('ownershipPercentagesSum', (val, context) => {
          if (!val) {
            return false;
          }
          const { from = [] } = context.options as ContextOptions;
          const ownershipPercentages = getOwnershipPercentageTotal(from);
          const validationResponse =
            OrganizationKeyIndividualValidations.validateKeyIndividualsOwnershipPercentagesSum(ownershipPercentages);
          return createErrorFromValidationResponse(validationResponse, context);
        }),
      address: addressSchema,
      dateOfBirth: riskDateOfBirthSchema,
      residencyDetails: residencySchema,
    }) as yup.SchemaOf<OwnershipDetails>;

  const uboSchema = getSchema();
  const schema = yup.object().shape({
    ubos: yup
      .array()
      .of(uboSchema)
      .nullable()
      .when('noIndividualsOwner', {
        is: false,
        then: (schema) => schema.required(),
      }),
    noIndividualsOwner: yup.boolean().nullable(),
  }) as yup.SchemaOf<OwnershipDetailsForm>;

  return schema;
};
