import { Button, ButtonProps, Container, Group, Icon, IconButton, IconKey, Text, useBreakpoint } from '@melio/penny';
import { useNavigate } from 'react-router-dom';

import { SystemMessageDisplay } from '../system-message';

export const SettingsPageHeader = ({
  title,
  subTitle,
  primaryButton,
  secondaryButton,
  badge,
  dataTestId,
}: {
  title?: string;
  subTitle?: string;
  primaryButton?: ButtonProps & { dataTestId?: string; icon?: IconKey };
  secondaryButton?: ButtonProps & { dataTestId?: string; icon?: IconKey };
  badge?: JSX.Element;
  dataTestId?: string;
}) => {
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();

  const navigate = useNavigate();

  const buttons = (
    <Group variant={isSmallScreen ? 'vertical' : 'horizontal'}>
      {secondaryButton ? (
        <Button
          isDisabled={secondaryButton.isDisabled}
          leftElement={
            secondaryButton.icon ? (
              <Icon size="small" type={secondaryButton.icon} color="inherit" aria-hidden />
            ) : undefined
          }
          onClick={secondaryButton.onClick}
          label={secondaryButton.label}
          data-testid={secondaryButton.dataTestId}
          variant={secondaryButton.variant}
        />
      ) : undefined}
      {primaryButton ? (
        <Group.Item shrink={0}>
          <Button
            leftElement={
              primaryButton.icon ? (
                <Icon size="small" type={primaryButton.icon} color="inherit" aria-hidden />
              ) : undefined
            }
            onClick={primaryButton.onClick}
            label={primaryButton.label}
            data-testid={primaryButton.dataTestId}
          />
        </Group.Item>
      ) : undefined}
    </Group>
  );
  const hasButtons = primaryButton || secondaryButton;
  return isExtraSmallScreen ? (
    <Container paddingBottom="xxs" data-testid={dataTestId || 'settings-page-header'}>
      <Group variant="vertical" spacing={hasButtons ? 's' : 'xxs'}>
        <Group justifyContent="space-between" style={{ flexWrap: 'wrap' }}>
          <Group spacing="s">
            <IconButton
              icon="arrow-left"
              aria-label="Go back"
              variant="naked"
              size="medium"
              onClick={() => navigate('/settings')}
            />
            <Text as="h2" textStyle="heading2Semi">
              {title}
            </Text>
          </Group>
          {hasButtons ? <Group.Item shrink={0}>{buttons}</Group.Item> : null}
        </Group>
        <Text textStyle="body2" color="global.neutral.1000">
          {subTitle}
        </Text>
        <SystemMessageDisplay />
      </Group>
    </Container>
  ) : (
    <Container paddingBottom="s" data-testid={dataTestId || 'settings-page-header'}>
      <Group variant="vertical" spacing={hasButtons ? 'xs' : 'xxs'}>
        <Group
          spacing="s"
          justifyContent="space-between"
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          variant={isSmallScreen ? 'vertical' : 'horizontal'}
        >
          <Group>
            <Text as="h2" textStyle="heading2Semi">
              {title}
            </Text>
            {badge}
          </Group>
          {hasButtons ? <Group.Item shrink={0}>{buttons}</Group.Item> : null}
        </Group>
        <Text textStyle="body2" color="global.neutral.1000">
          {subTitle}
        </Text>
      </Group>
    </Container>
  );
};
