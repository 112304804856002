import { useEffect, useMemo } from 'react';
import { useSendMessage } from '@melio/partner-bridge';
import { Image, Link, useTheme } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { useOneTimeEffect } from '@melio/platform-utils';

import { FullScreenContainer } from '@/cl/components/FullScreenContainer/FullScreenContainer.component';
import { usePlatformIntl } from '@/translations/Intl';
import {
  errorToOnboardingError,
  joinWith,
  OnboardingErrorType,
  onboardingErrorTypeToPathValues,
  validationKeyToFormatKey,
} from '@/utils/onboarding.utils';
import { OutgoingPostMessageTypes } from '@/utils/partnerBridge.utils.types';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

type OnboardingErrorProps = {
  error?: unknown;
  backToPartnerUrl: string;
  isBackToPartner?: boolean;
  productName: string;
};

const MissingEmailImage = ({ src }: { src: string }) => {
  const { borderRadii, colors } = useTheme();

  return (
    <div
      style={{
        border: '1px solid',
        borderColor: colors.semantic.border.static,
        borderRadius: borderRadii.global['200'],
      }}
    >
      <Image src={src} alt={'personal email field'} borderRadius={'global.200'} />
    </div>
  );
};

export const OnboardingError = ({ error, backToPartnerUrl, isBackToPartner, productName }: OnboardingErrorProps) => {
  const { formatMessage } = usePlatformIntl();
  const { type, missingFields, reason } = useMemo(() => errorToOnboardingError(error), [error]);
  const { track } = useAnalytics();
  const sendMessage = useSendMessage();
  const {
    settings: { showRetryButtonOnFieldValidation },
    links: { addMissingEmail, missingEmailOnboardingErrorImage },
  } = useConfig();
  const showEmailMissingError =
    type === OnboardingErrorType.NonCompliance &&
    !!missingFields?.some((field) => field === 'email') &&
    !!addMissingEmail;
  const { title, message } = onboardingErrorTypeToPathValues(type, showEmailMissingError);

  const onClick = () => {
    track('ExitFromPlatformBackButton', 'Chose');
    window.location.href = showEmailMissingError ? addMissingEmail : backToPartnerUrl;
  };

  useOneTimeEffect(
    () => {
      track('ErrorScreen', 'View', {
        ErrorType: type,
        ErrorFields: missingFields,
      });
    },
    () => !!track,
    [track],
  );
  useEffect(() => {
    if (reason) {
      sendMessage(OutgoingPostMessageTypes.AUTHENTICATION_ERROR, { reason, missingFields });
    }
  }, [reason, sendMessage, missingFields]);

  const getButtonText = () => {
    // if we have addMissingEmail, we want to show got it button even if isBackToPartner is false
    if (showEmailMissingError) {
      return formatMessage(`widgets.onboarding.errorPage.button.back.gotIt`);
    }
    return isBackToPartner
      ? type === OnboardingErrorType.NonCompliance
        ? formatMessage(`widgets.onboarding.errorPage.button.back.gotIt`)
        : formatMessage(`widgets.onboarding.errorPage.button.back`)
      : formatMessage(`widgets.onboarding.errorPage.button`);
  };

  const primaryButtonProps = {
    onClick,
    text: getButtonText(),
  };

  const formattedMissingFields = missingFields?.map((fieldName) => {
    const formatKey = validationKeyToFormatKey[fieldName as keyof typeof validationKeyToFormatKey];

    if (formatKey) {
      return formatMessage(formatKey);
    }

    return fieldName;
  });

  const formattedMissingFieldsMessage =
    formattedMissingFields &&
    joinWith(formattedMissingFields, ',', formatMessage('widgets.onboarding.errorPage.missingFields.lastDelimiter'));
  return (
    <FullScreenContainer centered>
      <ErrorPage
        title={formatMessage(title)}
        message={formatMessage(message, {
          ...(formattedMissingFieldsMessage && {
            missingFields: formattedMissingFieldsMessage,
            productName,
          }),
          ...(showEmailMissingError && {
            addMissingEmailLink: <Link href={addMissingEmail} label={addMissingEmail} />,
            productName,
          }),
        })}
        primaryButton={
          showRetryButtonOnFieldValidation && type !== OnboardingErrorType.Forbidden ? primaryButtonProps : undefined
        }
        bottomImage={
          showEmailMissingError && missingEmailOnboardingErrorImage ? (
            <MissingEmailImage src={missingEmailOnboardingErrorImage} />
          ) : undefined
        }
      />
    </FullScreenContainer>
  );
};
