import { Group, SimpleGrid, Text } from '@melio/penny';
import { PropsWithChildren } from 'react';

type Props = {
  label: string;
  colSpan: number;
  labelTestId?: string;
  dataTestId?: string;
  spacingY?: 'xs' | 'm';
};

export const FormFieldsGroup = ({
  label,
  colSpan,
  labelTestId,
  dataTestId,
  children,
  spacingY = 'xs',
}: PropsWithChildren<Props>) => (
  <Group spacing="m" variant="vertical" data-testid={dataTestId}>
    <Text as="h3" textStyle="heading3Semi" data-testid={labelTestId}>
      {label}
    </Text>
    <SimpleGrid columns={colSpan} spacingX="m" spacingY={spacingY}>
      {children}
    </SimpleGrid>
  </Group>
);
