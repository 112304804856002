export enum SubscriptionBillingPayor {
  Client = 'Client',
  AccountingFirm = 'AccountingFirm',
}

export type AnalyticsPaymentDrawerActionType = 'continue' | 'cancel' | 'exit';

export enum ManageClientFlow {
  UpdateSubscriptionBillingPayor = 'UpdateSubscriptionBillingPayor',
}
export type ManageClientFlowResultPayload = {
  flow: ManageClientFlow;
  success: boolean;
  clientOrgId: string;
};
