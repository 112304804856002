import { IconButton } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const CarouselNavigation = ({
  itemsCount,
  currentIndex,
  setCurrentIndex,
}: {
  itemsCount: number;
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { formatMessage } = useMelioIntl();

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <>
      <IconButton
        size="small"
        isDisabled={currentIndex === 0}
        onClick={handlePrev}
        icon="chevron-left"
        aria-label={formatMessage('activities.accountants.firmDashboard.v2.carousel.navigation.prev.ariaLabel')}
        variant="naked"
        data-testid="carousel-prev-button"
      />
      <IconButton
        size="small"
        isDisabled={currentIndex === itemsCount}
        onClick={handleNext}
        icon="chevron-right"
        aria-label={formatMessage('activities.accountants.firmDashboard.v2.carousel.navigation.next.ariaLabel')}
        variant="naked"
        data-testid="carousel-next-button"
      />
    </>
  );
};
