import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LocalStorageKeys } from '@melio/local-storage';

import { removePartnerTokens } from '@/api/utilities';
import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';

export const UnauthorizedScreen = () => {
  const { goToAuth, goToSessionExpired } = useRouter();
  const localStorage = usePartnerLocalStorage();
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirectUrl') || '';

  useEffect(() => {
    const hadAccessToken = !!localStorage.getItem(LocalStorageKeys.accessToken);
    if (hadAccessToken) {
      goToSessionExpired({ redirectUrl });
    } else {
      goToAuth({ redirectUrl });
    }
    return removePartnerTokens();
  }, [goToAuth, goToSessionExpired, localStorage, redirectUrl]);

  return null;
};
