import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useGuestPaymentIntent, usePaymentRequestStatus, useUpdatedInvoiceSystemMessage } from '../../hooks';
import { GuestPaymentIntentParams } from '../../types';
import { NoPaymentOptionsScreen } from './screens';

type NoPaymentOptionsActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
};

export const NoPaymentOptionsActivity = forwardRef<NoPaymentOptionsActivityProps>(
  ({ guestPaymentIntentParams, onError, onViewInvoice, ...props }, ref) => {
    const { isLoading: isGuestPaymentIntentLoading, data: guestPaymentIntentData } = useGuestPaymentIntent({
      guestPaymentIntentParams,
      onError,
    });

    const { status } = usePaymentRequestStatus(guestPaymentIntentParams);

    useEffect(() => {
      if (status && status != 'no-payment-option') onError?.({ code: 'UNKNOWN_ERROR', message: 'Unknown error' });
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdatedInvoiceSystemMessage(guestPaymentIntentParams);

    return (
      <NoPaymentOptionsScreen
        onViewInvoice={onViewInvoice}
        guestPaymentIntentData={guestPaymentIntentData}
        isLoading={isGuestPaymentIntentLoading}
        {...props}
        ref={ref}
      />
    );
  }
);
NoPaymentOptionsActivity.displayName = 'NoPaymentOptionsActivity';
