import { Box } from '@chakra-ui/react';
import { FundingSourceBankAccount, ReceivingMethod, useMelioIntl } from '@melio/ar-domain';
import { Button, Icon } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { ActivationLayout } from '../../../layouts';
import { AccountCardList } from '../components';

export type ConfirmReceivingAccountScreenProps = {
  receivingMethod?: ReceivingMethod;
  fundingSources?: FundingSourceBankAccount[];
  onSubmit: (data?: { fundingSourceId: string }) => void;
  onAddPlaidAccount: VoidFunction;
  onBack: VoidFunction;
  onClose: VoidFunction;
  isSaving: boolean;
  isLoading: boolean;
};

export const ConfirmReceivingAccountScreen = forwardRef<ConfirmReceivingAccountScreenProps>(
  (
    {
      onSubmit,
      onClose,
      onAddPlaidAccount,
      onBack,
      isSaving = false,
      isLoading = false,
      receivingMethod,
      fundingSources = [],
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<string>();

    const hasReceivingMethod = !!receivingMethod;
    const hasFundingSources = fundingSources.length > 0;

    return (
      <ActivationLayout
        step="receiving-account"
        isLoading={isLoading}
        primaryButtonProps={{
          isDisabled: !isSaving && !selectedFundingSourceId && !hasReceivingMethod,
          isLoading: isSaving,
          onClick: () => {
            if (selectedFundingSourceId) {
              onSubmit({ fundingSourceId: selectedFundingSourceId });
            } else {
              onSubmit();
            }
          },
        }}
        secondaryButtonProps={{ isDisabled: isSaving, onClick: onBack }}
        tertiaryButtonProps={{ isDisabled: isSaving, onClick: onClose }}
        header={{
          title:
            !hasReceivingMethod && hasFundingSources
              ? formatMessage('ar.onboarding.activities.confirmReceivingAccount.select.title')
              : formatMessage('ar.onboarding.activities.confirmReceivingAccount.set.title'),
          description: formatMessage('ar.onboarding.activities.confirmReceivingAccount.confirmScreen.description'),
        }}
        {...props}
        ref={ref}
      >
        <SavingContainer isSaving={isSaving}>
          {hasReceivingMethod ? (
            <AccountCardList isReadOnly accounts={[receivingMethod]} />
          ) : hasFundingSources ? (
            <AccountCardList
              accounts={fundingSources}
              selectedAccountId={selectedFundingSourceId}
              onSelectAccount={setSelectedFundingSourceId}
              onAddPlaidAccount={onAddPlaidAccount}
            />
          ) : (
            <Box>
              <Button
                leftElement={<Icon type="bank" />}
                data-testid="add-bank-account-button"
                variant="tertiary"
                label={formatMessage('ar.onboarding.activities.confirmReceivingAccount.components.addAccount.label')}
                onClick={onAddPlaidAccount}
              />
            </Box>
          )}
        </SavingContainer>
      </ActivationLayout>
    );
  }
);

ConfirmReceivingAccountScreen.displayName = 'ConfirmReceivingAccountScreen';

const SavingContainer = forwardRef<{ isSaving?: boolean }>(({ isSaving = false, ...props }, ref) => (
  <Box
    data-testid="saving-container"
    data-saving={String(isSaving)}
    pointerEvents={isSaving ? 'none' : 'auto'}
    opacity={isSaving ? 0.3 : 1}
    {...props}
    ref={ref}
  />
));
