import {
  FormattedMessage,
  FormInputs,
  FormLayout,
  FormWidgetProps,
  OwnerJobTitle,
  useMelioIntl,
  yup,
} from '@melio/ar-domain';
import { Form, Group, Link, Text, useMelioForm } from '@melio/penny';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';

import { OwnerDetailsFormValuesWithRefId } from '../types';

export type ControlPersonDetailsFormFields = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  formId: 'other' | (string & {});
  role: OwnerJobTitle;
};

export type ControlPersonDetailsFormProps = FormWidgetProps<ControlPersonDetailsFormFields> & {
  ubos?: OwnerDetailsFormValuesWithRefId[];
  onChange?: (values: ControlPersonDetailsFormFields) => void;
  hasOwners: boolean;
};

export const ControlPersonDetailsForm = forwardRef<ControlPersonDetailsFormProps>(
  ({ isSaving, onSubmit, ubos, hasOwners, defaultValues, onSubmissionStateChange, onChange, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { registerField, formProps, watch, setValue } = useMelioForm<ControlPersonDetailsFormFields>({
      onSubmit,
      isSaving,
      onSubmissionStateChange,
      defaultValues,
      subscribeToDefaultValuesChanges: true,
      schema: useScheme(),
    });

    const [formId, role] = watch(['formId', 'role']) as [string, OwnerJobTitle];
    useEffect(() => onChange?.({ formId, role }), [formId, role]); // eslint-disable-line react-hooks/exhaustive-deps

    const hasOrphanFormId = useMemo(
      () => !!formId && formId !== 'other' && !(ubos ?? []).find((ubo) => ubo.refId === formId),
      [formId, ubos]
    );

    useUpdateEffect(() => {
      if (hasOrphanFormId) setValue('formId', null as never);
    }, [hasOrphanFormId]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdateEffect(() => {
      if (!hasOwners && formId != 'other') setValue('formId', 'other');
    }, [hasOwners, formId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <FormLayout.Section
        title={
          <Group variant="vertical" spacing="xs">
            <FormLayout.SectionHeader
              title={formatMessage('ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.header.text')}
            />
            <Text textStyle="body2" color="semantic.text.secondary">
              <FormattedMessage
                id="ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.description.text"
                values={{
                  link: (
                    <Link
                      href="#"
                      label={formatMessage(
                        'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.description.link.text'
                      )}
                    />
                  ),
                }}
              />
            </Text>
          </Group>
        }
        {...props}
        ref={ref}
      >
        <FormLayout.Section>
          <Form {...formProps} columns={2}>
            <FormInputs.Select
              {...registerField('formId')}
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.type.label'
                ),
              }}
              options={[
                ...(ubos ?? []).map((ubo) => ({ value: ubo.refId, label: [ubo.firstName, ubo.lastName].join(' ') })),
                { label: 'Other', value: 'other' },
              ]}
            />

            <FormInputs.Select
              {...registerField('role')}
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.label'
                ),
              }}
              options={useRoleOptions()}
            />
          </Form>
        </FormLayout.Section>
      </FormLayout.Section>
    );
  }
);

const useRoleOptions = () => {
  const { formatMessage } = useMelioIntl();

  return useMemo<{ value: OwnerJobTitle; label: string }[]>(
    () => [
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.ceo'
        ),
        value: OwnerJobTitle.ChiefExecutiveOfficer,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.cfo'
        ),
        value: OwnerJobTitle.ChiefFinancialOfficer,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.memberLlc'
        ),
        value: OwnerJobTitle.ManagingMember,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.partner'
        ),
        value: OwnerJobTitle.GeneralPartner,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.president'
        ),
        value: OwnerJobTitle.President,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.vicePresident'
        ),
        value: OwnerJobTitle.VicePresident,
      },
      {
        label: formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.options.treasurer'
        ),
        value: OwnerJobTitle.Treasurer,
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

const useScheme = () => {
  const { formatMessage } = useMelioIntl();

  return yup.object().shape({
    formId: yup
      .string()
      .nullable()
      .required(
        formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.type.validation.required'
        )
      ),
    role: yup
      .string()
      .nullable()
      .required()
      .required(
        formatMessage(
          'ar.onboarding.activities.ownershipDetails.form.controlPersonDetails.fields.role.validation.required'
        )
      ),
  }) as yup.SchemaOf<ControlPersonDetailsFormFields>;
};
