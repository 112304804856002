import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Divider, Group, Icon, IconKey, Text, Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ActivationLayout } from '../../../layouts';

export type GetStartedScreenProps = {
  onDone: VoidFunction;
  onClose?: VoidFunction;
};

export const GetStartedScreen = forwardRef<GetStartedScreenProps>(({ onDone, onClose, ...props }, ref) => {
  const { formatMessage } = useMelioIntl();

  return (
    <ActivationLayout
      primaryButtonProps={{ onClick: onDone }}
      tertiaryButtonProps={{ onClick: onClose }}
      header={{
        title: formatMessage('ar.onboarding.activities.getStarted.header'),
        description: formatMessage('ar.onboarding.activities.getStarted.subheader'),
        illustration: 'pay-zero',
      }}
      step="get-started"
      {...props}
      ref={ref}
    >
      <Divider />
      <Group variant="vertical" spacing="m">
        <ContentBlock
          icon="stopwatch-fast"
          title={formatMessage('ar.onboarding.activities.getStarted.content.activation.title')}
        >
          <FormattedMessage id="ar.onboarding.activities.getStarted.content.activation.text" />
        </ContentBlock>

        <ContentBlock
          icon="to-do"
          title={formatMessage('ar.onboarding.activities.getStarted.content.requirements.title')}
        >
          <Typography.ParagraphList
            type="unordered"
            list={[
              { content: formatMessage('ar.onboarding.activities.getStarted.content.requirements.item1') },
              { content: formatMessage('ar.onboarding.activities.getStarted.content.requirements.item2') },
              { content: formatMessage('ar.onboarding.activities.getStarted.content.requirements.item3') },
            ]}
          />
        </ContentBlock>
      </Group>
    </ActivationLayout>
  );
});

GetStartedScreen.displayName = 'GetStartedScreen';

type ContentBlockProps = {
  title: string;
  icon: IconKey;
};
const ContentBlock = forwardRef<ContentBlockProps>(({ icon, title, children, ...props }, ref) => (
  <Text as="div" color="semantic.text.secondary" {...props} ref={ref}>
    <Group variant="vertical" spacing="m">
      <Group variant="horizontal" spacing="xs" alignItems="flex-start">
        <Icon type={icon} color="inherit" size="large" />
        <Group variant="vertical" spacing="xxs">
          <Text textStyle="body2Semi" color="inherit">
            {title}
          </Text>
          <Text textStyle="body3" color="inherit">
            {children}
          </Text>
        </Group>
      </Group>
    </Group>
  </Text>
));
