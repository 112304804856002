import { Button, Container, Group, Illustration, Text, ThemeIllustrationType } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { FormattedMessage, MessageKey, useMelioIntl } from './i18n';

export type ZeroStateProps = {
  illustrationType: ThemeIllustrationType;
  primaryLabel?: MessageKey;
  secondaryLabel?: MessageKey;
  dataTestId: string;
  actions?: {
    label: MessageKey;
    onClick: () => void;
    variant?: 'primary' | 'secondary';
    testId: string;
  }[];
};

export const ZeroState = forwardRef<ZeroStateProps>(
  ({ illustrationType, primaryLabel, secondaryLabel, actions = [], dataTestId, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Container
        overflow="initial"
        paddingX="s"
        paddingY="xxxl"
        data-component={ZeroState.displayName}
        data-testid={dataTestId}
        {...props}
        ref={ref}
      >
        <Group spacing="m" alignItems="center" justifyContent="center" variant="vertical">
          <Illustration type={illustrationType} size="medium" aria-hidden />
          <Group spacing="xs" alignItems="center" justifyContent="center" variant="vertical">
            <Container textAlign="center">
              {primaryLabel && (
                <Text textStyle="heading3Semi">
                  <FormattedMessage id={primaryLabel} />
                </Text>
              )}
            </Container>
            <Container textAlign="center">
              {secondaryLabel && (
                <Text textStyle="body2">
                  <FormattedMessage id={secondaryLabel} />
                </Text>
              )}
            </Container>
          </Group>
          {actions.length > 0 && (
            <Group spacing="xs" alignItems="center" justifyContent="center" variant="horizontal">
              {actions.map(({ label, onClick, variant = 'primary', testId }) => (
                <Button
                  key={testId}
                  variant={variant}
                  label={formatMessage(label)}
                  onClick={onClick}
                  data-testid={testId}
                />
              ))}
            </Group>
          )}
        </Group>
      </Container>
    );
  }
);
ZeroState.displayName = 'ZeroState';
