import { Box } from '@chakra-ui/react';
import { Link, Skeleton, Text } from '@melio/penny';
import { Plan, SubscriptionPreview } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';

import type { SubscriptionBillingCycleEnum } from '../../../../../api';
import { useSubscriptionPreviewItems } from '../../../../../hooks';

export const SubscriptionCheckoutSummaryDisclaimer = ({
  isLoading,
  preview,
  plan,
  planCycle,
}: {
  isLoading: boolean;
  plan: Plan;
  planCycle: SubscriptionBillingCycleEnum;
  preview?: SubscriptionPreview;
}) => {
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl } = useConfig().settings;
  const { freeTrialData, paidPeriodStartDate } = useSubscriptionPreviewItems({
    subscriptionPreview: preview,
    numberOfFreeUsers: plan.numberOfFreeUsers,
  });

  const getDisclaimer = () => {
    const isFreeTrial = !!freeTrialData?.freeBillingItem;

    const firstBillingDate = isLoading ? (
      <Box width="100px" display="inline-block">
        <Skeleton height="8px" />
      </Box>
    ) : isFreeTrial ? (
      formatDate(paidPeriodStartDate, { dateStyle: 'full' })
    ) : (
      formatDate(preview?.billingItems?.[0]?.serviceStartDate, { dateStyle: 'full' })
    );

    const amount = isLoading ? (
      <Box width="40px" display="inline-block">
        <Skeleton height="8px" />
      </Box>
    ) : isFreeTrial ? (
      formatCurrency(freeTrialData.chargingAmount || 0)
    ) : (
      formatCurrency(preview?.amount || 0)
    );

    const termsAndConditions = {
      termsOfService: (
        <Link
          color="inherit"
          href={termsOfServiceUrl}
          label={formatMessage('activities.subscription.checkout.summary.disclaimer.terms')}
          newTab
        />
      ),
      privacyPolicy: (
        <Link
          color="inherit"
          href={privacyPolicyUrl}
          label={formatMessage('activities.subscription.checkout.summary.disclaimer.privacy')}
          newTab
        />
      ),
    };

    return formatMessage(
      isFreeTrial
        ? `activities.subscription.checkout.summary.disclaimer.${planCycle}FreeTrial`
        : `activities.subscription.checkout.summary.disclaimer.${planCycle}`,
      {
        firstBillingDate: (
          <Text textStyle="inherit" color="inherit">
            {firstBillingDate}
          </Text>
        ), // wrapping with element is required to prevent page crash on browser translate
        amount: (
          <Text textStyle="inherit" color="inherit">
            {amount}
          </Text>
        ), // wrapping with element is required to prevent page crash on browser translate
        ...termsAndConditions,
      }
    );
  };

  return (
    <Text textStyle="body4" color="global.neutral.800" data-testid="subscription-checkout-disclaimer">
      {getDisclaimer()}
    </Text>
  );
};
