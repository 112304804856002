import { useMelioQueryClient } from '@melio/api-client';
import {
  PaymentWidgetFundingSourceType,
  PayorVerifyOTPResponseProperties,
  PostSendOTPRequest,
  PostVerifyOTPRequest,
  PublicPayorApiClient,
} from '@melio/ar-api-axios-client';
import { useMutation } from '@tanstack/react-query';

import { useModel, UseModelProps } from '../../api-client';

type UseGuestPayorPaymentRequestDetailsProps = Omit<
  UseModelProps<typeof PublicPayorApiClient.getPaymentRequestDetails>,
  'id'
> & {
  paymentRequestLink?: string;
};

export {
  UserTypes as GuestPayorUserTypes,
  OTPVerifyForbiddenErrorCode as OTPVerifyErrorCodes,
} from '@melio/ar-api-axios-client';

export const useGuestPayorPaymentRequestDetails = (props: UseGuestPayorPaymentRequestDetailsProps) =>
  useModel({
    ...props,
    id: props.paymentRequestLink,
    queryKey: 'PublicPayorApi',
    queryFn: PublicPayorApiClient.getPaymentRequestDetails,
  });

export const useGuestPayorOtp = () => {
  const { mutateAsync: sendVerificationCodeMutateAsync } = useMutation<unknown, ARPlatformError, PostSendOTPRequest>(
    ({ email, partnerName }) => PublicPayorApiClient.postPublicPayorOtpStart({ email, partnerName })
  );

  const queryClient = useMelioQueryClient();

  const { mutateAsync: verifyCodeMutateAsync, ...restVerify } = useMutation<
    PayorVerifyOTPResponseProperties,
    ARPlatformError,
    PostVerifyOTPRequest
  >(
    async (params) => {
      const response = await PublicPayorApiClient.postPublicPayorOtpVerify(params);
      return response.data.data;
    },
    {
      onSuccess: () => {
        void queryClient.invalidateQueries('AccountsApi', 'model');
      },
    }
  );

  return {
    sendVerificationCode: sendVerificationCodeMutateAsync,
    verifyCode: verifyCodeMutateAsync,
    isLoadingVerifyCode: restVerify.isLoading,
  };
};

export const usePaymentWidgetUrl = ({
  fundingSourceType,
  paymentRequestLink,
}: {
  fundingSourceType: PaymentWidgetFundingSourceType;
  paymentRequestLink?: string;
}) =>
  useModel({
    id: paymentRequestLink,
    queryKey: ['PublicPayorApi', 'paymentWidgetUrl', paymentRequestLink, fundingSourceType],
    queryFn: (paymentRequestLink: string) =>
      PublicPayorApiClient.getPaymentWidgetUrl(fundingSourceType, paymentRequestLink),
  });
