import { CatalogItem, InvoiceSummary, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Button, GridItem, Group, Icon, SectionBanner, SimpleGrid } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import { useMemo } from 'react';

import { DiscountProps, InvoiceFormMode, ServiceChargesProps, TaxRateProps } from '../../../types';
import { createDefaultFormLineItem, useInvoiceFormContext } from '../../../utils';
import { FormSection } from '../../FormSection';
import { InvoiceItemsFormHeader } from './InvoiceItemsFormHeader';
import { InvoiceItemsSummary } from './InvoiceItemsSummary';
import { InvoiceLineItemForm } from './InvoiceLineItemForm';

type InvoiceItemsFormSectionProps = {
  taxRate: TaxRateProps;
  discount: DiscountProps;
  serviceCharges: ServiceChargesProps;
  isLoading?: boolean;
  isLoadingSummary?: boolean;
  isDisabled?: boolean;
  isSaving?: boolean;
  catalogItems?: CatalogItem[];
  invoiceSummary?: InvoiceSummary;
  mode?: InvoiceFormMode;
};

export const InvoiceItemsFormSection = forwardRef<InvoiceItemsFormSectionProps>(
  (
    {
      isDisabled,
      isSaving,
      taxRate,
      discount,
      serviceCharges,
      children,
      isLoading,
      isLoadingSummary,
      catalogItems,
      invoiceSummary,
      mode,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();

    const { lineItemsFieldArray, watch } = useInvoiceFormContext();

    const hasDeleteColumn = lineItemsFieldArray.fields.length > 1 || !!lineItemsFieldArray.fields[0]?._current;

    useUpdateEffect(() => {
      if (lineItemsFieldArray.fields.length === 0) {
        lineItemsFieldArray.append(createDefaultFormLineItem());
      }
    }, [lineItemsFieldArray.fields]); // eslint-disable-line react-hooks/exhaustive-deps

    const isMobile = useIsMobile();

    const lineItems = watch('lineItems');
    const canAddLineItem = useMemo(
      () => lineItemsFieldArray.fields.every((_, id) => !!lineItems[id]?.catalogItemId),
      [lineItems, lineItemsFieldArray]
    );

    return (
      <FormSection
        title={formatMessage('ar.invoiceLifecycle.activities.createInvoice.form.sections.invoiceItems.label')}
        data-component={InvoiceItemsFormSection.displayName}
        data-testid="form-section-invoice-items"
        {...props}
        ref={ref}
      >
        {mode === 'edit' && (
          <SectionBanner
            data-testid="edit-invoice-items-section-banner-info"
            variant="informative"
            description={formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.form.sections.invoiceItems.sectionBanner.info'
            )}
          />
        )}
        <Group variant="vertical" spacing="l">
          <Group variant="vertical" spacing="m" hasDivider={isMobile} role={isMobile ? 'section' : 'table'}>
            {!isMobile && <InvoiceItemsFormHeader hasDeleteColumn={hasDeleteColumn} />}
            {lineItemsFieldArray.fields.map((field, index) => (
              <InvoiceLineItemForm
                key={field.id}
                catalogItems={catalogItems}
                index={index}
                onTaxable={taxRate.onEdit}
                taxRateId={taxRate.id}
                taxRateName={taxRate.name}
                hasDeleteColumn={hasDeleteColumn}
              />
            ))}
          </Group>
          <SimpleGrid columns={[1, 1, 2]} spacing="m">
            <GridItem>
              <Button
                label={formatMessage('ar.invoiceLifecycle.activities.createInvoice.buttons.addAnotherItem.label')}
                onClick={() => {
                  lineItemsFieldArray.append(createDefaultFormLineItem());
                  track('Invoice', 'Click', {
                    Intent: 'add-invoice-item',
                    Cta: 'add-another',
                  });
                }}
                isDisabled={isDisabled || isSaving || isLoading || !canAddLineItem}
                variant="tertiary"
                leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
                data-testid="add-line-item-button"
              />
            </GridItem>
            <GridItem>
              <InvoiceItemsSummary
                taxRate={taxRate}
                discount={discount}
                serviceCharges={serviceCharges}
                isDisabled={isDisabled || isLoadingSummary}
                isSaving={isSaving}
                invoiceSummary={invoiceSummary}
                mode={mode}
              />
            </GridItem>
          </SimpleGrid>
        </Group>
      </FormSection>
    );
  }
);
InvoiceItemsFormSection.displayName = 'InvoiceItemsFormSection';
