import { SelectOption } from '@melio/penny';
import { BusinessResultItem, formatAddress, MsnBusinessItem } from '@melio/platform-api';

export const getSelectOptionsFromBusinesses = (businesses: BusinessResultItem[]): SelectOption[] =>
  businesses.map(({ business }) => ({
    label: business.name,
    value: business.self,
    avatarProps: {
      name: business.name,
      src: business.logoUri,
    },
  }));

export const getSelectOptionsFromBusinessItems = (businessItems: MsnBusinessItem[]): SelectOption[] =>
  businessItems.map(({ business }) => ({
    label: business.name ?? '',
    value: business.id,
    description: business.contact?.address ? formatAddress(business.contact.address) : undefined,
    avatarProps: {
      name: business.name,
    },
  }));
