import { Box } from '@chakra-ui/react';
import { Container, Group, LoadingContainer } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { useIsAccountingFirm } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SectionHeader, SettingsPageHeader, useLocation } from '@melio/platform-utils';

import {
  useIsFirmOnClientOrganization,
  useIsSubscriptionBillingPayorIsFirm,
  usePlanInfo,
  useSubscription,
  useSubscriptionOrganizationFailureState,
  useSubscriptionPlan,
} from '../../../hooks';
import {
  PlanSettingCard,
  SubscriptionBillingHistory,
  SubscriptionPaymentMethod,
  SubscriptionPlansSelect,
} from '../../components';
import { SubscriptionPlansSettingsIntent, useSubscriptionRouter } from '../../utils';
import {
  SubscriptionPaidByFirmSectionBanner,
  SubscriptionPaymentFeeFailedBanner,
  SubscriptionPaymentPendingBanner,
} from './components';

type SubscriptionPlansSettingsScreenProps = {
  switchToFirmOrganizationPlansSettings: (firmOrganizationId: string) => void;
};

export const SubscriptionPlansSettingsScreen = ({
  switchToFirmOrganizationPlansSettings,
}: SubscriptionPlansSettingsScreenProps) => {
  const { isVip } = useSubscriptionPlan();
  const isAccountingFirm = useIsAccountingFirm();
  const { formatMessage } = useMelioIntl();
  const router = useSubscriptionRouter();
  const subscription = useSubscription();
  const { planId } = subscription || {};
  const { planName } = usePlanInfo(planId);
  const { state } = useLocation<{ intent?: SubscriptionPlansSettingsIntent }>();
  const { failureState } = useSubscriptionOrganizationFailureState();

  const isSubscriptionPaidByFirm = useIsSubscriptionBillingPayorIsFirm();
  const { isLoading, isFirmOnClientOrganization, firmOrganizationId } = useIsFirmOnClientOrganization();

  const isClientWithPlanManagedByFirm = isSubscriptionPaidByFirm && !isFirmOnClientOrganization;

  useAnalyticsView('Settings', true, true, {
    PageName: 'plans',
    Flow: 'settings',
    Intent: 'view-plans-page',
    CurrentPlan: planName.toLowerCase(),
  });

  return (
    <>
      <SettingsPageHeader
        title={formatMessage('app.settings.BillingSection.cards.subscriptionPlans.title')}
        subTitle={formatMessage('app.settings.BillingSection.cards.subscriptionPlans.description')}
      />
      <Container paddingTop="s">
        <LoadingContainer isLoading={isLoading}>
          {isSubscriptionPaidByFirm && (
            <Box pt="m" pb="xl">
              <SubscriptionPaidByFirmSectionBanner
                switchToFirmOrganizationPlansSettings={switchToFirmOrganizationPlansSettings}
                planName={planName}
                isExternalAccountant={isFirmOnClientOrganization}
                firmOrganizationId={firmOrganizationId}
              />
            </Box>
          )}

          <Group variant="vertical" spacing="xl" data-testid="subscription-plans">
            {failureState && (
              <Container paddingTop="s">
                {/*
                We also check the failure state since on refresh state.intent will not change, so we don't want to display
                the banner in the case that failure state was resolved.
            */}
                {state?.intent === 'resolve-failed-fee-payment' ? (
                  <SubscriptionPaymentPendingBanner />
                ) : (
                  <SubscriptionPaymentFeeFailedBanner />
                )}
              </Container>
            )}
            <PlanSettingCard dataTestId="subscriptions-plans-current-plan">
              <SectionHeader title={formatMessage('widgets.subscriptionPlanSettings.title.currentPlan')} titleAs="h3" />
              <SubscriptionPlansSelect isClientWithPlanManagedByFirm={isClientWithPlanManagedByFirm} />
            </PlanSettingCard>

            {(!isVip || isAccountingFirm) && !isSubscriptionPaidByFirm && (
              <>
                <PlanSettingCard dataTestId="subscriptions-plans-payment-methods">
                  <SectionHeader
                    title={formatMessage('widgets.subscriptionPlanSettings.title.paymentMethod')}
                    titleAs="h3"
                  />
                  <SubscriptionPaymentMethod
                    onChange={() => {
                      router.goToPaymentMethodSelect();
                    }}
                  />
                </PlanSettingCard>

                <PlanSettingCard dataTestId="subscriptions-plans-billing-history">
                  <SectionHeader
                    title={formatMessage('widgets.subscriptionPlanSettings.title.billingHistory')}
                    titleAs="h3"
                  />
                  <SubscriptionBillingHistory />
                </PlanSettingCard>
              </>
            )}
          </Group>
        </LoadingContainer>
      </Container>
    </>
  );
};
