import { useFile, useInvoiceExpanded, useReceivablePayment } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { PaymentDrawerScreen } from './screens';
import { getFileName } from './utils';

export type PaymentDrawerActivityProps = {
  onClose: VoidFunction;
  onError?: ARErrorFunction;
  rpdId: string;
};

export const PaymentDrawerActivity = forwardRef<PaymentDrawerActivityProps>(
  ({ onClose, rpdId, onError, ...props }, ref) => {
    const { data: receivablePayment, isFetching: isFetchingPayment } = useReceivablePayment({ id: rpdId, onError });
    const [isClosing, setIsClosing] = useState(false);
    const { data: expandedInvoice, isLoading: isFetchingExpandedInvoice } = useInvoiceExpanded({
      id: receivablePayment?.invoices?.[0]?.id,
      enabled: !!receivablePayment?.invoices?.[0],
    });
    const { data: payeeFile, isLoading: isFetchingPayeeFile } = useFile({
      id: expandedInvoice?.fileId || '',
      enabled: !!expandedInvoice?.id,
      onError,
    });
    const updatedPayeeFile = payeeFile
      ? { ...payeeFile, fileName: getFileName(payeeFile.fileName as string) }
      : payeeFile;

    const { data: payorFile, isLoading: isFetchingPayorFile } = useFile({
      id: receivablePayment?.paymentAttachmentIds?.[0],
      enabled: !!receivablePayment?.paymentAttachmentIds?.[0],
    });
    const updatedPayorFile = payorFile
      ? { ...payorFile, fileName: getFileName(payorFile.fileName as string) }
      : payorFile;
    const handleCloseDrawer = () => {
      setIsClosing(true);
    };

    return (
      <PaymentDrawerScreen
        receivablePayment={receivablePayment}
        expandedInvoice={expandedInvoice}
        isOpen={!isClosing}
        onCloseButtonClick={handleCloseDrawer}
        onClose={onClose}
        payeeFile={updatedPayeeFile}
        payorFile={updatedPayorFile}
        isLoadingPayeeFile={isFetchingPayeeFile}
        isLoadingPayorFile={isFetchingPayorFile}
        isLoadingInvoice={isFetchingExpandedInvoice}
        isLoadingPayment={isFetchingPayment}
        ref={ref}
        {...props}
      />
    );
  }
);

PaymentDrawerActivity.displayName = 'PaymentDrawerActivity';
