import { useMtlDateUtils } from '@melio/ap-domain';
import { useInternationalAddressSchema } from '@melio/ap-widgets';
import { OrganizationKeyIndividualValidations, ValidationResponse } from '@melio/compliance-validator';
import { FieldValues, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { MIN_AGE } from '@melio/platform-kyc';
import type { Path } from 'react-hook-form';
import { AnyObject } from 'react-joyride';
import * as yup from 'yup';

import { phoneIsValid } from '../../../../../../utils/validation/phone.valdiation.utils';
import {
  IdType,
  mapInternationalTypeToInternationalIdType,
  mapTaxIdTypeToOkiTaxIdType,
  Residency,
  TaxIdType,
  USResidency,
} from '../types';
import { riskVallationResponseHandler } from './riskVallationResponseHandler';

const getDirtyValues = <T extends FieldValues, DirtyFields extends Record<string, unknown>>({
  dirtyFields,
  formValues,
}: {
  dirtyFields: DirtyFields;
  formValues: T;
}): Partial<T> =>
  Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) {
      return prev;
    }

    return {
      ...prev,
      [key as keyof T]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues({ dirtyFields: dirtyFields[key] as DirtyFields, formValues: formValues[key] as T })
          : (formValues[key] as T),
    };
  }, {});
export const getDirtyValuesFromForm = <T extends FieldValues>({
  form,
}: {
  form: UseMelioFormResults<T>;
}): Partial<T> => {
  const formValues = form.getValues();
  const dirtyFields = form.formState.dirtyFields;

  return getDirtyValues({ dirtyFields, formValues });
};

export const setValueAndMakeDirty = <T extends FieldValues, K extends Path<T>>({
  fieldKey,
  form,
  value,
}: {
  form: UseMelioFormResults<T>;
  fieldKey: K;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: T[K];
}) => form.setValue(fieldKey, value, { shouldDirty: true });

export const useFxCommonValidations = () => {
  const { formatMessage } = useMelioIntl();
  const { addressSchema, countryCodeSchema } = useInternationalAddressSchema();
  const { isValidAge } = useMtlDateUtils();

  const createErrorFromValidationResponse = (
    validationResponse: ValidationResponse,
    context: yup.TestContext<AnyObject>
  ) => {
    const message = riskVallationResponseHandler(validationResponse);
    if (validationResponse.isValid || !message) {
      return true;
    }
    return context.createError({ message: formatMessage(message) });
  };

  type ValidationFunction<T> = (value?: T | null) => ValidationResponse;
  const riskValidationTest =
    <T>(validateFunction: ValidationFunction<T>) =>
    (value: T | undefined | null, context: yup.TestContext<AnyObject>) => {
      const validationResponse = validateFunction(value);
      return createErrorFromValidationResponse(validationResponse, context);
    };

  const firstNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.firstName.invalid'
      )
    )
    .trim();

  const lastNameSchema = yup
    .string()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.required'
      )
    )
    .min(
      2,
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.lastName.invalid'
      )
    )
    .trim();

  const ssnSchema = yup
    .string()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.ssn.required')
    )
    .trim();

  const dateOfBirthSchema = yup
    .date()
    .required(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .typeError(
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.required'
      )
    )
    .test(
      'validMinAge',
      formatMessage(
        `activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.dateOfBirth.minAge`,
        {
          age: MIN_AGE,
        }
      ),
      (v) => isValidAge(v, MIN_AGE, 'min')
    );

  const emailSchema = yup
    .string()
    .email(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.invalid')
    )
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.email.required')
    )
    .trim();

  const phoneSchema = yup
    .string()
    .trim()
    .required(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.required')
    )
    .test(
      'phoneIsValid',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.phone.invalid'),
      phoneIsValid
    );
  const residencyConditionalRequired =
    <T extends yup.AnySchema>(isCondition: 'usResidence' | 'nonUsResidence', message: string) =>
    (schema: T): T =>
      schema.when('usResidence', {
        is: isCondition,
        then: (s) => s.required(message) as T,
        otherwise: (s) => s.notRequired() as T,
      });

  const residencySchema = yup.object({
    usResidence: yup
      .string()
      .oneOf(['usResidence', 'nonUsResidence'])
      .required(
        formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.usResidence.required'
        )
      ),
    taxIdType: residencyConditionalRequired(
      'usResidence',
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxIdType.required'
      )
    )(
      yup.mixed<TaxIdType>().test((taxIdType: TaxIdType | undefined, context: yup.TestContext<AnyObject>) => {
        const validationResponse = OrganizationKeyIndividualValidations.validateKeyIndividualTaxIdType(
          taxIdType ? mapTaxIdTypeToOkiTaxIdType[taxIdType] : null,
          true
        );
        return createErrorFromValidationResponse(validationResponse, context);
      })
    ),
    taxId: residencyConditionalRequired(
      'usResidence',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.taxId.required')
    )(
      yup.string().test((taxId: string | undefined, context: yup.TestContext<AnyObject>) => {
        const { taxIdType } = context.parent as USResidency;
        const validationResponse = OrganizationKeyIndividualValidations.validateKeyIndividualTaxId(
          taxId,
          true,
          mapTaxIdTypeToOkiTaxIdType[taxIdType]
        );
        return createErrorFromValidationResponse(validationResponse, context);
      })
    ),
    idType: residencyConditionalRequired(
      'nonUsResidence',
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.idType.required')
    )(
      yup.mixed<IdType>().test((idType: IdType | undefined, context: yup.TestContext<AnyObject>) => {
        const validationResponse = OrganizationKeyIndividualValidations.validateKeyIndividualInternationalIdType(
          idType ? mapInternationalTypeToInternationalIdType[idType] : null,
          false
        );
        return createErrorFromValidationResponse(validationResponse, context);
      })
    ),
    idNumber: residencyConditionalRequired(
      'nonUsResidence',
      formatMessage(
        'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.idNumber.required'
      )
    )(
      yup.string().test((idNumber: string | undefined, context: yup.TestContext<AnyObject>) => {
        const validationResponse = OrganizationKeyIndividualValidations.validateKeyIndividualInternationalId(
          idNumber,
          false
        );
        return createErrorFromValidationResponse(validationResponse, context);
      })
    ),
  }) as yup.SchemaOf<Residency>;

  const riskDateOfBirthSchema = yup
    .date()
    .typeError(
      formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.organizationUser.invalidDate')
    )
    .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualDateOfBirth));

  return {
    phoneSchema,
    emailSchema,
    firstNameSchema,
    lastNameSchema,
    addressSchema,
    countryCodeSchema,
    ssnSchema,
    dateOfBirthSchema,
    riskDateOfBirthSchema,
    riskValidationTest,
    createErrorFromValidationResponse,
    residencySchema,
  };
};
