import { DeliveryPreference, DeliveryPreferenceType, UnsupportedDeliveryPreferenceReason } from '@melio/platform-api';

export const getFastestDeliveryPreferenceOption = (deliveryPreferences: DeliveryPreference[]) => {
  const prioritizedTypes = [
    DeliveryPreferenceType.Rtp,
    DeliveryPreferenceType.ExpressDomesticWire,
    DeliveryPreferenceType.ExpeditedAch,
    DeliveryPreferenceType.OvernightCheck,
    DeliveryPreferenceType.ExpressCheck,
  ];

  const options = prioritizedTypes
    .map((type) => deliveryPreferences.find((p) => p.type === type))
    .filter(
      (p) =>
        p?.supported ||
        (p?.type === DeliveryPreferenceType.Rtp &&
          p.unsupportedReason === UnsupportedDeliveryPreferenceReason.NoFutureDateScheduling)
    );
  return options[0];
};
