import { Box } from '@chakra-ui/react';
import { Button, Container, Divider, Floating, Group, Icon, NavigationItem, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';

import { FilterMenuOptionsMulti } from './FilterMenuOptionsMulti';
import { FilterMenuOptionsSingle } from './FilterMenuOptionsSingle';
import { NestedFiltersProps } from './types';
import { getFilterByKey } from './utils';

export const NestedFiltersDesktop = <T extends string>({
  filters,
  activeFilters,
  applyAllFilters,
  isFilterButtonDisable,
}: NestedFiltersProps<T>) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [visibleFilterKey, setVisibleFilterKey] = useState<T | undefined>();
  const [selectedOptions, setSelectedOptions] = useState<{ [key in T]?: string[] }>({});
  const [currentFilterOptions, setCurrentFilterOptions] = useState<{ [key in T]?: string[] }>({});

  useEffect(() => {
    if (selectedOptions?.[visibleFilterKey]?.length === 0) {
      const newSelectedOptions = Object.fromEntries(
        Object.entries(selectedOptions).filter(([key]) => key !== visibleFilterKey)
      ) as { [key in T]?: string[] };
      setSelectedOptions(newSelectedOptions);
    }
  }, [selectedOptions, visibleFilterKey]);

  const clickEvent = (cta: string) => {
    track('Dashboard', 'Click', {
      Intent: 'filter',
      TotalFilters: Object.keys(selectedOptions).length,
      FilterValues: Object.fromEntries(Object.entries(selectedOptions).sort(([a], [b]) => a.localeCompare(b))),
      Cta: cta,
    });
  };

  const onCancel = () => {
    setIsMenuOpen(false);
  };

  const handleMenuOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      setIsMenuOpen(isOpen);
      clickEvent('filters');
      setVisibleFilterKey(filters[0]?.key);
      setSelectedOptions({ ...activeFilters });
      setCurrentFilterOptions({ ...activeFilters });
    } else {
      setIsMenuOpen(false);
      clickEvent('cancel');
      setVisibleFilterKey(undefined);
      setSelectedOptions({});
    }
  };

  const onApply = () => {
    clickEvent('apply');
    setCurrentFilterOptions(selectedOptions);
    applyAllFilters(selectedOptions);
    setIsMenuOpen(false);
  };

  const handleSingleItemClick = (filterKey: T, optionValue?: { value: string | undefined; data?: string } | null) => {
    setSelectedOptions({
      ...selectedOptions,
      [filterKey]: optionValue?.value ? compact([optionValue.value, optionValue.data]) : [],
    });
  };

  const handleMultiSelectionItemClick = (filterKey: T, optionValue: string) => {
    const filterValues = selectedOptions?.[filterKey];
    if (filterValues?.includes(optionValue)) {
      setSelectedOptions({
        ...selectedOptions,
        [filterKey]: filterValues.filter((option) => option !== optionValue) ?? [],
      });
    } else {
      setSelectedOptions({
        ...selectedOptions,
        [filterKey]: [...(filterValues ?? []), optionValue],
      });
    }
  };

  const renderTrigger = () => (
    <Button
      variant="tertiary"
      size="medium"
      label={formatMessage('filter.button.trigger.text')}
      leftElement={<Icon size="small" type="filter" aria-hidden />}
      data-testid="filter-trigger"
    />
  );

  const renderMenuItems = () => (
    <Container paddingX="s" paddingY="s">
      <Group variant="vertical" spacing="xxs" role="tablist" aria-orientation="vertical">
        {filters.map((filter) => {
          const isSelected = visibleFilterKey === filter.key;
          return (
            <Container
              key={filter.key}
              data-testid={`filter-menu-item-${filter.key}`}
              onClick={() => setVisibleFilterKey(filter.key)}
              paddingX="none"
              paddingY="none"
              overflow="initial"
            >
              <NavigationItem
                aria-label={filter.label}
                role="tab"
                id={filter.key}
                aria-selected={isSelected}
                isSelected={isSelected}
                isFullWidth
              >
                {filter.label}
              </NavigationItem>
            </Container>
          );
        })}
      </Group>
    </Container>
  );

  const renderMenuItemOptions = () => {
    if (!visibleFilterKey) {
      return null;
    }
    const visibleFilter = getFilterByKey(filters, visibleFilterKey);
    if (!visibleFilter) {
      return null;
    }
    return (
      <Group variant="vertical" spacing="xs" height="full" role="tabpanel">
        <Container paddingX="s" paddingTop="s" paddingBottom="xs">
          <Text textStyle="body2Semi">{visibleFilter.label}</Text>
        </Container>
        <Divider variant="horizontal" />
        <Container overflow="auto" height="full">
          {visibleFilter.type === 'multi' ? (
            <FilterMenuOptionsMulti
              options={visibleFilter.options}
              selected={selectedOptions?.[visibleFilter.key] ?? []}
              onChange={(value: string) => handleMultiSelectionItemClick(visibleFilter.key, value)}
            />
          ) : (
            <FilterMenuOptionsSingle
              options={visibleFilter.options}
              activeFilter={visibleFilter}
              selected={selectedOptions?.[visibleFilter.key] ?? []}
              onChange={(value, data) => handleSingleItemClick(visibleFilter.key, { value, data })}
            />
          )}
        </Container>
      </Group>
    );
  };

  return (
    <Floating
      isOpen={isMenuOpen}
      onOpenChange={handleMenuOpenChange}
      trigger={renderTrigger()}
      data-testid="nested-filter-menu"
      isDisabled={isFilterButtonDisable}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // This is temporary until DS will fix it on their side https://meliorisk.atlassian.net/browse/ME-87220
      maxHeight="400px"
    >
      <Container paddingX="none" paddingY="none">
        <Group variant="vertical" height="full" spacing="none">
          <Container paddingX="none" paddingY="none" overflow="hidden">
            <Group variant="horizontal" spacing="none" height="full">
              <Box width={184}>{renderMenuItems()}</Box>
              <Divider variant="vertical" />
              <Box width={460}>{renderMenuItemOptions()}</Box>
            </Group>
          </Container>
          <Divider variant="horizontal" />
          <Container paddingX="s" paddingY="s" overflow="initial">
            <Group variant="horizontal" justifyContent="space-between">
              <Button
                onClick={onCancel}
                variant="tertiary"
                label={formatMessage('filter.button.cancel')}
                data-testid="nested-filter-menu-cancel"
                size="small"
              />
              <Button
                onClick={onApply}
                isDisabled={isEqual(selectedOptions, currentFilterOptions)}
                variant="primary"
                label={formatMessage('filter.button.apply')}
                data-testid="nested-filter-menu-apply"
                size="small"
              />
            </Group>
          </Container>
        </Group>
      </Container>
    </Floating>
  );
};
