import { SelectOption } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BusinessItemDirectoryName,
  BusinessResultItem,
  MsnBusiness,
  MsnBusinessItem,
  useAccount,
  useVendor,
  useVendors,
  Vendor,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { BUSINESS_SEARCH_DEBOUNCE } from '../../utils';
import { useVendorSelectBusinesses } from './useVendorSelectBusinesses';
import { useVendorSelectFilterQuery } from './useVendorSelectFilterQuery';
import {
  mapUnifiedBusinessItemToSelectOption,
  mapVendorToSelectOption,
  matchMsnBusinessWithVendor,
  matchVendorWithQuery,
} from './useVendorSelectOptions.utils';

type UseVendorSelectOptionsProps = { eventContextName?: string; isViewMode?: boolean; selectedOptionValue?: string };

export const useVendorSelectOptions = ({
  eventContextName,
  isViewMode,
  selectedOptionValue,
}: UseVendorSelectOptionsProps = {}) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { settings } = useConfig();

  const { data: account } = useAccount({ id: 'me' });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const params = useVendorSelectFilterQuery({ searchTerm, account, isViewMode });
  const [isExternalVendorLogoEnabled] = useDevFeature<boolean>(FeatureFlags.ExternalVendorLogoEnabled, false);

  const [vendors, setVendors] = useState<Vendor[]>();
  const [vendorsOptions, setVendorsOptions] = useState<SelectOption[]>();
  const [businessesOptions, setBusinessesOptions] = useState<SelectOption[]>();

  const shouldLoadSelectedVendor = useMemo(
    () => !!selectedOptionValue && !vendors?.some((vendor) => vendor.id === selectedOptionValue),
    [selectedOptionValue, vendors]
  );
  const { data: selectedVendor, isFetched: isSelectedVendorFetched } = useVendor({
    id: selectedOptionValue,
    enabled: shouldLoadSelectedVendor,
  });

  const {
    data: unfilteredMyVendors,
    isFetched: isMyVendorsFetched,
    isMutating: isCreatingVendor,
    create: createVendor,
  } = useVendors({
    params,
    enabled: !!account,
  });

  const myVendors = useMemo(
    () => unfilteredMyVendors?.filter((vendor) => matchVendorWithQuery(vendor, searchTerm)),
    [unfilteredMyVendors, searchTerm]
  );

  const [isInitialFetchDone, setIsInitialFetchDone] = useState<boolean>(!!myVendors);

  const {
    data: directoryBusinesses,
    isLoading: isLoadingBusinesses,
    isDirectoryEnabled,
    directoryName,
  } = useVendorSelectBusinesses({ searchTerm });

  const handleSearch = useDebounceCallback((query: string) => {
    setSearchTerm(query);
  }, BUSINESS_SEARCH_DEBOUNCE);

  useEffect(() => {
    if (myVendors && (!shouldLoadSelectedVendor || isSelectedVendorFetched)) {
      const mergedVendors = myVendors ? [...myVendors] : [];
      if (selectedVendor && !mergedVendors.some((vendor) => vendor.id === selectedVendor.id)) {
        mergedVendors.push(selectedVendor);
      }

      setVendorsOptions(
        mergedVendors.map((vendor) =>
          mapVendorToSelectOption(vendor, {
            isLogoEnabled: isExternalVendorLogoEnabled,
            isVerifiedIconEnabled: settings.showCheckMarkIconForManagedVendor,
          })
        )
      );
      setVendors(mergedVendors);
      setIsInitialFetchDone(true); // TODO: we can remove it after fixing https://meliorisk.atlassian.net/browse/ME-43953
    }
  }, [
    shouldLoadSelectedVendor,
    isSelectedVendorFetched,
    selectedVendor,
    myVendors,
    formatMessage,
    isExternalVendorLogoEnabled,
    settings.showCheckMarkIconForManagedVendor,
  ]);

  useEffect(() => {
    setBusinessesOptions(directoryBusinesses?.map(mapUnifiedBusinessItemToSelectOption) || []);
  }, [directoryBusinesses, formatMessage]);

  const isLocalVendorId = (id: string) => vendors?.some((vendor) => vendor.id === id);

  const getBusinessById = (id: string) => directoryBusinesses?.find(({ business }) => business.id === id);

  const getMatchingVendorForMsnBusiness = (msnBusiness: MsnBusiness) =>
    vendors?.find((vendor) => matchMsnBusinessWithVendor(msnBusiness, vendor));

  const sections = [
    {
      metadata: { label: formatMessage('form.vendorSelect.myVendors.title') },
      options: vendorsOptions ?? [],
    },
    {
      metadata: {
        label:
          directoryName === BusinessItemDirectoryName.Msn
            ? formatMessage('form.vendorSelect.businesses.title.msn')
            : formatMessage('form.vendorSelect.businesses.title'),
        icon: settings.showCheckMarkIconForManagedVendor ? ('verified' as const) : undefined,
      },
      options: businessesOptions ?? [],
    },
  ];

  const getHasUnmanagedLocalVendorWithTheSameName = (name: string) =>
    vendors?.some(
      (localVendor) => !localVendor.isManaged && localVendor.name.toLocaleLowerCase() === name.toLocaleLowerCase()
    );

  type GetSelectedItemAnalyticsDataParams =
    | { vendorType: 'directory'; selectedBusiness: BusinessResultItem | MsnBusinessItem }
    | { vendorType: 'local'; vendorId: Vendor['id'] }
    | { vendorType: 'new-local' };
  const getSelectedItemAnalyticsData = (params: GetSelectedItemAnalyticsDataParams) => {
    const directoryProperties: Record<string, unknown> = {};
    let itemPosition: number | null;
    let cta: string;

    switch (params.vendorType) {
      case 'new-local': {
        itemPosition = null;
        cta = 'add-new';
        break;
      }
      case 'local': {
        itemPosition =
          sections.flatMap(({ options }) => options).findIndex((option) => option.value === params.vendorId) + 1;
        cta = params.vendorId;
        break;
      }
      case 'directory': {
        itemPosition =
          sections
            .flatMap(({ options }) => options)
            .findIndex((option) => option.value === params.selectedBusiness.business.id) + 1;
        cta = params.selectedBusiness.business.name ?? '';
        break;
      }
    }

    if (directoryName === BusinessItemDirectoryName.Fiserv) {
      directoryProperties['BillersCount'] = (businessesOptions || []).length;
    }

    if (directoryName === BusinessItemDirectoryName.Msn) {
      directoryProperties['MsnCount'] = (businessesOptions || []).length;
      directoryProperties['MsnBusinessIds'] = directoryBusinesses?.map(({ business }) => business.id);
      directoryProperties['BusinessId'] =
        params.vendorType === 'directory' ? params.selectedBusiness.business.id : null;
    }

    const localVendorCount = (vendorsOptions || []).length;
    const countResults = localVendorCount + (businessesOptions || []).length;

    return {
      SearchValue: searchTerm,
      VendorType: params.vendorType,
      CountResults: countResults,
      LocalVendorCount: localVendorCount,
      ItemPosition: itemPosition,
      Cta: cta,
      ...directoryProperties,
    };
  };
  const trackView = () => {
    if (eventContextName) {
      track(eventContextName, 'View', { Intent: 'choose-a-vendor' });
    }
  };
  const trackClickOption = (params: GetSelectedItemAnalyticsDataParams) => {
    if (eventContextName) {
      // the same for the rest. move to fucntion
      track(eventContextName, 'Click', {
        ...getSelectedItemAnalyticsData(params),
        Intent: 'choose-a-vendor',
      });
    }
  };
  const [isLoading, setIsLoading] = useState(true);
  // TODO: we can replace the whole use effect with "isLoading="!isMyVendorsFetched || isLoadingBusinesses" after fixing https://meliorisk.atlassian.net/browse/ME-43953
  useEffect(() => {
    // We can turn off `isLoading` only after the options are ready.
    // don't move it out of the use effect  -
    // we need this side effect to prevent displaying the vendor id instead of vendor label (isLoading become false too eralier). we can remove it after merging https://github.com/melio/penny/pull/3289/files
    setIsLoading(
      !isMyVendorsFetched ||
        !vendorsOptions ||
        isLoadingBusinesses ||
        !businessesOptions ||
        !isInitialFetchDone ||
        (shouldLoadSelectedVendor && !isSelectedVendorFetched)
    );
  }, [
    businessesOptions,
    isLoadingBusinesses,
    isMyVendorsFetched,
    vendorsOptions,
    isInitialFetchDone,
    shouldLoadSelectedVendor,
    isSelectedVendorFetched,
  ]);

  // https://github.com/melio/platform-app/pull/10559
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  useEffect(() => {
    if (!isLoading && isFirstLoading) {
      setIsFirstLoading(false);
    }
  }, [isLoading, isFirstLoading]);

  // We can remove this when Penny implements the ability to set value and isLoading in any order
  const [isUpdatingVendorsInProgress, setIsUpdatingVendorsInProgress] = useState(false);
  useEffect(() => {
    if (isUpdatingVendorsInProgress && !isLoading) {
      setIsUpdatingVendorsInProgress(false);
    }
  }, [isUpdatingVendorsInProgress, isLoading]);

  const isSelectedVendorPresented = (shouldLoadSelectedVendor && !!selectedVendor) || !shouldLoadSelectedVendor;
  const areAllOptionsPresented = !!myVendors?.length && isSelectedVendorPresented && !!directoryBusinesses?.length;

  const isDisabled = !isInitialFetchDone || isCreatingVendor || (shouldLoadSelectedVendor && !isSelectedVendorFetched);

  return {
    getSelectedItemAnalyticsData,
    trackView,
    trackClickOption,
    selectedVendor,
    isLoading,
    isFirstLoading,
    isDisabled,
    sections,
    createVendor,
    isCreatingVendor,
    handleSearch,
    areAllOptionsPresented,
    isLocalVendorId,
    getBusinessById,
    canSearchBusinesses: isDirectoryEnabled,
    isSelectedOptionManaged: selectedVendor?.isManaged && settings.showCheckMarkIconForManagedVendor,
    getHasUnmanagedLocalVendorWithTheSameName,
    isUpdatingVendorsInProgress,
    setIsUpdatingVendorsInProgress,
    getMatchingVendorForMsnBusiness,
  };
};
