import { isPayorPaymentFee, sumPaymentFees, useGuestPayorPayment } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { useGuestPaymentIntent } from '../../hooks';
import { BaseLayout } from '../../layout';
import { GuestPaymentIntentParams } from '../../types';
import { GuestPaymentConfirmationScreen } from './screens';

export type GuestPaymentConfirmationActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  paymentId: string;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
};

export const GuestPaymentConfirmationActivity = withAnalyticsContext<GuestPaymentConfirmationActivityProps>(
  forwardRef(({ guestPaymentIntentParams, setAnalyticsProperties, onViewInvoice, paymentId, onError }, ref) => {
    const {
      data: paymentData,
      isLoading: isLoadingPayment,
      error: paymentError,
    } = useGuestPayorPayment({ id: paymentId });

    const {
      isLoading: isGuestPaymentIntentLoading,
      data: guestPaymentIntentData,
      error: guestPaymentIntentError,
    } = useGuestPaymentIntent({ guestPaymentIntentParams, onError });

    setAnalyticsProperties({ PaymentId: paymentId });

    useEffect(() => {
      if (isLoadingPayment || isGuestPaymentIntentLoading) return;
      if (
        guestPaymentIntentError ||
        !paymentData ||
        paymentError ||
        !guestPaymentIntentData?.receivablePaymentDetails
      ) {
        onError?.(guestPaymentIntentError ?? paymentError ?? { code: 'UNKNOWN_ERROR', message: 'Unknown error' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isGuestPaymentIntentLoading, isLoadingPayment]);

    if (
      isGuestPaymentIntentLoading ||
      isLoadingPayment ||
      !guestPaymentIntentData ||
      !guestPaymentIntentData.vendorPaymentDetails ||
      guestPaymentIntentError ||
      !paymentData ||
      paymentError ||
      !guestPaymentIntentData.receivablePaymentDetails
    ) {
      return (
        <BaseLayout
          isLoading
          data-component={GuestPaymentConfirmationActivity.displayName}
          backgroundColor={undefined}
          ref={ref}
        />
      );
    }

    const payorFees = paymentData.fees?.filter(isPayorPaymentFee) ?? [];
    const totalFeesAmount = sumPaymentFees(payorFees);

    return (
      <GuestPaymentConfirmationScreen
        ref={ref}
        amount={guestPaymentIntentData.invoice.totalAmount}
        invoicePreviewUrl={guestPaymentIntentData.invoice.fileInfo?.previewUrls[0]}
        onViewInvoice={onViewInvoice}
        debitDate={paymentData.scheduledDate}
        deliveryDate={paymentData.estimatedDelivery}
        totalFeeAmount={totalFeesAmount}
        invoiceNumber={guestPaymentIntentData.invoice.invoiceNumber}
        vendorName={guestPaymentIntentData.vendorPaymentDetails.payeeDetails.companyName}
        fundingSourceDisplayData={paymentData.fundingSource?.displayName as string}
        backgroundColor={guestPaymentIntentData.vendorPaymentDetails.payeeDetails.brandColor ?? undefined}
      />
    );
  })
);

GuestPaymentConfirmationActivity.displayName = 'GuestPaymentRequestConfirmationActivity';
