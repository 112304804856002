import { BusinessResultItem, MsnBusinessItem, useBusinesses, useSearchBusinesses } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useDebounceCallback } from '@melio/platform-utils';
import { useEffect, useMemo, useState } from 'react';

import { BUSINESS_SEARCH_DEBOUNCE } from '../utils';
import { getSelectOptionsFromBusinesses, getSelectOptionsFromBusinessItems } from './BusinessSearch.utils';

export const useBusinessSearchOptions = (shouldUseNewBusinessesSearch = false) => {
  const {
    settings: {
      showCheckMarkIconForManagedVendor,
      vendor: {
        forms: { shouldUseSearchVendorSectionLabel },
      },
    },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );
  const [isNewBusinessesSearchEnabled] = usePartnerFeature('NewBusinessesSearch', false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isPreparingOptions, setIsPreparingOptions] = useState<boolean>(false);

  const shouldSearch = searchTerm.length > 2;

  const { isLoading: isLoadingFiservBusinessItems, data: fiservBusinessItems } = useBusinesses({
    params: { searchTerm },
    enabled:
      shouldSearch &&
      isSearchBusinessesInDirectoriesSupported &&
      !(isNewBusinessesSearchEnabled && shouldUseNewBusinessesSearch),
  });
  const { isLoading: isLoadingAllBusinessItems, data: allBusinessItems } = useSearchBusinesses({
    params: { searchTerm },
    enabled: shouldSearch && isNewBusinessesSearchEnabled && shouldUseNewBusinessesSearch,
  });

  useEffect(() => {
    if (fiservBusinessItems && fiservBusinessItems.length > 0 && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [fiservBusinessItems, isPreparingOptions]);

  useEffect(() => {
    if (allBusinessItems?.msn && allBusinessItems.msn.length > 0 && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [allBusinessItems, isPreparingOptions]);

  useEffect(() => {
    if (
      (!fiservBusinessItems || fiservBusinessItems.length === 0) &&
      !isLoadingFiservBusinessItems &&
      isPreparingOptions
    ) {
      setIsPreparingOptions(false);
    }
  }, [fiservBusinessItems, isLoadingFiservBusinessItems, isPreparingOptions]);

  useEffect(() => {
    if ((!allBusinessItems || allBusinessItems.msn.length === 0) && !isLoadingAllBusinessItems && isPreparingOptions) {
      setIsPreparingOptions(false);
    }
  }, [allBusinessItems, isLoadingAllBusinessItems, isPreparingOptions]);

  const handleSearch = useDebounceCallback((query: string) => {
    if (query !== '') {
      setIsPreparingOptions(true);
    }
    setSearchTerm(query);
  }, BUSINESS_SEARCH_DEBOUNCE);

  const selectOptions = useMemo(() => {
    if (fiservBusinessItems && fiservBusinessItems.length > 0) {
      return getSelectOptionsFromBusinesses(fiservBusinessItems);
    }

    if (allBusinessItems && allBusinessItems.msn.length > 0) {
      return getSelectOptionsFromBusinessItems(allBusinessItems.msn);
    }

    return [];
  }, [allBusinessItems, fiservBusinessItems]);

  const options = useMemo(() => {
    if (!shouldUseSearchVendorSectionLabel) {
      return selectOptions;
    }

    const metadata = {
      icon: showCheckMarkIconForManagedVendor ? ('verified' as const) : undefined,
      label: isNewBusinessesSearchEnabled
        ? formatMessage('widgets.businessSearch.businessNetworkTitle.msn')
        : formatMessage('widgets.businessSearch.businessNetworkTitle'),
    };

    return [
      {
        options: selectOptions,
        metadata,
      },
    ];
  }, [
    selectOptions,
    isNewBusinessesSearchEnabled,
    shouldUseSearchVendorSectionLabel,
    showCheckMarkIconForManagedVendor,
    formatMessage,
  ]);

  const getBusinessByOptionValue = (value: string): BusinessResultItem | MsnBusinessItem | undefined => {
    if (isNewBusinessesSearchEnabled) {
      return allBusinessItems?.msn.find(({ business }) => business.id === value);
    }

    return fiservBusinessItems?.find(({ business }) => business.self === value);
  };

  const getSelectedItemAnalyticsProps = (selected?: BusinessResultItem | MsnBusinessItem) => {
    const filteredFiservBusinessItems = (fiservBusinessItems || []).filter(
      (option) => option.directoryName === 'Fiserv'
    );
    const msnBusinessItems = allBusinessItems?.msn ?? [];

    const vendorType = selected ? 'directory' : 'new-local';
    const directoryProperties: Record<string, unknown> = {};
    let itemPosition;
    let countResults;
    let cta;

    if (isNewBusinessesSearchEnabled) {
      itemPosition = selected
        ? selectOptions.findIndex(({ value }) => value === (selected as MsnBusinessItem).business.id) + 1
        : null;
      countResults = msnBusinessItems.length;
      cta = selected ? (selected as MsnBusinessItem).business.name : 'add-new';
      directoryProperties['MsnCount'] = msnBusinessItems.length;
      directoryProperties['MsnBusinessIds'] = msnBusinessItems.map(({ business }) => business.id);
      directoryProperties['BusinessId'] = selected ? selected.business.id : null;
    } else {
      itemPosition = selected
        ? selectOptions.findIndex(({ value }) => value === (selected as BusinessResultItem).business.self) + 1
        : null;
      countResults = (fiservBusinessItems || []).length;
      cta = selected ? (selected as BusinessResultItem).business.name : 'add-new';
      directoryProperties['BillersCount'] = filteredFiservBusinessItems.length;
    }

    return {
      SearchValue: searchTerm,
      VendorType: vendorType,
      CountResults: countResults,
      ItemPosition: itemPosition,
      Cta: cta,
      ...directoryProperties,
    };
  };

  return {
    isLoading: isLoadingFiservBusinessItems || isLoadingAllBusinessItems || isPreparingOptions,
    isNewSearch: isNewBusinessesSearchEnabled && shouldUseNewBusinessesSearch,
    options,
    handleSearch,
    shouldSearch,
    getBusinessByOptionValue,
    getSelectedItemAnalyticsProps,
  };
};
