import {
  ApiKeys,
  PostRecommendedVendorSubmitDecisionResponseDataDecisionEnum,
  RecommendedVendorsApiClient,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from '@tanstack/react-query';

export type SubmitVendorRecommendationDecisionData = {
  recommendationId: string;
  decision: PostRecommendedVendorSubmitDecisionResponseDataDecisionEnum;
};

export const useSubmitVendorRecommendationDecision = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: (data: object) => void;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: [ApiKeys.RecommendedVendorsApi, 'submitVendorRecommendationDecision'],
    mutationFn: async (data: SubmitVendorRecommendationDecisionData) => {
      const result = await RecommendedVendorsApiClient.postRecommendedVendorSubmitDecision(data.recommendationId, {
        decision: data.decision,
      });
      return result.data;
    },
    onError: () => onError?.(),
    onSuccess: (data) => onSuccess?.(data),
  });

  return {
    ...mutation,
    isMutating:
      queryClient.isMutating({ mutationKey: [ApiKeys.RecommendedVendorsApi, 'submitVendorRecommendationDecision'] }) >
      0,
  };
};
