import { Group, Tourtip } from '@melio/penny';
import { DeliveryMethodType, useVendor } from '@melio/platform-api';

import { useLoadingStateContext } from '../../../../LoadingStates';
import { DeliveryMethodSectionProps } from '../types';
import { SelectedDeliveryMethodCard } from './components/DeliveryMethodCard/SelectedDeliveryMethodCard';
import { BankAccountFormModal } from './components/modals/BankAccountFormModal/BankAccountFormModal';
import { useBankAccountDetailsFormModal } from './components/modals/BankAccountFormModal/useBankAccountDetailsFormModal';
import { PaperCheckFormModal } from './components/modals/PaperCheckFormModal/PaperCheckFormModal';
import { usePaperCheckFormModal } from './components/modals/PaperCheckFormModal/usePaperCheckFormModal';
import { NotSelected } from './components/NotSelected';
import { useFirstTimeAchDmTourtip } from './useFirstTimeAchDmTourtip';

export function DeliveryMethodSectionWithModals({
  vendorId,
  deliveryMethodId,
  isDisabled,
  onDeliveryMethodChange,
  paymentSettings,
}: DeliveryMethodSectionProps) {
  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: vendorId });

  const bankAccountModal = useBankAccountDetailsFormModal({
    vendor,
    onDone: onDeliveryMethodChange,
  });

  const paperCheckModal = usePaperCheckFormModal({
    vendor,
    onDone: onDeliveryMethodChange,
  });

  const {
    show: showAchTourtip,
    isLoading: isUserPreferenceLoading,
    props: achTourtipProps,
  } = useFirstTimeAchDmTourtip();

  const onEditDeliveryMethod = (type: DeliveryMethodType) => {
    const dmTypeToAction: Partial<Record<DeliveryMethodType, VoidFunction>> = {
      'bank-account': bankAccountModal.open,
      'paper-check': paperCheckModal.open,
    };
    dmTypeToAction[type]?.();
  };

  const loadingStateContext = useLoadingStateContext();

  const isLoading = isVendorLoading || isUserPreferenceLoading || loadingStateContext.includes('dm');

  const emptyState = !deliveryMethodId && !isLoading;

  return (
    <>
      {emptyState ? (
        <Group spacing="none">
          <Group.Item data-testid="tourtip-target" />
          {showAchTourtip && <Tourtip {...achTourtipProps} />}
          <NotSelected
            onBankClick={() => onEditDeliveryMethod('bank-account')}
            onPaperCheckClick={() => onEditDeliveryMethod('paper-check')}
          />
        </Group>
      ) : (
        <SelectedDeliveryMethodCard
          vendorId={vendor?.id}
          deliveryMethodId={deliveryMethodId}
          isLoading={isLoading}
          isDisabled={isDisabled}
          onDeliveryMethodChange={onDeliveryMethodChange}
          onEditDeliveryMethod={onEditDeliveryMethod}
          deliveryMethodTypeOptions={paymentSettings?.deliveryMethodTypeOptions}
        />
      )}
      <BankAccountFormModal onClose={bankAccountModal.close} {...bankAccountModal} />
      <PaperCheckFormModal onClose={paperCheckModal.close} {...paperCheckModal} />
    </>
  );
}
