export const ARTab = {
  Invoices: 'invoices',
  Customers: 'customers',
  Payments: 'payments',
  Payouts: 'payouts',
} as const;

export type ARTab = (typeof ARTab)[keyof typeof ARTab];

export const DefaultARTab: ARTab = ARTab.Invoices;
