import { Box } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

export const Footer = forwardRef<{ children?: ReactNode }, 'div'>((props, ref) => (
  <Box
    data-component="NewSinglePaymentStepLayout.Footer"
    display="flex"
    justifyContent="space-between"
    width="100%"
    marginX="auto"
    gap="m"
    {...props}
    ref={ref}
  />
));

Footer.displayName = 'NewSinglePaymentStepLayout.Footer';
