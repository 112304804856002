import * as yup from 'yup';

export type PostalCodeValidationErrorMessages = {
  required: string;
  format: string;
};

export const getPostalCodeValidationSchema = (errorMessages: PostalCodeValidationErrorMessages) =>
  yup
    .string()
    .required(errorMessages.required)
    .matches(/^\d{5}(?:[-\s]\d{4})?$|^\d{9}$/, errorMessages.format);
