import { Illustration } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { Carousel } from '../../../../../components/Carousel/Carousel';
import { ProTipItem } from './ProTipItem';

export const ProTipsCarousel = () => {
  const { formatMessage } = useMelioIntl();

  const carouselItems: Array<ProTipItem> = [
    {
      id: 'unifiedPayments',
      title: formatMessage('activities.accountants.firmDashboard.v2.proTips.unifiedPayments.title'),
      description: formatMessage('activities.accountants.firmDashboard.v2.proTips.unifiedPayments.description'),
      linkText: formatMessage('activities.accountants.firmDashboard.v2.proTips.unifiedPayments.linkText'),
      image: <Illustration size="small" type="pay-zero" />,
      // TODO: add handler when getting links
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
      },
    },
    {
      id: 'centralizedPayments',
      title: formatMessage('activities.accountants.firmDashboard.v2.proTips.centralizedPayments.title'),
      description: formatMessage('activities.accountants.firmDashboard.v2.proTips.centralizedPayments.description'),
      linkText: formatMessage('activities.accountants.firmDashboard.v2.proTips.centralizedPayments.linkText'),
      image: <Illustration size="small" type="small-business" />,
      // TODO: add handler when getting links
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
      },
    },
    {
      id: 'accountingSoftware',
      title: formatMessage('activities.accountants.firmDashboard.v2.proTips.accountingSoftware.title'),
      description: formatMessage('activities.accountants.firmDashboard.v2.proTips.accountingSoftware.description'),
      linkText: formatMessage('activities.accountants.firmDashboard.v2.proTips.accountingSoftware.linkText'),
      image: <Illustration size="small" type="sync-accounts" />,
      // TODO: add handler when getting links
      onClick: (e: React.MouseEvent) => {
        e.preventDefault();
      },
    },
  ];

  return (
    <Carousel title={formatMessage('activities.accountants.firmDashboard.v2.proTips.title.label')}>
      {carouselItems.map((item) => (
        <Carousel.Item key={item.id}>
          <ProTipItem {...item} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
