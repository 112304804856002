import { DependencyList, useEffect, useRef } from 'react';

export const useOnce = (fn: VoidFunction, deps: DependencyList = []) => {
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    fn();
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};
