import { SortingState } from '@melio/penny';

export const sortComparison = (
  valueA: string | number,
  valueB: string | number,
  direction?: SortingState['sortDirection']
) => {
  let comparison = 0;
  if (valueA > valueB) {
    comparison = 1;
  } else if (valueA < valueB) {
    comparison = -1;
  }
  return direction === 'desc' ? comparison * -1 : comparison;
};

export const getPaginationIndex = (currentPage: number, pageSize: number, totalItems: number) => {
  const getStartIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const totalPages = getTotalPages(totalItems, pageSize);
    const pageForCalculation = Math.min(currentPage, totalPages);
    return Math.max(1, 1 + pageSize * (pageForCalculation - 1));
  };

  const getEndIndex = (pageSize: number, currentPage: number, totalItems: number): number => {
    const pageForCalculation = Math.max(currentPage, 1);
    return Math.min(pageSize * pageForCalculation, totalItems);
  };

  const getTotalPages = (totalItems: number, pageSize: number): number => Math.ceil(totalItems / pageSize);

  return {
    startIndex: getStartIndex(pageSize, currentPage, totalItems),
    endIndex: getEndIndex(pageSize, currentPage, totalItems),
  };
};
