import { useIsMobile } from '@melio/ar-domain';
import { Group, Illustration, IllustrationProps, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type HeaderProps = {
  title: string;
  description?: string;
  illustration?: IllustrationProps['type'];
};

export const Header = forwardRef<HeaderProps>(({ title, children, description, illustration, ...props }, ref) => {
  const isMobile = useIsMobile();
  return (
    <Group variant="vertical" spacing={isMobile ? 's' : 'm'} {...props} ref={ref}>
      {illustration && <Illustration type={illustration} />}
      <Group variant="vertical" spacing="xxs">
        <Text as="h2" textStyle="heading2Semi" color="semantic.text.primary">
          {title}
        </Text>
        {description && (
          <Text as="p" textStyle="body2" color="semantic.text.secondary">
            {description}
          </Text>
        )}
      </Group>
    </Group>
  );
});
Header.displayName = 'Header';
