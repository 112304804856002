import { useVendorNameConstraints } from '@melio/ap-domain';
import { VendorDeliveryMethodsSection } from '@melio/ap-widgets';
import { Container, Divider, Form, Group, Loader, SectionBanner, VisuallyHidden } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { Suspense, useEffect } from 'react';

import { CompanyField } from '../../../AddVendor/AddVendorForm/components/CompanyField';
import { CountrySelect } from '../../../fields';
import { useVendorForm } from '../../../useVendorForm';
import {
  UnmanagedVendorFormMinimalFormFields,
  useUnmanagedVendorFormMinimalSchema,
} from '../../../VendorDetailsBaseSchema';
import { EditVendorFormWidgetProps } from '../../types';
import { getUpdateVendorPayload } from '../utils';

export const EditUnmanagedVendorFormMinimal = ({
  vendor,
  isSaving,
  titlesAs,
  defaultValues,
  isEmailRequired,
  inlineApiErrorCodes,
  bannerApiErrorCodes,
  emailRequiredMessage,
  shouldHideCompanyNameField,
  onSubmit,
  onSelectCompanyName,
  onDeliveryMethodChanged,
  onSubmissionStateChange,
  onClearCompanyNameSearchField,
  ...props
}: EditVendorFormWidgetProps) => {
  const { formatMessage } = useMelioIntl();
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );

  const {
    settings: {
      vendor: {
        createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
        forms: { shouldRequireEmail, shouldDisableEmailEdit, shouldCollectCountry },
      },
    },
  } = useConfig();

  const { formProps, registerField, watch, setValue, reset, formState } =
    useVendorForm<UnmanagedVendorFormMinimalFormFields>({
      inlineApiErrorCodes,
      bannerApiErrorCodes,
      onSubmit: (data, _e, target) => onSubmit(getUpdateVendorPayload(data), target),
      schema: useUnmanagedVendorFormMinimalSchema({
        showCompanyField: !shouldHideCompanyNameField,
        isManaged: false,
        isEmailRequired: shouldRequireEmail || isEmailRequired,
        emailRequiredMessage,
        shouldCollectCountry,
        shouldCollectVendorAddress: false,
      }),
      isSaving,
      defaultValues: {
        ...defaultValues,
        ...(shouldCollectCountry ? { countryCode: 'US' } : {}),
      },
      onSubmissionStateChange,
    });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const companyNameInputProps = registerField('companyName');

  const { vendorNameBannerDescription, vendorNameConstraintType } = useVendorNameConstraints({
    vendorName: watch('companyName') ?? undefined,
    vendorNameFieldErrorType: companyNameInputProps?.error?.type,
    isManaged: false,
    isFormSubmitted: formState.isSubmitted,
    isFreeTextInput: !isSearchBusinessesInDirectoriesSupported,
  });

  return (
    <>
      <Form rowGap="m" data-component="EditVendorFormWidget" {...props} {...formProps}>
        {!shouldHideCompanyNameField && (
          <Form.ContentBox>
            <Group spacing="xs" variant="vertical">
              <CompanyField
                onSelectCompanyName={onSelectCompanyName}
                onClearSearchField={() => {
                  setValue('companyName', null);
                  onClearCompanyNameSearchField?.();
                }}
                {...companyNameInputProps}
                autoFocus
              />
              {vendorNameBannerDescription && (
                <SectionBanner
                  data-testid={`company-name-suggestion-${vendorNameConstraintType}`}
                  description={vendorNameBannerDescription}
                />
              )}
            </Group>
          </Form.ContentBox>
        )}
        <Form.TextField
          {...registerField('fullName')}
          labelProps={{ label: formatMessage('widgets.vendors.fullName.label') }}
          placeholder={formatMessage('widgets.vendors.fullName.placeholder')}
          autoFocus={shouldHideCompanyNameField}
        />
        {shouldCollectAccountNumberForUnmanagedVendor && (
          <Form.TextField
            data-private
            {...registerField('accountNumber')}
            labelProps={{
              label: formatMessage('widgets.vendors.accountNumber.label'),
              tooltipProps: {
                content: (
                  <>
                    <VisuallyHidden>
                      {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')} tooltip
                    </VisuallyHidden>
                    {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')}
                  </>
                ),
              },
            }}
            autoFocus={shouldHideCompanyNameField}
          />
        )}
        <Form.TextField
          {...registerField('email')}
          labelProps={{
            label: formatMessage('widgets.vendors.email.label'),
            tooltipProps: shouldDisableEmailEdit
              ? {
                  content: (
                    <>
                      <VisuallyHidden>
                        {formatMessage('widgets.vendorDetails.form.email.labelTooltip')} tooltip
                      </VisuallyHidden>
                      {formatMessage('widgets.vendorDetails.form.email.labelTooltip')}
                    </>
                  ),
                }
              : undefined,
          }}
          placeholder={formatMessage('widgets.vendors.email.placeholder')}
          isReadOnly={shouldDisableEmailEdit}
        />
        <Form.PhoneField
          {...registerField('phone')}
          labelProps={{ label: formatMessage('widgets.vendors.phone.label') }}
          placeholder={formatMessage('widgets.vendors.phone.placeholder')}
        />
        {shouldCollectCountry ? <CountrySelect {...registerField('countryCode')} isReadOnly /> : null}
        <Divider />
      </Form>
      <Suspense
        fallback={
          <Container paddingY="xl" alignItems="center" justifyContent="center">
            <Loader />
          </Container>
        }
      >
        <VendorDeliveryMethodsSection
          vendor={vendor}
          isSaving={isSaving}
          onDeliveryMethodChanged={onDeliveryMethodChanged}
        />
      </Suspense>
    </>
  );
};

EditUnmanagedVendorFormMinimal.displayName = 'EditVendorFormWidget';
