import { Container, Group } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useSubscription } from '@melio/subscriptions';
import { useSubscriptionMe } from '@melio/subscriptions/src/api';
import kebabCase from 'lodash/kebabCase';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { usePatchAccountantFirmClient } from '../../api/entities/client/usePatchAccountantFirmClient';
import { ErrorBanner, FlowHeader, ManageClientFormFields } from '../../components';
import { SubscriptionBillingPayor } from '../../types';
import { routes } from '../../utils/routes';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { AssignFirmClientPlanForm } from './components';
export const AssignFirmClientPlan = withAnalyticsContext<{ onClose: VoidFunction }>(
  ({ onClose, setAnalyticsProperties }) => {
    const { formatMessage } = useMelioIntl();
    const title = formatMessage('activities.accountants.assignPlan.form.header.text');
    const PageName = kebabCase(title);
    setAnalyticsProperties({
      PageName,
      Flow: 'assign-client-plan',
    });

    const { data: firmAccount } = useAccount({ id: 'me' });
    const { id: clientId = '' } = useParams<{ id: string }>();
    const firmSubscription = useSubscription();
    const { update: updateSubscription } = useSubscriptionMe({ enabled: false });
    const [updateError, setUpdateError] = useState<string>();
    const { mutateAsync: updateAccountantFirmClient, isLoading: isLoadingUpdateAccountFirmClient } =
      usePatchAccountantFirmClient();

    const { goToAssignClientPlanSuccessScreen } = useAccountantsRoutes();
    const { track } = useAnalytics();

    const handleSubmit = async (data: ManageClientFormFields) => {
      const { subscriptionPlanId, fundingSourceId, whoPays, subscriptionPromoCode } = data;

      if (!subscriptionPlanId) {
        return;
      }

      setUpdateError(undefined);

      const eventProps = {
        PlanId: subscriptionPlanId,
        FundingSourceId: fundingSourceId,
        BillingCycle: 'monthly',
      };

      const promoCode = subscriptionPromoCode || undefined;

      try {
        const isFirmBilled = whoPays === SubscriptionBillingPayor.AccountingFirm;
        if (isFirmBilled && fundingSourceId && !firmSubscription?.fundingSourceId) {
          await updateSubscription({ fundingSourceId, promoCode });
        }
        const response = await updateAccountantFirmClient({
          id: clientId,
          data: {
            subscription: {
              planId: subscriptionPlanId,
              fundingSourceId: isFirmBilled ? fundingSourceId : undefined,
              promoCode,
            },
          },
        });
        track('Organization', 'Status', {
          StatusType: 'success',
          ...eventProps,
        });
        goToAssignClientPlanSuccessScreen({
          accountingClientId: response.data.organizationId,
          activated: isFirmBilled,
        });
      } catch (error: unknown) {
        track('Organization', 'Status', {
          StatusType: 'failure',
          ...eventProps,
        });
        const message =
          error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
            ? error.message
            : formatMessage('activities.accountants.activities.assignPan.update.error');
        setUpdateError(message);
        Logger.log(`Error while trying to create new client.`);
      }
    };

    const shouldRedirectToDashboard = clientId === firmAccount?.organizationId;
    useEffect(() => {
      if (!shouldRedirectToDashboard) {
        track('Organization', 'View', {
          PageName,
        });
      }
    }, [PageName, shouldRedirectToDashboard, track]);

    if (shouldRedirectToDashboard) {
      return <Navigate to={routes.DASHBOARD} replace />;
    }

    return (
      <Layout>
        <Container justifyContent="center" height="full" data-testid="assign-plan-container">
          <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
            <FlowHeader title={formatMessage('activities.accountants.assignPlan.form.header.text')} onClose={onClose} />
            <ErrorBanner message={updateError} dataTestId="assign-plan-method-error-banner" />
            <AssignFirmClientPlanForm
              onSubmit={handleSubmit}
              isSaving={isLoadingUpdateAccountFirmClient}
              organizationId={clientId}
            />
          </Group>
        </Container>
      </Layout>
    );
  }
);
