import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

import { usePaymentRequestStatus } from '../../hooks';
import { PaymentLayout } from '../../layout';
import { GuestPaymentIntentParams, PaymentRequestStatusType } from '../../types';

export type PaymentRequestTriageActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onDone: (next: PaymentRequestStatusType, paymentRequestLink: string) => unknown;
};

export const PaymentRequestTriageActivity = forwardRef<PaymentRequestTriageActivityProps>(
  ({ guestPaymentIntentParams, onDone, ...props }, ref) => {
    const { isLoading, status, paymentRequestLink: link } = usePaymentRequestStatus(guestPaymentIntentParams);

    useEffect(() => {
      if (!isLoading && link) onDone(status, link);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, status, link]);

    return (
      <PaymentLayout
        data-component={PaymentRequestTriageActivity.displayName}
        isLoading
        onViewInvoice={() => null}
        {...props}
        ref={ref}
      />
    );
  }
);
PaymentRequestTriageActivity.displayName = 'PaymentRequestTriageActivity';
