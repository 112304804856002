import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { BankAccountFormModel } from '../../bank-details';
import { AddVendorPaypalAccountForm, PaperCheckForm } from './types';

export const useDeliveryMethodCardDescription = () => {
  const { formatMessage } = useMelioIntl();

  const getDescription = (args: {
    values: BankAccountFormModel | PaperCheckForm | AddVendorPaypalAccountForm;
    type: DeliveryMethodType;
  }) => {
    switch (args.type) {
      case DeliveryMethodType.BankAccount:
        return formatMessage('activities.deliveryMethodsWidget.forms.bankAccount.added.description', {
          accountNumber: (args.values as BankAccountFormModel).accountNumber.slice(-4),
        });
      case DeliveryMethodType.PaperCheck:
        return formatMessage('widgets.delivery-methods.check.address', {
          addressLine1: (args.values as PaperCheckForm).line1,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          addressLine2: (args.values as PaperCheckForm).line2 ? ' ' + (args.values as PaperCheckForm).line2! : '',
          addressCity: (args.values as PaperCheckForm).city,
          addressPostalCode: (args.values as PaperCheckForm).postalCode,
          addressState: (args.values as PaperCheckForm).state,
        });
      case DeliveryMethodType.PaypalBalance:
        return (args.values as AddVendorPaypalAccountForm).email;
      default:
        return undefined;
    }
  };

  return {
    getDescription,
  };
};
