import { FormDialog, FormDialogWidgetProps, useMelioIntl } from '@melio/ar-domain';
import { Form, Group, Text, useMelioForm } from '@melio/penny';
import { useAnalytics, withAnalyticsContext, WithAnalyticsContextProps } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { FeeAbsorptionSettings as FeeAbsorptionSettingsFormFields } from '../types';

export type GlobalFeeAbsorptionSettingsModalScreenProps = FormDialogWidgetProps<
  FeeAbsorptionSettingsFormFields,
  {
    isOpen: boolean;
    onClose: VoidFunction;
  }
> &
  WithAnalyticsContextProps;

const useSchema = () => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    feesPaidBy: string().required(
      formatMessage(
        'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.required.label'
      )
    ),
  }) as SchemaOf<FeeAbsorptionSettingsFormFields>;
};

export const GlobalFeeAbsorptionSettingsModalScreen = withAnalyticsContext(
  forwardRef<GlobalFeeAbsorptionSettingsModalScreenProps>(
    ({ isOpen, onClose, onSubmit, defaultValues, isSaving, setAnalyticsProperties, ...props }, ref) => {
      const { formatMessage } = useMelioIntl();
      setAnalyticsProperties({ PageName: 'set-who-pays-card-fees-by-default' });

      const { track } = useAnalytics();

      const handleSubmit = ({ feesPaidBy }: FeeAbsorptionSettingsFormFields) => {
        track('Settings', 'Click', {
          Intent: feesPaidBy === 'payor' ? 'charge-card-fees-to-customers' : 'charge-card-fees-to-business',
          Cta: 'save',
        });
        onSubmit({ feesPaidBy });
      };

      const { registerField, watch, setError, setValue, ...useFormResults } = useMelioForm({
        schema: useSchema(),
        onSubmit: handleSubmit,
        defaultValues,
        isSaving,
      });

      const handleClose = () => {
        track('Settings', 'Click', { Intent: 'exit flow', Cta: 'close' });
        onClose();
      };

      useEffect(() => {
        if (isOpen) track('Settings', 'View', { Intent: 'change-card-fee-assignment' });
      }, [isOpen, track]);

      useEffect(() => {
        if (isOpen && defaultValues?.feesPaidBy) {
          setValue('feesPaidBy', defaultValues.feesPaidBy);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isOpen]);

      return (
        <FormDialog
          data-testid="global-fee-absorption-settings-modal-screen"
          data-component={GlobalFeeAbsorptionSettingsModalScreen.displayName}
          isOpen={isOpen}
          size="large"
          modalSize="medium"
          onClose={handleClose}
          header={formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.title.text'
          )}
          primaryButton={{
            variant: 'primary',
            label: formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.primaryButton.label'
            ),
          }}
          secondaryButton={{
            variant: 'tertiary',
            label: formatMessage(
              'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.secondaryButton.label'
            ),
          }}
          useFormResults={useFormResults}
          {...props}
          ref={ref}
        >
          <Group spacing="m" variant="vertical">
            <Text textStyle="body2">
              {formatMessage(
                'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.description.text'
              )}
            </Text>
            <Form.RadioGroup
              variant="vertical"
              options={[
                {
                  value: 'payor',
                  mainLabelProps: {
                    size: 'small',
                    label: formatMessage(
                      'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.payor.main.label'
                    ),
                  },
                  descriptionProps: {
                    label: formatMessage(
                      'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.payor.description.label'
                    ),
                  },
                },
                {
                  value: 'vendor',
                  mainLabelProps: {
                    size: 'small',
                    label: formatMessage(
                      'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.vendor.main.label'
                    ),
                  },
                  descriptionProps: {
                    label: formatMessage(
                      'ar.settings.activities.acceptedPaymentMethods.globalFeeAbsorptionSettings.modal.feesPaidBy.vendor.description.label'
                    ),
                  },
                },
              ]}
              {...registerField('feesPaidBy')}
            />
          </Group>
        </FormDialog>
      );
    }
  )
);

GlobalFeeAbsorptionSettingsModalScreen.displayName = 'GlobalFeeAbsorptionSettingsModalScreen';
