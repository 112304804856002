import { Button, Container, Divider, Form, Group, IconButton, Text } from '@melio/penny';
import {
  ApiError,
  DeliveryMethodType,
  PaypalBalanceAccountDeliveryMethod,
  useDeliveryMethods,
  useVendor,
} from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { PageTitle, SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { useRef } from 'react';

import {
  EffectedPaymentsDisclaimerModal,
  useEffectedPaymentsDisclaimerModal,
} from '../components/EffectedPaymentsDisclaimerModal';
import { AddVendorPaypalAccountForm, usePaypalBalanceAccountForm } from '../hooks/usePaypalBalanceAccountForm';
import { DeliveryMethodsFailureCodeErrors } from '../types';

type SubmissionTargetType = 'close' | 'continue';
export type AddVendorPaypalAccountActivityProps = {
  vendorId: string;
  onDone: (paypalAccount: PaypalBalanceAccountDeliveryMethod, target: SubmissionTargetType) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
};

export const AddVendorPaypalBalanceAccountActivity = (props: AddVendorPaypalAccountActivityProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const { data: vendor, isLoading: isVendorLoading } = useVendor({ id: props.vendorId });
  const {
    create: createDeliveryMethod,
    isCreating,
    isLoading: isLoadingVendorDeliveryMethods,
  } = useDeliveryMethods({
    vendorId: props.vendorId,
    enabled: false,
  });
  const formValues = useRef<AddVendorPaypalAccountForm>();
  const selectedTarget = useRef<SubmissionTargetType>();
  const effectedPaymentsDisclaimerModal = useEffectedPaymentsDisclaimerModal(props.vendorId);

  const vendorPaypalDm = vendor?.deliveryMethods.find((dm) => dm.type === DeliveryMethodType.PaypalBalance) as
    | PaypalBalanceAccountDeliveryMethod
    | undefined;

  const isEditMode = !!vendorPaypalDm;

  const onSubmit = async (values: AddVendorPaypalAccountForm, _e?: unknown, target?: string) => {
    if (isEditMode && effectedPaymentsDisclaimerModal.showDisclaimer) {
      formValues.current = values;
      selectedTarget.current = target as SubmissionTargetType;
      effectedPaymentsDisclaimerModal.onOpen();
      return;
    }

    return createPaypalDeliveryMethod(values, target as SubmissionTargetType);
  };

  const createPaypalDeliveryMethod = async (values: AddVendorPaypalAccountForm, target: SubmissionTargetType) => {
    try {
      const res = await createDeliveryMethod({
        type: DeliveryMethodType.PaypalBalance,
        details: { paypalAccountEmail: values.email },
      });

      props.onDone(res as PaypalBalanceAccountDeliveryMethod, target);
    } catch (error) {
      const errorCode = (error as ApiError).errorCode;
      const message =
        errorCode === DeliveryMethodsFailureCodeErrors.InvalidPaypalDeliveryAccount
          ? formatMessage('activities.addVendorPaypalBalance.errorPaypalEmailNotFound')
          : formatMessage('activities.addVendorPaypalBalance.defaultErrorTitle');

      showMessage({ type: 'error', title: message });

      props.onError?.(error as Error);
    }
  };

  const handleConfirmEffectivePaymentsModal = () => {
    if (formValues.current) {
      void createPaypalDeliveryMethod(formValues.current, selectedTarget.current as SubmissionTargetType);
      effectedPaymentsDisclaimerModal.onClose();
    }
  };
  const { registerField, submitButtonProps, formProps, handleSubmit } = usePaypalBalanceAccountForm({
    onSubmit,
    defaultValues: {
      email: vendorPaypalDm ? vendorPaypalDm.details.paypalAccountEmail : vendor?.contact.email ?? '',
    },
  });

  const isActivityLoading = isVendorLoading || isLoadingVendorDeliveryMethods;

  const submitAndContinue = handleSubmit('continue');
  const submitAndClose = handleSubmit('close');
  return (
    <>
      <Layout
        isLoading={isActivityLoading}
        maxWidth="600px"
        header={{
          content: (
            <>
              <Container paddingY="s" paddingX="m">
                <Group alignItems="center" justifyContent="space-between">
                  <IconButton
                    icon="chevron-left"
                    size="large"
                    variant="naked"
                    onClick={props.onBack}
                    data-testid="back-button"
                  />
                  <IconButton icon="close" variant="naked" onClick={props.onClose} data-testid="close-button" />
                </Group>
              </Container>
              <Divider />
              <Container paddingTop="xl" maxWidth="600px">
                <Group variant="vertical">
                  <PageTitle textStyle="heading1Semi" data-testid="page-title">
                    {formatMessage('activities.addVendorPaypalBalance.title', { vendorName: vendor?.name })}
                  </PageTitle>
                  <Text textStyle="body2" data-testid="page-subtitle">
                    {formatMessage('activities.addVendorPaypalBalance.subtitle')}
                  </Text>
                  <SystemMessageDisplay data-testid="add-vendor-paypal-balance-account-system-message-display" />
                </Group>
              </Container>
            </>
          ),
        }}
      >
        <Container maxWidth="600px">
          <Group variant="vertical" spacing="l">
            <Form {...formProps} isDisabled={isCreating}>
              <Form.TextField
                {...registerField('email')}
                labelProps={{
                  label: formatMessage('activities.addVendorPaypalBalance.form.emailField.label'),
                }}
              />
            </Form>
            <Group variant="vertical">
              <Button
                {...submitButtonProps}
                isLoading={isCreating}
                onClick={submitAndContinue}
                data-testid="submit-button"
                label={formatMessage('activities.addVendorPaypalBalance.form.submitButton.label')}
              />
              <Button
                isLoading={isCreating}
                data-testid="save-and-close-button"
                label={formatMessage('activities.addVendorPaypalBalance.form.saveAndClose.label')}
                variant="secondary"
                onClick={submitAndClose}
              />
            </Group>
          </Group>
        </Container>
      </Layout>
      <EffectedPaymentsDisclaimerModal
        isOpen={effectedPaymentsDisclaimerModal.isOpen}
        onClose={effectedPaymentsDisclaimerModal.onClose}
        onConfirm={handleConfirmEffectivePaymentsModal}
      />
    </>
  );
};
