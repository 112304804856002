import { Button } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { FullScreenLayout } from '../../components/FullScreenLayout/FullScreenLayout';

const useInvitationSentAnalytics = () => {
  useAnalyticsView('Organization', true, true);

  const { track } = useAnalytics();

  const trackClick = (action: 'done') => track('Organization', 'Click', { Cta: action });

  return { trackClick };
};

export const InvitationSent = withAnalyticsContext<{ onDone: VoidFunction }>(({ setAnalyticsProperties, onDone }) => {
  setAnalyticsProperties({
    PageName: 'invitation-sent',
    Flow: 'invite-existing-client',
    Intent: 'connect-with-existing-client',
  });
  const { formatMessage } = useMelioIntl();
  const { trackClick } = useInvitationSentAnalytics();

  const handleOnDone = () => {
    trackClick('done');
    onDone();
  };

  return (
    <FullScreenLayout
      data-testid="invitation-sent-container"
      title={formatMessage('activities.accountants.invitationSent.header.label')}
      content={formatMessage('activities.accountants.invitationSent.description.text')}
      illustration="sent"
      actions={
        <Button
          isFullWidth={false}
          data-testid="invitation-sent-done-button"
          variant="primary"
          label={formatMessage('activities.accountants.invitationSent.button.label')}
          onClick={handleOnDone}
        />
      }
    />
  );
});
