import { Container, Drawer, Group, LoadingContainer, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAccountingFirmClient } from '../../api/entities/client/useAccountingFirmClient';
import { ClientDrawerError } from './ClientDrawerError';
import { ClientDetails } from './components/ClientDetailsSection/ClientDetails';
import { DrawerFooter } from './components/DrawerFooter/DrawerFooter';
import { PaymentsOverviewSection } from './components/PaymentsOverviewSection/PaymentsOverviewSection';
import { SubscriptionSection } from './components/SubscriptionSection/SubscriptionSection';
import { TodosSection } from './components/TodosSection/TodosSection';
import { useSubscriptionFundingSourceInfo } from './hooks/useSubscriptionFundingSourceInfo';

type Props = {
  onClose: VoidFunction;
  goToSettingsSubscriptionPlansAndRefresh: VoidFunction;
  goToSettingsAndRefresh: VoidFunction;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const ClientDrawerActivity = ({ onClose, ...callbacks }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { clientId } = useParams();
  const {
    data: client,
    isLoading: isAccountingClientLoading,
    isError: isClientError,
  } = useAccountingFirmClient({ id: clientId });
  const [closed, setClosed] = useState<boolean>(false);

  const { isLoading: isSubscriptionFundingSourceLoading } = useSubscriptionFundingSourceInfo({
    clientOrganizationId: client?.organizationInfo.id,
    payingOrganizationId: client?.subscriptionInfo?.subscription?.payingOrganization?.organizationId,
    usedFundingSourceId: client?.subscriptionInfo?.subscription?.fundingSourceId || undefined,
  });

  const handleClose = () => {
    setClosed(true);
    onClose();
  };

  return (
    <Drawer
      isOpen={!closed}
      data-testid="firm-dashboard-client-drawer"
      header={
        <Text as="h2" textStyle="heading2Semi">
          {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.title')}
        </Text>
      }
      body={
        <LoadingContainer
          aria-live="polite"
          isLoading={isAccountingClientLoading || isSubscriptionFundingSourceLoading}
        >
          {!isAccountingClientLoading && client ? (
            <Group variant="vertical" spacing="m">
              <Container overflow="initial">
                <ClientDetails client={client} />
                <TodosSection client={client} {...callbacks} />
                <PaymentsOverviewSection client={client} onSwitchOrganization={callbacks.onSwitchOrganization} />
                <SubscriptionSection client={client} {...callbacks} />
              </Container>
            </Group>
          ) : isClientError ? (
            <ClientDrawerError />
          ) : undefined}
        </LoadingContainer>
      }
      footer={
        <DrawerFooter client={client} isFooterDisabled={isAccountingClientLoading || isClientError} {...callbacks} />
      }
      onClose={handleClose}
    />
  );
};
