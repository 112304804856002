import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

export const useTodosActions = () => {
  const { formatMessage } = useMelioIntl();

  const createViewBillsAction = useCallback(
    (totalItems: number, onClick: (e: React.MouseEvent) => void) => ({
      label: formatMessage('widgets.todosDrawer.section.action.viewBills', { count: totalItems }),
      onClick,
    }),
    [formatMessage]
  );

  const createViewPaymentAction = useCallback(
    (totalItems: number, onClick: () => void) => ({
      label: formatMessage('widgets.todosDrawer.section.action.viewPayments', { count: totalItems }),
      onClick,
    }),
    [formatMessage]
  );

  return { createViewBillsAction, createViewPaymentAction };
};
