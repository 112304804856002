import { _createFormFieldInput, FormTextFieldProps, TextField } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

const DateFormat = {
  'MM/dd/yyyy': {
    mask: [/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[1-2]/, /(9|0)/, /\d/, /\d/],
    label: 'MM/DD/YYYY',
  },
  'yyyy-MM-dd': {
    mask: [/[1-2]/, /(9|0)/, /\d/, /\d/, '-', /[0-1]/, /[0-9]/, '-', /[0-3]/, /[0-9]/],
    label: 'YYYY-MM-DD',
  },
};
type DateFormat = keyof typeof DateFormat;

export type DateStringInputProps = Omit<FormTextFieldProps, 'maskProps'> & {
  dateFormat?: DateFormat;
};

export const DateStringInput = _createFormFieldInput(
  forwardRef<DateStringInputProps, 'input'>(({ dateFormat = 'MM/dd/yyyy', placeholder, ...props }, ref) => (
    <TextField
      data-component={DateStringInput.displayName}
      placeholder={placeholder ?? DateFormat[dateFormat].label}
      maskProps={{ mask: DateFormat[dateFormat].mask }}
      {...props}
      ref={ref}
    />
  ))
);
DateStringInput.displayName = 'DateStringInput';
