import { format, parse } from 'date-fns';

export const createDateFormatter = <T extends string, Y extends string>(date1: T, date2: Y) => {
  type Option<K extends T | Y> = {
    src: K extends T ? Y : T;
    target: K extends T ? T : Y;
  };

  type Formatters = { [K in T | Y]: Option<K> };

  const formatters = {
    [date2]: { src: date1, target: date2 },
    [date1]: { src: date2, target: date1 },
  } as Formatters;

  return (date: Date | string, { target }: { target: T | Y }) => {
    const formatter = formatters[target];
    const src = typeof date === 'string' ? parse(date, formatter.src, Date.now()) : date;
    return format(src, formatter.target);
  };
};
