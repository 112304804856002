import { SectionBanner, Typography } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

type SubscriptionDowngradeWarningProps = {
  messages: string[];
};
const SubscriptionDowngradeWarningComponent: React.FC<SubscriptionDowngradeWarningProps> = ({ messages }) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      title={formatMessage(`activities.subscription.cancelOrDowngradeModal.warning.title`)}
      description={
        messages.length === 1 ? (
          messages[0]
        ) : (
          <Typography.ParagraphList
            list={messages.map((message) => ({
              content: message,
            }))}
            size="small"
            type="unordered"
            data-testid="typography-paragraph-list"
          />
        )
      }
      variant="warning"
      data-testid="subscription-cancel-or-downgrade-approval-modal-screen-warning-banner"
    />
  );
};

export const SubscriptionDowngradeWarning = React.memo(SubscriptionDowngradeWarningComponent);
