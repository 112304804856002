import { Container, Group, useBreakpoint } from '@melio/penny';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';

import { Props } from '../types';
import { DashboardCard } from './components/DashboardCard/DashboardCard';
import { DashboardHeader } from './components/DashboardHeader';
import { FirmClients } from './components/FirmClients';
import { Overview } from './components/Overview';
import { ProTipsCarousel } from './components/ProTipsCarousel/ProTipsCarousel';
import { useFirstTimeVisiting } from './hooks';

export const FirmDashboardActivityV2 = withAnalyticsContext<Props>(
  ({ goToSettingsAndRefresh, goToSettingsSubscriptionPlansAndRefresh, onSwitchOrganization }) => {
    const { isExtraSmallScreen, isLargeScreen, isExtraLargeScreen } = useBreakpoint();
    const { isFirstTime } = useFirstTimeVisiting();
    const { data: firmAccount } = useAccount({ id: 'me' });

    if (isExtraSmallScreen) {
      return (
        <Container height="full" overflow="initial" data-testid="accountants-dashboard-v2">
          <Group variant="vertical" spacing="m">
            <DashboardHeader isFirstTime={isFirstTime} userDisplayName={firmAccount?.user.firstName} />
            <Container paddingTop="s" paddingX="s" overflow="initial">
              <Group spacing="m" width="auto" variant={isLargeScreen || isExtraLargeScreen ? 'horizontal' : 'vertical'}>
                <DashboardCard>
                  <Overview />
                </DashboardCard>
              </Group>
            </Container>
            <Container overflow="initial">
              <FirmClients
                goToSettingsAndRefresh={goToSettingsAndRefresh}
                goToSettingsSubscriptionPlansAndRefresh={goToSettingsSubscriptionPlansAndRefresh}
                onSwitchOrganization={onSwitchOrganization}
              />
            </Container>
            <Container paddingY="s" paddingX="s" overflow="initial">
              <DashboardCard>
                <ProTipsCarousel />
              </DashboardCard>
            </Container>
          </Group>
        </Container>
      );
    }

    return (
      <Container height="full" overflow="initial" maxWidth="1600px" data-testid="accountants-dashboard-v2">
        <Group variant="vertical" spacing="m">
          <DashboardHeader isFirstTime={isFirstTime} userDisplayName={firmAccount?.user.firstName} />
          <Container overflow="initial">
            <Group spacing="m" width="auto" variant={isLargeScreen || isExtraLargeScreen ? 'horizontal' : 'vertical'}>
              <Group.Item basis="66%">
                <DashboardCard>
                  <Overview />
                </DashboardCard>
              </Group.Item>
              <Group.Item basis="33%">
                <DashboardCard>
                  <ProTipsCarousel />
                </DashboardCard>
              </Group.Item>
            </Group>
          </Container>
          <Container paddingBottom="s" overflow="initial" paddingTop="xs">
            <FirmClients
              goToSettingsAndRefresh={goToSettingsAndRefresh}
              goToSettingsSubscriptionPlansAndRefresh={goToSettingsSubscriptionPlansAndRefresh}
              onSwitchOrganization={onSwitchOrganization}
            />
          </Container>
        </Group>
      </Container>
    );
  }
);
