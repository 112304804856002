import { useNonEntityMutation } from '@melio/api-client';
import { PayorApiClient } from '@melio/ar-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { FundingSourcesApiClient, ServicesApiClient } from '@melio/platform-api-axios-client';

import { useCollection, UseCollectionProps } from '../../api-client';

export type UseFundingSourcesProps = UseCollectionProps<
  typeof FundingSourcesApiClient.getFundingSources,
  typeof PayorApiClient.postPayorFundingSource,
  typeof FundingSourcesApiClient.patchFundingSourcesFundingSourceId
>;

export const useGuestPayorFundingSources = ({ params, ...props }: UseFundingSourcesProps = {}) => {
  const query = useCollection({
    ...props,
    queryKey: ['FundingSourcesApi', params],
    queryFn: FundingSourcesApiClient.getFundingSources,
    createFn: PayorApiClient.postPayorFundingSource,
    deleteFn: FundingSourcesApiClient.deleteFundingSourcesFundingSourceId,
    updateFn: FundingSourcesApiClient.patchFundingSourcesFundingSourceId,
  });

  const verifyCardMutation = useNonEntityMutation(ServicesApiClient.postServicesVerifyCard, query.queryKey);

  return {
    ...query,
    verifyCard: verifyCardMutation.mutateAsync,
  };
};
