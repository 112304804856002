import { addWildcardToRoutes, useNavigate, withLocalization, withOutlet } from '@melio/ar-domain';
import { withAnalyticsContext, WithAnalyticsContextProps } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { AcceptedPaymentMethodsActivity, EditInvoicePaymentOptionsDialogActivity } from '../../activities';

type AcceptedPaymentMethodsFlowProps = WithAnalyticsContextProps;

const Flow = forwardRef(({ setAnalyticsProperties, ...props }: AcceptedPaymentMethodsFlowProps, ref) => {
  const { Paths, goToEditInvoiceCustomPaymentDetails, goToHome } = useNavigation();
  setAnalyticsProperties({ ProductName: 'ar', Flow: 'manage-receiving-method' });

  return (
    <Routes>
      <Route
        path={Paths.Home}
        element={withOutlet(
          <AcceptedPaymentMethodsActivity
            onEditCustomPaymentDetails={goToEditInvoiceCustomPaymentDetails}
            ref={ref}
            {...props}
          />
        )}
      >
        <Route
          path={Paths.EditInvoiceCustomPaymentDetails}
          element={<EditInvoicePaymentOptionsDialogActivity onDone={goToHome} onClose={goToHome} />}
        />
      </Route>
    </Routes>
  );
});

export const AcceptedPaymentMethodsFlow = withLocalization(withAnalyticsContext(Flow));

const useNavigation = () => {
  enum Paths {
    Home = '',
    EditInvoiceCustomPaymentDetails = 'edit-custom-payment-details',
  }

  const navigate = useNavigate<Paths>();

  return {
    Paths: addWildcardToRoutes(Paths),
    goToHome: () => navigate(Paths.Home),
    goToEditInvoiceCustomPaymentDetails: () => navigate(Paths.EditInvoiceCustomPaymentDetails),
  };
};
