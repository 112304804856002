import { countries } from '@melio/platform-api';

export const enum InternationalAddressFieldNamesEnum {
  Line1 = 'line1',
  Line2 = 'line2',
  City = 'city',
  State = 'state',
  PostalCode = 'postalCode',
  CountryCode = 'countryCode',
}

export const allowedCountry = countries.map(({ code }) => code);
