import { getInternationalFormattedIdentifierData } from '@melio/ap-domain';
import { BrandSymbol, BrandSymbolKey, FlagIcon, FlagKey, Icon } from '@melio/penny';
import {
  BankAccountDeliveryMethod,
  BillerAccountDeliveryMethod,
  DebitDeliveryMethod,
  DeliveryMethodType,
  DeliveryPreferenceType,
  DomesticWireDeliveryMethod,
  formatAddress,
  InternationalAccountDeliveryMethod,
  ManagedAccountDeliveryMethod,
  PaperCheckDeliveryMethod,
  PaypalBalanceAccountDeliveryMethod,
  Vendor,
  VirtualCardAccountDeliveryMethod,
} from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';

type DeliveryMethod =
  | BankAccountDeliveryMethod
  | DomesticWireDeliveryMethod
  | PaperCheckDeliveryMethod
  | DebitDeliveryMethod
  | InternationalAccountDeliveryMethod
  | VirtualCardAccountDeliveryMethod
  | BillerAccountDeliveryMethod
  | ManagedAccountDeliveryMethod
  | PaypalBalanceAccountDeliveryMethod;

export const getDeliveryMethodLabelMessageKey = ({
  deliveryMethod,
  deliveryPreferenceType,
}: {
  deliveryMethod: DeliveryMethod;
  deliveryPreferenceType?: DeliveryPreferenceType;
}): MessageKey | undefined => {
  switch (deliveryMethod.type) {
    case 'bank-account': {
      if (deliveryPreferenceType === DeliveryPreferenceType.Rtp) {
        return 'widgets.deliveryMethodListItemByPayor.bankAccount.rtp.label';
      }

      return 'widgets.deliveryMethodListItemByPayor.bankAccount.label';
    }

    case 'domestic-wire-account':
      return 'widgets.deliveryMethodListItemByPayor.domesticWireAccount.label';

    case 'paper-check':
      return 'widgets.deliveryMethodListItemByPayor.paperCheck.label';

    case 'card':
      return 'widgets.deliveryMethodListItemByPayor.card.label';

    case 'virtual-card':
      return isStpVirtualCard(deliveryMethod)
        ? 'widgets.deliveryMethodListItemByPayor.virtualCard.stp.label'
        : 'widgets.deliveryMethodListItemByPayor.virtualCard.label';

    case 'biller-account':
    case 'managed-account':
      return 'widgets.deliveryMethodListItemByPayor.billerAccount.label';

    case 'international-account':
      return 'widgets.deliveryMethodListItemByPayor.internationalAccount.label';

    case 'paypal-balance':
      //TODO Guygo - Add paypal label
      return 'widgets.deliveryMethodListItemByPayor.paypal-balance.label';
    default:
      return;
  }
};

const isStpVirtualCard = (deliveryMethod: DeliveryMethod) => {
  const isVirtualCard = deliveryMethod.type === DeliveryMethodType.VirtualCard;
  return isVirtualCard ? deliveryMethod.details?.isStp ?? false : false;
};

export const getMainLabelProperties = ({
  deliveryMethod,
  vendorName,
}: {
  deliveryMethod: DeliveryMethod;
  vendorName?: Vendor['name'];
}): { messageKey: MessageKey; values?: Record<string, unknown> } | undefined => {
  switch (deliveryMethod.type) {
    case 'bank-account':
      return deliveryMethod.isManaged
        ? {
            messageKey: 'widgets.deliveryMethodListItemByPayor.bankAccount.managedDescription',
          }
        : {
            messageKey: 'widgets.deliveryMethodListItemByPayor.bankAccount.description',
            values: { name: vendorName, account: deliveryMethod.details.accountNumberLast4Digits },
          };

    case 'domestic-wire-account':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.domesticWireAccount.description',
        values: { name: vendorName, account: deliveryMethod.details.accountNumber.slice(-4) },
      };

    case 'paper-check':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.paperCheck.description',
        values: { address: formatAddress(deliveryMethod.details.address) },
      };

    case 'card':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.card.description',
        values: {
          vendorName,
        },
      };
    case 'virtual-card': {
      return isStpVirtualCard(deliveryMethod)
        ? { messageKey: 'widgets.deliveryMethodListItemByPayor.virtualCard.stp.description' }
        : {
            messageKey: 'widgets.deliveryMethodListItemByPayor.virtualCard.description',
            values: {
              emailAddress: deliveryMethod.details.accountEmail,
            },
          };
    }
    case 'biller-account':
    case 'managed-account':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.billerAccount.description',
      };

    case 'international-account':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.internationalAccount.description',
        values: getInternationalFormattedIdentifierData(deliveryMethod),
      };
    case 'paypal-balance':
      return {
        //TODO Guygo - Add paypal description
        messageKey: 'widgets.deliveryMethodListItemByPayor.paypal-balance.description',
      };
  }
};

export const getDeliveryMethodHelperText = ({
  deliveryMethod,
  virtualCardExpiryDateFormatted,
  countryDisplayName,
}: {
  deliveryMethod: DeliveryMethod;
  virtualCardExpiryDateFormatted: string | undefined;
  countryDisplayName?: string;
}): { messageKey: MessageKey; values?: Record<string, unknown> } | undefined => {
  switch (deliveryMethod.type) {
    case 'card':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.card.helperText',
      };
    case 'bank-account':
      return deliveryMethod.isManaged
        ? {
            messageKey: 'widgets.deliveryMethodListItemByPayor.bankAccount.helperText',
          }
        : undefined;
    case 'virtual-card':
      return isStpVirtualCard(deliveryMethod)
        ? { messageKey: 'widgets.deliveryMethodListItemByPayor.virtualCard.stp.helperText' }
        : {
            messageKey: 'widgets.deliveryMethodListItemByPayor.virtualCard.helperText',
            values: { expiryDate: virtualCardExpiryDateFormatted },
          };
    case 'international-account':
      return {
        messageKey: 'widgets.deliveryMethodListItemByPayor.internationalAccount.helperText',
        values: {
          country: countryDisplayName || deliveryMethod.details.identifierDetails.bankCountryCode,
          flag: (
            <FlagIcon countryCode={deliveryMethod.details.identifierDetails.bankCountryCode as FlagKey} size="small" />
          ),
        },
      };

    default:
      return;
  }
};

export const getDeliveryMethodIcon = (
  deliveryMethodType: DeliveryMethod['type'],
  icons: { [key: string]: BrandSymbolKey }
): JSX.Element => {
  switch (deliveryMethodType) {
    case 'bank-account':
      return <Icon type="bank" data-testid="bank-icon" />;
    case 'domestic-wire-account':
      return <Icon type="funds" data-testid="funds-icon" />;
    case 'paper-check':
      return <Icon type="paper-check" data-testid="paper-check-icon" />;
    case 'card':
      return <Icon type="debit-card" data-testid="debit-card-icon" />;
    case 'international-account':
      return <Icon type="international" data-testid="international-icon" />;
    case 'virtual-card':
      return (
        <BrandSymbol
          type={icons['virtualCard'] as BrandSymbolKey}
          data-testid={`${icons['virtualCard'] as BrandSymbolKey}-brand-symbol`}
        />
      );
    case 'biller-account':
    case 'managed-account':
      return <Icon type="directory-payment" data-testid="directory-payment-icon" />;
    case 'paypal-balance':
      //TODO Guygo - Add paypal icon
      return <Icon type="pay" data-testid="paypal-icon" />;
  }
};

export const getTooltipMessageKey = (deliveryMethod: DeliveryMethod) => {
  switch (deliveryMethod.type) {
    case 'virtual-card':
      return deliveryMethod.isFilledByVendor
        ? 'widgets.deliveryMethodListItemByPayor.virtualCard.tooltipText'
        : undefined;
    case 'bank-account':
      return deliveryMethod.isManaged ? 'widgets.deliveryMethodListItemByPayor.bankAccount.tooltipText' : undefined;
    default:
      return;
  }
};

export const getCanEditDeliveryMethod = (deliveryMethod: DeliveryMethod) => !deliveryMethod.isManaged;
