import { SharedEmptyState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

export type EmptyStateProps = {
  search?: string;
  onClearSearch?: VoidFunction;
};

export const CustomersEmptyState = forwardRef<EmptyStateProps>(({ search, onClearSearch, ...props }, ref) => (
  <SharedEmptyState
    data-testid="customers-dashboard-no-search-result"
    ref={ref}
    search={search}
    searchMessage="ar.dashboard.activities.customersTable.emptyState.search.text"
    defaultMessage="ar.dashboard.activities.customersTable.emptyState.filters.text"
    actions={
      onClearSearch
        ? [
            {
              label: 'ar.dashboard.activities.customersTable.filters.advanced.clear.label',
              onClick: onClearSearch,
              variant: 'secondary',
              testId: 'clean-search-button',
            },
          ]
        : []
    }
    {...props}
  />
));

CustomersEmptyState.displayName = 'CustomersEmptyState';
