import { FormattedMessage, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, Text } from '@melio/penny';
import React from 'react';

type EditCustomPaymentDetailsProps = {
  customPaymentDetails: string;
  onEditCustomPaymentDetails: VoidFunction;
  onRemoveCustomPaymentDetails: VoidFunction;
};

export const EditCustomPaymentDetails = ({
  customPaymentDetails,
  onEditCustomPaymentDetails,
  onRemoveCustomPaymentDetails,
}: EditCustomPaymentDetailsProps) => {
  const { formatMessage } = useMelioIntl();
  const isMobile = useIsMobile();

  return (
    <Group variant="vertical" spacing="l">
      <Group variant="vertical" spacing="none">
        <Text textStyle="body4Semi" color="global.neutral.800">
          <FormattedMessage id="ar.settings.activities.acceptedPaymentMethods.customPaymentDetails.editMode.title.text" />
        </Text>
        <Text data-testid="invoice-custom-payment-details-text-value" textStyle="body2">
          {customPaymentDetails}
        </Text>
      </Group>
      <Group variant={isMobile ? 'vertical' : 'horizontal'}>
        <Button
          data-testid="remove-invoice-custom-payment-details-button"
          onClick={onRemoveCustomPaymentDetails}
          variant="critical-secondary"
          label={formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.customPaymentDetails.editMode.buttons.remove.text'
          )}
        />
        <Button
          data-testid="edit-invoice-custom-payment-details-button"
          onClick={onEditCustomPaymentDetails}
          variant="secondary"
          label={formatMessage(
            'ar.settings.activities.acceptedPaymentMethods.customPaymentDetails.editMode.buttons.edit.text'
          )}
        />
      </Group>
    </Group>
  );
};
