import {
  Badge,
  Button,
  Container,
  Divider,
  Group,
  IconButton,
  Menu,
  RadioGroup,
  StatusIndicator,
  Text,
} from '@melio/penny';
import { FC, useState } from 'react';

import { useMelioIntl } from '../i18n';

type FilterOption<T> = { id: T; label: string };

type FilterProps<T> = {
  options: FilterOption<T>[];
  activeFilter: T;
  defaultFilter: T;
  title: string;
  onChange: (id: T) => void;
};

export const MobileFilter = <T extends string>({
  options,
  activeFilter,
  defaultFilter,
  title,
  onChange,
}: FilterProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<T>(activeFilter);

  const onApply = () => {
    onChange(filter);
    handleOpenMenuChange(false);
  };

  const onClear = () => {
    onChange(defaultFilter);
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  const filterOptions = options.map(({ id, label }) => ({
    label: <FilterLabel defaultFilter={defaultFilter} id={id} label={label} />,
    value: id,
    ariaLabel: label,
  }));

  return (
    <Menu
      data-testid="filter-menu"
      data-component={MobileFilter.displayName}
      trigger={
        <Badge
          mark={activeFilter === defaultFilter ? undefined : <StatusIndicator status="informative" />}
          placement="top-right"
        >
          <IconButton
            icon="filter"
            size="large"
            aria-label={formatMessage('ar.domain.components.mobileFilter.aria-label')}
            data-testid="mobile-filters-button"
          />
        </Badge>
      }
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      title={title}
      size="fit-content"
    >
      <RadioGroup
        value={filter}
        variant="vertical"
        onChange={(e) => setFilter(e.target.value as T)}
        options={filterOptions}
        gap="none"
        paddingX="m"
      />
      <Container>
        <Divider />
        <Container data-testid="filter-footer-menu" paddingX="s" paddingY="s">
          <Group justifyContent="space-between" alignItems="center">
            <Button
              label={formatMessage('ar.domain.components.mobileFilter.footer.clear.label')}
              variant="secondary"
              onClick={onClear}
              data-testid="mobile-filter-clear"
              isDisabled={filter === defaultFilter}
            />
            <Button
              label={formatMessage('ar.domain.components.mobileFilter.footer.apply.label')}
              onClick={onApply}
              data-testid="mobile-filter-apply"
            />
          </Group>
        </Container>
      </Container>
    </Menu>
  );
};

type FilterLabelProps = FilterOption<string> & { defaultFilter: string };

const FilterLabel: FC<FilterLabelProps> = ({ id, label, defaultFilter }) => {
  const { formatMessage } = useMelioIntl();
  return (
    <Container data-testid={`filter-item-${id}`} key={`${id}`} paddingY="s" paddingLeft="xs" width="fit-content">
      <Group spacing="xs">
        <Text textStyle="body3Semi" color="global.neutral.1000">
          {label}
        </Text>
        {id === defaultFilter && (
          <Text textStyle="body3Semi" color="global.neutral.800">
            {formatMessage('ar.domain.components.mobileFilter.footer.default.label')}
          </Text>
        )}
      </Group>
    </Container>
  );
};

MobileFilter.displayName = 'MobileFilter';
