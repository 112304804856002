import {
  Button,
  Container,
  Group,
  GroupProps,
  Illustration,
  IllustrationProps,
  useBreakpoint,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';

type Props = {
  companyName: string;
  isAddedOnBehalf: boolean;
  onDone: () => void;
};

export const CompanyAddedScreen = withAnalyticsContext<Props>(
  ({ companyName, isAddedOnBehalf, onDone, setAnalyticsProperties, ...props }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'company-added',
      CompanyOwner: !isAddedOnBehalf,
    });

    useEffect(() => {
      track('User', 'View');
    }, [track]);

    const illustrationBottomGap = useBreakpointValue<GroupProps['spacing']>({ xs: 's', s: 's', m: 'm' });
    const illustrationSize = useBreakpointValue<IllustrationProps['size']>({ xs: 'small', s: 'small', m: 'large' });
    const { isExtraSmallScreen } = useBreakpoint();

    const title = isAddedOnBehalf
      ? 'activities.addCompany.screens.companyAdded.onBehalf.title'
      : 'activities.addCompany.screens.companyAdded.title';
    const description = isAddedOnBehalf
      ? 'activities.addCompany.screens.companyAdded.onBehalf.description'
      : 'activities.addCompany.screens.companyAdded.description';

    const handleDone = () => {
      track('User', 'Click', {
        Cta: 'back-to-pay',
      });
      onDone();
    };

    const nextButton = (
      <Container justifyContent="center">
        <Button
          variant="primary"
          isFullWidth={isExtraSmallScreen}
          size="large"
          data-testid="layout-next-button"
          label={formatMessage('activities.addCompany.screens.companyAdded.backToPay')}
          onClick={handleDone}
        />
      </Container>
    );

    return (
      <Layout
        maxWidth="600px"
        data-component="AddCompanyActivity.CompanyAddedScreen"
        data-testid="add-company-activity-company-added-screen"
        footer={
          isExtraSmallScreen
            ? {
                isSticky: true,
                content: nextButton,
              }
            : undefined
        }
        {...props}
      >
        <Container paddingTop="xxl">
          <Group variant="vertical" spacing={illustrationBottomGap} alignItems="center">
            <Illustration type="small-business" size={illustrationSize} />
            <Group variant="vertical">
              <NewSinglePaymentStepLayout.Title textAlign="center">
                {formatMessage(title)}
              </NewSinglePaymentStepLayout.Title>
              <NewSinglePaymentStepLayout.Description data-testid="layout-description" textAlign="center">
                {formatMessage(description, {
                  companyName,
                })}
              </NewSinglePaymentStepLayout.Description>
              {!isExtraSmallScreen && <Container paddingTop="m">{nextButton}</Container>}
            </Group>
          </Group>
        </Container>
      </Layout>
    );
  }
);

CompanyAddedScreen.displayName = 'AddCompanyActivity.CompanyAddedScreen';
