import { AccountAddress, InternationalAddress } from '@melio/platform-api';

export const getFormattedVendorAddress = (
  address?: InternationalAddress | AccountAddress | null
): string | undefined => {
  if (!address) {
    return undefined;
  }

  const addressParts = [];

  if (address.line1) {
    addressParts.push(address.line1);
  }

  if (address.city) {
    addressParts.push(address.city);
  }

  if (address.state) {
    addressParts.push(address.state);
  }

  if (address.postalCode) {
    addressParts.push(address.postalCode);
  }

  if ('countryCode' in address && address.countryCode) {
    addressParts.push(address.countryCode);
  }

  return addressParts.length > 0 ? addressParts.join(', ') : undefined;
};

/**
 * Formats a phone number according to standard international format.
 *
 * For US numbers:
 * - 10 digits: (XXX) XXX-XXXX
 * - 11 digits starting with 1: +1 (XXX) XXX-XXXX
 *
 * For international numbers:
 * - If already has country code (starts with +): keep as is
 * - If already formatted (contains spaces, parentheses, or dashes): keep as is
 * - If has country code without +: add +
 * - If no country code: assume US number and format accordingly
 *
 * @param phoneNumber - The phone number to format
 * @returns Formatted phone number string or undefined if input is empty
 */
export const formatPhoneNumber = (phoneNumber?: string | null): string | undefined => {
  if (!phoneNumber) {
    return undefined;
  }

  // If already in international format (starts with +), return as is
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }

  // If already formatted (contains spaces, parentheses, or dashes), return as is
  if (/[\s()-]/.test(phoneNumber)) {
    return phoneNumber;
  }

  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');

  // Handle US numbers (10 digits)
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // Handle US numbers with country code (11 digits starting with 1)
  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  }

  // For other numbers:
  // - If it's a reasonable length (8-15 digits), assume it's an international number
  // - If it's too short or too long, return the original input
  if (cleaned.length >= 8 && cleaned.length <= 15) {
    return `+${cleaned}`;
  }

  // If the number doesn't match any expected format, return the original input
  return phoneNumber;
};
