import { useVendors } from '@melio/platform-api';
import { isEmpty } from 'lodash';
import { useState } from 'react';

type PartialFormData = Record<string, unknown>;

type Props = {
  vendorId?: string;
  isVendorUniqueNameEnabled: boolean;
};

export const useVendorUniqueNameUpdate = ({ vendorId, isVendorUniqueNameEnabled }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<string>('');
  const [mode, setMode] = useState<'edit' | 'confirm'>('confirm');
  const { data: vendors, isLoading } = useVendors({ enabled: isVendorUniqueNameEnabled });

  const shouldShowModal = (formData: PartialFormData) => {
    const companyName = formData?.['companyName'] as string;
    const companyNameToVerify = companyName.trim().toLowerCase();
    const accountNumber = (formData?.['viewAccountNumber'] || formData?.['accountNumber']) as string;
    const isUniqueNameExists = !isEmpty(formData?.['uniqueName']);
    const vendorToUpdate = vendors?.find((vendor) => vendor.id === vendorId);
    const isInitiallySyncedVendor = vendorToUpdate ? vendorToUpdate.name === vendorToUpdate.uniqueName : false;
    const isUnchangedInitiallySyncedVendor = isInitiallySyncedVendor
      ? vendorToUpdate
        ? vendorToUpdate.name === companyName
        : false
      : false;
    const isCompanyNameUnique =
      !vendors?.length || !vendors.some((vendor) => vendor.name.trim().toLowerCase() === companyNameToVerify);
    const isCompanyNameUniqueExtended =
      !vendors?.length ||
      !vendors.some(
        (vendor) =>
          (vendor.name.trim().toLowerCase() === companyNameToVerify ||
            vendor.uniqueName?.trim().toLowerCase() === companyNameToVerify) &&
          vendor.id !== vendorId
      );
    const shouldShowModalInConfirmMode = isVendorUniqueNameEnabled && isUniqueNameExists && isCompanyNameUnique;
    const shouldShowModalInEditMode =
      isVendorUniqueNameEnabled && !isUnchangedInitiallySyncedVendor && !isCompanyNameUniqueExtended;

    if (shouldShowModalInEditMode) {
      setMode('edit');
      setValue(`${companyName.slice(0, 25)} (${accountNumber.toString().slice(-4)})`);
      return true;
    }

    if (shouldShowModalInConfirmMode) {
      setMode('confirm');
      setValue(companyName);
      return true;
    }

    return false;
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return {
    mode,
    value,
    isOpen,
    isLoading,
    onOpen: () => setIsOpen(true),
    onClose,
    shouldShowModal,
  };
};
