/* eslint-disable react-hooks/exhaustive-deps */
import { FundingSource, Vendor } from '@melio/platform-api';
import { useEffect } from 'react';

import { useGoodsReceived } from './useGoodsReceived';
import { useReconciliationModal } from './useReconciliationModal';
import { useVendorDetailsModal } from './useVendorDetailsModal.hook';

type UseFundingSourceSelectionModalsProps = {
  fundingSources?: FundingSource[];
  selectedFundingSourceId?: string;
  vendor?: Vendor;
  paymentAmount: number;
  onDone: (paymentAmount: number, fundingSourceId: string) => void;
  onCloseModal: VoidFunction;
  onOpenReconciliationFlow: () => void;
};

const useFundingSourceSelectionModals = ({
  fundingSources,
  vendor,
  onDone,
  paymentAmount,
  selectedFundingSourceId,
  onCloseModal,
  onOpenReconciliationFlow,
}: UseFundingSourceSelectionModalsProps) => {
  const {
    disableGoodsReceivedFundingSourcesTypes,
    goodsReceivedAmountThreshold,
    goodsReceived,
    shouldOpenGoodsReceivedModal,
    runGoodsReceivedFlow,
    shouldRunGoodsReceived,
    handleCloseGoodsReceived,
    handleDoneGoodsReceived,
  } = useGoodsReceived();

  const {
    shouldRunReconciliation,
    runReconciliationFlow,
    shouldShowReconciliationModal,
    onDoneReconciliationFlow,
    onCloseReconciliationFlow,
  } = useReconciliationModal();

  const {
    vendorDetailsModalState,
    onOpenVendorDetailsModal: runVendorMissingDetailsFlow,
    onCloseVendorDetailsModal,
    isVendorHasMissingDetails,
  } = useVendorDetailsModal();

  useEffect(() => {
    if (!selectedFundingSourceId) {
      return;
    }

    const fundingSource = fundingSources?.find((fs) => fs.id === selectedFundingSourceId);
    if (!fundingSource) {
      return;
    }
    const shouldRunGoodsReceivedFlow = shouldRunGoodsReceived(fundingSource, paymentAmount);
    const selectedFundingSource = fundingSources?.find((fundingSource) => fundingSource.id === selectedFundingSourceId);

    const shouldRunReconciliationFlow = shouldRunReconciliation(fundingSource);
    const isFundingSourceDisable = disableGoodsReceivedFundingSourcesTypes.includes(fundingSource.type);

    if (isFundingSourceDisable) {
      return;
    }

    const isVendorMissingDetails = selectedFundingSource
      ? isVendorHasMissingDetails({
          vendor,
          selectedFundingSource,
        })
      : false;

    if (shouldRunGoodsReceivedFlow) {
      runGoodsReceivedFlow();
    } else if (isVendorMissingDetails) {
      if (!vendorDetailsModalState.isOpen) {
        runVendorMissingDetailsFlow(vendor, selectedFundingSource);
      }

      return;
    } else if (shouldRunReconciliationFlow) {
      onOpenReconciliationFlow();
      runReconciliationFlow();
    } else {
      onDone(paymentAmount, selectedFundingSourceId);
    }
  }, [
    fundingSources,
    vendor,
    selectedFundingSourceId,
    shouldRunGoodsReceived,
    vendorDetailsModalState,
    shouldRunReconciliation,
    disableGoodsReceivedFundingSourcesTypes,
  ]);

  return {
    disableGoodsReceivedFundingSourcesTypes,
    goodsReceivedAmountThreshold,
    goodsReceived,
    shouldOpenGoodsReceivedModal,
    shouldShowReconciliationModal,
    onDoneReconciliationFlow,
    onCloseReconciliationFlow: onCloseReconciliationFlow(onCloseModal),
    handleCloseGoodsReceived: handleCloseGoodsReceived(onCloseModal),
    handleDoneGoodsReceived,
    vendorDetailsModalState,
    onCloseVendorDetailsModal,
  };
};

export { useFundingSourceSelectionModals };
