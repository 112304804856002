import { useMelioIntl } from '@melio/ar-domain';

import { ShareMode } from '../../../types';
import { SendEmailOptions, SubmitTarget } from '../types';

export const useSuccessMessage = () => {
  const { formatMessage } = useMelioIntl();

  const getSuccessMessage = ({
    target,
    shouldSendEmail,
    invoiceNumber,
    shareMode,
  }: {
    target?: SubmitTarget;
    shouldSendEmail?: SendEmailOptions;
    invoiceNumber?: string;
    shareMode: ShareMode;
  }) => {
    let successMessage = '';

    if (target === 'primary') {
      if (shouldSendEmail === 'yes') {
        const action = shareMode === 'updateInvoice' ? 'updatedAndSent' : 'sent';
        successMessage = formatMessage(`ar.invoiceLifecycle.activities.issueInvoice.toasts.${action}.success.label`, {
          invoiceNumber,
        });
      } else {
        successMessage =
          shareMode === 'createInvoice'
            ? formatMessage('ar.invoiceLifecycle.activities.issueInvoice.toasts.created.success.label', {
                invoiceNumber,
              })
            : formatMessage('ar.invoiceLifecycle.activities.issueInvoice.toasts.updated.success.label', {
                invoiceNumber,
              });
      }
    }

    return successMessage;
  };

  return getSuccessMessage;
};
