import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

const ACCOUNTANT_DASHBOARD_FIRST_TIME_VISITED = 'accountants-dashboard-visited';

export const useFirstTimeVisiting = () => {
  const { create } = useUserPreferences({ enabled: false });
  const { data, isLoading, isFetched } = useUserPreference({
    id: ACCOUNTANT_DASHBOARD_FIRST_TIME_VISITED,
  });
  const [isFirstTime, setIsFirstTime] = useState<boolean>(false);

  const setUserFirstTimeVisited = async () => {
    try {
      await create({ userPreference: { key: ACCOUNTANT_DASHBOARD_FIRST_TIME_VISITED, value: 'true' } });
      setIsFirstTime(false);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    if (isFetched) {
      setIsFirstTime(!data);
    }
  }, [data, isFetched]);

  useOneTimeEffect(
    () => {
      void (isFirstTime && setUserFirstTimeVisited());
    },
    () => !!(isFirstTime && !isLoading),
    [isFirstTime, isLoading]
  );

  return {
    isFirstTime,
    isLoading,
  };
};
