import { Checkbox, Container, Group, Pill, Text } from '@melio/penny';
import { Ref } from 'react';

import { FilterOption } from '../components/Filters/types';

type Props = {
  options: FilterOption[];
  selected: string[];
  onChange: (value: string) => void;
  firstOptionRef?: Ref<HTMLDivElement>;
};

export const FilterMenuOptionsMulti = ({ options, selected, onChange, firstOptionRef }: Props) => (
  <Group data-component="CheckboxGroup" variant="vertical" spacing="none">
    {options.map((option, index) => (
      <Container
        paddingX="s"
        paddingY="xs-s"
        key={option.value}
        {...(index === 0 && { ref: firstOptionRef })}
        data-testid={`option-menu-item-${option.value}`}
        onClick={() => onChange(option.value)}
      >
        <Group alignItems="center">
          <Checkbox
            key={option.value}
            data-testid={`checkbox-${option.value}`}
            size="large"
            isChecked={selected.includes(option.value)}
            onChange={() => onChange(option.value)}
          />
          <Group.Item>
            {option.backgroundColor ? (
              <Pill status={option.backgroundColor} type="secondary" label={option.label} />
            ) : (
              <Text textStyle="body3">{option.label}</Text>
            )}
          </Group.Item>
        </Group>
      </Container>
    ))}
  </Group>
);
