import { useMelioIntl } from '@melio/platform-i18n';
import Big from 'big.js';

import { type Plan, SubscriptionBillingCycleEnum, useSubscriptionMe } from '../../../../api';
import {
  useIsPlanWillRemoveCurrentUser,
  usePendingSubscription,
  usePlanInfo,
  usePlansPromotion,
  usePlansTiers,
  useSubscription,
  useSubscriptionPlan,
} from '../../../../hooks';
import { usePlanCardButtonLabel } from './PlanCardButton/usePlanCardButtonLabel';
import { usePlanBadge } from './PlanCardHeader/PlanCardHeader.utils';
import { PlanCardView } from './PlanCardView';

export type PlanCardProps = {
  plan: Plan;
  discount?: number;
  isSelected: boolean;
  selectedBillingCycle: SubscriptionBillingCycleEnum;
  onSelectPlan: (planId: Plan['id'], planName: string) => void;
  isEligibleForFreeTrial: boolean;
  isAccountingClient?: boolean;
  isOnboarding?: boolean;
};

export const PlanCard = ({
  plan,
  selectedBillingCycle,
  onSelectPlan,
  isSelected,
  isEligibleForFreeTrial,
  isOnboarding,
}: PlanCardProps) => {
  const subscription = useSubscription();
  const { plansPromotionData, isFetching: isFetchingPromoCodeData } = usePlansPromotion();
  const { getIsCurrentPlan, isDowngradedPlan } = useSubscriptionPlan();
  const { getIsMostPopularPlan, getIsFreePlan } = usePlansTiers();
  const { isUpgradedPlan } = useSubscriptionPlan();
  const { isReactivationAllowed, nextSubscriptionPlanId: pendingPlanId } = usePendingSubscription();
  const { isMutating: isUpdatingSubscription } = useSubscriptionMe({
    enabled: false,
  });
  const { planName, planDescription, capabilities } = usePlanInfo(plan.id, {
    isOnboarding,
  });
  const planBadge = usePlanBadge(plan.id);
  const isPlanWillRemoveCurrentUser = useIsPlanWillRemoveCurrentUser(plan.id);
  const { formatMessage } = useMelioIntl();

  const currentBillingCycle = subscription?.planCyclePeriod;
  const isBillingCycleToggledToYearly =
    selectedBillingCycle === SubscriptionBillingCycleEnum.Annual &&
    currentBillingCycle === SubscriptionBillingCycleEnum.Monthly;

  const selectPlanButtonLabel = usePlanCardButtonLabel({
    planId: plan.id,
    isBillingCycleToggledToYearly,
    isEligibleForFreeTrial,
  });

  const cycles = plan.cycles;

  const billingCycle = cycles[selectedBillingCycle];
  if (!billingCycle) {
    return null;
  }

  const isCurrentPlan = getIsCurrentPlan(plan.id);
  const isMostPopularPlan = getIsMostPopularPlan(plan.id);
  const shouldRenderMostPopularVariation = isMostPopularPlan && !isCurrentPlan && !isDowngradedPlan(plan.id);
  const shouldRenderCurrentPlanVariation = isCurrentPlan && !isMostPopularPlan;

  let variant: 'primary' | 'tertiary' = 'tertiary';

  if (
    (!subscription && !getIsFreePlan(plan.id)) ||
    (isCurrentPlan && (isBillingCycleToggledToYearly || isReactivationAllowed)) ||
    isUpgradedPlan(plan.id)
  ) {
    variant = 'primary';
  }

  const isNoSubscriptionAndPlanWillRemoveCurrentUser = !subscription && isPlanWillRemoveCurrentUser;

  const getIsDisabled = () => {
    if (isNoSubscriptionAndPlanWillRemoveCurrentUser) {
      return true;
    }
    if (isUpdatingSubscription && !isSelected) {
      return true;
    }

    if (isReactivationAllowed) {
      return pendingPlanId === plan.id;
    }

    if (isBillingCycleToggledToYearly && isCurrentPlan && getIsFreePlan(plan.id)) {
      return true;
    }

    return !isBillingCycleToggledToYearly && isCurrentPlan;
  };

  const isFreePlan = getIsFreePlan(plan.id);
  const isDisabled = getIsDisabled();
  const handleSelectPlanClick = () => onSelectPlan(plan.id, planName);

  const discount =
    !isFetchingPromoCodeData && plansPromotionData?.appliedForPlans.includes(plan.tier)
      ? plansPromotionData.discount
      : undefined;
  const priceBeforeDiscount = discount ? cycles.monthly.basePrice : undefined;

  const { basePrice, unitPrice } =
    selectedBillingCycle === SubscriptionBillingCycleEnum.Annual
      ? {
          basePrice: new Big(billingCycle.basePrice).div(12).toNumber(),
          unitPrice: new Big(billingCycle.unitPrice || 0).div(12).toNumber(),
        }
      : billingCycle;

  const finalPrice = discount ? new Big(basePrice).mul(1 - discount).toNumber() : basePrice;

  const disabledTooltipContent = isNoSubscriptionAndPlanWillRemoveCurrentUser
    ? formatMessage('activities.subscription.plans.planCard.disabled.tooltip.planWillRemoveCurrentUser')
    : undefined;

  return (
    <PlanCardView
      id={plan.id}
      headerProps={{ planBadge, planName, planDescription, isFreePlan }}
      priceProps={{ isFreePlan, selectedBillingCycle, priceBeforeDiscount, finalPrice, unitPrice }}
      shouldRenderMostPopularVariation={shouldRenderMostPopularVariation}
      shouldRenderCurrentPlanVariation={shouldRenderCurrentPlanVariation}
      selectPlanButtonProps={{
        planTier: plan.tier,
        isDisabled,
        label: selectPlanButtonLabel,
        variant,
        onClick: handleSelectPlanClick,
        isSaving: isSelected && isUpdatingSubscription,
        disabledTooltipContent,
      }}
      featureListProps={{ capabilities }}
      emphasizeMostPopularPlan={isOnboarding}
      shouldHideUnitPriceDetails={isOnboarding}
    />
  );
};
