import { Box, Divider } from '@chakra-ui/react';
import { FormattedMessage, SystemMessage, useIsMobile } from '@melio/ar-domain';
import { Container, Group, IconButton, Loader, Text } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import { forwardRef } from '@melio/platform-utils';
import { FC } from 'react';

import { ActivationTracker } from '../components';
import { ActivationStep } from '../utils';
import { Footer, FooterProps } from './Footer';
import { Header, HeaderProps } from './Header';

export type ActivationLayoutProps = {
  isLoading?: boolean;
  header?: HeaderProps;
  step: ActivationStep;
} & FooterProps;

export const ActivationLayout = forwardRef<ActivationLayoutProps>(
  (
    { header, isLoading, step, primaryButtonProps, secondaryButtonProps, tertiaryButtonProps, children, ...props },
    ref
  ) => {
    const isMobile = useIsMobile();

    const hasMainButton = !!primaryButtonProps || !!secondaryButtonProps;
    const hasFooter = isMobile ? hasMainButton : hasMainButton || !!tertiaryButtonProps;

    return (
      <Box height="100%" ref={ref} {...props}>
        <Layout
          header={{
            content: isMobile ? <MobileHeader tertiaryButtonProps={tertiaryButtonProps} /> : <DesktopHeader />,
            isSticky: true,
          }}
          footer={
            hasFooter
              ? {
                  content: (
                    <Footer
                      primaryButtonProps={primaryButtonProps}
                      secondaryButtonProps={secondaryButtonProps}
                      tertiaryButtonProps={isMobile ? undefined : tertiaryButtonProps}
                      isDisabled={isLoading}
                    />
                  ),
                  isSticky: true,
                }
              : undefined
          }
          paddingContent={isMobile ? 'none' : 'm'}
        >
          <Group width="full" height="full" variant="vertical" spacing={isMobile ? 's' : 'm'}>
            <Group
              variant={isMobile ? 'vertical' : 'horizontal'}
              height="full"
              width="full"
              spacing={isMobile ? 's' : 'xxxl'}
            >
              {isMobile && (
                <Container paddingBottom="s">
                  <SystemMessage />
                </Container>
              )}

              <Box width="fit-content">
                <ActivationTracker step={step} />
              </Box>

              <LoadingContainer isLoading={isLoading}>
                <Container paddingX={isMobile ? 's' : 'none'} width="full">
                  <Group variant="vertical" spacing="m" width="full" data-testid={`activation-step-${step}`}>
                    {header && <Header {...header} />}
                    {children}
                  </Group>
                </Container>
              </LoadingContainer>
            </Group>
          </Group>
        </Layout>
      </Box>
    );
  }
);

ActivationLayout.displayName = 'ActivationLayout';

const LoadingContainer: FC<{ isLoading?: boolean }> = ({ isLoading, children }) =>
  isLoading ? (
    <Box display="flex" alignItems="center" alignSelf="center" justifyContent="center" width="full" height="full">
      <Loader />
    </Box>
  ) : (
    <>{children}</>
  );

type MobileHeaderProps = {
  tertiaryButtonProps?: FooterProps['tertiaryButtonProps'];
};

const MobileHeader = ({ tertiaryButtonProps }: MobileHeaderProps) => (
  <>
    <Container paddingX="s" paddingY="s" height="full" width="full">
      <Group variant="horizontal" spacing="s">
        {tertiaryButtonProps && <IconButton variant="naked" icon="arrow-left" {...tertiaryButtonProps} />}
        <Text as="h1" textStyle="heading1Semi">
          <FormattedMessage id="ar.onboarding.layout.header.label" />
        </Text>
      </Group>
    </Container>
    <Divider />
  </>
);

const DesktopHeader = () => (
  <Container paddingX="m" paddingTop="xxl" paddingBottom="xs">
    <Group width="full" height="full" variant="vertical" spacing="l">
      <SystemMessage />
      <Group variant="horizontal" spacing="s">
        <Text as="h1" textStyle="heading1Semi">
          <FormattedMessage id="ar.onboarding.layout.header.label" />
        </Text>
      </Group>
    </Group>
  </Container>
);
