/* eslint-disable no-restricted-imports */
import { Text } from '@melio/penny';
import { AccountingFirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

export const PlanInfo = ({ client }: { client: AccountingFirmClientExpanded }) => {
  const { formatMessage, formatDate } = useMelioIntl();

  const planName = client.subscriptionInfo?.planMetadata?.planTier
    ? formatMessage(`activities.subscription.plans.${client.subscriptionInfo?.planMetadata?.planTier}.title`)
    : '';
  const cycle = client.subscriptionInfo?.subscription?.planCyclePeriod
    ? formatMessage(
        `activities.accountants.firmDashboard.v2.clientDrawer.subscription.planCyclePeriod.${client.subscriptionInfo?.subscription?.planCyclePeriod}.label`
      )
    : '';

  return (
    <>
      <Text textStyle="body4Semi" color="semantic.text.secondary">
        {formatMessage(
          'activities.accountants.firmDashboard.v2.clientDrawer.subscription.currentPlan.semiHeader.title'
        )}
      </Text>
      <Text textStyle="body2" color="semantic.text.primary" data-testid="subscription-plan-info">
        {formatMessage('activities.accountants.firmDashboard.v2.clientDrawer.subscription.planDescription.label', {
          tier: planName,
          cycle,
        })}
      </Text>
      <Text textStyle="body3" color="semantic.text.secondary">
        {formatMessage(
          'activities.accountants.firmDashboard.v2.clientDrawer.subscription.planDescription.nextBilling',
          {
            nextBillingDate: formatDate(client.subscriptionInfo?.subscription?.nextBillingDate, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
          }
        )}
      </Text>
    </>
  );
};
