import { BrandSymbolKey, IconKey } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

const verifyValidBase64Logo = (logo: string) => {
  const splitRes = logo.split(',');

  return splitRes.length === 2 && splitRes[1] !== 'default';
};

export const getFundingSourceImageProps = (fundingSource: FundingSource) => {
  if (fundingSource.type === 'bank-account' && fundingSource.logo && verifyValidBase64Logo(fundingSource.logo)) {
    return { src: fundingSource.logo, alt: 'Account provider logo' };
  }
  return undefined;
};

export const getFundingSourceIconType = (
  fundingSource: FundingSource
): { type: 'icon'; element: IconKey } | { type: 'brand'; element: BrandSymbolKey } => {
  const { type: fundingSourceType } = fundingSource;

  if (fundingSourceType === 'bank-account') {
    return {
      type: 'icon',
      element: 'bank',
    };
  }

  if (fundingSourceType === 'flex-account') {
    return {
      type: 'brand',
      element: 'flex',
    };
  }

  if (fundingSourceType === 'paypal-balance') {
    return {
      type: 'icon',
      element: 'paypal',
    };
  }

  const { network, type: cardType } = fundingSource.details;
  if (network) {
    const supportedCardCompanies = ['visa', 'amex', 'mastercard', 'discover'] as BrandSymbolKey[];
    if (supportedCardCompanies.find((item) => network.includes(item))) {
      return {
        type: 'brand',
        element: network as BrandSymbolKey,
      };
    }
    return {
      type: 'icon',
      element: 'credit-card',
    };
  } else {
    return {
      type: 'icon',
      element: cardType === 'debit' ? 'debit-card' : 'credit-card',
    };
  }
};
