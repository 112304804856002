import { useToast } from '@melio/penny';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { Bill, usePaymentIntent, usePayments } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';

import { useExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';
import { SinglePaymentFlowActivity, SinglePaymentFlowActivityProps } from '../SinglePaymentFlow';
import { useCreatePaymentIntentFromBill } from './useCreatePaymentIntentFromBill';
import { getFinancingDetailsFromPaymentIntent } from './utils';

export type ScheduleSinglePaymentActivityProps = Omit<
  SinglePaymentFlowActivityProps,
  'actionToPerform' | 'onConfirm' | 'paymentIntentModel' | 'paymentIntentId'
> & { billId: Bill['id']; externalOrigin?: string | null };

export const ScheduleSinglePaymentActivity = withAnalyticsContext<ScheduleSinglePaymentActivityProps>(
  ({ billId, onError, deliveryMethodId, isLoading, ...props }: ScheduleSinglePaymentActivityProps) => {
    const { toast } = useToast();
    const [addDeliveryDateToCreateAndUpdate] = useDevFeature<boolean>(
      FeatureFlags.AddDeliveryDateToCreateAndUpdatePayment,
      false
    );
    const handleFail = (error: PlatformError) => {
      toast({ type: 'error', title: error.message || 'Failed to create the payment intent' });
      onError?.(error);
    };

    const {
      paymentIntentId,
      isLoading: isLoadingPaymentIntentFromBill,
      bill,
    } = useCreatePaymentIntentFromBill({
      billId,
      onError: handleFail,
    });

    const { create: createPayment } = usePayments({
      params: {
        expand: 'none',
      },
      enabled: false,
    });

    const paymentIntentModel = usePaymentIntent({ id: paymentIntentId });

    const analyticsContext = { PaymentIntentId: paymentIntentId, IsEditFlow: 'False' };
    useAnalyticsContext({ globalProperties: analyticsContext });

    const {
      data: basicPaymentIntent,
      isLoading: isLoadingPaymentIntent,
      isUpdating: isUpdatingPaymentIntent,
    } = paymentIntentModel;
    const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);

    const handleConfirm = (fxQuoteToken?: string) => {
      if (
        paymentIntent?.billPayments &&
        paymentIntent.deliveryMethodId &&
        paymentIntent.selectedDeliveryPreferenceType &&
        paymentIntent.fundingSourceId &&
        paymentIntent.effectiveScheduledDate
      ) {
        return createPayment({
          paymentBillsInfo: paymentIntent.billPayments?.map((billPayment) => ({
            id: billPayment.billId,
            paymentAmount: billPayment.amount,
          })),
          vendorId: paymentIntent.vendorId,
          deliveryMethodId: paymentIntent.deliveryMethodId,
          deliveryPreference: paymentIntent.selectedDeliveryPreferenceType,
          fundingSourceId: paymentIntent.fundingSourceId,
          scheduledDate: paymentIntent.effectiveScheduledDate.toISOString(),
          deliveryDate: addDeliveryDateToCreateAndUpdate
            ? paymentIntent.effectiveDeliveryDate?.toISOString()
            : undefined,
          ...(paymentIntent.noteToVendor && { noteToVendor: paymentIntent.noteToVendor }),
          paymentPurpose: paymentIntent.paymentPurpose,
          requireWithdrawApproval: paymentIntent.requireWithdrawApproval,
          requireDepositApproval: paymentIntent.requireDepositApproval,
          financingDetails: getFinancingDetailsFromPaymentIntent(paymentIntent),
          fxQuoteToken,
          paymentFlowUniqueId: paymentIntent.id,
          datesQuoteId: paymentIntent.deliveryPreferenceOptions?.find(
            ({ type }) => type === paymentIntent.selectedDeliveryPreferenceType
          )?.quoteId,
        });
      }

      throw new Error('cannot create payment');
    };

    const billTotalAmount = bill?.amount;
    const paymentAmount = paymentIntent?.amountToPay;
    const isPartialPayment = !!(
      billTotalAmount &&
      paymentAmount &&
      0 < paymentAmount &&
      paymentAmount < billTotalAmount
    );
    const analyticsProperties = {
      BillId: billId,
      BillTotalAmount: billTotalAmount,
      PaymentAmount: paymentAmount,
      Is_Partial: isPartialPayment,
    };

    return (
      <SinglePaymentFlowActivity
        {...props}
        actionToPerform="schedule"
        onError={onError}
        paymentIntentId={paymentIntentId}
        // theres a type mismatch with the return value (API Payment vs javascript-sdk payment)
        // TODO: fix this with https://meliorisk.atlassian.net/browse/ME-31605
        onConfirm={handleConfirm as never}
        isLoading={isLoading || isLoadingPaymentIntent || isUpdatingPaymentIntent || isLoadingPaymentIntentFromBill}
        paymentIntentModel={paymentIntentModel}
        deliveryMethodId={deliveryMethodId}
        analyticsProperties={analyticsProperties}
      />
    );
  }
);
