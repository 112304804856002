import { useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Tracker } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useMemo } from 'react';

import { ActivationStep } from '../utils';

type ActivationTrackerProps = {
  step: ActivationStep;
};

export const ActivationTracker = forwardRef<ActivationTrackerProps>(({ step }, ref) => {
  const { steps } = useActivationTrackerTracker(step);
  const isMobile = useIsMobile();

  return (
    <Tracker
      width={isMobile ? 'full' : 'fit-content'}
      status="brand"
      variant={isMobile ? 'horizontal' : 'vertical'}
      data-testid="activation-tracker"
      ref={ref}
      {...(!isMobile ? { position: 'sticky', top: 'm' } : {})}
    >
      {steps.map((step, index) => (
        <Tracker.Step
          key={`tracker-step-${index}`}
          data-testid={`tracker-step-${step.title}`}
          isActive={step.isCurrent}
          isCompleted={step.isCompleted}
        >
          {isMobile ? <Tracker.StepDescription label={step.title} /> : <Tracker.StepTitle label={step.title} />}
        </Tracker.Step>
      ))}
    </Tracker>
  );
});

type ActivationTrackerStep = {
  title: string;
  stepId: ActivationStep;
  isCurrent?: boolean;
  isCompleted?: boolean;
};

const useActivationTrackerTracker = (currentStep: ActivationStep) => {
  const { formatMessage } = useMelioIntl();

  const onboardingSteps: ActivationTrackerStep[] = useMemo(
    () => [
      {
        stepId: 'get-started',
        title: 'Get Started',
      },
      {
        stepId: 'receiving-account',
        title: formatMessage('ar.onboarding.tracker.step.title.ReceivingAccount.text'),
      },
      {
        stepId: 'business-details',
        title: formatMessage('ar.onboarding.tracker.step.title.BusinessDetails.text'),
      },
      {
        stepId: 'ownership-details',
        title: formatMessage('ar.onboarding.tracker.step.title.BusinessOwnership.text'),
      },
    ],
    [formatMessage]
  );

  const getStepIndex = useCallback(
    (stepId: ActivationStep) => onboardingSteps.findIndex((step) => step.stepId === stepId),
    [onboardingSteps]
  );
  const currentStepIndex = useMemo(() => getStepIndex(currentStep), [currentStep, getStepIndex]);

  const isActive = useCallback(
    (stepId: ActivationStep) => currentStepIndex == getStepIndex(stepId),
    [currentStepIndex, getStepIndex]
  );

  const isCompleted = useCallback(
    (stepId: ActivationStep) => currentStepIndex > getStepIndex(stepId),
    [currentStepIndex, getStepIndex]
  );

  return {
    steps: useMemo<NonNullable<ActivationTrackerStep[]>>(
      () =>
        onboardingSteps.map((step) => ({
          ...step,
          isCurrent: isActive(step.stepId),
          isCompleted: isCompleted(step.stepId),
        })),
      [isActive, isCompleted, onboardingSteps]
    ),
  };
};
