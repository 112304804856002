import { FilterOption, MultiSelectFilter, useMelioIntl } from '@melio/ar-domain';

import { Filter } from '../types';

type InvoiceStatusFilterProps = {
  onApply: (filter?: Filter[]) => void;
  selectedStatusFilters?: Filter[];
};

export const InvoiceStatusFilter = ({ selectedStatusFilters, onApply }: InvoiceStatusFilterProps) => {
  const { formatMessage } = useMelioIntl();
  const filters: Filter[] = ['draft', 'paid', 'overdue', 'open', 'canceled'];

  const filterOptions: FilterOption<Filter>[] = filters.map((status) => ({
    filter: status,
    label: formatMessage(`ar.dashboard.activities.invoiceTable.filters.${status}.label`),
  }));

  return (
    <MultiSelectFilter
      label={formatMessage('ar.dashboard.activities.invoiceTable.filters.status.plural.label')}
      options={filterOptions}
      selectedFilters={selectedStatusFilters}
      onApply={onApply}
      data-testid="ar-dashboard-filter-menu-invoiceStatus"
    />
  );
};
