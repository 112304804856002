import { useCollectionApi, UseCollectionApiProps } from '@melio/api-client';
import {
  OrganizationVerifiedPersona,
  PersonasApiClient,
  PostOrganizationVerifiedPersonasRequest,
  UpsertOrganizationVerifiedPersonaRequest,
} from '@melio/platform-api-axios-client';
import { updateDateTimeToDateString } from '@melio/platform-utils/src/date-utils/utils';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';

type QueryFn = typeof PersonasApiClient.getVerifiedPersonas;
type CreateFn = (
  data: PostOrganizationVerifiedPersonasRequest
) => ReturnType<typeof PersonasApiClient.postVerifiedPersonas>;

export type ListFn = typeof PersonasApiClient.getVerifiedPersonas;

export type UseVerifiedPersonasProps = UseCollectionApiProps<QueryFn, OrganizationVerifiedPersona> & {
  onCreate?: (res: OrganizationVerifiedPersona) => void;
  onCreateError?: ErrorFunction;
};

const prepareCreateParams = (params: PostOrganizationVerifiedPersonasRequest) => ({
  ...params,
  personas: params.personas.map((req) => updateDateTimeToDateString(req, ['dateOfBirth'])),
});

const prepareUpsertParams = (params: UpsertOrganizationVerifiedPersonaRequest[]) =>
  params.map((req) => updateDateTimeToDateString(req, ['dateOfBirth']));

export const useVerifiedPersonas = (props?: UseVerifiedPersonasProps) => {
  const query = useCollectionApi<ListFn, CreateFn, never, never, OrganizationVerifiedPersona>({
    ...props,
    enabled: props?.enabled ?? true,
    queryKey: ['PersonasApi'],
    queryFn: PersonasApiClient.getVerifiedPersonas,
    prepareCreateParams,
    createFn: PersonasApiClient.postVerifiedPersonas,
  });
  const batchUpsert = useMutation(
    query.queryKey,
    (data: UpsertOrganizationVerifiedPersonaRequest[]) =>
      PersonasApiClient.bulkUpsertVerifiedPersonas(prepareUpsertParams(data)),
    {
      onSuccess: (data) => {
        if (data.data.data?.length > 0) {
          props?.onCreate?.(data.data.data[0] as OrganizationVerifiedPersona);
        }
        void query.refetch();
      },
    }
  );
  return { ...query, batchUpsertMutate: batchUpsert };
};
