import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClient } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionRouter } from '@melio/subscriptions';
import { useNavigate } from 'react-router-dom';

import { useAccountantsRoutes } from '../../../../utils/useAccountantsRoutes';
import { Props as DashboardProps } from '../../types';
import { hasEmptyPlanStatement } from '../../v1/utils/assign-plan.utils';
import { useFirmClientsTableContext } from '../components/FirmClients/FirmClientsTableContext';

type Props = Pick<
  DashboardProps,
  'goToSettingsAndRefresh' | 'goToSettingsSubscriptionPlansAndRefresh' | 'onSwitchOrganization'
>;

export const useClientActions = ({
  goToSettingsSubscriptionPlansAndRefresh,
  onSwitchOrganization,
  goToSettingsAndRefresh,
}: Props) => {
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const {
    clientActions: { updateClient },
  } = useFirmClientsTableContext();
  const navigate = useNavigate();
  const { goToAssignClientPlan, goToManageBillingOption } = useAccountantsRoutes();
  const { goToSubscriptionPlansSettings } = useSubscriptionRouter();

  const onSwitchToClient = async (organizationId: string) => {
    try {
      await onSwitchOrganization({ organizationId, keepLoadingState: true, switchAccessTokenOnly: true });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.switchOrganization.toast.error'),
      });
    }
  };
  const onChangeClientPlan = async (organizationId: string) => {
    try {
      await onSwitchOrganization({ organizationId, switchAccessTokenOnly: true, keepLoadingState: true });
      goToSettingsSubscriptionPlansAndRefresh();
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.changeClientPlan.toast.error'),
      });
    }
  };
  const onGoToAssignPlan = (organizationId: string) => {
    track('User', 'Click', {
      Intent: 'client-options',
      Cta: 'assign-plan',
    });
    goToAssignClientPlan({ accountingClientId: organizationId });
  };

  const onToggleHiddenState = async ({
    id,
    isHidden,
  }: {
    id: AccountingFirmClient['client']['id'];
    isHidden: boolean;
  }) => {
    try {
      track('User', 'Click', {
        Intent: 'client-options',
        Cta: isHidden ? 'unhide-client' : 'hide-client',
      });
      await updateClient?.({ id, data: { isHidden: !isHidden } });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('screens.serverError.title'),
      });
    }
  };
  const onManageClientBillingOption = (organizationId: string) => {
    track('User', 'Click', {
      Intent: 'client-options',
      Cta: 'manage-billing-option',
    });
    goToManageBillingOption({ accountingClientId: organizationId });
  };
  const onManageClientPlan = (orgId: string) => {
    track('User', 'Click', {
      Intent: 'client-options',
      Cta: 'proceed-to-plans',
    });
    void onChangeClientPlan(orgId);
  };
  const onProceedCheckout = (orgId: string) => {
    track('User', 'Click', {
      Intent: 'client-options',
      Cta: 'proceed-to-checkout',
    });
    void onSwitchToClient(orgId);
  };
  const onGoToClientAccount = (orgId: string, subscriptionInfo?: AccountingFirmClient['subscriptionInfo']) => {
    const action =
      !subscriptionInfo?.isAwaitingActivation && hasEmptyPlanStatement(subscriptionInfo)
        ? 'assign-plan'
        : 'choose-client';

    track('User', 'Click', {
      Intent: 'choose-client',
      Cta: action,
    });

    if (action === 'assign-plan') {
      return onGoToAssignPlan(orgId);
    }

    return onSwitchToClient(orgId);
  };
  const goToClientCompanyPage = async (organizationId: string) => {
    try {
      await onSwitchOrganization({ organizationId, switchAccessTokenOnly: true, keepLoadingState: true });
      goToSettingsAndRefresh();
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.accountants.firmDashboard.v2.goToClientCompanyPage.toast.error'),
      });
    }
  };

  const onViewBillingHistoryOption = async (
    organizationId: string,
    {
      isClientPayingForSubscription,
      isFirmPayingForSubscription,
    }: {
      isClientPayingForSubscription: boolean;
      isFirmPayingForSubscription: boolean;
    }
  ) => {
    if (isClientPayingForSubscription) {
      try {
        await onSwitchOrganization({ organizationId, switchAccessTokenOnly: true, keepLoadingState: true });
        goToSubscriptionPlansSettings();
        navigate(0);
      } catch (error) {
        showMessage({
          type: 'error',
          title: formatMessage('activities.accountants.firmDashboard.v2.onViewBillingHistoryOption.toast.error'),
        });
      }
    } else if (isFirmPayingForSubscription) {
      goToSubscriptionPlansSettings();
    }
  };

  return {
    onToggleHiddenState,
    onManageClientBillingOption,
    onManageClientPlan,
    onProceedCheckout,
    onGoToClientAccount,
    onGoToAssignPlan,
    goToClientCompanyPage,
    onViewBillingHistoryOption,
  };
};
