import { useIsMobile } from '@melio/ar-domain';
import { Button, ButtonProps, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
type FooterButtonProps = Pick<ButtonProps, 'label' | 'isLoading' | 'onClick' | 'isDisabled'>;

export type FooterProps = {
  primaryButtonProps?: FooterButtonProps;
  secondaryButtonProps?: FooterButtonProps;
  tertiaryButtonProps?: FooterButtonProps;
};

export const Footer = forwardRef<FooterProps>(
  ({ primaryButtonProps, secondaryButtonProps, tertiaryButtonProps }, ref) => {
    const isMobile = useIsMobile();
    return (
      <Group
        spacing={isMobile ? 's' : 'm'}
        width="auto"
        variant={isMobile ? 'vertical' : 'horizontal'}
        justifyContent={isMobile ? 'space-between' : 'flex-start'}
        data-component={Footer.displayName}
        ref={ref}
      >
        {tertiaryButtonProps && (
          <Button {...tertiaryButtonProps} data-testid="footer-tertiary-button" variant="tertiary" />
        )}
        <Group
          spacing={isMobile ? 's' : 'm'}
          width="full"
          variant="horizontal"
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
        >
          {secondaryButtonProps && (
            <Button
              {...secondaryButtonProps}
              variant="secondary"
              data-testid="footer-secondary-button"
              isFullWidth={isMobile}
            />
          )}
          {primaryButtonProps && (
            <Button {...primaryButtonProps} data-testid="footer-primary-button" isFullWidth={isMobile} />
          )}
        </Group>
      </Group>
    );
  }
);
Footer.displayName = 'Footer';
