import { SortingState } from '@melio/penny';

import { sortComparison } from '../../../../../utils/tables';
import { ClientData, SortableClientsTableColumns } from './types';

export const sortingFunctions: Record<
  SortableClientsTableColumns,
  (data: Array<ClientData>, sortDirection?: SortingState['sortDirection']) => Array<ClientData>
> = {
  [SortableClientsTableColumns.CLIENT]: (data: Array<ClientData>, sortDirection?: SortingState['sortDirection']) =>
    data.sort((a, b) => {
      const clientNameA = a.client.name?.toString().toLowerCase() ?? '';
      const clientNameB = b.client.name?.toString().toLowerCase() ?? '';
      return sortComparison(clientNameA, clientNameB, sortDirection);
    }),
  [SortableClientsTableColumns.BILLS_DUE]: (data: Array<ClientData>, sortDirection?: SortingState['sortDirection']) =>
    data.sort((a, b) => {
      const billsCountA = a.billsCount;
      const billsCountB = b.billsCount;
      return sortComparison(billsCountA, billsCountB, sortDirection);
    }),
  [SortableClientsTableColumns.ACCOUNT_STATUS]: (
    data: Array<ClientData>,
    sortDirection?: SortingState['sortDirection']
  ) =>
    data.sort((a, b) => {
      const statusA = a.clientStatus?.toString().toLowerCase() ?? '';
      const statusB = b.clientStatus?.toString().toLowerCase() ?? '';
      return sortComparison(statusA, statusB, sortDirection);
    }),
};

const baseFakeClientForLoading: ClientData = {
  client: {
    id: '1',
    name: 'a',
    history: { createdAt: undefined as unknown as Date, updatedAt: undefined as unknown as Date },
    phoneNumber: '',
    isHidden: false,
  },
  type: 'client',
  billsCount: 1,
  clientStatus: 'activated',
  hasPermissions: true,
};

const TABLE_LOADING_SKELETON_COUNT = 5;
export const fakerClientsForLoading: Array<ClientData> = Array.from(
  { length: TABLE_LOADING_SKELETON_COUNT },
  (_, i) => ({
    ...baseFakeClientForLoading,
    client: { ...baseFakeClientForLoading.client, id: `${i + 1}` },
  })
);
