import { isDeliveryPreferenceTypeFast, isLateDeliveryDate, usePaymentSchedulingPreference } from '@melio/ap-domain';
import { DeliveryPreferenceForm } from '@melio/ap-widgets';
import { Card, Group, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { DeliveryPreference, FundingSource } from '@melio/platform-api';
import { FormattedMessage, useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay, useDateUtils } from '@melio/platform-utils';
import { useCallback, useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type DeliverySpeedDateScreenProps = {
  dueDate: Date;
  isLoading?: boolean;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onError?: ErrorFunction;
  fundingSource: FundingSource;
  deliveryPreferences: DeliveryPreference[];
  selectedDeliveryPreferenceType: DeliveryPreference['type'];
  step: number;
  totalSteps: number;
  onDone: (type: DeliveryPreference['type'], date: Date) => unknown;
};

export const DeliverySpeedDateScreen = ({
  dueDate,
  onBack,
  onClose,
  isLoading,
  fundingSource,
  deliveryPreferences,
  selectedDeliveryPreferenceType,
  step,
  totalSteps,
  onDone,
  onError,
  ...props
}: DeliverySpeedDateScreenProps) => {
  const { formatMessage, formatDateTimeRange, formatDate } = useMelioIntl();
  const { createDate } = useDateUtils();
  const today = createDate();
  const [deliveryPreferenceType, setDeliveryPreferenceType] = useState(selectedDeliveryPreferenceType);
  const { isByDeliveryDate } = usePaymentSchedulingPreference();
  const selectedDeliveryPreference = deliveryPreferences.find(
    (preference) => preference.type === selectedDeliveryPreferenceType
  );

  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
  }, [endAction]);

  const { track } = useAnalytics();

  if (!selectedDeliveryPreference) {
    onError?.({ code: '500', message: 'could not find deliveryPreferences' });
    return null;
  }

  const { minDeliveryDate, maxDeliveryDate } = selectedDeliveryPreference;
  const deliveryDate = isByDeliveryDate
    ? formatDate(minDeliveryDate, { dateStyle: 'medium' })
    : formatDateTimeRange(minDeliveryDate, maxDeliveryDate, { dateStyle: 'medium' });
  const formattedDueDate = formatDate(dueDate, { dateStyle: 'medium' });

  const description = isLateDeliveryDate(selectedDeliveryPreference, dueDate)
    ? 'descriptionDueDatePast'
    : 'descriptionDueDateNotPast';
  const deliveryMethodType = selectedDeliveryPreferenceType === 'ach' ? 'ach' : 'check';

  const sectionBannerVariant = isLateDeliveryDate(selectedDeliveryPreference, dueDate) ? 'warning' : 'informative';

  const handleDone: typeof onDone = (type, date) => {
    startAction('delivery_date_selection');

    track('Payment', 'Click', {
      Cta: 'continue',
      Date: date,
      DeliveryPreference: type,
      SpecialDeliveryMethod: isDeliveryPreferenceTypeFast(type) ? type : null,
      IsSpecialDelivery: isDeliveryPreferenceTypeFast(type),
      IsPastDueDate: selectedDeliveryPreference ? isLateDeliveryDate(selectedDeliveryPreference, date) : null,
    });
    return onDone(type, date);
  };

  return (
    <NewSinglePaymentStepLayout
      data-component="DeliveryDateActivity.DeliverySpeedDateScreen"
      data-testid="delivery-date-activity-delivery-speed-date-screen"
      isLoading={isLoading}
      ref={endActionMonitoring}
      {...props}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={() => handleDone(deliveryPreferenceType, today)}
            isLoading={isLoading}
            label={formatMessage('activities.deliveryDate.screens.deliveryDate.continue')}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        <FormattedMessage
          id={
            isByDeliveryDate
              ? 'activities.deliveryDate.screens.deliverySpeed.byDeliveryDate.title'
              : 'activities.deliveryDate.screens.deliverySpeed.byScheduleDate.title'
          }
        />
      </NewSinglePaymentStepLayout.Title>
      <SystemMessageDisplay />
      <NewSinglePaymentStepLayout.Content>
        <Group variant="vertical" spacing="xl" data-testid="delivery-date-activity-delivery-speed-date-screen-content">
          <SectionBanner
            data-testid="delivery-speed-section-banner"
            variant={sectionBannerVariant}
            description={formatMessage(
              isByDeliveryDate
                ? `activities.deliveryDate.screens.deliverySpeed.byDeliveryDate.${description}`
                : `activities.deliveryDate.screens.deliverySpeed.${deliveryMethodType}.${description}`,
              {
                deliveryDate,
                dueDate: formattedDueDate,
              }
            )}
          />
          <Card data-component="DeliverySpeedWidget" data-testid="delivery-speed-widget" width="full">
            <DeliveryPreferenceForm
              data-testid="delivery-date-widget-delivery-preference-form"
              fundingSource={fundingSource}
              deliveryPreferences={deliveryPreferences}
              deliveryPreferenceType={deliveryPreferenceType}
              onChange={setDeliveryPreferenceType}
            />
          </Card>
        </Group>
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

DeliverySpeedDateScreen.displayName = 'DeliveryDateActivity.DeliverySpeedDateScreen';
