import { _createFormFieldInput, FormTextFieldProps, TextField } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { TaxIdTypeEnum } from '../api-hooks';

const TaxIdType = {
  [TaxIdTypeEnum.Ein]: {
    mask: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    placeholder: 'XX-XXXXXXX',
  },
  [TaxIdTypeEnum.Ssn]: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    placeholder: 'XXX-XX-XXXX',
  },
  [TaxIdTypeEnum.Itin]: {
    mask: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    placeholder: 'XXX-XX-XXXX',
  },
};
type TaxIdType = keyof typeof TaxIdType;

const getPlaceHolder = (placeholder: string, last4Digits?: string) =>
  last4Digits ? placeholder.slice(0, -4) + last4Digits : placeholder;

export type TaxIdNumberInputProps = Omit<FormTextFieldProps, 'maskProps'> & {
  taxIdType?: TaxIdTypeEnum;
  last4Digits?: string;
};

export const TaxIdNumberInput = _createFormFieldInput(
  forwardRef<TaxIdNumberInputProps, 'input'>(({ taxIdType = 'SSN', placeholder, last4Digits, ...props }, ref) => (
    <TextField
      data-component={TaxIdNumberInput.displayName}
      placeholder={placeholder ?? getPlaceHolder(TaxIdType[taxIdType].placeholder, last4Digits)}
      maskProps={{ mask: TaxIdType[taxIdType].mask }}
      {...props}
      ref={ref}
    />
  ))
);
TaxIdNumberInput.displayName = 'TaxIdNumberInput';
