import { GoogleAddressCombobox, InternationalAddress as FormInternationalAddress } from '@melio/form-controls';
import {
  FieldValues,
  FormField,
  SelectNew,
  SelectNewOption,
  TextField,
  UseMelioFormResults,
  useWatch,
  VisuallyHidden,
} from '@melio/penny';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { useMemo, useState } from 'react';
import type { Path, PathValue } from 'react-hook-form';

import { useStateSelect } from '../../useStateSelect';
import { CountrySelect } from '../CountrySelect';
import { allowedCountry, InternationalAddressFieldNamesEnum } from './const';
import { getIsStateRequired, getStates } from './utils';

export type InternationalVendorAddressFieldsProps<T extends FieldValues = FieldValues> = {
  form: UseMelioFormResults<T>;
  isViewMode?: boolean;
  colSpan?: number;
  label?: MessageKey;
};

export const InternationalVendorAddressFields = <T extends FieldValues>({
  form,
  colSpan,
  label,
  isViewMode,
}: InternationalVendorAddressFieldsProps<T>) => {
  const { formatMessage } = useMelioIntl();
  type FormFieldName = Path<T>;
  type FormValue = PathValue<T, Path<T>>;
  const [showFullAddress, setShowFullAddress] = useState(
    !!form.getValues(InternationalAddressFieldNamesEnum.Line1 as FormFieldName)
  );
  const countryCode = useWatch<T>({
    control: form.control,
    name: InternationalAddressFieldNamesEnum.CountryCode as FormFieldName,
  });
  const line1 = useWatch<T>({
    control: form.control,
    name: InternationalAddressFieldNamesEnum.Line1 as FormFieldName,
  });
  const line2 = useWatch<T>({
    control: form.control,
    name: InternationalAddressFieldNamesEnum.Line2 as FormFieldName,
  });
  const city = useWatch<T>({
    control: form.control,
    name: InternationalAddressFieldNamesEnum.City as FormFieldName,
  });
  const state = useWatch<T>({
    control: form.control,
    name: InternationalAddressFieldNamesEnum.State as FormFieldName,
  });
  const addressPlaceholder =
    line1 && city && state
      ? `${line1 as string}, ${city as string}, ${state as string}`
      : formatMessage('widgets.vendors.internationalAddress.line1.placeholder');
  const isStateRequired = getIsStateRequired(countryCode);

  const shouldRenderField = (value: unknown) => (isViewMode ? !!value?.toString().trim() : true);

  const setValuesToAddress = (address: FormInternationalAddress) => {
    if (!address.formattedAddress.length) {
      return;
    }

    form.setValue(InternationalAddressFieldNamesEnum.Line1 as FormFieldName, address.line1 as FormValue, {
      shouldDirty: true,
    });
    form.setValue(InternationalAddressFieldNamesEnum.City as FormFieldName, address.city.name as FormValue, {
      shouldDirty: true,
    });
    form.setValue(InternationalAddressFieldNamesEnum.PostalCode as FormFieldName, address.postalCode as FormValue, {
      shouldDirty: true,
    });
    form.setValue(InternationalAddressFieldNamesEnum.CountryCode as FormFieldName, address.country.code as FormValue, {
      shouldDirty: true,
    });
    const states = getStates(address.country.code);
    if (states?.length) {
      const stateCode = states.find((s) => s.name === address.state.name)?.stateCode || '';
      form.setValue(InternationalAddressFieldNamesEnum.State as FormFieldName, stateCode as FormValue, {
        shouldDirty: true,
      });
    }
    setShowFullAddress(true);
  };

  const handleAddressChange = (addressValue: FormInternationalAddress) => {
    setValuesToAddress(addressValue);
  };

  const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setValue(InternationalAddressFieldNamesEnum.State as FormFieldName, '' as FormValue);
    form.setValue(InternationalAddressFieldNamesEnum.CountryCode as FormFieldName, e.target.value as FormValue);
  };

  const stateOptions = useMemo<SelectNewOption<string>[]>(
    () =>
      getStates(countryCode)?.map((option) => ({
        value: option.stateCode,
        label: option.name,
        testId: `state-${option.stateCode}`,
      })) || [],
    [countryCode]
  );

  const { filteredStateOptions, handleChange: handleStateSearch } =
    useStateSelect<SelectNewOption<string>>(stateOptions);

  const _halfColSpan = colSpan ? colSpan / 2 : 3;
  const _colSpan = colSpan ? colSpan : 6;

  return (
    <>
      <CountrySelect
        {...form.registerField(InternationalAddressFieldNamesEnum.CountryCode as FormFieldName)}
        colSpan={2}
        onChange={onCountryChange}
        labelProps={{
          label: formatMessage('widgets.vendors.countryCode.label'),
          tooltipProps: {
            content: (
              <>
                <VisuallyHidden>{formatMessage('widgets.vendors.countryCode.tooltip')} tooltip</VisuallyHidden>
                {formatMessage('widgets.vendors.internationalAddress.countryCode.tooltip')}
              </>
            ),
          },
        }}
      />
      <GoogleAddressCombobox
        restrictedCountry={allowedCountry}
        {...form.registerField(InternationalAddressFieldNamesEnum.Line1 as FormFieldName)}
        isRequired
        onAddressChange={handleAddressChange}
        colSpan={_colSpan}
        labelProps={{
          label: formatMessage(label || 'widgets.vendors.internationalAddress.line1.label'),
        }}
        placeholder={addressPlaceholder}
      />
      {showFullAddress ? (
        <>
          {shouldRenderField(line2) && (
            <FormField
              {...form.registerField(InternationalAddressFieldNamesEnum.Line2 as FormFieldName)}
              data-testid={`form-field-${InternationalAddressFieldNamesEnum.Line2}`}
              colSpan={_colSpan}
              labelProps={{
                label: formatMessage(`widgets.vendors.internationalAddress.line2.label`),
              }}
              render={(formFieldProps) => <TextField {...formFieldProps} />}
            />
          )}
          {shouldRenderField(city) && (
            <FormField
              {...form.registerField(InternationalAddressFieldNamesEnum.City as FormFieldName)}
              data-testid={`form-field-${InternationalAddressFieldNamesEnum.City}`}
              colSpan={isStateRequired ? _colSpan : _halfColSpan}
              labelProps={{
                label: formatMessage(`widgets.vendors.internationalAddress.city.label`),
              }}
              render={(formFieldProps) => <TextField {...formFieldProps} />}
            />
          )}
          {isStateRequired && shouldRenderField(state) && (
            <FormField
              colSpan={_halfColSpan}
              labelProps={{
                label: formatMessage(`widgets.vendors.internationalAddress.state.label`),
              }}
              {...form.registerField(InternationalAddressFieldNamesEnum.State as FormFieldName)}
              data-testid={`form-field-${InternationalAddressFieldNamesEnum.State}`}
              render={(formFieldProps) => (
                <SelectNew
                  {...formFieldProps}
                  searchBarProps={{
                    placeholder: formatMessage('widgets.vendors.state.placeholder'),
                    options: filteredStateOptions,
                    onChange: handleStateSearch,
                  }}
                  options={stateOptions}
                  shouldHideClearButton
                />
              )}
            />
          )}
          {shouldRenderField(countryCode) && (
            <FormField
              {...form.registerField(InternationalAddressFieldNamesEnum.PostalCode as FormFieldName)}
              data-testid={`form-field-${InternationalAddressFieldNamesEnum.PostalCode}`}
              colSpan={_halfColSpan}
              labelProps={{
                label: formatMessage(`widgets.vendors.internationalAddress.postalCode.label`),
              }}
              render={(formFieldProps) => <TextField {...formFieldProps} />}
            />
          )}
        </>
      ) : null}
    </>
  );
};
