import { StatusIconSolid } from '@melio/penny';
import { TodoOverdueInboxItems, TodoOverdueInboxItemsTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { useTodosActions } from '../hooks';
import { isEmptySection, normalizeInboxItems } from '../utils';
import { TodoSection } from './TodoSection';

export type TodoSectionOverdueInboxItemsProps = {
  todo?: TodoOverdueInboxItems;
  clientOrganizationId: string;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodoSectionOverdueInboxItems = ({
  clientOrganizationId,
  todo,
  onSwitchOrganization,
}: TodoSectionOverdueInboxItemsProps) => {
  const { formatMessage } = useMelioIntl();
  const { createViewBillsAction } = useTodosActions();
  const { goToPayDashboardBillsTab } = useAccountantsRoutes();

  if (isEmptySection(todo)) {
    return null;
  }

  const totalItems = todo?.items.pagination.totalCount || 0;

  const data = normalizeInboxItems(todo);
  const title =
    totalItems === 1
      ? formatMessage('widgets.todosDrawer.section.overdueBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        })
      : formatMessage('widgets.todosDrawer.section.overdueBills.plural.title', {
          count: totalItems,
        });

  const onClickAction = async () => {
    await onSwitchOrganization({
      organizationId: clientOrganizationId,
      keepLoadingState: true,
      switchAccessTokenOnly: true,
    });
    goToPayDashboardBillsTab({ refresh: true, id: totalItems === 1 ? todo?.items.data[0]?.payload.id : undefined });
  };
  const action = createViewBillsAction(totalItems, () => void onClickAction());

  return (
    <TodoSection
      key={`section-${TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}`}
      icon={<StatusIconSolid variant="warning" size="medium" />}
      title={title}
      action={action}
      type={TodoOverdueInboxItemsTypeEnum.OverdueInboxItems}
    />
  );
};
