import { Bill } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import compact from 'lodash/compact';

import { EbillAmountDropdownOption } from './types';

type BillPaymentOptions = {
  amountDue: number;
  minimumAmount: number;
  accountBalance: number;
  customAmount: number;
};
export const getBillPaymentOptions = (bill: Bill): BillPaymentOptions => ({
  amountDue: bill.eBillAmountDue ?? 0,
  minimumAmount: bill.minimumAmount ?? 0,
  accountBalance: bill.billerAccountBalance ?? 0,
  customAmount: bill.amount,
});

export const useEditAmountFormOptions = ({ bill }: { bill: Bill }) => {
  const amounts = getBillPaymentOptions(bill);
  const { formatCurrency, formatMessage } = useMelioIntl();
  const options: Array<EbillAmountDropdownOption> = compact([
    amounts.amountDue
      ? {
          value: 'amountDue',
          label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.amountDue.label', {
            amount: formatCurrency(amounts['amountDue']),
          }),
          description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.amountDue.description'),
          testId: `amountDue`,
        }
      : undefined,
    bill.billerAccountBalance
      ? {
          value: 'accountBalance',
          label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.accountBalance.label', {
            amount: formatCurrency(amounts['accountBalance']),
          }),
          description: formatMessage(
            'activities.paymentFlow.form.content.amountToPay.ebill.accountBalance.description'
          ),
          testId: `accountBalance`,
        }
      : undefined,
    bill.minimumAmount
      ? {
          value: 'minimumAmount',
          label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.minimumAmount.label', {
            amount: formatCurrency(amounts['minimumAmount']),
          }),
          description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.minimumAmount.description'),
          testId: `minimumAmount`,
        }
      : undefined,
    {
      value: 'customAmount',
      label: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.customAmount.label'),
      description: formatMessage('activities.paymentFlow.form.content.amountToPay.ebill.customAmount.description'),
      testId: `customAmount`,
    },
  ]);

  return options;
};

export const getDefaultEBillPayOption = (amountOptions: BillPaymentOptions): EbillAmountDropdownOption['value'] => {
  if (amountOptions.amountDue > 0) {
    return 'amountDue';
  }
  if (amountOptions.minimumAmount > 0) {
    return 'minimumAmount';
  }
  if (amountOptions.accountBalance > 0) {
    return 'accountBalance';
  }

  return 'customAmount';
};
