import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import { useAnalytics, WithAnalyticsContextProps } from '@melio/platform-analytics';
import {
  Bill,
  BillSubscription,
  DeliveryMethod,
  FundingSource,
  Payment,
  PaymentCalendarCalculationDates,
  Vendor,
} from '@melio/platform-api';
import { useEffect } from 'react';

import { PaymentFlowFormFields } from '../types';

type PaymentFlowAnalyticsParams = PaymentFlowFormFields & {
  setAnalyticsProperties: WithAnalyticsContextProps['setAnalyticsProperties'];
  payment: Payment | undefined;
  bill: Bill | undefined;
  billSubscription: BillSubscription | undefined;
  vendor: Vendor | undefined;
  fundingSource: FundingSource | undefined;
  deliveryMethod: DeliveryMethod | undefined;
  selectedPaymentDates: PaymentCalendarCalculationDates | undefined;
};

export const usePaymentFlowAnalytics = (params: PaymentFlowAnalyticsParams) => {
  const { track } = useAnalytics();
  const {
    setAnalyticsProperties,
    payment,
    bill,
    billSubscription,
    vendor,
    fundingSource,
    deliveryMethod,
    deliveryPreference,
    selectedPaymentDates,
    amountToPay: _amountToPay,
    recurrenceType,
    numOfOccurrences,
    intervalType,
    endPolicy,
    endDate,
  } = params;

  const scheduleDateForAnalytics = selectedPaymentDates?.scheduleDate?.toISOString();
  const deliveryDateForAnalytics = selectedPaymentDates?.minDeliveryDate?.toISOString();
  const endDateForAnalytics = endDate?.toISOString();

  const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);

  const amountToPay = _amountToPay ? Number(_amountToPay) : undefined;

  useEffect(() => {
    const globalAnalyticProperties = {
      PageName: 'pay',
      Flow: 'single-payment',
      ProductName: 'ap',
      PaymentId: payment?.id,
      BillId: bill?.id,
      BillAmount: bill?.amount,
      BillSubscriptionId: billSubscription?.id,
      VendorId: vendor?.id,
      IsManagedVendor: vendor?.isManaged,
      PaymentAmount: amountToPay,
      PaymentMethodId: fundingSource?.id,
      PaymentMethodType: fundingSource?.type,
      DeliveryMethodId: deliveryMethod?.id,
      DeliveryMethodType: deliveryMethod?.type,
      IsPartialPayment: !!bill?.amount && !!amountToPay && amountToPay !== bill.amount,
      IsRecurring: recurrenceType === 'recurring',
      DeductionDate: scheduleDateForAnalytics,
      ...(recurrenceType === 'recurring'
        ? {
            StartDate: deliveryDateForAnalytics,
            EndDate: endDateForAnalytics,
            PaymentFrequency: intervalType,
            PaymentDuration: endPolicy,
            NumOfOccurrences: numOfOccurrences,
          }
        : {
            DeliveryDate: deliveryDateForAnalytics,
          }),

      UserMessage: !isVendorDirectoryInfoCompleted ? 'missing-vendor-details' : undefined,
    };

    setAnalyticsProperties(globalAnalyticProperties);
    track('Payment', 'View', globalAnalyticProperties);
  }, [
    payment,
    billSubscription,
    bill?.id,
    bill?.amount,
    vendor?.id,
    vendor?.isManaged,
    amountToPay,
    fundingSource?.id,
    fundingSource?.type,
    deliveryMethod?.id,
    deliveryMethod?.type,
    deliveryPreference,
    recurrenceType,
    scheduleDateForAnalytics,
    deliveryDateForAnalytics,
    endDateForAnalytics,
    intervalType,
    endPolicy,
    numOfOccurrences,
    isVendorDirectoryInfoCompleted,
    setAnalyticsProperties,
    track,
  ]);
};
