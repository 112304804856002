import { Flex } from '@chakra-ui/react';
import { formatPhone } from '@melio/ap-domain';
import { Avatar, Container, Divider, Group, Icon, Text, useBreakpointValue } from '@melio/penny';
import { formatAddress, MsnBusiness } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

type MsnBusinessPreviewCardProps = {
  msnBusiness: MsnBusiness;
};

type LabeledTextProps = {
  label: string;
  text: string;
};

const LabeledText = ({ label, text }: LabeledTextProps) => (
  <Group spacing="xxs" variant="vertical">
    <Text textStyle="body4Semi" color="semantic.text.secondary">
      {label}
    </Text>
    <Flex wordBreak="break-word">
      <Text textStyle="body2">{text}</Text>
    </Flex>
  </Group>
);

export const MsnBusinessPreviewCard = ({ msnBusiness }: MsnBusinessPreviewCardProps) => {
  const { formatMessage } = useMelioIntl();

  const { name, contact } = msnBusiness;
  const hasDetails = !!contact && (!!contact.name || !!contact.address || !!contact.email || !!contact.phoneNumber);

  const formattedAddress = contact?.address ? formatAddress(contact.address) : undefined;
  const formattedPhoneNumber = formatPhone(contact?.phoneNumber);

  const padding = useBreakpointValue<'m' | 'l'>({ xs: 'm', s: 'l' });

  return (
    <Container border="regular" paddingX={padding} paddingY={padding} data-testid="msn-business-preview-card">
      <Group spacing="s" variant="horizontal" alignItems="center">
        <Avatar name={name ?? ''} badge={<Icon type="verified" size="small" color="brand" />} />
        <Text as="h2" textStyle="body2Semi">
          {name}
        </Text>
      </Group>
      {hasDetails && (
        <>
          <Container paddingY="m">
            <Divider />
          </Container>
          <Group spacing="m" variant="vertical">
            <Text as="h3" textStyle="body4SemiUpper">
              {formatMessage('widgets.msnBusinessPreviewCard.title')}
            </Text>
            {formattedAddress && (
              <LabeledText
                label={formatMessage('widgets.msnBusinessPreviewCard.label.address')}
                text={formattedAddress}
              />
            )}
            {contact?.email && (
              <LabeledText label={formatMessage('widgets.msnBusinessPreviewCard.label.email')} text={contact.email} />
            )}
            {contact?.name && (
              <LabeledText label={formatMessage('widgets.msnBusinessPreviewCard.label.name')} text={contact.name} />
            )}
            {formattedPhoneNumber && (
              <LabeledText
                label={formatMessage('widgets.msnBusinessPreviewCard.label.phoneNumber')}
                text={formattedPhoneNumber}
              />
            )}
          </Group>
        </>
      )}
    </Container>
  );
};
