import React from 'react';
import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';

import { InitRemoteLogger } from '@/utils/error-tracking';
import App from './App';

import './index.css';

InitRemoteLogger();

const root = createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <App />
    </RecoilRoot>
  </React.StrictMode>,
);
