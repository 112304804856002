import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';

export const Footer = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    data-component="NewSinglePaymentStepLayout.Footer"
    display="flex"
    justifyContent="space-between"
    width="100%"
    marginX="auto"
    gap="m"
    {...props}
    ref={ref}
  />
));

Footer.displayName = 'NewSinglePaymentStepLayout.Footer';
