import { selectRadioButton } from '@melio/test-utils';
import { screen, within } from '@testing-library/react';
import { PointerEventsCheckLevel, userEvent } from '@testing-library/user-event';

import { SubscriptionCheckoutFreeTrialTrackerDriver } from '../../../components/SubscriptionCheckoutSummary/components/SubscriptionCheckoutFreeTrialTracker/__tests__/SubscriptionCheckoutFreeTrialTracker.driver';

const testIds = {
  submitButton: 'subscription-checkout-submit',
  promoCodeApplyButton: 'subscription-checkout-apply-promocode',
  promoCodeInput: 'promoCode-render-field',
  togglePromoCode: 'subscription-checkout-toggle-promocode',
  promoCodeForm: 'subscription-checkout-promocode-form',
  currentPromoCode: 'subscription-checkout-promocode',
  currentPromoCodeDiscount: 'subscription-checkout-promocode-discount',
  currentPromoCodeUpToPeriod: 'subscription-checkout-promocode-up-to-periods',
  currentPromoCodeMessage: 'subscription-checkout-promocode-message',
  errorPromoCode: 'promoCode-error-message',
  removePromoCode: 'subscription-checkout-promocode-remove',
  checkoutContainer: 'subscription-checkout-screen',
  planTitle: 'subscription-checkout-plan-title',
  monthlyRadioButton: 'checkoutBillingCycle-monthly',
  yearlyRadioButton: 'checkoutBillingCycle-annual',
  backButton: 'back-button',
  closeButton: 'close-button',
  goToDashboard: 'subscription-success-page-activity-go-to-dashboard-button',
  withTaxes: 'subscription-checkout-taxes',
  noTaxes: 'subscription-checkout-no-taxes',
  totalAmount: 'subscription-checkout-total',
  additionalSeats: 'subscription-checkout-additional-seats',
  freeTrialBadge: 'free-trial-badge',
  chargedImmediatelyDetails: 'subscription-checkout-charged-immediately-details',
  checkoutDisclaimer: 'subscription-checkout-disclaimer',
  screenTitle: 'subscription-checkout-screen-title',
  subtotal: 'subscription-checkout-subtotal',
  addBankFundingSourceButton: 'funding-source-type-card-bank',
  addCardFundingSourcebutton: 'funding-source-type-card-credit-card',
  submitCardFundingSourcrButton: 'submit-card-details-button',
};
export class SubscriptionCheckoutDriver {
  freeTrialTrackerDriver = new SubscriptionCheckoutFreeTrialTrackerDriver();

  getSubmitButton = () => screen.getByTestId(testIds.submitButton);
  getTogglePromoCode = () => screen.queryByTestId(testIds.togglePromoCode);
  getApplyPromoCodeButton = () => screen.getByTestId(testIds.promoCodeApplyButton);
  getPromoCodeForm = () => screen.queryByTestId(testIds.promoCodeForm);
  getPromoCodeInput = () => screen.queryByTestId(testIds.promoCodeInput);
  getCurrentPromoCode = () => screen.queryByTestId(testIds.currentPromoCode);
  getErrorPromoCode = () => screen.queryByTestId(testIds.errorPromoCode);
  clickTogglePromoCode = async () => {
    const togglePromoCode = this.getTogglePromoCode();

    if (togglePromoCode) {
      await userEvent.click(togglePromoCode);
    }
  };
  enterPromoCode = async (promoCode: string) => {
    const promoCodeInput = this.getPromoCodeInput();

    if (promoCodeInput) {
      await userEvent.type(promoCodeInput, promoCode);
    }
  };
  clickApplyPromoCode = async () => {
    const promoCodeApplyButton = this.getApplyPromoCodeButton();
    await userEvent.click(promoCodeApplyButton, { pointerEventsCheck: PointerEventsCheckLevel.Never });
  };
  getCheckoutContainer = () => screen.queryByTestId(testIds.checkoutContainer);
  getPlanTitle = () => screen.getByTestId(testIds.planTitle);
  getMonthlyRadioButton = () => screen.queryByTestId(testIds.monthlyRadioButton);
  getYearlyRadioButton = () => screen.queryByTestId(testIds.yearlyRadioButton);
  getBackButton = () => screen.getByTestId(testIds.backButton);
  getNoTaxesMessage = () => screen.queryByTestId(testIds.noTaxes);
  getWithTaxesMessage = () => screen.queryByTestId(testIds.withTaxes);
  getTotalAmount = () => screen.queryByTestId(testIds.totalAmount);
  getAdditionalSeats = () => screen.queryByTestId(testIds.additionalSeats);
  getFreeTrialTrackerDriver = () => this.freeTrialTrackerDriver;
  getChargedImmediatelyDetails = () => screen.queryByTestId(testIds.chargedImmediatelyDetails);
  getFreeTrialBadge = () => screen.queryByTestId(testIds.freeTrialBadge);
  getCheckoutDisclaimer = () => screen.queryByTestId(testIds.checkoutDisclaimer);
  getScreenTitle = () => screen.queryByTestId(testIds.screenTitle);
  clickYearlyRadioButton = () => selectRadioButton('checkoutBillingCycle', 'annual');
  getErrorBanner = () => screen.queryByTestId('subscription-checkout-error-banner');
  getErrorBannerDescription = () => screen.queryByTestId('subscription-checkout-error-banner-description');
  getErrorBannerTitle = () => screen.queryByTestId('subscription-checkout-error-banner-title');
  getCloseButton = () => within(screen.getByTestId(testIds.checkoutContainer)).getByTestId(testIds.closeButton);
  getSubtotal = () => screen.queryByTestId(testIds.subtotal);
}

export class SubscriptionCheckoutCypressDriver {
  getSubmitButton = () => cy.getByTestId(testIds.submitButton);
  getCheckoutContainer = () => cy.getByTestId(testIds.checkoutContainer);
  getPlanTitle = () => cy.getByTestId(testIds.planTitle);
  getMonthlyRadioButton = () => cy.getByTestId(testIds.monthlyRadioButton);
  getYearlyRadioButton = () => cy.getByTestId(testIds.yearlyRadioButton);
  getSuccessPageCta = () => cy.getByTestId(testIds.goToDashboard);
  getPromoCodeForm = () => cy.getByTestId(testIds.promoCodeForm);
  getPromoCodeFormToggle = () => cy.getByTestId(testIds.togglePromoCode);
  getPromoCodeInput = () => cy.getByTestId(testIds.promoCodeInput);
  enterPromoCode = (promoCode: string) => {
    this.getPromoCodeInput().type(promoCode);
  };
  getApplyPromoCodeButton = () => cy.getByTestId(testIds.promoCodeApplyButton);
  clickApplyPromoCode = () => {
    this.getApplyPromoCodeButton().click();
  };
  getCurrentPromoCode = () => cy.getByTestId(testIds.currentPromoCode);

  getCurrentPromoCodeDiscount = () => cy.getByTestId(testIds.currentPromoCodeDiscount);
  getCurrentPromoCodeUpToPeriod = () => cy.getByTestId(testIds.currentPromoCodeUpToPeriod);
  getCurrentPromoCodeMessage = () => cy.getByTestId(testIds.currentPromoCodeMessage);
  getErrorPromoCode = () => cy.getByTestId(testIds.errorPromoCode);
  removePromoCode = () => cy.getByTestId(testIds.removePromoCode).click();
  getAddBankFundingSourceButton = () => cy.getByTestId(testIds.addBankFundingSourceButton);
  getAddCardFundingSourceButton = () => cy.getByTestId(testIds.addCardFundingSourcebutton);
  getSubmitCardFundingSourcrButton = () => cy.getByTestId(testIds.submitCardFundingSourcrButton);

  clickCloseButton = () => cy.getByTestId(testIds.closeButton).click();
  clickBackButton = () => cy.getByTestId(testIds.backButton).click();
}
