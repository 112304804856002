import { convertCentsToDollars } from '@melio/platform-utils';

import { FeeCatalog } from './types';

export const convertFeeCatalogEntryCentsToDollars = (data: FeeCatalog) => {
  const convertedValues = ['cap', 'min'];
  if (data.valueType === 'fixed') {
    convertedValues.push('value');
  }
  return convertCentsToDollars(data, convertedValues);
};

export const convertFeeCatalogsCentsToDollars = (data: FeeCatalog[]) => data.map(convertFeeCatalogEntryCentsToDollars);
