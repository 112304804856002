import { Box } from '@chakra-ui/react';
import { BaseModal, BottomSheet, Container, Group, useTheme } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { LazyAnimation } from '@melio/platform-utils';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { useIsMobile } from '../../../../../utils/viewport-utils/useIsMobile';
import { MigratedUserExperienceBodyDesktop } from './MigratedUserExperienceBodyDesktop';
import { MigratedUserExperienceBodyMobile } from './MigratedUserExperienceBodyMobile';

type MigratedUserExperienceModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const MigratedUserExperienceModal = withAnalyticsContext<MigratedUserExperienceModalProps>(
  ({ isOpen, onClose, setAnalyticsProperties }) => {
    const { track } = useAnalytics();
    const isMobile = useIsMobile();
    const { colors } = useTheme();
    const { formatMessage } = useMelioIntl();

    setAnalyticsProperties({
      PageName: 'fte-guide',
      Flow: 'fte-new-user',
      Intent: 'fte-guide',
      EntryPoint: 'login-page',
    });

    const {
      settings: { migrationUserTour },
    } = useConfig();

    const ref = useRef<HTMLDivElement>(null);
    const removeX = useRef<boolean>(false);

    useEffect(() => {
      if (ref.current && !removeX.current) {
        if (isMobile) {
          const xButton = ref.current.querySelector('[data-testid="sheet-close-button"]') as HTMLElement;
          if (xButton) {
            xButton.style.display = 'none';
            removeX.current = true;
          }
        } else {
          //get x button to disappear it component called data-testid=modal-close-button
          const xButton = ref.current.querySelector('[data-testid="modal-close-button"]') as HTMLElement;
          const firstChild = ref.current.firstChild as HTMLElement;

          if (xButton && firstChild) {
            firstChild.style.paddingBottom = '0px';
            xButton.style.display = 'none';
            removeX.current = true;
          }
        }
      }
    });

    useEffect(() => {
      isOpen && track('Dashboard', 'View');
    }, [isOpen, track]);

    const onCloseCallback = useCallback(() => {
      track('Dashboard', 'Click', {
        Cta: 'see-whats-new',
      });
      onClose();
    }, [onClose, track]);

    const ariaLabelTitle = formatMessage(
      isMobile
        ? 'activities.migrationUserDashboardTour.modal.mobile.title'
        : 'activities.migrationUserDashboardTour.modal.title'
    );

    if (isMobile) {
      return (
        <BottomSheet
          ref={ref}
          isOpen={isOpen}
          onClose={onCloseCallback}
          data-testid="pay-dashboard-migrated-user-experience-modal-mobile"
        >
          <MigratedUserExperienceBodyMobile onClose={onCloseCallback} animationSrc={migrationUserTour.modalImage} />
        </BottomSheet>
      );
    }
    return (
      <BaseModal
        ref={ref}
        data-testid="pay-dashboard-migrated-user-experience-modal"
        isOpen={isOpen}
        onClose={noop}
        size="medium"
        aria-label={ariaLabelTitle}
      >
        <Group variant="horizontal" justifyContent="space-between" spacing="none" hasDivider>
          <MigratedUserExperienceBodyDesktop onClose={onCloseCallback} />
          <Box backgroundColor={colors.global.brand[100]} flex="0 0 400px">
            <Container width="fit-content" height="full" paddingTop="xxl" paddingBottom="xxl">
              <LazyAnimation
                id="pay-dashboard-migrated-user-experience-modal-animation"
                src={migrationUserTour.modalImage}
                loop
                width="352px"
                height="100%"
                aria-hidden
              />
            </Container>
          </Box>
        </Group>
      </BaseModal>
    );
  }
);
