import { FormLayout, OwnerJobTitle, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Button, Group, NakedButton, useFormSubmissionController } from '@melio/penny';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import { uniqueId } from 'lodash';
import { createRef as reactCreateRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ActivationLayout } from '../../../layouts';
import {
  CompanyOwnersForm,
  CompanyOwnersFormFields,
  ControlPersonDetailsForm,
  ControlPersonDetailsFormFields,
  OwnerDetailsForm,
  useCompanyOwnersForm,
} from '../components';
import {
  BusinessOwnerForm,
  BusinessOwnerFormRef,
  OwnerDetailsFormValues,
  OwnerDetailsFormValuesWithRefId,
} from '../types';

const MAX_OWNERS = 4;

const createBusinessOwnerFormRef = (type: BusinessOwnerFormRef['type']): BusinessOwnerFormRef => ({
  id: uniqueId('ref'),
  formRef: reactCreateRef<BusinessOwnerForm>(),
  visualRef: reactCreateRef<HTMLDivElement>(),
  type,
});

export type OwnershipDetailsScreenProps = {
  isSaving?: boolean;
  isLoading?: boolean;
  defaultValues?: Partial<OwnerDetailsFormValues>[];
  controlPersonDefaultValues?: Partial<OwnerDetailsFormValues>;
  companyOwnersDefaultValues?: Partial<CompanyOwnersFormFields>;
  onSubmit: (data: OwnerDetailsFormValues[]) => void;
  onBack: VoidFunction;
  onClose: VoidFunction;
};

export const OwnershipDetailsScreen = forwardRef<OwnershipDetailsScreenProps>(
  (
    {
      onSubmit: _onSubmit,
      isSaving,
      onBack,
      onClose,
      defaultValues: _defaultValues,
      controlPersonDefaultValues,
      companyOwnersDefaultValues,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();

    const [defaultValues, setDefaultValues] = useState(_defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setDefaultValues(_defaultValues), [JSON.stringify(_defaultValues)]);
    const removeDefaultValueByIndex = useCallback(
      (index: number) => setDefaultValues((current) => current?.filter((_, i) => i !== index)),
      []
    );

    const [UBOs, setUBOs] = useState<OwnerDetailsFormValuesWithRefId[]>();
    const setUBO = (values: OwnerDetailsFormValuesWithRefId) => {
      setUBOs((current = []) => {
        if (current.some((form) => form.refId === values.refId)) {
          return current.map((current) => (current.refId === values.refId ? values : current));
        }
        return current.concat(values);
      });
    };
    const removeUBO = (refId: string) => setUBOs((current = []) => current.filter((form) => form.refId !== refId));

    const [formRefs, setFormRefs] = useState<BusinessOwnerFormRef[]>([createBusinessOwnerFormRef('ubo')]);

    const removeFormActionCreator = useCallback(
      (formId: string, index: number) => () => {
        removeDefaultValueByIndex(index);
        removeUBO(formId);
        setFormRefs((formRefs) => formRefs.filter((ref) => ref.id != formId));
      },
      [removeDefaultValueByIndex]
    );

    const addForm = useCallback((...args: Parameters<typeof createBusinessOwnerFormRef>) => {
      const newForm = createBusinessOwnerFormRef(...args);
      setFormRefs((formRefs) => [...formRefs, newForm]);
      setTimeout(() => newForm.visualRef.current?.scrollIntoView({ behavior: 'smooth' }), 0);
    }, []);

    useEffect(() => {
      if (defaultValues?.length && formRefs.length < defaultValues.length) {
        defaultValues.slice(formRefs.length).forEach(() => addForm('ubo'));
      }
    }, [addForm, defaultValues, formRefs]);

    const controlPersonFormRef = useRef(createBusinessOwnerFormRef('director'));
    const controlPersonForm = useFormSubmissionController<ControlPersonDetailsFormFields>();
    const [controlPersonFormValues, setControlPersonFormValues] = useState<ControlPersonDetailsFormFields>();

    const isNewControlPerson = controlPersonFormValues?.formId === 'other';
    useUpdateEffect(() => {
      if (isNewControlPerson) {
        controlPersonFormRef.current.visualRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, [isNewControlPerson]);

    const { isInitialState, companyOwnersForm, hasOwners, isSoleOwner, noOwners } = useCompanyOwnersForm({
      isSaving,
      defaultValues: companyOwnersDefaultValues,
    });

    useEffect(() => {
      if (hasOwners && formRefs.length === 0) {
        addForm('ubo');
        defaultValues?.forEach((values, index) => {
          const refId = formRefs[index]?.id;
          if (refId) setUBO({ ...values, refId, type: 'ubo' });
        });
      }
    }, [addForm, defaultValues, formRefs, hasOwners]);

    useEffect(() => {
      if (noOwners) {
        setUBOs([]);
        setFormRefs([]);
      }
    }, [noOwners]);

    const onSubmit = () => {
      const forms = formRefs.map(({ formRef, id }) => {
        const isDirector = id === controlPersonFormValues?.formId;
        formRef.current?.setValue('isDirector', isDirector);
        if (isDirector) formRef.current?.setValue('jobTitle', controlPersonFormValues.role);
        return formRef.current as BusinessOwnerForm;
      });

      if (isNewControlPerson) {
        forms.push(controlPersonFormRef.current.formRef.current as BusinessOwnerForm);
      }

      controlPersonForm.submitButtonProps?.onClick();
      forms.reverse().forEach((form) => form.submitButtonProps.onClick());

      const isValid = (controlPersonForm.formState?.isValid ?? true) && forms.every((form) => form.formState.isValid);

      if (isValid) {
        _onSubmit(forms.reverse().map((form) => form.getValues()));
      }
    };

    const isMobile = useIsMobile();

    useEffect(() => {
      if (isSoleOwner) {
        setUBOs((current) => current?.slice(0, 1));
        setFormRefs((current) => current.slice(0, 1));
      }
    }, [isSoleOwner]);

    return (
      <ActivationLayout
        {...props}
        ref={ref}
        step="ownership-details"
        primaryButtonProps={{ onClick: onSubmit, isLoading: isSaving, isDisabled: isInitialState }}
        secondaryButtonProps={{ onClick: onBack, isDisabled: isSaving }}
        tertiaryButtonProps={{ onClick: onClose, isDisabled: isSaving }}
        header={{
          title: formatMessage('ar.onboarding.activities.ownershipDetails.header.title.text'),
          description: formatMessage('ar.onboarding.activities.ownershipDetails.header.description.text'),
        }}
      >
        <Group variant="vertical" spacing={isMobile ? 'm' : 'l'} width="full" hasDivider={!isInitialState}>
          <CompanyOwnersForm form={companyOwnersForm} />

          {hasOwners && (
            <FormLayout.Section>
              <Group variant="vertical" spacing={isMobile ? 'm' : 'l'} hasDivider>
                {formRefs.map((formRef, index) => (
                  <Group key={formRef.id} variant="vertical" spacing="s">
                    <OwnerDetailsForm
                      defaultValues={defaultValues?.[index]}
                      formRef={formRef}
                      onChange={setUBO}
                      isSaving={isSaving}
                      onSubmit={() => null}
                      data-testid={`owner-details-form-${index}`}
                    />
                    <Button
                      variant="secondary"
                      isDisabled={isSaving}
                      hidden={formRefs.length <= 1 || isSoleOwner}
                      onClick={removeFormActionCreator(formRef.id, index)}
                      label={formatMessage('ar.onboarding.activities.ownershipDetails.buttons.removeOwner.label')}
                      data-testid={`remove-owner-button-${index}`}
                    />
                  </Group>
                ))}
              </Group>
              <NakedButton
                variant="secondary"
                isDisabled={isSaving}
                hidden={isSoleOwner || formRefs.length >= MAX_OWNERS}
                label={formatMessage('ar.onboarding.activities.ownershipDetails.buttons.addOwner.label')}
                onClick={() => addForm('ubo')}
                data-testid="add-owner-button"
              />
            </FormLayout.Section>
          )}

          <FormLayout.Section isHidden={isInitialState}>
            <Group variant="vertical" spacing="xl">
              <ControlPersonDetailsForm
                hasOwners={!!formRefs.length}
                onChange={setControlPersonFormValues}
                onSubmissionStateChange={controlPersonForm.onSubmissionStateChange}
                isSaving={isSaving}
                ubos={UBOs?.filter((ubo) => ubo.firstName?.trim() || ubo.lastName?.trim())}
                onSubmit={() => null}
                defaultValues={useControlPersonDetailsFormDefaultValues({
                  formRefs,
                  controlPersonDefaultValues,
                  defaultValues,
                })}
                data-testid="owner-details-form-5"
              />
              {isNewControlPerson && (
                <OwnerDetailsForm
                  formRef={controlPersonFormRef.current}
                  onChange={setUBO}
                  isSaving={isSaving}
                  onSubmit={() => null}
                  data-testid="control-person-details-form"
                  defaultValues={controlPersonDefaultValues ?? { jobTitle: controlPersonFormValues.role }}
                />
              )}
            </Group>
          </FormLayout.Section>
        </Group>
      </ActivationLayout>
    );
  }
);

type GetControlPersonDetailsFormProps = {
  controlPersonDefaultValues?: Partial<OwnerDetailsFormValues>;
  defaultValues?: Partial<OwnerDetailsFormValues>[];
  formRefs: BusinessOwnerFormRef[];
};

const useControlPersonDetailsFormDefaultValues = ({
  controlPersonDefaultValues,
  defaultValues,
  formRefs,
}: GetControlPersonDetailsFormProps): Partial<ControlPersonDetailsFormFields> => {
  const formId = useMemo(
    () =>
      controlPersonDefaultValues
        ? 'other'
        : defaultValues?.reduce<string | undefined>((acc, owner, index) => {
            if (owner.isDirector) acc = formRefs[index]?.id;
            return acc;
          }, undefined),
    [controlPersonDefaultValues, defaultValues, formRefs]
  );

  const role = useMemo(
    () =>
      controlPersonDefaultValues
        ? (controlPersonDefaultValues.jobTitle as OwnerJobTitle)
        : defaultValues?.reduce<OwnerJobTitle | undefined>(
            (acc, owner) => (owner.isDirector ? (owner.jobTitle as OwnerJobTitle) : acc),
            undefined
          ),
    [controlPersonDefaultValues, defaultValues]
  );

  return useMemo(() => ({ formId, role }), [formId, role]);
};
