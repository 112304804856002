import { AREnrollmentStatusEnum, useOrganizationEnrollmentStatus } from './api-hooks';

export const EnrollmentStatus = {
  Active: AREnrollmentStatusEnum.Active,
  Failed: AREnrollmentStatusEnum.Failed,
  NotSubmitted: AREnrollmentStatusEnum.NotSubmitted,
} as const;
export type EnrollmentStatus = (typeof EnrollmentStatus)[keyof typeof EnrollmentStatus];

export const useEnrollmentStatus = (): EnrollmentStatus => {
  const { data } = useOrganizationEnrollmentStatus();
  switch (data?.status) {
    case 'failed':
      return EnrollmentStatus.Failed;
    case 'notSubmitted':
      return EnrollmentStatus.NotSubmitted;
    case 'active':
    case 'pending':
    case 'awaitMoreInfo':
      return EnrollmentStatus.Active;
    default:
      return EnrollmentStatus.Active;
  }
};
