import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Tier } from '@melio/subscriptions';

import { ManageClientFormFields, ManageClientFormStep, useManageClientForm } from '../../../../components';
import { ManageClientForm } from '../../../../components/ManageClientForm/ManageClientForm';
import { useFirmClient } from '../../../../hooks';
import { useGetAccountingFirmClientPlanByTier } from '../../../../hooks/useGetAccountingFirmClientPlanByTier';
import { SubscriptionBillingPayor } from '../../../../types';
import { useAssignFirmClientPlanFormSchema, useAssignFirmClientPlanFormStepManager } from '../../hooks';

type AssignFirmClientPlanFormProps = {
  organizationId: string;
  onSubmit: (fields: ManageClientFormFields) => Promise<void>;
  isSaving: boolean;
};

export const AssignFirmClientPlanForm = ({ organizationId, onSubmit, isSaving }: AssignFirmClientPlanFormProps) => {
  const defaultAccountingFirmClientPlan = useGetAccountingFirmClientPlanByTier(Tier.MelioAccountingClientCore);
  const { formatMessage } = useMelioIntl();
  const { data: firmAccount } = useAccount({ id: 'me' });
  const { data: client } = useFirmClient(organizationId);

  const form = useManageClientForm({
    isSaving,
    onSubmit,
    schema: useAssignFirmClientPlanFormSchema(),
    defaultValues: {
      whoPays: SubscriptionBillingPayor.Client,
      subscriptionPlanId: defaultAccountingFirmClientPlan?.id || '',
      fundingSourceId: undefined,
      organizationId,
    },
  });

  const stepManager = useAssignFirmClientPlanFormStepManager({
    defaultActiveId: ManageClientFormStep.BillingDetails,
    clientName: client?.organization.name ?? '',
    firmName: firmAccount?.company.name ?? '',
    isFirmBilled: form.watch('whoPays') === SubscriptionBillingPayor.AccountingFirm,
  });

  return (
    <ManageClientForm
      form={form}
      isSaving={isSaving}
      stepManager={stepManager}
      labels={{ submit: formatMessage(`activities.accountants.assignClientPlan.form.submitButton.label`) }}
    />
  );
};
