import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo } from 'react';

import { ManageClientFormStep, ManageClientFormStepPayload } from '../../../components';
import { useStepManager } from '../../../hooks';

export const useAssignFirmClientPlanFormStepManager = ({
  defaultActiveId,
  clientName,
  isFirmBilled,
  firmName,
}: {
  isFirmBilled: boolean;
  defaultActiveId?: ManageClientFormStep;
  clientName: string;
  firmName: string;
}) => {
  const { formatMessage } = useMelioIntl();

  const stepsData = useMemo(
    () => [
      {
        stepId: ManageClientFormStep.BillingDetails,
        description: formatMessage(
          'activities.accountants.manageClient.form.billingDetails.assignPlan.description.text',
          {
            clientName,
            firmName,
          }
        ),
      },
      {
        stepId: ManageClientFormStep.PlanSelection,
      },
      {
        stepId: ManageClientFormStep.ReviewAndConfirm,
        description: formatMessage(
          isFirmBilled
            ? `activities.accountants.manageClient.form.reviewAndConfirm.description.text.firmBilled.assignPlan`
            : `activities.accountants.manageClient.form.reviewAndConfirm.description.text.clientBilled.assignPlan`,
          { clientName, firmName }
        ),
      },
    ],
    [clientName, firmName, formatMessage, isFirmBilled]
  );

  const { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep } = useStepManager<
    ManageClientFormStep,
    ManageClientFormStepPayload
  >({
    data: stepsData,
    defaultActiveId,
  });

  return { steps, expandedStep, expandSelectedStep, goToNextStep, focusErrorStep };
};
