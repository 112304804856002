import { useAccount, useIsAccountingClient } from '@melio/platform-api';

import { useSubscriptionContext } from '../context';

export const useIsSubscriptionBillingPayorIsFirm = () => {
  const { plans, subscription } = useSubscriptionContext();
  const { data: account } = useAccount({ id: 'me' });

  const isAccountingClient = useIsAccountingClient();

  if (!subscription?.payingOrganization) {
    return false;
  }

  const currentPlan = plans?.find((plan) => plan.id === subscription.planId);

  if (!currentPlan) {
    return false;
  }

  const isFreePlan = currentPlan.cycles.monthly.basePrice === 0;

  if (isFreePlan) {
    return false;
  }

  const isSelfPaid = subscription?.payingOrganization.organizationId === account?.organizationId;

  const isSubscriptionPaidByFirm = isAccountingClient && !isSelfPaid;

  return isSubscriptionPaidByFirm;
};
