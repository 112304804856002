import {
  Address,
  ApiKeys,
  PostSearchMatchingVendorRequestBankAccount,
  PostSearchMatchingVendorResponseData,
  RecommendedVendorsApiClient,
} from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation, useQueryClient } from '@tanstack/react-query';

type RecommendedMatchingVendorsPayload = {
  vendorId: string;
  bankAccount?: PostSearchMatchingVendorRequestBankAccount;
  address?: Address;
};

export const useVendorRecommendationsById = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: (data?: PostSearchMatchingVendorResponseData) => void;
}) => {
  const queryClient = useQueryClient();
  const mutationKey = [ApiKeys.RecommendedVendorsApi, 'postRecommendedVendorsMatchingByVendorId'];
  const mutation = useMutation({
    mutationKey,
    mutationFn: async (data: RecommendedMatchingVendorsPayload) => {
      const result = await RecommendedVendorsApiClient.postRecommendedVendorsMatchingByVendorId(data.vendorId, {
        bankAccount: data.bankAccount,
        address: data.address,
      });
      return result.data.data[0];
    },
    onError: () => onError?.(),
    onSuccess: (data) => onSuccess?.(data),
  });

  return {
    ...mutation,
    isMutating: queryClient.isMutating({ mutationKey }) > 0,
  };
};
