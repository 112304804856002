import { OrganizationKeyIndividualValidations } from '@melio/compliance-validator';
import { InternationalAddress } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import * as yup from 'yup';

import { OTHER_DECISION_MAKER_KEY, Residency } from '../types';
import { useFxCommonValidations } from '../utils/formUtils';

export const useDecisionMakerFormSchema = () => {
  const { formatMessage } = useMelioIntl();
  const { riskValidationTest, addressSchema, residencySchema, riskDateOfBirthSchema } = useFxCommonValidations();
  const getSchema = () =>
    yup.object().shape({
      uboId: yup
        .string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.decisionMakerField.required'
          )
        ),
      companyRole: yup
        .string()
        .required(
          formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.decisionMakerDetailsCard.companyRoleField.required'
          )
        ),
      decisionMakerFirstName: whenDecisionMakerIsOther({
        then: yup
          .string()
          .test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualFirstName)),
      }),
      decisionMakerLastName: whenDecisionMakerIsOther({
        then: yup.string().test(riskValidationTest(OrganizationKeyIndividualValidations.validateKeyIndividualLastName)),
      }),
      decisionMakerDayOfBirth: whenDecisionMakerIsOther({
        then: riskDateOfBirthSchema,
      }),
      decisionMakerAddress: yup.object().optional().nullable().when('uboId', {
        is: isOtherUbo,
        then: addressSchema,
      }) as yup.SchemaOf<InternationalAddress>,
      decisionMakerResidencyDetails: yup.object().optional().nullable().when('uboId', {
        is: isOtherUbo,
        then: residencySchema,
      }) as yup.SchemaOf<Residency>,
    });

  const schema = getSchema();
  return schema;
};

const isOtherUbo = (id: string) => id === OTHER_DECISION_MAKER_KEY;

const whenDecisionMakerIsOther = ({ then }: { then: yup.AnySchema }) =>
  yup.mixed().when('uboId', {
    is: isOtherUbo,
    then,
    otherwise: yup.string().optional().nullable(),
  });
