import { AmountField, FormField, Table } from '@melio/penny';
import { Vendor } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { MAX_PAYMENT_AMOUNT_DIGITS } from '../../../../consts';
import { useFormContext } from '../../FormContext';

type Props = { vendor: Vendor; onChange: (vendor: Vendor, value?: string) => void };
export const AmountCell = ({ vendor, onChange }: Props) => {
  const { setValue, formState } = useFormContext();
  const { formatCurrency, formatMessage } = useMelioIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(`amounts.${vendor.id}`, e.target.value);
    onChange(vendor, e.target.value);
  };

  const errorMessage = formState.errors.amounts?.[vendor.id]?.message
    ? { message: formState.errors.amounts?.[vendor.id]?.message }
    : undefined;

  return (
    <Table.Cell data-testid={`vendors-batch-payments-modal-body-row-${vendor.id}-amount-cell`}>
      <FormField
        labelProps={{
          label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountCell.header', {
            vendorName: vendor.name,
            amount: formState.defaultValues?.amounts?.[vendor.id] || formatCurrency(0),
          }),
          isHidden: true,
        }}
        error={errorMessage}
        data-testid={`vendors-batch-payments-amounts-modal-field-${vendor.id}`}
        render={() => (
          <AmountField
            align="end"
            size="small"
            data-testid={`vendors-batch-payments-amounts-modal-input-${vendor.id}`}
            onChange={handleInputChange}
            allowNegativeValue={false}
            integerLimit={MAX_PAYMENT_AMOUNT_DIGITS}
            aria-label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.amountCell.header', {
              vendorName: vendor.name,
              amount: formState.defaultValues?.amounts?.[vendor.id] || formatCurrency(0),
            })}
          />
        )}
      />
    </Table.Cell>
  );
};
