import { object } from 'yup';

import { ManageClientFormSchema, useManageClientFormSchemaValidators } from '../../../components/ManageClientForm';

export const useUpdateClientBillingInfoFormSchema = () => {
  const { whoPays, fundingSourceId } = useManageClientFormSchemaValidators();

  return object().shape({
    whoPays,
    fundingSourceId,
  }) as unknown as ManageClientFormSchema;
};
