import { ApiPagination, ReceivablePayment, useIsMobile } from '@melio/ar-domain';
import { Group, LoadingContainer } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { PaymentActionsHandlers } from '../../../utils';
import { PaymentsEmptyState, PaymentsTable, PaymentsTableToolbar, PaymentsTableZeroState } from '../components';
import { FilterParams, OnSort, QueryParams, SortParams } from '../types';

export type PaymentsTableScreenProps = {
  payments: ReceivablePayment[];
  pagination: ApiPagination;
  tableActions: PaymentActionsHandlers;
  onSearch: (search: string) => void;
  searchTerm?: string;
  isLoading?: boolean;
  isFetching?: boolean;
  onError?: ARErrorFunction;
  onParamsChange: (filters: QueryParams) => void;
  onClearFilters: () => void;
  onClearAllParams: () => void;
  filters: FilterParams;
  sortParams?: SortParams;
  onCreateInvoice?: () => void;
  onExport?: VoidFunction;
};

export const PaymentsTabScreen = forwardRef<PaymentsTableScreenProps>(
  (
    {
      filters,
      isLoading,
      isFetching,
      tableActions,
      onParamsChange,
      onSearch,
      searchTerm,
      sortParams,
      onClearFilters,
      onClearAllParams,
      payments,
      onCreateInvoice,
      onExport,
      pagination,
    },
    ref
  ) => {
    const isResultsEmpty = !isFetching && payments.length === 0;
    const isFiltered = !isEmpty(filters) || !!searchTerm;
    const isZeroState = isResultsEmpty && !isFiltered;
    const isEmptyState = isResultsEmpty && isFiltered;
    const isMobile = useIsMobile();

    const handleSort: OnSort = (sortBy, sortOrder) => {
      onParamsChange({ sortBy, sortOrder });
    };

    return (
      <LoadingContainer isLoading={isLoading}>
        <Group
          width="full"
          variant="vertical"
          spacing={isMobile ? 's' : 'm'}
          data-component={PaymentsTabScreen.displayName}
          data-testid="invoices-tab-screen"
          ref={ref}
        >
          <PaymentsTableToolbar
            filters={filters}
            onSearch={onSearch}
            onParamsChange={onParamsChange}
            searchTerm={searchTerm}
            onClearFilters={onClearFilters}
            onSort={handleSort}
            sortParams={sortParams}
            onExport={onExport}
            shouldDisplayFilters={!isZeroState}
          />
          <PaymentsTable
            tableActions={tableActions}
            onRowClick={({ rowId }) => tableActions.onViewPayment(rowId)}
            isLoading={isFetching}
            onSort={handleSort}
            sortParams={sortParams}
            payments={payments}
            shouldDisplayTable={!isZeroState && !isEmptyState}
            pagination={pagination}
            ref={ref}
          />
          {isEmptyState && (
            <PaymentsEmptyState
              search={searchTerm}
              onClearSearch={() => {
                onClearAllParams();
              }}
              filters={filters}
            />
          )}
          {isZeroState && <PaymentsTableZeroState onCreateInvoice={onCreateInvoice} />}
        </Group>
      </LoadingContainer>
    );
  }
);

PaymentsTabScreen.displayName = 'PaymentsTabScreen';
