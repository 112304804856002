import { ZeroState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

export type ZeroStateProps = {
  onCreateInvoice?: () => void;
};

export const InvoicesTableZeroState = forwardRef<ZeroStateProps>(({ onCreateInvoice, ...props }, ref) => (
  <ZeroState
    ref={ref}
    illustrationType="create-invoice"
    primaryLabel="ar.dashboard.activities.invoiceTable.emptyState.no-data.primary.label"
    secondaryLabel="ar.dashboard.activities.invoiceTable.emptyState.no-data.secondary.label"
    actions={
      onCreateInvoice
        ? [
            {
              label: 'ar.dashboard.activities.invoiceTable.emptyState.create.button.label', // Changed from labelId to label
              onClick: onCreateInvoice,
              variant: 'primary',
              testId: 'zero-state-create-invoice-button',
            },
          ]
        : []
    }
    dataTestId="invoices-dashboard-no-invoices"
    {...props}
  />
));

InvoicesTableZeroState.displayName = 'InvoicesTableZeroState';
