import { StatusIconSolid } from '@melio/penny';
import {
  TodoOverdueIn7DaysInboxItems,
  TodoOverdueIn7DaysInboxItemsTypeEnum,
  TodoOverdueInMoreThan7DaysInboxItems,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../../utils/useAccountantsRoutes';
import { useTodosActions } from '../hooks/useTodosActions';
import { isEmptySection, normalizeInboxItems } from '../utils';
import { TodoSection } from './TodoSection';

export type TodoSectionOverdueInFutureInboxItemsProps = {
  overdueIn7Days?: TodoOverdueIn7DaysInboxItems;
  overdueInMoreThan7Days?: TodoOverdueInMoreThan7DaysInboxItems;
  clientOrganizationId: string;
  onSwitchOrganization: ({
    organizationId,
    skipPayDashboardRedirect,
    switchAccessTokenOnly,
    keepLoadingState,
  }: {
    organizationId: string;
    skipPayDashboardRedirect?: boolean;
    switchAccessTokenOnly?: boolean;
    keepLoadingState?: boolean;
  }) => Promise<void>;
};

export const TodoSectionOverdueInFutureInboxItems = ({
  clientOrganizationId,
  overdueIn7Days,
  overdueInMoreThan7Days,
  onSwitchOrganization,
}: TodoSectionOverdueInFutureInboxItemsProps) => {
  const { formatMessage } = useMelioIntl();
  const { createViewBillsAction } = useTodosActions();
  const { goToPayDashboardBillsTab } = useAccountantsRoutes();

  if (isEmptySection(overdueIn7Days) && isEmptySection(overdueInMoreThan7Days)) {
    return null;
  }

  const overdueIn7DaysTotalItems = overdueIn7Days?.items?.pagination?.totalCount ?? 0;
  const overdueInMoreThan7DaysTotalItems = overdueInMoreThan7Days?.items?.pagination?.totalCount ?? 0;
  const totalItems = overdueIn7DaysTotalItems + overdueInMoreThan7DaysTotalItems;
  const data = [...normalizeInboxItems(overdueIn7Days), ...normalizeInboxItems(overdueInMoreThan7Days)];
  const allItemsAreScannedInvoice = data.every((d) => d.type === 'scannedInvoice');

  const calcTitle = () => {
    if (allItemsAreScannedInvoice) {
      return formatMessage('widgets.todosDrawer.section.overdueInTheFuture.scannedInvoices.title', {
        count: totalItems,
      });
    } else if (totalItems === 1) {
      if (overdueIn7DaysTotalItems > 0) {
        return formatMessage('widgets.todosDrawer.section.overdueInWeekBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        });
      } else {
        return formatMessage('widgets.todosDrawer.section.overdueInFutureBills.singular.title', {
          vendorName: data[0]?.vendorName ?? '',
        });
      }
    } else {
      return formatMessage('widgets.todosDrawer.section.overdueInTheFuture.title', {
        total: totalItems,
      });
    }
  };

  const title = calcTitle();

  const onClickAction = async () => {
    const firstItem =
      overdueIn7Days && overdueIn7Days?.items.data.length > 0
        ? overdueIn7Days?.items.data[0]
        : overdueInMoreThan7Days?.items.data[0];
    await onSwitchOrganization({
      organizationId: clientOrganizationId,
      keepLoadingState: true,
      switchAccessTokenOnly: true,
    });
    goToPayDashboardBillsTab({ refresh: true, id: totalItems === 1 && firstItem ? firstItem.payload?.id : undefined });
  };
  const action = createViewBillsAction(totalItems, () => void onClickAction());

  return (
    <TodoSection
      key={`section-${TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}`}
      icon={<StatusIconSolid variant="informative" size="medium" />}
      title={title}
      action={action}
      type={TodoOverdueIn7DaysInboxItemsTypeEnum.OverdueIn7DaysInboxItems}
    />
  );
};
