// eslint-disable-next-line import/no-extraneous-dependencies
import { messages as coreMessages } from '@melio/platform-i18n';

const prefixes = [
  '_deprecated.widgets.completeLegalInfo.',
  'activities.completeLegalInfo.',
  'activities.subscription.',
  'activities.settings.subscriptionPlans.',
  'app.mtl.',
  'form.addressSearch.',
  'form.industryType.',
  'form.industryTypeSelect.',
  'form.googleAddress.',
  'widgets.completeLegalInfo.',
  'widgets.completeRequiredDetailsModal.',
  'widget.kyc.',
  'widgets.tbtForm.',
] as const;
type Prefix = (typeof prefixes)[number];

type GlobalMessageKey = keyof {
  [K in keyof typeof coreMessages as K extends `${Prefix}${string}` ? K : never]: string;
};

export const globalMessages = Object.entries(coreMessages).reduce((acc, [key, value]) => {
  if (prefixes.some((prefix) => key.startsWith(prefix))) {
    acc[key as GlobalMessageKey] = value as string;
  }
  return acc;
}, {} as Record<GlobalMessageKey, string>);
