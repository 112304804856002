import { useIsOwner } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useCallback } from 'react';

import { Plan, Tier } from '../api';
import { usePlansTiers } from './usePlansTiers';
import { useSubscriptionPlan } from './useSubscriptionPlan';

enum WarningMessageKeyEnum {
  CURRENT_USER_REMOVED = 'activities.subscription.cancelOrDowngradeModal.warning.text.currentUserRemoved',
  USERS_REMOVED = 'activities.subscription.cancelOrDowngradeModal.warning.text.usersRemoved',
  APPROVAL_WORKFLOWS_REMOVED = 'activities.subscription.cancelOrDowngradeModal.warning.text.approvalWorkflowsRemoved',
  ADVANCED_APPROVAL_WORKFLOWS_REMOVED = 'activities.subscription.cancelOrDowngradeModal.warning.text.advancedApprovalWorkflowsRemoved',
}

export const usePlansDowngradedImportantFeaturesLost = () => {
  const { currentPlan } = useSubscriptionPlan();
  const { getPlan } = usePlansTiers();
  const { formatMessage } = useMelioIntl();

  const isOwner = useIsOwner();

  const getDowngradedImportantFeaturesLost = useCallback(
    (downgradedPlanId: Plan['id']): string[] => {
      const importantFeaturesMap: Record<string, Array<`${WarningMessageKeyEnum}`>> = {
        [`${Tier.MelioPro}-${Tier.MelioPlus}`]: [WarningMessageKeyEnum.ADVANCED_APPROVAL_WORKFLOWS_REMOVED],
        [`${Tier.MelioPro}-${Tier.MelioBasic}`]: [
          ...(isOwner ? [] : [WarningMessageKeyEnum.CURRENT_USER_REMOVED]),
          WarningMessageKeyEnum.USERS_REMOVED,
          WarningMessageKeyEnum.APPROVAL_WORKFLOWS_REMOVED,
        ],
        [`${Tier.MelioPlus}-${Tier.MelioBasic}`]: [
          ...(isOwner ? [] : [WarningMessageKeyEnum.CURRENT_USER_REMOVED]),
          WarningMessageKeyEnum.USERS_REMOVED,
          WarningMessageKeyEnum.APPROVAL_WORKFLOWS_REMOVED,
        ],
        [`${Tier.MelioAccountingClientBoost}-${Tier.MelioAccountingClientCore}`]: [
          WarningMessageKeyEnum.ADVANCED_APPROVAL_WORKFLOWS_REMOVED,
        ],
      };

      const downgradedPlan = getPlan(downgradedPlanId);

      if (!currentPlan || !downgradedPlan) {
        const error = `current plan or downgraded plan not found`;
        Logger.log(error, 'error');
        return [];
      }

      if (currentPlan.tier === downgradedPlan.tier) {
        return [];
      }

      const key = `${currentPlan.tier}-${downgradedPlan?.tier}`;

      return importantFeaturesMap[key]?.map((feature) => formatMessage(feature)) || [];
    },
    [isOwner, currentPlan, getPlan, formatMessage]
  );

  return { getDowngradedImportantFeaturesLost };
};
