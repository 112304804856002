import {
  ARErrorCode,
  useGuestPayorPaymentIntent,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorVendorPaymentDetailsByPaymentRequestLink,
  useGuestPayorVendorPaymentDetailsByVendorHandle,
} from '@melio/ar-domain';
import { useMemo } from 'react';

import { GuestPaymentIntentData, GuestPaymentIntentParams, PaymentRequestResult, VendorHandleResult } from '../types';

type UseGuestPaymentIntentResult = {
  create: () => ReturnType<ReturnType<typeof useGuestPayorPaymentIntent>['createGuestPaymentIntent']>;
  refetch: ReturnType<typeof useGuestPayorPaymentRequestDetails>['refetch'];
  isError: boolean;
  isLoading: boolean;
  error: ARPlatformError<ARErrorCode> | null;
  data?: GuestPaymentIntentData;
};

type UseGuestPaymentIntentProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onError?: (err: ARPlatformError<ARErrorCode>) => void;
};

export const useGuestPaymentIntent = ({
  guestPaymentIntentParams,
  onError,
}: UseGuestPaymentIntentProps): UseGuestPaymentIntentResult => {
  const { paymentRequestLink, billDetails } = guestPaymentIntentParams;
  const { createGuestPaymentIntent: _createGuestPaymentIntent } = useGuestPayorPaymentIntent();

  const paymentRequestDetailsModel = useGuestPayorPaymentRequestDetails({
    paymentRequestLink,
    onError,
  });

  const vendorPaymentDetailsByPaymentRequestLinkModel = useGuestPayorVendorPaymentDetailsByPaymentRequestLink({
    paymentRequestLink,
    onError,
  });

  const vendorPaymentDetailsByVendorHandleModel = useGuestPayorVendorPaymentDetailsByVendorHandle({
    vendorHandle: billDetails?.vendorHandle,
    onError,
  });

  const create = async () =>
    _createGuestPaymentIntent(
      billDetails
        ? {
            vendorHandle: billDetails.vendorHandle,
            bill: {
              amount: billDetails.amount,
              invoiceNumber: billDetails.invoiceNumber,
              note: billDetails.note,
              // TODO: attachment handling will be covered in scope of https://meliorisk.atlassian.net/browse/ME-83895
            },
          }
        : { paymentRequestLink }
    );

  const vendorPaymentDetailsModel = paymentRequestLink
    ? vendorPaymentDetailsByPaymentRequestLinkModel
    : vendorPaymentDetailsByVendorHandleModel;

  const paymentRequestData: PaymentRequestResult | undefined = useMemo(
    () =>
      paymentRequestDetailsModel.data
        ? {
            ...paymentRequestDetailsModel.data,
            type: 'payment-request-link',
            vendorPaymentDetails: vendorPaymentDetailsModel.data,
          }
        : undefined,
    [vendorPaymentDetailsModel.data, paymentRequestDetailsModel.data]
  );

  const vendorHandleData: VendorHandleResult | undefined = useMemo(
    () =>
      vendorPaymentDetailsModel.data && billDetails?.amount
        ? {
            type: 'vendor-handle',
            vendorPaymentDetails: vendorPaymentDetailsModel.data,
            isPayable: true,
            invoice: {
              totalAmount: billDetails.amount,
            },
          }
        : undefined,
    [vendorPaymentDetailsModel.data, billDetails?.amount]
  );

  return {
    create,
    refetch: paymentRequestDetailsModel.refetch,
    isError: paymentRequestDetailsModel.isError || vendorPaymentDetailsModel.isError,
    isLoading: [paymentRequestDetailsModel, vendorPaymentDetailsModel].some((model) => model.isLoading),
    error: paymentRequestDetailsModel.error || vendorPaymentDetailsModel.error,
    data: paymentRequestLink ? paymentRequestData : vendorHandleData,
  };
};
