import { Button, Container, Group, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useEffect } from 'react';

import { MigratedUserExperiencePillRow } from './MigratedUserExperiencePillRow';
import { MigratedUserExperienceRow } from './MigratedUserExperienceRow';

type MigratedUserExperienceBodyProps = {
  onClose: () => void;
};
export const MigratedUserExperienceBodyDesktop = ({ onClose }: MigratedUserExperienceBodyProps) => {
  const { formatMessage } = useMelioIntl();

  //we need to do it for ada currently penny support focus only on the x button which we don't have in this modal so we need to focus the container in order to let the user navigate through the modal using arrows
  useEffect(() => {
    setTimeout(() => {
      const migratedTourModalWrapper = document.querySelector(
        '[data-testid="pay-dashboard-migrated-user-experience-modal"]'
      );
      if (migratedTourModalWrapper) {
        (migratedTourModalWrapper as HTMLDivElement).focus();
      }
    }, 500);
  }, []);

  return (
    <Container width="full" paddingX="m" paddingY="m" data-testid="pay-dashboard-migrated-user-experience-body">
      <Group variant="vertical" spacing="xl">
        <Text textStyle="heading2Semi" color="semantic.text.primary">
          {formatMessage('activities.migrationUserDashboardTour.modal.title')}
        </Text>
        <Group variant="vertical" spacing="m">
          <Text textStyle="body2" color="semantic.text.primary">
            {formatMessage('activities.migrationUserDashboardTour.modal.description')}
          </Text>
          <Group variant="vertical" spacing="s">
            <MigratedUserExperienceRow
              description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row1')}
              iconType="checked"
            />
            <MigratedUserExperienceRow
              description={formatMessage('activities.migrationUserDashboardTour.modal.descriptionRows.row2')}
              iconType="checked"
            />
            <MigratedUserExperiencePillRow />
          </Group>
        </Group>
        <Button
          data-testid="pay-dashboard-migrated-user-experience-modal-button"
          id="pay-dashboard-migrated-user-experience-modal-button"
          onClick={onClose}
          label={formatMessage('activities.migrationUserDashboardTour.modal.button')}
          variant="primary"
        />
      </Group>
    </Container>
  );
};
