import { getFundingSourceIconType, getFundingSourceImageProps } from '@melio/ap-domain';
import { BrandSymbol, Icon, Image } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';

export const FundingSourceIconOrImage = ({ fundingSource }: { fundingSource: FundingSource }) => {
  const imageProps = getFundingSourceImageProps(fundingSource);
  if (imageProps) {
    return <Image width={32} height={32} {...imageProps} />;
  }

  const { type, element } = getFundingSourceIconType(fundingSource);

  return type === 'brand' ? (
    <BrandSymbol type={element} />
  ) : (
    <Icon type={element} color="inherit" role="img" aria-label={fundingSource.nickname || fundingSource.displayName} />
  );
};
