import {
  Address,
  FormattedMessage,
  FormInputs,
  FormLayout,
  FormWidgetProps,
  InternationalAddress,
  InternationalIdType,
  TaxIdType,
  useMelioIntl,
} from '@melio/ar-domain';
import { createNumberMask, Form, Text, useMelioForm } from '@melio/penny';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import { MutableRefObject, useEffect, useRef } from 'react';

import { DateStringInput } from '../../../components';
import {
  BusinessOwnerForm,
  BusinessOwnerFormRef,
  OwnerDetailsFormValues,
  OwnerDetailsFormValuesWithRefId,
  UsResident,
} from '../types';
import { useOwnerDetailsFormSchema } from './useOwnerDetailsFormSchema';

export type OwnerDetailsFormProps = FormWidgetProps<OwnerDetailsFormValues> & {
  formRef: BusinessOwnerFormRef;
  onChange: (values: OwnerDetailsFormValuesWithRefId) => void;
};

export const OwnerDetailsForm = forwardRef<OwnerDetailsFormProps, 'form', false>(
  ({ isSaving, onSubmit, onChange, defaultValues, autoFocus, isLoading, formRef, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const formControl = useMelioForm<OwnerDetailsFormValues>({
      onSubmit,
      isSaving,
      defaultValues: {
        ownershipPercentage: formRef.type == 'ubo' ? 0.25 : null,
        taxIdType: defaultValues?.taxId ? TaxIdType.Ssn : undefined,
        usResident: UsResident.Yes,
        ...defaultValues,
        isUbo: formRef.type === 'ubo',
        isDirector: formRef.type === 'director',
      },
      subscribeToDefaultValuesChanges: true,
      autoFocus,
      isLoading,
      schema: useOwnerDetailsFormSchema(formRef.type == 'director'),
    });

    useEffect(() => {
      (formRef.formRef as MutableRefObject<BusinessOwnerForm>).current = formControl;
      return () => {
        (formRef.formRef as MutableRefObject<BusinessOwnerForm | null>).current = null;
      };
    }, [formControl, formRef.formRef]);

    const { register, registerField, formProps, watch } = formControl;

    const { usResident } = useAddressFieldState(formControl);

    const taxIdType = watch('taxIdType');

    const [firstName, lastName] = watch(['firstName', 'lastName']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => onChange({ refId: formRef.id, type: formRef.type, firstName, lastName }), [firstName, lastName]);

    return (
      <Form {...props} {...formProps} ref={ref} data-ref={formRef.id} data-component={OwnerDetailsForm.displayName}>
        <input type="hidden" {...register('id')} />
        <input type="hidden" {...register('jobTitle')} />
        <input type="hidden" {...register('isUbo')} />
        <input type="hidden" {...register('isDirector')} />
        <Text textStyle="body4SemiUpper" color="global.neutral.800" ref={formRef.visualRef}>
          <FormattedMessage
            id={
              formRef.type === 'ubo'
                ? 'ar.onboarding.activities.ownershipDetails.form.ownerDetails.header.text'
                : 'ar.onboarding.activities.ownershipDetails.form.contactDetails.header.text'
            }
          />
        </Text>
        <FormLayout.Section columns={2}>
          <Form.TextField
            {...registerField('firstName')}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.firstName.label'),
            }}
          />
          <Form.TextField
            {...registerField('lastName')}
            labelProps={{
              label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.lastName.label'),
            }}
          />
          <DateStringInput
            {...registerField('dateOfBirth')}
            labelProps={{
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.dateOfBirth.label'
              ),
            }}
            dateFormat="MM/dd/yyyy"
            colSpan={formRef.type === 'director' ? 2 : 1}
          />
          <FormInputs.PercentageField
            {...registerField('ownershipPercentage')}
            labelProps={{
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.ownershipPercentage.label'
              ),
            }}
            placeholder="25.00%"
            isHidden={formRef.type === 'director'}
          />
        </FormLayout.Section>

        <Form.RadioGroup
          {...registerField('usResident')}
          labelProps={{
            label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.label'),
            tooltipProps: {
              content: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.label.tooltip'
              ),
            },
          }}
          options={[
            {
              value: UsResident.Yes,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.options.yes.label'
              ),
              ariaLabel: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.options.yes.label'
              ),
            },
            {
              value: UsResident.No,
              label: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.options.no.label'
              ),
              ariaLabel: formatMessage(
                'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.usResident.options.no.label'
              ),
            },
          ]}
        />

        {usResident == 'yes' ? (
          <FormLayout.Section columns={2}>
            <FormInputs.Select
              {...registerField('taxIdType')}
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxIdType.label'
                ),
              }}
              options={[
                {
                  value: TaxIdType.Ssn,
                  label: formatMessage(
                    'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxIdType.options.ssn.label'
                  ),
                },
                {
                  value: TaxIdType.Itin,
                  label: formatMessage(
                    'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxIdType.options.itin.label'
                  ),
                },
              ]}
            />
            <FormInputs.TaxIdNumber
              {...registerField('taxId')}
              labelProps={{
                label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.taxId.label'),
              }}
              taxIdType={taxIdType}
            />

            <FormInputs.AddressSearch
              {...registerField('address')}
              labelProps={{
                label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.address.label'),
              }}
              colSpan={2}
            />
          </FormLayout.Section>
        ) : (
          <FormLayout.Section columns={2}>
            <FormInputs.Select
              {...registerField('internationalIdType')}
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalIdType.label'
                ),
              }}
              options={[
                {
                  value: InternationalIdType.Passport,
                  label: formatMessage(
                    'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalIdType.options.passport.label'
                  ),
                },
                {
                  value: InternationalIdType.DriversLicense,
                  label: formatMessage(
                    'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalIdType.options.driverLicense.label'
                  ),
                },
                {
                  value: InternationalIdType.NationalId,
                  label: formatMessage(
                    'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalIdType.options.nationalId.label'
                  ),
                },
              ]}
            />
            <Form.TextField
              {...registerField('internationalId')}
              labelProps={{
                label: formatMessage(
                  'ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.internationalId.label'
                ),
              }}
              maskProps={{
                mask: createNumberMask({
                  suffix: '',
                  prefix: '',
                  includeThousandsSeparator: false,
                  allowLeadingZeroes: true,
                  allowDecimal: false,
                  integerLimit: 18,
                }),
              }}
            />
            <FormInputs.InternationalAddressSearch
              {...registerField('address')}
              labelProps={{
                label: formatMessage('ar.onboarding.activities.ownershipDetails.ownerDetailsForm.fields.address.label'),
              }}
              colSpan={2}
            />
          </FormLayout.Section>
        )}
      </Form>
    );
  }
);
OwnerDetailsForm.displayName = 'OwnerDetailsForm';

const useAddressFieldState = ({ watch, setValue, clearErrors, getValues }: BusinessOwnerForm) => {
  const usResident = watch('usResident');
  const address = watch('address');

  const usAddressRef = useRef<Address | null>(null);
  const internationalAddressRef = useRef<InternationalAddress | null>(null);
  useUpdateEffect(() => {
    const targetAddress = usResident === 'yes' ? usAddressRef.current : internationalAddressRef.current;
    setValue('address', targetAddress as never);
    clearErrors('address');
  }, [usResident]);
  useEffect(() => {
    const usResident = getValues('usResident');
    if (usResident === 'yes') {
      usAddressRef.current = address;
    } else {
      internationalAddressRef.current = address;
    }
  }, [address, getValues]);

  return {
    usResident,
  };
};
