import { Payment } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { usePaymentNotPermittedText } from './usePaymentNotPermittedText';

export const usePaymentDescription = (payment: Payment) => {
  const [exclusiveUserViewEnabled] = usePartnerFeature('exclusiveUserViewEnabled', false);
  const paymentNotPermittedText = usePaymentNotPermittedText(payment);

  if (exclusiveUserViewEnabled && paymentNotPermittedText) {
    return paymentNotPermittedText;
  }

  return null;
};
