import { TaxRate, useMelioIntl, useSystemMessage, useTaxRate, useTaxRates } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';

import { TaxRateModalScreen } from './screens';
import { AddTaxRateFormFields } from './types';
import { isTaxRateUpdated } from './utils';

const NEW_TAX_RATE_ID = 'NEW';

export type TaxRateManagementActivityProps = {
  isOpen: boolean;
  onError?: ARErrorFunction;
  onClose: VoidFunction;
  onDone: (taxRate: TaxRate) => void;
  taxRateId?: string;
};

export const TaxRateModalActivity = forwardRef<TaxRateManagementActivityProps>(
  ({ onClose, onError, onDone, taxRateId, ...props }, ref) => {
    const [newTaxRate, setNewTaxRate] = useState<TaxRate>();
    const [selectedTaxRateId, setSelectedTaxRateId] = useState(taxRateId);
    const { triggerMessage } = useSystemMessage();
    const { formatMessage } = useMelioIntl();
    const { data: taxRatesData, isLoading } = useTaxRates({ onError });
    const { update, create, isMutating } = useTaxRate({
      id: selectedTaxRateId,
      enabled: false,
      onCreate: onDone,
      onUpdate: onDone,
      onCreateError: onError,
      onUpdateError: onError,
      onError,
    });

    const taxRates = [newTaxRate, ...(taxRatesData ?? [])].filter(Boolean) as TaxRate[];
    const selectedTaxRate = taxRates.find((taxRate) => taxRate.id === selectedTaxRateId);
    const selectOptions = taxRates.map((taxRate) => ({
      label: taxRate.name,
      value: taxRate.id,
    }));

    useEffect(() => setSelectedTaxRateId(taxRateId), [taxRateId]);

    const handleSubmit = async ({ id: taxRateId, rate, isDefault = false }: AddTaxRateFormFields) => {
      if (taxRateId === NEW_TAX_RATE_ID && newTaxRate) {
        await create({ name: newTaxRate.name, rate, isDefault });
        triggerMessage({
          type: 'success',
          title: formatMessage('ar.modals.activities.taxRate.toasts.add.text', {
            taxName: newTaxRate.name,
          }),
        });
      } else if (selectedTaxRate && isTaxRateUpdated(selectedTaxRate, { rate, isDefault })) {
        await update({ rate, isDefault });
        triggerMessage({
          type: 'success',
          title: formatMessage('ar.modals.activities.taxRate.toasts.update.text', {
            taxName: selectedTaxRate.name,
          }),
        });
      } else if (selectedTaxRate) {
        onDone(selectedTaxRate);
      }
      setNewTaxRate(undefined);
    };

    const handleChangeTaxRate = (selectedId: string) => {
      setNewTaxRate(undefined);
      setSelectedTaxRateId(selectedId);
    };

    const handleCreateTaxRate = (name: string): string => {
      const option = { name, id: NEW_TAX_RATE_ID } as TaxRate;
      setNewTaxRate(option);
      setSelectedTaxRateId(option.id);
      return option.id;
    };

    const handleOnClose = () => {
      setNewTaxRate(undefined);
      setSelectedTaxRateId(taxRateId);
      onClose();
    };

    return (
      <TaxRateModalScreen
        onChangeSelectedTaxRate={handleChangeTaxRate}
        defaultValues={pick(selectedTaxRate, ['id', 'rate', 'isDefault'])}
        isLoading={isLoading}
        isSaving={isMutating}
        selectOptions={selectOptions}
        onClose={handleOnClose}
        onSubmit={handleSubmit}
        onCreateTaxRate={handleCreateTaxRate}
        data-component={TaxRateModalActivity.displayName}
        data-testid="add-tax-rate-modal"
        {...props}
        ref={ref}
      />
    );
  }
);

TaxRateModalActivity.displayName = 'TaxRateModalActivity';
