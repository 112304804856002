import { SharedEmptyState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { FilterParams } from '../types';

export type EmptyTableStateProps = {
  search?: string;
  filters?: FilterParams;
  onClearSearch?: VoidFunction;
};

export const PaymentsEmptyState = forwardRef<EmptyTableStateProps>(
  ({ search, filters = {}, onClearSearch, ...props }, ref) => (
    <SharedEmptyState
      data-testid="payments-dashboard-no-search-result"
      ref={ref}
      search={search}
      filters={filters}
      searchMessage="ar.dashboard.activities.paymentsTable.emptyState.noSearchResults.label"
      defaultMessage="ar.dashboard.activities.paymentsTable.emptyState.noResults.label"
      actions={
        onClearSearch
          ? [
              {
                label: 'ar.dashboard.activities.paymentsTable.filters.advanced.clear.label',
                onClick: onClearSearch,
                variant: 'secondary',
                testId: 'clean-search-button',
              },
            ]
          : []
      }
      {...props}
    />
  )
);

PaymentsEmptyState.displayName = 'PaymentsEmptyState';
