import { Collapse, SectionBanner } from '@melio/penny';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';

import { useAccountantsRoutes } from '../../../../utils/useAccountantsRoutes';

export const ManageClientFlowResultBanner = ({
  clients,
  onClose,
}: {
  clients: FirmClientExpanded[];
  onClose: VoidFunction;
}) => {
  const { state } = useAccountantsRoutes();
  const flowResult = state?.flowResult;
  const { formatMessage } = useMelioIntl();
  const client = clients?.find((client) => client.organization.id === flowResult?.clientOrgId);

  const isOpen = !!flowResult && !!client;
  const variant = flowResult && (flowResult.success ? 'success' : 'critical');

  return (
    <Collapse in={isOpen}>
      <SectionBanner
        data-testid="manage-client-flow-result-banner"
        variant={variant ?? 'success'}
        description={
          flowResult &&
          formatMessage(`activities.accountants.firmDashboard.manageClientFlowResult.description.${flowResult.flow}`, {
            clientName: client?.organization.name,
          })
        }
        showCloseIcon
        onClose={onClose}
      />
    </Collapse>
  );
};
