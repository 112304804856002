import { useIsMobile } from '@melio/ar-domain';
import { Container } from '@melio/penny';
import { FC } from 'react';

export const MobileContainer: FC = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <Container overflow="initial" paddingX={isMobile ? 's' : undefined}>
      {children}
    </Container>
  );
};
