import { getDMCurrency, isFXCurrency, useInternationalFxFeature } from '@melio/ap-domain';
import { RecurringPaymentProps, ReviewAndConfirmWidget } from '@melio/ap-widgets';
import {
  ApprovalRequirementStatus,
  Bill,
  BillingFeeSetting,
  DeliveryMethod,
  DeliveryPreference,
  FundingSource,
  FxQuote,
  PaymentFee,
  PaymentIntent,
  SelectedRepaymentOption,
  Vendor,
} from '@melio/platform-api';
import { MessageKey } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useMelioIntl } from '@melio/platform-provider';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { useCallback } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';

export type ReviewAndConfirmScreenProps = {
  invoiceNumbers: Bill['invoice']['number'][];
  fundingSource: FundingSource;
  vendorName: Vendor['name'];
  countryDisplayName?: string;
  deliveryMethod: DeliveryMethod;
  memoToVendor?: PaymentIntent['noteToVendor'];
  amount: number;
  paymentFees?: PaymentFee[];
  scheduledDate: Date;
  deliveryPreference: DeliveryPreference;
  isLoading?: boolean;
  step: number;
  totalSteps: number;
  recurringPayment?: RecurringPaymentProps;
  onEditAmount?: VoidFunction;
  onEditFundingSource?: VoidFunction;
  onEditRepaymentTerm?: VoidFunction;
  onEditDate?: VoidFunction;
  onEditDeliveryMethod?: VoidFunction;
  onEditMemoToVendor?: VoidFunction;
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: (fxQuoteToken?: string) => void;
  updatedFields?: string[];
  isLoadingButton?: boolean;
  dueDate?: Date;
  enableLateTag: boolean;
  hideMemoSection?: boolean;
  hideFromSection?: boolean;
  hideFeesSection?: boolean;
  remainingAmount?: number;
  isEditPaymentFlow?: boolean;
  texts?: {
    continue?: MessageKey;
  };
  isFinancingPaymentFlow?: boolean;
  orgBillingFeeSettings: BillingFeeSetting[];
  selectedRepaymentOption?: SelectedRepaymentOption;
  approvalRequirementStatus?: ApprovalRequirementStatus;
  vendorId: string;
  quote?: FxQuote;
  isQuoteFetching: boolean;
};

const getCTALabel = (recurringPayment: boolean, prop?: MessageKey): MessageKey => {
  if (prop) {
    return prop;
  }
  return recurringPayment
    ? 'activities.reviewAndConfirm.screens.reviewAndConfirm.recurringContinue'
    : 'activities.reviewAndConfirm.screens.reviewAndConfirm.continue';
};

export const ReviewAndConfirmScreen = ({
  step,
  totalSteps,
  amount,
  invoiceNumbers,
  fundingSource,
  vendorName,
  countryDisplayName,
  paymentFees = [],
  scheduledDate,
  deliveryPreference,
  memoToVendor,
  orgBillingFeeSettings,
  deliveryMethod,
  isEditPaymentFlow,
  recurringPayment,
  onEditAmount,
  onEditFundingSource,
  onEditRepaymentTerm,
  onEditDate,
  onEditDeliveryMethod,
  onEditMemoToVendor,
  onBack,
  onClose,
  onDone,
  updatedFields,
  isLoading,
  isLoadingButton,
  dueDate,
  enableLateTag,
  hideMemoSection,
  hideFromSection,
  hideFeesSection,
  remainingAmount,
  isFinancingPaymentFlow,
  selectedRepaymentOption,
  approvalRequirementStatus,
  vendorId,
  quote,
  isQuoteFetching,
  ...props
}: ReviewAndConfirmScreenProps) => {
  const { formatMessage } = useMelioIntl();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const isFxPaymentEnabled = useInternationalFxFeature();
  const currency = getDMCurrency(deliveryMethod);
  const isFxPayment = isFXCurrency(currency);

  const endActionMonitoring = useCallback(() => {
    endAction('funding_source_selection');
    endAction('goods_received_flow');
    endAction('vendor_missing_details_flow');
    endAction('mcc_flow');
    endAction('reconciliation_flow');
    endAction('delivery_date_selection');
    endAction('memo_to_vendor');
    endAction('edit_vendor_bank_details');
    endAction('effected_payments_disclaimer');
  }, [endAction]);

  const isNextButtonDisabled = isFxPaymentEnabled && isFxPayment && !quote;

  return (
    <NewSinglePaymentStepLayout
      data-component="ReviewAndConfirmActivity.ReviewAndConfirmScreen"
      data-testid="review-and-confirm-activity-review-and-confirm-screen"
      isLoading={isLoading}
      {...props}
      ref={endActionMonitoring}
      headerContent={
        <NewSinglePaymentStepLayout.Header>
          <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
          <NewSinglePaymentStepLayout.BackButton onClick={onBack} />
        </NewSinglePaymentStepLayout.Header>
      }
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <NewSinglePaymentStepLayout.NextButton
            onClick={() => {
              startAction('review_and_confirm');
              onDone(quote?.fxQuoteToken);
            }}
            label={formatMessage(getCTALabel(!!recurringPayment, props.texts?.continue))}
            isFullWidth
            isDisabled={isNextButtonDisabled}
            variant="success"
            isLoading={isLoadingButton}
          />
        </NewSinglePaymentStepLayout.Actions>
      }
    >
      <NewSinglePaymentStepLayout.Title>
        {formatMessage('activities.reviewAndConfirm.screens.reviewAndConfirm.title')}
      </NewSinglePaymentStepLayout.Title>
      <SystemMessageDisplay data-testid="review-and-confirm-activity-system-message" />
      <NewSinglePaymentStepLayout.Content>
        <ReviewAndConfirmWidget
          amount={amount}
          invoiceNumbers={invoiceNumbers}
          fundingSource={fundingSource}
          vendorName={vendorName}
          countryDisplayName={countryDisplayName}
          paymentFees={paymentFees}
          orgBillingFeeSettings={orgBillingFeeSettings}
          scheduledDate={scheduledDate}
          deliveryPreference={deliveryPreference}
          memoToVendor={memoToVendor}
          deliveryMethod={deliveryMethod}
          onEditAmount={onEditAmount}
          onEditFundingSource={onEditFundingSource}
          onEditRepaymentTerm={onEditRepaymentTerm}
          onEditDate={onEditDate}
          onEditDeliveryMethod={onEditDeliveryMethod}
          onEditMemoToVendor={onEditMemoToVendor}
          updatedFields={updatedFields}
          recurringPayment={recurringPayment}
          dueDate={dueDate}
          isEditPaymentFlow={isEditPaymentFlow}
          enableLateTag={enableLateTag}
          hideMemoSection={hideMemoSection}
          hideFromSection={hideFromSection}
          hideFeesSection={hideFeesSection}
          remainingAmount={remainingAmount}
          isFinancingPaymentFlow={isFinancingPaymentFlow}
          selectedRepaymentOption={selectedRepaymentOption}
          approvalRequirementStatus={approvalRequirementStatus}
          vendorId={vendorId}
          quote={quote}
          isQuoteFetching={isQuoteFetching}
        />
      </NewSinglePaymentStepLayout.Content>
    </NewSinglePaymentStepLayout>
  );
};

ReviewAndConfirmScreen.displayName = 'ReviewAndConfirmActivity.ReviewAndConfirmScreen';
