import { ReportsApiGetReceivablePaymentsReportRequest, ReportsApiInstance } from '@melio/ar-api-axios-client';
import { useMutation } from '@tanstack/react-query';

export type UseReceivablePaymentsReportProps = ReportsApiGetReceivablePaymentsReportRequest;

export const useReceivablePaymentsReport = () => {
  const mutation = useMutation(
    async (params: ReportsApiGetReceivablePaymentsReportRequest) => {
      const response = await ReportsApiInstance.getReceivablePaymentsReport(params);
      return response.data;
    },
    {
      mutationKey: ['receivablePaymentsReport'],
    }
  );

  return {
    ...mutation,
    getReceivablePaymentsReport: mutation.mutateAsync,
  };
};
