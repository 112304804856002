import { Group } from '@melio/penny';
import { FundingSource } from '@melio/platform-api/src/entities-v2/funding-source/types';
import { FundingSourceCard } from '@melio/subscriptions/src/ui';

export const FundingSourceSelect = ({
  fundingSources,
  onCardClick,
  selectedId,
}: {
  fundingSources: FundingSource[];
  onCardClick: (fundingSource: FundingSource) => void;
  selectedId: string | null;
  addLabel: string;
}) => (
  <Group role="radiogroup" variant="vertical">
    {fundingSources.map((fundingSource) => (
      <FundingSourceCard
        key={fundingSource.id}
        fundingSource={fundingSource}
        onClick={() => onCardClick(fundingSource)}
        isSelected={selectedId === fundingSource.id}
      />
    ))}
  </Group>
);
