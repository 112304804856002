/* eslint-disable max-lines */
import { getDefaultDeliveryMethodTypeByDeliveryPreference } from '@melio/ap-domain';
import { BatchPaymentsFundsDebitDateBanner, DeliveryDateFundsDebitDateBanner } from '@melio/ap-widgets';
import { MissingVendorInfoBannerWidget } from '@melio/ap-widgets/src/components/Vendors/MissingVendorInfoBanner/MissingVendorInfoBanner.widget';
import { Button, Card, Container, Group, IconButton, SectionBanner, Text, useBreakpoint } from '@melio/penny';
import { Layout } from '@melio/platform-ds';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { forwardRef, PageTitle, SystemMessageDisplay, withFormBannerAnnouncementProvider } from '@melio/platform-utils';
import { useState } from 'react';

import { AmountHeader } from './components/AmountHeader/AmountHeader';
import { CardPaymentPromotionBanner } from './components/CardPaymentPromotionBanner/CardPaymentPromotionBanner';
import { useCardPaymentPromotionBanner } from './components/CardPaymentPromotionBanner/useCardPaymentPromotionBanner';
import { PaymentIntentsTable } from './components/PaymentIntentsTable/PaymentIntentsTable';
import { UsHolidayChecksBanner } from './components/UsHolidayChecksBanner';
import {
  doesPaymentIntentsContainAnEbill,
  getNumberOfBills,
  useUnreadyPaymentIntents,
} from './PaymentIntentsTable.screen.utils';
import { PaymentIntentsTableScreenProps } from './types';
import { useEventHandlers } from './useEventHandlers';

export const PaymentIntentsTableScreen = withFormBannerAnnouncementProvider(
  forwardRef<PaymentIntentsTableScreenProps, 'div'>(
    (
      {
        isToggling,
        paymentIntentsWithDerivatives,
        immediatelyShowStatus,
        fundingSources,
        orgBillingFeeSettings,
        isByDueDate,
        selectedDeliveryDateHeaderCellOption,
        missingVendorDirectoryDetailsBannerProps,
        arePaymentsCombined,
        onToggleCombinedPayments,
        onOpenReconciliationModal,
        onOpenPaymentPurposeModal,
        onOpenVendorDetailsModal,
        onOpenInvoiceAttachmentModal,
        onViewBillDetailsClick,
        onAddMemoToVendorClick,
        onSetInvoiceNumberClick,
        onRemoveBillsClick,
        onAddFundingSourceClick,
        onAddDeliveryMethodClick,
        onUpdateSingleDeliveryPreferenceType,
        onUpdateSingleFundingSource,
        onUpdateSingleDeliveryMethod,
        onUpdateSingleScheduledDate,
        onUpdateSingleDeliveryDate,
        onDone,
        onBack,
        loadingRowIds,
        onUpdateAllFundingSources,
        onUpdateAllDeductionDatesToTheSameDate,
        onUpdateAllDeductionDatesToTheEarliestDate,
        onUpdateAllDeductionDatesToArriveByDueDate,
        onUpdateAllDeliveryDatesToTheDueDate,
        isUpdating,
        shouldDisplayVendorDetailsFailureBanner,
        onEditAmountClick,
        tableErrors,
        bannerError,
        paymentIntentToEbillOption,
        ...props
      },
      ref
    ) => {
      const { isExtraSmallScreen } = useBreakpoint();
      const {
        settings: { eoyCheck },
      } = useConfig();

      const [isEoyChecksEnabled] = usePartnerFeature('eoyCheck', false);
      const [isFlowOfFundsEnabled] = usePartnerFeature('FofImprovements', false);
      const { isCardPromotionActive, dismissCardPromotionBanner } =
        useCardPaymentPromotionBanner(paymentIntentsWithDerivatives);

      const [showMissingDetailsIndicationAsCritical] = useDevFeature(
        FeatureFlags.BatchPaymentsShowPaymentIntentsMissingDetailsIndicationAsCritical,
        false
      );

      const numberOfBills = getNumberOfBills(paymentIntentsWithDerivatives);
      const hasCombinedPayments = numberOfBills > paymentIntentsWithDerivatives.length;
      const hasEbills = doesPaymentIntentsContainAnEbill(paymentIntentsWithDerivatives);

      const { formatMessage } = useMelioIntl();
      const { startAction } = useMonitoring();

      const [shouldDisplayStatus, setShouldDisplayStatus] = useState(false);

      const unreadyToConfirmPaymentIntentsIds = useUnreadyPaymentIntents(
        paymentIntentsWithDerivatives,
        fundingSources
      ).map(({ paymentIntent }) => paymentIntent.id);
      const numberOfUnreadyPaymentIntents = unreadyToConfirmPaymentIntentsIds.length;

      const {
        onConfirmClick,
        handleAddMemoToVendorClick,
        handleSetInvoiceNumberClick,
        handleRemoveBillsClick,
        handleUpdateAllDeductionDatesToTheSameDate,
        handleUpdateAllDeductionDatesToTheEarliestDate,
        handleUpdateAllDeductionDatesToArriveByDueDate,
        handleUpdateAllDeliveryDatesToArriveByDueDate,
        handleUpdateSingleFundingSource,
        handleViewBillDetailsClick,
        handleCancel,
        handleUpdateSingleDeliveryMethod,
        handleUpdateSingleScheduledDate,
        handleUpdateSingleDeliveryDate,
        handleAddDeliveryMethodClick,
        handleAddFundingSourceClick,
        handleUpdateAllFundingSources,
        handleOpenReconciliationModal,
        handleOpenPaymentPurposeModal,
        handleOpenInvoiceAttachmentModal,
        handleUpdateSingleDeliveryPreferenceType,
        handleRowExpand,
        handleEditAmountClick,
      } = useEventHandlers({
        arePaymentsCombined,
        paymentIntentsWithDerivatives,
        setShouldDisplayStatus,
        isByDueDate,
        selectedDeliveryDateHeaderCellOption,
        onDone: () => {
          startAction('batch_payment_confirm');
          onDone();
        },
        onBack,
        onOpenReconciliationModal,
        onOpenPaymentPurposeModal,
        onOpenInvoiceAttachmentModal,
        onRemoveBillsClick,
        numberOfUnreadyPaymentIntents,
        fundingSources,
        onAddMemoToVendorClick,
        onSetInvoiceNumberClick,
        onAddDeliveryMethodClick,
        onViewBillDetailsClick,
        onAddFundingSourceClick,
        onUpdateAllDeductionDatesToTheSameDate,
        onUpdateAllDeductionDatesToTheEarliestDate,
        onUpdateAllDeductionDatesToArriveByDueDate,
        onUpdateAllDeliveryDatesToTheDueDate,
        onUpdateAllFundingSources,
        onUpdateSingleDeliveryMethod,
        onUpdateSingleDeliveryPreferenceType,
        onUpdateSingleFundingSource,
        onUpdateSingleScheduledDate,
        onUpdateSingleDeliveryDate,
        onEditAmountClick,
        missingVendorDirectoryDetailsBannerProps,
      });

      const hasUnmanagedVendors = paymentIntentsWithDerivatives.some(({ vendor }) => !vendor.isManaged);
      const isFundsDebitDateSectionActive = isFlowOfFundsEnabled && hasUnmanagedVendors;
      const singlePaymentIntent =
        paymentIntentsWithDerivatives.length === 1 ? paymentIntentsWithDerivatives[0]?.paymentIntent : undefined;

      const shouldDisplayStatusBanner =
        (shouldDisplayStatus || immediatelyShowStatus) && !!numberOfUnreadyPaymentIntents;

      const shouldShowUpperBannersSection = isCardPromotionActive || isFundsDebitDateSectionActive;
      const shouldShowBannersSection =
        isEoyChecksEnabled ||
        shouldDisplayStatusBanner ||
        shouldDisplayVendorDetailsFailureBanner ||
        bannerError ||
        !!missingVendorDirectoryDetailsBannerProps;

      return (
        <Layout
          paddingContent="l"
          data-component="BatchPaymentsActivity.PaymentIntentsTableScreen"
          data-testid="batch-payments-activity-payment-intents-table-screen"
          {...props}
          ref={ref}
          footer={{
            isSticky: true,
            content: (
              <Group spacing="l" justifyContent="space-between">
                <Button
                  variant="tertiary"
                  data-testid="layout-cancel-button"
                  onClick={handleCancel}
                  label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.cancel')}
                />
                <Button
                  onClick={onConfirmClick}
                  data-testid="layout-next-button"
                  isLoading={isUpdating}
                  label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.schedulePayments')}
                />
              </Group>
            ),
          }}
        >
          <Container paddingX="xxs" paddingY="xxs">
            <Group variant="vertical" spacing="xl">
              <Group justifyContent="flex-end">
                <IconButton
                  aria-label={formatMessage('app.payDashboard.form.actions.close.label')}
                  icon="close"
                  size="large"
                  variant="primary"
                  onClick={handleCancel}
                  data-testid="batch-payments-close-button"
                />
              </Group>
              <SystemMessageDisplay data-testid="payment-intents-table-notification" />
              {shouldShowUpperBannersSection && (
                <Container>
                  <Group variant="vertical" spacing="m" aria-live="assertive" role="alert">
                    {isCardPromotionActive && <CardPaymentPromotionBanner onDismiss={dismissCardPromotionBanner} />}
                    {isFundsDebitDateSectionActive &&
                      (singlePaymentIntent ? (
                        <DeliveryDateFundsDebitDateBanner
                          deliveryType={
                            singlePaymentIntent?.selectedDeliveryPreferenceType
                              ? getDefaultDeliveryMethodTypeByDeliveryPreference(
                                  singlePaymentIntent.selectedDeliveryPreferenceType
                                )
                              : undefined
                          }
                          scheduledDate={singlePaymentIntent?.effectiveScheduledDate || undefined}
                          deliveryDate={singlePaymentIntent?.effectiveDeliveryDate || undefined}
                        />
                      ) : (
                        <BatchPaymentsFundsDebitDateBanner />
                      ))}
                  </Group>
                </Container>
              )}
              <Group
                variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
                alignItems="flex-start"
                justifyContent="space-between"
                data-testid="layout-title"
              >
                <Group variant="vertical" spacing="xs">
                  <PageTitle textStyle="heading1Semi" aria-live="polite">
                    {formatMessage('activities.batchPayments.screens.paymentIntentsTable.title', {
                      numberOfPaymentIntents: paymentIntentsWithDerivatives.length,
                    })}
                  </PageTitle>
                  {hasCombinedPayments && (
                    <Text textStyle="body4" color="global.neutral.900">
                      {formatMessage('activities.batchPayments.screens.paymentIntentsTable.subtitle.combined', {
                        numberOfBills,
                      })}
                    </Text>
                  )}
                </Group>
                <AmountHeader
                  orgBillingFeeSettings={orgBillingFeeSettings}
                  paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
                />
              </Group>
              {shouldShowBannersSection && (
                <Container>
                  <Group variant="vertical" spacing="m" aria-live="assertive" role="alert">
                    {isEoyChecksEnabled && eoyCheck.enabled && <UsHolidayChecksBanner eoyCheckLink={eoyCheck.link} />}
                    {shouldDisplayStatusBanner && (
                      <SectionBanner
                        variant={showMissingDetailsIndicationAsCritical ? 'critical' : 'warning'}
                        title={formatMessage(
                          'activities.batchPayments.screens.paymentIntentsTable.unreadyPaymentsBanner.title',
                          {
                            numberOfPaymentIntents: numberOfUnreadyPaymentIntents,
                          }
                        )}
                        description={formatMessage(
                          'activities.batchPayments.screens.paymentIntentsTable.unreadyPaymentsBanner.description',
                          { numberOfPaymentIntents: numberOfUnreadyPaymentIntents }
                        )}
                        data-testid="confirm-warning-banner"
                      />
                    )}
                    {shouldDisplayVendorDetailsFailureBanner && (
                      <SectionBanner
                        data-testid="mcc-selection-modal-section-banner-error"
                        variant="critical"
                        title={formatMessage(
                          'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.title'
                        )}
                        description={formatMessage(
                          'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.description'
                        )}
                      />
                    )}
                    {!!missingVendorDirectoryDetailsBannerProps && (
                      <MissingVendorInfoBannerWidget
                        onEditClick={missingVendorDirectoryDetailsBannerProps.onEditClick}
                        vendorName={missingVendorDirectoryDetailsBannerProps.vendorName}
                      />
                    )}
                    {bannerError && (
                      <SectionBanner
                        data-testid="post-creation-error-banner"
                        variant="critical"
                        title={bannerError.label}
                        description={bannerError.description}
                      />
                    )}
                  </Group>
                </Container>
              )}
              <Container>
                <Group variant="vertical" data-testid="layout-content" spacing="l">
                  {hasEbills && (
                    <SectionBanner
                      data-testid="payment-intents-table-screen-ebills-section-banner"
                      description={formatMessage(
                        'activities.batchPayments.screens.paymentIntentsTable.ebills.section.description'
                      )}
                      variant="informative"
                    />
                  )}
                  <Card paddingX="none" paddingY="none">
                    <PaymentIntentsTable
                      isToggling={isToggling}
                      paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
                      fundingSources={fundingSources}
                      orgBillingFeeSettings={orgBillingFeeSettings}
                      arePaymentsCombined={arePaymentsCombined}
                      onToggleCombinedPayments={onToggleCombinedPayments}
                      onOpenVendorDetailsModal={onOpenVendorDetailsModal}
                      onOpenReconciliationModal={handleOpenReconciliationModal}
                      onOpenPaymentPurposeModal={handleOpenPaymentPurposeModal}
                      onOpenInvoiceAttachmentModal={handleOpenInvoiceAttachmentModal}
                      onViewBillDetailsClick={handleViewBillDetailsClick}
                      onAddMemoToVendorClick={handleAddMemoToVendorClick}
                      onEditAmountClick={handleEditAmountClick}
                      onSetInvoiceNumberClick={handleSetInvoiceNumberClick}
                      onRemoveBillsClick={handleRemoveBillsClick}
                      onAddFundingSourceClick={handleAddFundingSourceClick}
                      onAddDeliveryMethodClick={handleAddDeliveryMethodClick}
                      onUpdateSingleDeliveryPreferenceType={handleUpdateSingleDeliveryPreferenceType}
                      onUpdateSingleFundingSource={handleUpdateSingleFundingSource}
                      onUpdateSingleDeliveryMethod={handleUpdateSingleDeliveryMethod}
                      onUpdateSingleScheduledDate={handleUpdateSingleScheduledDate}
                      onUpdateSingleDeliveryDate={handleUpdateSingleDeliveryDate}
                      loadingRowIds={loadingRowIds}
                      shouldDisplayStatus={shouldDisplayStatus}
                      onUpdateAllFundingSources={handleUpdateAllFundingSources}
                      onUpdateAllDeductionDatesToTheSameDate={handleUpdateAllDeductionDatesToTheSameDate}
                      onUpdateAllDeductionDatesToTheEarliestDate={handleUpdateAllDeductionDatesToTheEarliestDate}
                      onUpdateAllDeductionDatesToArriveByDueDate={handleUpdateAllDeductionDatesToArriveByDueDate}
                      onUpdateAllDeliveryDatesToTheDueDate={handleUpdateAllDeliveryDatesToArriveByDueDate}
                      isByDueDate={isByDueDate}
                      onRowExpand={handleRowExpand}
                      selectedDeliveryDateHeaderCellOption={selectedDeliveryDateHeaderCellOption}
                      tableErrors={tableErrors}
                      paymentIntentToEbillOption={paymentIntentToEbillOption}
                    />
                  </Card>
                </Group>
              </Container>
            </Group>
          </Container>
        </Layout>
      );
    }
  )
);

PaymentIntentsTableScreen.displayName = 'BatchPaymentsActivity.PaymentIntentsTableScreen';
