import { forwardRef, HTMLTag } from '@melio/platform-utils';
import { ComponentType, isValidElement, ReactElement } from 'react';
import { MemoryRouter, UNSAFE_LocationContext } from 'react-router-dom';

import { HOCReturnType } from './types';

/**
 * A MemoryRouter lets as use a router functionality without exposing the URL to the use.
 * @see https://reactrouter.com/en/main/router-components/memory-router
 * @param UI React component or element to wrap with a MemoryRouter
 *
 */

export function withMemoryRouter<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T>): HOCReturnType<T, TTag>;
export function withMemoryRouter(UI: ReactElement): ReactElement;

export function withMemoryRouter<T, TTag extends HTMLTag = 'div'>(UI: ComponentType<T> | ReactElement) {
  if (isValidElement(UI)) return add(UI);
  return forwardRef<T, TTag>((props, ref) => add(<UI {...(props as T)} ref={ref} />));
}

function add(UI: ReactElement): ReactElement {
  return (
    // we need UNSAFE_LocationContext to enable adding a nested router inside a router
    <UNSAFE_LocationContext.Provider value={null as never}>
      <MemoryRouter>{UI}</MemoryRouter>
    </UNSAFE_LocationContext.Provider>
  );
}
