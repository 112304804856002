import { Button, Card, Group, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { Organization } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';

export type SelectCompanyScreenProps = {
  organizations: Organization[];
  selectOrganization: (organizationId: string) => void;
  vendorName: string;
};

export const SelectCompanyScreen = ({
  organizations,
  selectOrganization,
  vendorName,
  ...props
}: SelectCompanyScreenProps) => {
  const [selectedOrgId, setSelectedOrg] = useState<string>();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const renderOrgsCards = () =>
    organizations.map((organization) => {
      const onClick = () => {
        track('User', 'Click', { Cta: `${organization.id} clicked` });
        setSelectedOrg(organization.id);
      };
      return (
        <Card
          key={organization.id}
          onClick={onClick}
          variant="flat"
          isSelected={organization.id === selectedOrgId}
          data-testid={`company-card-${organization.id}`}
        >
          <Text textStyle="body2Semi">{organization.name}</Text>
        </Card>
      );
    });

  return (
    <NewSinglePaymentStepLayout
      data-component="PaymentRequestSelectCompanyActivity.SelectCompanyScreen"
      data-testid="payment-request-select-company-activity-select-company-screen"
      footerContent={
        <NewSinglePaymentStepLayout.Actions>
          <Button
            label={formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.cta')}
            isDisabled={!selectedOrgId}
            onClick={() => selectOrganization(selectedOrgId as string)}
            data-testid="continue-button"
          />
        </NewSinglePaymentStepLayout.Actions>
      }
      {...props}
    >
      <NewSinglePaymentStepLayout.Title data-testid="payment-request-select-company-activity-select-company-screen-title">
        <Group variant="vertical" spacing="s">
          <Text textStyle="heading1Semi">
            {formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.title')}
          </Text>
          <Text textStyle="body2" data-testid="select-company-screen-description">
            {formatMessage('activities.paymentRequestSelectCompany.screens.selectCompany.description', { vendorName })}
          </Text>
        </Group>
      </NewSinglePaymentStepLayout.Title>
      <Group variant="vertical" spacing="s" alignItems="center">
        {renderOrgsCards()}
      </Group>
    </NewSinglePaymentStepLayout>
  );
};

SelectCompanyScreen.displayName = 'PaymentRequestSelectCompanyActivity.SelectCompanyScreen';
