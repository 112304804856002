import { mixed, object, SchemaOf } from 'yup';

type FormFields = {
  file: File;
};

const FILE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB

type GetSchemaArgs = {
  requiredError: string;
  tooBigError: string;
};

export const getSchema = ({ requiredError, tooBigError }: GetSchemaArgs) =>
  object().shape({
    file: mixed<File>()
      .required(requiredError)
      .test('is-file-size-below-limit', tooBigError, (file) => {
        if (file) {
          return file.size <= FILE_SIZE_LIMIT;
        }
        return true;
      })
      .test('is-file-format-allowed', 'file format not allowed', (file) => {
        if (file) {
          return ['csv'].includes(file.type?.split('/')[1] || '');
        }
        return true;
      }),
  }) as unknown as SchemaOf<FormFields>;
