import { Link, NakedButton, SectionBannerProps } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useUpdateClientSubscriptionBillingOptions } from '../../../hooks';
import { SubscriptionBillingPayor } from '../../../types';
import { BillingPayorFlowBannerType as BannerType } from '../types';
import { usePaymentMethodBannerType } from './usePaymentMethodBannerType';

type BannerProps = SectionBannerProps & { isHidden?: boolean; nextPayor: SubscriptionBillingPayor };

export const usePaymentMethodBannerProps = ({
  clientOrgId,
  subscriptionBillingPayor,
  isAllowedToUpdateFirmBillingFundingSource,
  onOpenPaymentMethodDrawer,
  goToSupportPage,
  onNavigateToClientPaymentMethodsSettings,
  fundingSourceDisplayName,
}: {
  clientOrgId?: string;
  subscriptionBillingPayor: SubscriptionBillingPayor;
  onOpenPaymentMethodDrawer: VoidFunction;
  isAllowedToUpdateFirmBillingFundingSource: boolean;
  goToSupportPage: VoidFunction;
  onNavigateToClientPaymentMethodsSettings?: VoidFunction;
  fundingSourceDisplayName?: string;
}) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();

  const bannerType = usePaymentMethodBannerType({ clientOrgId, subscriptionBillingPayor });
  const { clientFundingSources, clientSubscriptionBillingFundingSourceId, clientName } =
    useUpdateClientSubscriptionBillingOptions(clientOrgId).data;

  const unknownPayorContactSupportLink = (
    <Link
      data-testid="limitied-blling-options-contact-support"
      label={formatMessage('activities.accountants.paymentMethodCard.anonymous.payor.banner.action')}
      href=""
      onClick={(e) => {
        track('Organization', 'Click', {
          Cta: formatMessage('activities.accountants.paymentMethodCard.anonymous.payor.banner.action'),
          Intent: 'contact-support',
        });
        e.preventDefault();
        goToSupportPage();
      }}
    />
  );

  const firmToClientWithClientMissingFundingSourcesBannerProps: BannerProps = {
    variant: 'informative',
    title: formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.title.client', { clientName }),
    description: formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.label.client', {
      action: (
        <Link
          data-testid="add-client-funding-source"
          href=""
          label={formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.action.label.client')}
          onClick={(e) => {
            track('Organization', 'Click', {
              Cta: formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.action.label.client'),
              Intent: 'add-payment-method',
            });
            e.preventDefault();
            onNavigateToClientPaymentMethodsSettings?.();
          }}
        />
      ),
    }),
    nextPayor: SubscriptionBillingPayor.Client,
  };

  const firmToClientWithClientFundingSourcesBannerProps: BannerProps = {
    variant: 'neutral',
    action: (
      <NakedButton
        data-testid="change-payment-method-link"
        label={formatMessage('activities.accountants.paymentMethodCard.existingPaymentMethod.change.link')}
        size="medium"
        variant="primary"
        onClick={onOpenPaymentMethodDrawer}
      />
    ),
    nextPayor: SubscriptionBillingPayor.Client,
    description: formatMessage('activities.accountants.paymentMethodCard.existingPaymentMethod.label.client', {
      fundingSourceDisplayName,
    }),
    isHidden: subscriptionBillingPayor !== SubscriptionBillingPayor.Client,
  };

  const clientToFirmWithFirmMissingBillingFundingSourceProps: BannerProps = {
    variant: 'informative',
    description: formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.label.firm'),
    action: isAllowedToUpdateFirmBillingFundingSource && (
      <NakedButton
        data-testid="change-payment-method-link"
        label={formatMessage('activities.accountants.paymentMethodCard.addPaymentMethod.link')}
        size="medium"
        variant="primary"
        onClick={onOpenPaymentMethodDrawer}
      />
    ),
    nextPayor: SubscriptionBillingPayor.AccountingFirm,
  };

  const clientToFirmWithFirmHasBillingFundingSourceBannerProps: BannerProps = {
    variant: 'neutral',
    description: formatMessage('activities.accountants.paymentMethodCard.existingPaymentMethod.label.firm', {
      fundingSourceDisplayName,
    }),
    action: isAllowedToUpdateFirmBillingFundingSource && (
      <NakedButton
        data-testid="change-payment-method-link"
        label={formatMessage('activities.accountants.paymentMethodCard.existingPaymentMethod.change.link')}
        size="medium"
        variant="primary"
        onClick={() => {
          track('Organization', 'Click', {
            Cta: formatMessage('activities.accountants.paymentMethodCard.existingPaymentMethod.change.link'),
            Intent: 'edit-payment-method',
          });
          onOpenPaymentMethodDrawer();
        }}
      />
    ),
    isHidden: subscriptionBillingPayor !== SubscriptionBillingPayor.AccountingFirm,
    nextPayor: SubscriptionBillingPayor.AccountingFirm,
  };

  const limitedBillingOptionsBannerProps: BannerProps = {
    variant: 'informative',
    title: formatMessage('activities.accountants.paymentMethodCard.anonymous.payor.banner.title'),
    description:
      clientFundingSources?.length || clientSubscriptionBillingFundingSourceId
        ? formatMessage(
            'activities.accountants.paymentMethodCard.anonymous.payor.banner.description.withClientFundingSources',
            {
              allowedOption: formatMessage(
                'activities.accountants.assignPlan.form.billingDetails.fields.clientPays.label'
              ),
              contactSupport: unknownPayorContactSupportLink,
            }
          )
        : formatMessage(
            'activities.accountants.paymentMethodCard.anonymous.payor.banner.description.withoutClientFundingSources',
            {
              allowedOption: formatMessage(
                'activities.accountants.assignPlan.form.billingDetails.fields.clientPays.label'
              ),
              contactSupport: unknownPayorContactSupportLink,
              addClientPaymentMethod: (
                <Link
                  data-testid="limitied-blling-options-contact-support"
                  label={formatMessage(
                    'activities.accountants.paymentMethodCard.anonymous.payor.addClientFundingSource.action'
                  )}
                  href=""
                  onClick={(e) => {
                    track('Organization', 'Click', {
                      Cta: formatMessage(
                        'activities.accountants.paymentMethodCard.anonymous.payor.addClientFundingSource.action'
                      ),
                      Intent: 'add-payment-method',
                    });
                    e.preventDefault();
                    onNavigateToClientPaymentMethodsSettings?.();
                  }}
                />
              ),
            }
          ),
    isHidden: !!subscriptionBillingPayor,
    nextPayor: SubscriptionBillingPayor.Client,
  };

  const bannerPropsMapping: Record<BannerType, BannerProps> = {
    [BannerType.FirmToClientWithClientMissingFundingSources]: firmToClientWithClientMissingFundingSourcesBannerProps,
    [BannerType.FirmToClientWithClientFundingSources]: firmToClientWithClientFundingSourcesBannerProps,
    [BannerType.ClientToFirmWithFirmMissingBillingFundingSource]: clientToFirmWithFirmMissingBillingFundingSourceProps,
    [BannerType.ClientToFirmWithFirmHasBillingFundingSource]: clientToFirmWithFirmHasBillingFundingSourceBannerProps,
    [BannerType.LimitedBillingOptions]: limitedBillingOptionsBannerProps,
  };

  return { ...bannerPropsMapping[bannerType], bannerType };
};
