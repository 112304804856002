import { BankAccountFormModel, useAchValidationSchema } from '@melio/ap-widgets';
import { useAddDeliveryMethodUtils } from '@melio/ap-widgets/src/components/delivery-methods/cards/AddDeliveryMethodCard/utils';
import { Form, Text, useMelioForm } from '@melio/penny';
import { DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { StopFormPropagation } from '../../../../form-controls';
import { DeliveryMethodCardProps } from '../../types';
import { DeliveryMethodSectionCard } from '../DeliveryMethodSectionCard';

export const BankAccountCard = ({
  onSubmit,
  isExpanded,
  onAdd,
  onCancel,
  isDisabled,
  values,
  description,
}: DeliveryMethodCardProps<BankAccountFormModel>) => {
  const schema = useAchValidationSchema();
  const { formProps, registerField, handleSubmit } = useMelioForm<BankAccountFormModel>({
    onSubmit,
    schema,
    values,
  });

  const _onSubmit = handleSubmit();
  const { formatMessage } = useMelioIntl();
  // TODO: MarikSh Change supported to come from supported types
  const { getIconKey, getLabel, getFee } = useAddDeliveryMethodUtils({
    type: DeliveryMethodType.BankAccount,
    supported: true,
    suspense: true,
  });

  const fee = getFee(DeliveryMethodType.BankAccount);
  return (
    <DeliveryMethodSectionCard
      isExpanded={isExpanded}
      onAdd={onAdd}
      onCancel={onCancel}
      onSubmit={_onSubmit}
      isDisabled={isDisabled}
      label={getLabel()}
      icon={getIconKey()}
      pillProps={
        fee
          ? {
              label: fee,
            }
          : undefined
      }
      description={
        description ||
        (values &&
          formatMessage('activities.deliveryMethodsWidget.forms.bankAccount.added.description', {
            accountNumber: `${values.accountNumber.slice(-4)}`,
          }))
      }
      testId="bank-account-card"
    >
      <StopFormPropagation>
        <Form {...formProps} columns={12}>
          <Form.ContentBox colSpan={12}>
            <Text textStyle="body3" color="semantic.text.secondary">
              {formatMessage('activities.deliveryMethodsWidget.forms.bankAccount.description')}
            </Text>
          </Form.ContentBox>
          <Form.TextField
            colSpan={6}
            {...registerField('routingNumber')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.bankAccount.field.routingNumber'),
            }}
            maskProps={{
              mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
            }}
          />
          <Form.TextField
            data-private
            colSpan={6}
            {...registerField('accountNumber')}
            labelProps={{
              label: formatMessage('activities.deliveryMethodsWidget.forms.bankAccount.field.accountNumber'),
            }}
          />
        </Form>
      </StopFormPropagation>
    </DeliveryMethodSectionCard>
  );
};
