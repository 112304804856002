import { useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Button, ButtonProps, Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
type FooterButtonProps = Pick<ButtonProps, 'label' | 'isLoading' | 'onClick' | 'isDisabled'>;

export type FooterProps = {
  primaryButtonProps?: FooterButtonProps;
  secondaryButtonProps?: FooterButtonProps;
  tertiaryButtonProps?: FooterButtonProps;
  isDisabled?: boolean;
};

export const Footer = forwardRef<FooterProps>(
  ({ primaryButtonProps, secondaryButtonProps, tertiaryButtonProps, isDisabled, ...props }, ref) => {
    const isMobile = useIsMobile();
    const { formatMessage } = useMelioIntl();
    return (
      <Group
        spacing={isMobile ? 's' : 'm'}
        width="auto"
        variant={isMobile ? 'vertical' : 'horizontal'}
        justifyContent={isMobile ? 'space-between' : 'flex-start'}
        data-component={Footer.displayName}
        {...props}
        ref={ref}
      >
        {tertiaryButtonProps && (
          <Button
            {...{ label: formatMessage('ar.onboarding.layout.buttons.cancel.label'), ...tertiaryButtonProps }}
            data-testid="footer-tertiary-button"
            variant="tertiary"
          />
        )}
        <Group
          spacing={isMobile ? 's' : 'm'}
          width="full"
          variant="horizontal"
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
        >
          {secondaryButtonProps && (
            <Button
              {...{ label: formatMessage('ar.onboarding.layout.buttons.back.label'), ...secondaryButtonProps }}
              variant="secondary"
              data-testid="footer-secondary-button"
              isFullWidth={isMobile}
            />
          )}
          {primaryButtonProps && (
            <Button
              {...{
                label: formatMessage('ar.onboarding.layout.buttons.continue.label'),
                ...primaryButtonProps,
                isDisabled: isDisabled || primaryButtonProps.isDisabled,
              }}
              data-testid="footer-primary-button"
              isFullWidth={isMobile}
            />
          )}
        </Group>
      </Group>
    );
  }
);
Footer.displayName = 'Footer';
