import { useCollectionApi } from '@melio/api-client';
import { ApiKeys, RecommendedVendorsApiClient } from '@melio/platform-api-axios-client';

type Props = { disabled: boolean; timeout?: number };

export const useRecommendedVendors = (props: Props) =>
  useCollectionApi({
    enabled: !props.disabled,
    queryKey: ApiKeys.RecommendedVendorsApi,
    queryFn: () =>
      RecommendedVendorsApiClient.getRecommendedVendors(undefined, {
        timeout: props.timeout || 5000,
      }),
  });
