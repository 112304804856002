import { useMelioQueryClient } from '@melio/api-client';
import { ApiKeys, PostVendorGroupsRequest, VendorGroupsApiClient } from '@melio/platform-api-axios-client';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from '@tanstack/react-query';

export const useCreateVendorsGroup = () => {
  const queryClient = useMelioQueryClient();

  return useMutation({
    mutationKey: [ApiKeys.VendorGroupsApi],
    mutationFn: async ({ vendorsGroup }: { vendorsGroup: PostVendorGroupsRequest }) => {
      const response = await VendorGroupsApiClient.postVendorGroups(vendorsGroup);

      return response.data.data;
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(ApiKeys.VendorGroupsApi);
      void queryClient.invalidateQueries(ApiKeys.VendorsApi);
    },
  });
};
