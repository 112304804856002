import { ARInvoice } from '@melio/ar-api-axios-client';
import { PillProps } from '@melio/penny';

import { useMelioIntl } from './i18n';

export const useInvoiceStatusLabel = () => {
  const { formatMessage } = useMelioIntl();

  const getInvoiceStatusVariant = (status: ARInvoice['displayStatus']): PillProps['status'] => {
    const statusMap: Record<ARInvoice['displayStatus'], PillProps['status']> = {
      draft: 'neutral',
      inProcess: 'informative',
      open: 'informative',
      overdue: 'warning',
      paid: 'success',
      canceled: 'neutral',
    };
    return statusMap[status];
  };

  const getInvoiceStatusLabel = (status: ARInvoice['displayStatus']): string => {
    const labelMap: Record<ARInvoice['displayStatus'], string> = {
      draft: formatMessage('ar.dashboard.activities.invoiceStatus.draft.label'),
      inProcess: formatMessage('ar.dashboard.activities.invoiceStatus.inProcess.label'),
      overdue: formatMessage('ar.dashboard.activities.invoiceStatus.overdue.label'),
      paid: formatMessage('ar.dashboard.activities.invoiceStatus.paid.label'),
      open: formatMessage('ar.dashboard.activities.invoiceStatus.open.label'),
      canceled: formatMessage('ar.dashboard.activities.invoiceStatus.canceled.label'),
    };
    return labelMap[status];
  };

  return {
    getInvoiceStatusVariant,
    getInvoiceStatusLabel,
  };
};
