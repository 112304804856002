import { useEffect } from 'react';

import { useGuestPaymentIntent } from '../../hooks';
import { BaseLayout } from '../../layout';
import { GuestPaymentIntentParams, GuestPayorPaymentOptions } from '../../types';

type PaymentRoutingActivityProps = {
  guestPaymentIntentParams: GuestPaymentIntentParams;
  onDone: (next: GuestPayorPaymentOptions) => unknown;
  onError?: ARErrorFunction;
};

export const PaymentRoutingActivity = ({ guestPaymentIntentParams, onDone, onError }: PaymentRoutingActivityProps) => {
  const { isLoading, data: guestPaymentIntentData, error } = useGuestPaymentIntent({ guestPaymentIntentParams });

  const { vendorPaymentDetails } = guestPaymentIntentData || {};

  useEffect(() => {
    if (vendorPaymentDetails) {
      if (vendorPaymentDetails.paymentOptions.isCardAllowed) {
        onDone('card');
      } else if (vendorPaymentDetails.paymentOptions.isAchAllowed) {
        onDone('bank');
      } else {
        onDone('custom');
      }
    } else if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorPaymentDetails, error]);

  return <BaseLayout backgroundColor={undefined} isLoading={isLoading} />;
};
