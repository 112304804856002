import { useNavigate, useSearchParams } from 'react-router-dom';

import { useClientsOverview } from '../../../../../api/entities';
import { OverviewScope } from './types';
const OVERVIEW_SCOPE_QUERY_PARAM = 'overview-scope';

export const useDashboardOverview = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const scope = (searchParams.get(OVERVIEW_SCOPE_QUERY_PARAM) as OverviewScope) || OverviewScope.Days30;

  const query = useClientsOverview({ scope });

  const onScopeFilterChange = (value: OverviewScope) => {
    searchParams.set(OVERVIEW_SCOPE_QUERY_PARAM, value);
    navigate({ search: searchParams.toString() }, { replace: true, state: { keepToast: true } });
  };

  return {
    data: query.data,
    isFetching: query.isFetching,
    error: query.error,
    refetch: query.refetch,
    scope,
    onScopeFilterChange,
  };
};
