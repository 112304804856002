import { Icon } from '@melio/penny';
import { TodoActivateCollaborators, TodoActivateCollaboratorsTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useActivitiesNavigate } from '../../../../utils';
import { EnrichedTodo } from '../../utils';
import { TodoSection } from '../TodoSection';

export type TodoSectionActivateCollaboratorsProps = {
  todo?: EnrichedTodo<TodoActivateCollaborators>;
  onNavigate?: VoidFunction;
};

export const TodoSectionActivateCollaborators = ({ todo, onNavigate }: TodoSectionActivateCollaboratorsProps) => {
  const { formatMessage } = useMelioIntl();
  const { navigateToSettingsCollaborators } = useActivitiesNavigate();

  if (!todo || todo.items.pagination.totalCount === 0 || todo.items.pagination.totalCount == null) {
    return null;
  }

  const count = todo.items.pagination.totalCount;
  const title = formatMessage('widgets.todosDrawer.section.activateCollaborators.title', { count });
  const description = [{ label: formatMessage('widgets.todosDrawer.section.activateCollaborators.description') }];

  const action = {
    label: formatMessage('widgets.todosDrawer.section.action.manageCollaborators', { count }),
    onClick: () => {
      onNavigate?.();
      setTimeout(() => {
        navigateToSettingsCollaborators();
      });
    },
  };

  return (
    <TodoSection
      key={`section-${TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}`}
      icon={<Icon type="user-add" color="brand" />}
      title={title}
      description={description}
      action={action}
      type={TodoActivateCollaboratorsTypeEnum.ActivateCollaborators}
      newItemsCount={todo.enrichment.newItemsCount}
    />
  );
};
