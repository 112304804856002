import { Box } from '@chakra-ui/react';
import {
  Card,
  Container,
  Form,
  Grid,
  Group,
  Icon,
  SegmentedControl,
  Text,
  useBreakpoint,
  useMelioForm,
} from '@melio/penny';
import { Address, InternationalAddress, US_STATES } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { defaults } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FormWidgetProps } from '../../../../types';
import { AddressSearchWidget, AddressSearchWidgetProps } from '../../../form-controls';
import { CheckAccountFormModel } from '../types';
import { useCheckValidationSchema } from '../useCheckValidationSchema';

type Props = FormWidgetProps<CheckAccountFormModel> & {
  vendorAddress?: InternationalAddress | null;
};

export const VendorCheckDetailsPayorFormV2 = ({
  onSubmit,
  vendorAddress,
  defaultValues: _defaultValues,
  isSaving,
  onSubmissionStateChange,
  ...props
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const defaultValues = useMemo(
    () =>
      defaults(_defaultValues, {
        printName: '',
        line1: null,
        line2: '',
        city: '',
        state: '',
        postalCode: '',
      }),
    [_defaultValues]
  );

  const existingVendorAddress = vendorAddress
    ? `${vendorAddress?.line1 || ''}, ${vendorAddress?.city || ''}, ${vendorAddress?.state || ''} ${
        vendorAddress?.postalCode || ''
      }`
    : null;

  const { formProps, registerField, setValue, getValues, watch } = useMelioForm<CheckAccountFormModel>({
    onSubmit,
    schema: useCheckValidationSchema(),
    defaultValues,
    isSaving,
    onSubmissionStateChange,
    subscribeToDefaultValuesChanges: true,
  });
  const { isExtraSmallScreen: isMobile } = useBreakpoint();
  const [selectedOption, setSelectedOption] = useState('vendorAddress');

  const line1Value = watch('line1');
  const showAddressFields = Boolean(line1Value);

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const isFormValueCleared = address === null && getValues('line1');

    if (isFormValueCleared) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' search works properly only after 2nd search input
      setValue('line1', null);
    }

    const setFormField = (field: keyof Omit<CheckAccountFormModel, 'printName'>) => {
      if (address?.[field]) {
        setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };

    setFormField('line1');
    setFormField('line2');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  useEffect(() => {
    if (selectedOption === 'vendorAddress') {
      setValue('line1', vendorAddress?.line1 || '');
      setValue('line2', vendorAddress?.line2 || '');
      setValue('state', vendorAddress?.state || '');
      setValue('city', vendorAddress?.city || '');
      setValue('postalCode', vendorAddress?.postalCode || '');
    }
  }, [selectedOption, setValue, vendorAddress]);

  const clearForm = (option: string) => {
    if (option === 'differentAddress') {
      setValue('line1', '');
      setValue('line2', '');
      setValue('state', '');
      setValue('city', '');
      setValue('postalCode', '');
    }
  };

  return (
    <Form data-component="VendorCheckDetailsPayorFormV2" {...props} {...formProps} columns={2}>
      <Form.TextField
        colSpan={2}
        labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.label') }}
        placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.placeholder')}
        helperTextProps={{
          label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.printName.helperText'),
        }}
        {...registerField('printName')}
      />

      <Grid column={isMobile ? 1 : 'span 2/span 2'}>
        <SegmentedControl
          isFullWidth
          data-testid="vendor-address-segment-control"
          name="main"
          onChange={(e) => {
            setSelectedOption(e.target.value);
            clearForm(e.target.value);
          }}
        >
          <SegmentedControl.Segment
            data-testid="segment-vendor-address"
            value="vendorAddress"
            checked={selectedOption === 'vendorAddress'}
          >
            {formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.options.vendorAddress')}
          </SegmentedControl.Segment>
          <SegmentedControl.Segment
            data-testid="segment-different-address"
            value="differentAddress"
            checked={selectedOption === 'differentAddress'}
          >
            {formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.options.differentAddress')}
          </SegmentedControl.Segment>
        </SegmentedControl>
      </Grid>
      {selectedOption === 'differentAddress' && (
        <AddressSearchWidget
          {...registerField('line1')}
          colSpan={2}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Type of formatSelectedValue will be fixed & data can be string | undefined | Address
          formatSelectedValue={(data): Address | string => (data.value as unknown as Address)?.line1 ?? data.value}
          labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line1.label') }}
          placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line1.placeholder')}
          onChange={handleAddressChange}
        />
      )}
      {selectedOption === 'differentAddress' && showAddressFields && (
        <>
          <Form.TextField
            colSpan={2}
            labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line2.label') }}
            placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.line2.placeholder')}
            {...registerField('line2')}
          />
          <Form.TextField
            colSpan={2}
            labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.label') }}
            placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.city.placeholder')}
            {...registerField('city')}
          />
          <Form.Select
            labelProps={{ label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.state.label') }}
            placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.state.placeholder')}
            {...registerField('state')}
            options={US_STATES.map((state) => ({
              value: state,
              label: formatMessage(`local.USA.states.${state}`),
              testId: state,
            }))}
            emptyState={{ label: formatMessage('form.select.default.emptyState.label') }}
          />
          <Form.TextField
            labelProps={{
              label: formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.postalCode.label'),
            }}
            placeholder={formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.postalCode.placeholder')}
            {...registerField('postalCode')}
          />
        </>
      )}
      {selectedOption === 'vendorAddress' && (
        <Grid column={isMobile ? 1 : 'span 2/span 2'}>
          <Card data-testid="vendor-check-details-existing-card" variant="flat">
            <Container width="full" justifyContent="flex-start" alignItems="center">
              <Box height="full" pr="s">
                <Icon type="vendor" size="large" aria-hidden />
              </Box>
              <Group variant="vertical" spacing="none">
                <Box>
                  <Text textStyle="body4Semi" color="global.neutral.900">
                    {formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.card.title')}
                  </Text>
                </Box>
                <Box>
                  <Text textStyle="body2Semi">{existingVendorAddress}</Text>
                </Box>
                <Box>
                  <Text textStyle="body4" color="global.neutral.900">
                    {formatMessage('widgets.deliveryMethods.vendorCheckDetailsPayorForm.card.description')}
                  </Text>
                </Box>
              </Group>
            </Container>
          </Card>
        </Grid>
      )}
    </Form>
  );
};

VendorCheckDetailsPayorFormV2.displayName = 'VendorCheckDetailsPayorFormV2';
