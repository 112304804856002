import { Link, SectionBanner } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useNavigate } from '@melio/platform-utils';
import { kebabCase } from 'lodash';

import { useShowMultiAccountBanner } from './useShowMultiAccountBanner';

export const MultiAccountBanner = () => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();
  const { track } = useAnalytics();
  const showBanner = useShowMultiAccountBanner();
  const ctaLink = links['activities.multiAccountBanner.cta.href'];
  const navigate = useNavigate();

  useAnalyticsView('Dashboard', showBanner, true, {
    UserMessage: 'activate-all-your-accounts',
  });

  if (!showBanner || !ctaLink) {
    return null;
  }

  const ctaText = formatMessage('activities.multiAccountBanner.cta.label');

  const handleClick = () => {
    track('Dashboard', 'Click', {
      UserMessage: 'activate-all-your-accounts',
      Cta: kebabCase(ctaText),
    });
    navigate('/settings/support');
  };

  return (
    <SectionBanner
      title={formatMessage('activities.multiAccountBanner.title')}
      description={formatMessage('activities.multiAccountBanner.description')}
      action={<Link href="#" onClick={handleClick} label={ctaText} />}
    />
  );
};
