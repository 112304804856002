import { CurrencyTooltip } from '@melio/ap-widgets';
import { AmountField, Table } from '@melio/penny';
import { Vendor } from '@melio/platform-api';

import { MAX_PAYMENT_AMOUNT_DIGITS } from '../consts';

type VendorsTabAmountInputCellProps = {
  value?: string;
  vendor: Vendor;
  onAmountChange: (vendor: Vendor, value: string) => void;
};

export const VendorsTabAmountInputCell = ({ value, vendor, onAmountChange }: VendorsTabAmountInputCellProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue !== value) {
      onAmountChange(vendor, newValue);
    }
  };

  return (
    <Table.Cell data-testid={`vendors-tab-amount-input-cell-${vendor.id}`}>
      <CurrencyTooltip currency={vendor.currency}>
        <AmountField
          currency={vendor.currency ?? undefined}
          aria-label="Total amount"
          data-testid={`vendors-tab-amount-input-${vendor.id}`}
          size="small"
          align="end"
          value={value}
          onChange={handleChange}
          allowNegativeValue={false}
          integerLimit={MAX_PAYMENT_AMOUNT_DIGITS}
          onClick={(event) => event.stopPropagation()}
        />
      </CurrencyTooltip>
    </Table.Cell>
  );
};
