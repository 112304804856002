import { useGuestPayorOtp } from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef, useDebounce } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { useUserState } from '../../hooks';
import { GuestPaymentIntentParams } from '../../types';
import { AuthenticationModalScreen } from './screens';

export type AuthenticationModalActivityProps = {
  onDone: VoidFunction;
  onClose: VoidFunction;
  isOpen: boolean;
  email: string;
  partnerName: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onError?: ARErrorFunction;
  isLoading?: boolean;
  guestPaymentIntentParams: GuestPaymentIntentParams;
};

const SECONDS_TO_ENABLE_RESEND = 30;

export const AuthenticationModalActivity = withAnalyticsContext<AuthenticationModalActivityProps>(
  forwardRef(
    (
      {
        onError,
        onLoggedIn,
        partnerName,
        email,
        isOpen,
        onDone,
        onClose,
        setAnalyticsProperties,
        isLoading,
        guestPaymentIntentParams,
      },
      ref
    ) => {
      const { sendVerificationCode, verifyCode, isLoadingVerifyCode } = useGuestPayorOtp();
      const [errorCode, setErrorCode] = useState<string>();
      const [didAuthenticate, setDidAuthenticate] = useState(false);
      const { track } = useAnalytics();
      const [secToResend, setSecToResend] = useState<number>(SECONDS_TO_ENABLE_RESEND);
      const isDisabled = secToResend > 0;

      // check if the user is already authenticated and complete the flow if they are
      const { isAuthenticated } = useUserState({ guestPaymentIntentParams, email });
      useEffect(() => (!didAuthenticate && isAuthenticated ? onDone() : undefined), [isAuthenticated, didAuthenticate]); // eslint-disable-line react-hooks/exhaustive-deps

      setAnalyticsProperties({
        PageName: 'verification-code',
        Intent: 'verification',
        AuthenticatorType: 'email',
        EligibilityType: 'email',
      });

      const handleError = (error: ARPlatformError) => {
        track('PaymentRequest', 'Status', { StatusType: 'failure', ErrorType: error.message });
        setErrorCode(error.errorCode);
      };

      const onSendEmailVerificationCode = () => {
        setErrorCode(undefined);
        sendVerificationCode({ email, partnerName }).catch((error: ARPlatformError) => {
          onClose();
          onError?.(error);
        });
      };

      useEffect(() => {
        if (isOpen && !isAuthenticated) {
          void onSendEmailVerificationCode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isOpen]);

      useEffect(() => {
        if (!isOpen) {
          setSecToResend(SECONDS_TO_ENABLE_RESEND);
          return;
        }

        const resendIntervalId = setInterval(() => {
          if (secToResend > 0) {
            setSecToResend((prevCount) => prevCount - 1);
          }
        }, 1000);

        return () => {
          clearInterval(resendIntervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isDisabled, isOpen]);

      const onSubmitCodeVerification = async (code: string, email: string) => {
        try {
          setErrorCode(undefined);
          const { accessToken, refreshToken } = await verifyCode({
            email,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            partnerName,
            otp: code,
          });
          await onLoggedIn?.(accessToken, refreshToken);
          track('PaymentRequest', 'Status', {
            StatusType: 'success',
          });
          setDidAuthenticate(true);
          onDone();
        } catch (error) {
          handleError(error as ARPlatformError);
        }
      };

      // Because the router "currentUrl" changes after its moved to the next activity
      // theres a short period where isLoading is false and the screen flickers.
      // Adding a short debounce prevents this from affecting the UI.
      const isVisuallyLoading = useDebounce(isLoading || isLoadingVerifyCode, 10);

      // hide the screen when the user was authenticated to begin with
      if (isAuthenticated && !didAuthenticate) return null;

      return (
        <AuthenticationModalScreen
          errorCode={errorCode}
          onResetResendTimer={() => setSecToResend(SECONDS_TO_ENABLE_RESEND)}
          secToResend={secToResend}
          isLoading={isVisuallyLoading}
          email={email}
          onClose={() => {
            setErrorCode(undefined);
            onClose();
          }}
          isOpen={isOpen}
          generateNewCode={onSendEmailVerificationCode}
          onCodeComplete={onSubmitCodeVerification}
          ref={ref}
        />
      );
    }
  )
);
