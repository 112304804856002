import { Box } from '@chakra-ui/react';
import { Skeleton, SkeletonCircle } from '@melio/penny';

export const CellLoadingWrapper: React.FC<{ isLoading: boolean; withCircleLoader?: boolean }> = ({
  isLoading,
  withCircleLoader,
  children,
}) =>
  isLoading ? (
    <Box width="200px" justifyContent="space-between" display="flex" alignItems="center">
      {withCircleLoader && <SkeletonCircle />}
      <Skeleton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        sx={{
          width: '170px',
        }}
      />
    </Box>
  ) : (
    <>{children}</>
  );
