import { useUpdatedPaymentRequestLink } from '@melio/ar-domain';

import { GuestPaymentIntentParams, PaymentRequestStatusType } from '../types';
import { useGuestPaymentIntent } from './useGuestPaymentIntent';

type LoadingResults = {
  isLoading: true;
  paymentRequestLink?: never;
  status?: never;
};

type LoadedResults = {
  isLoading: false;
  paymentRequestLink: string;
  status: PaymentRequestStatusType;
};

type DisabledResults = {
  isLoading: false;
  paymentRequestLink?: never;
  status: 'vendor-handle';
};

type PaymentRequestStatusResult = LoadingResults | LoadedResults | DisabledResults;

export const usePaymentRequestStatus = (
  guestPaymentIntentParams: GuestPaymentIntentParams
): PaymentRequestStatusResult => {
  const { paymentRequestLink } = guestPaymentIntentParams;

  const {
    isError: isGuestPaymentIntentError,
    isLoading: isGuestPaymentIntentLoading,
    data,
  } = useGuestPaymentIntent({ guestPaymentIntentParams });

  const {
    isLoading: isUpdatedPaymentRequestLinkLoading,
    error: updatedPaymentRequestLinkError,
    data: updatedPaymentRequestLinkData,
  } = useUpdatedPaymentRequestLink({
    id: paymentRequestLink,
    enabled: isGuestPaymentIntentError,
  });

  if (!paymentRequestLink || data?.type === 'vendor-handle') {
    return { isLoading: false, status: 'vendor-handle' };
  }

  if (isGuestPaymentIntentLoading || isUpdatedPaymentRequestLinkLoading) {
    return { isLoading: true };
  }

  const target = { isLoading: false, paymentRequestLink } as const;

  if (data?.receivablePaymentDetails?.paidAt) {
    return { ...target, status: 'paid' };
  }

  if (updatedPaymentRequestLinkError?.errorCode == 'invoiceCancelled') {
    return { ...target, status: 'cancelled' };
  }

  if (updatedPaymentRequestLinkData?.updatedPaymentRequestLink) {
    return {
      ...target,
      status: 'updated',
      paymentRequestLink: updatedPaymentRequestLinkData.updatedPaymentRequestLink,
    };
  }

  if (data?.vendorPaymentDetails) {
    const {
      paymentOptions: { isAchAllowed, isCardAllowed },
    } = data.vendorPaymentDetails;

    if (isAchAllowed || isCardAllowed) {
      return { ...target, status: 'unpaid' };
    }

    return { ...target, status: 'no-payment-option' };
  }

  return { ...target, status: 'error' };
};
