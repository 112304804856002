import {
  BusinessItemDirectoryName,
  FiservBusinessesResultBusiness,
  MsnBusiness,
  useBusinesses,
  useSearchBusinesses,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { usePartnerFeature } from '@melio/platform-provider';
import { useMemo } from 'react';

type Props = {
  searchTerm: string;
};

export type UnifiedBusinessItem =
  | {
      directoryName: typeof BusinessItemDirectoryName.Fiserv;
      business: FiservBusinessesResultBusiness;
    }
  | {
      directoryName: typeof BusinessItemDirectoryName.Msn;
      business: MsnBusiness;
    };

export const useVendorSelectBusinesses = ({ searchTerm }: Props) => {
  const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
    FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
    false
  );
  const [isNewBusinessesSearchEnabled] = usePartnerFeature('NewBusinessesSearch', false);

  const { isLoading: isLoadingFiservBusinessItems, data: fiservBusinessItems } = useBusinesses({
    params: { searchTerm },
    enabled: searchTerm.length > 2 && isSearchBusinessesInDirectoriesSupported && !isNewBusinessesSearchEnabled,
  });

  const { isLoading: isLoadingAllBusinessItems, data: allBusinessItems } = useSearchBusinesses({
    params: { searchTerm },
    enabled: searchTerm.length > 2 && isNewBusinessesSearchEnabled,
  });

  const data: UnifiedBusinessItem[] = useMemo(() => {
    if (allBusinessItems?.msn && allBusinessItems.msn.length > 0) {
      return allBusinessItems.msn.map(({ business }) => ({
        directoryName: BusinessItemDirectoryName.Msn,
        business,
      }));
    }

    if (fiservBusinessItems && fiservBusinessItems.length > 0) {
      return fiservBusinessItems.map(({ business }) => ({
        directoryName: BusinessItemDirectoryName.Fiserv,
        business,
      }));
    }

    return [];
  }, [fiservBusinessItems, allBusinessItems]);

  return {
    isDirectoryEnabled: isSearchBusinessesInDirectoriesSupported || isNewBusinessesSearchEnabled,
    isLoading: isLoadingFiservBusinessItems || isLoadingAllBusinessItems,
    directoryName: isNewBusinessesSearchEnabled
      ? BusinessItemDirectoryName.Msn
      : isSearchBusinessesInDirectoriesSupported
      ? BusinessItemDirectoryName.Fiserv
      : null,
    data,
  };
};
