import { Group, Link, SectionBanner, Table, Text, Typography, useTable } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import _compact from 'lodash/compact';
import { useCallback } from 'react';

import { PaymentLateApprovalTableMeta } from '../types';
import { usePaymentsLateApprovalTableColumns } from '../usePaymentsLateApprovalTableColumns';
import { PendingPaymentListItem } from './PendingPaymentListItem';

type Props = {
  approvedPayments: Payment[];
  pendingPayments: Payment[];
  changedFields: PaymentLateApprovalTableMeta['changedFields'];
};
export const PaymentsLateApprovalModalBody = ({ pendingPayments, approvedPayments, changedFields }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { links } = useConfig();

  const mobileRowRenderer = useCallback(
    (payment: Payment, meta?: PaymentLateApprovalTableMeta) => (
      <PendingPaymentListItem
        payment={payment}
        newDebitDate={meta?.changedFields[payment.id]?.date?.minScheduledDate}
        newDeliveryDate={meta?.changedFields[payment.id]?.date?.deliveryDate}
        fxQuote={meta?.changedFields[payment.id]?.quote}
      />
    ),
    []
  );

  const columns = usePaymentsLateApprovalTableColumns();
  const tableProps = useTable({
    isHeaderSticky: true,
    headerVariant: 'dark',
    data: pendingPayments,
    mobileRowRenderer,
    columns,
    meta: {
      changedFields,
    },
  });

  const hasRatesChanged = Object.values(changedFields).some((field) => field.quote);
  const hasDatesChanged = Object.values(changedFields).some((field) => field.date);

  return (
    <Group spacing="m" variant="vertical" data-testid="pending-approval-payments-modal-body-content">
      <SectionBanner
        variant="informative"
        hideIcon
        data-testid="pending-payments-section-banner"
        description={
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body3Semi" data-testid="pending-payments-section-banner-pending-text">
              {formatMessage('activities.paymentsLateApprovalModal.banner.title', {
                numPendingPayments: pendingPayments.length,
              })}
            </Text>
            {approvedPayments.length > 0 ? (
              <Text textStyle="body3Semi" data-testid="pending-payments-section-banner-approved-text">
                {formatMessage('activities.paymentsLateApprovalModal.banner.titleApproved', {
                  numApprovedPayments: approvedPayments.length,
                })}
              </Text>
            ) : null}
            <Typography.ParagraphList
              data-testid="pending-payments-section-banner-paragraph-list"
              list={_compact([
                hasDatesChanged
                  ? { content: formatMessage('activities.paymentsLateApprovalModal.banner.list.dateChanged') }
                  : null,
                hasRatesChanged
                  ? { content: formatMessage('activities.paymentsLateApprovalModal.banner.list.rateChanged') }
                  : null,
              ])}
              type="unordered"
              size="small"
            />
          </Group>
        }
        action={
          hasRatesChanged && (
            <Link
              data-testid="pending-payments-section-banner-link"
              label={formatMessage('activities.paymentsLateApprovalModal.banner.ratesLink')}
              href={links['widgets.learnAboutRates.href']}
              newTab
              size="medium"
            />
          )
        }
      />

      <Table {...tableProps} />
    </Group>
  );
};
