import { Link, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { useBoolean } from '@melio/platform-utils';

import { CheckProtectionModalWidget } from '../../CheckProtectionModal';
import { fundsDebitDateResolver } from '../fundsDebitDateSectionUtils/fundsDebitDateSection.utils';
import { FundsDebitDateSectionProps } from '../types';

export const DeliveryDateFundsDebitDateBanner = ({
  deliveryType,
  scheduledDate,
  deliveryDate,
}: FundsDebitDateSectionProps) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const [showCheckProtectionModal, { toggle: toggleShowCheckProtectionModal }] = useBoolean(false);
  const { isDmCheck, daysToDebit } = fundsDebitDateResolver({ deliveryType, scheduledDate, deliveryDate });

  if (daysToDebit === 0) {
    return null;
  }

  const onClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    toggleShowCheckProtectionModal();
  };

  const getDescriptionContent = () =>
    isDmCheck
      ? formatMessage(`widgets.fundsDebitDateSection.description.check`, {
          link: (
            <Link
              data-testid="funds-debit-date-section-link"
              onClick={onClick}
              label={formatMessage(`widgets.fundsDebitDateSection.check.linkText`)}
              href="#"
            />
          ),
        })
      : formatMessage('widgets.fundsDebitDateSection.description', { daysToDebit });

  return (
    <>
      <SectionBanner
        data-testid="funds-debit-date-section"
        title={formatMessage('widgets.fundsDebitDateSection.title', {
          debitDate: formatDate(scheduledDate, { dateStyle: 'medium' }),
        })}
        description={getDescriptionContent()}
        variant="informative"
      />
      <CheckProtectionModalWidget isOpen={showCheckProtectionModal} onClose={toggleShowCheckProtectionModal} />
    </>
  );
};
