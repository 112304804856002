import { useRoleText } from '@melio/ap-domain';
import { Form, FormMultiSelectOption, Group, Pill, PillProps, Text } from '@melio/penny';
import { useCollaborators } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

import { useFormContext } from '../FormContext';

function useSpecificSchedulersOptions() {
  const { getRoleText } = useRoleText();

  return useCollaborators<FormMultiSelectOption<string>>({
    expand: ['user'],
    select(res) {
      return res
        .filter(({ approvalActions: { canApprovePayments } }) => !canApprovePayments)
        .map((collaborator) => ({
          label: `${collaborator.user?.firstName} ${collaborator.user?.lastName}`,
          value: collaborator.userId,
          testId: `specific-collaborator-${collaborator.userId}`,
          pillProps: {
            label: getRoleText(collaborator.roleUniqueName),
            status: 'neutral',
          },
        }));
    },
  });
}

export function CollaboratorsSelector({ index }: { index: number }) {
  const { formatMessage } = useMelioIntl();
  const { registerField } = useFormContext();

  const field = registerField(`criteria.conditions.${index}.condition.configuration.ids`);

  const { data, isLoading } = useSpecificSchedulersOptions();

  const optionRenderer = (option: FormMultiSelectOption<string> & { pillProps?: PillProps }) => (
    <Group data-testid={option.testId} spacing="xxs" alignItems="center">
      <Text textStyle="inline" color="inherit" shouldSupportEllipsis>
        {option.label}
      </Text>
      {option.pillProps && <Pill type="secondary" {...option.pillProps} />}
    </Group>
  );

  return (
    <Form.MultiSelect
      {...registerField(`criteria.conditions.${index}.condition.configuration.ids`)}
      aria-label="specific collaborators"
      data-testid="specific-collaborators-list"
      placeholder={formatMessage(
        'activities.create-approval-workflows.conditions.scheduler.options.specific.placeholder'
      )}
      options={data ?? []}
      isDisabled={isLoading}
      error={
        field.error
          ? { message: formatMessage('activities.create-approval-workflows.conditions.scheduler.errors.required') }
          : undefined
      }
      size="large"
      optionRenderer={optionRenderer}
    />
  );
}
