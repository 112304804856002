import { CardHolderDetailsForm, PCIComplienceInfo, TBTFormWidget, TBTFormWidgetFields } from '@melio/ap-widgets';
import { useTbt } from '@melio/form-controls';
import { Button, Container, Divider, Drawer, Group, Text, useFormSubmissionController } from '@melio/penny';
import { CardHolderDetails, FundingSource, useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useBoolean, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onDone?: (createdFs: FundingSource) => void;
};
const _AddCardAccountDrawerActivity = ({ isOpen, onClose, onCancel, onDone }: Props) => {
  const { formatMessage } = useMelioIntl();
  const [isInitialLoading, initialLoadingControl] = useBoolean(true);
  const [isLoading, isLoadingControl] = useBoolean();
  const { onSubmissionStateChange: onCardDetailsFormStateChange, submitButtonProps } =
    useFormSubmissionController<TBTFormWidgetFields>();
  const {
    onSubmissionStateChange: onAccountHolderFormStateChange,
    trigger: validateCardHolderForm,
    getValues,
  } = useFormSubmissionController<CardHolderDetails>();

  const onCreateSuccess = (fundingSource: FundingSource) => {
    isLoadingControl.off();
    return onDone?.(fundingSource);
  };
  const onError = (err: Error) => {
    isLoadingControl.off();
    showMessage({
      type: 'error',
      title: err.message || formatMessage('widgets.addCardFundingSourceDrawer.generic-error-message'),
    });
  };

  const onTokenizeError = () => {
    isLoadingControl.off();
    showMessage({
      type: 'error',
      title: formatMessage('widgets.addCardFundingSourceDrawer.generic-error-message'),
    });
  };

  const { verifyCard, create: createFundingSource } = useFundingSources({
    enabled: false,
    onCreate: onCreateSuccess,
    onVerifyError: onError,
    onCreateError: onError,
  });
  const { showMessage, hideMessage } = useSystemMessage();

  const { tokenize } = useTbt();

  const onCardDetailsSubmitted = async (fields: TBTFormWidgetFields) => {
    const isCardHolderFormValid = await validateCardHolderForm?.();

    if (!isCardHolderFormValid) {
      return;
    }

    hideMessage();
    isLoadingControl.on();

    const cardData = await tokenize(fields).catch(onTokenizeError);

    if (!cardData) {
      return;
    }

    await verifyCard({
      cardBin: cardData.cardBin,
      tabapayToken: cardData.tabapayToken,
      tokenProvider: cardData.tokenProvider,
    });

    const cardHolderDetailsValues = getValues?.();
    if (!cardHolderDetailsValues) {
      return;
    }

    void createFundingSource({
      type: 'card',
      details: {
        cardBin: cardData.cardBin,
        expirationMonth: cardData.expirationMonth,
        expirationYear: cardData.expirationYear,
        cardOwner: {
          firstName: cardHolderDetailsValues.firstName,
          lastName: cardHolderDetailsValues.lastName,
        },
        lastFourDigits: cardData.lastFourDigits,
        address: {
          city: cardHolderDetailsValues.city,
          line1: cardHolderDetailsValues.line1,
          postalCode: cardHolderDetailsValues.postalCode,
          state: cardHolderDetailsValues.state,
        },
        tabapayToken: cardData.tabapayToken,
        tokenProvider: cardData.tokenProvider,
      },
    });
  };

  const onSubmit = () => {
    validateCardHolderForm?.();
    submitButtonProps?.onClick();
  };

  return (
    <Drawer
      data-testid="add-card-account-drawer"
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Text as="h2" textStyle="heading2Semi">
          {formatMessage('widgets.addCardFundingSourceDrawer.title')}
        </Text>
      }
      body={
        <>
          <Container>
            <Group variant="vertical" spacing="m">
              <SystemMessageDisplay data-testid="add-card-account-drawer-error-message" />
              {!isInitialLoading && (
                <>
                  <Text color="global.neutral.900" textStyle="body4">
                    {formatMessage('widgets.addCardFundingSourceDrawer.form.cardDetails.requiredFields')}
                  </Text>
                  <Text as="h3" textStyle="heading3Semi">
                    {formatMessage('widgets.addCardFundingSourceDrawer.form.cardDetails.title')}
                  </Text>
                </>
              )}
              <TBTFormWidget
                onSubmit={onCardDetailsSubmitted}
                onSubmissionStateChange={onCardDetailsFormStateChange}
                isSaving={isLoading}
                onReady={initialLoadingControl.off}
              />
              {!isInitialLoading && (
                <>
                  <PCIComplienceInfo
                    textStyle="caption1"
                    privacyIconProps={{
                      height: '24px',
                      width: '20px',
                    }}
                    pciIconProps={{
                      height: '24px',
                      width: '62px',
                    }}
                  />
                  <Container paddingY="m">
                    <Divider />
                  </Container>
                  <Text as="h3" textStyle="heading3Semi">
                    {formatMessage('widgets.addCardFundingSourceDrawer.form.cardHolderDetails.title')}
                  </Text>
                  <CardHolderDetailsForm
                    onSubmit={() => null}
                    onSubmissionStateChange={onAccountHolderFormStateChange}
                    isSaving={isLoading}
                  />
                </>
              )}
            </Group>
          </Container>
        </>
      }
      footer={
        !isInitialLoading && (
          <>
            <Container>
              <Group justifyContent="space-between">
                <Button
                  label={formatMessage('widgets.addCardFundingSourceDrawer.form.cancel')}
                  variant="secondary"
                  onClick={onCancel}
                  data-testid="add-card-account-drawer-cancel"
                  isDisabled={isLoading}
                />
                <Button
                  label={formatMessage('widgets.addCardFundingSourceDrawer.form.submit')}
                  onClick={onSubmit}
                  data-testid="add-card-account-drawer-submit"
                  isLoading={isLoading}
                />
              </Group>
            </Container>
          </>
        )
      }
    />
  );
};

export const AddCardAccountDrawerActivity = withSystemMessageProvider(_AddCardAccountDrawerActivity);
