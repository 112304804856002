import { DateRange } from '@melio/ar-domain';
import { useDateUtils } from '@melio/platform-utils';
import { format } from 'date-fns';

export const useExportFileName = (dateRange?: DateRange): string => {
  const { createDate } = useDateUtils();
  const today = format(createDate(), 'yyyy-MM-dd');

  if (dateRange?.start && dateRange.end) {
    return `${dateRange.start}-${dateRange.end}-receivables.csv`;
  } else if (dateRange?.start) {
    return `${dateRange.start}-${today}-receivables.csv`;
  } else if (dateRange?.end) {
    return `${dateRange.end}-receivables.csv`;
  } else {
    return `${today}-receivables.csv`;
  }
};
