import { Drawer, Group, SectionBanner } from '@melio/penny';
import { EventProperties, useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  BusinessItemDirectoryName,
  CreateVendorAnalyticsMetadataFlow,
  CreateVendorParams,
  ModelError,
  MsnBusiness,
  useVendors,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { getErrorsByType, getInlineApiErrorsFields, useBannerApiError } from '../../apiErrorsUtils';
import { MsnBusinessPreviewCard } from '../../MsnBusinessPreviewCard';
import { ErrorTypeToErrorCodesMap, VendorFormBannerApiErrorCode } from '../../types';
import { AddVendorFormFields } from '../AddVendorForm';
import { AddMsnVendorDrawerFooter } from './AddMsnVendorDrawerFooter.widget';
import { AddMsnVendorDrawerHeader } from './AddMsnVendorDrawerHeader.widget';

type TrackCreateVendorStatusParams = {
  status: 'success' | 'failure';
  vendorId?: string;
  errorType?: string;
  inlineErrorsFields?: (keyof AddVendorFormFields)[];
  bannerErrorsTypes?: VendorFormBannerApiErrorCode[];
};

type AnalyticsMetadata = {
  flow: CreateVendorAnalyticsMetadataFlow;
};

type Props = {
  isOpen: boolean;
  msnBusiness?: MsnBusiness;
  analyticsProperties?: EventProperties;
  analyticsMetadata?: AnalyticsMetadata;
  onClose: VoidFunction;
  onDone: (vendor: Vendor) => void;
};

export const AddMsnVendorDrawerWidget = withSystemMessageProvider(
  withAnalyticsContext<Props>(
    ({ isOpen, msnBusiness, analyticsProperties, analyticsMetadata, onClose, onDone, setAnalyticsProperties }) => {
      const { formatMessage } = useMelioIntl();
      const { showMessage } = useSystemMessage();
      const { track, trackMarketing } = useAnalytics();

      const [apiErrorCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();
      const { error } = useBannerApiError(apiErrorCodes?.banner);

      const { create: createVendor, isMutating: isCreatingVendor } = useVendors({ enabled: false });

      setAnalyticsProperties({
        PageName: 'search-vendor',
        Intent: 'add-a-vendor',
        Flow: 'bill',
        VendorType: 'directory',
        ...(analyticsProperties?.PageEntryPoint ? { PageEntryPoint: analyticsProperties.PageEntryPoint } : {}),
      });

      useEffect(() => {
        if (isOpen) {
          track('Vendor', 'View', {
            BusinessId: msnBusiness?.id,
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isOpen, track]);

      const handleSubmitClick = () => {
        track(`Vendor`, 'Click', {
          Cta: 'add-vendor',
          BusinessId: msnBusiness?.id,
        });

        if (!msnBusiness) {
          return;
        }

        setApiErrorsCodes(undefined);

        const dataWithAnalyticsMetadata: CreateVendorParams = {
          businessDirectoryExternalId: msnBusiness.id,
          name: msnBusiness.name ?? '',
          contact: {
            name: msnBusiness.contact?.name,
            email: msnBusiness.contact?.email,
            phoneNumber: msnBusiness.contact?.phoneNumber,
            address: msnBusiness.contact?.address,
          },
          directoryName: BusinessItemDirectoryName.Msn,
          analyticsMetadata: {
            flow: analyticsMetadata?.flow ?? CreateVendorAnalyticsMetadataFlow.Bill,
          },
        };

        createVendor(dataWithAnalyticsMetadata)
          .then((vendor) => handleCreateVendorSuccess(vendor))
          .catch((error) => handleCreateVendorFail(error as ModelError, dataWithAnalyticsMetadata));
      };

      const handleCloseClick = () => {
        track('Vendor', 'Click', {
          Cta: 'exit',
          BusinessId: msnBusiness?.id,
        });
        onClose();
      };

      const handleCancelClick = () => {
        track('Vendor', 'Click', {
          Cta: 'cancel',
          BusinessId: msnBusiness?.id,
        });
        onClose();
      };

      const trackCreateVendorStatus = ({
        status,
        vendorId,
        errorType,
        inlineErrorsFields,
        bannerErrorsTypes,
      }: TrackCreateVendorStatusParams) => {
        const properties = {
          PageName: 'search-vendor',
          Intent: 'add-a-vendor',
          Flow: 'bill',
          VendorType: 'directory',
          Status: status,
          InlineErrorsFields: inlineErrorsFields,
          BannerErrorsTypes: bannerErrorsTypes,
          ...(analyticsProperties ?? {}),
          ...(vendorId ? { VendorId: vendorId } : {}),
          ...(errorType ? { ErrorType: errorType } : {}),
        };

        track('Vendor', 'Status', properties);
        status === 'success' && trackMarketing('bill-create_create-vendor', properties);
      };

      const handleCreateVendorSuccess = (vendor: Pick<Vendor, 'id' | 'name'>) => {
        trackCreateVendorStatus({
          status: 'success',
          vendorId: vendor.id,
          errorType: 'vendor-added',
        });

        onDone(vendor as Vendor);
      };

      const handleCreateVendorFail = (error: ModelError, createVendorParams: CreateVendorParams) => {
        const { inline, banner } = getErrorsByType(error, false);

        if (inline?.length || banner?.length) {
          trackCreateVendorStatus({
            status: 'failure',
            ...(error?.errorCode ? { errorType: error.errorCode } : {}),
            ...(inline?.length ? { inlineErrorsFields: getInlineApiErrorsFields(inline) } : {}),
            ...(banner?.length ? { bannerErrorsTypes: banner } : {}),
          });
          setApiErrorsCodes({ banner });
        } else {
          // TODO: add monitor for a case where getErrorsByType return unknow key.length https://meliorisk.atlassian.net/browse/ME-41490
          showMessage({
            type: 'error',
            title: formatMessage('addVendor.createVendorToast.error', {
              companyName: createVendorParams.name,
            }),
            action: {
              text: formatMessage('addVendor.createVendorToast.error.action'),
              onAction: (close) => {
                close();
                handleSubmitClick();
              },
              type: 'button',
            },
          });
        }
      };

      return (
        <Drawer
          data-testid="add-msn-vendor-drawer"
          isOpen={isOpen}
          onClose={handleCloseClick}
          closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.add.msn.button.close.ariaLabel')}
          header={<AddMsnVendorDrawerHeader />}
          footer={
            <AddMsnVendorDrawerFooter
              isLoading={isCreatingVendor}
              onSubmit={handleSubmitClick}
              onCancel={handleCancelClick}
            />
          }
          body={
            <Group variant="vertical" spacing="m">
              <SystemMessageDisplay />
              {msnBusiness && (
                <>
                  {error && <SectionBanner variant="critical" description={error.description} />}
                  <SectionBanner
                    description={formatMessage('widgets.vendorDrawer.add.msn.sectionBanner', {
                      name: msnBusiness.name ?? '',
                    })}
                  />
                  <MsnBusinessPreviewCard msnBusiness={msnBusiness} />
                </>
              )}
            </Group>
          }
        />
      );
    }
  )
);
