/* eslint-disable max-lines */
import { useDisclosure } from '@chakra-ui/react';
import {
  AddVendorFormFields,
  AddVendorFormWidget,
  AddVendorFormWidgetProps,
  getVendorNameForNotificationMessage,
  VendorFormBannerApiErrorCode,
  VendorFormInlineApiErrorCode,
  W9SubmissionMethod,
} from '@melio/ap-widgets';
import { Group, Text, useBreakpointValue, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CreateVendorParams, useVendors } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { SystemMessageDisplay, useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useEffect, useMemo } from 'react';

import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import {
  PromoteAccountingSoftwareSyncBanner,
  useShowPromoteAccountingSoftwareSyncBanner,
} from '../../../promote-accounting-software-sync';
import {
  PushFxForInternationalOrgsBanner,
  usePushFxForInternationalOrgsBanner,
} from '../../../PushFxForInternationalOrgsBanner';
import { FooterContent } from '../../components/FooterContent';
import { HeaderContent } from '../../components/HeaderContent';
import { SubmitTarget } from '../../types';
import { TaxPayerInfo, useTaxPayerInfo } from './components/TaxPayerInfo';
import { VendorGroupCreateNewGroupModal } from './VendorGroupCreateNewGroupModal';

export { SubmitTarget } from '../../types';

type CreateVendorReturnType = ReturnType<ReturnType<typeof useVendors>['create']>;

export type AddVendorScreenProps = {
  inlineApiErrorCodes?: VendorFormInlineApiErrorCode[];
  bannerApiErrorsCodes?: VendorFormBannerApiErrorCode[];
  defaultFormValues?: Partial<AddVendorFormFields>;
  managed?: AddVendorFormWidgetProps['managed'];
  onSelectCompany: (data: { companyName?: string; managed?: AddVendorFormWidgetProps['managed'] }) => void;
  isSaving?: boolean;
  isLoading?: boolean;
  onClose: VoidFunction;
  onDone: (
    data: CreateVendorParams,
    target?: `${SubmitTarget}`,
    shouldSkipToast?: boolean,
    intent?: string
  ) => Promise<Awaited<CreateVendorReturnType> | void>;
  onClearCompanyNameSearchField?: AddVendorFormWidgetProps['onClearCompanyNameSearchField'];
  isVendorSelected?: boolean;
};

const EMPTY_DEFAULT_VALUES = {};

export const AddVendorDetailsScreen = withAnalyticsContext<AddVendorScreenProps>(
  ({
    onClearCompanyNameSearchField,
    setAnalyticsProperties,
    bannerApiErrorsCodes,
    inlineApiErrorCodes,
    defaultFormValues = EMPTY_DEFAULT_VALUES,
    managed: initialManaged,
    isSaving,
    isLoading,
    isVendorSelected,
    onClose,
    onDone,
    onSelectCompany,
    ...props
  }) => {
    const { track } = useAnalytics();
    const { showMessage } = useSystemMessage();
    const [isVendorGroupsEnabled] = usePartnerFeature('VendorGroups', false);
    const isMobile = useBreakpointValue({ xs: true, s: false });
    const {
      onClose: onVendorGroupModalClose,
      isOpen: isVendorGroupModalOpen,
      //  onOpen: onVendorGroupModalOpen,  TODO: uncomment this line when adding the VendorGroupSelectGroup component and send this as a prop
    } = useDisclosure();
    const {
      isContractor,
      setIsContractor,
      shouldSendRequestToContractor,
      taxPayerInfoFormProps,
      submitTaxPayerInfoForm,
      handleFileUpload,
      handleDeleteInvalidFile,
      isMutating: isTaxPayerInfoMutating,
      selectedW9SubmissionMethod,
      w9File,
    } = useTaxPayerInfo();
    const [isW9TaxPayerInfoEnabled] = useDevFeature(FeatureFlags.W9TaxPayerInfo, false);
    const isEligibleForW9 = isW9TaxPayerInfoEnabled && !initialManaged;
    const { viewAnalyticsProps } = useShowPromoteAccountingSoftwareSyncBanner({ variant: 'vendor' });

    useEffect(() => {
      if (isEligibleForW9) {
        track('Vendor', 'View', {
          PageName: 'add-a-vendor',
          VendorType: initialManaged ? 'directory' : 'local',
          Flow: 'vendor-1099',
          Intent: 'add-a-vendor',
          IsContractor: isContractor,
          ...viewAnalyticsProps,
        });
      } else {
        track('Vendor', 'View', {
          VendorType: initialManaged ? 'directory' : 'local',
          ...viewAnalyticsProps,
        });
      }
    }, [initialManaged, track, isEligibleForW9, isContractor, viewAnalyticsProps]);

    const handleSelectCompanyName: AddVendorFormWidgetProps['onSelectCompanyName'] = ({ companyName, business }) => {
      onSelectCompany({
        companyName,
        managed: business
          ? {
              managedBy: business.directoryName,
              self: business.business.self,
              isZipCodeNeeded: business.business.merchantZipRequired,
            }
          : undefined,
      });
    };

    const { formatMessage } = useMelioIntl();

    const [isSearchBusinessesInDirectoriesSupported] = useDevFeature<boolean>(
      FeatureFlags.IsSearchBusinessesInDirectoriesSupported,
      false
    );

    const { onSubmissionStateChange, submitButtonProps, handleSubmit } =
      useFormSubmissionController<AddVendorFormFields>();

    setAnalyticsProperties({
      PageName: 'add-a-vendor',
      Intent: 'add-vendor-details',
    });

    const taxIdType = useMemo(() => {
      if (selectedW9SubmissionMethod === W9SubmissionMethod.Email) {
        return 'email';
      }

      if (selectedW9SubmissionMethod === W9SubmissionMethod.Pdf) {
        return w9File ? 'pdf' : 'no-file';
      }

      return null;
    }, [selectedW9SubmissionMethod, w9File]);

    const onSubmit = (submitTarget: SubmitTarget.CONTINUE | SubmitTarget.CLOSE) => {
      if (isEligibleForW9) {
        track('Vendor', 'Click', {
          Cta: submitTarget,
          VendorType: initialManaged ? 'directory' : 'local',
          PageName: 'add-a-vendor',
          Flow: 'vendor-1099',
          Intent: 'add-a-vendor',
          IsContractor: isContractor,
          TaxIdType: taxIdType,
        });
      } else {
        track('Vendor', 'Click', {
          Cta: submitTarget,
          VendorType: initialManaged ? 'directory' : 'local',
        });
      }

      handleSubmit?.(submitTarget)();
    };

    const { tryUseFeature: tryUseW9Feature } = useSubscriptionFeature({
      featureName: 'w9',
    });

    const handleFormSubmitWithPaywall: AddVendorFormWidgetProps['onSubmit'] = (data, target) => {
      if (!shouldSendRequestToContractor) {
        return handleFormSubmit(data, target);
      }
      return tryUseW9Feature({
        onFeatureIsBlocked: () => taxPayerInfoFormProps.setValue('W9SubmissionMethod', W9SubmissionMethod.Pdf),
        onFeatureIsEligible: () => handleFormSubmit(data, target),
      });
    };

    const handleFormSubmit: AddVendorFormWidgetProps['onSubmit'] = async (data, target) => {
      const newData = isEligibleForW9
        ? {
            ...data,
            contractor: isContractor,
          }
        : data;

      const intent = taxIdType
        ? {
            email: 'add-a-vendor-and-w9-request-sent',
            'no-file': 'add-a-vendor-no-file',
            pdf: 'add-a-vendor-pdf',
          }[taxIdType]
        : undefined;

      const vendor = await onDone(
        newData,
        target as `${SubmitTarget}`,
        isEligibleForW9 && shouldSendRequestToContractor,
        intent
      );

      if (isEligibleForW9 && vendor) {
        await submitTaxPayerInfoForm(vendor);

        if (shouldSendRequestToContractor) {
          const vendorName = getVendorNameForNotificationMessage(vendor);

          showMessage({
            type: 'success',
            title: formatMessage('activities.addVendor.screens.addVendorDetails.taxPayerInfo.successToast', {
              vendorName,
            }),
          });
        }
      }
    };

    const {
      isEnabled: shouldShowPushFxForInternationalOrgsBanner,
      handleCloseBanner: handleClosePushFxForInternationalOrgsBanner,
    } = usePushFxForInternationalOrgsBanner({
      analyticsProps: {
        viewContext: 'Vendor',
        PageName: 'add-a-vendor',
        Intent: 'add-a-vendor',
      },
    });

    return (
      <NewSinglePaymentStepLayout
        data-component="AddVendorActivity.AddVendorDetailsScreen"
        data-testid="add-vendor-activity-add-vendor-details-screen"
        {...props}
        isLoading={isLoading}
        headerContent={<HeaderContent onClose={onClose} />}
        isStickyFooter
        footerContent={
          <FooterContent
            onClose={onClose}
            onSubmit={onSubmit}
            submitButtonProps={submitButtonProps}
            managed={initialManaged}
            shouldSendRequestToContractor={shouldSendRequestToContractor}
          />
        }
      >
        <SystemMessageDisplay />
        {isMobile ? null : (
          <NewSinglePaymentStepLayout.Title textAlign="start" autoFocus={false} onClose={onClose}>
            {formatMessage('activities.addVendor.screens.addVendorDetails.title')}
          </NewSinglePaymentStepLayout.Title>
        )}
        {isSearchBusinessesInDirectoriesSupported && (
          <NewSinglePaymentStepLayout.Description
            as="p"
            paddingTop={isMobile ? 'xs' : undefined}
            textStyle={isMobile ? 'body3' : undefined}
            textAlign="start"
          >
            {formatMessage('activities.addVendor.searchAndAddVendorDetails.description')}
          </NewSinglePaymentStepLayout.Description>
        )}
        <PromoteAccountingSoftwareSyncBanner
          variant="vendor"
          analyticsProps={{
            EntryPoint: 'dashboard-vendors',
            Flow: 'dashboard-user-message',
            PageName: 'add-vendor',
          }}
        />
        {shouldShowPushFxForInternationalOrgsBanner ? (
          <PushFxForInternationalOrgsBanner onClose={handleClosePushFxForInternationalOrgsBanner} />
        ) : null}
        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="xs" width="full">
            <Text color="global.neutral.900" textStyle={isMobile ? 'caption1' : 'body4'}>
              {formatMessage('activities.addVendor.searchAndAddVendorDetails.requiredFields')}
            </Text>
            <AddVendorFormWidget
              defaultValues={defaultFormValues}
              managed={initialManaged}
              isVendorSelected={isVendorSelected}
              onSelectCompanyName={handleSelectCompanyName}
              onClearCompanyNameSearchField={onClearCompanyNameSearchField}
              inlineApiErrorCodes={inlineApiErrorCodes}
              bannerApiErrorCodes={bannerApiErrorsCodes}
              onSubmit={handleFormSubmitWithPaywall}
              isSaving={isSaving || isTaxPayerInfoMutating}
              onSubmissionStateChange={onSubmissionStateChange}
              isEmailRequired={shouldSendRequestToContractor}
              emailRequiredMessage={
                shouldSendRequestToContractor
                  ? formatMessage('activities.addVendor.screens.addVendorDetails.taxPayerInfo.emailRequiredMessage')
                  : undefined
              }
            />
            {isEligibleForW9 && (
              <TaxPayerInfo
                taxPayerInfoFormProps={taxPayerInfoFormProps}
                isContractor={isContractor}
                onChangeIsContractor={setIsContractor}
                onFileUpload={handleFileUpload}
                onDeleteInvalidFile={handleDeleteInvalidFile}
                isLoading={isSaving || isTaxPayerInfoMutating}
              />
            )}
          </Group>
        </NewSinglePaymentStepLayout.Content>
        {isVendorGroupsEnabled && (
          <VendorGroupCreateNewGroupModal isOpen={isVendorGroupModalOpen} onClose={onVendorGroupModalClose} />
        )}
      </NewSinglePaymentStepLayout>
    );
  }
);

AddVendorDetailsScreen.displayName = 'AddVendorActivity.AddVendorDetailsScreen';
