import { Box } from '@chakra-ui/react';
import { AvatarGroup, Group, Icon, Pill, Text, Tooltip } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { FirmClientExpanded } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import { isAccountantFirmClientPlanTier, Tier, useIsSubscriptionsEnabled } from '@melio/subscriptions';

import { useFirmClientSubscriptionActionsProps } from '../../../../hooks/useFirmClientSubscriptionActionsProps';
import { CompanyAvatar } from '../../CompanyAvatar';
import { PaymentsOverview } from '../../PaymentsOverview';
import { CardLayout } from '../common/Card.layout';

type Props = {
  client: FirmClientExpanded;
  firmOrganizationId: string;
  isPaymentsOverviewLoading: boolean;
  isLoading: boolean;
  isExpandedClientsFetchedSuccessfully?: boolean;
  onManageClientPlan: VoidFunction;
  onToggleHiddenState: VoidFunction;
  onProceedToCheckout: VoidFunction;
  onGoToAssignPlan: VoidFunction;
  onManageClientBillingOption: VoidFunction;
  onClick?: VoidFunction;
};

export const OrganizationCard = ({
  client,
  isPaymentsOverviewLoading,
  isLoading,
  isExpandedClientsFetchedSuccessfully,
  onManageClientPlan,
  onToggleHiddenState,
  onProceedToCheckout,
  onGoToAssignPlan,
  onManageClientBillingOption,
  onClick,
  firmOrganizationId,
}: Props) => {
  const { formatMessage } = useMelioIntl();
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { track } = useAnalytics();

  const { id: accountingClientId, name, logoUrl, billsAndPaymentsOverview } = client.organization;
  const companyName = name || '';

  const subscriptionInfo = isSubscriptionsEnabled ? client.subscriptionInfo : undefined;
  const { planMetadata } = subscriptionInfo || {};
  const isVipPlan = planMetadata?.planTier === Tier.MelioVip;

  const isAccountingFirmClientPlan = planMetadata?.planTier
    ? isAccountantFirmClientPlanTier(planMetadata.planTier)
    : false;

  const onToggleDropdown = (value: boolean) => {
    if (value) {
      track('User', 'Click', { Cta: 'client-options' });
    }
  };

  const {
    subscriptionPlanDropdownActionsProps,
    limitedBillingOptionsType,
    subscriptionPlanState,
    subscriptionPlanPillProps,
  } = useFirmClientSubscriptionActionsProps({
    clientOrgId: accountingClientId,
    subscriptionInfo: client?.subscriptionInfo,
    onManageClientBillingOption,
    firmOrganizationId,
    onManageClientPlan,
    onGoToAssignPlan,
    isDataFetchedSuccessfully: !!isExpandedClientsFetchedSuccessfully && !isPaymentsOverviewLoading,
    onProceedToCheckout,
  });

  const isPaidTier = !!planMetadata && (!planMetadata.isFreePlan || isVipPlan) && !!planMetadata.planTier;
  const activatedPlanTier = planMetadata?.planTier || '';

  return (
    <CardLayout
      data-testid={`organization-card-${accountingClientId}`}
      isMenuDisabled={isLoading}
      actions={[
        ...subscriptionPlanDropdownActionsProps,
        {
          label: client.organization.isHidden
            ? formatMessage('activities.accountants.OrganizationCard.menu.unhideClient')
            : formatMessage('activities.accountants.OrganizationCard.menu.hideClient'),
          onClick: onToggleHiddenState,
          dataTestId: 'menu-item-toggle-hidden-state',
        },
      ]}
      onClick={onClick}
      onToggle={onToggleDropdown}
      top={
        <>
          <CompanyAvatar size="medium" src={logoUrl} name={companyName} />
          <Group variant="vertical" spacing="xxs" alignItems="flex-start">
            <Group variant="horizontal" alignItems="center" spacing="xs">
              <Text textStyle="body2Semi" shouldSupportEllipsis>
                {companyName}
              </Text>
              {isPaidTier && (
                <Tooltip
                  isEnabled={isVipPlan || !isAccountingFirmClientPlan}
                  content={
                    isVipPlan
                      ? formatMessage('activities.accountants.OrganizationCard.planTier.vipTooltip.label')
                      : formatMessage('activities.accountants.OrganizationCard.planTier.smbTooltip.label')
                  }
                  data-testid={`plan-tier-pill-tooltip-${accountingClientId}`}
                >
                  <Pill
                    label={
                      isVipPlan
                        ? formatMessage(`activities.accountants.OrganizationCard.planTier.${Tier.MelioVip}.chip.label`)
                        : activatedPlanTier
                        ? formatMessage(`activities.subscription.plans.${activatedPlanTier}.title`)
                        : ''
                    }
                    status={isAccountingFirmClientPlan ? 'neutral' : 'brand'}
                    type={isAccountingFirmClientPlan ? 'primary' : 'secondary'}
                    data-testid={`plan-tier-pill-${accountingClientId}`}
                  />
                </Tooltip>
              )}
              {limitedBillingOptionsType && (
                <Box
                  data-testid={`organization-manage-client-limitations-indication-${limitedBillingOptionsType}-${accountingClientId}`}
                  cursor="default"
                  display="flex"
                  flexDirection="column"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Tooltip
                    content={formatMessage(
                      `activities.accountants.OrganizationCard.pill.tooltip.manageClientLimitations.content.${limitedBillingOptionsType}`
                    )}
                    data-testid={`organization-manage-client-limitations-pill-tooltip-indication-${accountingClientId}`}
                  >
                    <Icon
                      size="small"
                      type="info-fill"
                      color="informative"
                      data-testid={`organization-manage-client-limitations-pill-icon-indication-${accountingClientId}`}
                      aria-label={formatMessage(
                        `activities.accountants.OrganizationCard.pill.tooltip.manageClientLimitations.content.${limitedBillingOptionsType}`
                      )}
                    />
                  </Tooltip>
                </Box>
              )}
              {subscriptionPlanPillProps && subscriptionPlanState && (
                <Box onClick={(e) => e.stopPropagation()}>
                  <Tooltip
                    isEnabled={!!subscriptionPlanPillProps.tooltip}
                    content={subscriptionPlanPillProps.tooltip}
                    data-testid={`pending-plan-pill-tooltip-${subscriptionPlanState}-${accountingClientId}`}
                  >
                    <Box onClick={subscriptionPlanPillProps.onClick}>
                      <Pill
                        data-testid={`pending-plan-pill-${subscriptionPlanState}-${accountingClientId}`}
                        {...subscriptionPlanPillProps}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Group>
          </Group>
        </>
      }
      bottom={
        <>
          <PaymentsOverview
            isPaymentsOverviewLoading={isPaymentsOverviewLoading}
            paymentsOverview={billsAndPaymentsOverview}
          />
          {!!client.firmMembers?.length && (
            <AvatarGroup
              data-testid="firm-members"
              items={client.firmMembers.map((member) => ({
                name: [member.firstName, member.lastName].join(' ').trim(),
              }))}
            />
          )}
        </>
      }
    />
  );
};
