import { Group } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { CustomPaymentInstructions, FundingSourceSelection } from '../../../components';
import { BaseLayout } from '../../../layout';
import { GuestPaymentIntentData, GuestPayorFundingSourceTypes, GuestPayorPaymentOptions } from '../../../types';
import { FiservPaymentDetailsHeader, FiservPaymentWidget } from '../components';

export type FiservPaymentScreenProps = {
  isLoading: boolean;
  guestPaymentIntentData?: GuestPaymentIntentData;
  paymentWidgetUrl?: string;
  onSelectFundingSourceType: (type: GuestPayorFundingSourceTypes) => void;
  onViewInvoice: VoidFunction;
  selectedFundingSource?: GuestPayorPaymentOptions;
  shouldAllowFundingSourceSelection: boolean;
};

export const FiservPaymentScreen = forwardRef<FiservPaymentScreenProps>(
  (
    {
      isLoading,
      guestPaymentIntentData,
      onViewInvoice,
      paymentWidgetUrl,
      onSelectFundingSourceType,
      selectedFundingSource,
      shouldAllowFundingSourceSelection,
    },
    ref
  ) => {
    useAnalyticsView('PaymentRequest', true, true, {
      PaymentMethodType: selectedFundingSource,
    });
    const { track } = useAnalytics();

    const handleOnViewInvoice = () => {
      track('PaymentRequest', 'Click', {
        PageName: 'payment-request',
        Intent: 'view-invoice-file',
        Cta: 'view',
      });
      onViewInvoice();
    };

    return (
      <BaseLayout
        isLoading={isLoading}
        isPayable={guestPaymentIntentData?.isPayable}
        ref={ref}
        data-testid="fiserv-payment-screen"
        backgroundColor={guestPaymentIntentData?.vendorPaymentDetails?.payeeDetails.brandColor ?? undefined}
      >
        {guestPaymentIntentData?.invoice.dueDate && guestPaymentIntentData.vendorPaymentDetails && (
          <Group variant="vertical">
            <Group variant="vertical" spacing="l" hasDivider>
              <FiservPaymentDetailsHeader
                payeeDetails={guestPaymentIntentData.vendorPaymentDetails.payeeDetails}
                invoiceFileUrl={guestPaymentIntentData.invoice.fileInfo?.previewUrls[0]}
                totalAmount={guestPaymentIntentData.invoice.totalAmount}
                dueDate={guestPaymentIntentData.invoice.dueDate}
                onViewInvoice={onViewInvoice}
                isViewInvoiceEnabled={isLoading}
              />
              {selectedFundingSource !== 'custom' ? (
                <Group variant="vertical" spacing="l">
                  {shouldAllowFundingSourceSelection && (
                    <FundingSourceSelection
                      fees={guestPaymentIntentData.vendorPaymentDetails.fees}
                      selectedFundingSource={selectedFundingSource}
                      onCardSelected={() => onSelectFundingSourceType('card')}
                      onBankSelected={() => onSelectFundingSourceType('bank')}
                    />
                  )}
                  <FiservPaymentWidget paymentWidgetUrl={paymentWidgetUrl} data-testid="fiserv-iframe-container" />
                </Group>
              ) : (
                <CustomPaymentInstructions
                  onViewInvoice={handleOnViewInvoice}
                  customPayInstructions={
                    guestPaymentIntentData.vendorPaymentDetails.paymentOptions.customPayInstructions || undefined
                  }
                  ref={ref}
                />
              )}
            </Group>
          </Group>
        )}
      </BaseLayout>
    );
  }
);

FiservPaymentScreen.displayName = 'FiservPaymentScreen';
