import { ARInvoice, FormattedCurrency, FormattedMessage, useInvoiceStatusLabel, useMelioIntl } from '@melio/ar-domain';
import { Group, Icon, Pill, Text, Typography } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MobileContainer } from '../../../components';
import { InvoiceActionsHandlers } from '../../../utils';

export type InvoiceTableRowsMobileProps = { invoice: ARInvoice; invoiceActions?: InvoiceActionsHandlers };

export const InvoiceTableRowsMobile = forwardRef<InvoiceTableRowsMobileProps>(
  ({ invoice: { customer, ...invoice }, invoiceActions, ...props }, ref) => {
    const { formatDate } = useMelioIntl();
    const { getInvoiceStatusLabel, getInvoiceStatusVariant } = useInvoiceStatusLabel();
    return (
      <MobileContainer>
        <Group
          data-testid={`table-mobile-row-${invoice.id}`}
          variant="horizontal"
          justifyContent="space-between"
          {...props}
          ref={ref}
        >
          <Group variant="vertical" spacing="xxs">
            <Typography.MainLabel
              label={customer?.companyName || ''}
              variant="bold"
              data-testid="invoice-customer-name"
            />
            <Group variant="vertical" spacing="xxxs">
              <Text textStyle="body3" color="global.neutral.800" data-testid="customer-email">
                {customer?.email}
              </Text>
              <Text textStyle="body3" color="global.neutral.800" data-testid="invoice-due-date">
                <FormattedMessage
                  id="ar.invoiceLifecycle.activities.invoiceTable.mobile.card.dueDate"
                  values={{ dueDate: formatDate(invoice.dueDate) }}
                />
              </Text>
              <Text textStyle="body3" color="global.neutral.800" data-testid="invoice-number">
                {invoice.invoiceNumber}
              </Text>
            </Group>
            <Pill
              type="secondary"
              status={getInvoiceStatusVariant(invoice.displayStatus)}
              label={getInvoiceStatusLabel(invoice.displayStatus)}
              data-testid="invoice-status-label"
              data-cy-status={invoice.displayStatus}
            />
          </Group>
          <Group variant="horizontal" alignItems="center" height="fit-content" spacing="xxxs">
            <Text textStyle="body2Semi" color="global.neutral.900" data-testid="invoice-amount">
              <FormattedCurrency amount={invoice.totalAmount} />
            </Text>
            <Icon type="chevron-right" size="small" />
          </Group>
        </Group>
      </MobileContainer>
    );
  }
);
InvoiceTableRowsMobile.displayName = 'InvoiceTableListItem';
