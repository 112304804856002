import { Box } from '@chakra-ui/react';
import { Button, Container, Group, Illustration, Text, useBreakpoint } from '@melio/penny';
import { Layout } from '@melio/platform-ds';

import { ButtonPropsWithTestId } from './PayDashboardEmptyState';

export const PayDashboardClearedState = ({
  title,
  description,
  descriptionCTA,
  buttonProps,
  illustrationSrc,
  hidden,
}: {
  title?: string;
  description: string;
  descriptionCTA?: React.ReactNode;
  buttonProps?: ButtonPropsWithTestId[];
  illustrationSrc: string;
  hidden?: boolean;
}) => {
  const { isExtraSmallScreen } = useBreakpoint();

  return (
    <Layout maxWidth="600px" paddingContent={isExtraSmallScreen ? 'm' : 'none'} data-testid="pay-dashboard-clear-state">
      <Container paddingTop="xxl" hidden={hidden}>
        <Group alignItems="center" variant="vertical">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Illustration type={illustrationSrc} size="medium" />
          <Group spacing="m" variant="vertical" textAlign="center">
            {title && <Text textStyle="heading3Semi">{title}</Text>}
            <Box>
              <Text color="global.neutral.800">{description}</Text>
              {descriptionCTA}
            </Box>
            {buttonProps && buttonProps.length > 0 && (
              <Group>
                {buttonProps.map((buttonProp, index) => (
                  <Button key={index} variant="primary" isFullWidth={isExtraSmallScreen} {...buttonProp} />
                ))}
              </Group>
            )}
          </Group>
        </Group>
      </Container>
    </Layout>
  );
};
