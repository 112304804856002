import { useMelioIntl } from '@melio/ar-domain';
import { IconButton, SortDropdownMenu } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';

import { OnSort, Order, SortField } from '../types';

type SortMenuMobileProps = {
  onSort?: OnSort;
  sortParams?: { sortBy?: SortField; sortOrder?: Order };
};

const invertSortOrder = (order?: Order): Order => (order === 'Asc' ? 'Desc' : 'Asc');
const convertSortOrderToSortDirection = (order?: Order) => (order === 'Asc' ? 'asc' : 'desc');

export const SortMenuMobile = forwardRef<SortMenuMobileProps>(({ onSort, sortParams }, ref) => {
  const [isOpen, toggle] = useBoolean(false);
  const { formatMessage } = useMelioIntl();

  const sortFields: Record<SortField, string> = {
    creationDate: formatMessage('ar.dashboard.activities.paymentsTable.mobile.sort.fields.creationDate'),
    amount: formatMessage('ar.dashboard.activities.paymentsTable.mobile.sort.fields.amount'),
  };

  return (
    <SortDropdownMenu
      ref={ref}
      isOpen={isOpen}
      trigger={
        <IconButton
          size="large"
          icon="sort"
          data-testid="mobile-sort-button"
          onClick={toggle.toggle}
          aria-label={formatMessage('ar.dashboard.activities.paymentsTable.mobile.sort.aria-label')}
        />
      }
      items={Object.entries(sortFields).map(([key, label]) => ({
        key,
        label,
        onClick: () => {
          onSort?.(key as SortField, sortParams?.sortBy === key ? invertSortOrder(sortParams.sortOrder) : 'Asc');
          setTimeout(() => {
            toggle.off();
          }, 1000);
        },
        dataTestId: `sortable-header-cell-${key}`,
      }))}
      selectedItemIndex={Object.keys(sortFields).indexOf(sortParams?.sortBy || 'creationDate')}
      onOpenChange={toggle.toggle}
      sortDirection={convertSortOrderToSortDirection(sortParams?.sortOrder)}
      title={formatMessage('ar.dashboard.activities.paymentsTable.mobile.sort.title')}
    />
  );
});

SortMenuMobile.displayName = 'SortMenuMobile';
