/* eslint-disable max-lines */
import { Box, useDisclosure } from '@chakra-ui/react';
import { useNavigationWithQueryParams } from '@melio/ap-domain';
import { InboxEmailAddressCopyLinkWidget } from '@melio/ap-widgets';
import { Button, Container, Group, Icon, useBreakpoint, useTable } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import {
  Bill,
  InboxItemBillTypeEnum,
  PaymentRequest,
  useAccountingPlatforms,
  usePaymentRequests,
  useUserHasPayments,
} from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useLocation, useSystemMessage } from '@melio/platform-utils';
import { useSubscriptionFeature } from '@melio/subscriptions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Route, Routes, useResolvedPath, useSearchParams } from 'react-router-dom';

import { DeleteBillItemsModalActivity } from '../../../delete-bill-modal';
import { EbillsImportModal, useShouldShowEbillsSyncOption } from '../../../ebills-import';
import { useDynamicEbillsImportCopy } from '../../../ebills-import/EbillsImport.activity';
import { MarkAsPaidModal } from '../../../mark-as-paid-modal/MarkAsPaidModal';
import { PaymentRequestRejectModal } from '../../../PaymentRequest';
import { useEnrichedTodos, useTodosEnabled } from '../../../todos-drawer';
import { ROW_HEIGHT } from '../../consts';
import { FilterChips } from '../../FilterChips';
import { emitFocusEvent, FocusEvents } from '../../FocusSkipToComponent';
import { useLoadingState } from '../../hooks/useLoadingState';
import { useSearchTerm } from '../../hooks/useSearchTerm';
import { useShouldShowConnectAccountingSoftwareOption } from '../../hooks/useShouldShowConnectAccountingSoftwareOption';
import { NestedFilters } from '../../NestedFilters';
import { EmptyFilterResult } from '../../NestedFilters/EmptyFilterResult';
import { PayDashboardFilterProvider, usePayDashboardFilters } from '../../PayDashboardFilterProvider';
import { PayDashboardSortingProvider, usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardTabs } from '../../types';
import { APTable } from '../APTable';
import { BillsTabDrawers } from '../Drawer';
import { MobileSortMenu } from '../MobileSortMenu/MobileSortMenu';
import { PayDashboardPagination, PayDashboardPaginationProvider, usePayDashboardPagination } from '../Pagination';
import { PayDashboardZeroState } from '../PayDashboardEmptyState/PayDashboardZeroState';
import { EmptySearchResult, SearchBar } from '../Search';
import { AddBillDropdownMenu } from './components/AddBillDropdownMenu';
import { BillItemsSelectionFooter } from './components/BillItemsSelectionFooter';
import { getAmountToDisplay } from './components/BillsTabAmountCell';
import { BillsTabEmptyState } from './components/BillsTabEmptyState';
import { BillsTabListItem } from './components/BillsTabListItem';
import { useBillsTabStatusCell } from './components/BillsTabStatusCell';
import { BillsTabItem, CELLS_IDS, SelectedInboxItemType } from './types';
import { useBillsTabAnalytics } from './useBillsTabAnalytics';
import { supportedSearchParamKeys, useBillsTabFilters } from './useBillsTabFilters';
import { useBillsTabFiltersQueryParams } from './useBillsTabFiltersQueryParams';
import { useBillsTabItems } from './useBillsTabItems';
import { useBillsTableColumns } from './useBillsTableColumns';
import { Selection, useBillsTabSelection } from './useBillsTabSelection';
import { useBillsTabSortableColumns } from './useBillsTabSortableColumns';

export type BillsTabProps = {
  onEditBill: ({ id }: { id: string; returnUrl?: string }) => void;
  onPayPaymentRequest: (id: string) => void;
  onReviewScannedInvoice: ({ id, returnUrl }: { id: string; returnUrl: string; originFlow?: OriginFlow }) => void;
  onAccountingPlatformConnect: () => void;
  onAddNewBillManual: ({ returnUrl, originFlow }: { returnUrl: string; originFlow?: OriginFlow }) => void;
  onAddNewBillUpload: ({ returnUrl, files }: { returnUrl: string; files: File[]; originFlow?: OriginFlow }) => void;
};

const BillsTabComponent = ({
  onPayPaymentRequest,
  onReviewScannedInvoice,
  onEditBill,
  onAccountingPlatformConnect,
  onAddNewBillManual,
  onAddNewBillUpload,
}: BillsTabProps) => {
  const { track } = useAnalytics();
  const { showMessage } = useSystemMessage();
  const { formatMessage, formatCurrency, formatDate } = useMelioIntl();
  const { isExtraSmallScreen, isSmallScreen } = useBreakpoint();
  const isUnderSmallScreen = isExtraSmallScreen || isSmallScreen;
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchTerm } = useSearchTerm();
  const resolvedPathUrl = useResolvedPath('');
  const { handleNavigationWithQueryParams } = useNavigationWithQueryParams();
  const { resetToFirstPage } = usePayDashboardPagination();
  const { data: accountingPlatforms, isLoading: isAccountingPlatformsLoading } = useAccountingPlatforms();
  const {
    settings: {
      newBillExperience: { shouldShowIconInBillsTabCta },
      payDashboardSearchLabelDisabled,
      isBillsInboxEnabled,
      isUploadBillsEnabled,
    },
  } = useConfig();
  const [isMobileSortEnabled] = useDevFeature<boolean>(FeatureFlags.NpeSortMobile, false);
  const [isBillsTabFiltersInfraEnabled] = usePartnerFeature('BillsTabFiltersInfra', false);
  const [isBillsTabFiltersMenuEnabled] = usePartnerFeature('BillsTabFiltersMenu', false);

  const sorting = usePayDashboardSorting();
  const { sortableColumns: billsTabSortableItems } = useBillsTabSortableColumns();
  const { isEnabled: isTodosEnabled } = useTodosEnabled();
  const { refetch: refetchTodos } = useEnrichedTodos({ enabled: false });
  const filterContext = usePayDashboardFilters();

  const { filtersQueryParams, queryParamsOptions } = useBillsTabFiltersQueryParams();

  const paginationResponse = useBillsTabItems({
    searchTerm,
    sort: sorting?.sortingState || null,
    search: Object.keys(filtersQueryParams).length ? filtersQueryParams : undefined,
    params: Object.keys(queryParamsOptions).length ? queryParamsOptions : undefined,
    enabled: !!sorting?.isLoaded,
  });
  const {
    data: billTabItemsResult,
    isLoading: isLoadingItems,
    refetch: refetchInboxItems,
    isPreviousData,
    isFetching: isFetchingItems,
  } = paginationResponse;
  const billItems = billTabItemsResult?.data ?? [];

  const billsTabFilters = useBillsTabFilters({
    filterContext,
  });

  const [billItemsToDelete, setBillItemsToDelete] = useState<SelectedInboxItemType[]>([]);
  const [billToMarkAsPaidId, setBillToMarkAsPaidId] = useState('');
  const [isEbillsImportModalOpen, toggleEbillsImportModal] = useState(false);

  const {
    approve: { mutateAsync: approvePaymentRequests },
  } = usePaymentRequests({ enabled: false });
  const { tryUseFeature: tryUseBatchPayments } = useSubscriptionFeature({ featureName: 'batchPayments' });

  const onSelectionChange = useCallback((rowSelections: SelectedInboxItemType[], lastSelection: Selection) => {
    const selectedBills = rowSelections.filter((row) => row.type === 'bill');
    if (selectedBills.length <= 1) {
      return;
    }

    tryUseBatchPayments({ onFeatureIsBlocked: () => uncheckSelection(lastSelection) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    selectedRows,
    rowSelections,
    setRowSelections,
    setDisableAllRowsSelection,
    disableAllRowsSelection,
    onRowSelectionChange,
    onAllRowsSelectionChange,
    disableRowSelection,
    rowSelectionTooltips,
    uncheckSelection,
    areAllSelected,
  } = useBillsTabSelection({ billsData: billItems, onChange: onSelectionChange });

  const { routeReady } = useMonitoring();

  useBillsTabAnalytics({ billItems, rowSelections, areAllSelected });
  const {
    isEmptyState,
    isEmptySearchResult,
    isInitialLoading,
    isTableLoading,
    shouldShowTabHeader: isNotEmptyStateWithoutFilter,
    shouldShowPaginationControls,
    isEmptyStateFilter,
  } = useLoadingState({
    isLoading: isLoadingItems || !sorting?.isLoaded,
    searchTerm,
    items: billItems,
    isFiltered: filterContext?.hasActiveFilters,
    paginationConfig: {
      isFetching: isFetchingItems,
      isPreviousData,
      totalCount: billTabItemsResult?.pagination?.totalCount,
    },
  });

  const { userHasPayments, isLoading: isUserHasPaymentsLoading } = useUserHasPayments();
  const shouldShowZeroState = isEmptyState && userHasPayments;
  const shouldShowTabHeader = isNotEmptyStateWithoutFilter || shouldShowZeroState;
  const mobileSortMenuOptions = Object.values(billsTabSortableItems);

  useEffect(() => {
    if (isLoadingItems || isUserHasPaymentsLoading) {
      return;
    }
    track('Dashboard', 'View', {
      PageName: 'dashboard-bills',
      TotalFilters: filterContext?.filters ? Object.keys(filterContext.filters).length : 0,
      FilterValue: filterContext?.filters,
      Intent: 'pay-bill',
      SortColumn: sorting?.sortingState?.id,
      SortDirection: sorting?.sortingState?.sortDirection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, isLoadingItems, isUserHasPaymentsLoading, filterContext]);

  const onCloseDrawer = () => handleNavigationWithQueryParams({ newPath: resolvedPathUrl.pathname });

  const onDeleteBillItemClick = (billItemToDelete: SelectedInboxItemType) => {
    setBillItemsToDelete([billItemToDelete]);
  };

  const onMarkAsPaid = ({ id }: { id: string }) => {
    setBillToMarkAsPaidId(id);
    accountingPlatforms?.length && isExtraSmallScreen && onCloseDrawer(); // This is a temporary fix for the reconciliation form is crashing on mobile https://meliorisk.atlassian.net/browse/ME-46585. the issue will be fixed in this PR: https://github.com/melio/platform-app/pull/6199
    onMarkAsPaidBillModalOpen();
  };

  const onViewItemClick = ({ id }: { id: string }) => {
    handleNavigationWithQueryParams({ newPath: id });
  };

  const onEditItemClick = ({ id }: { id: string }) => {
    onEditBill({ id, returnUrl: pathname });
  };

  const onReviewScannedInvoiceClick = (id: string) => {
    onReviewScannedInvoice({ id, returnUrl: pathname, originFlow: OriginFlow.BillPage });
  };

  const onRejectClick = ({ id }: { id: string }) => {
    setSearchParams({ paymentRequestReject: id });
  };

  const onRowClick = useCallback(
    ({ rowData }: { rowData: BillsTabItem }) => {
      if (rowSelections.length) {
        return;
      }
      if (!disableAllRowsSelection) {
        track('Dashboard', 'Click', { Intent: 'view-bill', Cta: 'row-bill-details' });
        handleNavigationWithQueryParams({ newPath: rowData.payload.id });
      }
    },
    [handleNavigationWithQueryParams, rowSelections.length, track, disableAllRowsSelection]
  );

  const onEbillsSyncClick = (isEmptyState: boolean) => {
    toggleEbillsImportModal(true);
    track('Dashboard', 'Click', {
      PageName: 'dashboard-bills',
      Flow: 'dashboard',
      Intent: 'import-bill',
      Cta: 'connect-accounts',
      IsEmptyState: isEmptyState,
      EntryPoint: 'dashboard - bills',
    });
  };

  const handleAddNewBillManual = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'add-a-bill' });
    onAddNewBillManual({
      returnUrl: pathname,
      originFlow: OriginFlow.BillPage,
    });
  };

  const handleAddNewBillUpload = (files: File[]) => {
    onAddNewBillUpload({
      returnUrl: pathname,
      files,
      originFlow: OriginFlow.BillPage,
    });
  };

  const handleAccountingPlatformConnect = () => {
    track('Dashboard', 'Click', { Intent: 'add-bill', Cta: 'import-connect-accounting-software' });
    onAccountingPlatformConnect();
  };

  const handleOnCloseRejectModal = () => {
    setSearchParams((params) => {
      params.delete('paymentRequestReject');
      return params;
    });
  };

  const handleRejectSuccess = () => {
    refetchInboxItems();
  };

  const handleDeleteInboxItemSuccess = () => {
    onCloseDrawer();
    setRowSelections([]);
    refetchInboxItems();
    if (isTodosEnabled) {
      refetchTodos();
    }
  };

  const handleMarkAsPaidBillSuccess = () => {
    onCloseDrawer();
    refetchInboxItems();
  };

  const {
    isOpen: isMarkAsPaidBillDialogOpen,
    onOpen: onMarkAsPaidBillModalOpen,
    onClose: onMarkAsPaidBillModalClose,
  } = useDisclosure();

  const handleMarkAsPaidModalClose = () => {
    onMarkAsPaidBillModalClose();
    onCloseDrawer();
  };
  const handleDeleteActionModalClose = () => {
    setBillItemsToDelete([]);
  };

  const handleDeleteActionModalError = () => {
    handleDeleteActionModalClose();
    onCloseDrawer();
  };

  const handleInboxEmailCopy = () => {
    track('Dashboard', 'Click', {
      Intent: 'add-bill',
      Cta: 'copy-email',
    });
  };

  const getBillIdsToReviewAndPay = async ({
    paymentRequests,
    bills,
  }: {
    paymentRequests: PaymentRequest[];
    bills: Bill[];
  }) => {
    setDisableAllRowsSelection(true);

    try {
      const billIdsFromPaymentRequests: string[] = [];

      if (paymentRequests.length > 0) {
        const approvedPaymentRequests = (await approvePaymentRequests({
          paymentRequests: paymentRequests.map(({ id }) => ({ id })),
        })) as Array<PaymentRequest & { billId: string }>;

        billIdsFromPaymentRequests.push(...approvedPaymentRequests.map(({ billId }) => billId));
      }

      return [...bills.map(({ id }) => id), ...billIdsFromPaymentRequests];
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('activities.payDashboard.billsTab.selectionFooter.reviewAndPay.error'),
        dataTestId: 'review-and-pay-error',
      });
    } finally {
      setDisableAllRowsSelection(false);
    }

    return null;
  };

  const columns = useBillsTableColumns({
    onSortChange: resetToFirstPage,
  });

  const mobileRowRenderer = useCallback((row: BillsTabItem) => <BillsTabListItem billsTabItem={row} />, []);
  const getBillsTabStatus = useBillsTabStatusCell();

  const getItemAriaLabelContext = useCallback(
    (billItem: BillsTabItem) => {
      if (billItem.type === InboxItemBillTypeEnum.Bill) {
        const amount = getAmountToDisplay(billItem);
        const { label: statusLabel } = getBillsTabStatus({ billsTabItem: billItem });
        return formatMessage('activities.payDashboard.billsTab.table.aria.row', {
          vendorName: billItem.payload.vendor?.name,
          creationDate: formatDate(billItem.payload.history.createdAt ?? undefined, { dateStyle: 'medium' }),
          dueDate: formatDate(billItem.payload.dueDate ?? undefined, { dateStyle: 'medium' }),
          status: statusLabel,
          amount: amount ? formatCurrency(amount) : '',
        });
      }
      return '';
    },
    [formatCurrency, formatDate, formatMessage, getBillsTabStatus]
  );

  const tableProps = useTable({
    isLoading: isTableLoading,
    data: billItems,
    columns,
    getRowId: (row) => row.payload.id,
    rowSelectionTooltips,
    selectedRows,
    onRowSelectionChange,
    disableRowSelection,
    disableAllRowsSelection,
    onAllRowsSelectionChange,
    onRowClick,
    ...sorting,
    mobileRowRenderer,
    headerVariant: 'dark',
    allRowsSelectionAriaLabel: '',
    hideHeaderWhileLoading: isInitialLoading,
    getRowSelectionAriaLabel: getItemAriaLabelContext,
    captionId: formatMessage(`activities.payDashboard.tabs.bills.caption`),
    meta: {
      rowSelections: rowSelections.map(({ id }) => id),
      getItemAriaLabelContext,
      onPayPaymentRequest,
      onReviewScannedInvoiceClick,
      onDeleteBillItemClick,
      onViewItemClick,
      onEditItemClick,
      onRejectClick,
      onMarkAsPaid,
    },
  });

  const paymentRequestRejectQP = searchParams.get('paymentRequestReject');

  const shouldShowConnectAccountingSoftwareOption = useShouldShowConnectAccountingSoftwareOption();
  const shouldShowEbillsSyncOption = useShouldShowEbillsSyncOption();
  const { importCta } = useDynamicEbillsImportCopy();

  const renderNestedFilters = () => {
    if (!filterContext) {
      return null;
    }
    return (
      <NestedFilters
        title={formatMessage('activities.payDashboard.billsTab.filter.title')}
        filters={billsTabFilters.filter(({ hidden }) => !hidden)}
        activeFilters={filterContext.filters ?? {}}
        applyFilter={filterContext.applyFilter}
        applyAllFilters={filterContext.applyAllFilters}
        isFilterButtonDisable={isTableLoading}
      />
    );
  };

  const renderFilters = () => {
    if (isBillsTabFiltersMenuEnabled && filterContext?.filters) {
      return renderNestedFilters();
    }
    return null;
  };

  const renderTableContent = () => (
    <>
      <Group alignItems="flex-end" variant="vertical" spacing="m">
        <APTable
          {...tableProps}
          captionLabel={
            shouldShowTabHeader ? formatMessage('activities.payDashboard.tabs.bills.captionLabel') : undefined
          }
        />
        {isEmptySearchResult && !isFetchingItems ? (
          <EmptySearchResult onClear={() => emitFocusEvent(FocusEvents.TAB_TOP_SEARCH)} />
        ) : null}
        {isEmptyStateFilter && !isFetchingItems && isBillsTabFiltersInfraEnabled ? <EmptyFilterResult /> : null}
        <>
          <PayDashboardPagination paginatedCollection={paginationResponse} isVisible={shouldShowPaginationControls} />
        </>
      </Group>
      {/* Fills space on the DOM equals to gap required by pagination controls in order for ActionBar footer to respect it due to it's fixed position  */}
      {rowSelections?.length ? <Box height={`${ROW_HEIGHT}px`} /> : null}
    </>
  );

  const renderContent = () => {
    if (isEmptySearchResult || !isEmptyState || isEmptyStateFilter) {
      return renderTableContent();
    }

    if (shouldShowZeroState) {
      return <PayDashboardZeroState />;
    }

    return (
      <BillsTabEmptyState
        connectGmailCta={importCta}
        shouldShowConnectAccountingSoftwareOption={shouldShowConnectAccountingSoftwareOption}
        onAccountingPlatformConnect={handleAccountingPlatformConnect}
        onAddNewBillUpload={handleAddNewBillUpload}
        onGmailConnect={() => onEbillsSyncClick(true)}
        shouldShouldGmailOption={shouldShowEbillsSyncOption}
        manuallyAddNewBill={handleAddNewBillManual}
        onEmailCopy={handleInboxEmailCopy}
        isUploadBillsEnabled={isUploadBillsEnabled}
        isBillsInboxEnabled={isBillsInboxEnabled}
      />
    );
  };

  const searchBarPlaceholder = isExtraSmallScreen
    ? 'activities.payDashboard.search.placeholder'
    : 'activities.payDashboard.billsTab.search.placeholder';

  const renderSearchBar = () => (
    <SearchBar
      onSearchSubmitted={resetToFirstPage}
      placeholderOnFocus="activities.payDashboard.billsTab.searchPlaceholder"
      label={hideSearchLabel ? undefined : formatMessage('activities.payDashboard.billsTab.searchLabel')}
      pagination={!paginationResponse.isFetching ? paginationResponse.data?.pagination : undefined}
      focusOnEvent={FocusEvents.TAB_TOP_SEARCH}
      placeholder={hideSearchLabel ? searchBarPlaceholder : undefined}
    />
  );

  const hideSearchLabel = isUnderSmallScreen || payDashboardSearchLabelDisabled;

  const renderDesktopHeader = () => (
    <Group variant="horizontal" alignItems="center" justifyContent="space-between" width="full">
      <Group spacing="s" width="full">
        {renderSearchBar()}
        {isBillsTabFiltersMenuEnabled && renderFilters()}
      </Group>

      {!isAccountingPlatformsLoading && (
        <Group width="full" alignItems="center" justifyContent="flex-end">
          {isBillsInboxEnabled && <InboxEmailAddressCopyLinkWidget onCopy={handleInboxEmailCopy} />}
          <AddBillDropdownMenu
            label={formatMessage('activities.payDashboard.billsTab.importButton')}
            onGmailConnect={() => onEbillsSyncClick(false)}
            onAddNewBillUpload={handleAddNewBillUpload}
            onAccountingPlatformConnect={handleAccountingPlatformConnect}
            shouldShouldGmailOption={shouldShowEbillsSyncOption}
            connectGmailCta={importCta}
            shouldShowConnectAccountingSoftwareOption={shouldShowConnectAccountingSoftwareOption}
            isUploadBillsEnabled={isUploadBillsEnabled}
          />
          <Button
            data-testid="bills-tab-add-bill-button"
            onClick={handleAddNewBillManual}
            label={formatMessage('activities.payDashboard.billsTab.addBillButton')}
            variant="tertiary"
            leftElement={
              shouldShowIconInBillsTabCta ? <Icon size="small" type="add" color="inherit" aria-hidden /> : undefined
            }
          />
        </Group>
      )}
    </Group>
  );

  const renderMobileHeader = () => (
    <Group justifyContent="flex-start" width={isSmallScreen ? 'full' : 'auto'}>
      <Group.Item basis={isExtraSmallScreen ? 165 : 200}>{renderSearchBar()}</Group.Item>
      {isMobileSortEnabled && isExtraSmallScreen && (
        <Group.Item>
          <MobileSortMenu
            items={mobileSortMenuOptions}
            onSortChange={resetToFirstPage}
            title="activities.payDashboard.billsTab.sort.title"
            showTriggerText={false}
          />
        </Group.Item>
      )}
      <Group.Item>{renderFilters()}</Group.Item>
    </Group>
  );

  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <Container data-testid="pay-dashboard-bills-tab" overflow="initial">
      {!isLoadingItems && <span ref={routeReady} />}
      <Group variant="vertical" width="full" spacing={isExtraSmallScreen ? 's' : 'm'}>
        <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
          {shouldShowTabHeader && (isUnderSmallScreen ? renderMobileHeader() : renderDesktopHeader())}
        </Container>
        {shouldShowTabHeader &&
        filterContext?.filters &&
        Object.keys(filterContext.filters)?.length &&
        isBillsTabFiltersInfraEnabled ? (
          <Container overflow="initial" paddingX={isExtraSmallScreen ? 'm' : undefined}>
            <FilterChips filterContext={filterContext} tabFilters={billsTabFilters} />
          </Container>
        ) : null}
        <Container overflow="initial" ref={contentRef}>
          {renderContent()}
        </Container>
      </Group>

      {paymentRequestRejectQP && (
        <PaymentRequestRejectModal
          isOpen
          onClose={handleOnCloseRejectModal}
          onSuccess={handleRejectSuccess}
          paymentRequestId={paymentRequestRejectQP}
        />
      )}
      {billItemsToDelete.length > 0 && (
        <DeleteBillItemsModalActivity
          isOpen
          billItems={billItemsToDelete}
          onClose={handleDeleteActionModalClose}
          onSuccess={handleDeleteInboxItemSuccess}
          onError={handleDeleteActionModalError}
        />
      )}
      {isMarkAsPaidBillDialogOpen && (
        <MarkAsPaidModal
          id={billToMarkAsPaidId}
          isOpen={isMarkAsPaidBillDialogOpen}
          onClose={handleMarkAsPaidModalClose}
          onSuccess={handleMarkAsPaidBillSuccess}
          type="bill"
        />
      )}
      <Routes>
        <Route
          path=":billItemId"
          element={
            <BillsTabDrawers
              onClose={onCloseDrawer}
              refetchBillItems={refetchInboxItems}
              onRejectClick={(id) => onRejectClick({ id })}
              onDeleteBillClick={({ id }: { id: string }) =>
                onDeleteBillItemClick({
                  id,
                  type: InboxItemBillTypeEnum.Bill,
                })
              }
              onReviewScannedInvoiceClick={onReviewScannedInvoiceClick}
              onMarkAsPaidClick={onMarkAsPaid}
              onEditBill={onEditBill}
            />
          }
        />
      </Routes>
      {rowSelections.length > 0 && (
        <BillItemsSelectionFooter
          totalCount={billTabItemsResult?.pagination?.totalCount ?? 0}
          billItems={rowSelections}
          onDeleteBillItems={() => setBillItemsToDelete(rowSelections)}
          getBillIdsToReviewAndPay={getBillIdsToReviewAndPay}
          onCancel={() => setRowSelections([])}
          maxWidth={contentRef.current?.getBoundingClientRect().width}
        />
      )}
      <EbillsImportModal isOpen={isEbillsImportModalOpen} onClose={() => toggleEbillsImportModal(false)} />
    </Container>
  );
};

export const BillsTab = (props: BillsTabProps) => {
  const [isBillsTabFiltersInfraEnabled] = usePartnerFeature('BillsTabFiltersInfra', false);

  const [searchParams] = useSearchParams();
  return (
    <PayDashboardPaginationProvider>
      <PayDashboardSortingProvider
        defaultSort={{ id: CELLS_IDS.DUE_DATE, sortDirection: 'asc' }}
        tableId={PayDashboardTabs.Bills}
      >
        <PayDashboardFilterProvider
          urlSearchParams={searchParams}
          shouldInitializeProvider={isBillsTabFiltersInfraEnabled}
          supportedSearchParamKeys={supportedSearchParamKeys}
        >
          <BillsTabComponent {...props} />
        </PayDashboardFilterProvider>
      </PayDashboardSortingProvider>
    </PayDashboardPaginationProvider>
  );
};
