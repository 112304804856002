import { SharedEmptyState } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { Filters } from '../types';

export type EmptyStateProps = {
  search?: string;
  filters?: Filters;
  onClearSearch?: VoidFunction;
};

export const InvoiceTableEmptyState = forwardRef<EmptyStateProps, 'div'>(
  ({ search, filters = {}, onClearSearch, ...props }, ref) => (
    <SharedEmptyState
      data-testid="invoices-dashboard-no-search-result"
      ref={ref}
      search={search}
      filters={filters}
      searchMessage="ar.dashboard.activities.invoiceTable.emptyState.no-search-results.search.value.primary.label"
      defaultMessage="ar.dashboard.activities.invoiceTable.emptyState.no-search-results.primary.label"
      actions={
        onClearSearch
          ? [
              {
                label: 'ar.dashboard.activities.invoiceTable.filters.advanced.clear.label',
                onClick: onClearSearch,
                variant: 'secondary',
                testId: 'clean-search-button',
              },
            ]
          : []
      }
      data-component={InvoiceTableEmptyState.displayName}
      {...props}
    />
  )
);

InvoiceTableEmptyState.displayName = 'InvoiceTableEmptyState';
