import React, { ComponentProps, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Group, Link, Text, useBreakpointValue } from '@melio/penny';
import { useLegalDocumentsContent } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

import { MelioIcon } from '@/cl/icons/system';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { FooterLayoutTheme } from './footerLayout.theme';

export const FooterLayout = () => {
  const style = useAppTheme(FooterLayoutTheme, {});
  const {
    partnerConfig: {
      config: {
        links,
        settings: { supportPhoneNumber, shouldHideLogoInFooter, showSupportNumberInFooter },
      },
      partnerProductName,
      displayName,
    },
  } = usePartnerConfig();
  const [signTermsAndConditionsEnabled] = usePartnerFeature('TermsAndConditions', false);
  const {
    data: legalDocumentContent,
    isFetching: legalDocumentContentFetching,
    isLoading: legalDocumentContentLoading,
  } = useLegalDocumentsContent({ isEnabled: signTermsAndConditionsEnabled });
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      !signTermsAndConditionsEnabled ||
      legalDocumentContentFetching ||
      legalDocumentContentLoading ||
      !legalDocumentContent
    ) {
      return;
    }
    const file = new File([legalDocumentContent], 'terms-and-conditions.html', { type: 'text/html' });
    setTermsAndConditionsUrl(URL.createObjectURL(file));
  }, [legalDocumentContent, legalDocumentContentFetching, signTermsAndConditionsEnabled, legalDocumentContentLoading]);

  const { formatMessage } = usePlatformIntl();
  const footerDirection = useBreakpointValue<ComponentProps<typeof Group>['variant']>({
    xs: 'vertical',
    s: 'horizontal',
  });
  const footerGap = useBreakpointValue<ComponentProps<typeof Group>['spacing']>({ xs: 'none', s: 'xs' });

  const getLink = ({ href, text }: { href: string; text: string }) => (
    <Box css={style['link']}>
      <Link color="inherit" href={href} label={text} newTab />
    </Box>
  );

  const getNewWindowLink = ({ url, text }: { url?: string; text: string }) => (
    <Box css={style['link']}>
      <Link
        color="inherit"
        href="#"
        label={text}
        onClick={() => window.open(url, '_blank', 'width=600,height=400,left=300,top=200')}
      />
    </Box>
  );

  return (
    <Box data-testid="footer-container" __css={style['footer']}>
      {!shouldHideLogoInFooter && (
        <MelioIcon width={'50px'} height={'24px'} marginBottom={'2px'} color="global.neutral.600" />
      )}
      <Group variant={footerDirection} spacing={footerGap} alignItems={{ xs: 'flex-start', s: 'center' } as never}>
        <Text as="span" textStyle="caption1" color="semantic.text.secondary">
          {formatMessage('widgets.footer.info', { partnerProductName, displayName })}
        </Text>
        <Group>
          {signTermsAndConditionsEnabled
            ? getNewWindowLink({
                url: termsAndConditionsUrl,
                text: formatMessage('widgets.footer.termsOfServiceLink.text'),
              })
            : getLink({
                href: links['widgets.footer.termsOfServiceLink.href'],
                text: formatMessage('widgets.footer.termsOfServiceLink.text'),
              })}
          {getLink({
            href: links['widgets.footer.privacyPolicy.href'],
            text: formatMessage('widgets.footer.privacyPolicy.text'),
          })}
          {showSupportNumberInFooter &&
            getLink({
              href: `tel:${supportPhoneNumber}`,
              text: supportPhoneNumber,
            })}
        </Group>
      </Group>
    </Box>
  );
};
