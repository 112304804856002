import { useMelioIntl } from '@melio/ar-domain';
import { Group, Modal, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ExportReportModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: () => Promise<void>;
  isExporting?: boolean;
};

export const ExportReportModal = forwardRef<ExportReportModalProps>(
  ({ isOpen, onClose, onConfirm, isExporting = false }, ref) => {
    const { formatMessage } = useMelioIntl();

    return (
      <Modal
        isOpen={isOpen}
        onClose={() => !isExporting && onClose()}
        header={formatMessage('ar.dashboard.activities.paymentsTable.exportModal.title')}
        data-testid="export-report-modal"
        ref={ref}
        primaryButton={{
          label: formatMessage('ar.dashboard.activities.paymentsTable.exportModal.confirm'),
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick: onConfirm,
          isLoading: isExporting,
          variant: 'primary',
        }}
        secondaryButton={{
          label: formatMessage('ar.dashboard.activities.paymentsTable.exportModal.cancel'),
          onClick: onClose,
          variant: 'tertiary',
          isDisabled: isExporting,
        }}
        shouldReturnFocus
      >
        <Group spacing="l" variant="vertical">
          <Text>{formatMessage('ar.dashboard.activities.paymentsTable.exportModal.description')}</Text>
        </Group>
      </Modal>
    );
  }
);

ExportReportModal.displayName = 'ExportReportModal';
