import { useMelioIntl } from '@melio/platform-i18n';

import { Tier } from '../api';
import { useSubscriptionContext } from '../context';
import { useFreeTrialEligibility } from './useFreeTrialEligibility';

type PlansPromotionData = {
  code: string;
  discount: number;
  appliedForPlans: Tier[];
  bannerContent: string;
};

const WELCOME_USERS_PROMO_DISCOUNT = 0.15;

export const usePlansPromotion = () => {
  const { formatMessage, formatPercents } = useMelioIntl();

  const welcomeUsersPromoCodeData: PlansPromotionData = {
    code: 'WELCOME15',
    discount: WELCOME_USERS_PROMO_DISCOUNT,
    appliedForPlans: [Tier.MelioPlus, Tier.MelioPro, Tier.MelioUnlimitedAch],
    bannerContent: formatMessage('activities.subscription.plans.selection.promotionBanner.newUser.description', {
      discountPercent: formatPercents(WELCOME_USERS_PROMO_DISCOUNT),
    }),
  };

  const { isEligibleForFreeTrial, isFetching: isFetchingEligibilityForFreeTrialData } = useFreeTrialEligibility();

  const {
    gracePeriod: { isOrgCreatedBeforeGracePeriodStart },
  } = useSubscriptionContext();

  const isEligibleForWelcomePromo =
    !isFetchingEligibilityForFreeTrialData && !isOrgCreatedBeforeGracePeriodStart && isEligibleForFreeTrial;

  const plansPromotionData = isEligibleForWelcomePromo ? welcomeUsersPromoCodeData : undefined;

  return { plansPromotionData, isFetching: isFetchingEligibilityForFreeTrialData };
};
