import { Container, Group } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount, useFundingSources } from '@melio/platform-api';
import { Layout } from '@melio/platform-ds';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { useSubscription } from '@melio/subscriptions';
import { useSubscriptionMe } from '@melio/subscriptions/src/api';
import { kebabCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { useAccountingFirmClientFundingSources } from '../../api/entities';
import { usePatchAccountantFirmClient } from '../../api/entities/client/usePatchAccountantFirmClient';
import { ErrorBanner, FlowHeader, ManageClientFormFields } from '../../components';
import { RedirectTarget } from '../../consts';
import { useUpdateClientSubscriptionBillingOptions } from '../../hooks';
import { ManageClientFlow, SubscriptionBillingPayor } from '../../types';
import { routes } from '../../utils/routes';
import { useAccountantsRoutes } from '../../utils/useAccountantsRoutes';
import { UpdateClientBillingInfoForm } from './components/UpdateClientBillingInfoForm';

export const UpdateClientBillingOption = withAnalyticsContext<{
  onClose: VoidFunction;
  onDone(params: { firmClientOrgId: string; returnTo: RedirectTarget }): Promise<void>;
}>(({ onClose, setAnalyticsProperties, onDone }) => {
  const { formatMessage } = useMelioIntl();
  const pageTitle = formatMessage('activities.accountants.manageBillingOption.form.header.text');
  const PageName = kebabCase(pageTitle);
  setAnalyticsProperties({
    PageName,
    Flow: 'update-client-billing-info',
  });

  const clientOrgId = useParams<{ id: string }>().id ?? '';

  const { data: firmAccount } = useAccount({ id: 'me' });
  const firmSubscription = useSubscription();
  const { update: updateSubscription } = useSubscriptionMe({ enabled: false });
  const [updateError, setUpdateError] = useState<Error>();

  const { mutateAsync: updateAccountantFirmClient, isLoading: isLoadingUpdateAccountFirmClient } =
    usePatchAccountantFirmClient();

  const { isLoading: isLoadingClientSubscriptionBillingOptions, error: clientSubscriptionBillingOptionsError } =
    useUpdateClientSubscriptionBillingOptions(clientOrgId);

  const { isLoading: isFirmFundingSourcesLoading, error: firmFundingSourcesError } = useFundingSources();

  const { isLoading: isLoadingClientFundingSources, error: clientFundingSourcesError } =
    useAccountingFirmClientFundingSources(clientOrgId);

  const { goToClientsDashboard } = useAccountantsRoutes();
  const { track } = useAnalytics();

  const handleSubmit = async (data: Pick<ManageClientFormFields, 'whoPays' | 'fundingSourceId'>) => {
    setUpdateError(undefined);
    const { fundingSourceId, whoPays } = data;

    const eventProps = {
      FundingSourceId: fundingSourceId,
      PageName,
    };

    try {
      const isFirmBilled = whoPays === SubscriptionBillingPayor.AccountingFirm;
      if (isFirmBilled && fundingSourceId && !firmSubscription?.fundingSourceId) {
        await updateSubscription({ fundingSourceId });
      }
      const response = await updateAccountantFirmClient({
        id: clientOrgId,
        data: {
          subscription: {
            fundingSourceId,
          },
        },
      });
      track('Organization', 'Status', {
        StatusType: 'success',
        ...eventProps,
      });
      goToClientsDashboard({
        state: {
          flowResult: {
            clientOrgId: response.data.organizationId,
            success: true,
            flow: ManageClientFlow.UpdateSubscriptionBillingPayor,
          },
        },
      });
    } catch (error: unknown) {
      const message =
        error && typeof error === 'object' && 'message' in error && typeof error.message === 'string'
          ? error.message
          : formatMessage('activities.accountants.activities.assignPan.update.error');
      setUpdateError(new Error(message));
      track('Organization', 'Status', {
        StatusType: 'failure',
        ...eventProps,
      });
      Logger.log(`Error while trying to create new client.`);
    }
  };

  const shouldRedirectToDashboard = clientOrgId === firmAccount?.organizationId;

  useEffect(() => {
    if (!shouldRedirectToDashboard) {
      track('Organization', 'View', {
        PageName,
      });
    }
  }, [PageName, shouldRedirectToDashboard, track]);

  const isLoading =
    isLoadingClientFundingSources || isLoadingClientSubscriptionBillingOptions || isFirmFundingSourcesLoading;
  const error =
    updateError || clientSubscriptionBillingOptionsError || clientFundingSourcesError || firmFundingSourcesError;

  if (shouldRedirectToDashboard) {
    return <Navigate to={routes.DASHBOARD} replace />;
  }

  return (
    <Layout isLoading={isLoading}>
      <Container justifyContent="center" height="full" data-testid="manage-client-billing-option-container">
        <Group spacing="m" variant="vertical" width={{ xs: '100%', s: '800px' } as never}>
          <FlowHeader title={pageTitle} onClose={onClose} />
          <ErrorBanner message={error?.message} dataTestId="update-billing-method-error-banner" />
          <UpdateClientBillingInfoForm
            onSubmit={handleSubmit}
            isSaving={isLoadingUpdateAccountFirmClient}
            clientOrgId={clientOrgId}
            onNavigateToClientPaymentMethodsSettings={() =>
              void onDone({ firmClientOrgId: clientOrgId, returnTo: RedirectTarget.ClientPaymentMethodsSettings })
            }
          />
        </Group>
      </Container>
    </Layout>
  );
});
