import { Box } from '@chakra-ui/react';
import { Button, Counter, FloatingMenu, Group, Icon, IconButton, Menu, StatusIndicator, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { compact, kebabCase, lowerCase, toLower } from 'lodash';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo, useRef, useState } from 'react';

import { FilterMenuOptionsMulti } from './FilterMenuOptionsMulti';
import { FilterMenuOptionsSingle } from './FilterMenuOptionsSingle';
import { NestedFiltersProps } from './types';
import { getFilterByKey, getSelectedOptionsFromActiveFilter } from './utils';

export const NestedFiltersMobile = <T extends string>({
  filters,
  activeFilters,
  title,
  applyFilter,
}: NestedFiltersProps<T>) => {
  const { formatMessage } = useMelioIntl();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [currentFilterOptions, setCurrentFilterOptions] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const [visibleFilterKey, setVisibleFilterKey] = useState<T | undefined>();
  const label = formatMessage('filter.button.text');
  const firstFiletMenuRef = useRef<HTMLDivElement>(null);
  const firstOptionRef = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();
  useEffect(() => {
    if (visibleFilterKey) {
      const visibleFilter = getFilterByKey(filters, visibleFilterKey);
      const activeFilterSelectedOptions = getSelectedOptionsFromActiveFilter(visibleFilter, activeFilters);

      setCurrentFilterOptions(activeFilterSelectedOptions);
      setSelectedOptions(activeFilterSelectedOptions);

      track('Dashboard', 'Click', {
        Intent: 'filter',
        FilterValue: kebabCase(lowerCase(visibleFilter?.label)),
        Cta: `filters_${kebabCase(lowerCase(visibleFilter?.label))}`,
      });
    } else {
      setCurrentFilterOptions([]);
      setSelectedOptions([]);
    }
  }, [filters, visibleFilterKey, activeFilters, track]);

  useEffect(() => {
    if (visibleFilterKey) {
      const activeFilter = getFilterByKey(filters, visibleFilterKey);
      if (activeFilter) {
        firstOptionRef.current?.focus();
      }
    } else {
      firstFiletMenuRef.current?.focus();
    }
  }, [filters, visibleFilterKey]);

  const hasActiveFilters = useMemo(() => {
    if (!activeFilters) {
      return false;
    }
    const keys = Object.keys(activeFilters) as T[];
    return keys.filter((key) => activeFilters[key] && activeFilters[key]).length > 0;
  }, [activeFilters]);

  const onApply = () => {
    if (visibleFilterKey) {
      const visibleKeyLabel = filters.find((f) => f.key === visibleFilterKey)?.label;
      track('Dashboard', 'Click', {
        Intent: 'filter',
        FilterValue: kebabCase(toLower(visibleKeyLabel)),
        FilterField: currentFilterOptions.map((item) => kebabCase(toLower(item))).sort((a, b) => a.localeCompare(b)),
        Cta: 'apply',
      });
      setSelectedOptions(currentFilterOptions);
      applyFilter(visibleFilterKey, currentFilterOptions);
    }
    handleOpenMenuChange(false);
  };

  const handleOpenMenuChange = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
    if (!isOpen) {
      cleanState();
    }
  };

  const onToggleOptionWithMultipleChoises = (optionValue: string) => {
    if (currentFilterOptions.includes(optionValue)) {
      setCurrentFilterOptions(currentFilterOptions.filter((cur) => cur !== optionValue));
    } else {
      setCurrentFilterOptions([...currentFilterOptions, optionValue]);
    }
  };

  const cleanState = () => {
    setVisibleFilterKey(undefined);
    setCurrentFilterOptions([]);
    setSelectedOptions([]);
  };

  const renderMenuOptions = () => {
    if (!visibleFilterKey) {
      return null;
    }
    const visibleFilter = getFilterByKey(filters, visibleFilterKey);
    if (!visibleFilter) {
      return null;
    }
    if (visibleFilter.type === 'multi') {
      return (
        <FilterMenuOptionsMulti
          options={visibleFilter.options}
          selected={currentFilterOptions}
          onChange={onToggleOptionWithMultipleChoises}
          firstOptionRef={firstOptionRef}
        />
      );
    }
    return (
      <FilterMenuOptionsSingle
        options={visibleFilter.options}
        selected={currentFilterOptions}
        onChange={(value, data) => {
          value ? setCurrentFilterOptions(compact([value, data])) : setCurrentFilterOptions([]);
        }}
        firstOptionRef={firstOptionRef}
        activeFilter={visibleFilter}
      />
    );
  };

  const renderMenuFilters = () => (
    <Group variant="vertical" spacing="xxs">
      {filters.map((filter, index) => {
        const selectedOptionsCount = activeFilters?.[filter.key]?.length
          ? filter.type === 'multi'
            ? activeFilters?.[filter.key]?.length
            : 1
          : 0;

        return (
          <FloatingMenu.Item
            key={filter.key}
            onClick={() => setVisibleFilterKey(filter.key)}
            data-testid={`filter-menu-item-${filter.key}`}
            {...(index === 0 && { ref: firstFiletMenuRef })}
          >
            <Group justifyContent="space-between" width="full">
              <Group spacing="xxs" alignItems="center">
                <Text textStyle="body3Semi">{filter.label}</Text>
                {selectedOptionsCount ? (
                  <Counter
                    number={selectedOptionsCount}
                    status="brand"
                    data-testid={`filter-menu-item-counter-${filter.key}`}
                  />
                ) : null}
              </Group>
              <Icon type="chevron-right" />
            </Group>
          </FloatingMenu.Item>
        );
      })}
    </Group>
  );

  const renderCurrentFilterOrOption = () => {
    if (visibleFilterKey) {
      return renderMenuOptions();
    }
    return renderMenuFilters();
  };

  const renderTrigger = () => {
    const iconButton = (
      <IconButton icon="filter" variant="primary" size="large" aria-label={label} data-testid="filter-trigger" />
    );

    if (hasActiveFilters) {
      // We need the Box wrapper so the trigger will work properly
      return (
        <Box>
          <StatusIndicator status="informative">{iconButton}</StatusIndicator>
        </Box>
      );
    }

    return iconButton;
  };

  const renderTitle = () => {
    if (visibleFilterKey) {
      return {
        header: (
          <Group alignItems="center">
            <IconButton
              icon="arrow-left"
              onClick={cleanState}
              size="small"
              variant="naked"
              data-testid="filter-back"
            ></IconButton>
            <Text textStyle="body3Semi" color="semantic.text.secondary">
              {formatMessage('filter.nested.inner.options.title', {
                filterBy: filters.find((f) => f.key === visibleFilterKey)?.label?.toLowerCase(),
              })}
            </Text>
          </Group>
        ),
      };
    }
    return { title };
  };

  return (
    <Menu
      data-testid="nested-filter-menu"
      trigger={renderTrigger()}
      onOpenChange={handleOpenMenuChange}
      isOpen={isMenuOpen}
      {...renderTitle()}
      footer={
        visibleFilterKey
          ? {
              label: formatMessage('activities.payDashboard.filter.apply.text'),
              onClick: onApply,
              isDisabled: isEqual(selectedOptions, currentFilterOptions),
              children: (
                <Button
                  label={formatMessage('activities.payDashboard.filter.apply.text')}
                  variant="primary"
                  data-testid="apply-filter"
                  isFullWidth
                />
              ),
            }
          : undefined
      }
    >
      <>{renderCurrentFilterOrOption()}</>
    </Menu>
  );
};
