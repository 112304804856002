import { Group, Icon, ListItem } from '@melio/penny';
import { DeliveryMethod, FeeCatalog, FundingSource, Vendor } from '@melio/platform-api';

import { useDeliveryMethodCardProps } from '../../utils/useDeliveryMethodCardProps';

type Props = {
  vendor?: Vendor;
  deliveryMethod: DeliveryMethod;
  fundingSource: FundingSource | undefined;
  isDisabled: boolean;
  feesCatalog?: FeeCatalog[];
};

export const DeliveryMethodCardContents = ({ vendor, deliveryMethod, fundingSource, isDisabled }: Props) => {
  const { title, icon, description, pillProps } = useDeliveryMethodCardProps({
    vendor,
    deliveryMethod,
    fundingSource,
  });

  return (
    <Group
      data-testid={`payment-flow-form-delivery-method-card-${deliveryMethod.id}`}
      width="full"
      variant="horizontal"
      alignItems="center"
    >
      <Icon type={icon} isDisabled={isDisabled} />
      <ListItem
        isDisabled={isDisabled}
        mainLabelProps={{
          label: title,
          variant: 'bold',
          pillProps,
        }}
        descriptionProps={{ label: description }}
      />
    </Group>
  );
};
