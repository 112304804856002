import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ManageClientFlowResultPayload } from '../types';
import { routes } from './routes';

type RouteState = { flowResult?: ManageClientFlowResultPayload };

export const useAccountantsRoutes = () => {
  const navigate = useNavigate();
  const state = useLocation().state as RouteState | null;

  return React.useMemo(
    () => ({
      goToClientsDashboard: (params?: { state?: RouteState; replace?: boolean }) =>
        navigate(routes.DASHBOARD, {
          replace: params?.replace,
          ...(params?.state && {
            state: {
              flowResult: params.state.flowResult,
            },
          }),
        }),
      goToClientCreationOptions: () => navigate(routes.CREATION_OPTIONS),
      goToAddNewClient: () => navigate(routes.ADD_NEW_CLIENT),
      goToInvitationSent: () => navigate(routes.INVITATION_SENT, { replace: true }),
      goToInviteExistingClient: () => navigate(routes.INVITE_EXISTING_CLIENT),
      goToClientCreatedSuccessScreen: (state: { invited: boolean; company: string; orgId: string }) => {
        const params = new URLSearchParams({ ...state, invited: JSON.stringify(state.invited) });
        return navigate(`${routes.CLIENT_CREATED}?${params.toString()}`);
      },
      goToAssignClientPlan: ({ accountingClientId, refresh }: { accountingClientId: string; refresh?: boolean }) => {
        navigate(routes.getClientAssignPlanRoute(accountingClientId));
        if (refresh) {
          navigate(0);
        }
      },
      goToManageBillingOption: ({ accountingClientId }: { accountingClientId: string }) => {
        navigate(routes.getClientManageBillingOptionRoute(accountingClientId));
      },
      goToAssignClientPlanSuccessScreen: ({
        accountingClientId,
        activated,
      }: {
        accountingClientId: string;
        activated: boolean;
      }) => {
        const params = new URLSearchParams({ activated: JSON.stringify(activated) });
        return navigate(`${routes.getClientAssignPlanSuccessRoute(accountingClientId)}?${params.toString()}`);
      },
      goToClientAccountingSoftwareRedirect: (orgId: string | null) => {
        const params = new URLSearchParams({ orgId: orgId || '' });
        return navigate(`${routes.CLIENT_ACCOUNTING_SOFTWARE_REDIRECT}?${params.toString()}`);
      },
      goToSupportPage: () => navigate('/settings/support'),
      goToBillingMethodSetting: () => navigate('/settings/billing'),
      goToCollaboratorsSettings: (options?: { refresh: boolean }) => {
        navigate('/settings/collaborators');
        if (options?.refresh) {
          navigate(0);
        }
      },
      goToPayDashboardBillsTab: (options?: { refresh: boolean; id?: string; query?: string }) => {
        navigate(
          `/pay-dashboard/bills${options?.id ? `/${options.id}` : ''}${options?.query ? `?${options.query}` : ''}`
        );
        if (options?.refresh) {
          navigate(0);
        }
      },
      goToPayDashboardPaymentsTab: (options?: { refresh: boolean; query?: string }) => {
        navigate(`/pay-dashboard/payments${options?.query ? `?${options.query}` : ''}`);
        if (options?.refresh) {
          navigate(0);
        }
      },
      goToPayDashboardApprovalsTab: (options?: { refresh: boolean; id?: string; query?: string }) => {
        navigate(
          `/pay-dashboard/approvals${options?.id ? `/${options.id}` : ''}${options?.query ? `?${options.query}` : ''}`
        );
        if (options?.refresh) {
          navigate(0);
        }
      },
      goToAddBankAccount: ({ returnUrl }: { returnUrl: string }) =>
        navigate(`/payment-methods/bank/select?integrationType=plaid&returnUrl=${encodeURIComponent(returnUrl)}`),
      goToAddCard: ({ returnUrl }: { returnUrl: string }) =>
        navigate('/payment-methods/card', { state: { returnUrl } }),
      state,
    }),
    [navigate, state]
  );
};
