import { useCollectionApi } from '@melio/api-client';
// eslint-disable-next-line no-restricted-imports
import { AccountingFirmClientsApiClient } from '@melio/platform-api-axios-client';

type QueryParams = { params: { activityStartDate: Date; activityEndDate: Date }; enabled?: boolean };
type QueryFn = typeof AccountingFirmClientsApiClient.getAccountingFirmClients;

export const useAccountingFirmClientsV2 = (props: QueryParams) =>
  useCollectionApi<QueryFn, never, never, never>({
    queryKey: ['AccountingFirmClientsApi', 'collection', 'v2'],
    queryFn: () =>
      AccountingFirmClientsApiClient.getAccountingFirmClients(
        props.params.activityStartDate,
        props.params.activityEndDate
      ),
    enabled: props?.enabled,
  });
