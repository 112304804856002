/* eslint-disable max-lines */
import { act, screen, waitFor, within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

export async function fillTextInput(name: string, value: string, clearFields = false) {
  if (clearFields) {
    await clearField(name);
  }
  await userEvent.type(await screen.findByTestId(`form-input-${name}`), value, { delay: 1 });
}

export async function clearField(name: string) {
  await userEvent.clear(await screen.findByTestId(`form-input-${name}`));
}

export async function fillTextInputs(values: Record<string, string>, clearFields = false) {
  for (const [name, value] of Object.entries(values)) {
    await fillTextInput(name, value, clearFields);
  }
}

export const selectRadioButton = async (groupName: string, value: string): Promise<void> => {
  await userEvent.click(await screen.findByTestId(`form-input-${groupName}-${value}`));
};
export const toggleCheckbox = async (name: string): Promise<void> => {
  await userEvent.click(await screen.findByTestId(`form-input-${name}`));
};
export const fillSelectInput = async (name: string, value: string) => {
  await userEvent.click(within(await screen.findByTestId(`form-field-${name}`)).getByTestId('select-control'));
  await userEvent.click(await screen.findByTestId(value));
};
export const fillNewSelectInput = async (name: string, value: string) => {
  // TODO: delete uses of depreactedSelect and then delete the current fillSelectInput
  await userEvent.click(await screen.findByTestId(`form-input-${name}`));
  await userEvent.click(await screen.findByTestId(value));
};
export const fillSelectNew = async ({
  testId,
  name = '',
  value,
}: {
  testId?: string;
  name?: string;
  value: string;
}) => {
  await userEvent.click(await screen.findByTestId(testId ?? `form-input-${name}-trigger-input`));
  await userEvent.click(await screen.findByText(value));
};
export const assertSelectOption = async ({
  testId,
  name,
  value,
  isExists,
}: {
  testId?: string;
  name: string;
  value: string;
  isExists: boolean;
}) => {
  await userEvent.click(await screen.findByTestId(testId ?? `form-input-${name}-trigger-input`));
  const option = screen.queryByTestId(`form-input-businessType-option-${value}`);
  await waitFor(() => {
    if (isExists) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expect(option).toBeInTheDocument();
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      expect(option).not.toBeInTheDocument();
    }
  });
};
export const fillSelectInputOption = async ({
  testId,
  name,
  value,
}: {
  testId?: string;
  name: string;
  value: string;
}) => {
  await userEvent.click(await screen.findByTestId(testId ?? `form-input-${name}-trigger-input`));
  await userEvent.click(await screen.findByTestId(`form-input-businessType-option-${value}`));
};
export const assertSelectInputHasValue = async (name: string, value: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  expect(within(await screen.findByTestId(`form-field-${name}`)).getByTestId('select-control')).toHaveTextContent(
    value
  );
};
export const assertSelectNewHasValue = ({
  name = '',
  value,
  testId,
}: {
  name?: string;
  testId?: string;
  value: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  expect(screen.getByTestId(testId ?? `form-input-${name}-trigger-value`)).toHaveTextContent(value);
};
// https://linear.app/meliopayments/issue/PLA-497
export const fillAsyncSelect = async (
  name: string,
  value: string,
  selectedOptionText: string | RegExp | undefined | null = new RegExp(value),
  useFakeTimers = true
) => {
  useFakeTimers && jest.useFakeTimers();

  await userEvent.click(within(screen.getByTestId(`form-field-${name}`)).getByTestId('select-control'));
  await userEvent.type(within(screen.getByTestId(`form-field-${name}`)).getByTestId('select-control'), value);

  if (useFakeTimers) {
    // Advance the timer by 500ms - just enough for the debounce to tick
    // eslint-disable-next-line @typescript-eslint/require-await
    await act(async () => {
      jest.advanceTimersByTime(500);
    });

    // We're stopping the fake timers here since fake timers don't work well with MSW, and it will cause the api call not to return in time.
    jest.useRealTimers();
  }

  await userEvent.click(within(screen.getByTestId('select-menu')).getByText(selectedOptionText ?? value));
};

export const fillAsyncNewSelect = async (
  value: string,
  selectedOptionText: string | RegExp | undefined | null = new RegExp(value),
  useFakeTimers = true
) => {
  await userEvent.click(screen.getByRole('combobox'));

  await userEvent.type(screen.getByRole('combobox'), value);

  if (useFakeTimers) {
    // Advance the timer by 500ms - just enough for the debounce to tick
    // eslint-disable-next-line @typescript-eslint/require-await
    await act(async () => {
      jest.advanceTimersByTime(500);
    });

    // We're stopping the fake timers here since fake timers don't work well with MSW, and it will cause the api call not to return in time.
    jest.useRealTimers();
  }

  await userEvent.click(within(screen.getByTestId('base-select-dropdown-menu')).getByText(selectedOptionText ?? value));
};
export type FillSearchSearchOptionTextType = string | RegExp | undefined | null;

export const typeSearchTerm = async (name: string, value: string) => {
  await userEvent.type(within(screen.getByTestId(`form-field-${name}`)).getByTestId(`form-input-${name}`), value);
};

export const typeComboboxSearchTerm = async (name: string, value: string) => {
  await userEvent.click(
    within(screen.getByTestId(`form-field-${name}`)).getByTestId(`form-field-${name}-render-field-trigger-input`)
  );
  await userEvent.type(
    within(screen.getByTestId(`form-field-${name}`)).getByTestId(`form-field-${name}-render-field-trigger-input`),
    value
  );
};

export const fillSearchField = async (
  name: string,
  value: string,
  selectedOptionText: FillSearchSearchOptionTextType = new RegExp(value)
) => {
  jest.useFakeTimers();
  const user = userEvent.setup({ delay: null, advanceTimers: jest.advanceTimersByTime });
  await user.type(within(screen.getByTestId(`form-field-${name}`)).getByTestId(`form-input-${name}`), value);
  await screen.findByTestId('base-select-dropdown-menu');
  await act(() => jest.advanceTimersByTimeAsync(2000));
  act(() => jest.advanceTimersByTime(2000));
  const el = await waitFor(() =>
    within(screen.getByTestId('base-select-dropdown-menu')).getByText(selectedOptionText ?? value)
  );
  await user.click(el);
  await act(() => jest.advanceTimersByTimeAsync(2000));
  act(() => jest.advanceTimersByTime(2000));
  jest.useRealTimers();
};

export const fillComboboxField = async (
  name: string,
  value: string,
  selectedOptionText: FillSearchSearchOptionTextType = new RegExp(value)
) => {
  await typeComboboxSearchTerm(name, value);
  await userEvent.click(
    within(screen.getByTestId(`form-field-${name}-render-field-menu`)).getByText(selectedOptionText ?? value)
  );
};

export const fillVendorSelect = async (
  name: string,
  value: string,
  selectedOptionText: string | RegExp | undefined | null = new RegExp(value)
) => {
  await userEvent.click(within(screen.getByTestId(`form-field-${name}`)).getByRole('combobox'));
  await userEvent.type(within(screen.getByTestId(`form-field-${name}`)).getByRole('combobox'), value);
  const el = await waitFor(
    () => within(screen.getByTestId('base-select-dropdown-menu')).getByText(selectedOptionText ?? value),
    { timeout: 5000 }
  );
  await userEvent.click(el);
};

export const createOptionInVendorSelect = async (name: string, value: string) => {
  await userEvent.click(screen.getByTestId(name));
  await userEvent.type(screen.getByTestId(name), value, { delay: 1 });

  await userEvent.click(screen.getByTestId('base-select-creatable-option'));
};

export const createOptionInSelect = async (name: string, value: string, expectedValue?: string) => {
  jest.useFakeTimers();
  await userEvent.click(within(screen.getByTestId(name)).getByTestId('select-control'));
  await userEvent.type(within(screen.getByTestId(name)).getByTestId('select-control'), value);
  // Advance the timer by 500ms - just enough for the debounce to tick
  // eslint-disable-next-line @typescript-eslint/require-await
  await act(async () => {
    jest.advanceTimersByTime(500);
  });

  // We're stopping the fake timers here since fake timers don't work well with MSW, and it will cause the api call not to return in time.
  jest.useRealTimers();

  await userEvent.click(within(screen.getByTestId('select-menu')).getByText(expectedValue || value));
};
const waitForSelectToFinishLoading = async () => {
  // TODO: solve typescript issue with `toHaveAttribute`
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await waitFor(() => expect(screen.getByRole('combobox')).not.toHaveAttribute('data-loading'));
};
export const createNewOptionSelect = async (newAccountName: string, displayFundingSourceName: string) => {
  await waitForSelectToFinishLoading();
  await userEvent.click(screen.getByRole('combobox'));
  await userEvent.type(screen.getByRole('combobox'), newAccountName);
  await userEvent.click(screen.getByTestId('base-select-creatable-option'));
  await userEvent.click(screen.getByRole('combobox'));
  await userEvent.click(screen.getByTestId(displayFundingSourceName));
};
export const createOptionInReconciliationSelect = async (value: string) => {
  await waitForSelectToFinishLoading();
  await userEvent.click(screen.getByRole('combobox'));

  await userEvent.type(screen.getByRole('combobox'), value);
  await userEvent.click(screen.getByTestId('base-select-creatable-option'));
};
export const fillDateInputWithToday = async (name: string) => {
  await userEvent.click(screen.getByTestId(`form-input-${name}`));
  await userEvent.click(within(await screen.findByTestId(`form-input-${name}-calendar`)).getByTestId('today-marker'));
};
export const assertValidationError = async (field: string, errorMessage = '') =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  await waitFor(() => expect(screen.getByTestId(`form-error-message-${field}`)).toHaveTextContent(errorMessage));
export const querySearchComponent = (fieldName: string) => {
  const field = screen.getByTestId(`form-field-${fieldName}`);

  return within(field).queryByTestId('search-icon');
};
export const querySelectComponent = (fieldName: string) => {
  const field = screen.getByTestId(`form-field-${fieldName}`);

  return within(field).queryByTestId('select-toggle-button');
};

export const queryField = (fieldName: string) => screen.getByTestId(`form-field-${fieldName}`);
