import { screen } from '@testing-library/react';

const trackerStepToday = 'tracker-step-today';
const trackerStepReminder = 'tracker-step-reminder';
const trackerStepCharged = 'tracker-step-charged';
const tracker = 'subscription-checkout-free-trial-tracker';
const chargedPriceDescription = 'tracker-step-charged-price-description';

export class SubscriptionCheckoutFreeTrialTrackerDriver {
  getTracker() {
    return screen.queryByTestId(tracker);
  }
  getTrackerStepToday() {
    return screen.getByTestId(trackerStepToday);
  }
  getTrackerStepReminder() {
    return screen.getByTestId(trackerStepReminder);
  }
  getTrackerStepCharged() {
    return screen.getByTestId(trackerStepCharged);
  }
  getChargedPriceDescription() {
    return screen.getByTestId(chargedPriceDescription);
  }
}
