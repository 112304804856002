/// <reference types='google.maps' />
import { Combobox, FormComboboxProps, FormField } from '@melio/penny';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useConfig, useMelioIntl } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import usePlacesAutocompleteService from '@melio/react-google-autocomplete/lib/usePlacesAutocompleteService';

import { InternationalAddress, PlacesAutocompleteServiceProps } from '../shared-types/googleAddress.types';
import { getPlacesAutocompleteServiceProps, parseGoogleAddress } from '../utils/googleAddress.utils';

export type GoogleAddressComboboxProps = Omit<FormComboboxProps, 'emptyState' | 'onInputChange' | 'options'> &
  PlacesAutocompleteServiceProps & {
    onAddressChange: (addressValue: InternationalAddress) => void;
  };

export const GoogleAddressCombobox = forwardRef<GoogleAddressComboboxProps, 'input'>((props, ref) => {
  const [isPlatformGoogleAutocompleteAddressOnly] = useDevFeature(FeatureFlags.PlatformFinancingApplicationFlow, false);
  const config = useConfig();
  const { formatMessage } = useMelioIntl();
  const {
    id,
    onChange,
    onAddressChange,
    restrictedCountry,
    labelProps,
    control,
    name,
    error,
    colSpan,
    showOptionalIndicator,
    isRequired,
    ...comboboxProps
  } = props;
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService(getPlacesAutocompleteServiceProps(config, restrictedCountry));

  return (
    <FormField
      control={control}
      name={name}
      labelProps={
        labelProps || {
          label: formatMessage('widgets.InternationalVendorDetailsForm.line1.label'),
        }
      }
      colSpan={colSpan}
      showOptionalIndicator={showOptionalIndicator}
      error={error}
      data-testid={`form-field-${name}`}
      render={(fieldProps) => (
        <Combobox
          {...comboboxProps}
          {...fieldProps}
          data-component="GoogleAddressInput"
          ref={ref}
          onInputChange={(v) =>
            getPlacePredictions({
              input: v,
              types: isPlatformGoogleAutocompleteAddressOnly ? ['address'] : undefined,
            })
          }
          isMenuOpen={!!placePredictions.length}
          isLoadingOptions={isPlacePredictionsLoading}
          emptyState={{ label: formatMessage('form.googleAddress.emptyState') }}
          onChange={(event) => {
            if (!event.target.value) {
              const placeAddressData = parseGoogleAddress({}) || {};
              event.target.value = '';
              onChange?.(event);
              onAddressChange(placeAddressData);
              return;
            }
            placesService?.getDetails({ placeId: event.target.value }, (placeResult) => {
              if (placeResult) {
                // a temporary workaround until DS provide a better solution
                const placeAddressData = parseGoogleAddress(placeResult) || {};
                event.target.value = placeAddressData.line1;
                onChange?.(event);
                onAddressChange(placeAddressData);
              }
            });
          }}
          options={(placePredictions || []).map((p) => ({ label: p.description || '', value: p.place_id }))}
        />
      )}
    />
  );
});

GoogleAddressCombobox.displayName = 'GoogleAddressCombobox';
