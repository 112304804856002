import { AmountCell, useMelioIntl } from '@melio/ar-domain';
import { Group, Table, TableColumnDef, Text, useTable } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useMemo } from 'react';

import { Payout } from '../mocks';
import { Column } from '../types';
import { PayoutTypeCell } from './PayoutTypeCell';

export type PayoutsTableProps = {
  payouts: Payout[];
  isLoading?: boolean;
  onViewInvoice?: (invoiceId: string) => void;
};

export const PayoutsTable = forwardRef<PayoutsTableProps>(
  ({ payouts = [], isLoading, onViewInvoice, ...props }, ref) => {
    const { formatMessage, formatDate } = useMelioIntl();

    const columns: TableColumnDef<Column>[] = useMemo(
      () => [
        {
          id: 'settlementDate',
          header: <Table.Cell>{formatMessage('ar.dashboard.activities.payoutsTable.columns.date.label')}</Table.Cell>,
          cell: ({ row }) => <Table.Cell>{row.settlementDate}</Table.Cell>,
          isPinnedToLeft: true,
          isRowHeader: true,
        },
        {
          id: 'transactionType',
          header: (
            <Table.Cell>{formatMessage('ar.dashboard.activities.payoutsTable.columns.paymentType.label')}</Table.Cell>
          ),
          cell: () => <Table.Cell />,
          subRowCell: ({ row: transaction }) => (
            <PayoutTypeCell
              transactionType={transaction.transactionType}
              invoiceNumber={transaction.invoiceNumber}
              onClick={() => onViewInvoice?.(transaction.invoiceId)}
            />
          ),
        },
        {
          id: 'customerName',
          header: (
            <Table.Cell>{formatMessage('ar.dashboard.activities.payoutsTable.columns.customer.label')}</Table.Cell>
          ),
          cell: () => <Table.Cell />,
          subRowCell: ({ row: transaction }) => (
            <Table.Cell>
              <Group spacing="xxs" variant="vertical">
                {transaction.customerName}
                <Text textStyle="body4" color="semantic.text.secondary">
                  {formatMessage('ar.dashboard.activities.payoutsTable.columns.customer.subTitle.label', {
                    customerId: transaction.customerAccountNumber,
                  })}
                </Text>
              </Group>
            </Table.Cell>
          ),
        },
        {
          id: 'fundingMethod',
          header: (
            <Table.Cell>{formatMessage('ar.dashboard.activities.payoutsTable.columns.paymentMethod.label')}</Table.Cell>
          ),
          cell: () => <Table.Cell />,
          subRowCell: ({ row: transaction }) => <Table.Cell>{transaction.fundingMethod}</Table.Cell>,
        },
        {
          id: 'amount',
          header: (
            <Table.Cell textAlign="end">
              {formatMessage('ar.dashboard.activities.payoutsTable.columns.amount.label')}
            </Table.Cell>
          ),
          size: 's',
          cell: ({ row }) => (
            <AmountCell value={row.totalPaymentAmount - row.totalReturnAmount} data-testid="payouts-amount" />
          ),
          subRowCell: ({ row: transaction }) => (
            <AmountCell value={transaction.paymentAmount - transaction.returnAmount} />
          ),
        },
        {
          id: 'fees',
          header: (
            <Table.Cell textAlign="end">
              {formatMessage('ar.dashboard.activities.payoutsTable.columns.fees.label')}
            </Table.Cell>
          ),
          size: 's',
          cell: ({ row }) => <AmountCell value={row.totalPaymentFee - row.totalReturnFee} />,
          subRowCell: ({ row: transaction }) => <AmountCell value={transaction.paymentFee - transaction.returnFee} />,
        },
        {
          id: 'fundsReserved',
          header: (
            <Table.Cell textAlign="end">
              {formatMessage('ar.dashboard.activities.payoutsTable.columns.fundsReserved.label')}
            </Table.Cell>
          ),
          size: 's',
          cell: ({ row }) => <AmountCell value={row.fundsHeldToday - row.fundsReleasedToday} />,
          subRowCell: ({ row: transaction }) => <AmountCell value={transaction.paymentAmount} />,
        },
        {
          id: 'netAmount',
          header: (
            <Table.Cell textAlign="end">
              {formatMessage('ar.dashboard.activities.payoutsTable.columns.netAmount.label')}
            </Table.Cell>
          ),
          size: 's',
          cell: ({ row }) => <AmountCell value={row.netSettlementAmount} />,
          subRowCell: ({ row: transaction }) => <AmountCell value={transaction.settlementAmount} />,
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [formatMessage, formatDate]
    );

    const tableData = useMemo(
      () =>
        payouts.map((payout) => ({
          ...payout,
          subRows: payout.transactions,
        })),
      [payouts]
    );

    const tableProps = useTable<Column>({
      isLoading,
      data: tableData,
      columns,
      headerVariant: 'dark',
    });

    return (
      <Group variant="vertical" alignItems="stretch" spacing="l">
        <Table
          data-testid="payouts-table"
          data-component={PayoutsTable.displayName}
          isLoading={isLoading}
          {...tableProps}
          {...props}
          ref={ref}
        />
      </Group>
    );
  }
);

PayoutsTable.displayName = 'PayoutsTable';
