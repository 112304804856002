import {
  ActionsDropdownMenu,
  BrandSymbol,
  Button,
  Group,
  GroupProps,
  Link,
  Pill,
  Text,
  useBreakpointValue,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDateUtils } from '@melio/platform-utils';
import React, { useState } from 'react';

import {
  useFreeTrialPeriod,
  useIsSubscriptionFundingSourceRemoved,
  usePendingSubscription,
  usePlanInfo,
  useRedirects,
  useSubscription,
} from '../../../../hooks';
import { ReactivatePlanFundingSourceRequiredModal } from '../../../activities';
import { ReactivatePlanModal } from '../../ReactivatePlanModal/ReactivatePlanModal';
import { SectionSettingCard } from '../../SectionSettingCard';
import { CurrentPlanCell } from '../CurrentPlanCell';
import { PendingSubscriptionPlanChange } from '../PendingSubscriptionPlanChange';
import { useEditablePlanCardActions } from './useEditablePlanCardActions';

type EditablePlanCardProps = {
  onOpenCancelModal: () => void;
  onOpenSwitchBillingCycleModal: () => void;
  isClientWithPlanManagedByFirm?: boolean;
};

export const EditablePlanCard = withAnalyticsContext<EditablePlanCardProps>(
  ({ isClientWithPlanManagedByFirm, onOpenCancelModal, onOpenSwitchBillingCycleModal, setAnalyticsProperties }) => {
    const [accountManagerAccessCollaboratorsEnabled] = useDevFeature<boolean>(
      FeatureFlags.AccountManagerAccessCollaborators,
      false
    );
    const { goToSettingsCollaboratorsPage } = useRedirects();
    const isMobileOrTablet = useBreakpointValue({ s: true, m: false });
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [showReactivateModal, setShowReactivateModal] = useState(false);
    const [showReactivateFundingSourceRequiredModal, setShowReactivateFundingSourceRequiredModal] = useState(false);
    const subscription = useSubscription();
    const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
    const groupVariant: GroupProps['variant'] = useBreakpointValue({ xs: 'vertical', s: 'horizontal' });
    const { isPendingPlanFree } = usePendingSubscription();
    const isSubscriptionFundingSourceRemoved = useIsSubscriptionFundingSourceRemoved();
    const { planName } = usePlanInfo(subscription?.planId);
    const { createDate } = useDateUtils();

    setAnalyticsProperties({
      PageName: 'plans',
      Flow: 'settings',
    });

    const { track } = useAnalytics();

    const toggleMenu = (open: boolean) => {
      if (open) {
        const intent = 'subscription-menu';
        track('Settings', 'Click', {
          Intent: intent,
          Cta: intent,
        });
      }
      setIsMenuOpen(open);
    };

    const onManageSeats = (e: React.MouseEvent<HTMLLinkElement>) => {
      e.preventDefault();
      track('Settings', 'Click', {
        Intent: 'manage-seats',
        Cta: 'manage',
        NumberOfSeats: subscription?.measurementInfo?.quantity,
      });

      goToSettingsCollaboratorsPage();
    };

    const { getRemainingDaysForFreeTrial } = useFreeTrialPeriod();

    const remainingDaysForFreeTrial = subscription?.nextSubscription?.startDate
      ? getRemainingDaysForFreeTrial(createDate(subscription?.nextSubscription?.startDate))
      : undefined;

    const actions = useEditablePlanCardActions({ onOpenCancelModal, onOpenSwitchBillingCycleModal });
    const variantForPlansDetails = isMobileOrTablet ? 'vertical' : 'horizontal';
    const alignItemsForPlansDetails = isMobileOrTablet ? 'flex-start' : 'center';

    if (isClientWithPlanManagedByFirm) {
      return (
        <SectionSettingCard data-testid="subscription-current-plan">
          <Group spacing="s" alignItems="center">
            <BrandSymbol type="melio" size="extra-large" />
            <Text textStyle="body2Semi" as="h4" data-testid="current-plan-name">
              {formatMessage('activities.settings.plans.planName', { planName })}
            </Text>
          </Group>
        </SectionSettingCard>
      );
    }

    return (
      <SectionSettingCard data-testid="subscription-current-plan">
        <Group width="full" variant="vertical" spacing="m" alignItems="flex-start">
          <Group
            width="full"
            variant={variantForPlansDetails}
            alignItems={alignItemsForPlansDetails}
            justifyContent="space-between"
          >
            <Group spacing="s" variant={variantForPlansDetails} alignItems={alignItemsForPlansDetails}>
              <BrandSymbol type="melio" size="extra-large" />
              <Group variant="vertical" spacing="xxs">
                <Group spacing="xs" variant={variantForPlansDetails} alignItems={alignItemsForPlansDetails}>
                  <Text textStyle="body2Semi" as="h4" data-testid="current-plan-name">
                    {formatMessage('activities.settings.plans.planName', { planName })}
                  </Text>
                  {subscription?.isFreeTrial && (
                    <Group variant="horizontal" spacing="xs">
                      <Pill
                        data-testid="free-trial-pill"
                        status="success"
                        label={formatMessage('activities.settings.plans.editablePlanCard.freeTrial.freeTrialBadge')}
                        type="secondary"
                      />
                      {remainingDaysForFreeTrial ? (
                        <Pill
                          data-testid="remaining-days-for-free-trial-pill"
                          status="neutral"
                          label={formatMessage(
                            'activities.settings.plans.editablePlanCard.freeTrial.remainingDaysBadge',
                            {
                              remainingDays: remainingDaysForFreeTrial,
                            }
                          )}
                          type="secondary"
                        />
                      ) : null}
                    </Group>
                  )}
                </Group>
                <PendingSubscriptionPlanChange />
              </Group>
            </Group>

            {!isPendingPlanFree && (
              <ActionsDropdownMenu
                isOpen={isMenuOpen}
                onOpenChange={toggleMenu}
                size="small"
                label="menu action"
                data-testid="subscription-current-plan-actions-menu"
                items={actions}
              />
            )}
            {isPendingPlanFree && (
              <>
                <ReactivatePlanModal
                  variant="cancel-pending-change"
                  isOpen={showReactivateModal}
                  onClose={() => setShowReactivateModal(false)}
                />

                <ReactivatePlanFundingSourceRequiredModal
                  isOpen={showReactivateFundingSourceRequiredModal}
                  onClose={() => setShowReactivateFundingSourceRequiredModal(false)}
                  flowAnalyticsProperties={{
                    Flow: 'settings',
                    Intent: 'reactivate-subscription',
                  }}
                />

                <Button
                  data-testid="pending-subscription-reactivate-from-free-plan-button"
                  label={formatMessage('activities.subscription.plans.card.reactivatePlan')}
                  onClick={() => {
                    track('Settings', 'Click', {
                      Intent: 'reactivate-subscription',
                      Cta: 'reactivate-subscription',
                    });
                    isSubscriptionFundingSourceRemoved
                      ? setShowReactivateFundingSourceRequiredModal(true)
                      : setShowReactivateModal(true);
                  }}
                />
              </>
            )}
          </Group>

          {!isPendingPlanFree && (
            <Group hasDivider spacing="m" width="full" variant={groupVariant}>
              {subscription && (
                <CurrentPlanCell label={formatMessage('activities.settings.editablePlanCard.title.paymentFrequency')}>
                  <Text textStyle="body2" data-testid="plans-settings-page-payment-frequency">
                    {formatMessage(
                      `activities.settings.editablePlanCard.paymentFrequency.${subscription.planCyclePeriod}`
                    )}
                  </Text>
                  {subscription.nextBillingDate && (
                    <Text
                      textStyle="body4"
                      color="global.neutral.800"
                      data-testid="plans-settings-page-next-billing-date"
                    >
                      {formatMessage('activities.settings.editablePlanCard.action.plan.nextBilling', {
                        nextBillingDate: formatDate(subscription.nextBillingDate, {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                        }),
                      })}
                    </Text>
                  )}
                </CurrentPlanCell>
              )}

              <CurrentPlanCell label={formatMessage('activities.settings.editablePlanCard.title.users')}>
                <Text textStyle="body2" data-testid="subscription-measurement-info-quantity">
                  {subscription?.measurementInfo?.quantity}
                </Text>
                {accountManagerAccessCollaboratorsEnabled && (
                  <Text textStyle="body4">
                    <Link
                      href="#"
                      label={formatMessage('activities.settings.editablePlanCard.seats.manage')}
                      onClick={onManageSeats}
                      color="secondary"
                    />
                  </Text>
                )}
              </CurrentPlanCell>

              {subscription?.nextBillingAmount && subscription?.nextBillingDate && (
                <CurrentPlanCell
                  label={formatMessage('activities.settings.editablePlanCard.title.estimatedTotal')}
                  tooltipLabel={formatMessage('activities.settings.editablePlanCard.tooltip.subscriptionFee')}
                >
                  <Text textStyle="body2" data-testid="plans-settings-page-next-billing-amount">
                    {formatCurrency(subscription.nextBillingAmount)}
                  </Text>
                </CurrentPlanCell>
              )}
            </Group>
          )}
        </Group>
      </SectionSettingCard>
    );
  }
);
