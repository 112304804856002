import { Container, Group, Icon, Skeleton, SkeletonCircle, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

import { ClientLogo } from '../ClientLogo/ClientLogo';
import { StatusCell } from './cells/status';
import { ClientData } from './types';

export const ClientListItem = ({ data, isLoading }: { data: ClientData; isLoading: boolean }) => {
  const { formatMessage } = useMelioIntl();

  if (isLoading) {
    return (
      <Container paddingX={['s', null, '0'] as never}>
        <Group variant="horizontal" spacing="m">
          <Group.Item>
            <SkeletonCircle diameter="40px" />
          </Group.Item>
          <Group.Item grow={1}>
            <Group variant="vertical" spacing="xxs">
              <Skeleton />
              <Skeleton
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sx={{
                  width: '170px',
                }}
              />
            </Group>
          </Group.Item>
        </Group>
      </Container>
    );
  }

  return (
    <Container paddingX={['s', null, '0'] as never}>
      <Group variant="horizontal" spacing="m">
        <Group.Item>
          <ClientLogo
            client={{ name: data.client.name, isHidden: data.client.isHidden, logoUrl: data.client.logoUrl }}
          />
        </Group.Item>
        <Group.Item grow={1}>
          <Group variant="vertical" spacing="xxs">
            <Text textStyle="body2Semi">{data.client.name}</Text>
            <Text textStyle="body4">
              {formatMessage('activities.accountants.firmDashboard.v2.firmClientsTable.billsDue.label.mobile', {
                billsCount: data.billsCount,
              })}
            </Text>
            <StatusCell status={data.clientStatus} />
          </Group>
        </Group.Item>
        <Group.Item alignSelf="center">
          <Icon type="chevron-right" size="small" />
        </Group.Item>
      </Group>
    </Container>
  );
};
