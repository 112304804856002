import { useMelioIntl } from '@melio/ar-domain';
import { Group, NakedButton, SelectionCard } from '@melio/penny';
import { FundingSourceBankAccount } from '@melio/platform-api';
import { forwardRef } from '@melio/platform-utils';

type Account = {
  id: string;
  details: FundingSourceBankAccount['details'];
  displayName?: string;
  logo?: string;
};

export type AccountCardListProps = {
  accounts?: Account[];
  onAddPlaidAccount?: VoidFunction;
  onSelectAccount?: (id?: string) => void;
  selectedAccountId?: string;
  isReadOnly?: boolean;
};

export const AccountCardList = forwardRef<AccountCardListProps>(
  ({ accounts = [], onAddPlaidAccount, onSelectAccount, selectedAccountId, isReadOnly, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();

    const getDisplayName = useGetDisplayName();

    return (
      <Group
        spacing="s"
        variant="vertical"
        width="full"
        alignItems="flex-start"
        data-component={AccountCardList.displayName}
        data-testid="account-card-list"
        {...props}
        ref={ref}
      >
        {accounts.map((account) => {
          const isSelected = !isReadOnly && account.id === selectedAccountId;
          const displayName = getDisplayName(account);
          const idx = displayName.indexOf(',');
          return (
            <SelectionCard
              key={account.id}
              data-testid={`account-card-${account.id}`}
              isSelected={isSelected}
              variant="flat"
              mainLabelProps={{
                label:
                  displayName.substring(0, idx) ||
                  formatMessage(
                    'ar.onboarding.activities.confirmReceivingAccount.components.fundingSourceSelection.defaultAccountName'
                  ),
              }}
              descriptionProps={{ label: displayName.substring(idx + 1) }}
              image={{
                src: account.logo ?? '',
                alt: displayName,
                fallbackIcon: 'bank',
              }}
              onClick={!isReadOnly ? () => onSelectAccount?.(isSelected ? undefined : account.id) : undefined}
            />
          );
        })}
        {!isReadOnly && !!onAddPlaidAccount && (
          <NakedButton
            data-testid="add-bank-account-button"
            variant="secondary"
            label={formatMessage(
              'ar.onboarding.activities.confirmReceivingAccount.components.fundingSourceSelection.add.label'
            )}
            onClick={onAddPlaidAccount}
          />
        )}
      </Group>
    );
  }
);
AccountCardList.displayName = 'AccountCardList';

const useGetDisplayName = () => {
  const { formatMessage } = useMelioIntl();

  return ({ displayName, details }: Account) => {
    if (displayName) return displayName;

    const { accountType, accountNumberLast4Digits = details.accountNumber.slice(-4) } = details;

    const accountTypeLabels: Record<Account['details']['accountType'], string> = {
      checking: formatMessage(
        'ar.onboarding.activities.confirmReceivingAccount.components.fundingSourceSelection.accountType.checking'
      ),
      savings: formatMessage(
        'ar.onboarding.activities.confirmReceivingAccount.components.fundingSourceSelection.accountType.savings'
      ),
    };

    return formatMessage(
      'ar.onboarding.activities.confirmReceivingAccount.components.fundingSourceSelection.displayName',
      {
        accountType: accountTypeLabels[accountType],
        accountNumberLast4Digits,
      }
    );
  };
};
