import { Box } from '@chakra-ui/react';
import { forwardRef } from '@melio/platform-utils';
import { ReactNode } from 'react';

export const Actions = forwardRef<{ children?: ReactNode }, 'div'>((props, ref) => (
  <Box
    data-component="NewSinglePaymentStepLayout.Actions"
    display="flex"
    justifyContent="center"
    width="100%"
    marginX="auto"
    {...props}
    ref={ref}
  />
));

Actions.displayName = 'NewSinglePaymentStepLayout.Actions';
