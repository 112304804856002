/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SyncFlowEnum } from '@melio/ap-activities';
import { encodeStringifiedParams, useOAuthPopup, useRedirectUrls } from '@melio/ap-activities/src/utils/useOAuthPopup';
import { useAccountingPlatformName } from '@melio/ap-widgets';
import {
  AccountingPlatformSlug,
  useAccountingPlatform,
  useAccountingPlatforms,
  useAccountingPlatformSync as useAccountingPlatformSyncStatus,
} from '@melio/platform-api';
import {
  AccountingPlatformSyncStatus,
  useAccountingPlatformSync as useAccountingPlatformSyncAPI,
} from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { useSystemMessage } from '@melio/platform-utils';

import { authApiGetIntuitConnectLink } from '@/api/auth.api';
import { APP_EVENTS, emitAppEvent } from '@/queries/event-emitter-query';
import { usePlatformIntl } from '@/translations/Intl';
import { getQboAccountingId } from '@/utils/accountingPlatform.utils';

export const useAccountingPlatformDisconnect = ({
  accountingPlatformId,
  accountingSlug,
  organizationId,
}: {
  accountingPlatformId?: string;
  accountingSlug?: AccountingPlatformSlug;
  organizationId: string;
}) => {
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const { disconnect, isMutating } = useAccountingPlatform({ id: accountingPlatformId, enabled: false });
  const accountingPlatformName = useAccountingPlatformName(accountingSlug);
  const { refetch: refetchPlatforms } = useAccountingPlatforms();

  const disconnectAccountingPlatform = useCallback(async () => {
    try {
      await disconnect(organizationId);

      // Force a refetch of the accounting platforms data
      await refetchPlatforms();

      // Emit an event to notify other components about the disconnection
      if (accountingPlatformId) {
        emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, {
          accountingPlatformId,
        });
      }

      showMessage({
        type: 'informative',
        title: formatMessage('widgets.accountingPlatform.disconnect.disconnectSuccess', {
          accountingPlatformName: accountingPlatformName,
        }),
      });
    } catch (error) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.accountingPlatform.disconnect.disconnectError'),
      });
    }
  }, [disconnect, organizationId]);

  return {
    isLoading: isMutating,
    disconnectAccountingPlatform,
  };
};

export const useAccountingPlatformConnect = ({
  flowToReturn,
  accountingPlatformId,
}: {
  flowToReturn: SyncFlowEnum;
  accountingPlatformId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const { generateAuthLink } = useAccountingPlatform({ id: accountingPlatformId, enabled: false });

  const { redirectUrl } = useRedirectUrls(
    '/accounting-software/auth/callback',
    '/auth/providers/intuit/callback/error',
  );

  const state = encodeStringifiedParams({ flow: flowToReturn, accountingPlatformId });

  const connectToAccountingPlatform = useCallback(async () => {
    setIsLoading(true);
    try {
      const { link } = await generateAuthLink({
        redirectUrl,
        state,
      });
      window.location.href = link;
    } catch (e) {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.accountingPlatform.connect.generateAuthLinkError'),
      });
    }
  }, [redirectUrl, state, generateAuthLink]);

  return {
    isLoading,
    connectToAccountingPlatform,
  };
};

export const useQuickBooksDesktopConnect = ({
  flowToReturn,
  accountingPlatformId,
}: {
  flowToReturn: SyncFlowEnum;
  accountingPlatformId?: string;
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const connectToAccountingPlatform = async () => {
    setIsLoading(true);
    const state = window.btoa(
      JSON.stringify({
        flow: flowToReturn,
        accountingPlatformId,
        isQuickBooksDesktop: true,
      }),
    );
    const redirectUrl = `/accounting-software/auth/callback?state=${state}`;
    navigate(redirectUrl);
    setIsLoading(false);
  };

  return {
    isLoading,
    connectToAccountingPlatform,
  };
};

type RedirectMethod = 'push' | 'replace';

const redirectHandlers: Record<RedirectMethod, (link: string) => void> = {
  replace: (link) => window.location.replace(link),
  push: (link) => (window.location.href = link),
};

export const useIntuitConnect = (
  flowToReturn: SyncFlowEnum,
  accountingPlatformId?: string,
  options?: { redirectMethod?: RedirectMethod; isAccountant?: boolean },
) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showMessage } = useSystemMessage();
  const { formatMessage } = usePlatformIntl();
  const [popupEnabled] = usePartnerFeature('UseOAuthPopup', false);

  const { redirectUrl, errorRedirectUrl } = useRedirectUrls(
    '/accounting-software/auth/callback',
    '/auth/providers/intuit/callback/error',
  );

  const state = encodeURIComponent(
    JSON.stringify({
      flow: flowToReturn,
      accountingPlatformId,
      isQuickBooksOnline: true,
    }),
  );

  const { startOAuthFlow } = useOAuthPopup({
    redirectMethod: options?.redirectMethod,
    onClose: () => setIsLoading(false),
  });

  // Add event listener for disconnection
  useEffect(() => {
    const handleDisconnection = () => {
      setIsLoading(false);
    };

    window.addEventListener(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, handleDisconnection);
    return () => {
      window.removeEventListener(APP_EVENTS.ACCOUNTING_PLATFORM_DISCONNECTED, handleDisconnection);
    };
  }, []);

  const loginToQuickBooks = useCallback(async () => {
    try {
      setIsLoading(true);

      const { token, link, loginWithTokenLink } = await authApiGetIntuitConnectLink({
        successReturnUrl: encodeURIComponent(`${redirectUrl}?state=${state}`),
        errorReturnUrl: encodeURIComponent(`${errorRedirectUrl}?state=${state}`),
        isAccountant: !!options?.isAccountant,
      });
      await axios.post(
        loginWithTokenLink,
        {
          token,
        },
        {
          withCredentials: true,
        },
      );

      if (popupEnabled) {
        startOAuthFlow(link);
      } else {
        // Since we use a redirect here, there's no need to set isLoading to false.
        // The loading state should persist until the redirect is complete.
        // Once the user returns or clicks the back button, the state will reinitialize.
        redirectHandlers[options?.redirectMethod || 'push'](link);
      }
    } catch {
      showMessage({
        type: 'error',
        title: formatMessage('widgets.accountingPlatform.connect.generateAuthLinkError'),
      });
      setIsLoading(false);
    }
  }, [errorRedirectUrl, redirectUrl, startOAuthFlow]);

  return {
    loginToQuickBooks,
    isLoading,
  };
};

export const useAccountingPlatformSync = ({
  accountingPlatformId,
  onSyncDone,
  onSyncError,
}: {
  accountingPlatformId: string;
  onSyncDone?: VoidFunction;
  onSyncError?: VoidFunction;
}) => {
  const syncStatusRef = useRef<{
    intervalCallbackNumber: number | null;
    isFetching: boolean;
    shouldRefetchData: boolean;
  }>({
    isFetching: false,
    intervalCallbackNumber: null,
    shouldRefetchData: false,
  });
  const [isSyncStatusLoading, setIsSyncStatusLoading] = useState(false);
  const {
    data: syncStatusData,
    isLoading: isSyncStatusApiLoading,
    refetch,
  } = useAccountingPlatformSyncStatus({ id: accountingPlatformId });
  const { sync } = useAccountingPlatformSyncAPI({ id: accountingPlatformId });

  useEffect(() => {
    syncStatusRef.current.isFetching = isSyncStatusApiLoading;
  }, [isSyncStatusApiLoading]);

  useEffect(() => {
    if (!isSyncStatusApiLoading) {
      if (syncStatusData?.syncStatus === AccountingPlatformSyncStatus.Running) {
        setIsSyncStatusLoading(true);
        syncStatusRef.current.shouldRefetchData = true;
      } else {
        setIsSyncStatusLoading(false);
      }
      if (syncStatusData) {
        if (syncStatusData?.syncStatus === AccountingPlatformSyncStatus.Running) {
          if (!syncStatusRef.current.intervalCallbackNumber) {
            syncStatusRef.current.intervalCallbackNumber = window.setInterval(() => {
              if (!syncStatusRef.current.isFetching) {
                refetch();
              }
            }, 1000);
          }
        } else {
          if (syncStatusRef.current.intervalCallbackNumber) {
            window.clearInterval(syncStatusRef.current.intervalCallbackNumber);
            syncStatusRef.current.intervalCallbackNumber = null;
          }
          if (
            syncStatusData.syncStatus === AccountingPlatformSyncStatus.Done &&
            syncStatusRef.current.shouldRefetchData
          ) {
            syncStatusRef.current.shouldRefetchData = false;
            emitAppEvent(APP_EVENTS.ACCOUNTING_PLATFORM_SYNC_STATUS_DONE, {
              accountingPlatformId,
            });
            onSyncDone?.();
          }
          if (
            onSyncError &&
            syncStatusData.syncStatus === AccountingPlatformSyncStatus.Error &&
            syncStatusRef.current.shouldRefetchData
          ) {
            onSyncError();
          }
        }
      }
    }
  }, [syncStatusData, isSyncStatusApiLoading]);

  const triggerSync = useCallback(() => {
    setIsSyncStatusLoading(true);
    sync().then(() => {
      syncStatusRef.current.shouldRefetchData = true;
    });
  }, [setIsSyncStatusLoading, sync, syncStatusData]);

  return {
    triggerSync,
    isSyncStatusApiLoading,
    isSyncStatusLoading,
    triggerStatusSync: refetch,
  };
};

export const usePaymentFlowIntuitConnect = (options?: {
  redirectMethod?: RedirectMethod;
  flowToReturn?: SyncFlowEnum;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { data: accountingPlatformData } = useAccountingPlatforms();
  const qboAccountingId = getQboAccountingId(accountingPlatformData);
  const { loginToQuickBooks } = useIntuitConnect(options?.flowToReturn || SyncFlowEnum.PayBoard, qboAccountingId, {
    redirectMethod: options?.redirectMethod,
  });
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();

  const loginToAccountPlatformAuth = async () => {
    try {
      setIsLoading(true);
      await loginToQuickBooks();
    } catch (e: unknown) {
      setIsLoading(false);
      showMessage({
        type: 'error',
        title: formatMessage('screens.serverError.title'),
      });
    } finally {
      // Don't set isLoading to false, user still see the ui during the location.href updating, so wwe should view the loader
    }
  };

  const connectAccountingPlatformCustomErrorMessage = async ({ errorMessageKey }: { errorMessageKey: MessageKey }) => {
    try {
      setIsLoading(true);
      await loginToQuickBooks();
    } catch (e: unknown) {
      setIsLoading(false);
      showMessage({
        type: 'error',
        title: formatMessage(errorMessageKey),
      });
    }
  };

  return {
    connectAccountingPlatformCustomErrorMessage,
    loginToAccountPlatformAuth,
    isLoginToAccountPlatformAuthLoading: isLoading,
  };
};
