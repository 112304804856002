import { Link, NakedButton, SectionBanner } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { BankAccount, Card, DeliveryMethodType, Payment } from '@melio/platform-api';
import { FeatureFlags, useDevFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useContext } from 'react';

import { getPaymentAnalyticsFields } from '../../../../../utils';
import { getFinancingCancelDeadlineDate } from '../../../../../utils/pay-flow/Financing.utils';
import { PaymentDrawerContext } from '../../../PaymentDrawerContext';

const cardFailureReason = [
  'doNotHonor',
  'invalidTransaction',
  'exceedsWithdrawalAmountLimit',
  'insufficientFunds',
] as const;
type CardPaymentFailureReason = (typeof cardFailureReason)[number];
const bankFailureReason = [
  'insufficientFunds',
  'receiverAccountClosed',
  'noBankAccount',
  'invalidBankAccountNumber',
  'unauthorizedDebitToConsumerAccount',
  'paymentStoppedByVendor',
  'uncollectedFunds',
  'bankAccountUnauthorized',
  'routingNumberIncorrect',
  'accountFrozen',
  'receiverRefusedCreditEntry',
  'userBlockedMelioAccount',
] as const;
type BankAccountPaymentFailureReason = (typeof bankFailureReason)[number];

/**
 * which failure reasons support a failure description.
 */
const reason = {
  card: cardFailureReason,
  'bank-account': bankFailureReason,
};

type DescriptionMessageModel = {
  lastFourDigits: string;
  vendorCompanyName: string | undefined;
};

type Model =
  | null
  | {
      failureReason: CardPaymentFailureReason;
      fundingSourceType: 'card';
      descriptionModel: DescriptionMessageModel;
    }
  | {
      failureReason: BankAccountPaymentFailureReason;
      fundingSourceType: 'bank-account';
      descriptionModel: DescriptionMessageModel;
    };

const getPaymentFailedActionAlertModel = (payment: Payment): Model => {
  const failureReason = payment.paymentFailure?.failureReason;

  if (!failureReason) {
    return null;
  }

  const vendorCompanyName = payment.vendor?.name;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fundingSourceType = payment.fundingSource!.type;
  if (fundingSourceType === 'card' && reason['card'].includes(failureReason as CardPaymentFailureReason)) {
    const { lastFourDigits } = payment.fundingSource?.details as Card;

    return {
      failureReason: failureReason as CardPaymentFailureReason,
      fundingSourceType: 'card' as const,
      descriptionModel: {
        lastFourDigits,
        vendorCompanyName,
      },
    };
  } else if (
    fundingSourceType === 'bank-account' &&
    reason['bank-account'].includes(failureReason as BankAccountPaymentFailureReason)
  ) {
    const { accountNumberLast4Digits } = payment.fundingSource?.details as BankAccount;

    return {
      failureReason: failureReason as BankAccountPaymentFailureReason,
      fundingSourceType: 'bank-account' as const,
      descriptionModel: {
        lastFourDigits: accountNumberLast4Digits || '',
        vendorCompanyName,
      },
    };
  }

  return null;
};

type DescriptionNodes = {
  supportEmail: React.ReactNode;
  supportPhoneNumber: React.ReactNode;
  learnMore: React.ReactNode;
  retryDeliverLink: React.ReactNode;
};

const useGetTitleAndDescription = ({
  payment,
  descriptionNodes,
  isRobinhoodRefundFlowEnabled,
}: {
  payment: Payment;
  descriptionNodes: DescriptionNodes;
  isRobinhoodRefundFlowEnabled: boolean;
}) => {
  const { formatMessage, formatDate } = useMelioIntl();
  const {
    settings: { supportPhoneNumber },
  } = useConfig();

  if (payment.isFinanced) {
    if (payment.loan?.status === 'canceled') {
      if (payment.deliveryMethod?.type === 'paper-check') {
        return {
          title: formatMessage('widgets.paymentDetails.failed.reason.loanCanceled.financed.title'),
          description: formatMessage('widgets.paymentDetails.failed.reason.loanCanceled.check.financed.description'),
        };
      }
      return {
        title: formatMessage('widgets.paymentDetails.failed.reason.loanCanceled.financed.title'),
        description: formatMessage('widgets.paymentDetails.failed.reason.loanCanceled.financed.description'),
      };
    }
    return {
      title: formatMessage('widgets.paymentDetails.failed.reason.failedToDeliver.financed.title'),
      description: formatMessage('widgets.paymentDetails.failed.reason.failedToDeliver.financed.description', {
        deadlineDate: formatDate(getFinancingCancelDeadlineDate(payment), { dateStyle: 'medium' }),
      }),
    };
  }

  const canRetryDeliver = payment.paymentActions?.retryDeliver.eligible;
  const canRefund = payment.paymentActions?.refund?.eligible;

  if (canRetryDeliver) {
    if (canRefund && isRobinhoodRefundFlowEnabled) {
      return {
        title: formatMessage('widgets.paymentDetails.failed.reason.deliverRefund.title'),
        description: formatMessage('widgets.paymentDetails.failed.reason.deliverRefund.description'),
      };
    }
    return {
      title: formatMessage('widgets.paymentDetails.failed.reason.deliver.title'),
      description: formatMessage('widgets.paymentDetails.failed.reason.deliver.description', {
        retryPayment: descriptionNodes.retryDeliverLink,
      }),
    };
  }

  //taken exact same logic from old dashbaord
  if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualCard) {
    const message = formatMessage(`widgets.paymentDetails.failed.reason.virtualCard.description`, {
      vendorName: payment.vendor?.name,
    });

    return { title: '', description: message };
  }

  const model = getPaymentFailedActionAlertModel(payment);
  if (!model) {
    return {
      title: formatMessage('widgets.paymentDetails.failed.reason.general.title'),
      description: formatMessage('widgets.paymentDetails.failed.reason.general.description', {
        supportEmail: descriptionNodes.supportEmail,
        supportPhoneNumber: descriptionNodes.supportPhoneNumber,
      }),
    };
  }

  if (model.fundingSourceType === 'card') {
    return {
      title: formatMessage(`widgets.paymentDetails.failed.reason.card.${model.failureReason}.title`),
      description: formatMessage(`widgets.paymentDetails.failed.reason.card.${model.failureReason}.title`, {
        ...model.descriptionModel,
        ...descriptionNodes,
      }),
    };
  }

  if (model.fundingSourceType === 'bank-account') {
    return {
      title: formatMessage(`widgets.paymentDetails.failed.reason.bank-account.${model.failureReason}.title`, {
        supportPhoneNumber,
      }),
      description: formatMessage(
        `widgets.paymentDetails.failed.reason.bank-account.${model.failureReason}.description`,
        {
          ...model.descriptionModel,
          ...descriptionNodes,
        }
      ),
    };
  }

  return { title: '', description: '' };
};

export const PaymentFailedSectionBanner = ({ payment }: { payment: Payment }) => {
  const { track } = useAnalytics();
  const { onRetryFailedToDeliverPayment } = useContext(PaymentDrawerContext);
  const [isRobinhoodRefundFlowEnabled] = useDevFeature(FeatureFlags.RobinhoodRefundFlow, false);
  const {
    links,
    settings: { supportEmail, supportPhoneNumber },
  } = useConfig();
  const { formatMessage } = useMelioIntl();
  const failedToCollectSupportLink = links?.failedToCollectSupport;
  const supportEmailLink = (
    <Link href={`mailto:${supportEmail}`} label={supportEmail} newTab data-testid="supportEmail" />
  );
  const supportPhoneLink = (
    <Link href={`tel:${supportPhoneNumber}`} label={supportPhoneNumber} newTab data-testid="supportPhone" />
  );

  const onRetryDeliverLinkClicked = () => {
    track('Dashboard', 'Click', {
      Cta: 'retry-payment',
      Flow: 'dashboard',
      EntryPoint: 'dashboard-inbox',
      FailedDestination: 'deliver-failure',
      ...getPaymentAnalyticsFields(payment),
    });
    onRetryFailedToDeliverPayment(payment.id);
  };

  const retryDeliverLink = (
    <NakedButton
      variant="secondary"
      onClick={onRetryDeliverLinkClicked}
      data-testid="retry-link"
      label={formatMessage('widgets.paymentDetails.failed.retryPayment')}
    />
  );

  const learnMore = failedToCollectSupportLink ? (
    <Link href={failedToCollectSupportLink} label={formatMessage('widgets.paymentDetails.failed.learnMore')} newTab />
  ) : null;

  const { title, description } = useGetTitleAndDescription({
    payment,
    descriptionNodes: {
      supportEmail: supportEmailLink,
      supportPhoneNumber: supportPhoneLink,
      learnMore,
      retryDeliverLink,
    },
    isRobinhoodRefundFlowEnabled,
  });

  return (
    <SectionBanner title={title} description={description} variant="critical" data-testid="payment-failed-alert" />
  );
};
