import { isCurrencyCloudInternationalDm } from '@melio/ap-domain';
import { DeliveryMethod, DeliveryMethodType } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';

export const useVendorHasUnmigratedInternationalDm = () => {
  const [enabledInternationalUSDWise] = usePartnerFeature('EnableInternationalUSDWise', false);

  return {
    vendorHasUnmigratedInternationalDm: (deliveryMethods: DeliveryMethod[]) => {
      if (!enabledInternationalUSDWise) {
        return false;
      }

      return deliveryMethods.some(
        (dm) => dm.type === DeliveryMethodType.InternationalAccount && isCurrencyCloudInternationalDm(dm)
      );
    },
  };
};
