import { getStatesByCountry } from '@melio/ap-domain';
import lookup from 'country-code-lookup';

export const getCountryCode = (countryCodeOrName: string) => {
  if (!countryCodeOrName) {
    return undefined;
  }

  if (countryCodeOrName.length === 2) {
    return lookup.byIso(countryCodeOrName)?.iso2;
  }

  return lookup.byCountry(countryCodeOrName)?.iso2;
};

export const getIsStateRequired = (countryCode: string) => !!(countryCode && getStatesByCountry(countryCode)?.length);

export const getStates = (countryCode: string) => {
  if (getIsStateRequired(countryCode)) {
    return getStatesByCountry(countryCode);
  }
  return [];
};
