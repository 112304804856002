import { FloatingMenu, Group, SelectableDropdownMenu, SelectableDropdownMenuItem, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
// eslint-disable-next-line no-restricted-imports
import { AccountingClientsOverviewScopeEnum } from '@melio/platform-api-axios-client';
import { useMelioIntl } from '@melio/platform-i18n';
import React, { useState } from 'react';

import { OverviewScope } from '../types';

export const OverviewHeader = ({
  onScopeFilterChange,
  scope,
  isFetching,
  isError,
}: {
  isFetching: boolean;
  isError: boolean;
  scope: AccountingClientsOverviewScopeEnum;
  onScopeFilterChange: (value: OverviewScope) => void;
}) => {
  const { formatMessage } = useMelioIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { track } = useAnalytics();

  const scopeFilterItems = Object.values(OverviewScope).map((scope) => ({
    value: scope,
    label: formatMessage(`activities.accountants.firmDashboard.v2.overview.filter.scope.${scope}`),
  }));

  const selectedItem = scopeFilterItems.find((_item) => _item.value === scope);

  const handleScopeFilterChange = (item: SelectableDropdownMenuItem) => {
    onScopeFilterChange(item.value as OverviewScope);
    setIsOpen(false);
    track('Organization', 'Click', {
      Cta: item.label,
      Intent: 'change-overview-scope',
      OverviewStats: {},
    });
  };
  return (
    <Group spacing="xs" justifyContent="space-between">
      <Group.Item>
        <Text textStyle="heading3Semi" data-testid="clients-overview-header">
          {formatMessage('activities.accountants.firmDashboard.v2.overview.title')}
        </Text>
      </Group.Item>
      <Group.Item>
        <SelectableDropdownMenu
          trigger={<FloatingMenu.ActionTrigger size="small" label={selectedItem?.label} />}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
          selectedItemValue={selectedItem?.value}
          onSelect={handleScopeFilterChange}
          items={scopeFilterItems}
          size="small"
          isDisabled={isFetching || isError}
        />
      </Group.Item>
    </Group>
  );
};
