import { FC, useCallback, useState } from 'react';

import { SystemMessageContext } from './SystemMessageContext';
import { SystemMessageType } from './types';

export const SystemMessageProvider: FC = ({ children }) => {
  const [message, _setMessage] = useState<SystemMessageType | null>(null);

  const clear = useCallback(() => _setMessage(null), []);
  const setMessage = useCallback((value: SystemMessageType) => _setMessage(value), []);
  return (
    <SystemMessageContext.Provider value={{ setMessage, message, clear }}>{children}</SystemMessageContext.Provider>
  );
};
