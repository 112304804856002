import { screen } from '@testing-library/react';

export class SubscriptionPaymentFeeFailedModalDriver {
  getSubscriptionPaymentFeeFailedModal() {
    return screen.queryByTestId('subscription-payment-fee-failed-modal');
  }
}

export class SubscriptionPaymentFeeFailedModalCypressDriver {
  getSubscriptionPaymentFeeFailedModal() {
    return cy.getByTestId('subscription-payment-fee-failed-modal');
  }
}
