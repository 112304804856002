import { ApiPagination, ARInvoice, useIsMobile } from '@melio/ar-domain';
import { Group, LoadingContainer } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { isEmpty } from 'lodash';

import { InvoiceActionsHandlers } from '../../../utils';
import { InvoicesTableZeroState, InvoiceTable, InvoiceTableEmptyState, InvoiceTableToolbar } from '../components';
import { Filters, InvoiceReport, OnSort, SetSortableInvoicesParams, SortParams } from '../types';

export type InvoicesTabScreenProps = {
  onRowClick?: (params: { rowId: string; rowData: ARInvoice }) => void;
  invoices: ARInvoice[];
  invoiceSummary?: InvoiceReport;
  isReportsFetching?: boolean;
  pagination: ApiPagination;
  isFetching?: boolean;
  isLoading?: boolean;
  filters: Filters;
  onClearFilters: () => void;
  onClearAllParams: () => void;
  search?: string;
  sortParams: SortParams;
  onParamsChange: SetSortableInvoicesParams;
  invoiceActions: InvoiceActionsHandlers;
  selectedInvoiceId?: string;
  id?: string;
  onCreateInvoice?: VoidFunction;
};

export const InvoicesTabScreen = forwardRef<InvoicesTabScreenProps>(
  (
    {
      invoices,
      invoiceSummary,
      isReportsFetching,
      pagination,
      isFetching,
      isLoading,
      onRowClick,
      onParamsChange,
      onClearFilters,
      onClearAllParams,
      filters,
      search,
      sortParams,
      invoiceActions,
      selectedInvoiceId,
      onCreateInvoice,
      ...props
    },
    ref
  ) => {
    const isMobile = useIsMobile();
    const noResults = !isFetching && invoices.length === 0;
    const isFiltered = !isEmpty(filters) || !!search;
    const zeroState = noResults && !isFiltered;
    const emptyState = noResults && isFiltered;

    useAnalyticsView('Dashboard', !isFetching, true, {
      ProductName: 'ar',
      PageName: 'dashboard-invoices',
      CountInTab: pagination.totalCount,
      Intent: 'view-ar-invoices',
    });

    const { track } = useAnalytics();

    const handleSort: OnSort = (sort, order) => {
      track('Dashboard', 'Click', {
        ProductName: 'ar',
        Intent: 'sort-invoices',
        Cta: sort,
        SortOrder: order,
        SortDirection: sort,
      });
      onParamsChange({ sort, order });
    };

    return (
      <LoadingContainer isLoading={isLoading}>
        <Group
          width="full"
          variant="vertical"
          spacing={isMobile ? 's' : 'm'}
          data-component={InvoicesTabScreen.displayName}
          data-testid="invoices-tab-screen"
          {...props}
          ref={ref}
        >
          <InvoiceTableToolbar
            search={search}
            onSearch={(searchTerm) => onParamsChange({ searchTerm })}
            filters={filters}
            onSort={handleSort}
            sortParams={sortParams}
            onClearFilters={onClearFilters}
            onFilter={(filters) => {
              track('Dashboard', 'Click', {
                ProjectName: 'ar',
                PageName: 'dashboard',
                TabName: 'invoices',
                Flow: 'dashboard-filters',
                Intent: 'filter-invoices',
                Cta: filters,
              });
              onParamsChange(filters);
            }}
            isLoading={isFetching}
            shouldDisplayFilters={!zeroState}
          />
          <InvoiceTable
            invoices={invoices}
            invoiceSummary={invoiceSummary}
            isReportsFetching={isReportsFetching}
            pagination={pagination}
            isLoading={isFetching}
            isDataFiltered={!isEmpty(filters) || !!search}
            onRowClick={onRowClick}
            onSort={handleSort}
            sortParams={sortParams}
            invoiceActions={invoiceActions}
            selectedInvoiceId={selectedInvoiceId}
            shouldDisplayTable={!zeroState && !emptyState}
          />
          {zeroState && <InvoicesTableZeroState onCreateInvoice={onCreateInvoice} ref={ref} {...props} />}
          {emptyState && (
            <InvoiceTableEmptyState
              onClearSearch={() => {
                onClearAllParams();
              }}
              search={search}
              filters={filters}
              ref={ref}
              {...props}
            />
          )}
        </Group>
      </LoadingContainer>
    );
  }
);

InvoicesTabScreen.displayName = 'InvoicesTabScreen';
